import React from 'react';
import PropTypes from 'prop-types';
import styles from './SuperBillCard.module.scss';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';

const SuperBillCard = ({
  title,
  message,
  onClick,
  buttonClass,
  buttonLabel,
}) => {
  return (
    <div className={styles.root}>
      <h2>{title}</h2>
      <p>{message}</p>
      <PrimaryButton
        className={styles[buttonClass]}
        label={buttonLabel}
        onClick={onClick}
      />
    </div>
  );
};

SuperBillCard.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onClick: PropTypes.func,
  buttonClass: PropTypes.string,
  buttonLabel: PropTypes.string,
};

export default SuperBillCard;
