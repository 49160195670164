import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './TextInput.module.scss';
import rightBlack from '../../../../img/icons/right.svg';
import rightWhite from '../../../../img/icons/right-white.svg';

const TextInput = ({
  autoComplete,
  autoFocus,
  disabled,
  name,
  onChange,
  onBlur,
  onKeyDown,
  placeholder,
  type,
  value,
  submitButton,
  className,
  'data-testid': datatestid,
  onFocus,
  min,
}) => (
  <div
    className={classnames(styles.container, className, {
      [disabled]: disabled,
    })}
  >
    <input
      type={type}
      className={styles.input}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      name={name}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      data-testid={datatestid}
      id={name}
      disabled={disabled}
      onFocus={onFocus}
      min={min}
    />
    {submitButton && (
      <button
        type="submit"
        className={styles.button}
        disabled={!value}
        data-testid="button"
      >
        <div className={styles[`arrow-${value ? 'black' : 'white'}-img`]}>
          <img src={value ? rightWhite : rightBlack} alt="submit" />
        </div>
      </button>
    )}
  </div>
);

TextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['email', 'text', 'password', 'number']),
  submitButton: PropTypes.bool,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  min: PropTypes.number,
};

TextInput.defaultProps = {
  type: 'text',
  submitButton: false,
  value: '',
};

export default TextInput;
