import React, { useEffect, useState, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { getEntries } from '../../../api/contentful';
import Loading from '../../../components/common/Loading';
import styles from './StaticPageTemplate.module.scss';
import { WHITE_LABEL_DATA } from '../../../utils/common';

const StaticPageTemplate = ({ match }) => {
  const [staticPage, setStaticPage] = useState();
  const hostname = window && window.location && window.location.hostname;
  useEffect(() => {
    const fetchContent = async () => {
      const whilteLableData = WHITE_LABEL_DATA.find((e) => {
        return hostname.toLocaleLowerCase().includes(e.clinicName);
      });
      const content = await getEntries({
        'fields.key': whilteLableData
          ? whilteLableData[match.params.pageName]
          : match.params.pageName,
        content_type: 'staticPage',
      });
      setStaticPage(content.items);
    };
    fetchContent();
  }, [match.params.pageName, hostname]);

  return !staticPage ? (
    <Loading />
  ) : staticPage.length === 0 ? (
    <Redirect to="/" />
  ) : (
    <Fragment>
      <div className={styles.container}>
        {documentToReactComponents(staticPage[0].fields.content)}
      </div>
    </Fragment>
  );
};

export default StaticPageTemplate;
