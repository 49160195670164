import React, { useContext } from 'react';

import Loading from '../common/Loading';

import AuthContext from '../../context/AuthContext';

const BillingPlanFeature = ({
  children,
  matching,
  patientDetails,
  appointments,
  payments,
  intakeForms,
  assessments,
  notes,
  patientFiles,
}) => {
  const { isLoading, authUser } = useContext(AuthContext);

  if (isLoading) {
    return <Loading />;
  }

  if (
    !authUser?.billing_plan ||
    (matching && !authUser?.billing_plan?.matching) ||
    (patientDetails && !authUser?.billing_plan?.patient_details) ||
    (appointments && !authUser?.billing_plan?.appointments) ||
    (payments && !authUser?.billing_plan?.payments) ||
    (intakeForms && !authUser?.billing_plan?.intake_forms) ||
    (assessments && !authUser?.billing_plan?.assessments) ||
    (notes && !authUser?.billing_plan?.notes) ||
    (patientFiles && !authUser?.billing_plan?.patient_files)
  ) {
    return null;
  }

  return children;
};

export default BillingPlanFeature;
