import { doPost } from '../fetch';

const inviteParticipant = async (body) => {
  const participants = await doPost('/b2c/okta/invite-participant', body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return participants;
};

export { inviteParticipant };
