import React, { Fragment } from 'react';
import classnames from 'classnames';
import styles from './Modal.module.scss';

const Modal = ({ children, className }) => (
  <Fragment>
    <div className={styles.background} />
    <div className={classnames(styles.container, className)}>{children}</div>
  </Fragment>
);

export default Modal;
