import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../common/Modal';
import styles from './SuperBillMessageModal.module.scss';
import ApprovalImg from '../../../img/approval.svg';
import SuccessImg from '../../../img/success-new.svg';

const SuperBillMessageModal = ({ type, children }) => {
  const chooseLogo = (type) => {
    switch (type) {
      case 'error':
        return (
          <img
            src={ApprovalImg}
            alt="Warning"
            data-testid="modal warning image"
          />
        );
      case 'success':
        return (
          <img
            src={SuccessImg}
            alt="Sucess"
            data-testid="modal success image"
          />
        );
      default:
        return (
          <img
            src={SuccessImg}
            alt="Sucess"
            data-testid="modal success image"
          />
        );
    }
  };

  return (
    <Modal
      className={`${styles.mainContainer} ${
        type === 'error' ? styles.errorModal : ''
      }`}
    >
      <div className={styles.content}>
        <div className={styles.container}>
          <div className={styles.warningImg}>{chooseLogo(type)}</div>
        </div>
        {children}
      </div>
    </Modal>
  );
};

SuperBillMessageModal.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
};

export default SuperBillMessageModal;
