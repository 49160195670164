import React from 'react';
import s from './StepCard.module.scss';
import SecondaryButton from '../../components/common/buttons/SecondaryButton';
import NumberFormat from 'react-number-format';

const StepCard = (props) => {
  const {
    step,
    head,
    text,
    btnText,
    onClick,
    oneLiner = false,
    isHeadAmount = false,
  } = props;

  return (
    <div className={s.root}>
      {step === 'Step 1' && (
        <>
          <span className={s.leftimg}></span>
          <span className={s.rightimg}></span>{' '}
        </>
      )}
      {step === 'Step 2' && (
        <>
          <span className={s.topright}></span>
          <span className={s.bottomleft}></span>{' '}
        </>
      )}
      {step === 'Step 3' && (
        <>
          <span className={s.topLeft}></span>
          <span className={s.topcenter}></span>
          <span className={s.bottomright}></span>{' '}
        </>
      )}
      <div className={s.content}>
        <div className={s.stepDiv}>
          {/* <p className={s.step}>{step}</p> */}
        </div>
        <div>
          <h3
            className={s.head}
            data-testid={`dashboard stepcard heading ${step}`}
          >
            {isHeadAmount ? (
              <NumberFormat
                value={head}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            ) : (
              head
            )}
          </h3>
          {text && (
            <p
              className={s.text}
              data-testid={`dashboard stepcard text ${step}`}
            >
              {text}{' '}
            </p>
          )}
        </div>
        <div className={s.button}>
          <SecondaryButton
            label={btnText}
            className={[
              s.btn,
              oneLiner || (!text && isHeadAmount) ? s.btnMargin : ' ',
            ].join(' ')}
            type="button"
            onClick={onClick}
            data-testid={`dashboard stepcard button ${step}`}
          />
        </div>
      </div>
    </div>
  );
};

export default StepCard;
