import React from 'react';
import { Cell, Grid } from 'react-foundation';
import { Link } from 'react-router-dom';
import styles from './NotFoundPage.module.scss';
import useIntl from '../../hooks/useIntl';
import messages from './messages';
import error404 from '../../img/404-error.svg';
import FDAStatement from '../../components/FDAStatement';

const NotFoundPage = () => {
  const { formatMessage } = useIntl();

  return (
    <Grid>
      <Cell
        small={12}
        medium={4}
        className={styles.centered}
        offsetOnMedium={2}
      >
        <img
          src={error404}
          className={styles['oro-error-img']}
          alt={formatMessage(messages.topImage)}
        />
      </Cell>
      <Cell small={12} medium={4} className={styles.centered}>
        <div>
          <h1>
            <b>{formatMessage(messages.title)}</b>
          </h1>
        </div>
        <div>
          <p className={styles['margin-bottom']}>
            <b>{formatMessage(messages.explanation)}</b>
          </p>
        </div>
        <div className={styles['center-link']}>
          <div>
            <Link to="/my-account/discoverv2">
              <span className={styles.link}>
                <b>{formatMessage(messages.discover)}</b>
              </span>
            </Link>
          </div>
        </div>
      </Cell>
      <FDAStatement />
    </Grid>
  );
};

export default NotFoundPage;
