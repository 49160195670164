import React, { useState, useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Cell } from 'react-foundation';

import styles from './LeftNav.module.scss';

import PrimaryButton from '../../common/buttons/PrimaryButton';
import SecondaryButton from '../../common/buttons/SecondaryButton';
import FieldDropdown from '../../common/fields/FieldDropdown';
import Loading from '../../common/Loading';
import Modal from '../../common/Modal';

import useIntl from '../../../hooks/useIntl';
import messages from './messages';
import AuthContext from '../../../context/AuthContext';
import { compareFun, addToDataLayer } from '../../../utils/common';
import { getFactMatchedDoctors } from '../../../api/doctor';

const LeftNav = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const { authUser } = useContext(AuthContext);

  const [showBookAppointment, setShowBookAppointment] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [doctorId, setDoctorId] = useState('');
  const [error, setError] = useState('');

  if (authUser.id && window.UserLeap) {
    window.UserLeap('setUserId', authUser.id);
  }
  const fetchDoctors = async () => {
    setIsLoading(true);
    const params = {
      patient_id: authUser.id,
      clinic_id: (authUser.invites && authUser.invites.clinic_id) || 0,
    };

    const { doctors } = await getFactMatchedDoctors(params);
    let clinicsName = doctors.map((doctor) => doctor);
    // sort doctors by alphbetical order
    clinicsName = clinicsName.sort((a, b) => {
      return compareFun(
        a.first_name.toLowerCase(),
        b.first_name.toLowerCase(),
        'Asc',
      );
    });
    const formattedDoctorList = clinicsName.map((doctor) => {
      return {
        label: `${doctor.first_name} ${
          doctor.last_name ? doctor.last_name : ''
        }`,
        value: `${doctor.id}`,
      };
    });
    setDoctorList(formattedDoctorList);
    setIsLoading(false);
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div className={styles.title} data-testid="patient username label">
            Hi {authUser.first_name}
          </div>
          <ul className={styles.menu}>
            <li>
              <NavLink exact to="/my-account" activeClassName={styles.active}>
                <span data-testid="patient Dashboard tab">Dashboard</span>
              </NavLink>
            </li>
            {process.env.REACT_APP_WELLNESS_FEATURE_FLAG === 'true' && (
              <li>
                <NavLink
                  exact
                  to="/my-account/wellness"
                  activeClassName={styles.active}
                >
                  <span data-testid="patient Wellness tab">Wellness</span>
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                exact
                to="/my-account/appointments"
                activeClassName={styles.active}
              >
                <span data-testid="patient Appointments tab">Appointments</span>
              </NavLink>
            </li>
            <li>
              <div className={styles.bookBtn}>
                <button
                  onClick={async () => {
                    setIsLoading(true);
                    await fetchDoctors();
                    setIsLoading(false);
                    setShowBookAppointment(true);
                    const eventData = {
                      event: 'ga4_event',
                      properties: {
                        event_name: 'book_appointment',
                        step_name: 'book_appointment_popup',
                        step_number: 1,
                        patient_id: authUser.id || '',
                      },
                    };
                    addToDataLayer(eventData);
                  }}
                >
                  <span data-testid="patient Book New Appointment tab">
                    Book New Appointment
                  </span>
                </button>
              </div>
            </li>
            <li>
              <NavLink
                exact
                to="/my-account/intake-forms"
                activeClassName={styles.active}
              >
                <span data-testid="patient Intake forms tab">
                  Forms and Assessments
                </span>
              </NavLink>
            </li>
            {authUser && !authUser.invitation_status && (
              <li>
                <NavLink
                  exact
                  to={
                    authUser?.whitelabelDetails?.assessment_key
                      ? `/my-account/discoverv2/${authUser.whitelabelDetails.assessment_key}`
                      : `/my-account/discoverv2`
                  }
                  activeClassName={styles.active}
                >
                  <span data-testid="patient Assessment tab">Assessment</span>
                </NavLink>
              </li>
            )}
            <li>
              <a>
                <span>Give Feedback</span>
              </a>
            </li>
          </ul>
          {showBookAppointment && (
            <Modal className={styles.bookAppointmentModal}>
              <div className={styles.content}>
                <div
                  className={styles.closeButton}
                  onClick={() => {
                    setShowBookAppointment(false);
                    setError('');
                    setDoctorId('');
                  }}
                  data-testid="BookNewAppointment.Patient.CloseButton"
                >
                  <img
                    src={require(`../../../img/close.svg`)}
                    alt="Close"
                    data-testid="BookNewAppointment.Patient.CloseButton.Img"
                  />
                </div>
                <h1>
                  <b data-testid="BookNewAppointment.Patient.ModalHeader">
                    Appointment With?
                  </b>
                </h1>
                <div className={styles.box}>
                  <p data-testid="BookNewAppointment.Patient.SelectProvider">
                    Please select provider to book appointment with
                  </p>
                </div>
                <Cell small={6} medium={3} className={styles.dropDown}>
                  <FieldDropdown
                    label=""
                    name="doctor"
                    errorText={error}
                    value={doctorId}
                    onChange={(e) => {
                      setError('');
                      setDoctorId(e.target.value);
                      const eventData = {
                        event: 'ga4_event',
                        properties: {
                          event_name: 'book_appointment',
                          step_name: 'select_physician',
                          step_number: 2,
                          patient_id: authUser.id || '',
                          clinician_id: e.target.value || '',
                        },
                      };
                      addToDataLayer(eventData);
                    }}
                    options={doctorList}
                    placeholder="Select Provider"
                    dataTestId="BookNewAppointment.Patient.ProviderDropdown"
                    isSearchable={true}
                  />
                </Cell>
                <PrimaryButton
                  label="Continue Appointment"
                  className={styles.primaryButton}
                  data-testid="BookNewAppointment.Patient.Button.Continue"
                  onClick={() => {
                    if (doctorId) {
                      const eventData = {
                        event: 'ga4_event',
                        properties: {
                          event_name: 'book_appointment',
                          step_name: 'continue_appointment',
                          step_number: 3,
                          patient_id: authUser.id || '',
                          clinician_id: doctorId || '',
                        },
                      };
                      addToDataLayer(eventData);
                      history.push(`/my-account/appointment/${doctorId}`);
                    } else {
                      setError('Please Select Physician');
                    }
                  }}
                />
                <SecondaryButton
                  label={formatMessage(messages.resetModalButtonSecondary)}
                  data-testid="BookNewAppointment.Patient.Button.Cancel"
                  className={styles.secondaryButton}
                  onClick={() => {
                    setShowBookAppointment(false);
                    setError('');
                    setDoctorId('');
                  }}
                />
              </div>
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};

export default LeftNav;
