import React, { useState, useEffect } from 'react';
import { Cell, Grid } from 'react-foundation';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import classnames from 'classnames';

import FieldInput from '../../../common/fields/FieldInput/FieldInput';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import useIntl from '../../../../hooks/useIntl';
import messages from './messages';

import FieldDropdown from '../../../common/fields/FieldDropdown';
import styles from './KAPIntakeForm.module.scss';
import CheckBox from '../../../common/inputs/CheckBox';
import Datepicker from '../../../common/Datepicker';
import RadioChildrenSelector from '../../../common/RadioChildrenSelector';

import FieldArrayItem from '../../../common/fields/FieldArrayItem';
import SecondaryButton from '../../../common/buttons/SecondaryButton';
import TagsInput from '../../../common/inputs/TagsInput';
import Loading from '../../../common/Loading';

import states from '../../../../utils/data/states.json';
import maritalStatuses from '../../../../utils/data/maritalStatuses.json';

import { saveIntakeForm1, updateIntakeForm1 } from '../../../../api/intakeForm';

const initialValues = {
  personalDetails: {
    name: '',
    address: '',
    postalCode: '',
    city: '',
    state: '',
  },
  contactDetails: {
    sendEmail: true,
  },
  otherDetails: {
    gender: 'male',
    isTransgender: false,
  },
  medicalEmergency: {},
  familyDoctor: {},
  familyDetails: {
    relationshipStatus: 'married',
    children: [
      {
        name: '',
        gender: '',
        age: '',
      },
    ],
  },
  insuranceDetails: {},
  allergies: [],
  currentMedications: [
    {
      dose: '',
      frequency: '',
      dateUsed: '',
    },
  ],
  recreationalDrugs: [
    {
      method: '',
      frequency: '',
      experience: '',
      dateUsed: '',
    },
  ],
  medicalIllness: [
    {
      illness: '',
      status: '',
    },
  ],
  hasPreviousPsychologicalCare: false,
  psychologicalCare: [
    {
      clinicians: '',
      date: '',
      difficulty: '',
    },
  ],
  hasPsychiatricMedications: false,
  psychiatricMedications: [
    {
      medicine: '',
      dose: '',
      effects: '',
    },
  ],
  hasHospitalized: false,
  hasSuicidalThoughts: false,
  sleepingHabits: '',
  difficultyFallingAsleep: false,
  poorSleep: false,
  awakeningDuringNight: false,
  isHappyWithWeight: false,
  hasLostGainedWeight: false,
  depressionLevel: 'No',
  hasAnxiety: false,
  hadAlchoholDrugDependency: false,
  hadPrescreptionDrugDependency: false,
  isPregnant: false,
  familyMemberBipolarDisorder: false,
  familyMemberAnxiety: false,
  familyMemberPTSD: false,
  familyMemberAnger: false,
  familyMemberOtherSubstance: false,
  familyMemberDepression: false,
  familyMemberViolence: false,
  familyMemberAlcoholAbuse: false,
  familyMemberSchizophrenia: false,
  familyMemberSuicide: false,
  hasFamilyMemberPsychatric: false,
  sleepingProblemSince: '',
  weight: '',
  height: '',
  depressionSince: '',
  anxietySince: '',
  alchoholPerWeek: '',
  alchoholPerNight: '',
  alchoholPerHour: '',
  recreationalDrugsFrequency: '',
  natureOfAlchoholDepenency: '',
  natureOfPrescreptionDependency: '',
  formOfBC: '',
  familyMemberTreatment: '',
  familyMemberPsychatricTreatment: '',
  natureOfTherapy: '',
  significantTrauma: '',
};

const childrenArrayFields = [
  { placeholder: 'Name', name: 'name' },
  { placeholder: 'Gender', name: 'gender' },
  { placeholder: 'Age', name: 'age' },
];
const currentMedicationsArrayFields = [
  { placeholder: 'Type', name: 'type' },
  { placeholder: 'Dose', name: 'dose' },
  { placeholder: 'Frequency', name: 'frequency' },
  { placeholder: 'Date Used', name: 'dateUsed' },
];
const recreationalDrugsArrayFields = [
  { placeholder: 'Type', name: 'type' },
  { placeholder: 'Dose', name: 'dose' },
  { placeholder: 'Frequency', name: 'frequency' },
  { placeholder: 'Date Used', name: 'dateUsed' },
];
const medicalIllnessArrayFields = [
  { placeholder: 'Illness', name: 'illness' },
  { placeholder: 'Current/Past', name: 'status' },
];
const psychologicalCareArrayFields = [
  { placeholder: 'Clinician(s)', name: 'clinicians' },
  { placeholder: 'Date', name: 'date' },
  { placeholder: 'Difficulty', name: 'difficulty' },
];
const psychiatricMedicationsArrayFields = [
  { placeholder: 'Medicine', name: 'medicine' },
  { placeholder: 'Dose', name: 'dose' },
  { placeholder: 'Effects', name: 'effects' },
];
const sleepingHabitTypes = [
  'Poor',
  'Unsatisfactory',
  'Satisfactory',
  'Good',
  'Very Good',
];
const radioYesNoLabels = ['Yes', 'No'];
const depressionLabels = [
  'Overwhelming Sadness',
  'Grief',
  'Depression',
  'None',
];

const KAPIntakeForm = ({
  id,
  history,
  patientId,
  doctorId,
  fact_form_id,
  nextBtnLabel,
  submitBtnLabel,
  backBtnLabel,
  onCancel,
  parentOnChange,
  isEditMode,
}) => {
  const { formatMessage } = useIntl();

  const [gender, setGender] = useState(0);
  const [isTransgender, setIsTransgenderGender] = useState(0);
  const [step, setStep] = useState(1);
  const [hasPreviousPsychologicalCare, setHasPreviousPsychologicalCare] =
    useState(1);
  const [hasPsychiatricMedications, setHasPsychiatricMedications] = useState(1);
  const [hasHospitalized, setHasHospitalized] = useState(1);
  const [hasSuicidalThoughts, setHasSuicidalThoughts] = useState(1);
  const [sleepingHabits, setSleepingHabits] = useState(1);
  const [difficultyFallingAsleep, setDifficultyFallingAsleep] = useState(1);
  const [awakeningDuringNight, setAwakeningDuringNight] = useState(1);
  const [poorSleep, setPoorSleep] = useState(1);
  const [isHappyWithWeight, setIsHappyWithWeight] = useState(1);
  const [hasLostGainedWeight, setHasLostGainedWeight] = useState(1);
  const [depressionLevel, setDepressionLevel] = useState(0);
  const [hasAnxiety, setHasAnxiety] = useState(1);
  const [hadAlchoholDrugDependency, setHadAlchoholDrugDependency] = useState(1);
  const [hadPrescreptionDrugDependency, setHadPrescreptionDrugDependency] =
    useState(1);
  const [isPregnant, setIsPregnant] = useState(1);
  const [familyMemberBipolarDisorder, setFamilyMemberBipolarDisorder] =
    useState(1);
  const [familyMemberAnxiety, setFamilyMemberAnxiety] = useState(1);
  const [familyMemberPTSD, setFamilyMemberPTSD] = useState(1);
  const [familyMemberAnger, setFamilyMemberAnger] = useState(1);
  const [familyMemberOtherSubstance, setFamilyMemberOtherSubstance] =
    useState(1);
  const [familyMemberDepression, setFamilyMemberDepression] = useState(1);
  const [familyMemberViolence, setFamilyMemberViolence] = useState(1);
  const [familyMemberAlcoholAbuse, setFamilyMemberAlcoholAbuse] = useState(1);
  const [familyMemberSchizophrenia, setFamilyMemberSchizophrenia] = useState(1);
  const [familyMemberSuicide, setFamilyMemberSuicide] = useState(1);
  const [hasFamilyMemberPsychatric, setHasFamilyMemberPsychatric] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    if (id) {
      try {
        await updateIntakeForm1(id, { ...values });
        history.push(`/my-account/intake-forms`);
      } catch (error) {}
    } else {
      setIsLoading(true);
      try {
        await saveIntakeForm1({
          patient_id: patientId,
          doctor_id: doctorId,
          fact_form_id,
          ...values,
        });
        history.push(`/my-account/intake-forms`);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (initialValues && isEditMode) {
      setIsTransgenderGender(initialValues.otherDetails.isTransgender ? 0 : 1);
      setHasPreviousPsychologicalCare(
        initialValues.hasPreviousPsychologicalCare ? 0 : 1,
      );
      setHasPsychiatricMedications(
        initialValues.hasPsychiatricMedications ? 0 : 1,
      );
      setHasHospitalized(initialValues.setHasHospitalized ? 0 : 1);
      setHasSuicidalThoughts(initialValues.hasSuicidalThoughts ? 0 : 1);
      setSleepingHabits(
        initialValues.sleepingHabits
          ? sleepingHabitTypes.indexOf(initialValues.sleepingHabits)
          : 1,
      );
      setDifficultyFallingAsleep(initialValues.difficultyFallingAsleep ? 0 : 1);
      setAwakeningDuringNight(initialValues.awakeningDuringNight ? 0 : 1);
      setPoorSleep(initialValues.poorSleep ? 0 : 1);
      setHasLostGainedWeight(initialValues.hasLostGainedWeight ? 0 : 1);
      setIsHappyWithWeight(initialValues.isHappyWithWeight ? 0 : 1);
      setDepressionLevel(
        initialValues.depressionLevel
          ? depressionLabels.indexOf(initialValues.depressionLevel)
          : 1,
      );
      setHasAnxiety(initialValues.hasAnxiety ? 0 : 1);
      setHadAlchoholDrugDependency(
        initialValues.hadAlchoholDrugDependency ? 0 : 1,
      );
      setHadPrescreptionDrugDependency(
        initialValues.hadPrescreptionDrugDependency ? 0 : 1,
      );
      setIsPregnant(initialValues.isPregnant ? 0 : 1);
      setFamilyMemberBipolarDisorder(
        initialValues.familyMemberBipolarDisorder ? 0 : 1,
      );
      setFamilyMemberAnxiety(initialValues.familyMemberAnxiety ? 0 : 1);
      setFamilyMemberPTSD(initialValues.familyMemberPTSD ? 0 : 1);
      setFamilyMemberAnger(initialValues.familyMemberAnger ? 0 : 1);
      setFamilyMemberOtherSubstance(
        initialValues.familyMemberOtherSubstance ? 0 : 1,
      );
      setFamilyMemberDepression(initialValues.familyMemberDepression ? 0 : 1);
      setFamilyMemberViolence(initialValues.familyMemberViolence ? 0 : 1);
      setFamilyMemberAlcoholAbuse(
        initialValues.familyMemberAlcoholAbuse ? 0 : 1,
      );
      setFamilyMemberSchizophrenia(
        initialValues.familyMemberSchizophrenia ? 0 : 1,
      );
      setFamilyMemberSuicide(initialValues.familyMemberSuicide ? 0 : 1);
      setHasFamilyMemberPsychatric(
        initialValues.hasFamilyMemberPsychatric ? 0 : 1,
      );
    }
  }, [isEditMode]);

  const onKeyPress = (event) => {
    if (event.which === 13) {
      event.preventDefault();
    }
  };
  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.form}>
      <Formik
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
        enableReinitialize
        onKeyPress={onKeyPress}
        initialValues={initialValues}
        render={({
          values,
          handleChange,
          errors,
          touched,
          isValid,
          setFieldValue,
          handleBlur,
        }) => {
          return (
            <Form data-testid="KAP-intake-form">
              <span className={styles.firstImage}></span>
              <span className={styles.secondImage}></span>
              <Grid>
                <Cell small={12} medium={12}>
                  <h2 className={styles.formTitle}>
                    {formatMessage(messages.formTitle)}
                  </h2>
                </Cell>
                <Cell small={12} medium={12}>
                  <h4 className={styles.formInfo}>
                    {formatMessage(messages.formInfo)}
                  </h4>
                </Cell>
                <Cell
                  small={12}
                  medium={12}
                  className={styles['step-container']}
                >
                  <h4 className={styles.formSubTitle}>
                    {formatMessage(messages.formSubTitle)}
                  </h4>
                  <h4 className={styles.stepInfo}>Step {step} of 2</h4>
                </Cell>
                {step === 1 && (
                  <>
                    <Cell small={12} medium={12}>
                      <h4 className={styles.section}>
                        {formatMessage(messages.personalDetails)}
                      </h4>
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(
                          messages.personalDetails.nameLable,
                        )}
                        placeholder="E.g., James"
                        errorText={
                          touched.personalDetails &&
                          touched.personalDetails.name &&
                          errors['personalDetails.name']
                        }
                        name="personalDetails.name"
                        value={values.personalDetails.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12}>
                      <FieldInput
                        label={formatMessage(
                          messages.personalDetails.addressLabel,
                        )}
                        placeholder="Street/PO Box"
                        errorText={
                          touched.personalDetails &&
                          touched.personalDetails.address &&
                          errors['personalDetails.address']
                        }
                        name="personalDetails.address"
                        value={values.personalDetails.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={6}>
                      <FieldInput
                        label={formatMessage(
                          messages.personalDetails.cityLabel,
                        )}
                        errorText={
                          touched.personalDetails &&
                          touched.personalDetails.city &&
                          errors['personalDetails.city']
                        }
                        name="personalDetails.city"
                        placeholder="City"
                        value={values.personalDetails.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={6} medium={3}>
                      <FieldDropdown
                        label={formatMessage(
                          messages.personalDetails.stateLabel,
                        )}
                        errorText={
                          touched.personalDetails &&
                          touched.personalDetails.state &&
                          errors['personalDetails.state']
                        }
                        name="personalDetails.state"
                        placeholder="State"
                        value={values.personalDetails.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={states}
                        dataTestId="KAPIntakeForm.States.Dropdown"
                      />
                    </Cell>
                    <Cell small={6} medium={3}>
                      <FieldInput
                        label={formatMessage(
                          messages.personalDetails.zipCodeLabel,
                        )}
                        errorText={
                          touched.personalDetails &&
                          touched.personalDetails.postalCode &&
                          errors['personalDetails.postalCode']
                        }
                        name="personalDetails.postalCode"
                        placeholder="E.g., 12345"
                        value={values.personalDetails.postalCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={12}>
                      <h4 className={styles.section}>
                        {formatMessage(messages.contactDetails)}
                      </h4>
                    </Cell>
                    <Cell small={12} medium={4}>
                      <FieldInput
                        label={formatMessage(
                          messages.contactDetails.homePhoneLabel,
                        )}
                        name="contactDetails.homePhone"
                        value={values.contactDetails.homePhone}
                        onChange={handleChange}
                        errorText={
                          touched.contactDetails &&
                          touched.contactDetails.homePhone &&
                          errors['contactDetails.homePhone']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={4}>
                      <FieldInput
                        label={formatMessage(
                          messages.contactDetails.cellPhoneLabel,
                        )}
                        errorText={
                          touched.contactDetails &&
                          touched.contactDetails.cellPhone &&
                          errors['contactDetails.cellPhone']
                        }
                        name="contactDetails.cellPhone"
                        value={values.contactDetails.cellPhone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={4}>
                      <FieldInput
                        label={formatMessage(
                          messages.contactDetails.workPhoneLabel,
                        )}
                        name="contactDetails.workPhone"
                        value={values.contactDetails.workPhone}
                        onChange={handleChange}
                        errorText={
                          touched.contactDetails &&
                          touched.contactDetails.workPhone &&
                          errors['contactDetails.workPhone']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(
                          messages.contactDetails.emailLabel,
                        )}
                        errorText={
                          touched.contactDetails &&
                          touched.contactDetails.email &&
                          errors.email
                        }
                        name="contactDetails.email"
                        placeholder="E.g., abc@xyz.com"
                        value={values.contactDetails.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell
                      small={12}
                      medium={12}
                      className={styles['checkboxBtn']}
                    >
                      <CheckBox
                        label={formatMessage(
                          messages.contactDetails.sendEmailLabel,
                        )}
                        name="contactDetails.sendEmail"
                        value={values.contactDetails.sendEmail}
                        isActive={values.contactDetails.sendEmail}
                        onClick={() => {
                          setFieldValue(
                            'contactDetails.sendEmail',
                            values.contactDetails.sendEmail ? false : true,
                          );
                        }}
                      />
                    </Cell>
                    <Cell small={12} medium={12}>
                      <h4 className={styles.section}>
                        {formatMessage(messages.otherDetails)}
                      </h4>
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(messages.otherDetails.SSIdLabel)}
                        name="otherDetails.ssId"
                        placeholder="Enter here"
                        value={values.otherDetails.ssId}
                        onChange={handleChange}
                        errorText={
                          touched.otherDetails &&
                          touched.otherDetails.ssId &&
                          errors['otherDetails.ssId']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={6}>
                      <FieldInput
                        label={formatMessage(messages.otherDetails.ageLabel)}
                        name="otherDetails.age"
                        placeholder="Enter here"
                        value={values.otherDetails.age}
                        onChange={handleChange}
                        errorText={
                          touched.otherDetails &&
                          touched.otherDetails.age &&
                          errors['otherDetails.age']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell
                      small={12}
                      medium={6}
                      className={styles['datepicker']}
                    >
                      <Datepicker
                        label={formatMessage(
                          messages.otherDetails.birthDateLabel,
                        )}
                        selected={
                          values.otherDetails.birthDate
                            ? new Date(values.otherDetails.birthDate)
                            : null
                        }
                        className="form-control"
                        name="otherDetails.birthDate"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        maxDate={new Date()}
                        placeholderText="MM/DD/YYYY"
                        dateFormat="MM/dd/yyyy"
                        onChange={(date) =>
                          setFieldValue('otherDetails.birthDate', date)
                        }
                        required={true}
                      />
                    </Cell>
                    <Cell small={12} medium={6} className={styles['radioBtn']}>
                      <h5> Your Gender </h5>
                      <RadioChildrenSelector
                        name="otherDetails.gender"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setGender(!value ? 0 : 1);
                          setFieldValue(
                            'otherDetails.gender',
                            !value ? 'male' : 'female',
                          );
                        }}
                        selected={gender}
                        direction="row"
                      >
                        <div className={styles['radio-buttons']}>
                          {formatMessage(messages.otherDetails.maleLabel)}
                        </div>
                        <div className={styles['radio-buttons']}>
                          {formatMessage(messages.otherDetails.femaleLabel)}
                        </div>
                      </RadioChildrenSelector>
                    </Cell>
                    <Cell small={12} medium={6} className={styles['radioBtn']}>
                      <h5> Do you identify as transgender?</h5>
                      <RadioChildrenSelector
                        name="otherDetails.isTransgender"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setIsTransgenderGender(!value ? 0 : 1);
                          setFieldValue(
                            'otherDetails.isTransgender',
                            !value ? 1 : 0,
                          );
                        }}
                        selected={isTransgender}
                        direction="row"
                      >
                        <div className={styles['radio-buttons']}>
                          {formatMessage(
                            messages.otherDetails.transgenderYesLable,
                          )}
                        </div>
                        <div className={styles['radio-buttons']}>
                          {formatMessage(
                            messages.otherDetails.transgenderNoLable,
                          )}
                        </div>
                      </RadioChildrenSelector>
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(
                          messages.otherDetails.educationLable,
                        )}
                        name="otherDetails.education"
                        placeholder="Enter here"
                        value={values.otherDetails.education}
                        onChange={handleChange}
                        errorText={
                          touched.otherDetails &&
                          touched.otherDetails.education &&
                          errors['otherDetails.education']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(
                          messages.otherDetails.currentOccupationLabel,
                        )}
                        name="otherDetails.currentOccupation"
                        placeholder="Enter here"
                        value={values.otherDetails.currentOccupation}
                        onChange={handleChange}
                        errorText={
                          touched.otherDetails &&
                          touched.otherDetails.currentOccupation &&
                          errors['otherDetails.currentOccupation']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={12}>
                      <h4 className={styles.section}>
                        {formatMessage(messages.medicalEmergency)}
                      </h4>
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(
                          messages.medicalEmergency.nameLable,
                        )}
                        placeholder="E.g., James"
                        name="medicalEmergency.name"
                        value={values.medicalEmergency.name}
                        onChange={handleChange}
                        errorText={
                          touched.medicalEmergency &&
                          touched.medicalEmergency.name &&
                          errors['medicalEmergency.name']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12}>
                      <FieldInput
                        label={formatMessage(
                          messages.medicalEmergency.addressLabel,
                        )}
                        placeholder="Enter Here"
                        name="medicalEmergency.address"
                        value={values.medicalEmergency.address}
                        onChange={handleChange}
                        errorText={
                          touched.medicalEmergency &&
                          touched.medicalEmergency.address &&
                          errors['medicalEmergency.address']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={6}>
                      <FieldInput
                        label={formatMessage(
                          messages.medicalEmergency.relationLabel,
                        )}
                        placeholder="Enter Here"
                        name="medicalEmergency.relation"
                        value={values.medicalEmergency.relation}
                        onChange={handleChange}
                        errorText={
                          touched.medicalEmergency &&
                          touched.medicalEmergency.relation &&
                          errors['medicalEmergency.relation']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={6}>
                      <FieldInput
                        label={formatMessage(
                          messages.medicalEmergency.phoneLabel,
                        )}
                        name="medicalEmergency.phone"
                        value={values.medicalEmergency.phone}
                        onChange={handleChange}
                        errorText={
                          touched.medicalEmergency &&
                          touched.medicalEmergency.phone &&
                          errors['medicalEmergency.phone']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={12}>
                      <h4 className={styles.section}>
                        {formatMessage(messages.familyDoctor)}
                      </h4>
                    </Cell>
                    <Cell small={12} medium={6}>
                      <FieldInput
                        label={formatMessage(messages.familyDoctor.nameLable)}
                        placeholder="E.g., James"
                        name="familyDoctor.name"
                        value={values.familyDoctor.name}
                        onChange={handleChange}
                        errorText={
                          touched.familyDoctor &&
                          touched.familyDoctor.name &&
                          errors['familyDoctor.name']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={6}>
                      <FieldInput
                        label={formatMessage(messages.familyDoctor.phoneLabel)}
                        name="familyDoctor.phone"
                        value={values.familyDoctor.phone}
                        onChange={handleChange}
                        errorText={
                          touched.familyDoctor &&
                          touched.familyDoctor.phone &&
                          errors['familyDoctor.phone']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={12}>
                      <h4 className={styles.section}>
                        {formatMessage(messages.familyDetails)}
                      </h4>
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldDropdown
                        label={formatMessage(
                          messages.familyDetails.relationshipStatusLabel,
                        )}
                        name="familyDetails.relationshipStatus"
                        placeholder="Marital Status"
                        value={values.familyDetails.relationshipStatus}
                        onChange={(e) =>
                          setFieldValue(
                            'familyDetails.relationshipStatus',
                            e.target.value,
                          )
                        }
                        options={maritalStatuses}
                        errorText={
                          touched.familyDetails &&
                          touched.familyDetails.relationshipStatus &&
                          errors['familyDetails.relationshipStatus']
                        }
                        onBlur={handleBlur}
                        dataTestId="KAPIntakeForm.FamilyDetails.RelationshipStatus.Dropdown"
                      />
                    </Cell>
                    <Cell small={12} medium={6}>
                      <FieldInput
                        label={formatMessage(messages.familyDetails.nameLable)}
                        placeholder="E.g., James"
                        name="familyDetails.spouseName"
                        value={values.familyDetails.spouseName}
                        onChange={handleChange}
                        errorText={
                          touched.familyDetails &&
                          touched.familyDetails.spouseName &&
                          errors['familyDetails.spouseName']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={6}>
                      <FieldInput
                        label={formatMessage(messages.familyDetails.phoneLabel)}
                        name="familyDetails.spousePhone"
                        value={values.familyDetails.spousePhone}
                        onChange={handleChange}
                        errorText={
                          touched.familyDetails &&
                          touched.familyDetails.spousePhone &&
                          errors['familyDetails.spousePhone']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <FieldArray
                      name="familyDetails.children"
                      render={({ remove, push }) => (
                        <FieldArrayItem
                          arrayName="familyDetails.children"
                          label={formatMessage(
                            messages.familyDetails.childrenLabel,
                          )}
                          remove={remove}
                          push={push}
                          items={values.familyDetails.children}
                          fields={childrenArrayFields}
                          maxLength={5}
                        />
                      )}
                    />
                    <Cell small={12} medium={12}>
                      <h4 className={styles.section}>
                        {formatMessage(messages.insuranceDetails)}
                      </h4>
                    </Cell>
                    <Cell small={12} medium={8}>
                      <FieldInput
                        label={formatMessage(
                          messages.insuranceDetails.companyNameLabel,
                        )}
                        placeholder="Enter Here"
                        name="insuranceDetails.companyName"
                        value={values.insuranceDetails.companyName}
                        onChange={handleChange}
                        errorText={
                          touched.insuranceDetails &&
                          touched.insuranceDetails.companyName &&
                          errors['insuranceDetails.companyName']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={4}>
                      <FieldInput
                        label={formatMessage(
                          messages.insuranceDetails.insuranceIdLable,
                        )}
                        placeholder="Enter Here"
                        name="insuranceDetails.insuranceId"
                        value={values.insuranceDetails.insuranceId}
                        onChange={handleChange}
                        errorText={
                          touched.insuranceDetails &&
                          touched.insuranceDetails.insuranceId &&
                          errors['insuranceDetails.insuranceId']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={5}>
                      <FieldInput
                        label={formatMessage(
                          messages.insuranceDetails.groupNameLabel,
                        )}
                        placeholder="Enter Here"
                        name="insuranceDetails.groupName"
                        value={values.insuranceDetails.groupName}
                        onChange={handleChange}
                        errorText={
                          touched.insuranceDetails &&
                          touched.insuranceDetails.groupName &&
                          errors['insuranceDetails.groupName']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={3}>
                      <FieldInput
                        label={formatMessage(
                          messages.insuranceDetails.groupIdLabel,
                        )}
                        placeholder="Enter Here"
                        name="insuranceDetails.groupId"
                        value={values.insuranceDetails.groupId}
                        onChange={handleChange}
                        errorText={
                          touched.insuranceDetails &&
                          touched.insuranceDetails.groupId &&
                          errors['insuranceDetails.groupId']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={4}>
                      <FieldInput
                        label={formatMessage(
                          messages.insuranceDetails.employerLabel,
                        )}
                        placeholder="Enter Here"
                        name="insuranceDetails.employer"
                        value={values.insuranceDetails.employer}
                        onChange={handleChange}
                        errorText={
                          touched.insuranceDetails &&
                          touched.insuranceDetails.employer &&
                          errors['insuranceDetails.employer']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={5}>
                      <FieldInput
                        label={formatMessage(
                          messages.insuranceDetails.subscriberNameLabel,
                        )}
                        placeholder="Enter Here"
                        name="insuranceDetails.subscriberName"
                        value={values.insuranceDetails.subscriberName}
                        onChange={handleChange}
                        errorText={
                          touched.insuranceDetails &&
                          touched.insuranceDetails.subscriberName &&
                          errors['insuranceDetails.subscriberName']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={3}>
                      <FieldInput
                        label={formatMessage(
                          messages.insuranceDetails.relationLabel,
                        )}
                        placeholder="Enter Here"
                        name="insuranceDetails.subscriberRelation"
                        value={values.insuranceDetails.subscriberRelation}
                        onChange={handleChange}
                        errorText={
                          touched.insuranceDetails &&
                          touched.insuranceDetails.subscriberRelation &&
                          errors['insuranceDetails.subscriberRelation']
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={4}>
                      <Datepicker
                        label={formatMessage(
                          messages.insuranceDetails.subscriberDOBLabel,
                        )}
                        selected={
                          values.insuranceDetails.subscriberDOB
                            ? new Date(values.insuranceDetails.subscriberDOB)
                            : null
                        }
                        className="form-control"
                        name="insuranceDetails.subscriberDOB"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        maxDate={new Date()}
                        placeholderText="MM/DD/YYYY"
                        dateFormat="MM/dd/yyyy"
                        onChange={(date) =>
                          setFieldValue('insuranceDetails.subscriberDOB', date)
                        }
                        required={true}
                      />
                    </Cell>
                    <Cell
                      small={12}
                      medium={12}
                      className={styles['checkboxBtn']}
                    >
                      <CheckBox
                        label={formatMessage(messages.certifyCheckboxLabel)}
                        name="isCertify"
                        value={values.isCertify}
                        isActive={values.isCertify}
                        onClick={() => {
                          setFieldValue(
                            'isCertify',
                            values.isCertify ? false : true,
                          );
                        }}
                      />
                    </Cell>
                    <Cell
                      small={12}
                      flexContainer
                      alignY="middle"
                      flexDirCol="all"
                    >
                      {nextBtnLabel && (
                        <PrimaryButton
                          disabled={
                            !values.isCertify ||
                            Object.entries(errors).length > 12
                          } // TODO this need to be optimize
                          label={nextBtnLabel}
                          type="button"
                          className="font-18"
                          onClick={() => setStep(2)}
                        />
                      )}
                    </Cell>
                  </>
                )}
                {step === 2 && (
                  <React.Fragment>
                    <span className={styles.thirdImage}></span>
                    <span className={styles.fourthImage}></span>

                    <FieldArray
                      name="allergies"
                      render={({ remove, push }) => (
                        <TagsInput
                          placeholder="Add allergy"
                          arrayName="allergies"
                          label={formatMessage(messages.allergiesTagsLabel)}
                          remove={remove}
                          push={push}
                          items={values.allergies}
                          required={false}
                        />
                      )}
                    />
                    <hr />
                    <Cell small={12} medium={12}>
                      <h3> {formatMessage(messages.medicationLabel)} </h3>
                    </Cell>
                    <FieldArray
                      name="currentMedications"
                      render={({ remove, push }) => (
                        <FieldArrayItem
                          arrayName="currentMedications"
                          label={formatMessage(
                            messages.currentMedicationsLabel,
                          )}
                          remove={remove}
                          push={push}
                          items={values.currentMedications}
                          fields={currentMedicationsArrayFields}
                        />
                      )}
                    />
                    <hr />
                    <Cell small={12} medium={12}>
                      <h3>
                        {' '}
                        {formatMessage(messages.recreationalDrugString)}{' '}
                      </h3>
                    </Cell>
                    <FieldArray
                      name="recreationalDrugs"
                      render={({ remove, push }) => (
                        <FieldArrayItem
                          arrayName="recreationalDrugs"
                          label={formatMessage(messages.recreationalDrugsLabel)}
                          remove={remove}
                          push={push}
                          items={values.recreationalDrugs}
                          fields={recreationalDrugsArrayFields}
                        />
                      )}
                    />
                    <hr />
                    <Cell small={12} medium={12}>
                      <h3> {formatMessage(messages.medicalIllnessString)} </h3>
                    </Cell>
                    <FieldArray
                      name="medicalIllness"
                      render={({ remove, push }) => (
                        <FieldArrayItem
                          arrayName="medicalIllness"
                          label={formatMessage(messages.medicalIllnessLabel)}
                          remove={remove}
                          push={push}
                          items={values.medicalIllness}
                          fields={medicalIllnessArrayFields}
                        />
                      )}
                    />
                    <hr />
                    <Cell small={12} medium={12} className={styles['radioBtn']}>
                      <h5>
                        {' '}
                        {formatMessage(messages.psychologicalCareRadioLabel)}
                      </h5>
                      <RadioChildrenSelector
                        name="hasPreviousPsychologicalCare"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setHasPreviousPsychologicalCare(!value ? 0 : 1);
                          setFieldValue(
                            'hasPreviousPsychologicalCare',
                            !value ? 1 : 0,
                          );
                        }}
                        selected={hasPreviousPsychologicalCare}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    {hasPreviousPsychologicalCare === 0 && (
                      <>
                        <Cell small={12} medium={12}>
                          <h3>
                            {' '}
                            {formatMessage(
                              messages.psychologicalCareDetailsLabel,
                            )}
                          </h3>
                        </Cell>
                        <FieldArray
                          name="psychologicalCare"
                          render={({ remove, push }) => (
                            <FieldArrayItem
                              arrayName="psychologicalCare"
                              label="Psychological Care"
                              remove={remove}
                              push={push}
                              items={values.psychologicalCare}
                              fields={psychologicalCareArrayFields}
                              required={
                                !hasPreviousPsychologicalCare ? true : false
                              }
                            />
                          )}
                        />
                      </>
                    )}
                    <hr />
                    <Cell small={12} medium={12} className={styles['radioBtn']}>
                      <h5>
                        {' '}
                        {formatMessage(
                          messages.psychiatricMedicationRadioLabel,
                        )}
                      </h5>
                      <RadioChildrenSelector
                        name="hasPsychiatricMedications"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setHasPsychiatricMedications(!value ? 0 : 1);
                          setFieldValue(
                            'hasPsychiatricMedications',
                            !value ? 1 : 0,
                          );
                        }}
                        selected={hasPsychiatricMedications}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    {hasPsychiatricMedications === 0 && (
                      <>
                        <Cell small={12} medium={12}>
                          <h3>
                            {' '}
                            {formatMessage(
                              messages.psychiatricMedicationDetailsLabel,
                            )}{' '}
                          </h3>
                        </Cell>
                        <FieldArray
                          name="psychiatricMedications"
                          render={({ remove, push }) => (
                            <FieldArrayItem
                              arrayName="psychiatricMedications"
                              label="Psychiatric Medications"
                              remove={remove}
                              push={push}
                              items={values.psychiatricMedications}
                              fields={psychiatricMedicationsArrayFields}
                              required={
                                !hasPsychiatricMedications ? true : false
                              }
                            />
                          )}
                        />
                      </>
                    )}
                    <hr />
                    <Cell small={12} medium={12} className={styles['radioBtn']}>
                      <h5>
                        {' '}
                        {formatMessage(
                          messages.psychologicalDifficultyRadioLabel,
                        )}{' '}
                      </h5>
                      <RadioChildrenSelector
                        name="hasHospitalized"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setHasHospitalized(!value ? 0 : 1);
                          setFieldValue('hasHospitalized', !value ? 1 : 0);
                        }}
                        selected={hasHospitalized}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    <hr />
                    <Cell small={12} medium={12} className={styles['radioBtn']}>
                      <h5>
                        {' '}
                        {formatMessage(
                          messages.suicidalThoughtsRadioLabel,
                        )}{' '}
                      </h5>
                      <RadioChildrenSelector
                        name="hasSuicidalThoughts"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setHasSuicidalThoughts(!value ? 0 : 1);
                          setFieldValue('hasSuicidalThoughts', !value ? 1 : 0);
                        }}
                        selected={hasSuicidalThoughts}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    <hr />
                    <Cell small={12} medium={12} className={styles['radioBtn']}>
                      <h5>
                        {' '}
                        {formatMessage(messages.sleepingHabitsRadioLabel)}{' '}
                      </h5>
                      <RadioChildrenSelector
                        name="sleepingHabits"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setSleepingHabits(value);
                          setFieldValue(
                            'sleepingHabits',
                            sleepingHabitTypes[value],
                          );
                        }}
                        selected={sleepingHabits}
                        direction="row"
                      >
                        {sleepingHabitTypes.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    <Cell small={12} medium={6} className={styles['radioBtn']}>
                      <h5>
                        {' '}
                        {formatMessage(
                          messages.difficultyFallingAsleepRadioLabel,
                        )}{' '}
                      </h5>
                      <RadioChildrenSelector
                        name="difficultyFallingAsleep"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setDifficultyFallingAsleep(!value ? 0 : 1);
                          setFieldValue(
                            'difficultyFallingAsleep',
                            !value ? 1 : 0,
                          );
                        }}
                        selected={difficultyFallingAsleep}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    <Cell small={12} medium={6} className={styles['radioBtn']}>
                      <h5>
                        {' '}
                        {formatMessage(
                          messages.awakeningDuringNightRadioLabel,
                        )}{' '}
                      </h5>
                      <RadioChildrenSelector
                        name="awakeningDuringNight"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setAwakeningDuringNight(!value ? 0 : 1);
                          setFieldValue('awakeningDuringNight', !value ? 1 : 0);
                        }}
                        selected={awakeningDuringNight}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    <Cell small={12} medium={6} className={styles['radioBtn']}>
                      <h5> {formatMessage(messages.poorSleepRadioLabel)} </h5>
                      <RadioChildrenSelector
                        name="poorSleep"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setPoorSleep(!value ? 0 : 1);
                          setFieldValue('poorSleep', !value ? 1 : 0);
                        }}
                        selected={poorSleep}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    {poorSleep === 0 && (
                      <>
                        <Cell
                          small={12}
                          medium={12}
                          className={styles['label-bold']}
                        >
                          <FieldInput
                            label={formatMessage(messages.sleepingProblemLabel)}
                            placeholder="Enter Here"
                            name="sleepingProblemSince"
                            value={values.sleepingProblemSince}
                            onChange={handleChange}
                            errorText={
                              touched.sleepingProblemSince &&
                              errors.sleepingProblemSince
                            }
                            onBlur={handleBlur}
                          />
                        </Cell>
                      </>
                    )}
                    <hr />
                    <Cell small={12} medium={6}>
                      <FieldInput
                        label={formatMessage(messages.currentWeightLabel)}
                        placeholder="Enter Here"
                        name="weight"
                        value={values.weight}
                        onChange={handleChange}
                        errorText={touched.weight && errors.weight}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={6}>
                      <FieldInput
                        label={formatMessage(messages.currentHeightLabel)}
                        placeholder="Enter Here"
                        name="height"
                        value={values.height}
                        onChange={handleChange}
                        errorText={touched.height && errors.height}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={6} className={styles['radioBtn']}>
                      <h5> Are you happy with your weight?</h5>
                      <RadioChildrenSelector
                        name="isHappyWithWeight"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setIsHappyWithWeight(!value ? 0 : 1);
                          setFieldValue('isHappyWithWeight', !value ? 1 : 0);
                        }}
                        selected={isHappyWithWeight}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    <Cell small={12} medium={6} className={styles['radioBtn']}>
                      <h5> Have you recently lost or gained weight?</h5>
                      <RadioChildrenSelector
                        name="hasLostGainedWeight"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setHasLostGainedWeight(!value ? 0 : 1);
                          setFieldValue('hasLostGainedWeight', !value ? 1 : 0);
                        }}
                        selected={hasLostGainedWeight}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    <Cell
                      small={12}
                      medium={12}
                      className={styles['label-bold']}
                    >
                      <FieldInput
                        label="Please describe any difficulties you experience with your appetite or eating patterns."
                        placeholder="Enter Here"
                        name="difficultyWithAppetite"
                        value={values.difficultyWithAppetite}
                        onChange={handleChange}
                        errorText={
                          touched.difficultyWithAppetite &&
                          errors.difficultyWithAppetite
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <hr />
                    <Cell small={12} medium={12} className={styles['radioBtn']}>
                      <h5> Are you currently experiencing?</h5>
                      <RadioChildrenSelector
                        name="depressionLevel"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setDepressionLevel(value);
                          setFieldValue(
                            'depressionLevel',
                            depressionLabels[value],
                          );
                        }}
                        selected={depressionLevel}
                        direction="row"
                      >
                        {depressionLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    <Cell
                      small={12}
                      medium={12}
                      className={styles['label-bold']}
                    >
                      <FieldInput
                        label="If yes, for how long?"
                        placeholder="Enter Here"
                        name="depressionSince"
                        value={values.depressionSince}
                        onChange={handleChange}
                        errorText={
                          touched.depressionSince && errors.depressionSince
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <hr />
                    <Cell small={12} medium={12} className={styles['radioBtn']}>
                      <h5>
                        {' '}
                        Are you currently experiencing anxiety, or panic
                        attacks?
                      </h5>
                      <RadioChildrenSelector
                        name="hasAnxiety"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setHasAnxiety(!value ? 0 : 1);
                          setFieldValue('hasAnxiety', !value ? 1 : 0);
                        }}
                        selected={hasAnxiety}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    {hasAnxiety === 0 && (
                      <>
                        <Cell
                          small={12}
                          medium={12}
                          className={styles['label-bold']}
                        >
                          <FieldInput
                            label="If yes, for how long?"
                            placeholder="Enter Here"
                            name="anxietySince"
                            value={values.anxietySince}
                            onChange={handleChange}
                            errorText={
                              touched.anxietySince && errors.anxietySince
                            }
                            onBlur={handleBlur}
                          />
                        </Cell>
                      </>
                    )}
                    <hr />
                    <Cell
                      small={12}
                      medium={12}
                      className={styles['label-bold']}
                    >
                      <FieldInput
                        label="How many days per week do you drink alcohol?"
                        placeholder="Enter Here"
                        name="alchoholPerWeek"
                        value={values.alchoholPerWeek}
                        onChange={handleChange}
                        errorText={
                          touched.alchoholPerWeek && errors.alchoholPerWeek
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell
                      small={12}
                      medium={12}
                      className={styles['label-bold']}
                    >
                      <FieldInput
                        label="How many drinks per night?"
                        placeholder="Enter Here"
                        name="alchoholPerNight"
                        value={values.alchoholPerNight}
                        onChange={handleChange}
                        errorText={
                          touched.alchoholPerNight && errors.alchoholPerNight
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell
                      small={12}
                      medium={12}
                      className={styles['label-bold']}
                    >
                      <FieldInput
                        label="How often do you binge drink (4-5 drinks within a couple hours)?"
                        placeholder="Enter Here"
                        name="alchoholPerHour"
                        value={values.alchoholPerHour}
                        onChange={handleChange}
                        errorText={
                          touched.alchoholPerHour && errors.alchoholPerHour
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <hr />
                    <Cell
                      small={12}
                      medium={12}
                      className={styles['label-bold']}
                    >
                      <FieldInput
                        label="How often do you use recreational drugs?"
                        placeholder="Enter Here"
                        name="recreationalDrugsFrequency"
                        value={values.recreationalDrugsFrequency}
                        onChange={handleChange}
                        errorText={
                          touched.recreationalDrugsFrequency &&
                          errors.recreationalDrugsFrequency
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <hr />
                    <Cell small={12} medium={12} className={styles['radioBtn']}>
                      <h5>
                        {' '}
                        Have you ever had a dependency on alcohol or
                        recreational drugs?
                      </h5>
                      <RadioChildrenSelector
                        name="hadAlchoholDrugDependency"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setHadAlchoholDrugDependency(!value ? 0 : 1);
                          setFieldValue(
                            'hadAlchoholDrugDependency',
                            !value ? 1 : 0,
                          );
                        }}
                        selected={hadAlchoholDrugDependency}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    {hadAlchoholDrugDependency === 0 && (
                      <>
                        <Cell
                          small={12}
                          medium={12}
                          className={styles['label-bold']}
                        >
                          <FieldInput
                            label="If yes, please explain the nature of dependency or abuse."
                            placeholder="Enter Here"
                            name="natureOfAlchoholDepenency"
                            value={values.natureOfAlchoholDepenency}
                            onChange={handleChange}
                            errorText={
                              touched.natureOfAlchoholDepenency &&
                              errors.natureOfAlchoholDepenency
                            }
                            onBlur={handleBlur}
                          />
                        </Cell>
                      </>
                    )}
                    <hr />
                    <Cell small={12} medium={12} className={styles['radioBtn']}>
                      <h5>
                        {' '}
                        Have you ever or do you now have a history of
                        prescription drug dependency or abuse?
                      </h5>
                      <RadioChildrenSelector
                        name="hadPrescreptionDrugDependency"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setHadPrescreptionDrugDependency(!value ? 0 : 1);
                          setFieldValue(
                            'hadPrescreptionDrugDependency',
                            !value ? 1 : 0,
                          );
                        }}
                        selected={hadPrescreptionDrugDependency}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    {hadPrescreptionDrugDependency === 0 && (
                      <>
                        <Cell
                          small={12}
                          medium={12}
                          className={styles['label-bold']}
                        >
                          <FieldInput
                            label="If yes, which drug? Please explain the nature of dependency or abuse."
                            placeholder="Enter Here"
                            name="natureOfPrescreptionDependency"
                            value={values.natureOfPrescreptionDependency}
                            onChange={handleChange}
                            errorText={
                              touched.natureOfPrescreptionDependency &&
                              errors.natureOfPrescreptionDependency
                            }
                            onBlur={handleBlur}
                          />
                        </Cell>
                      </>
                    )}
                    <hr />
                    <Cell small={12} medium={12}>
                      <h4 className={classnames('mt-none', styles.section)}>
                        For Females
                      </h4>
                    </Cell>
                    <Cell small={12} medium={12} className={styles['radioBtn']}>
                      <h5> {formatMessage(messages.isPregnantLabel)}</h5>
                      <RadioChildrenSelector
                        name="isPregnant"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setIsPregnant(!value ? 0 : 1);
                          setFieldValue('isPregnant', !value ? 1 : 0);
                        }}
                        selected={isPregnant}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    <Cell
                      small={12}
                      medium={12}
                      className={styles['label-bold']}
                    >
                      <FieldInput
                        label="If applicable, what form of BC do you use?"
                        placeholder="Enter Here"
                        name="formOfBC"
                        value={values.formOfBC}
                        onChange={handleChange}
                        errorText={touched.formOfBC && errors.formOfBC}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <hr />
                    <Cell small={12} medium={12} className={styles['radioBtn']}>
                      <h5>
                        {' '}
                        Has anyone in your family been diagnosed with or treated
                        for:
                      </h5>
                    </Cell>
                    <Cell
                      small={6}
                      medium={3}
                      className={styles['family-treated-cell']}
                    >
                      Bipolar disorder
                    </Cell>
                    <Cell small={6} medium={3} className={styles['radioBtnNp']}>
                      <RadioChildrenSelector
                        name="familyMemberBipolarDisorder"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setFamilyMemberBipolarDisorder(!value ? 0 : 1);
                          setFieldValue(
                            'familyMemberBipolarDisorder',
                            !value ? 1 : 0,
                          );
                        }}
                        selected={familyMemberBipolarDisorder}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    <Cell
                      small={6}
                      medium={3}
                      className={styles['family-treated-cell']}
                    >
                      Anxiety
                    </Cell>
                    <Cell small={6} medium={3} className={styles['radioBtnNp']}>
                      <RadioChildrenSelector
                        name="familyMemberAnxiety"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setFamilyMemberAnxiety(!value ? 0 : 1);
                          setFieldValue('familyMemberAnxiety', !value ? 1 : 0);
                        }}
                        selected={familyMemberAnxiety}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    <Cell
                      small={6}
                      medium={3}
                      className={styles['family-treated-cell']}
                    >
                      PTSD
                    </Cell>
                    <Cell small={6} medium={3} className={styles['radioBtnNp']}>
                      <RadioChildrenSelector
                        name="familyMemberPTSD"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setFamilyMemberPTSD(!value ? 0 : 1);
                          setFieldValue('familyMemberPTSD', !value ? 1 : 0);
                        }}
                        selected={familyMemberPTSD}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    <Cell
                      small={6}
                      medium={3}
                      className={styles['family-treated-cell']}
                    >
                      Anger
                    </Cell>
                    <Cell small={6} medium={3} className={styles['radioBtnNp']}>
                      <RadioChildrenSelector
                        name="familyMemberAnger"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setFamilyMemberAnger(!value ? 0 : 1);
                          setFieldValue('familyMemberAnger', !value ? 1 : 0);
                        }}
                        selected={familyMemberAnger}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>

                    <Cell
                      small={6}
                      medium={3}
                      className={styles['family-treated-cell']}
                    >
                      Other substance abuse
                    </Cell>
                    <Cell small={6} medium={3} className={styles['radioBtnNp']}>
                      <RadioChildrenSelector
                        name="familyMemberOtherSubstance"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setFamilyMemberOtherSubstance(!value ? 0 : 1);
                          setFieldValue(
                            'familyMemberOtherSubstance',
                            !value ? 1 : 0,
                          );
                        }}
                        selected={familyMemberOtherSubstance}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    <Cell
                      small={6}
                      medium={3}
                      className={styles['family-treated-cell']}
                    >
                      Depression
                    </Cell>
                    <Cell small={6} medium={3} className={styles['radioBtnNp']}>
                      <RadioChildrenSelector
                        name="familyMemberDepression"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setFamilyMemberDepression(!value ? 0 : 1);
                          setFieldValue(
                            'familyMemberDepression',
                            !value ? 1 : 0,
                          );
                        }}
                        selected={familyMemberDepression}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>

                    <Cell
                      small={6}
                      medium={3}
                      className={styles['family-treated-container']}
                    >
                      Violence
                    </Cell>
                    <Cell small={6} medium={3} className={styles['radioBtnNp']}>
                      <RadioChildrenSelector
                        name="familyMemberViolence"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setFamilyMemberViolence(!value ? 0 : 1);
                          setFieldValue('familyMemberViolence', !value ? 1 : 0);
                        }}
                        selected={familyMemberViolence}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    <Cell
                      small={6}
                      medium={3}
                      className={styles['family-treated-cell']}
                    >
                      Alcohol abuse
                    </Cell>
                    <Cell small={6} medium={3} className={styles['radioBtnNp']}>
                      <RadioChildrenSelector
                        name="familyMemberAlcoholAbuse"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setFamilyMemberAlcoholAbuse(!value ? 0 : 1);
                          setFieldValue(
                            'familyMemberAlcoholAbuse',
                            !value ? 1 : 0,
                          );
                        }}
                        selected={familyMemberAlcoholAbuse}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>

                    <Cell
                      small={6}
                      medium={3}
                      className={styles['family-treated-cell']}
                    >
                      Schizophrenia
                    </Cell>
                    <Cell small={6} medium={3} className={styles['radioBtnNp']}>
                      <RadioChildrenSelector
                        name="familyMemberSchizophrenia"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setFamilyMemberSchizophrenia(!value ? 0 : 1);
                          setFieldValue(
                            'familyMemberSchizophrenia',
                            !value ? 1 : 0,
                          );
                        }}
                        selected={familyMemberSchizophrenia}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    <Cell
                      small={6}
                      medium={3}
                      className={styles['family-treated-cell']}
                    >
                      Suicide
                    </Cell>
                    <Cell small={6} medium={3} className={styles['radioBtnNp']}>
                      <RadioChildrenSelector
                        name="familyMemberSuicide"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setFamilyMemberSuicide(!value ? 0 : 1);
                          setFieldValue('familyMemberSuicide', !value ? 1 : 0);
                        }}
                        selected={familyMemberSuicide}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    <Cell
                      small={12}
                      medium={12}
                      className={styles['label-bold']}
                    >
                      <FieldInput
                        label="If yes, who had what problems?"
                        placeholder="Enter Here"
                        name="familyMemberTreatment"
                        value={values.familyMemberTreatment}
                        onChange={handleChange}
                        errorText={
                          touched.familyMemberTreatment &&
                          errors.familyMemberTreatment
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <hr />
                    <Cell small={12} medium={12} className={styles['radioBtn']}>
                      <h5>
                        {' '}
                        Has any family member been treated with a psychiatric
                        medication?
                      </h5>
                      <RadioChildrenSelector
                        name="hasFamilyMemberPsychatric"
                        handleOnChange={(value) => {
                          value === 0 && handleChange(null, true);
                          setHasFamilyMemberPsychatric(!value ? 0 : 1);
                          setFieldValue(
                            'hasFamilyMemberPsychatric',
                            !value ? 1 : 0,
                          );
                        }}
                        selected={hasFamilyMemberPsychatric}
                        direction="row"
                      >
                        {radioYesNoLabels.map((type, index) => (
                          <div key={index} className={styles['radio-buttons']}>
                            {type}
                          </div>
                        ))}
                      </RadioChildrenSelector>
                    </Cell>
                    {hasFamilyMemberPsychatric === 0 && (
                      <>
                        <Cell
                          small={12}
                          medium={12}
                          className={styles['label-bold']}
                        >
                          <FieldInput
                            label="If yes, who was treated, what medications, and how effective was the treatment?"
                            placeholder="Enter Here"
                            name="familyMemberPsychatricTreatment"
                            value={values.familyMemberPsychatricTreatment}
                            onChange={handleChange}
                            errorText={
                              touched.familyMemberPsychatricTreatment &&
                              errors.familyMemberPsychatricTreatment
                            }
                            onBlur={handleBlur}
                          />
                        </Cell>
                      </>
                    )}
                    <hr />
                    <Cell
                      small={12}
                      medium={12}
                      className={styles['label-bold']}
                    >
                      <FieldInput
                        label={formatMessage(messages.natureOfTherapyLabel)}
                        placeholder="Enter Here"
                        name="natureOfTherapy"
                        value={values.natureOfTherapy}
                        onChange={handleChange}
                        errorText={
                          touched.natureOfTherapy && errors.natureOfTherapy
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <hr />
                    <Cell
                      small={12}
                      medium={12}
                      className={styles['label-bold']}
                    >
                      <FieldInput
                        label={formatMessage(messages.significantTraumaLabel)}
                        placeholder="Enter Here"
                        name="significantTrauma"
                        value={values.significantTrauma}
                        onChange={handleChange}
                        errorText={
                          touched.significantTrauma && errors.significantTrauma
                        }
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell
                      small={12}
                      flexContainer
                      alignY="middle"
                      className={styles['actions-container']}
                    >
                      {backBtnLabel && (
                        <SecondaryButton
                          label={backBtnLabel}
                          className="font-18"
                          type="button"
                          onClick={() => setStep(1)}
                        />
                      )}
                      {submitBtnLabel && (
                        <PrimaryButton
                          disabled={Object.entries(errors).length > 0}
                          label={submitBtnLabel}
                          type="submit"
                          className="font-18"
                        />
                      )}
                    </Cell>
                  </React.Fragment>
                )}
              </Grid>
            </Form>
          );
        }}
      />
    </div>
  );
};

KAPIntakeForm.propTypes = {
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  nextBtnLabel: PropTypes.string,
  backBtnLabel: PropTypes.string,
  submitBtnLabel: PropTypes.string,
};

KAPIntakeForm.defaultProps = {};

export default KAPIntakeForm;
