import InnerHTML from "dangerously-set-html-content";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getConsentFormUrl,
  getSignatureTemplate,
  getConsentFormUrlClinician
} from "../../api/intakeForm";
import Loading from "../../components/common/Loading";
import { USER_TYPES } from "../../constants";
import AuthContext from "../../context/AuthContext";
import { HELLO_SIGN_CLIENT_ID } from "../../utils/envVariables";

const Signature = ({
  patientId,
  doctorId,
  fact_form_id,
  isType,
  role,
  templateName,
  formData,
}) => {
  const { authUser } = useContext(AuthContext);
  const { type } = useParams();

  const [signUrl, setSignUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [template, setTemplate] = useState();

  const getTemplate = async (url, requestId, result) => {
    const content = await getSignatureTemplate({
      requestId,
      signUrl: url,
      clientId: HELLO_SIGN_CLIENT_ID,
      patientId,
      doctorId,
      factFormId: fact_form_id,
      redirectUrl:
        authUser.profile_type === USER_TYPES.PATIENT
          ? process.env.REACT_APP_ESIGN_REDIRECT_URL
          : `/doctor/patient-details/${patientId}`,
      multiSigner: result ? result.multiSigner : false,
      doctorSignatureId: result ? result.doctorSignatureId : null,
      role: authUser.profile_type,
    });
    setTemplate(content);
  };

  const getSignURl = async () => {
    try {
      setIsLoading(true);
      if (authUser.profile_type === USER_TYPES.PATIENT) {
        const result = await getConsentFormUrl({
          email: authUser.email,
          name: authUser.first_name,
          role: role,
          templateName,
          doctorId
        });
        setSignUrl(result.signUrl);
        getTemplate(result.signUrl, result.requestId, result);
      }
      else {
        const result = await getConsentFormUrlClinician({
          doctor_signature_id: formData.consentform[0].doctor_signature_id
        });
        setSignUrl(result.signUrl);
        getTemplate(result.signUrl, formData.consentform[0].requestId, result);
      }
      setIsLoading(false);
    }
    catch(err) {
      setIsLoading(false);
      let errorMessage = 'Something go wrong with hellosign';

      if (err && err.message && err.message.toString()) {
        errorMessage = err.message.toString();
      }

      toast.error(errorMessage, {
        autoClose: 5000,
        hideProgressBar: true,
      });
    }
  };

  const OpenConsentForm = () => {
    return <InnerHTML html={template} />;
  };

  useEffect(() => {
    if (type === 'consentform' && authUser.first_name) getSignURl();
    if (isType) getSignURl();
  }, [type, isType]);

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && signUrl && OpenConsentForm()}
    </>
  );
};

export default Signature;
