import React, { useState, Fragment, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import Loading from '../../../components/common/Loading';
import DoctorLeftNav from '../../../components/nav/DoctorLeftNav';
import styles from './CalendlyAuthCallbackPage.module.scss';
import AuthContext from '../../../context/AuthContext';
import useIntl from '../../../hooks/useIntl';
import messages from './messages';

const CalendlyAuthCallbackPage = ({ match }) => {
  const { formatMessage } = useIntl();
  const { isLoading, authUser } = useContext(AuthContext);
  const [dataLoading] = useState(false);
  const isDataLoading = dataLoading || isLoading;

  const query = new URLSearchParams(useLocation().search);

  const generateAccessToken = async (code, id) => {};

  useEffect(() => {
    const code = query.get('code');
    const error = query.get('error');
    if ((code || error) && authUser.id) {
      generateAccessToken(code, authUser.id);
    }
  }, [authUser, query]);

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftNav, 'desktop-only')}>
        <DoctorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            ></div>
            <h1 data-testid={`calendly callback message`}>
              {formatMessage(messages.title)}
            </h1>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default CalendlyAuthCallbackPage;
