export const contactMethods = [
  {
    label: 'Home Phone',
    value: 'Home Phone',
  },
  {
    label: 'Mobile Phone',
    value: 'Mobile Phone',
  },
  {
    label: 'Email',
    value: 'Email',
  },
  {
    label: 'Text message to mobile',
    value: 'Text message to mobile',
  },
];

export const frequencyOptions = [
  {
    label: 'As needed',
    value: 'As needed',
  },
  {
    label: 'Once a day',
    value: 'Once a day',
  },
  {
    label: 'Twice a day',
    value: 'Twice a day',
  },
  {
    label: 'Three times a day',
    value: 'Three times a day',
  },
  {
    label: 'Four times a day',
    value: 'Four times a day',
  },
  {
    label: 'Five times a day',
    value: 'Five times a day',
  },
  {
    label: 'Every 4 hours',
    value: 'Every 4 hours',
  },
  {
    label: 'Every 3 hours',
    value: 'Every 3 hours',
  },
  {
    label: 'Every 2 hours',
    value: 'Every 2 hours',
  },
  {
    label: 'Hourly',
    value: 'Hourly',
  },
];

export const responseOptions = [
  'Never',
  'Once or Twice',
  'Monthly',
  'Weekly',
  'Daily or Almost Daily',
];

export const genderOptions = [
  {
    label: 'Female',
    value: 'Female',
  },
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Non-binary',
    value: 'Non-binary',
  },
  {
    label: 'Transgender F-M',
    value: 'Transgender F-M',
  },
  {
    label: 'Transgender M-F',
    value: 'Transgender M-F',
  },
];

export const alcoholAndDrugsOptions = [
  {
    label: 'Tobacco products (cigarettes, chewing tobacco, cigars, etc.)',
    id: 'tobacco',
  },
  {
    label: 'Alcoholic beverages (beer, wine, spirits, etc.)',
    id: 'alcoholic_beverages',
  },
  {
    label: 'Cannabis (marijuana, pot, grass, hash, etc.)',
    id: 'cannabis',
  },
  {
    label: 'Cocaine (coke, crack, etc.)',
    id: 'cocaine',
  },
  {
    label: 'Amphetamine type stimulants (Adderall, diet pills, speed, etc.)',
    id: 'amphetamine_simulants',
  },
  {
    label: 'Inhalants (nitrous, glue, petrol, paint thinner, etc.)',
    id: 'inhalants',
  },
  {
    label:
      'Sedatives or Sleeping Pills (GHB, Rohypnol, Serepax, Valium, Xanax etc.)',
    id: 'sedatives_pills',
  },
  {
    label: 'Psychedelics (Ketamine, LSD, MDMA, Psilocybin, PCP, etc.)',
    id: 'hallucinogens',
  },
  {
    label:
      'Opioids (codeine, fentanyl, heroin, kratom, methadone, morphine, etc.)',
    id: 'opioids',
  },
];
