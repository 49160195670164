import { PDFDownloadLink } from '@react-pdf/renderer';
import classnames from 'classnames';
import moment from 'moment';
import React, { Fragment } from 'react';
import useIntl from '../../../../hooks/useIntl';
import BPSIntakePDF from '../../../AssessmentPDF/BPSIntakePDF';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import Modal from '../../../common/Modal';
import styles from '../../../FormListView/FormListView.module.scss';
import messages from './messages';

const BPSFormResponse = ({ onClick, item, data, opened }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {opened && (
        <Modal className={styles.modal}>
          <div className={styles.content}>
            <div className={styles.closeButton} onClick={onClick}>
              <img src={require(`../../../../img/close.svg`)} alt={`Close`} />
            </div>
            <h1>
              <b>{item.form_name}</b>
            </h1>
            <div className={styles.box}>
              <div className={styles['content-detail']}>
                <div className={classnames([styles.headerSection])}>
                  <div>
                    <p className={styles.label}>Client Name</p>
                    <p>
                      {`${item.patient?.first_name || ''} ${
                        item.patient?.last_name || ''
                      }`}
                    </p>
                  </div>
                  <div>
                    <p className={styles.label}>Assessment</p>
                    <p>{item.form_name || ''}</p>
                  </div>
                  <div>
                    <p className={styles.label}>Date Completed</p>
                    <p>{moment(item.updatedAt).format('MMMM DD, YYYY')}</p>
                  </div>
                  <PDFDownloadLink
                    document={
                      <BPSIntakePDF
                        item={item}
                        formDetail={item.patient}
                        data={data}
                      />
                    }
                    fileName={`${item.form_name || 'form'}.pdf`}
                  >
                    <PrimaryButton
                      label={`Download PDF`}
                      type="button"
                      className={styles.btn}
                    />
                  </PDFDownloadLink>
                </div>
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles['content-detail']}>
                {item.patient && item.patient.id ? (
                  <Fragment>
                    <section className={styles['content-detail-section']}>
                      <div>
                        <span>{formatMessage(messages.formReference)}</span>
                        <p>{data.reference}</p>
                      </div>
                      <h2>{formatMessage(messages.medicalHistory)}</h2>
                      <div className={classnames('text', styles.column3)}>
                        <span>
                          {formatMessage(
                            messages.medicalHistory.primaryCarePhysician
                              .physicianName,
                          )}
                        </span>
                        <p>{data.physician_name}</p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>
                          {formatMessage(
                            messages.medicalHistory.primaryCarePhysician
                              .physicianPhone,
                          )}
                        </span>
                        <p>{data.physician_phone}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.medicalHistory.primaryCarePhysician
                              .healthCareProviders,
                          )}
                        </span>
                        <p>{data.health_care_providers}</p>
                      </div>
                      {data.health_care_providers_list &&
                        data.health_care_providers_list.length > 0 && (
                          <section className={styles['content-detail-section']}>
                            <span>
                              {formatMessage(
                                messages.medicalHistory.primaryCarePhysician,
                              )}
                            </span>
                            <div className={styles.detail}>
                              <div className={classnames(styles[`table`])}>
                                <div className={classnames(styles[`table-tr`])}>
                                  <div className={classnames('text')}>
                                    {formatMessage(
                                      messages.medicalHistory
                                        .primaryCarePhysician.providersName,
                                    )}
                                  </div>
                                  <div className={classnames('text')}>
                                    {formatMessage(
                                      messages.medicalHistory
                                        .primaryCarePhysician
                                        .providersSpecialty,
                                    )}
                                  </div>
                                  <div className={classnames('text')}>
                                    {formatMessage(
                                      messages.medicalHistory
                                        .primaryCarePhysician
                                        .providersPhoneNumber,
                                    )}
                                  </div>
                                </div>
                                {data?.health_care_providers_list &&
                                  data?.health_care_providers_list.map(
                                    (item, index) => (
                                      <div
                                        key={index}
                                        className={classnames(
                                          styles[`table-tr`],
                                        )}
                                      >
                                        <p>{item.name}</p>
                                        <p>{item.specialty}</p>
                                        <p>{item.phone}</p>
                                      </div>
                                    ),
                                  )}
                              </div>
                            </div>
                          </section>
                        )}
                      <div>
                        <span>
                          {formatMessage(
                            messages.medicalHistory.primaryCarePhysician
                              .contactProviders,
                          )}
                        </span>
                        <p>{data.providers_contact}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.medicalHistory.primaryCarePhysician
                              .medicallyHospitalized,
                          )}
                        </span>
                        <p>{data.medically_hospitalized}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.medicalHistory.primaryCarePhysician
                              .physicalExam,
                          )}
                        </span>
                        <p>
                          {!!data.physical_exam_date
                            ? moment(data.physical_exam_date).format(
                                'MMMM DD, YYYY',
                              )
                            : ''}
                        </p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.medicalHistory.primaryCarePhysician
                              .headInjury,
                          )}
                        </span>
                        <p>{data.head_injury}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.medicalHistory.primaryCarePhysician
                              .chestPain,
                          )}
                        </span>
                        <p>{data.chest_pain}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.medicalHistory.primaryCarePhysician
                              .personalHistory,
                          )}
                        </span>
                        <div className={styles.listContainer}>
                          {data?.personal_history &&
                            data?.personal_history.map(
                              (item, index) =>
                                item !== 'other' && <p key={index}>{item}</p>,
                            )}
                          <p>{data.personal_disease_input}</p>
                        </div>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.medicalHistory.primaryCarePhysician
                              .familyDiagnoses,
                          )}
                        </span>
                        <div className={styles.listContainer}>
                          {data?.family_diagnoses &&
                            data?.family_diagnoses.map(
                              (item, index) =>
                                item !== 'other' && <p key={index}>{item}</p>,
                            )}
                          <p>{data.family_diagnoses_input}</p>
                        </div>
                      </div>

                      <h2>{formatMessage(messages.currentWellbeing)}</h2>

                      <div>
                        <span>
                          {formatMessage(messages.currentWellbeing.visitReason)}
                        </span>
                        <p>{data.visit_reason}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.depressionSymptoms,
                          )}
                        </span>
                        <div className={styles.listContainer}>
                          {data?.depression_symptoms &&
                            data?.depression_symptoms.map((item, index) => (
                              <p key={index}>{item}</p>
                            ))}
                        </div>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsPeriod,
                          )}
                        </span>
                        <p>{data.depression_symptoms_period}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsCause,
                          )}
                        </span>
                        <p>{data.depression_symptoms_cause}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.affirmativeCause,
                          )}
                        </span>
                        <p>{data.depression_question_response}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.anxietySymptoms,
                          )}
                        </span>
                        <div className={styles.listContainer}>
                          {data?.anxiety_symptoms &&
                            data?.anxiety_symptoms.map((item, index) => (
                              <p key={index}>{item}</p>
                            ))}
                        </div>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.panicAttacks,
                          )}
                        </span>
                        <p>{data.panic_attacks}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.otherSymptom,
                          )}
                        </span>
                        <p>{data.other_anxiety_symptom}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsPeriod,
                          )}
                        </span>
                        <p>{data.anxiety_symptoms_period}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsCause,
                          )}
                        </span>
                        <p>{data.anxiety_symptoms_cause}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.affirmativeCause,
                          )}
                        </span>
                        <p>{data.anxiety_question_response}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.stressSymptoms,
                          )}
                        </span>
                        <div className={styles.listContainer}>
                          {data?.stress_symptoms &&
                            data?.stress_symptoms.map(
                              (item, index) =>
                                item !== 'other' && <p key={index}>{item}</p>,
                            )}
                        </div>
                        <p>{data.stress_symptoms_input}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsPeriod,
                          )}
                        </span>
                        <p>{data.traumatic_symptoms_period}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.traumaticEvents,
                          )}
                        </span>
                        <p>{data.traumatic_symptoms_cause}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.affirmativeCause,
                          )}
                        </span>
                        <p>{data.traumatic_question_response}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsCause,
                          )}
                        </span>
                        <p>{data.stress_symptoms_cause}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.affirmativeCause,
                          )}
                        </span>
                        <p>{data.stress_question_response}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.maniaSymptoms,
                          )}
                        </span>
                        <div className={styles.listContainer}>
                          {data?.mania_symptoms &&
                            data?.mania_symptoms.map(
                              (item, index) =>
                                item !== 'other' && <p key={index}>{item}</p>,
                            )}
                        </div>
                        <p>{data.mania_symptoms_input}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsPeriod,
                          )}
                        </span>
                        <p>{data.mania_symptoms_period}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsCause,
                          )}
                        </span>
                        <p>{data.mania_symptoms_cause}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.affirmativeCause,
                          )}
                        </span>
                        <p>{data.mania_question_response}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(messages.currentWellbeing.ocdSymptoms)}
                        </span>
                        <div className={styles.listContainer}>
                          {data?.ocd_symptoms &&
                            data?.ocd_symptoms.map(
                              (item, index) =>
                                item !== 'other' && <p key={index}>{item}</p>,
                            )}
                        </div>
                        <p>{data.ocd_symptoms_input}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsPeriod,
                          )}
                        </span>
                        <p>{data.ocd_symptoms_period}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsCause,
                          )}
                        </span>
                        <p>{data.ocd_symptoms_cause}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.affirmativeCause,
                          )}
                        </span>
                        <p>{data.ocd_question_response}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.behaviorConcernsSymptoms,
                          )}
                        </span>
                        <div className={styles.listContainer}>
                          {data?.behavior_concerns_symptoms &&
                            data?.behavior_concerns_symptoms.map(
                              (item, index) =>
                                item !== 'other' && <p key={index}>{item}</p>,
                            )}
                        </div>
                        <p>{data.behavior_concerns_symptoms_input}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsPeriod,
                          )}
                        </span>
                        <p>{data.behavior_concerns_symptoms_period}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsCause,
                          )}
                        </span>
                        <p>{data.behavior_concerns_symptoms_cause}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.affirmativeCause,
                          )}
                        </span>
                        <p>{data.behavior_concerns_question_response}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.attentionalIssuesSymptoms,
                          )}
                        </span>
                        <div className={styles.listContainer}>
                          {data?.attentional_issues_symptoms &&
                            data?.attentional_issues_symptoms.map(
                              (item, index) =>
                                item !== 'other' && <p key={index}>{item}</p>,
                            )}
                        </div>
                        <p>{data.attentional_issues_symptoms_input}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsPeriod,
                          )}
                        </span>
                        <p>{data.attentional_issues_symptoms_period}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsCause,
                          )}
                        </span>
                        <p>{data.attentional_issues_symptoms_cause}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.affirmativeCause,
                          )}
                        </span>
                        <p>{data.attentional_issues_question_response}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.eatingProblemsSymptoms,
                          )}
                        </span>
                        <div className={styles.listContainer}>
                          {data?.eating_problems_symptoms &&
                            data?.eating_problems_symptoms.map(
                              (item, index) =>
                                item !== 'other' && <p key={index}>{item}</p>,
                            )}
                        </div>
                        <p>{data.eating_problems_symptoms_input}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsPeriod,
                          )}
                        </span>
                        <p>{data.eating_problems_symptoms_period}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsCause,
                          )}
                        </span>
                        <p>{data.eating_problems_symptoms_cause}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.affirmativeCause,
                          )}
                        </span>
                        <p>{data.eating_problems_question_response}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.psychosisSymptoms,
                          )}
                        </span>
                        <div className={styles.listContainer}>
                          {data?.psychosis_symptoms &&
                            data?.psychosis_symptoms.map(
                              (item, index) =>
                                item !== 'other' && <p key={index}>{item}</p>,
                            )}
                        </div>
                        <p>{data.psychosis_symptoms_input}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsPeriod,
                          )}
                        </span>
                        <p>{data.psychosis_symptoms_period}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.symptomsCause,
                          )}
                        </span>
                        <p>{data.psychosis_symptoms_cause}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.currentWellbeing.affirmativeCause,
                          )}
                        </span>
                        <p>{data.psychosis_question_response}</p>
                      </div>

                      <h2>{formatMessage(messages.substanceUse)}</h2>

                      <div>
                        <span>
                          {formatMessage(
                            messages.substanceUse.substancesHistory,
                          )}
                        </span>
                        <p>{data.substance_history}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.substanceUse.drinkingOrDrugUse,
                          )}
                        </span>
                        <p>{data.drinking_or_drug_use}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(messages.substanceUse.useCriticizing)}
                        </span>
                        <p>{data.use_criticizing}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(messages.substanceUse.feltBad)}
                        </span>
                        <p>{data.felt_bad}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(messages.substanceUse.hangover)}
                        </span>
                        <p>{data.hangover}</p>
                      </div>
                      <h2>{formatMessage(messages.pastTreatment)}</h2>

                      <div>
                        <span>
                          {formatMessage(
                            messages.pastTreatment.psychiatricTreatment,
                          )}
                        </span>
                        <div className={styles.listContainer}>
                          {data?.psychiatric_treatment &&
                            data?.psychiatric_treatment.map((item, index) => (
                              <p key={index}>{item}</p>
                            ))}
                        </div>
                      </div>
                      {data.past_treatment_facilities &&
                        data.past_treatment_facilities.length > 0 && (
                          <section className={styles['content-detail-section']}>
                            <span>
                              {formatMessage(
                                messages.pastTreatment.pastFacility,
                              )}
                            </span>
                            <div className={styles.detail}>
                              <div className={classnames(styles[`table`])}>
                                <div className={classnames(styles[`table-tr`])}>
                                  <div className={classnames('text')}>
                                    {formatMessage(
                                      messages.pastTreatment.facilityName,
                                    )}
                                  </div>
                                  <div className={classnames('text')}>
                                    {formatMessage(
                                      messages.pastTreatment.treatmentDate,
                                    )}
                                  </div>
                                  <div className={classnames('text')}>
                                    {formatMessage(
                                      messages.pastTreatment.treatmentLength,
                                    )}
                                  </div>
                                  <div className={classnames('text')}>
                                    {formatMessage(
                                      messages.pastTreatment.issuesAddressed,
                                    )}
                                  </div>
                                </div>
                                {data?.past_treatment_facilities &&
                                  data?.past_treatment_facilities.map(
                                    (item, index) => (
                                      <div
                                        key={index}
                                        className={classnames(
                                          styles[`table-tr`],
                                        )}
                                      >
                                        <p>{item.facility_name}</p>
                                        <p>{item.treatment_date}</p>
                                        <p>{item.treatment_length}</p>
                                        <p>{item.issues_addressed}</p>
                                      </div>
                                    ),
                                  )}
                              </div>
                            </div>
                          </section>
                        )}
                      <div>
                        <span>
                          {formatMessage(
                            messages.pastTreatment.previousMentalConditions,
                          )}
                        </span>
                        <div className={styles.listContainer}>
                          {data?.previous_mental_conditions &&
                            data?.previous_mental_conditions.map(
                              (item, index) =>
                                item !== 'other' && <p key={index}>{item}</p>,
                            )}
                        </div>
                        <p>{data.previous_mental_conditions_input}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.pastTreatment.previousMedications,
                          )}
                        </span>
                        <p>{data.previous_medications}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.pastTreatment.familyMentalConditions,
                          )}
                        </span>
                        <div className={styles.listContainer}>
                          {data?.family_mental_conditions &&
                            data?.family_mental_conditions.map(
                              (item, index) =>
                                item !== 'other' && <p key={index}>{item}</p>,
                            )}
                        </div>
                        <p>{data.family_mental_conditions_input}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(messages.pastTreatment.familySuicide)}
                        </span>
                        <p>{data.family_suicide}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.pastTreatment.yourFamilyRelationship,
                          )}
                        </span>
                        <p>{data.your_family_relationship}</p>
                      </div>
                      <h2>{formatMessage(messages.familyAndSocialHistory)}</h2>
                      <div>
                        <span>
                          {formatMessage(
                            messages.familyAndSocialHistory.sexAtBirth,
                          )}
                        </span>
                        <p>{data.sex_at_birth}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.familyAndSocialHistory.gender,
                          )}
                        </span>
                        {data.gender_identity === 'non-conforming' ||
                        data.gender_identity === 'other' ? (
                          <p>{data.preferred_gender}</p>
                        ) : (
                          <p>{data.gender_identity}</p>
                        )}
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.familyAndSocialHistory.sexualOrientation,
                          )}
                        </span>
                        {data.sexual_orientation === 'other' ? (
                          <p>{data.other_sexual_orientation}</p>
                        ) : (
                          <p>{data.sexual_orientation}</p>
                        )}
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.familyAndSocialHistory.relationshipStatus,
                          )}
                        </span>
                        {data.relationship_status === 'other' ? (
                          <p>{data.other_relationship_status}</p>
                        ) : (
                          <p>{data.relationship_status}</p>
                        )}
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.familyAndSocialHistory.currentRelationship,
                          )}
                        </span>
                        {data.relationship_type === 'other' ? (
                          <p>{data.other_relationship_type}</p>
                        ) : (
                          <p>{data.relationship_type}</p>
                        )}
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.familyAndSocialHistory.nrOfChildren,
                          )}
                        </span>
                        <p>{data.nr_of_children}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.familyAndSocialHistory.nrOfPregnancies,
                          )}
                        </span>
                        <p>{data.nr_of_pregnancies}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.familyAndSocialHistory.nrOfAdoptions,
                          )}
                        </span>
                        <p>{data.nr_of_adoptions}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.familyAndSocialHistory.resideWith,
                          )}
                        </span>
                        {data.resident_type === 'other' ? (
                          <p>{data.other_resident_type}</p>
                        ) : (
                          <p>{data.resident_type}</p>
                        )}
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.familyAndSocialHistory.employmentStatus,
                          )}
                        </span>
                        <p>{data.employment_status}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.familyAndSocialHistory.sourceOfIncome,
                          )}
                        </span>
                        {data.income_source === 'other' ? (
                          <p>{data.other_income_sources}</p>
                        ) : (
                          <p>{data.income_source}</p>
                        )}
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.familyAndSocialHistory.emotionalSupport,
                          )}
                        </span>
                        {data.emotional_support === 'other' ? (
                          <p>{data.other_emotional_support}</p>
                        ) : (
                          <p>{data.emotional_support}</p>
                        )}
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.familyAndSocialHistory
                              .developmentalMilestones,
                          )}
                        </span>
                        <p>{data.developmental_milestones}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.familyAndSocialHistory.developmentalDelays,
                          )}
                        </span>
                        <p>{data.developmental_delays}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.familyAndSocialHistory.familyRelationship,
                          )}
                        </span>
                        <p>{data.family_relationship}</p>
                      </div>

                      <h2>{formatMessage(messages.legalHistory)}</h2>

                      <div>
                        <span>
                          {formatMessage(
                            messages.legalHistory.legalInvolvement,
                          )}
                        </span>
                        <p>{data.legal_involvement}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(messages.legalHistory.otherListLabel)}
                        </span>
                        <p>{data.other_legal_involvement}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(messages.legalHistory.legalStatus)}
                        </span>
                        <p>{data.legal_status}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.legalHistory.mentalHealthTreatment,
                          )}
                        </span>
                        <p>{data.mental_health_treatment}</p>
                      </div>

                      <h2>{formatMessage(messages.culturalHistory)}</h2>

                      <div>
                        <span>
                          {formatMessage(messages.culturalHistory.religionRole)}
                        </span>
                        <p>{data.religion_role}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.culturalHistory.religionPractices,
                          )}
                        </span>
                        <p>{data.religion_practices}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.culturalHistory.racialIdentity,
                          )}
                        </span>
                        <p>{data.racial_identity}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.culturalHistory.specificCulture,
                          )}
                        </span>
                        <p>{data.specific_culture}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(messages.culturalHistory.hobbies)}
                        </span>
                        <p>{data.hobbies}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(
                            messages.culturalHistory.mentalHealthResult,
                          )}
                        </span>
                        <p>{data.mental_health_result}</p>
                      </div>
                      <h2>{formatMessage(messages.assessment)}</h2>
                      <div>
                        <span>
                          {formatMessage(messages.assessment.therapyGoals)}
                        </span>
                        <p>{data.therapy_goals}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(messages.assessment.possibleBarriers)}
                        </span>
                        <p>{data.possible_barriers}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(messages.assessment.therapyNeeds)}
                        </span>
                        <p>{data.therapy_needs}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(messages.assessment.currentStrengths)}
                        </span>
                        <p>{data.current_strengths}</p>
                      </div>
                      <div>
                        <span>
                          {formatMessage(messages.assessment.therapistAdvice)}
                        </span>
                        <p>{data.therapist_advice}</p>
                      </div>
                    </section>
                  </Fragment>
                ) : (
                  <div>No Data Available</div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default BPSFormResponse;
