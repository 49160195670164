import { defineMessages } from 'react-intl';

export default defineMessages({
  formTitle: {
    id: 'components.PersonalInfoForm.formTitle',
    description: 'Title of the Form',
    defaultMessage: 'Patient Information Form',
  },
  formInfo: {
    id: 'components.PersonalInfoForm.formInfo',
    description: 'Information of the Form',
    defaultMessage:
      'Welcome to our practice. Please fill out the following information as completely as possible. This information is confidential.  You can also save and return to complete or edit this form later if necessary.',
  },
  formSubTitle: {
    id: 'components.PersonalInfoForm.formSubTitle',
    description: 'Sub Title of the Form',
    defaultMessage: 'Fill in your details',
  },
  personalDetails: {
    id: 'component.PersonalInfoForm.personalDetails',
    description: 'Text for personal details section',
    defaultMessage: 'Personal Details',
    firstNameLabel: {
      id: 'component.PersonalInfoForm.personalDetails.firstNameLabel',
      defaultMessage: 'First Name',
    },
    lastNameLabel: {
      id: 'component.PersonalInfoForm.personalDetails.lastNameLabel',
      defaultMessage: 'Last Name',
    },
    allergies: {
      id: 'component.PersonalInfoForm.personalDetails.allergies',
      defaultMessage: 'Allergies',
    },
    gender: {
      id: 'component.PersonalInfoForm.personalDetails.gender',
      defaultMessage: 'Gender',
    },
    pronouns: {
      id: 'component.PersonalInfoForm.personalDetails.pronouns',
      defaultMessage: 'Preferred Pronouns',
    },
    relationshipStatus: {
      id: 'component.PersonalInfoForm.personalDetails.relationshipStatus',
      defaultMessage: 'Relationship Status',
    },
    dateOfBirth: {
      id: 'component.PersonalInfoForm.personalDetails.dateOfBirth',
      defaultMessage: 'Date of Birth',
    },
  },
  emergencyContactInformation: {
    id: 'component.PersonalInfoForm.emergencyContactInformation',
    defaultMessage: 'Emergency Contact Information',
    contactName: {
      id: 'component.PersonalInfoForm.emergencyContactInformation.contactName',
      defaultMessage: 'Name of Emergency Contact',
    },
    contactRelationship: {
      id: 'component.PersonalInfoForm.emergencyContactInformation.contactRelationship',
      defaultMessage: 'Relationship to you',
    },
    contactPhone: {
      id: 'component.PersonalInfoForm.emergencyContactInformation.contactPhone',
      defaultMessage: 'Phone Number',
    },
    contactEmail: {
      id: 'component.PersonalInfoForm.emergencyContactInformation.contactEmail',
      defaultMessage: 'Email',
    },
  },
  contactInformation: {
    id: 'component.PersonalInfoForm.contactInformation',
    defaultMessage: 'Contact Information',
    streetAddress: {
      id: 'component.PersonalInfoForm.contactInformation.streetAddress',
      defaultMessage: 'Street Address',
    },
    cityLabel: {
      id: 'component.PersonalInfoForm.personalDetails.cityLabel',
      defaultMessage: 'City',
    },
    stateLabel: {
      id: 'component.PersonalInfoForm.personalDetails.stateLabel',
      defaultMessage: 'State',
    },
    zipCodeLabel: {
      id: 'component.PersonalInfoForm.personalDetails.zipCodeLabel',
      defaultMessage: 'ZIP',
    },
    homePhoneLabel: {
      id: 'component.PersonalInfoForm.homePhoneLabel',
      defaultMessage: 'Home Phone',
    },
    cellPhoneLabel: {
      id: 'component.PersonalInfoForm.contactInformation.cellPhoneLabel',
      defaultMessage: 'Mobile Phone',
    },
    workPhoneLabel: {
      id: 'component.PersonalInfoForm.contactInformation.workPhoneLabel',
      defaultMessage: 'Work Phone',
    },
    emailLabel: {
      id: 'component.PersonalInfoForm.contactInformation.emailLabel',
      defaultMessage: 'Email address',
    },
    sendEmailLabel: {
      id: 'component.PersonalInfoForm.contactInformation.sendEmailLabel',
      defaultMessage: 'May we leave a message?',
    },
    contactMethods: {
      id: 'component.PersonalInfoForm.contactInformation.contactMethods',
      defaultMessage: 'Preferred Methods of Contact:',
    },
  },

  insuranceInformation: {
    id: 'component.PersonalInfoForm.insuranceInformation',
    defaultMessage: 'Insurance Information (Optional)',
    SSNLabel: {
      id: 'component.PersonalInfoForm.insuranceInformation.SSNLabel',
      defaultMessage: 'SSN',
    },
    insuranceProviderLabel: {
      id: 'component.PersonalInfoForm.insuranceInformation.insuranceProviderLabel',
      defaultMessage: 'Primary Insurance Provider',
    },
    insuranceIdLabel: {
      id: 'component.PersonalInfoForm.insuranceInformation.insuranceIdLabel',
      defaultMessage: 'Insurance ID #',
    },
    groupIdLabel: {
      id: 'component.PersonalInfoForm.insuranceInformation.groupIdLabel',
      defaultMessage: 'Group ID #',
    },
    insuredNameLabel: {
      id: 'component.PersonalInfoForm.insuranceInformation.insuredNameLabel',
      defaultMessage: 'Name of Insured',
    },
    primaryDoctorNameLabel: {
      id: 'component.PersonalInfoForm.insuranceInformation.primaryDoctorNameLabel',
      defaultMessage: 'Name of Primary Care Doctor',
    },
    doctorsContactLabel: {
      id: 'component.PersonalInfoForm.insuranceInformation.doctorsContactLabel',
      defaultMessage: "Doctor's Contact #",
    },
    preferredPharmacyLabel: {
      id: 'component.PersonalInfoForm.insuranceInformation.preferredPharmacyLabel',
      defaultMessage: 'Preferred Pharmacy & Address',
    },
  },
  paymentInformation: {
    id: 'component.PersonalInfoForm.paymentInformation',
    defaultMessage: 'Billing & Payment Information',
  },

  currentMedications: {
    id: 'component.PersonalInfoForm.currentMedications',
    defaultMessage: 'Current Medications',
    nameLabel: {
      id: 'component.PersonalInfoForm.currentMedications.nameLabel',
      defaultMessage: 'Name',
    },
    doseLabel: {
      id: 'component.PersonalInfoForm.currentMedications.doseLabel',
      defaultMessage: 'Dose',
    },
    frequencyLabel: {
      id: 'component.PersonalInfoForm.currentMedications.frequencyLabel',
      defaultMessage: 'Frequency',
    },
  },
  illnessesAndInjuries: {
    id: 'component.PersonalInfoForm.illnessesAndInjuries',
    defaultMessage: 'Illnesses and Injuries',
    descriptionLabel: {
      id: 'component.PersonalInfoForm.illnessesAndInjuries.descriptionLabel',
      defaultMessage: 'Description',
    },
    dateOfOnsetLabel: {
      id: 'component.PersonalInfoForm.illnessesAndInjuries.dateOfOnsetLabel',
      defaultMessage: 'Estimated Date of Onset',
    },
  },
  alcoholAndDrugUse: {
    id: 'component.PersonalInfoForm.alcoholAndDrugUse',
    defaultMessage: 'Alcohol and Drug Use',
    formDescriptionLabel: {
      id: 'component.PersonalInfoForm.alcoholAndDrugUse.formDescriptionLabel',
      defaultMessage:
        'In the past year, have often have you used any of the following?',
    },
    otherLabel: {
      id: 'component.PersonalInfoForm.alcoholAndDrugUse.formDescriptionLabel',
      defaultMessage: 'Other (Specify)',
    },
  },
});
