import React, { useState, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './AdminLeftNav.module.scss';
import Loading from '../../../components/common/Loading';

const AdminLeftNav = () => {
  const [isLoading] = useState(false);
  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.title}>Admin Dashboard</div>
          <ul className={styles.menu}>
            <li>
              <NavLink exact to="/admin" activeClassName={styles.active}>
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/invite-clinician"
                activeClassName={styles.active}
              >
                <span>Invite Clinician</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/manage-forms" activeClassName={styles.active}>
                <span>Manage Forms</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/manage-clinics"
                activeClassName={styles.active}
              >
                <span>Manage Clinics</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/invite-researcher"
                activeClassName={styles.active}
              >
                <span>Invite Researcher</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/forms" activeClassName={styles.active}>
                <span>Forms</span>
              </NavLink>
            </li>
          </ul>
        </Fragment>
      )}
    </div>
  );
};

export default AdminLeftNav;
