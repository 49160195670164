import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Cell, Grid } from 'react-foundation';
import { useHistory } from 'react-router-dom';
import { saveIntakeForm21 } from '../../../../api/intakeForm';
import useIntl from '../../../../hooks/useIntl';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import SecondaryButton from '../../../common/buttons/SecondaryButton';
import Loading from '../../../common/Loading';
import styles from '../PersonalInfoForm/PersonalInfoForm.module.scss';
import { SolsticeFormStepOne, SolsticeFormStepTwo } from './SolsticeFormSteps';
import messages from './messages';
import { onKeyPress } from '../../../../utils/common';

export const solsticeCustomStyles = {
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  pageNumber: { fontSize: 15 },
  buttonsContainer: { marginRight: 30 },
};

const SolsticeForm = ({ fact_form_id, patientId, doctorId, id }) => {
  const { formatMessage } = useIntl();

  const [providerValue, setProviderValue] = useState();
  const [editItemProvider, setEditItemProvider] = useState();

  const [facilityValue, setFacilityValue] = useState();
  const [editItemFacility, setEditItemFacility] = useState();
  const [formStep, setFormStep] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const initialValues = {
    practice_heard_of_origin: '',
    counseling_goals: '',
    counseling_reason: '',
    psychotherapy_history: '',
    current_medication_and_reason: '',
    prescription_mds: '',
    primary_doctor: '',
    alcohol_use: '',
    recreational_substance: '',
    suicidal_thoughts: '',
    attempted_suicide: '',
    harm_others: '',
    psychiatric_hospitalization: '',
    mental_illness_history: '',
    relationship_nature: '',
    living_situation: '',
    education_level: '',
    current_occupation: '',
    experienced_symptoms_past_six_months: [],
    experienced_symptoms_past_six_months_free_text: '',
    other_experienced_symptoms: [],
    other_experienced_symptoms_free_text: '',
    other_mentions: '',
  };

  const nextStep = () => {
    setFormStep(formStep + 1);
  };
  const prevStep = () => {
    setFormStep(formStep - 1);
  };

  const handleSubmit = async (values) => {
    formatValues(values);
    setIsLoading(true);
    if (id) {
      try {
        await saveIntakeForm21(id, { ...values });
        history.push(`/my-account/intake-forms`);
      } catch (error) {}
    } else {
      setIsLoading(true);
      try {
        await saveIntakeForm21({
          patient_id: patientId,
          doctor_id: doctorId,
          fact_form_id,
          intake_form_values: values,
        });
        history.push(`/my-account/intake-forms`);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const formatValues = (values) => {
    if (
      !values.experienced_symptoms_past_six_months.some((v) => v === 'Other')
    ) {
      values.experienced_symptoms_past_six_months_free_text = '';
    }
    if (!values.other_experienced_symptoms.some((v) => v === 'Other')) {
      values.other_experienced_symptoms_free_text = '';
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.form}>
      <Formik
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        enableReinitialize
        initialValues={initialValues}
        render={({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          dirty,
        }) => {
          const handleCheckboxChange = (arrOfItems, value) => {
            const itemIndex = arrOfItems.indexOf(value);
            itemIndex === -1
              ? arrOfItems.push(value)
              : arrOfItems.splice(itemIndex, 1);
            return arrOfItems;
          };

          const SolsticeFormSteps = [
            SolsticeFormStepOne,
            SolsticeFormStepTwo,
          ].map((FormStep, i) => (
            <FormStep
              key={i}
              isLoading={isLoading}
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              editItemProvider={editItemProvider}
              providerValue={providerValue}
              setProviderValue={setProviderValue}
              setEditItemProvider={setEditItemProvider}
              setFacilityValue={setFacilityValue}
              editItemFacility={editItemFacility}
              facilityValue={facilityValue}
              setEditItemFacility={setEditItemFacility}
              handleCheckboxChange={handleCheckboxChange}
            />
          ));

          return (
            <Form onKeyPress={onKeyPress} data-testid="Personal-info-form">
              <Grid>
                <Cell small={12}>
                  <div style={solsticeCustomStyles.paginationContainer}>
                    <h2 className={styles.formTitle}>
                      {formatMessage(messages.formTitle)}
                    </h2>
                    <p style={solsticeCustomStyles.pageNumber}>{`Page ${
                      formStep + 1
                    } of ${SolsticeFormSteps.length}`}</p>
                  </div>
                </Cell>
                {SolsticeFormSteps[formStep]}
                <Cell medium={12} flexContainer>
                  {formStep > 0 && (
                    <div style={solsticeCustomStyles.buttonsContainer}>
                      <SecondaryButton
                        type="button"
                        label={'Back'}
                        onClick={prevStep}
                        className="font-18"
                      />
                    </div>
                  )}
                  {formStep < SolsticeFormSteps.length - 1 && (
                    <PrimaryButton
                      type="button"
                      label={'Next'}
                      onClick={nextStep}
                      className="font-18"
                    />
                  )}
                  {formStep === SolsticeFormSteps.length - 1 && (
                    <PrimaryButton
                      disabled={!dirty}
                      label={'Submit'}
                      type="submit"
                      className="font-18"
                    />
                  )}
                </Cell>
              </Grid>
            </Form>
          );
        }}
      />
    </div>
  );
};

SolsticeForm.propTypes = {
  onCancel: PropTypes.func,
  nextBtnLabel: PropTypes.string,
  backBtnLabel: PropTypes.string,
  submitBtnLabel: PropTypes.string,
};

SolsticeForm.defaultProps = {};

export default SolsticeForm;
