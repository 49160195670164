import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Section.module.scss';

const Section = ({ children, isHeader, theme, className }) => (
  <section
    className={classnames(
      { [styles.header]: isHeader },
      styles[`section-${theme}`],
      { [className]: className },
    )}
  >
    <div className={styles.container}>{children}</div>
  </section>
);

Section.propTypes = {
  isHeader: PropTypes.bool,
  theme: PropTypes.oneOf(['gray', 'white']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Section.defaultValues = {
  isHeader: false,
  theme: 'white',
};

export default Section;
