import axios from 'axios';
import { toast } from 'react-toastify';

import { getAccessToken, clearAuthLocalStorageData } from '../../utils/user';

const API_URL = process.env.REACT_APP_API_URL;
const PDF_API_URL = process.env.REACT_APP_PDF_API_URL;
const UNAUTHORIZED = 401;

const ERROR_DEFAULT_MESSAGE = 'Something is wrong. Please try again';
const ERROR_DEFAULT_TOAST_PAYLOAD = {
  autoClose: 5000,
  hideProgressBar: true,
};

function handleError(err) {
  console.error('err', err);
  const status = err?.response?.status || null;

  /**
   * here we set the list to ignore error and dont show error message to user
   */
  const isNylasDisconnectRequest =
    err?.request?.responseURL?.includes('nylas/disconnect');
  const isGetClinicianRegistrationRequest =
    err.config.method === 'get' &&
    err?.request?.responseURL?.includes('doctor/clinician-registration');
  const isVerifyUninvitedUserRequest = // this request handles in RegistrationPage component
    err.config.method === 'get' &&
    err?.request?.responseURL?.includes('auth/uninvited/verify-user');
  const isVerifyExistingUserRequest = // this request handles in DoctorInitialSignupPage component
    err.config.method === 'post' &&
    err?.request?.responseURL?.includes('okta/user') &&
    err?.response?.data?.message === 'user_not_found';
  const isGetAppointmentByPrebookingRequest =
    err.config.method === 'get' &&
    err?.request?.responseURL?.includes('appointment/by-prebooking');

  if (
    status !== 401 &&
    !isNylasDisconnectRequest &&
    !isGetClinicianRegistrationRequest &&
    !isVerifyUninvitedUserRequest &&
    !isVerifyExistingUserRequest &&
    !isGetAppointmentByPrebookingRequest
  ) {
    toast.error(
      err?.response?.data?.message || ERROR_DEFAULT_MESSAGE,
      ERROR_DEFAULT_TOAST_PAYLOAD,
    );
  }

  throw err;
}

export const doGet = async (uri, params, host = API_URL) => {
  try {
    const { data } = await axios.get(`${host}${uri}`, params);
    return data;
  } catch (err) {
    handleError(err);
  }
};

export const doPost = async (uri, body, params, host = API_URL) => {
  try {
    const { data } = await axios.post(`${host}${uri}`, body, params);
    return data;
  } catch (err) {
    handleError(err);
  }
};

export const doDelete = async (uri, params, host = API_URL) => {
  try {
    const { data } = await axios.delete(`${host}${uri}`, params);
    return data;
  } catch (err) {
    handleError(err);
  }
};

export const doPut = async (uri, body, params, host = API_URL) => {
  try {
    const { data } = await axios.put(`${host}${uri}`, body, params);
    return data;
  } catch (err) {
    handleError(err);
  }
};

export const doPatch = async (uri, body, params, host = API_URL) => {
  try {
    const { data } = await axios.patch(`${host}${uri}`, body, params);
    return data;
  } catch (err) {
    handleError(err);
  }
};

export const getPdf = async (
  uri,
  params,
  host = `${window.location.protocol}//${window.location.hostname}${PDF_API_URL}`,
) => {
  try {
    const { data } = await axios.get(`${host}${uri}`, {
      params,
      responseType: 'blob',
      headers: {
        accept: 'application/pdf',
        'Access-Control-Allow-Origin': '*',
      },
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${uri.replaceAll('/', '_')}.pdf`);
    document.body.appendChild(link);
    link.click();
    return data;
  } catch (err) {
    handleError(err);
  }
};

axios.interceptors.request.use(async ({ headers, ...rest }) => {
  const authHeader = headers['Authorization'];
  if (!authHeader) {
    const token = await getAccessToken();
    if (token)
      return {
        ...rest,
        headers: { ...headers, Authorization: `Bearer ${token}` },
      };
  }
  return { ...rest, headers };
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.config?.url === 'https://api.schedule.nylas.com/manage/pages') {
      // show popup to reconnect nylas/ DONE in doctor/MyAccountPage page component
    } else if (error?.response?.status === UNAUTHORIZED) {
      clearAuthLocalStorageData();
    }
    return Promise.reject(error);
  },
);
