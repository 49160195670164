import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import React from 'react';

const SwitchToggle = ({
  onChange,
  checked,
  label,
  name,
  value,
  dataTestId,
}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <p
        style={{
          color: '#b7b7b7',
          fontSize: '9px',
          fontFamily: 'Montserrat',
        }}
        data-testid={`${dataTestId}_Label`}
      >
        {label}
      </p>
      <Switch
        checked={checked}
        color="primary"
        inputProps={{
          'data-testid': `${dataTestId}_Switch`,
        }}
        onChange={onChange}
        name={name}
        value={value}
      />
    </div>
  );
};

SwitchToggle.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
};
export default SwitchToggle;
