import React, {
  useState,
  Fragment,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Cell, Grid } from 'react-foundation';
import Dropzone from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import classnames from 'classnames';
import { useOktaAuth } from '@okta/okta-react';

import Loading from '../../../components/common/Loading';
import Modal from '../../../components/common/Modal';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import { Switch } from '../../../components/common/Switch';
import DoctorLeftNav from '../../../components/nav/DoctorLeftNav';
import BillingPlanFeature from '../../../components/BillingPlanFeature';

import styles from './DoctorProfilePage.module.scss';
import 'react-image-crop/dist/ReactCrop.css';

import {
  getDoctor,
  uploadDoctorProfilePic,
  removeDoctorProfilePic,
  closeClinicianAccount,
  updateClinicianMatchingStateByDoctor,
} from '../../../api/doctor';
import { logout } from '../../../api/auth';

import useIntl from '../../../hooks/useIntl';
import AuthContext from '../../../context/AuthContext';
import messages from './messages';

const cropConfig = {
  x: 180,
  y: 180,
  unit: 'px',
  width: 160,
  height: 160,
  aspect: 1 / 1,
};

const DoctorProfilePage = () => {
  const { formatMessage } = useIntl();
  const { authState, oktaAuth } = useOktaAuth();
  const { isLoading, authUser, userAvtar, dispatch } = useContext(AuthContext);
  const history = useHistory();

  const [dataLoading, setLoading] = useState(false);
  const [showCloseAccountlModal, setShowCloseAccountlModal] = useState(false);
  const [profile, setProfile] = useState({});
  const isDataLoading = dataLoading || isLoading;
  const [showProfilePicDialog, setShowProfilePicDialog] = useState(false);
  const [image, setImage] = useState(require(`../../../img/doctor.jpg`));
  const [cropObj, setCropObj] = useState(cropConfig);
  const [imageRef, setImageRef] = useState('');
  const [imageBlob, setImageBlob] = useState('');
  const [imageUploadError, setImageUploadError] = useState('');
  const [inviteLink, setInviteLink] = useState('');

  const fetchDoctorPayment = async () => {
    if (authUser && authUser.id) {
      try {
        const { doctor } = await getDoctor(authUser.id);
        setProfile(doctor);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchDoctorPayment();
  }, [authUser]);

  const getClinicData = useCallback(async () => {
    if (authUser.id) {
      let inviteLink = `${window.origin}/discoverv3/${authUser.id}`;

      if (authUser?.whitelabelDetails?.assessment_key) {
        inviteLink = `${inviteLink}/${authUser.whitelabelDetails.assessment_key}`;
      }

      setInviteLink(inviteLink);
    }
  }, [authUser]);

  useEffect(() => {
    getClinicData();
  }, [getClinicData]);

  const onCloseAccount = async () => {
    setLoading(true);
    try {
      const respMessageOnAccountClose = await closeClinicianAccount(
        authUser.id,
      );
      if (authState.isAuthenticated) {
        oktaAuth.signOut({
          postLogoutRedirectUri: window.location.origin,
        });
      }
      logout(dispatch);
      history.push('/');
      setLoading(false);
      setShowCloseAccountlModal(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onDrop = (files) => {
    if (files.length === 0) {
      setImageUploadError('File size must be less than 2 MB');
    }
    setShowProfilePicDialog(true);
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    if (files.length) {
      reader.readAsDataURL(files[0]);
    }
  };

  const onImageLoaded = (img) => {
    setImageRef(img);
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop /*percentCrop*/) => {
    setCropObj(crop); // we could also use percentCrop:
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageBlob = await getCroppedImg(
        imageRef,
        crop,
        `${Date().now}.jpeg`,
      );
      setImageBlob(croppedImageBlob);
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const onClickUploadProfilePic = async () => {
    const formData = new FormData();
    formData.append('file', imageBlob, `${Date().now}.jpeg`);
    setLoading(true);
    try {
      const { doctor } = await uploadDoctorProfilePic(
        { id: profile.id, params: formData },
        dispatch,
      );
      setProfile(doctor);
      setLoading(false);
      setShowProfilePicDialog(false);
      setCropObj(cropConfig);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onClickRemoveProfilePic = async () => {
    setLoading(true);
    try {
      const { doctor } = await removeDoctorProfilePic(
        { id: profile.id },
        dispatch,
      );
      setProfile(doctor);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const getDescription = (description) => {
    const desc = description.replace(/\r?\n/g, '<br />');
    return desc;
  };

  const onChangeAllowMatchingToClient = async (e) => {
    const {
      target: { checked },
    } = e;
    try {
      const { doctor } = await updateClinicianMatchingStateByDoctor(
        profile.id,
        { allowMatchingToClients: checked },
      );
      setProfile(doctor);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftNav, 'desktop-only')}>
        <DoctorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <h1 data-testid="clinician myaccount header">
              {formatMessage(messages.title)}
            </h1>
            <div className={styles.profileSection}>
              <div className={styles.profilePic}>
                <div className={styles.profileimage}>
                  <img
                    src={userAvtar}
                    data-testid="profile picture"
                    alt="ProfilePicture"
                  />
                </div>
                <div>
                  <Dropzone
                    onDrop={onDrop}
                    accept="image/jpeg, image/png"
                    maxSize={2000000}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <PrimaryButton
                          label={formatMessage(
                            profile.profile_pic
                              ? messages.editProfileBtnLabel
                              : messages.uploadProfileBtnLabel,
                          )}
                          className="font-18"
                          type="button"
                          data-testid="edit profile picture button"
                        />
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div>
                  {profile.profile_pic && (
                    <SecondaryButton
                      label={formatMessage(messages.removeProfileBtnLabel)}
                      className="font-18"
                      type="button"
                      onClick={() => onClickRemoveProfilePic()}
                      data-testid="remove profile picture button"
                    />
                  )}
                </div>
              </div>
              <div className={styles.profileDetil}>
                <div className={styles.profileinfo}>
                  <div className={styles.detailsDiv}>
                    <div
                      data-testid="cinician_name_label"
                      className={styles.fieldTitle}
                    >
                      {formatMessage(messages.nameLabel)}
                    </div>
                    <div
                      data-testid="cinician_name_value"
                      className={styles.fieldvalue}
                    >
                      {profile.first_name}{' '}
                      {profile.last_name ? profile.last_name : ''}
                    </div>
                  </div>
                  <div className={styles.detailsDiv}>
                    <div
                      data-testid="cinician_emai_label"
                      className={styles.fieldTitle}
                    >
                      {formatMessage(messages.emailLabel)}
                    </div>
                    <div
                      data-testid="cinician_emai_value"
                      className={styles.fieldvalue}
                    >
                      {profile.email}
                    </div>
                  </div>
                  <div className={styles.detailsDiv}>
                    <div
                      data-testid="cinician_password_label"
                      className={styles.fieldTitle}
                    >
                      {formatMessage(messages.passwordLabel)}
                    </div>
                    <div
                      data-testid="cinician_password_value"
                      className={styles.fieldvalue}
                    >
                      ************
                    </div>
                  </div>
                  <div className={styles.detailsDiv}>
                    <div
                      data-testid="cinician_phone_label"
                      className={styles.fieldTitle}
                    >
                      {formatMessage(messages.phoneLabel)}
                    </div>
                    <div
                      data-testid="cinician_phone_value"
                      className={styles.fieldvalue}
                    >
                      {profile.phone}
                    </div>
                  </div>
                  {profile && profile.description && (
                    <div className={styles.detailsDiv}>
                      <div
                        data-testid="cinician_bio_label"
                        className={styles.fieldTitle}
                      >
                        {formatMessage(messages.doctorBioLable)}
                      </div>
                      <div
                        data-testid="cinician_emai_value"
                        className={styles.fieldvalue}
                      >
                        <p
                          data-testid="cinician_bio_value"
                          dangerouslySetInnerHTML={{
                            __html: getDescription(profile.description),
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <BillingPlanFeature matching>
                  <div className={styles.switchdiv}>
                    <div
                      className={styles.label}
                      data-testid="cinician_maching_to_label"
                    >
                      {formatMessage(messages.matchingToClientsLabel)}
                    </div>
                    <div>
                      <Switch
                        checked={!!profile.allowMatchingToClients}
                        onChange={onChangeAllowMatchingToClient}
                        name="allowMatchingToClients"
                        data-testid="allow matching to clients"
                        label-testid="allow matching to clients label"
                      />
                    </div>
                  </div>
                </BillingPlanFeature>
                <BillingPlanFeature>
                  <div>
                    <PrimaryButton
                      label={formatMessage(messages.editProfileLinkLable)}
                      className="font-18"
                      type="button"
                      data-testid="edit my account profile"
                      onClick={() =>
                        history.push('/doctor/update-clinician/registration')
                      }
                    />
                  </div>
                </BillingPlanFeature>
                <div>
                  <SecondaryButton
                    label={formatMessage(messages.closeAccountLinkLable)}
                    className="font-18"
                    type="button"
                    data-testid="close my account"
                    onClick={() => setShowCloseAccountlModal(true)}
                  />
                </div>
                <BillingPlanFeature>
                  <div>
                    <strong>Invite Link</strong>{' '}
                    <a
                      className={styles.link}
                      href={`${inviteLink}`}
                      data-testid="invite-link"
                    >
                      {inviteLink}
                    </a>
                  </div>
                </BillingPlanFeature>
              </div>
            </div>
          </div>
        </Fragment>
      )}

      {showCloseAccountlModal && (
        <Modal className={styles.closeAccountModal}>
          <div className={styles.content}>
            <div
              className={styles.closeButton}
              onClick={() => {
                setShowCloseAccountlModal(false);
              }}
              data-testid=""
            >
              <img
                src={require(`../../../img/close.svg`)}
                alt={`Close`}
                data-testid="close_account icon"
              />
            </div>
            <h1>
              <b data-testid="close_account title">
                Are you sure you want to close your account?
              </b>
            </h1>
            <div className={styles.box}>
              <p data-testid="close_account text">
                This action will log you off the system.
              </p>
            </div>
            <PrimaryButton
              label="Yes, close my account"
              onClick={() => {
                onCloseAccount();
              }}
              data-testid="close_account yes button"
            />
            <SecondaryButton
              label="No"
              onClick={() => {
                setShowCloseAccountlModal(false);
              }}
              data-testid="close_account no button"
            />
          </div>
        </Modal>
      )}

      <div
        className={showProfilePicDialog ? styles.showDialog : styles.hideDialog}
      >
        <Modal
          className={[
            styles.profileModal,
            showProfilePicDialog ? 'showDialog' : '',
          ].join(' ')}
        >
          <div className={styles.content}>
            <h1>
              <b data-testid="profile modal title">
                {formatMessage(messages.profilePicModalTitle)}
              </b>
            </h1>
            <p>{formatMessage(messages.profilePicModalFileSize)}</p>
            {imageUploadError && (
              <p className={styles.imageUploadError}>{imageUploadError}</p>
            )}
            <div className={styles.cropper}>
              <ReactCrop
                src={image}
                crop={cropObj}
                ruleOfThirds
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}
                minHeight={160}
                minWidth={160}
                circularCrop={true}
              />
            </div>
          </div>
          <Grid className={styles['crop-action-grid']}>
            <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Cell
                    medium={5}
                    small={6}
                    className={styles['actions-container-left']}
                  >
                    <SecondaryButton
                      label={formatMessage(messages.profilePicChooseAnotherBtn)}
                      className="font-16"
                      type="button"
                      onClick={() => setCropObj(cropConfig)}
                    />
                  </Cell>
                </div>
              )}
            </Dropzone>
            <Cell
              medium={7}
              small={6}
              className={styles['actions-container-right']}
            >
              <SecondaryButton
                label={formatMessage(messages.profilePicCancelBtn)}
                className="font-16"
                type="button"
                onClick={() => {
                  setShowProfilePicDialog(false);
                  setCropObj(cropConfig);
                }}
              />
              <PrimaryButton
                label={formatMessage(messages.profilePicSaveBtn)}
                disabled={isDataLoading}
                className="font-18"
                type="button"
                onClick={() => onClickUploadProfilePic()}
              />
            </Cell>
          </Grid>
        </Modal>
      </div>
    </div>
  );
};

export default DoctorProfilePage;
