import { useEffect, useState } from 'react';
import { getEntries } from '../../api/contentful';

const useContentful = ({ key, contentType }) => {
  const [staticContent, setStaticContent] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchContent = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const content = await getEntries({
          'fields.key': key,
          content_type: contentType,
        });
        setStaticContent(content.items[0]);
      } catch (e) {
        setError(e);
      }
      setIsLoading(false);
    };
    fetchContent();
  }, [contentType, key]);

  return { staticContent, isLoading, error };
};

export default useContentful;
