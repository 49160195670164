import React, { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Grid, Cell } from 'react-foundation';

import styles from './Footer.module.scss';
import useIntl from '../../hooks/useIntl';
import EveLogo from '../../img/logo-white.svg';

import WhitelabelContext from '../../context/WhitelabelContext';

import messages from './messages';

const renderFooter = (path, formatMessage, whitelabel) => {
  switch (path) {
    case '/my-account/discoverv2':
      return (
        <footer className={styles.footer}>
          <Grid className={styles.container}>
            <Cell small={12} medium={2} className={styles['logo-container']}>
              {whitelabel && whitelabel.footer_logo_url ? (
                <img src={whitelabel.footer_logo_url} alt="logo" />
              ) : (
                <img className={styles.eveLogo} src={EveLogo} alt="Eve" />
              )}
            </Cell>
          </Grid>
          <div className={styles.terms}>
            <Link to="/agreements/terms-conditions">
              <b>{formatMessage(messages.terms)}</b>
            </Link>
            <Link to="/agreements/privacy-policy">
              <b>{formatMessage(messages.privacy)}</b>
            </Link>
            <Link to="/contact-us">
              <b>{formatMessage(messages.contact)}</b>
            </Link>
            <span className={styles.copyright}>
              <b>
                © {new Date().getFullYear()}{' '}
                {whitelabel && whitelabel.subdomain
                  ? `${whitelabel.subdomain}`
                  : `evehealthsystems.com`}{' '}
                All Rights Reserved.
              </b>
            </span>
          </div>
        </footer>
      );
    case '/swipe':
    case '/':
    case '/join':
    case '/user-denied':
    case '/unathorize-user':
    case '/user-registered':
    case '/reset/email':
    case '/my-account/discover':
    case '/my-account/recommended-products':
    case '/thankyou':
    case '/my-account/intake-form':
      return null;
    case (path.match('/my-account/form') || {}).input:
      return null;
    case (path.match('/login') || {}).input:
      return null;
    case (path.match('/approval') || {}).input:
      return null;
    case (path.match('/disable') || {}).input:
      return null;
    case (path.match('/expire-token') || {}).input:
      return null;
    case (path.match('/activate') || {}).input:
      return null;
    case (path.match('/signup') || {}).input:
      return null;
    case (path.match('/Registration') || {}).input:
      return null;
    case (path.match('/forgot-password') || {}).input:
      return null;
    case (path.match('/reset-password') || {}).input:
      return null;
    case (path.match('/discoverv3') || {}).input:
      return null;
    default:
      return (
        <footer className={styles.footer}>
          <Grid className={styles.container}>
            <Cell small={12} medium={2} className={styles['logo-container']}>
              {whitelabel && whitelabel.footer_logo_url ? (
                <img src={whitelabel.footer_logo_url} alt="logo" />
              ) : (
                <img className={styles.eveLogo} src={EveLogo} alt="Eve" />
              )}
            </Cell>
          </Grid>
          <div className={styles.terms}>
            <Link to="/agreements/terms-conditions">
              <b>{formatMessage(messages.terms)}</b>
            </Link>
            <Link to="/agreements/privacy-policy">
              <b>{formatMessage(messages.privacy)}</b>
            </Link>
            <Link to="/contact-us">
              <b>{formatMessage(messages.contact)}</b>
            </Link>
            <span className={styles.copyright}>
              <b>
                © {new Date().getFullYear()}{' '}
                {whitelabel && whitelabel.subdomain
                  ? `${whitelabel.subdomain}`
                  : `evehealthsystems.com`}{' '}
                All Rights Reserved.
              </b>
            </span>
          </div>
        </footer>
      );
  }
};
const Footer = ({ location }) => {
  const { formatMessage } = useIntl();
  const { whitelabel } = useContext(WhitelabelContext);

  return (
    <footer>
      {renderFooter(location.pathname, formatMessage, whitelabel)}
    </footer>
  );
};

export default withRouter(Footer);
