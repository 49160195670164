import React, {
  useState,
  Fragment,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import { Cell } from 'react-foundation';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import moment from 'moment';

import FormsList from '../../../components/FormsList';
import NotesList from '../../../components/NotesList';
import PatientFiles from '../../../components/PatientFiles';
import FormListView from '../../../components/FormListView';
import MessageModal from '../../../components/MessageModal';
import DoctorLeftNav from '../../../components/nav/DoctorLeftNav';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import FieldDropdown from '../../../components/common/fields/FieldDropdown';
import FieldInput from '../../../components/common/fields/FieldInput';
import Loading from '../../../components/common/Loading';
import Modal from '../../../components/common/Modal';
import BillingPlanFeature from '../../../components/BillingPlanFeature';
import ClientDetailsModal from './ClientDetailsModal';

import {
  getEvents,
  getEventDetail,
  getPatientDetails,
} from '../../../api/user';
import {
  getClinicAssociatedFormsData,
  assignForm,
  getAssignedForms,
} from '../../../api/intakeForm';
import { removeAssignedForm } from '../../../api/doctor';
import { getNotes } from '../../../api/note';
import { getPatientAssessment, resendInvitation } from '../../../api/patient';
import { getEntries } from '../../../api/contentful';

import AuthContext from '../../../context/AuthContext';

import styles from './PatientDetailsPage.module.scss';

import Happy from '../../../img/happy.png';
import Sad from '../../../img/sad.png';
import Neutral from '../../../img/neutral.png';
import { USER_TYPES, PATIENT_DETAILS_STATUS, FORM } from '../../../constants';

const TAB = {
  TIMELINE: 'Timeline',
  ASS_FORMS: 'Assessments and Forms',
  NOTES: 'Notes',
  FILES: 'Files',
};

const PatientDetailsPage = () => {
  const { id } = useParams();

  const { isLoading, authUser } = useContext(AuthContext);

  const [dataLoading, setLoading] = useState(false);
  const [isPatientDetailsLoading, setIsPatientDetailsLoading] = useState(true);
  const [isPatientEventsLoading, setIsPatientEventsLoading] = useState(true);
  const [isNotesLoading, setIsNotesLoading] = useState(true);
  const [isClinicFormsLoading, setIsClinicFormsLoading] = useState(true);
  const [isAssignedFormsLoading, setIsAssignedFormsLoading] = useState(true);

  const [showClientDetailModal, setShowClientDetailModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showNewFormModal, setShowNewFormModal] = useState(false);
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);

  const [activeTab, setActiveTab] = useState(null);
  const [ubpTabs, setUbpTabs] = useState([]);

  const [patientDetails, setPatientDetails] = useState();
  const [timeline, setTimeline] = useState([]);
  const [matchingAssessment, setMatchingAssessment] = useState(null);
  const [notes, setNotes] = useState([]);
  const [timeLineDetail, setTimeLineDetail] = useState('');
  const [useAvatar, setUseAvatar] = useState({});

  const [formLists, setFormLists] = useState([]);
  const [formattedFormLists, setFormattedFormLists] = useState([]);

  const [formId, setFormId] = useState('');
  const [assignedForms, setAssignedForms] = useState([]);
  const [userDetail, setUserDetail] = useState({});
  const [patientEmail, setPatientEmail] = useState('');
  const [error, setError] = useState('');

  const [displayMessage, setDisplayMessage] = useState({
    title: '',
    message: '',
  });

  const isDataLoading =
    isLoading ||
    dataLoading ||
    isPatientDetailsLoading ||
    isPatientEventsLoading ||
    isNotesLoading ||
    isClinicFormsLoading ||
    isAssignedFormsLoading;

  const fetchPatient = useCallback(async () => {
    if (id) {
      try {
        setIsPatientDetailsLoading(true);
        const { patient } = await getPatientDetails(id);

        setPatientDetails(patient);
        setUseAvatar(patient.profile_pic);
        setPatientEmail(patient.email);

        setIsPatientDetailsLoading(false);
        setUserDetail({
          doctor_id: authUser.id,
          doctor: authUser,
          patient: patient,
          patient_id: id,
        });
      } catch (error) {
        setIsPatientDetailsLoading(false);
      }
    }
  }, [authUser, id]);

  const prepareAssessmentValues = (fields, assessmentValues) => {
    const assessments = [];
    let assessmentValue;

    fields.map((field) => {
      if (field.key && assessmentValues[field.key]) {
        if (
          assessmentValues[field.key].value ||
          assessmentValues[field.key].options ||
          field.type === 'slider'
        ) {
          if (assessmentValues[field.key].options) {
            assessments.push({
              key: [field.title],
              value: assessmentValues[field.key].options
                .map((option) => option.title)
                .toString(),
            });
          } else if (field.type === 'slider') {
            assessmentValue = field.marks.find(
              (mark) =>
                mark.value === assessmentValues[field.key].value ||
                mark.value === assessmentValues[field.key],
            ).label;
            assessments.push({ key: [field.title], value: assessmentValue });
          } else if (field.type === 'swipe-card') {
            assessments.push({
              key: [`${field.title.replace(/\.+$/, '')} ${field.subtitle}`],
              value: assessmentValues[field.key].value,
            });
          } else {
            assessments.push({
              key: [field.title],
              value: assessmentValues[field.key].value,
            });
          }
        }
      }
    });
    return assessments;
  };

  const fetchPatientMatchingAssessment = useCallback(async () => {
    try {
      setLoading(true);

      let newMatchingAssessment = null;
      const { assessment } = await getPatientAssessment(id);

      if (assessment?.assessment_name) {
        const questionnaire = await getEntries({
          'fields.title': assessment.assessment_name,
          content_type: 'recommendationEngineQuestionnaire',
        });

        if (
          assessment.assessment_values &&
          questionnaire.items.length > 0 &&
          questionnaire.items[0].fields['questions']
        ) {
          newMatchingAssessment = {
            id: assessment.id,
            assessments: prepareAssessmentValues(
              questionnaire.items[0].fields['questions'],
              assessment.assessment_values,
            ),
          };
        }
      }

      setMatchingAssessment(newMatchingAssessment);
      setLoading(false);
    } catch (error) {
      console.error('error', error);
      setLoading(false);
    }
  }, [id]);

  const formatNotesData = useCallback(
    async (tempNotes) => {
      const note = {
        id: authUser.id,
        notes: [],
        first_name: authUser.first_name,
        last_name: authUser.last_name,
      };
      if (tempNotes.length) {
        const tempNote = tempNotes.find((n) => n.id === authUser.id);
        if (tempNote) {
          tempNotes.unshift(
            tempNotes.splice(
              tempNotes
                .map(function (e) {
                  return e.id;
                })
                .indexOf(authUser.id),
              1,
            )[0],
          );
        } else {
          tempNotes.unshift(note);
        }
        setNotes(tempNotes);
      } else {
        setNotes([note]);
      }
    },
    [authUser.first_name, authUser.id, authUser.last_name],
  );

  const fetchAllNotes = useCallback(async () => {
    try {
      setIsNotesLoading(true);

      const { notes: allNotes } = await getNotes(id);
      formatNotesData(allNotes);

      setIsNotesLoading(false);
    } catch (error) {
      setIsNotesLoading(false);
    }
  }, [formatNotesData, id]);

  const getPatientEvents = async (id, doctor_id) => {
    try {
      setIsPatientEventsLoading(true);

      const { events } = await getEvents(id);
      setTimeline(events);

      setIsPatientEventsLoading(false);
    } catch (error) {
      setIsPatientEventsLoading(false);
    }
  };

  const fetchFormLists = useCallback(async () => {
    try {
      setIsClinicFormsLoading(true);

      const params = {
        clinic_id: authUser.clinic_id || 0,
      };
      const lists = await getClinicAssociatedFormsData(params);
      setFormLists(lists);

      const formattedLists = lists
        .filter((form) => {
          if (
            form.dim_form?.form_type === FORM.TYPE.INTAKE &&
            !authUser?.billing_plan?.intake_forms
          ) {
            return false;
          }

          if (
            form.dim_form?.form_type === FORM.TYPE.ASSESSMENT &&
            !authUser?.billing_plan?.assessments
          ) {
            return false;
          }

          return true;
        })
        .map((form) => {
          return {
            ...form,
            label: form.dim_form.form_name,
            value: form.dim_form.id,
          };
        })
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

      setFormattedFormLists(formattedLists);

      setIsClinicFormsLoading(false);
    } catch (error) {
      setIsClinicFormsLoading(false);
    }
  }, [authUser.clinic_id, authUser.id]);

  const fetchAssignedForms = async (id) => {
    try {
      setIsAssignedFormsLoading(true);

      const { assignForms } = await getAssignedForms(id);
      setAssignedForms(assignForms);

      setIsAssignedFormsLoading(false);
    } catch (error) {
      setIsAssignedFormsLoading(false);
    }
  };

  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleTimelineClick = async (item) => {
    if (item.details.tableName === 'appointment') {
      fetchPatientMatchingAssessment();
      setShowDetailModal(true);
      const { response } = await getEventDetail(item.details);
      setTimeLineDetail(response);
      setShowDetailModal(true);
    }
  };

  const handleRemoveForm = async (data) => {
    try {
      if (data && data.id) {
        setLoading(true);

        await removeAssignedForm(data.id);
        fetchAssignedForms(id);

        setDisplayMessage({
          type: 'success',
          title: 'Done',
          text: 'Form successfully removed',
        });

        setShowMessageModal(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setDisplayMessage({
        type: 'error',
        text: 'Something went wrong. Please try again.',
      });
      setShowMessageModal(true);
    }
  };

  const handleAssignForm = async () => {
    setLoading(true);
    setShowNewFormModal(false);
    const selectedForm = formLists.filter((x) => x.dim_form.id === formId)[0];
    await assignForm({
      form_id: formId,
      form_name: selectedForm.dim_form.form_name,
      form_type: selectedForm.dim_form.form_type,
      doctor_id: authUser.id,
      patient_id: id,
      clinic_id: authUser.clinic_id || 0,
      status: 'assigned',
    });

    setFormId('');
    fetchAssignedForms(id);
    setLoading(false);
  };

  const handleCancelAssignForm = () => {
    setFormId('');
    setShowNewFormModal(false);
  };

  const actualizeUbpTabs = () => {
    const billingPlan = authUser?.billing_plan;

    if (ubpTabs.length || !billingPlan) {
      return;
    }

    const tabs = [];
    if (billingPlan.patient_details) {
      tabs.push(TAB.TIMELINE);
    }
    if (billingPlan.intake_forms || billingPlan.assessments) {
      tabs.push(TAB.ASS_FORMS);
    }
    if (billingPlan.notes) {
      tabs.push(TAB.NOTES);
    }
    if (billingPlan.patient_files) {
      tabs.push(TAB.FILES);
    }

    if (tabs.length) {
      setUbpTabs(tabs);
      setActiveTab(tabs[0]);
    }
  };

  useEffect(() => {
    if (id && authUser?.id) {
      fetchPatient();
      getPatientEvents(id, authUser.id);
      fetchAllNotes();
      fetchPatientMatchingAssessment();
      fetchAssignedForms(id);
      fetchFormLists();
      actualizeUbpTabs();
    }
  }, [authUser, id]);

  // TODO have to move to a separate component
  const getDetailContent = (title) => {
    switch (title) {
      case 'Registration':
        return (
          <div className={styles.contentDetail}>
            <div className={styles['meeting-details']}>
              <div className={styles.text}>
                <div className={styles.location}>
                  <span>Name: </span>
                  <p>
                    {timeLineDetail.first_name
                      ? `${timeLineDetail.first_name} ${timeLineDetail.last_name}`
                      : 'NA'}{' '}
                  </p>
                </div>
                <div className={styles.location}>
                  <span>Email:</span>
                  <p>{timeLineDetail.email}</p>
                </div>
              </div>
            </div>
          </div>
        );
      case 'Payment':
        return (
          <div className={styles.contentDetail}>
            <div className={styles['meeting-details']}>
              <div className={styles.text}>
                <div className={styles.location}>
                  <span>Soruce: </span>
                  <p>
                    {timeLineDetail.source_type
                      ? timeLineDetail.source_type
                      : 'Card'}
                    ****{timeLineDetail.card_details.last_4}
                  </p>
                </div>
                <div className={styles.location}>
                  <span>Fee:</span>
                  <p>$ {timeLineDetail.amount}</p>
                </div>
              </div>
            </div>
          </div>
        );
      case 'Consultations':
        return (
          <div className={styles.contentDetail}>
            <div className={styles['meeting-details']}>
              <div className={styles['meeting-time']}>
                {moment(timeLineDetail.start_time).isAfter(new Date()) && (
                  <>
                    <img
                      className={styles['meeting-icon']}
                      src={require(`../../../img/icons/calendar-blue.svg`)}
                      alt={'appointment-booked'}
                    />
                    <p className={styles.isAfter}>
                      {moment(timeLineDetail.start_time).format('LT')} -{' '}
                      {moment(timeLineDetail.end_time).format('LT')},{' '}
                      {moment(timeLineDetail.end_time).format(
                        'dddd, MMMM DD, YYYY',
                      )}
                    </p>
                  </>
                )}
                {moment(timeLineDetail.start_time).isBefore(new Date()) && (
                  <>
                    <img
                      className={styles['meeting-icon']}
                      src={require(`../../../img/icons/calendar-black.svg`)}
                      alt={'appointment-booked'}
                    />
                    <p className={styles.isBefore}>
                      {moment(timeLineDetail.start_time).format('LT')} -{' '}
                      {moment(timeLineDetail.end_time).format('LT')},{' '}
                      {moment(timeLineDetail.end_time).format(
                        'dddd, MMMM DD, YYYY',
                      )}
                    </p>
                  </>
                )}
              </div>
              <div className={styles.title}>
                {timeLineDetail.patient.first_name}{' '}
                {timeLineDetail.patient.last_name}
              </div>
              <div className={styles.text}>
                <div className={styles.type}>
                  <span>Type:</span>
                  <p>{timeLineDetail.patient_type}</p>
                </div>
                <div className={styles.location}>
                  <span>Mobile:</span>
                  <p>{timeLineDetail.patient_phone}</p>
                </div>
                <div className={classnames(styles.location, styles.reason)}>
                  <span>Reason for visit:</span>
                  <p>{timeLineDetail.reason_for_visit}</p>
                </div>
              </div>
              <div className={styles.text}>
                <div className={styles.location}>
                  <span>Assessment:</span>
                  <p>{timeLineDetail.has_assessment ? 'Yes' : 'No'}</p>
                </div>
                <div className={styles.location}>
                  <span>
                    Fee:{' '}
                    {timeLineDetail.payment.source_type
                      ? timeLineDetail.payment.source_type
                      : 'Card'}
                    ****{timeLineDetail.payment.card_details.last_4}
                  </span>
                  <p>$ {timeLineDetail.payment.amount}</p>
                </div>
              </div>
            </div>
          </div>
        );
      case 'Intake Form':
        return (
          <div className={styles.contentDetail}>
            <div className={styles.box}>
              <div className={styles['content-detail']}>
                {timeLineDetail.id ? (
                  <>
                    <h2>Personal Details</h2>
                    <div className={styles.text}>
                      <span>Name:</span>
                      <p>{timeLineDetail.personalDetails.name}</p>
                    </div>
                    <div className={styles.text}>
                      <span>Address:</span>
                      <p>{timeLineDetail.personalDetails.address}</p>
                    </div>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>City:</span>
                        <p>{timeLineDetail.personalDetails.city}</p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>State:</span>
                        <p>{timeLineDetail.personalDetails.state}</p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Zip/Postal Code:</span>
                        <p>{timeLineDetail.personalDetails.postalCode}</p>
                      </div>
                    </div>
                    <h2>Contact Details</h2>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>Home Phone:</span>
                        <p>{timeLineDetail.contactDetails.homePhone}</p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Cell Phone:</span>
                        <p>{timeLineDetail.contactDetails.cellPhone}</p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Work Phone:</span>
                        <p>{timeLineDetail.contactDetails.workPhone}</p>
                      </div>
                      <div className={styles.type}>
                        <span>Type:</span>
                        <p>{timeLineDetail.patient_type}</p>
                      </div>
                      <div className={styles.location}>
                        <span>Mobile:</span>
                        <p>{timeLineDetail.patient_phone}</p>
                      </div>
                      <div
                        className={classnames(styles.location, styles.reason)}
                      >
                        <span>Booking Type:</span>
                        <p>
                          {timeLineDetail.bookingType &&
                          timeLineDetail.bookingType.name
                            ? timeLineDetail.bookingType.name
                            : '-'}
                        </p>
                      </div>
                    </div>
                    <div className={styles.text}>
                      <span>Email:</span>
                      <p>{timeLineDetail.contactDetails.email}</p>
                    </div>
                    <h2>Other Details</h2>
                    <div className={styles.text}>
                      <span>SSH#</span>
                      <p>{timeLineDetail.otherDetails.ssid}</p>
                    </div>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>Age</span>
                        <p>{timeLineDetail.otherDetails.age}</p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>BirthDate</span>
                        <p>{timeLineDetail.otherDetails.birthDate}</p>
                      </div>
                    </div>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>Your Gender</span>
                        <p>{timeLineDetail.otherDetails.gender}</p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Do you identify as transgender?</span>
                        <p>
                          {timeLineDetail.otherDetails.isTransgender === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                    </div>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>Education</span>
                        <p>{timeLineDetail.otherDetails.education}</p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Current Occupation</span>
                        <p>{timeLineDetail.otherDetails.currentOccupation}</p>
                      </div>
                    </div>
                    <h2>Medical Emergency Contact Details</h2>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>Name</span>
                        <p>{timeLineDetail.medicalEmergency.name}</p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Address</span>
                        <p>{timeLineDetail.medicalEmergency.address}</p>
                      </div>
                    </div>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>Relation</span>
                        <p>{timeLineDetail.medicalEmergency.relation}</p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Phone</span>
                        <p>{timeLineDetail.medicalEmergency.phone}</p>
                      </div>
                    </div>
                    <h2>Family Doctor Details</h2>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>Name</span>
                        <p>{timeLineDetail.familyDoctor.name}</p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Phone:</span>
                        <p>{timeLineDetail.familyDoctor.phone}</p>
                      </div>
                    </div>
                    <h2>Family Details</h2>
                    <div className={styles.text}>
                      <span>RelationShip Status</span>
                      <p>{timeLineDetail.contactDetails.relationshipStatus}</p>
                    </div>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>Spouse Name</span>
                        <p>{timeLineDetail.familyDetails.spouseName}</p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Spouse Phone:</span>
                        <p>{timeLineDetail.familyDetails.spousePhone}</p>
                      </div>
                    </div>
                    <h2>Primary Insurance Details</h2>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>Company Name</span>
                        <p>{timeLineDetail.insuranceDetails.companyName}</p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Ins ID#</span>
                        <p>{timeLineDetail.insuranceDetails.insuranceId}</p>
                      </div>
                    </div>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>Group Name</span>
                        <p>{timeLineDetail.insuranceDetails.groupName}</p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Group ID#</span>
                        <p>{timeLineDetail.insuranceDetails.groupId}</p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Employer</span>
                        <p>{timeLineDetail.insuranceDetails.employer}</p>
                      </div>
                    </div>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>Subscriber's Name</span>
                        <p>{timeLineDetail.insuranceDetails.subscriberName}</p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Relation</span>
                        <p>
                          {timeLineDetail.insuranceDetails.subscriberRelation}
                        </p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Subscriber's DOB</span>
                        <p>{timeLineDetail.insuranceDetails.subscriberDOB}</p>
                      </div>
                    </div>
                    <h2>Medical History</h2>
                    <div className={styles.text}>
                      <span>Allergies</span>
                      <p>{timeLineDetail.allergies.toString()}</p>
                    </div>
                    {timeLineDetail.currentMedications.length > 0 && (
                      <>
                        <h2>Current Medications</h2>
                        <div className={styles.detail}>
                          <div className={classnames(styles[`table`])}>
                            <div className={classnames(styles[`table-tr`])}>
                              <div className={classnames('text')}>Dose</div>
                              <div className={classnames('text')}>
                                frequency
                              </div>
                              <div className={classnames('text')}>DateUsed</div>
                            </div>
                            {timeLineDetail.currentMedications.map(
                              (item, index) => (
                                <div
                                  key={index}
                                  className={classnames(styles[`table-tr`])}
                                >
                                  <p>{item.dose}</p>
                                  <p>{item.frequency}</p>
                                  <p>{item.dateUsed}</p>
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {timeLineDetail.recreationalDrugs.length > 0 && (
                      <>
                        <h2>Recreational Durgs</h2>
                        <div className={styles.detail}>
                          <div className={classnames(styles[`table`])}>
                            <div className={classnames(styles[`table-tr`])}>
                              <div className={classnames('text')}>Method</div>
                              <div className={classnames('text')}>
                                frequency
                              </div>
                              <div className={classnames('text')}>
                                experience
                              </div>
                              <div className={classnames('text')}>DateUsed</div>
                            </div>
                            {timeLineDetail.recreationalDrugs.map(
                              (item, index) => (
                                <div
                                  key={index}
                                  className={classnames(styles[`table-tr`])}
                                >
                                  <p>{item.method}</p>
                                  <p>{item.frequency}</p>
                                  <p>{item.experience}</p>
                                  <p>{item.dateUsed}</p>
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {timeLineDetail.medicalIllness.length > 0 && (
                      <>
                        <h2>Medical Illness</h2>
                        <div className={styles.detail}>
                          <div className={classnames(styles[`table`])}>
                            <div className={classnames(styles[`table-tr`])}>
                              <div
                                className={classnames('text', styles.column3)}
                              >
                                illness
                              </div>
                              <div
                                className={classnames('text', styles.column3)}
                              >
                                status
                              </div>
                            </div>
                            {timeLineDetail.medicalIllness.map(
                              (item, index) => (
                                <div
                                  key={index}
                                  className={classnames(styles[`table-tr`])}
                                >
                                  <p>{item.illness}</p>
                                  <p>{item.status}</p>
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    <div className={styles.text}>
                      <span>Previously psychological care or counseling?</span>
                      <p>
                        {timeLineDetail.hasPreviousPsychologicalCare === true
                          ? 'Yes'
                          : 'No'}
                      </p>
                    </div>
                    {timeLineDetail.psychologicalCare.length > 0 && (
                      <>
                        <h2>Psychological Care</h2>
                        <div className={styles.detail}>
                          <div className={classnames(styles[`table`])}>
                            <div className={classnames(styles[`table-tr`])}>
                              <div className={classnames('text')}>
                                clinicians
                              </div>
                              <div className={classnames('text')}>
                                difficulty
                              </div>
                              <div className={classnames('text')}>date</div>
                            </div>
                            {timeLineDetail.psychologicalCare.map(
                              (item, index) => (
                                <div
                                  key={index}
                                  className={classnames(styles[`table-tr`])}
                                >
                                  <p>{item.clinicians}</p>
                                  <p>{item.difficulty}</p>
                                  <p>{item.date}</p>
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    <div className={styles.text}>
                      <span>
                        Have you been prescribed psychiatric medications?
                      </span>
                      <p>
                        {timeLineDetail.hasPsychiatricMedications === true
                          ? 'Yes'
                          : 'No'}
                      </p>
                    </div>
                    {timeLineDetail.psychiatricMedications.length > 0 && (
                      <>
                        <h2>Psychiatric Medications</h2>
                        <div className={styles.detail}>
                          <div className={classnames(styles[`table`])}>
                            <div className={classnames(styles[`table-tr`])}>
                              <div className={classnames('text')}>medicine</div>
                              <div className={classnames('text')}>dose</div>
                              <div className={classnames('text')}>effects</div>
                            </div>
                            {timeLineDetail.psychiatricMedications.map(
                              (item, index) => (
                                <div
                                  key={index}
                                  className={classnames(styles[`table-tr`])}
                                >
                                  <p>{item.medicine}</p>
                                  <p>{item.dose}</p>
                                  <p>{item.effects}</p>
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    <div className={styles.text}>
                      <span>
                        Have you ever been hospitalized for a psychological
                        difficulty?
                      </span>
                      <p>
                        {timeLineDetail.hasHospitalized === true ? 'Yes' : 'No'}
                      </p>
                    </div>
                    <div className={styles.text}>
                      <span>
                        Have you ever had feelings or thoughts that you didn't
                        want to live?
                      </span>
                      <p>
                        {timeLineDetail.hasSuicidalThoughts === true
                          ? 'Yes'
                          : 'No'}
                      </p>
                    </div>
                    <div className={styles.text}>
                      <span>sleeping habits</span>
                      <p>{timeLineDetail.sleepingHabits}</p>
                    </div>
                    <div className={styles.text}>
                      <span>Do you have difficulty falling asleep?</span>
                      <p>
                        {timeLineDetail.difficultyFallingAsleep === true
                          ? 'Yes'
                          : 'No'}
                      </p>
                    </div>
                    <div className={styles.text}>
                      <span>Do you have awakenings during the night?</span>
                      <p>
                        {timeLineDetail.awakeningDuringNight === true
                          ? 'Yes'
                          : 'No'}
                      </p>
                    </div>
                    <div className={styles.text}>
                      <span>Unrefreshing Sleep</span>
                      <p>{timeLineDetail.poorSleep === true ? 'Yes' : 'No'}</p>
                    </div>
                    <div className={styles.text}>
                      <span>
                        how long have you been experiencing Poor Sleep Problems?
                      </span>
                      <p>{timeLineDetail.sleepingProblemSince}</p>
                    </div>
                    <div className={styles.text}>
                      <span>Weight</span>
                      <p>{timeLineDetail.weight}</p>
                    </div>
                    <div className={styles.text}>
                      <span>Height</span>
                      <p>{timeLineDetail.height}</p>
                    </div>
                    <div className={styles.text}>
                      <span>Happy with your Weight</span>
                      <p>
                        {timeLineDetail.isHappyWithWeight === true
                          ? 'Yes'
                          : 'No'}
                      </p>
                    </div>
                    <div className={styles.text}>
                      <span>recently lost or gained weight?</span>
                      <p>
                        {timeLineDetail.hasLostGainedWeight === true
                          ? 'Yes'
                          : 'No'}
                      </p>
                    </div>
                    <div className={styles.text}>
                      <span>Difficulty With Appetite</span>
                      <p>{timeLineDetail.difficultyWithAppetite}</p>
                    </div>
                    <div className={styles.text}>
                      <span>Depression Level</span>
                      <p>{timeLineDetail.depressionLevel}</p>
                    </div>
                    <div className={styles.text}>
                      <span>Depression Since</span>
                      <p>{timeLineDetail.depressionSince}</p>
                    </div>
                    <div className={styles.text}>
                      <span>experiencing anxiety</span>
                      <p>{timeLineDetail.hasAnxiety === true ? 'Yes' : 'No'}</p>
                    </div>
                    <div className={styles.text}>
                      <span>anxiety Since</span>
                      <p>{timeLineDetail.anxietySince}</p>
                    </div>
                    <div className={styles.text}>
                      <span>alcohol Per Week</span>
                      <p>{timeLineDetail.alchoholPerWeek}</p>
                    </div>
                    <div className={styles.text}>
                      <span>alcohol Per Night</span>
                      <p>{timeLineDetail.alchoholPerNight}</p>
                    </div>
                    <div className={styles.text}>
                      <span>alcohol Per Hour</span>
                      <p>{timeLineDetail.alchoholPerHour}</p>
                    </div>
                    <div className={styles.text}>
                      <span>How often do you use recreational drugs</span>
                      <p>{timeLineDetail.recreationalDrugsFrequency}</p>
                    </div>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>
                          dependency on alcohol or recreational drugs?
                        </span>
                        <p>
                          {timeLineDetail.hadAlchoholDrugDependency === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Nature of dependency or abuse</span>
                        <p>{timeLineDetail.natureOfAlchoholDepenency}</p>
                      </div>
                    </div>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>
                          have a history of prescription drug dependency or
                          abuse?
                        </span>
                        <p>
                          {timeLineDetail.hadPrescreptionDrugDependency === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Nature of dependency or abuse</span>
                        <p>{timeLineDetail.natureOfPrescreptionDependency}</p>
                      </div>
                    </div>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>Is Pregnant</span>
                        <p>
                          {timeLineDetail.isPregnant === true ? 'Yes' : 'No'}
                        </p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Form of BC do you use</span>
                        <p>{timeLineDetail.formOfBC}</p>
                      </div>
                    </div>
                    <h2>
                      Has anyone in your family been diagnosed with or treated
                      for
                    </h2>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>Bipolar disorder</span>
                        <p>
                          {timeLineDetail.familyMemberBipolarDisorder === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Anxiety</span>
                        <p>
                          {timeLineDetail.familyMemberAnxiety === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                    </div>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>PTSD</span>
                        <p>
                          {timeLineDetail.familyMemberPTSD === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Anger</span>
                        <p>
                          {timeLineDetail.familyMemberAnger === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                    </div>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>Other substance abuse</span>
                        <p>
                          {timeLineDetail.familyMemberOtherSubstance === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Depression</span>
                        <p>
                          {timeLineDetail.familyMemberDepression === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                    </div>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>Violence</span>
                        <p>
                          {timeLineDetail.familyMemberViolence === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Alcohol abuse</span>
                        <p>
                          {timeLineDetail.familyMemberAlcoholAbuse === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                    </div>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>Schizophrenia</span>
                        <p>
                          {timeLineDetail.familyMemberSchizophrenia === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>Suicide</span>
                        <p>
                          {timeLineDetail.familyMemberSuicide === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                    </div>
                    <div className={styles.text}>
                      <span>What Problems</span>
                      <p>{timeLineDetail.familyMemberTreatment}</p>
                    </div>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>
                          Has any family member been treated with a psychiatric
                          medication?
                        </span>
                        <p>
                          {timeLineDetail.hasFamilyMemberPsychatric === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>
                          If yes, who was treated, what medications, and how
                          effective was the treatment?
                        </span>
                        <p>{timeLineDetail.familyMemberPsychatricTreatment}</p>
                      </div>
                    </div>
                    <div className={styles.detail}>
                      <div className={classnames('text', styles.column3)}>
                        <span>Natue OF Therapy</span>
                        <p>{timeLineDetail.natureOfTherapy}</p>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>significantTrauma</span>
                        <p>{timeLineDetail.significantTrauma}</p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div>No Data Available</div>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      case 'Appointment':
        return (
          <div>
            <div className={styles['profile']}>
              <div className={styles.profileDiv}>
                <div className={styles.timeDiv}>
                  <img
                    src={
                      useAvatar
                        ? useAvatar
                        : require('../../../img/patient.jpg')
                    }
                    alt="patient_profile"
                  />
                  <div className={styles.personalDetailsDiv}>
                    <h2>
                      {patientDetails ? patientDetails.first_name : ''}{' '}
                      {patientDetails ? patientDetails.last_name : ''}
                    </h2>
                    <div className={styles['profile-detail']}>
                      {patientDetails && patientDetails.phone && (
                        <div className={styles.patientDetails}>
                          <span>Mobile:</span>
                          <p>{patientDetails ? patientDetails.phone : ''}</p>
                        </div>
                      )}

                      <div className={styles.patientDetails}>
                        <span>Email:</span>
                        <p>{patientDetails ? patientDetails.email : ''}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {assignedForms.map((form, index) => (
                <Fragment key={index}>
                  <FormListView
                    key={form.id}
                    item={form}
                    userType={authUser.profile_type}
                    isView={true}
                    onConfirmRemove={handleRemoveForm}
                    viewSign={
                      authUser.profile_type === USER_TYPES.DOCTOR &&
                      (form.doctor_id === authUser.id ||
                        form.clinic_id === authUser.clinic_id)
                    }
                  />
                </Fragment>
              ))}
              {matchingAssessment && (
                <FormsList
                  form={matchingAssessment}
                  title="Matching Assessment"
                  isView={true}
                  isAssessment={true}
                />
              )}
            </div>
          </div>
        );
      default:
        return;
    }
  };

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <DoctorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            ></div>
            <h1 data-testid="patient_profile header">Patient Profile</h1>
            <div className={styles['profile']}>
              <div className={styles.profileDiv}>
                <div className={styles.timeDiv}>
                  <img
                    src={
                      useAvatar
                        ? useAvatar
                        : require('../../../img/patient.jpg')
                    }
                    alt="patient_profile"
                    data-testid="patient_profile pic"
                  />
                  <div className={styles.personalDetailsDiv}>
                    <h2 data-testid="patient_profile name">
                      {patientDetails ? patientDetails.first_name : ''}{' '}
                      {patientDetails ? patientDetails.last_name : ''}
                    </h2>

                    <div className={styles['profile-detail']}>
                      {patientDetails && patientDetails.phone && (
                        <div className={styles.patientDetails}>
                          <span data-testid="patient_profile phone label">
                            Mobile:
                          </span>
                          <p data-testid="patient_profile phone value">
                            {patientDetails ? patientDetails.phone : ''}
                          </p>
                        </div>
                      )}
                      <div className={styles.patientDetails}>
                        <span data-testid="patient_profile email label">
                          Email:
                        </span>
                        <p data-testid="patient_profile email value">
                          {patientDetails ? patientDetails.email : ''}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.headerButtons}>
                  {patientDetails &&
                  patientDetails.status === PATIENT_DETAILS_STATUS.INACTIVE &&
                  patientDetails.invitation_status === true ? (
                    <SecondaryButton
                      label="Resend Invitation"
                      type="button"
                      className={styles.btn}
                      onClick={() => {
                        setShowInvitationModal(true);
                      }}
                      data-testid="Resend Invitation button"
                    />
                  ) : (
                    <BillingPlanFeature patientDetails>
                      <PrimaryButton
                        label="View Client Details"
                        type="button"
                        className={styles.viewDetails}
                        onClick={setShowClientDetailModal}
                        data-testid="PatientDetails.Doctor.ViewClientDetails"
                      />
                    </BillingPlanFeature>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div>
                <hr />
                <div className={styles.text}>
                  <div className={styles.firstOrder}>
                    {patientDetails && (
                      <div className={classnames(styles.type)}>
                        {patientDetails && patientDetails.invitation_status ? (
                          <div className={styles.yesNoDiv}>
                            <img
                              className={styles.yesNo}
                              src={Happy}
                              alt="yes"
                            />
                          </div>
                        ) : (
                          <div className={styles.yesNoDiv}>
                            <img
                              className={styles.yesNo}
                              src={Neutral}
                              alt="no"
                            />
                            <div
                              className={classnames(
                                styles.neutralLabel,
                                styles.neutralPopUp,
                              )}
                            >
                              User not invited
                            </div>
                          </div>
                        )}
                        <span data-testid="patient_profile invitation label">
                          {patientDetails.invitation_status
                            ? 'Invited'
                            : 'User not invited'}
                        </span>
                      </div>
                    )}
                    {patientDetails && (
                      <div className={classnames(styles.location)}>
                        {patientDetails.status ===
                        PATIENT_DETAILS_STATUS.ACTIVE ? (
                          <div className={styles.yesNoDiv}>
                            <img
                              className={styles.yesNo}
                              src={Happy}
                              alt="yes"
                            />
                            <div
                              className={classnames(
                                styles.positivePopUp,
                                styles.yesLabel,
                              )}
                            >
                              {moment(patientDetails.createdAt).format(
                                ' MMMM DD, YYYY',
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className={styles.yesNoDiv}>
                            <img className={styles.yesNo} src={Sad} alt="no" />
                            <div
                              className={classnames(
                                styles.noLabel,
                                styles.negativePopUp,
                              )}
                            >
                              No Data Found
                            </div>
                          </div>
                        )}
                        <span data-testid="patient_profile registered label">
                          Registered
                        </span>
                      </div>
                    )}
                    {patientDetails && !patientDetails.invitation_status && (
                      <div className={classnames(styles.location)}>
                        {patientDetails.assessment ? (
                          <div className={styles.yesNoDiv}>
                            <img
                              className={styles.yesNo}
                              src={Happy}
                              alt="yes"
                            />
                            <div
                              className={classnames(
                                styles.positivePopUp,
                                styles.yesLabel,
                              )}
                            >
                              {moment(
                                patientDetails.assessment.createdAt,
                              ).format(' MMMM DD, YYYY')}
                            </div>
                          </div>
                        ) : (
                          <div className={styles.yesNoDiv}>
                            <img className={styles.yesNo} src={Sad} alt="no" />
                            <div
                              className={classnames(
                                styles.noLabel,
                                styles.negativePopUp,
                              )}
                            >
                              No Data Found
                            </div>
                          </div>
                        )}
                        <span data-testid="patient_profile matching assessment label">
                          Matching Assessment
                        </span>
                      </div>
                    )}
                  </div>
                  <div
                    medium={6}
                    className={classnames(styles.mobileOrder, 'mobile-only')}
                  >
                    {patientDetails && (
                      <div className={classnames(styles.location)}>
                        {patientDetails.intake_form1 &&
                        patientDetails.intake_form1.id ? (
                          <div className={styles.yesNoDiv}>
                            <img
                              className={styles.yesNo}
                              src={Happy}
                              alt="yes"
                            />
                            <div
                              className={classnames(
                                styles.positivePopUp,
                                styles.yesLabel,
                              )}
                            >
                              {moment(
                                patientDetails.intake_form1.createdAt,
                              ).format(' MMMM DD, YYYY')}
                            </div>
                          </div>
                        ) : (
                          <div className={styles.yesNoDiv}>
                            <img className={styles.yesNo} src={Sad} alt="no" />
                            <div
                              className={classnames(
                                styles.noLabel,
                                styles.negativePopUp,
                              )}
                            >
                              No Data Found
                            </div>
                          </div>
                        )}
                        <span data-testid="patient_profile intake form label">
                          Intake Form
                        </span>
                      </div>
                    )}
                    {patientDetails && (
                      <div className={styles.location}>
                        {patientDetails.payment &&
                        patientDetails.payment.createdAt ? (
                          <div className={styles.yesNoDiv}>
                            <img
                              className={styles.yesNo}
                              src={Happy}
                              alt="yes"
                            />
                            <div
                              className={classnames(
                                styles.positivePopUp,
                                styles.yesLabel,
                              )}
                            >
                              {moment(patientDetails.payment.createdAt).format(
                                ' MMMM DD, YYYY',
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className={styles.yesNoDiv}>
                            <img className={styles.yesNo} src={Sad} alt="no" />
                            <div
                              className={classnames(
                                styles.noLabel,
                                styles.negativePopUp,
                              )}
                            >
                              No Data Found
                            </div>
                          </div>
                        )}
                        <span data-testid="patient_profile paid label">
                          Paid
                        </span>
                      </div>
                    )}
                    {patientDetails && (
                      <div className={classnames(styles.location)}>
                        {patientDetails.appointment &&
                        patientDetails.appointment.createdAt ? (
                          <div className={styles.yesNoDiv}>
                            <img
                              className={styles.yesNo}
                              src={Happy}
                              alt="yes"
                            />
                            <div
                              className={classnames(
                                styles.positivePopUp,
                                styles.yesLabel,
                              )}
                            >
                              {moment(
                                patientDetails.appointment.createdAt,
                              ).format(' MMMM DD, YYYY')}
                            </div>
                          </div>
                        ) : (
                          <div className={styles.yesNoDiv}>
                            <img className={styles.yesNo} src={Sad} alt="no" />
                            <div
                              className={classnames(
                                styles.noLabel,
                                styles.negativePopUp,
                              )}
                            >
                              No Data Found
                            </div>
                          </div>
                        )}
                        <span data-testid="patient_profile initail appointment label">
                          Initial Appointment
                        </span>
                      </div>
                    )}
                  </div>
                  <div
                    className={classnames(styles.desktopOrder, 'desktop-only')}
                  >
                    {patientDetails && (
                      <div className={classnames(styles.location)}>
                        {patientDetails.appointment &&
                        patientDetails.appointment.createdAt ? (
                          <div className={styles.yesNoDiv}>
                            <img
                              className={styles.yesNo}
                              src={Happy}
                              alt="yes"
                            />
                            <div
                              className={classnames(
                                styles.positivePopUp,
                                styles.yesLabel,
                              )}
                            >
                              {moment(
                                patientDetails.appointment.createdAt,
                              ).format(' MMMM DD, YYYY')}
                            </div>
                          </div>
                        ) : (
                          <div className={styles.yesNoDiv}>
                            <img className={styles.yesNo} src={Sad} alt="no" />
                            <div
                              className={classnames(
                                styles.noLabel,
                                styles.negativePopUp,
                              )}
                            >
                              No Data Found
                            </div>
                          </div>
                        )}
                        <span data-testid="patient_profile initial appointment label">
                          Initial Appointment
                        </span>
                      </div>
                    )}
                    {patientDetails && (
                      <div className={styles.location}>
                        {patientDetails.payment &&
                        patientDetails.payment.createdAt ? (
                          <div className={styles.yesNoDiv}>
                            <img
                              className={styles.yesNo}
                              src={Happy}
                              alt="yes"
                            />
                            <div
                              className={classnames(
                                styles.positivePopUp,
                                styles.yesLabel,
                              )}
                            >
                              {moment(patientDetails.payment.createdAt).format(
                                ' MMMM DD, YYYY',
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className={styles.yesNoDiv}>
                            <img className={styles.yesNo} src={Sad} alt="no" />
                            <div
                              className={classnames(
                                styles.noLabel,
                                styles.negativePopUp,
                              )}
                            >
                              No Data Found
                            </div>
                          </div>
                        )}
                        <span data-testid="patient_profile paid label">
                          Paid
                        </span>
                      </div>
                    )}

                    {patientDetails && (
                      <div className={classnames(styles.location)}>
                        {patientDetails.intake_form1 &&
                        patientDetails.intake_form1.id ? (
                          <div className={styles.yesNoDiv}>
                            <img
                              className={styles.yesNo}
                              src={Happy}
                              alt="yes"
                            />
                            <div
                              className={classnames(
                                styles.positivePopUp,
                                styles.yesLabel,
                              )}
                            >
                              {moment(
                                patientDetails.intake_form1.createdAt,
                              ).format(' MMMM DD, YYYY')}
                            </div>
                          </div>
                        ) : (
                          <div className={styles.yesNoDiv}>
                            <img className={styles.yesNo} src={Sad} alt="no" />
                            <div
                              className={classnames(
                                styles.noLabel,
                                styles.negativePopUp,
                              )}
                            >
                              No Data Found
                            </div>
                          </div>
                        )}
                        <span data-testid="patient_profile intake form label">
                          Intake Form
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <nav className={styles.tabs}>
                {ubpTabs.map((tab) => (
                  <button
                    key={tab}
                    className={[
                      styles.tab,
                      activeTab === tab ? styles.activeTab : '',
                    ].join(' ')}
                    type="button"
                    onClick={() => onTabClick(tab)}
                    data-testid={`patient_profile_${tab} button`}
                  >
                    <span data-testid={`patient_profile_${tab}`}>{tab}</span>
                  </button>
                ))}
              </nav>
              {activeTab === TAB.ASS_FORMS && (
                <div>
                  {assignedForms.map((form, index) => (
                    <FormListView
                      key={form.id}
                      item={form}
                      userType={authUser.profile_type}
                      isView={true}
                      onConfirmRemove={handleRemoveForm}
                      viewSign={
                        authUser.profile_type === USER_TYPES.DOCTOR &&
                        (form.doctor_id === authUser.id ||
                          form.clinic_id === authUser.clinic_id)
                      }
                    />
                  ))}
                  {matchingAssessment && (
                    <FormsList
                      form={matchingAssessment}
                      title="Matching Assessment"
                      isView={true}
                      isAssessment={true}
                    />
                  )}
                  <div className={styles.buttonRow}>
                    <SecondaryButton
                      label="Add New"
                      type="button"
                      className={styles.btn}
                      onClick={() => {
                        setShowNewFormModal(true);
                      }}
                      data-testid="add new form button"
                    />
                  </div>
                </div>
              )}
              {activeTab === TAB.NOTES && (
                <div data-testid="notes-list">
                  {notes.map((note, index) => {
                    return (
                      <NotesList
                        key={note.id}
                        doctor={
                          note.first_name +
                          (note.last_name ? ' ' + note.last_name : '')
                        }
                        updatedAt={note.updatedAt}
                        canEdit={note.id === authUser.id}
                        canAdd={note.id === authUser.id}
                        notes={note.notes}
                        onNoteSave={() => fetchAllNotes()}
                        appointment={userDetail}
                        canView={true}
                        showTitle={true}
                        readOnly={note.id !== authUser.id}
                        loggedInId={authUser.id}
                      />
                    );
                  })}
                </div>
              )}
              {activeTab === TAB.TIMELINE && (
                <div className={styles.timeline} data-testid="timelines">
                  <div className={styles.timelineHead}>
                    <span
                      className={styles.heading}
                      data-testid="timeline datetime label"
                    >
                      Date & time
                    </span>
                    <span
                      className={styles.heading}
                      data-testid="timeline event label"
                    >
                      Event
                    </span>
                    <span
                      className={styles.heading}
                      data-testid="timeline details label"
                    >
                      Details
                    </span>
                  </div>
                  <ul className={styles.timelineList}>
                    {timeline.map((item, Index) => (
                      <li key={Index}>
                        <div data-testid="timeline datetime value">
                          {moment(item.createdAt).format('MM-DD-YYYY HH:mm A')}
                        </div>
                        <div
                          className={styles.timelineDetails}
                          data-testid="timeline event value"
                        >
                          {item.name}{' '}
                        </div>
                        <div
                          className={styles.connect}
                          style={{ marginLeft: `${Index * 20}px` }}
                          onClick={() => handleTimelineClick(item)}
                          data-testid="timeline details click"
                        >
                          <div className={styles.timelineBox}>
                            <span data-testid="timeline details value">
                              {item.details.title}
                            </span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {activeTab === TAB.FILES && <PatientFiles patientId={id} />}
            </div>
          </div>
        </Fragment>
      )}

      {showClientDetailModal && (
        <ClientDetailsModal
          patient={patientDetails}
          setShowClientDetailModal={setShowClientDetailModal}
        />
      )}

      {showDetailModal && (
        <Modal className={styles.modal}>
          <div className={styles.content}>
            <div
              className={styles.closeButton}
              onClick={() => setShowDetailModal(false)}
            >
              <img src={require(`../../../img/close.svg`)} alt={`Close`} />
            </div>
            <h1>
              <b>Patient Detail</b>
            </h1>
            {getDetailContent('Appointment')}
          </div>
        </Modal>
      )}

      {showNewFormModal && (
        <Modal className={classnames(styles.modal, styles.formModal)}>
          <div className={styles.content}>
            <div
              className={styles.closeButton}
              onClick={handleCancelAssignForm}
              data-testid="assign form close"
            >
              <img src={require(`../../../img/close.svg`)} alt={`Close`} />
            </div>
            <h1>
              <b data-testid="assign_form header">Assign Form</b>
            </h1>
            <Cell small={12} medium={12} className={styles['label-bold']}>
              <FieldDropdown
                label=""
                name="form"
                onChange={(e) => {
                  setFormId(e.target.value);
                }}
                options={formattedFormLists}
                placeholder="Select Form"
                dataTestId="PatientDetails.AssignForm.Dropdown"
              />
            </Cell>
            <div className={styles.buttonrow}>
              <PrimaryButton
                label="Save"
                className={styles.primaryButton}
                onClick={handleAssignForm}
                disabled={!formId}
                data-testid="assign_form save button"
              />
              <SecondaryButton
                label="Cancel"
                className={styles.secondaryButton}
                onClick={handleCancelAssignForm}
                data-testid="assign_form cancel button"
              />
            </div>
          </div>
        </Modal>
      )}

      {showInvitationModal && (
        <Modal className={styles.modal}>
          <div className={styles.content}>
            <div
              className={styles.closeButton}
              onClick={() => setShowInvitationModal(false)}
              data-testid="send invitation close icon"
            >
              <img src={require(`../../../img/close.svg`)} alt={`Close`} />
            </div>
            <h1>
              <b data-testid="send invitation header">Resend Invitation</b>
            </h1>
            <Cell small={12} medium={12} className={styles['label-bold']}>
              <FieldInput
                label="First Name"
                disabled={true}
                name="first_name"
                value={patientDetails && patientDetails.first_name}
                data-testid="send invitation first_name"
                label-testid="send invitation first_name label"
                error-testid="send invitation first_name error"
              />
            </Cell>
            <Cell small={12} medium={12} className={styles['label-bold']}>
              <FieldInput
                label="Email"
                name="email"
                value={patientEmail}
                onChange={(e) => {
                  setPatientEmail(e.target.value);
                }}
                data-testid="send invitation email"
                label-testid="send invitation email label"
                error-testid="send invitation email error"
              />
              {error && (
                <div
                  className={styles.typeError}
                  data-testid="send invitation error container"
                >
                  {' '}
                  {error}
                </div>
              )}
            </Cell>
            <div className={styles.buttonrow}>
              <PrimaryButton
                label="Send Invitation"
                data-testid="send invitation form button"
                className={styles.primaryButton}
                onClick={async () => {
                  try {
                    setLoading(true);
                    await resendInvitation(id, { email: patientEmail });
                    setLoading(false);
                    toast('Invitation sent successfuly', {
                      autoClose: 5000,
                      className: styles.toastMsg,
                      bodyClassName: styles.toastDiv,
                      hideProgressBar: true,
                    });
                    setShowInvitationModal(false);
                    fetchPatient();
                  } catch (error) {
                    toast('Something is wrong. Please try again', {
                      autoClose: 5000,
                      className: styles.ErrortoastMsg,
                      bodyClassName: styles.toastDiv,
                      hideProgressBar: true,
                    });
                    setError(
                      error &&
                        error.response &&
                        error.response.data &&
                        error.response.data.message,
                    );
                    setLoading(false);
                  }
                }}
              />
              <SecondaryButton
                label="Cancel"
                className={styles.secondaryButton}
                onClick={() => {
                  setShowInvitationModal(false);
                }}
                data-testid="send invitation form cancel button"
              />
            </div>
          </div>
        </Modal>
      )}

      {showMessageModal && (
        <MessageModal
          message={displayMessage}
          displayLogo={false}
          onCloseModal={() => setShowMessageModal(false)}
        />
      )}
    </div>
  );
};

export default PatientDetailsPage;
