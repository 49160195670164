import React, { useState, useEffect, Fragment, useCallback } from 'react';
import styles from './ResetPasswordPage.module.scss';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import FieldInput from '../../components/common/fields/FieldInput/FieldInput';
import { useHistory } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import Loading from '../../components/common/Loading';
import Back from '../../img/icons/back.svg';
import * as Yup from 'yup';
import { OktaAuth } from '@okta/okta-auth-js';
import config from '../../config';
import Logo from '../../components/Logo';
import DynamicClinicText from '../../components/DynamicClinicText';
import MessageModal from '../../components/MessageModal';

const Schema = Yup.object().shape({
  password: Yup.string()
    .required('Field Required')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?&-]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number',
    ),
  confirmPassword: Yup.string().when('password', {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref('password')],
      'Both password need to be the same',
    ),
  }),
});
const auth = new OktaAuth({
  ...config.oidc,
});
const ResetPasswordPage = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const param = useParams();
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [displayMessage, setDisplayMessage] = useState({
    title: '',
    message: '',
  });

  const checkToken = useCallback(async () => {
    if (param.resetToken) {
      try {
        await auth.verifyRecoveryToken({
          recoveryToken: param.resetToken,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setDisplayMessage({
          type: 'error',
          title: 'Token Expired',
          text: error.errorSummary,
          buttonLabel: 'OK',
          redirectUrl: '/',
        });
        setShowMessageModal(true);
      }
    }
  }, [param.resetToken]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  const handleResetPassword = async (values) => {
    const { password } = values;
    setIsLoading(true);
    try {
      const transaction = await auth.verifyRecoveryToken({
        recoveryToken: param.resetToken,
      });
      await transaction.resetPassword({
        newPassword: password,
      });

      setIsLoading(false);
      history.push('/');
    } catch (error) {
      if (error && error.errorSummary) {
        setError(error.errorCauses[0].errorSummary);
      } else {
        setError('Something went wrong.Try again later.');
      }
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.root}>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.container}>
            <div className={styles.backDiv}>
              <Link to="/">
                <img src={Back} alt="Back" />
                <span>Back</span>
              </Link>
            </div>
            <Logo />
            <div className={styles.content}>
              <h4>
                Reset your <DynamicClinicText /> Password
              </h4>
              <Formik
                initialValues={{
                  password: '',
                  confirmPassword: '',
                }}
                validationSchema={Schema}
                onSubmit={async (values, { setSubmitting }) => {
                  handleResetPassword(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <>
                      <FieldInput
                        className={styles.userInput}
                        autoComplete="current-password"
                        label="Password"
                        onBlur={handleBlur}
                        errorText={
                          (touched.password && errors.password) || error
                        }
                        name="password"
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        data-testid="password"
                      />
                      <div className={styles.validation}>
                        {values.password.length < 8 ? (
                          <p
                            style={{
                              fontFamily: 'Barlow',
                              fontSize: '12px',
                              letterSpacing: '0.75px',
                              lineHeight: '20px',
                            }}
                          >
                            Min. 8 characters
                          </p>
                        ) : (
                          <p style={{ color: 'green' }}>Min. 8 characters</p>
                        )}
                        {!/\d/.test(values.password) ? (
                          <p
                            style={{
                              fontFamily: 'Barlow',
                              fontSize: '12px',
                              letterSpacing: '0.75px',
                              lineHeight: '20px',
                            }}
                          >
                            Number
                          </p>
                        ) : (
                          <p style={{ color: 'green' }}>Number</p>
                        )}
                        {!/[a-z]/.test(values.password) ? (
                          <p
                            style={{
                              fontFamily: 'Barlow',
                              fontSize: '12px',
                              letterSpacing: '0.75px',
                              lineHeight: '20px',
                            }}
                          >
                            Lowercase letter
                          </p>
                        ) : (
                          <p style={{ color: 'green' }}>Lowercase letter</p>
                        )}

                        {!/[A-Z]/.test(values.password) ? (
                          <p
                            style={{
                              fontFamily: 'Barlow',
                              fontSize: '12px',
                              letterSpacing: '0.75px',
                              lineHeight: '20px',
                            }}
                          >
                            Uppercase letter
                          </p>
                        ) : (
                          <p style={{ color: 'green' }}>Uppercase letter</p>
                        )}
                      </div>
                      <FieldInput
                        className={styles.userInput}
                        label="Confirm Password"
                        errorText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                        onBlur={handleBlur}
                        name="confirmPassword"
                        onChange={handleChange}
                        type="password"
                        value={values.confirmPassword}
                        data-testid="confirmPassword"
                      />
                      <div className={styles.nextBtn}>
                        <PrimaryButton
                          className={styles.primaryBtn}
                          label="Reset Password"
                        />
                      </div>
                    </>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Fragment>
      )}
      {showMessageModal && (
        <MessageModal
          message={displayMessage}
          displayLogo={false}
          onCloseModal={() => {
            setShowMessageModal(false);
            history.push('/');
          }}
        />
      )}
    </div>
  );
};

export default ResetPasswordPage;
