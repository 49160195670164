import { defineMessages } from 'react-intl';

export default defineMessages({
  accountType: {
    id: 'component.ResearcherRegistration.accountType',
    defaultMessage: 'Choose account Type: ',
  },
  contactDetails: {
    firstNameLabel: {
      id: 'component.ResearcherRegistration.contactDetails.firstNameLabel',
      defaultMessage: 'First Name',
    },
    LastNameLabel: {
      id: 'component.ResearcherRegistration.contactDetails.LastNameLabel',
      defaultMessage: 'Last Name',
    },
  },
});
