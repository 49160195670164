import { Field, FieldArray, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Cell, Grid } from 'react-foundation';
import { useHistory } from 'react-router-dom';
import { getInsuranceList } from '../../../../api/doctor';
import { updatePatientDetails } from '../../../../api/patient';
import { getUserProfile } from '../../../../api/user';
import AuthContext from '../../../../context/AuthContext';
import useIntl from '../../../../hooks/useIntl';
import colors from '../../../../styles/_colors.scss';
import maritalStatuses from '../../../../utils/data/maritalStatuses.json';
import states from '../../../../utils/data/states.json';
import AddedItem from '../../../common/AddedItem/AddedItem';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import SecondaryButton from '../../../common/buttons/SecondaryButton';
import Datepicker from '../../../common/Datepicker';
import FieldDropdown from '../../../common/fields/FieldDropdown';
import FieldDropdownWithInput from '../../../common/fields/FieldDropdownWithInput';
import FieldInput from '../../../common/fields/FieldInput/FieldInput';
import RadioButton from '../../../common/inputs/RadioButton/RadioButton';
import RadioGroupWithLabel from '../../../common/inputs/RadioGroupWithLabel';
import Loading from '../../../common/Loading';
import SuperBillMessageModal from '../../../SuperBillForm/SuperBillMessageModal/SuperBillMessageModal';
import {
  alcoholAndDrugsOptions,
  contactMethods,
  genderOptions,
  responseOptions,
} from './data';
import IllnessesForm from './IllnessesForm/IllnessesForm';
import MedicationsForm from './MedicationsForm/MedicationsForm';
import messages from './messages';
import styles from './PersonalInfoForm.module.scss';
import { validate } from './validationSchema';
import { onKeyPress } from '../../../../utils/common';

export const customDropdownStyles = {
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  control: (base) => ({
    ...base,
    borderRadius: '1.625rem',
    width: '100%',
    height: '3.25rem',
    boxShadow: 0,
    border: `1px solid ${colors.grayLight2}`,
    '&:hover': {
      border: `1px solid ${colors.grayLight2}`,
    },
    '&:focus': {
      outlineWidth: 0,
      boxShadow: 'none',
      border: 'none',
    },
    background: 'white',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: colors.grayLight2,
    paddingRight: '1rem',
    transition: 'all .2s ease',
    transform: state.isFocused ? 'rotate(180deg)' : null,
    '&:hover': {
      color: colors.grayLight2,
    },
  }),
  singleValue: (base) => ({
    ...base,
    marginLeft: '1rem',
    fontFamily: 'inherit',
    fontSize: '1rem',
    color: '#000',
  }),
  multiValue: (base) => ({
    ...base,
    fontSize: '13px',
    fontFamily: 'Montserrat',
    borderRadius: '15px',
    color: colors.grayDark,
  }),
  multiValueLabel: (base) => ({
    padding: '4px',
    marginLeft: '5px',
  }),
  input: (base) => ({
    height: 'inherit',
  }),
  valueContainer: (base) => ({
    ...base,
    height: '100%',
    alignContent: 'center',
    overflow: 'auto',
    position: 'static',
    input: {
      boxShadow: 'none !important',
      marginLeft: '1rem',
      fontFamily: 'inherit',
      fontSize: '1rem',
      color: '#000',
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: 'inherit',
    fontSize: '1rem',
    paddingLeft: '1rem',
    opacity: '0.5',
  }),
};

const PersonalInfoForm = ({ fact_form_id }) => {
  const [patient, setPatient] = useState();
  const [medicationValue, setMedicationValue] = useState();
  const [editItemMedication, setEditItemMedication] = useState();
  const [illnessValue, setIllnessValue] = useState();
  const [editItemIllness, setEditItemIllness] = useState();
  const [insuranceList, setInsuranceList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const { authUser } = useContext(AuthContext);
  const { formatMessage } = useIntl();

  const initialValues = {
    first_name: patient?.first_name || '',
    last_name: patient?.last_name || '',
    phone: patient?.phone || '',
    dob: patient?.dob ? new Date(patient.dob) : null,
    email: patient?.email || '',
    allergies: patient?.details?.allergies || '',
    gender: patient?.details?.gender || '',
    preferred_pronouns: patient?.details?.preferred_pronouns || '',
    relationship_status: patient?.details?.relationship_status || '',
    street_address: patient?.details?.street_address || '',
    city: patient?.details?.city || '',
    state: patient?.details?.state || '',
    zip: patient?.details?.zip || '',
    home_phone: patient?.details?.home_phone || '',
    work_phone: patient?.details?.work_phone || '',
    contact_method: patient?.details?.contact_method || [],
    emergency_contact_name: patient?.details.emergency_contact_name || '',
    emergency_contact_relationship:
      patient?.details.emergency_contact_relationship || '',
    emergency_contact_phone: patient?.details.emergency_contact_phone || '',
    emergency_contact_email: patient?.details.emergency_contact_email || '',
    ssn: patient?.details?.ssn || '',
    insurance_provider: patient?.details?.insurance_provider || '',
    insurance_id: patient?.details?.insurance_id || '',
    name_insured: patient?.details?.name_insured || '',
    group_id: patient?.details?.group_id || '',
    primary_care_doctor: patient?.details?.primary_care_doctor || '',
    primary_care_doctor_phone:
      patient?.details?.primary_care_doctor_phone || '',
    preferred_pharmacy_address:
      patient?.details?.preferred_pharmacy_address || '',
    billing_street_address: patient?.details?.billing_street_address || '',
    billing_city: patient?.details?.billing_city || '',
    billing_state: patient?.details?.billing_state || '',
    billing_zip: patient?.details?.billing_zip || '',
    current_medications: patient?.details?.current_medications || [],
    illnesses_injuries: patient?.details?.illnesses_injuries || [],
    tobacco: patient?.details?.tobacco || '',
    alcoholic_beverages: patient?.details?.alcoholic_beverages || '',
    cannabis: patient?.details?.cannabis || '',
    cocaine: patient?.details?.cocaine || '',
    amphetamine_simulants: patient?.details?.amphetamine_simulants || '',
    inhalants: patient?.details?.inhalants || '',
    sedatives_pills: patient?.details?.sedatives_pills || '',
    hallucinogens: patient?.details?.hallucinogens || '',
    opioids: patient?.details?.opioids || '',
    others: patient?.details?.others || '',
  };

  const fetchData = useCallback(async () => {
    if (!authUser.id) {
      // TODO: remove redirect when we have the full user provider redirect.
      history.goBack();
    }
    setIsLoading(true);
    const { patient } = await getUserProfile();
    setPatient(patient);
    const insurances = await getInsuranceList();
    const formattedInsurances = insurances.map((insurance) => {
      return {
        label: insurance.company_name,
        value: insurance.company_name,
      };
    });
    formattedInsurances.unshift({
      label: 'None',
      value: 'None',
    });
    setInsuranceList(formattedInsurances);
    setIsLoading(false);
  }, [authUser.id, history]);

  useEffect(() => {
    if (!patient && !insuranceList.length) {
      fetchData();
    }
  }, [insuranceList, fetchData, patient]);

  const handleSubmit = async (values) => {
    formatValues(values);
    console.log({ values });
    try {
      setIsLoading(true);
      await updatePatientDetails(fact_form_id, values);
      setIsLoading(false);
      history.goBack();
    } catch (err) {
      console.log(err);
    }
  };

  const formatValues = (values) => {
    if (values.custom_relationship_status) {
      values.relationship_status = values.custom_relationship_status;
      delete values.custom_relationship_status;
    }
    if (medicationValue && Object.values(medicationValue).every(Boolean)) {
      if (!values.current_medications) {
        values.currentMedications = [];
      }
      values.current_medications.push({
        name: medicationValue.name,
        dose: medicationValue.dose,
        frequency: medicationValue.frequency,
      });
    }

    if (illnessValue) {
      if (!values.illnesses_injuries) {
        values.illnesses_injuries = [];
      }
      values.illnesses_injuries.push({
        description: illnessValue.description,
        dateOfOnset: illnessValue.dateOfOnset,
      });
    }
    setMedicationValue();
    setIllnessValue();
  };

  const handleCheckboxChange = (arrOfItems, value) => {
    const itemIndex = arrOfItems.indexOf(value);
    itemIndex === -1 ? arrOfItems.push(value) : arrOfItems.splice(itemIndex, 1);
    return arrOfItems;
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.form}>
      <Formik
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        validationSchema={validate}
        onKeyPress={onKeyPress}
        initialValues={initialValues}
        render={({
          values,
          handleChange,
          errors,
          touched,
          setFieldValue,
          handleBlur,
        }) => {
          const keys = Object.entries(errors);
          /**
           * The Array we are receiving from errors Object.entries is as the following example
           * [
           *  [
           *   0: "last_name"
           *   1: "Field is required"
           *  ]
           * ]
           * We take in consideration only the first error we receive and we scroll to that element and apply focus to it.
           * In this way the user cannot move forward without validating the field requirement
           */

          if (keys.length > 0) {
            if (keys[0][1].length > 0 || Object.keys(keys[0][1]).length > 0) {
              const selector = keys[0][0];
              const errorElement = document.getElementById(selector);
              errorElement.scrollIntoView({
                behavior: 'smooth',
              });
              errorElement.focus();
            }
          }

          // Add db custom values to the input dropdown options.
          if (
            values.gender &&
            !genderOptions.some((el) => el.value === values.gender)
          ) {
            genderOptions.push({ label: values.gender, value: values.gender });
          }

          // Add db custom values to the input dropdown options.
          if (
            values.relationship_status &&
            !maritalStatuses.some(
              (el) => el.value === values.relationship_status,
            )
          ) {
            maritalStatuses.push({
              label: values.relationship_status,
              value: values.relationship_status,
            });
          }

          // Add db custom values to the input dropdown options.
          if (
            values.insurance_provider &&
            !insuranceList.some((el) => el.value === values.insurance_provider)
          ) {
            insuranceList.push({
              label: values.insurance_provider,
              value: values.insurance_provider,
            });
          }

          return (
            <Form onKeyPress={onKeyPress} data-testid="Personal-info-form">
              <Grid>
                <Cell small={12} medium={12}>
                  <h2 className={styles.formTitle}>
                    {formatMessage(messages.formTitle)}
                  </h2>
                </Cell>
                {fact_form_id && (
                  <Cell small={12} medium={12}>
                    <p className={styles.formInfo}>
                      {formatMessage(messages.formInfo)}
                    </p>
                  </Cell>
                )}
                <Cell small={12} medium={12}>
                  <h4 className={styles.section}>
                    {formatMessage(messages.personalDetails)}
                  </h4>
                </Cell>
                <hr className={styles.form.hr} />
                <Cell small={12} medium={6}>
                  <FieldInput
                    label={formatMessage(
                      messages.personalDetails.firstNameLabel,
                    )}
                    placeholder="Enter here"
                    errorText={
                      touched &&
                      errors &&
                      touched.first_name &&
                      errors.first_name
                    }
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="PersonalInfoForm.FirstName.Input"
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <FieldInput
                    label={formatMessage(
                      messages.personalDetails.lastNameLabel,
                    )}
                    placeholder="Enter here"
                    errorText={
                      touched && errors && touched.last_name && errors.last_name
                    }
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="PersonalInfoForm.LastName.Input"
                  />
                </Cell>
                <Cell small={12} medium={12}>
                  <FieldInput
                    label={formatMessage(messages.personalDetails.allergies)}
                    placeholder="If you have any allergies, please describe"
                    name="allergies"
                    value={values.allergies}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="PersonalInfoForm.Allergies.Input"
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <FieldDropdownWithInput
                    isClearable
                    label={formatMessage(messages.personalDetails.gender)}
                    maxLength={25}
                    onChange={(event) => {
                      if (event === null) {
                        setFieldValue('gender', null);
                        return;
                      }
                      setFieldValue('gender', event.value);
                    }}
                    value={values.gender}
                    options={genderOptions}
                    placeholder="Select or type here"
                    dataTestId="PersonalInfoForm.Gender.Dropdown"
                    customStyles={customDropdownStyles}
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <FieldInput
                    label={formatMessage(messages.personalDetails.pronouns)}
                    placeholder="Enter here"
                    name="preferred_pronouns"
                    value={values.preferred_pronouns}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    dataTestId="PersonalInfoForm.Pronouns.Dropdown"
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <FieldDropdownWithInput
                    isClearable
                    label={formatMessage(
                      messages.personalDetails.relationshipStatus,
                    )}
                    maxLength={25}
                    onChange={(event) => {
                      if (event === null) {
                        setFieldValue('relationship_status', null);
                        return;
                      }
                      setFieldValue('relationship_status', event.value);
                    }}
                    value={values.relationship_status}
                    options={maritalStatuses}
                    placeholder="Select or type here"
                    dataTestId="PersonalInfoForm.PersonalDetails.RelationshipStatus.Dropdown"
                    customStyles={customDropdownStyles}
                  />
                </Cell>
                <Cell small={12} medium={6} className={styles['datepicker']}>
                  <Datepicker
                    label={formatMessage(messages.personalDetails.dateOfBirth)}
                    selected={values.dob}
                    className="form-control"
                    name="dob"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={new Date()}
                    placeholderText="MM/DD/YYYY"
                    onChange={(date) => setFieldValue('dob', date)}
                    data-testid="PersonalInfoForm.PersonalDetails.DateOfBirth.DatePicker"
                  />
                </Cell>
                {values.relationship_status === 'other' && (
                  <Cell small={12} medium={6}>
                    <FieldInput
                      label="Please Enter Relationship Status:"
                      placeholder="Enter here"
                      name="custom_relationship_status"
                      value={values.custom_relationship_status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      dataTestId="PersonalInfoForm.PersonalDetails.RelationshipStatus.Input"
                    />
                  </Cell>
                )}
                {/* Contact Information */}
                <Cell small={12} medium={12}>
                  <h4 className={styles.section}>
                    {formatMessage(messages.contactInformation)}
                  </h4>
                </Cell>
                <hr className={styles.form.hr} />
                <Cell small={12} medium={12}>
                  <FieldInput
                    label={formatMessage(
                      messages.contactInformation.streetAddress,
                    )}
                    name="street_address"
                    placeholder="Enter here"
                    value={values.street_address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="ContactInformation.Address.Input"
                  />
                </Cell>
                <Cell small={12} medium={4}>
                  <FieldInput
                    label={formatMessage(messages.contactInformation.cityLabel)}
                    name="city"
                    placeholder="Enter here"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="ContactInformation.City.Input"
                  />
                </Cell>
                <Cell small={12} medium={4}>
                  <FieldDropdown
                    label={formatMessage(
                      messages.contactInformation.stateLabel,
                    )}
                    name="state"
                    placeholder="Select here"
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={states}
                    dataTestId="PersonalInfoForm.States.Dropdown"
                    customStyles={customDropdownStyles}
                  />
                </Cell>
                <Cell small={12} medium={4}>
                  <FieldInput
                    label={formatMessage(
                      messages.contactInformation.zipCodeLabel,
                    )}
                    name="zip"
                    placeholder="Enter here"
                    value={values.zip}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="ContactInformation.Zip.Input"
                  />
                </Cell>
                <Cell small={12} medium={4}>
                  <FieldInput
                    label={formatMessage(
                      messages.contactInformation.homePhoneLabel,
                    )}
                    name="home_phone"
                    value={values.home_phone}
                    onChange={handleChange}
                    errorText={
                      touched &&
                      errors &&
                      touched.home_phone &&
                      errors.home_phone
                    }
                    onBlur={handleBlur}
                    placeholder="Enter here"
                    data-testid="ContactInformation.HomePhone.Input"
                  />
                </Cell>
                <Cell small={12} medium={4}>
                  <FieldInput
                    label={formatMessage(
                      messages.contactInformation.cellPhoneLabel,
                    )}
                    errorText={
                      touched && errors && touched.phone && errors.phone
                    }
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter here"
                    data-testid="ContactInformation.Phone.Input"
                  />
                </Cell>
                <Cell small={12} medium={4}>
                  <FieldInput
                    label={formatMessage(
                      messages.contactInformation.workPhoneLabel,
                    )}
                    name="work_phone"
                    value={values.work_phone}
                    onChange={handleChange}
                    errorText={
                      touched &&
                      errors &&
                      touched.work_phone &&
                      errors.work_phone
                    }
                    onBlur={handleBlur}
                    placeholder="Enter here"
                    data-testid="ContactInformation.WorkPhone.Input"
                  />
                </Cell>
                <Cell small={12} medium={12}>
                  <FieldInput
                    label={formatMessage(
                      messages.contactInformation.emailLabel,
                    )}
                    errorText={
                      touched && errors && touched.email && errors.email
                    }
                    disabled
                    name="contactInformation.email"
                    placeholder="Enter here"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="ContactInformation.Email.Input"
                  />
                </Cell>
                <Cell small={12} medium={12} className={styles['radioBtn']}>
                  <RadioGroupWithLabel
                    type="checkbox"
                    componentName="contact_method"
                    label={formatMessage(
                      messages.contactInformation.contactMethods,
                    )}
                    options={contactMethods}
                    dataTestId="ContactInformation.ContactMethod.Input.RadioGroup"
                    labelTestId="ContactInformation.ContactMethod.Label"
                    value={values.contact_method}
                    onChange={(value) => {
                      const arrOfItems = handleCheckboxChange(
                        values.contact_method,
                        value,
                      );
                      setFieldValue('contact_method', arrOfItems);
                    }}
                  />
                </Cell>
                {/* Emergency Contact Information */}
                <Cell small={12} medium={12}>
                  <h4 className={styles.section}>
                    {formatMessage(messages.emergencyContactInformation)}
                  </h4>
                </Cell>
                <hr className={styles.form.hr} />
                <Cell small={12} medium={6}>
                  <FieldInput
                    label={formatMessage(
                      messages.emergencyContactInformation.contactName,
                    )}
                    name="emergency_contact_name"
                    placeholder="Enter here"
                    value={values.emergency_contact_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="EmergencyContactInformation.ContactName.Input"
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <FieldInput
                    label={formatMessage(
                      messages.emergencyContactInformation.contactRelationship,
                    )}
                    name="emergency_contact_relationship"
                    placeholder="Enter here"
                    value={values.emergency_contact_relationship}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="EmergencyContactInformation.ContactRelationship.Input"
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <FieldInput
                    label={formatMessage(
                      messages.emergencyContactInformation.contactPhone,
                    )}
                    name="emergency_contact_phone"
                    placeholder="Enter here"
                    errorText={
                      touched &&
                      errors &&
                      touched.emergency_contact_phone &&
                      errors.emergency_contact_phone
                    }
                    value={values.emergency_contact_phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="EmergencyContactInformation.ContactPhone.Input"
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <FieldInput
                    label={formatMessage(
                      messages.emergencyContactInformation.contactEmail,
                    )}
                    name="emergency_contact_email"
                    placeholder="Enter here"
                    errorText={
                      touched &&
                      errors &&
                      touched.emergency_contact_email &&
                      errors.emergency_contact_email
                    }
                    value={values.emergency_contact_email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="EmergencyContactInformation.ContactEmail.Input"
                  />
                </Cell>
                {/* Insurance Information */}
                <Cell small={12} medium={12}>
                  <h4 className={styles.section}>
                    {formatMessage(messages.insuranceInformation)}
                  </h4>
                </Cell>
                <hr className={styles.form.hr} />
                <Cell small={12} medium={12}>
                  <FieldInput
                    label={formatMessage(
                      messages.insuranceInformation.SSNLabel,
                    )}
                    name="ssn"
                    placeholder="Enter here"
                    value={values.ssn}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="InsuranceInformation.Snn.Input"
                  />
                </Cell>
                <Cell small={12} medium={12}>
                  <FieldDropdownWithInput
                    isClearable
                    label={formatMessage(
                      messages.insuranceInformation.insuranceProviderLabel,
                    )}
                    maxLength={30}
                    onChange={(event) => {
                      if (event === null) {
                        setFieldValue('insurance_provider', null);
                        return;
                      }
                      setFieldValue('insurance_provider', event.value);
                    }}
                    value={values.insurance_provider}
                    options={insuranceList}
                    placeholder="Select or type here"
                    dataTestId="PersonalInfoForm.InsuranceInformation.InsuranceProviderLabel.Dropdown"
                    customStyles={customDropdownStyles}
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <FieldInput
                    label={formatMessage(
                      messages.insuranceInformation.insuranceIdLabel,
                    )}
                    name="insurance_id"
                    placeholder="Enter here"
                    value={values.insurance_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="InsuranceInformation.InsuranceId.Input"
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <FieldInput
                    label={formatMessage(
                      messages.insuranceInformation.groupIdLabel,
                    )}
                    name="group_id"
                    placeholder="Enter here"
                    value={values.group_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="InsuranceInformation.GroupId.Input"
                  />
                </Cell>
                <Cell small={12} medium={12}>
                  <FieldInput
                    label={formatMessage(
                      messages.insuranceInformation.insuredNameLabel,
                    )}
                    name="name_insured"
                    placeholder="Enter here"
                    value={values.name_insured}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="InsuranceInformation.InsuredName.Input"
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <FieldInput
                    label={formatMessage(
                      messages.insuranceInformation.primaryDoctorNameLabel,
                    )}
                    name="primary_care_doctor"
                    placeholder="Enter here"
                    value={values.primary_care_doctor}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="InsuranceInformation.PrimaryDoctorName.Input"
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <FieldInput
                    label={formatMessage(
                      messages.insuranceInformation.doctorsContactLabel,
                    )}
                    errorText={
                      touched &&
                      errors &&
                      touched.primary_care_doctor_phone &&
                      errors.primary_care_doctor_phone
                    }
                    name="primary_care_doctor_phone"
                    placeholder="Enter here"
                    value={values.primary_care_doctor_phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="InsuranceInformation.DoctorsContact.Input"
                  />
                </Cell>
                <Cell small={12} medium={12}>
                  <FieldInput
                    label={formatMessage(
                      messages.insuranceInformation.preferredPharmacyLabel,
                    )}
                    name="preferred_pharmacy_address"
                    placeholder="Enter here"
                    value={values.preferred_pharmacy_address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="InsuranceInformation.PreferredPharmacy.Input"
                  />
                </Cell>
                {/* Billing and Payment Information */}
                <Cell small={12} medium={12}>
                  <h4 className={styles.section}>
                    {formatMessage(messages.paymentInformation)}
                  </h4>
                </Cell>
                <hr className={styles.form.hr} />
                <Cell small={12} medium={12}>
                  <FieldInput
                    label={formatMessage(
                      messages.contactInformation.streetAddress,
                    )}
                    name="billing_street_address"
                    placeholder="Enter here"
                    value={values.billing_street_address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="PaymentInformation.Address.Input"
                  />
                </Cell>
                <Cell small={12} medium={4}>
                  <FieldInput
                    label={formatMessage(messages.contactInformation.cityLabel)}
                    name="billing_city"
                    placeholder="Enter here"
                    value={values.billing_city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="PaymentInformation.City.Input"
                  />
                </Cell>
                <Cell small={12} medium={4}>
                  <FieldDropdown
                    label={formatMessage(
                      messages.contactInformation.stateLabel,
                    )}
                    name="billing_state"
                    placeholder="Select here"
                    value={values.billing_state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={states}
                    dataTestId="PersonalInfoForm.States.Dropdown"
                    customStyles={customDropdownStyles}
                    data-testid="PaymentInformation.State.Input"
                  />
                </Cell>
                <Cell small={12} medium={4}>
                  <FieldInput
                    label={formatMessage(
                      messages.contactInformation.zipCodeLabel,
                    )}
                    name="billing_zip"
                    placeholder="Enter here"
                    value={values.billing_zip}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="PaymentInformation.Zip.Input"
                  />
                </Cell>
                {/* Current Medications */}
                <Cell small={12} medium={12}>
                  <h4 className={styles.section}>
                    {formatMessage(messages.currentMedications)}
                  </h4>
                </Cell>
                <hr className={styles.form.hr} />
                <FieldArray
                  name="current_medications"
                  render={({ remove, push }) => (
                    <>
                      <MedicationsForm
                        push={push}
                        values={values.current_medications}
                        medicationValue={
                          !editItemMedication ? medicationValue : null
                        }
                        setMedicationValue={setMedicationValue}
                        customDropdownStyles={customDropdownStyles}
                      />
                      {values.current_medications?.length > 0 && (
                        <Cell>
                          <AddedItem
                            displayFields={['name', 'dose', 'frequency']}
                            displayEditItem="none"
                            remove={remove}
                            items={values.current_medications}
                            maxLength={10}
                            setEditItem={setEditItemMedication}
                          />
                        </Cell>
                      )}
                      {!!editItemMedication && editItemMedication.delete && (
                        <SuperBillMessageModal type="error">
                          <h1>Deleting Medications</h1>
                          <p>Are you sure do you want to proceed?</p>
                          <div className={styles.buttonsWrapperDelete}>
                            <SecondaryButton
                              data-testid="PaymentInformation.CurrentMedication.Modal.CancelButton"
                              className={styles.cancel}
                              onClick={() => {
                                setEditItemMedication();
                              }}
                              label={'Cancel'}
                            />
                            <PrimaryButton
                              data-testid="PaymentInformation.CurrentMedication.Modal.YesButton"
                              label="Yes"
                              onClick={() => {
                                remove(editItemMedication.index);
                                setEditItemMedication();
                              }}
                            />
                          </div>
                        </SuperBillMessageModal>
                      )}
                    </>
                  )}
                />
                {/* Illnesses and Injuries */}
                <Cell small={12} medium={12}>
                  <h4 className={styles.section}>
                    {formatMessage(messages.illnessesAndInjuries)}
                  </h4>
                </Cell>
                <hr className={styles.form.hr} />
                <FieldArray
                  name="illnesses_injuries"
                  render={({ remove, push }) => (
                    <>
                      <IllnessesForm
                        styles={styles}
                        values={values.illnesses_injuries}
                        push={push}
                        illnessValue={!editItemIllness ? illnessValue : null}
                        setIllnessValue={setIllnessValue}
                      />

                      {values.illnesses_injuries?.length > 0 && (
                        <Cell>
                          <AddedItem
                            displayFields={['description', 'dateOfOnset']}
                            displayEditItem="none"
                            remove={remove}
                            items={values.illnesses_injuries}
                            maxLength={10}
                            setEditItem={setEditItemIllness}
                          />
                        </Cell>
                      )}
                      {!!editItemIllness && editItemIllness.delete && (
                        <SuperBillMessageModal type="error">
                          <h1>Deleting Illnesses and Injuries</h1>
                          <p>Are you sure do you want to proceed?</p>
                          <div className={styles.buttonsWrapperDelete}>
                            <SecondaryButton
                              data-testid="PaymentInformation.IllnessesAndInjuries.Modal.CancelButton"
                              className={styles.cancel}
                              onClick={() => {
                                setEditItemIllness();
                              }}
                              label={'Cancel'}
                            />
                            <PrimaryButton
                              data-testid="PaymentInformation.IllnessesAndInjuries.Modal.YesButton"
                              label="Yes"
                              onClick={() => {
                                remove(editItemIllness.index);
                                setEditItemIllness();
                              }}
                            />
                          </div>
                        </SuperBillMessageModal>
                      )}
                    </>
                  )}
                />
                <Cell small={12} medium={12}>
                  <h4 className={styles.section}>
                    {formatMessage(messages.alcoholAndDrugUse)}
                  </h4>
                </Cell>
                <hr className={styles.form.hr} />
                <p style={{ marginLeft: 10 }}>
                  {formatMessage(
                    messages.alcoholAndDrugUse.formDescriptionLabel,
                  )}
                </p>
                {alcoholAndDrugsOptions.map((section, key) => (
                  <Cell
                    key={key}
                    small={12}
                    medium={12}
                    className={styles['radioBtn']}
                  >
                    <h5 data-testid={section.id}>{section.label}</h5>
                    {responseOptions.map((radioOption, key) => (
                      <div key={key} className={styles.radioButtonList}>
                        <Field
                          component={(field) => (
                            <RadioButton {...field} name={section.id} />
                          )}
                          value={radioOption}
                          htmlFor={`${section.id}${key}`}
                          name={section.id}
                          checked={radioOption === values[section.id]}
                          onChange={(value) => {
                            setFieldValue(section.id, value);
                          }}
                          labelName={radioOption}
                          data-testid={`AlcoholAndDrugs.${section.id}_${radioOption}.RadioButton`}
                          label-testid={`AlcoholAndDrugs.${section.id}_${radioOption}.RadioButton.Label`}
                        />
                      </div>
                    ))}
                  </Cell>
                ))}
                <div className={styles.customLabel}>
                  <h5>
                    {formatMessage(messages.alcoholAndDrugUse.otherLabel)}
                  </h5>
                </div>
                <Cell small={12} medium={12}>
                  <FieldInput
                    name="others"
                    placeholder="Enter here"
                    value={values.others}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="AlcoholAndDrugs.Other.Input"
                  />
                </Cell>
                <Cell
                  small={12}
                  flexContainer
                  className={styles['actions-container noMarginButton']}
                >
                  <PrimaryButton
                    disabled={Object.entries(errors).length > 0}
                    label={'Save'}
                    type="submit"
                    className="font-18"
                  />
                </Cell>
              </Grid>
            </Form>
          );
        }}
      />
    </div>
  );
};

PersonalInfoForm.propTypes = {
  onCancel: PropTypes.func,
  nextBtnLabel: PropTypes.string,
  backBtnLabel: PropTypes.string,
  submitBtnLabel: PropTypes.string,
};

PersonalInfoForm.defaultProps = {};

export default PersonalInfoForm;
