import React, { useState, Fragment, useEffect, useContext } from 'react';
import styles from './CancelAppointmentPage.module.scss';
import Iframe from 'react-iframe';
import { Cell } from 'react-foundation';
import { useParams } from 'react-router-dom';
import {
  getAppointment,
  updatePayment,
  cancelAppointment,
} from '../../../api/appointment';
import Progress from '../../../components/Progress';
import { useHistory } from 'react-router-dom';
import Loading from '../../../components/common/Loading';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import Modal from '../../../components/common/Modal';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import FieldDropdown from '../../../components/common/fields/FieldDropdown';
import AuthContext from '../../../context/AuthContext';
import AppointmentCancellation from '../../../components/AppointmentCancellation/AppointmentCancellation';
import MessageModal from '../../../components/MessageModal';
import {
  CALENDAR_TYPES,
  USER_TYPES,
  APPOINTEMNT_URLS,
  APPOINTMENT_STATUSES,
} from '../../../constants';

const CancelAppointmentPage = () => {
  const { authUser } = useContext(AuthContext);

  const { id: appointmentId } = useParams();
  const [doctor, setDoctor] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [bookingTypeLabel, setBookingTypeLabel] = useState('');
  const [cancelUrl, setCancelUrl] = useState('');
  const [bookingAmountLabel, setBookingAmountLabel] = useState('');
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [error, setError] = useState('');
  const [patient, setPatient] = useState({});
  const [payment, setPayment] = useState();
  const [refund, setRefund] = useState('');
  const [refundList, setRefundList] = useState([
    { name: '100%-full Refund', value: '100' },
    { name: '80%-Refund', value: '80' },
    { name: '50%-Refund', value: '50' },
  ]);
  const [appointment, setAppointment] = useState({});
  const [showAppoinmentCancelled, setShowAppoinmentCancelled] = useState(false);
  const [displayMessage, setDisplayMessage] = useState({
    title: '',
    message: '',
    buttonLabel: '',
    redirectUrl: '',
  });
  const url =
    authUser.profile_type === USER_TYPES.PATIENT
      ? APPOINTEMNT_URLS.PATIENT
      : APPOINTEMNT_URLS.DOCTOR;

  const fetchAppointment = async (appointmentId) => {
    const { appointment } = await getAppointment(appointmentId);

    // check appointment status if it's already canceled show a message to user and redirect to appointments page
    if (appointment.status === APPOINTMENT_STATUSES.CANCELED) {
      setDisplayMessage({
        type: 'error',
        title: 'Appointment',
        text: 'Appointment already cancelled',
        buttonLabel: 'OK',
        redirectUrl: url,
      });
      setShowAppoinmentCancelled(true);
    }
    setAppointment(appointment);
    if (appointment.payment === null) {
      setShowRefundModal(false);
    } else {
      if (authUser.profile_type === USER_TYPES.PATIENT) {
        setShowRefundModal(false);
        await updatePayment(appointment.payment.square_payment_id, {
          refund_amount: appointment.payment.amount,
        });
      } else {
        setShowRefundModal(true);
      }
      setPayment(appointment.payment);
    }
    setPatient(appointment.patient);
    setCancelUrl(appointment.cancel_url);
    setDoctor(appointment.doctor);
    setBookingTypeLabel(
      appointment.bookingType ? appointment.bookingType.name : '',
    );
    setBookingAmountLabel(
      `$${appointment.amount} (${appointment.appointment_duration_in_minutes}min)`,
    );
    setIsLoading(false);
    const formattedRefundList = refundList.map((refund) => {
      return {
        label: refund.name,
        value: refund.value,
      };
    });
    setRefundList(formattedRefundList);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchAppointment(appointmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId]);

  const handleCancelAppointment = async (values) => {
    try {
      setIsLoading(true);
      await cancelAppointment(appointmentId, values);

      // show the message after 2 seconds
      setTimeout(function () {
        setIsLoading(false);
        setDisplayMessage({
          type: 'sucess',
          title: 'Appointment',
          text: 'Appointment Cancelled',
          buttonLabel: 'OK',
          redirectUrl: url,
        });
        setShowAppoinmentCancelled(true);
      }, 2000);
    } catch (error) {
      let errorMsg = '';
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMsg = error.response.data.message;
      }
      setIsLoading(false);
      setDisplayMessage({
        type: 'error',
        title: '',
        text: errorMsg ? errorMsg : 'Something went wrong. Try again later',
      });
      setShowAppoinmentCancelled(true);
    }
  };

  return (
    <div className={styles.root}>
      {isLoading && <Loading />}
      <Fragment>
        <div data-testid="cancel_appoinment header" className={styles.title}>
          Cancel Appointment
        </div>
        <div className={styles['schedule-header']}>
          <div className={styles['header-left']}>
            <div className={styles['schedule-user']}>
              <img
                data-testid="cancel_appoinment doctor profilepic"
                src={
                  doctor.profile_pic
                    ? doctor.profile_pic
                    : require('../../../img/doctor.jpg')
                }
                alt={`${doctor.first_name} ${
                  doctor.last_name ? doctor.last_name : ''
                }`}
              />
            </div>
            <div>
              <h3 data-testid="cancel_appoinment doctor_name">
                {doctor.first_name} {doctor.last_name ? doctor.last_name : ''}
              </h3>
            </div>
          </div>
          <div className={styles['header-right']}>
            <div className={styles['schedule-content']}>
              <h3 data-testid="cancel_appoinment mentbooking type label">
                {' '}
                {bookingTypeLabel}{' '}
              </h3>
              <h6 data-testid="cancel_appoinment booking amount">
                {' '}
                {bookingAmountLabel}{' '}
              </h6>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.progressHead}>
            <p data-testid="reason for canceling label">Reason for canceling</p>
          </div>
          <div className={styles.progressBar}>
            <Progress width={700} height={5} percent={100} />
          </div>
        </div>
      </Fragment>
      <div className={styles.calendlyDiv}>
        {appointment &&
          appointment.calendar_type === CALENDAR_TYPES.CALENDLY &&
          cancelUrl && (
            <Iframe
              url={cancelUrl}
              max-width="600px"
              width="100%"
              height="500px"
              id="reschedule"
              display="initial"
              position="relative"
            />
          )}
        {appointment && appointment.calendar_type === CALENDAR_TYPES.NYLAS && (
          <div>
            <AppointmentCancellation
              appointmentData={{
                start_time: appointment.start_time,
                end_time: appointment.end_time,
                booking_type_label: appointment.booking_type_label,
              }}
              onCancelClick={handleCancelAppointment}
            />
          </div>
        )}
      </div>
      <div className={styles.btnDiv}>
        <SecondaryButton
          label="Back"
          className="font-18"
          type="button"
          onClick={() => history.push(`/doctor/my-account/`)}
          data-testid="cancel_appoinment back button"
        />
      </div>
      {showRefundModal && (
        <Modal className={styles.refundmodal}>
          <div className={styles.content}>
            <div
              className={styles.closeButton}
              onClick={() => {
                history.push('/doctor/appointments');
                setError('');
                setRefund('');
              }}
              data-testid="refund close button"
            >
              <img
                data-testid="close button"
                src={require(`../../../img/close.svg`)}
                alt={`Close`}
              />
            </div>
            <h1>
              <b data-testid="refund cancel appointment modal header">
                Cancel Appointment
              </b>
            </h1>
            <div className={styles.box}>
              {patient && patient.first_name && (
                <p data-testid="refund cancel_appointment sub header">
                  Please select the refund amount for {patient.first_name}{' '}
                  {patient.last_name}
                </p>
              )}
            </div>
            <Cell small={12} medium={12} className={styles['label-bold']}>
              <FieldDropdown
                label=""
                name="doctor"
                errorText={error}
                value={refund}
                onChange={(e) => {
                  const refundAmount = (e.target.value * payment.amount) / 100;
                  setRefund(refundAmount);
                  setError('');
                }}
                options={refundList}
                placeholder="Select Refund"
                dataTestId="CancelAppointment.Refund.Dropdown"
              />
            </Cell>
            <h3> {refund && `${refund}$`}</h3>
            <PrimaryButton
              label="Cancel Appointment"
              className={styles.primaryButton}
              onClick={async () => {
                if (refund) {
                  setShowRefundModal(false);
                  await updatePayment(payment.square_payment_id, {
                    refund_amount: refund,
                  });
                } else {
                  setError('Please Select Refund');
                }
              }}
              data-testid="cancel appointment button"
            />
            <SecondaryButton
              label="Cancel"
              className={styles.secondaryButton}
              onClick={() => {
                setError('');
                setRefund('');
                history.push('/doctor/appointments');
              }}
              data-testid="refund cancel  button"
            />
          </div>
        </Modal>
      )}
      <>
        {showAppoinmentCancelled && (
          <MessageModal
            message={displayMessage}
            displayLogo={false}
            onCloseModal={() => {
              history.push(url);
            }}
          />
        )}
      </>
    </div>
  );
};

export default CancelAppointmentPage;
