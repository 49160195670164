import React, { useState, Fragment, useEffect, useContext } from 'react';
import styles from './ReschedulePage.module.scss';
import messages from './messages';
import Iframe from 'react-iframe';
import useIntl from '../../../hooks/useIntl';
import { useParams } from 'react-router-dom';
import { getAppointment, updatePayment } from '../../../api/appointment';
import Progress from '../../../components/Progress';
import { useHistory } from 'react-router-dom';
import Loading from '../../../components/common/Loading';
import AuthContext from '../../../context/AuthContext';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import MessageModal from '../../../components/MessageModal';
import {
  USER_TYPES,
  APPOINTEMNT_URLS,
  APPOINTMENT_STATUSES,
} from '../../../constants';

const ReschedulePage = () => {
  const { formatMessage } = useIntl();
  const { id: appointmentId } = useParams();
  const [doctor, setDoctor] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [bookingTypeLabel, setBookingTypeLabel] = useState('');
  const [rescheduleUrl, setrescheduleUrl] = useState('');
  const { authUser } = useContext(AuthContext);

  const [bookingAmountLabel, setBookingAmountLabel] = useState('');
  const [showAppoinmentBooked, setShowAppoinmentBooked] = useState(false);
  const [displayMessage, setDisplayMessage] = useState({
    title: '',
    message: '',
    buttonLabel: '',
    redirectUrl: '',
  });
  const url =
    authUser.profile_type === USER_TYPES.PATIENT
      ? APPOINTEMNT_URLS.PATIENT
      : APPOINTEMNT_URLS.DOCTOR;

  const fetchAppointment = async (appointmentId) => {
    const { appointment } = await getAppointment(appointmentId);

    // check appointment status if it's already canceled show a message to user and redirect to appointments page
    if (appointment.status === APPOINTMENT_STATUSES.CANCELED) {
      setDisplayMessage({
        type: 'error',
        title: 'Appointment',
        text: 'Appointment already cancelled',
        buttonLabel: 'OK',
        redirectUrl: url,
      });
      setShowAppoinmentBooked(true);
    }

    if (appointment.payment) {
      await updatePayment(appointment.payment.square_payment_id, null);
    }
    setrescheduleUrl(`${appointment.reschedule_url}`);
    setDoctor(appointment.doctor);
    if (appointment.bookingType) {
      setBookingTypeLabel(appointment.bookingType.name);
    }
    setBookingAmountLabel(
      `$${appointment.amount} (${appointment.appointment_duration_in_minutes}min)`,
    );
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchAppointment(appointmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId]);

  const handleEvent = async (e) => {
    try {
      const params = e.detail;
      if (params.event_id) {
        // show the message after 2 seconds
        setTimeout(function () {
          setDisplayMessage({
            type: 'sucess',
            title: 'Appointment',
            text: 'Appointment Rescheduled',
            buttonLabel: 'OK',
            redirectUrl: url,
          });
          setShowAppoinmentBooked(true);
          setIsLoading(false);
        }, 2000);
      }
    } catch (error) {
      let errorMsg = '';
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMsg = error.response.data.message;
      }
      setIsLoading(false);
      setDisplayMessage({
        type: 'error',
        title: '',
        text: errorMsg ? errorMsg : 'Something went wrong. Try again later',
      });
      setShowAppoinmentBooked(true);
    }
  };
  useEffect(() => {
    if (window) {
      window.document.addEventListener(
        'onScheduleComplete',
        handleEvent,
        false,
      );
    }
    return () => {
      window.document.removeEventListener(
        'onScheduleComplete',
        handleEvent,
        false,
      );
    };
  }, []);

  return (
    <div className={styles.root}>
      {isLoading && <Loading />}
      <Fragment>
        <div
          className={styles.title}
          data-testid="reschedule appointment header"
        >
          {formatMessage(messages.title)}
        </div>
        <div className={styles['schedule-header']}>
          <div className={styles['header-left']}>
            <div className={styles['schedule-user']}>
              <img
                data-testid="reschedule doctor profile pic"
                src={
                  doctor.profile_pic
                    ? doctor.profile_pic
                    : require('../../../img/doctor.jpg')
                }
                alt={`${doctor.first_name} ${
                  doctor.last_name ? doctor.last_name : ''
                }`}
              />
            </div>
            <div>
              <h3 data-testid="reschedule doctor name">
                {doctor.first_name} {doctor.last_name ? doctor.last_name : ''}
              </h3>
            </div>
          </div>
          <div className={styles['header-right']}>
            <div className={styles['schedule-content']}>
              <h3 data-testid="reschedule booking type label">
                {' '}
                {bookingTypeLabel}{' '}
              </h3>
              <h6 data-testid="reschedule booking amount">
                {' '}
                {bookingAmountLabel}{' '}
              </h6>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.progressHead}>
            <p data-testid="select new date and time label">
              Select New Date & Time
            </p>
          </div>
          <div className={styles.progressBar}>
            <Progress width={700} height={5} percent={100} />
          </div>
        </div>
      </Fragment>
      <div>
        <div className={styles.calendlyDiv}>
          {rescheduleUrl && (
            <Iframe
              url={rescheduleUrl}
              max-width="600px"
              width="100%"
              height="500px"
              id="reschedule"
              display="initial"
              position="relative"
            />
          )}
        </div>
        <div className={styles.btnDiv}>
          <SecondaryButton
            data-testid="reschedule back button"
            label="Back"
            className="font-18"
            type="button"
            onClick={() => history.push(`/doctor/my-account/`)}
          />
        </div>
      </div>
      <>
        {showAppoinmentBooked && (
          <MessageModal
            message={displayMessage}
            displayLogo={false}
            onCloseModal={() => {
              history.push(url);
            }}
          />
        )}
      </>
    </div>
  );
};

export default ReschedulePage;
