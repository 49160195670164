import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import useIntl from '../../../../hooks/useIntl';
import AnahitaIntakePDF from '../../../AssessmentPDF/AnahitaIntakePDF';
import Modal from '../../../common/Modal';
import FormDownloadLink from '../../../FormListView/components/FormDownloadLink/FormDownloadLink';
import FormHeader from '../../../FormListView/components/FormHeader/FormHeader';
import styles from '../../../FormListView/FormListView.module.scss';
import messages from './messages';

const AnahitaFormResponse = ({ onClick, item, data, opened }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {opened && (
        <Modal className={styles.modal}>
          <div className={styles.content}>
            <FormHeader onClick={onClick} formName={item.form_name} />
            <FormDownloadLink
              item={item}
              pdfDownload={
                <AnahitaIntakePDF
                  item={item}
                  formDetail={item.patient}
                  data={data}
                />
              }
            />
            <div className={styles.box}>
              <div className={styles['content-detail']}>
                {item.patient && item.patient.id ? (
                  <section className={styles['content-detail-section']}>
                    <h2>{formatMessage(messages.sectionOneTitle)}</h2>
                    <div className={classnames('text')}>
                      <span>{formatMessage(messages.overallHealth)}</span>
                      <p>{data.overall_health}</p>
                    </div>
                    <div className={classnames('text')}>
                      <span>{formatMessage(messages.currentProfession)}</span>
                      <p>{data.current_profession}</p>
                    </div>
                    <div className={classnames('text')}>
                      <span>{formatMessage(messages.diagnosisHistory)}</span>
                      <p>{data.diagnosis_history.join(', ')}</p>
                    </div>
                    <div className={classnames('text')}>
                      <span>{formatMessage(messages.pregnantStatus)}</span>
                      <p>{data.pregnant_status}</p>
                    </div>
                    <div className={classnames('text')}>
                      <span>
                        {formatMessage(
                          messages.physicalMentalEmotionalConcerns,
                        )}
                      </span>
                      <p>{data.physical_mental_emotional_concerns}</p>
                    </div>
                    <div className={classnames('text')}>
                      <span>{formatMessage(messages.traumaHistory)}</span>
                      <p>{data.trauma_history}</p>
                    </div>
                    <div className={classnames('text')}>
                      <span>{formatMessage(messages.surgeriesStatus)}</span>
                      <p>{data.surgeries_status}</p>
                      {data.surgeries_status === 'yes' && (
                        <div className={styles.indent}>
                          <span>
                            {formatMessage(messages.surgeriesStatusYesDetails)}
                          </span>
                          <p>{data.surgeries_status_yes_details}</p>
                        </div>
                      )}
                    </div>
                    <div className={classnames('text')}>
                      <span>{formatMessage(messages.standardDiet)}</span>
                      <p>{data.standard_diet}</p>
                    </div>
                    <div className={classnames('text')}>
                      <span>{formatMessage(messages.sleepPatterns)}</span>
                      <p>{data.sleep_patterns}</p>
                    </div>
                    <div className={classnames('text')}>
                      <span>{formatMessage(messages.familyDiagnoses)}</span>
                      <p>{data.family_diagnoses}</p>
                      {data.family_diagnoses === 'yes' && (
                        <div className={styles.indent}>
                          <span>
                            {formatMessage(messages.familyDiagnosesDetails)}
                          </span>
                          <p>{data.family_diagnoses_details}</p>
                        </div>
                      )}
                    </div>
                    <div className={classnames('text')}>
                      <span>
                        {formatMessage(messages.partnerApprovalHealingPractice)}
                      </span>
                      <p>{data.partner_approval_healing_practice}</p>
                    </div>
                    <div className={classnames('text')}>
                      <span>{formatMessage(messages.strengthsList)}</span>
                      <p>{data.strengths_list}</p>
                    </div>
                    <div className={classnames('text')}>
                      <span>
                        {formatMessage(messages.meditativeSpiritualPractice)}
                      </span>
                      <p>{data.meditative_spiritual_practice}</p>
                      {data.meditative_spiritual_practice === 'yes' && (
                        <div className={styles.indent}>
                          <span>
                            {formatMessage(
                              messages.meditativeSpiritualPracticeDetails,
                            )}
                          </span>
                          <p>{data.meditative_spiritual_practice_details}</p>
                        </div>
                      )}
                    </div>
                    <div className={classnames('text')}>
                      <span>{formatMessage(messages.supplementsTaken)}</span>
                      <p>{data.supplements_taken}</p>
                    </div>
                    <div className={classnames('text')}>
                      <span>{formatMessage(messages.idealOutcome)}</span>
                      <p>{data.ideal_outcome}</p>
                    </div>
                    <div className={classnames('text')}>
                      <span>
                        {formatMessage(messages.additionalInformation)}
                      </span>
                      <p>{data.additional_information}</p>
                    </div>
                  </section>
                ) : (
                  <div>No Data Available</div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

AnahitaFormResponse.propTypes = {
  onClick: PropTypes.func,
  item: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  opened: PropTypes.bool,
};

export default AnahitaFormResponse;
