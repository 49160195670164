import React, { useEffect } from 'react';
import { Cell } from 'react-foundation';
import useIntl from '../../../../../hooks/useIntl';
import RadioGroupWithLabel from '../../../../common/inputs/RadioGroupWithLabel';
import TextInput from '../../../../common/inputs/TextInput';
import Loading from '../../../../common/Loading';
import styles from '../../PersonalInfoForm/PersonalInfoForm.module.scss';
import {
  attentionalIssuesSymptoms,
  eatingProblemsSymptoms,
  psychosisSymptoms,
} from '../data';
import messages from '../messages';
import SymptomsForm from '../SymptomsForm/SymptomsForm';

const BPSFormStepFour = ({
  values,
  handleChange,
  setFieldValue,
  handleBlur,
  isLoading,
  handleCheckboxChange,
}) => {
  const { formatMessage } = useIntl();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Cell small={12}>
        <h4 className={styles.section}>
          {formatMessage(messages.currentWellbeing)}
        </h4>
      </Cell>
      <hr className={styles.form.hr} />
      <Cell small={12}>
        <RadioGroupWithLabel
          type="checkbox"
          label={formatMessage(
            messages.currentWellbeing.attentionalIssuesSymptoms,
          )}
          componentName="attentional_issues_symptoms"
          options={attentionalIssuesSymptoms}
          dataTestId="BPSFormStepFour.CurrentWellbeing.AttentionalIssuesSymptoms.RadioGroup"
          labelTestId="BPSFormStepFour.CurrentWellbeing.AttentionalIssuesSymptoms.Label"
          value={values.attentional_issues_symptoms}
          onChange={(value) => {
            const arrOfItems = handleCheckboxChange(
              values.attentional_issues_symptoms,
              value,
            );
            setFieldValue('attentional_issues_symptoms', arrOfItems);
          }}
        />
        {values.attentional_issues_symptoms.includes('other') && (
          <TextInput
            placeholder="Enter here"
            name="attentional_issues_symptoms_input"
            value={values.attentional_issues_symptoms_input}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepFour.CurrentWellbeing.AttentionalIssuesSymptoms.Input"
          />
        )}
      </Cell>
      <SymptomsForm
        dropdownName="attentional_issues_symptoms_period"
        inputName="attentional_issues_question_response"
        radioGroupName="attentional_issues_symptoms_cause"
        dropdownValue={values.attentional_issues_symptoms_period}
        inputValue={values.attentional_issues_question_response}
        radioGroupValue={values.attentional_issues_symptoms_cause}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        dropdownDataTestId="BPSFormStepFour.CurrentWellbeing.AttentionalIssuesSymptomsPeriod.Dropdown"
        inputDataTestId="BPSFormStepFour.CurrentWellbeing.AttentionalIssuesQuestionResponse.Input"
        radioGroupDataTestId="BPSFormStepFour.CurrentWellbeing.AttentionalIssuesSymptomsCause.RadioGroup"
        radioGroupLabelTestId="BPSFormStepFour.CurrentWellbeing.AttentionalIssuesSymptomsCause.Label"
      />
      <Cell small={12}>
        <RadioGroupWithLabel
          type="checkbox"
          label={formatMessage(
            messages.currentWellbeing.eatingProblemsSymptoms,
          )}
          componentName="eating_problems_symptoms"
          options={eatingProblemsSymptoms}
          dataTestId="BPSFormStepFour.CurrentWellbeing.EatingProblemsSymptoms.RadioGroup"
          labelTestId="BPSFormStepFour.CurrentWellbeing.EatingProblemsSymptoms.Label"
          value={values.eating_problems_symptoms}
          onChange={(value) => {
            const arrOfItems = handleCheckboxChange(
              values.eating_problems_symptoms,
              value,
            );
            setFieldValue('eating_problems_symptoms', arrOfItems);
          }}
        />
        {values.eating_problems_symptoms.includes('other') && (
          <TextInput
            placeholder="Enter here"
            name="eating_problems_symptoms_input"
            value={values.eating_problems_symptoms_input}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepFour.CurrentWellbeing.EatingProblemsSymptoms.Input"
          />
        )}
      </Cell>
      <SymptomsForm
        dropdownName="eating_problems_symptoms_period"
        inputName="eating_problems_question_response"
        radioGroupName="eating_problems_symptoms_cause"
        dropdownValue={values.eating_problems_symptoms_period}
        inputValue={values.eating_problems_question_response}
        radioGroupValue={values.eating_problems_symptoms_cause}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        dropdownDataTestId="BPSFormStepFour.CurrentWellbeing.EatingProblemsSymptomsPeriod.Dropdown"
        inputDataTestId="BPSFormStepFour.CurrentWellbeing.EatingProblemsQuestionResponse.Input"
        radioGroupDataTestId="BPSFormStepFour.CurrentWellbeing.EatingProblemsSymptomsCause.RadioGroup"
        radioGroupLabelTestId="BPSFormStepFour.CurrentWellbeing.EatingProblemsSymptomsCause.Label"
      />
      <Cell small={12}>
        <RadioGroupWithLabel
          type="checkbox"
          label={formatMessage(messages.currentWellbeing.psychosisSymptoms)}
          componentName="psychosis_symptoms"
          options={psychosisSymptoms}
          dataTestId="BPSFormStepFour.CurrentWellbeing.PsychosisSymptoms.RadioGroup"
          labelTestId="BPSFormStepFour.CurrentWellbeing.PsychosisSymptoms.Label"
          value={values.psychosis_symptoms}
          onChange={(value) => {
            const arrOfItems = handleCheckboxChange(
              values.psychosis_symptoms,
              value,
            );
            setFieldValue('psychosis_symptoms', arrOfItems);
          }}
        />
        {values.psychosis_symptoms.includes('other') && (
          <TextInput
            placeholder="Enter here"
            name="psychosis_symptoms_input"
            value={values.psychosis_symptoms_input}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepFour.CurrentWellbeing.PsychosisSymptoms.Input"
          />
        )}
      </Cell>
      <SymptomsForm
        dropdownName="psychosis_symptoms_period"
        inputName="psychosis_question_response"
        radioGroupName="psychosis_symptoms_cause"
        dropdownValue={values.psychosis_symptoms_period}
        inputValue={values.psychosis_question_response}
        radioGroupValue={values.psychosis_symptoms_cause}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        dropdownDataTestId="BPSFormStepFour.CurrentWellbeing.PsychosisSymptomsPeriod.Dropdown"
        inputDataTestId="BPSFormStepFour.CurrentWellbeing.PsychosisQuestionResponse.Input"
        radioGroupDataTestId="BPSFormStepFour.CurrentWellbeing.PsychosisSymptomsCause.RadioGroup"
        radioGroupLabelTestId="BPSFormStepFour.CurrentWellbeing.PsychosisSymptomsCause.Label"
      />
    </>
  );
};

BPSFormStepFour.propTypes = {};

BPSFormStepFour.defaultProps = {};

export default BPSFormStepFour;
