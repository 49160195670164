import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import SecondaryButton from '../../components/common/buttons/SecondaryButton';
import oroOrbVideoStill from '../../img/oro-orb-video-still.png';
import useIntl from '../../hooks/useIntl';
import messages from './messages';
import styles from './HomePage.module.scss';
import './HomePage.scss';
import FDAStatement from '../../components/FDAStatement/FDAStatement';

const HomePage = () => {
  const { formatMessage } = useIntl();
  return (
    <Fragment>
      <Helmet>
        <title>{formatMessage(messages.metaTitle)}</title>
        <meta
          name="description"
          content={formatMessage(messages.metaDescription)}
        />
      </Helmet>
      <div className={styles.container}>
        <video
          poster={oroOrbVideoStill}
          className={classnames('desktop-only', styles.video)}
          playsInline
          muted
          loop
          autoPlay
        >
          <source
            src="https://storage.googleapis.com/oro-co-public-assets/ORO-orb-v3.mp4"
            type="video/mp4"
          ></source>
        </video>
        <div className={styles['title-container']}>
          <h1>{formatMessage(messages.topTitle)}</h1>
          <h3>{formatMessage(messages.topSubtitle)}</h3>
          <div className={styles['banner-button']}>
            <Link to="/my-account/discoverv2">
              <SecondaryButton
                label={formatMessage(messages.getStarted)}
                className={styles['home-btn']}
              />
            </Link>
          </div>
        </div>
      </div>
      <div className={styles['collaboration-banner']}>
        <div>
          <h3>{formatMessage(messages.collaborationTitle)}</h3>
          <p className="desktop-only">
            {formatMessage(messages.collaborationText)}
          </p>
          <div className={classnames(styles['title-buttons'], 'desktop-only')}>
            <Link to="/explore/about">
              <SecondaryButton
                label={formatMessage(messages.learnMoreBtn)}
                className={styles['home-btn']}
              />
            </Link>
          </div>
        </div>
      </div>
      <div
        className={classnames('mobile-only', styles['collaboration-mobile'])}
      >
        <p>{formatMessage(messages.collaborationText)}</p>
        <Link to="/explore/about">
          <SecondaryButton
            label={formatMessage(messages.learnMoreBtn)}
            className={styles['home-btn']}
          />
        </Link>
      </div>
      <div className="mobile-only">
        <FDAStatement />
      </div>
    </Fragment>
  );
};

export default HomePage;
