import classnames from 'classnames';
import React, { Fragment } from 'react';
import useIntl from '../../../../hooks/useIntl';
import SolsticeIntakePDF from '../../../AssessmentPDF/SolsticeIntakePDF';
import Modal from '../../../common/Modal';
import FormDownloadLink from '../../../FormListView/components/FormDownloadLink/FormDownloadLink';
import FormHeader from '../../../FormListView/components/FormHeader/FormHeader';
import styles from '../../../FormListView/FormListView.module.scss';
import messages from './messages';

const SolsticeFormResponse = ({ onClick, item, data, opened }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {opened && (
        <Modal className={styles.modal}>
          <div className={styles.content}>
            <FormHeader onClick={onClick} formName={item.form_name} />
            <FormDownloadLink
              item={item}
              pdfDownload={
                <SolsticeIntakePDF
                  item={item}
                  formDetail={item.patient}
                  data={data}
                />
              }
            />
            <div className={styles.box}>
              <div className={styles['content-detail']}>
                {item.patient && item.patient.id ? (
                  <Fragment>
                    <section className={styles['content-detail-section']}>
                      <div className={classnames('text')}>
                        <span>
                          {formatMessage(messages.practiceHeardOfOrigin)}
                        </span>
                        <p>{data.practice_heard_of_origin}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>{formatMessage(messages.counselingReason)}</span>
                        <p>{data.counseling_reason}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>{formatMessage(messages.counselingGoals)}</span>
                        <p>{data.counseling_goals}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>
                          {formatMessage(messages.psychotherapyHistory)}
                        </span>
                        <p>{data.psychotherapy_history}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>
                          {formatMessage(messages.currentMedicationAndReason)}
                        </span>
                        <p>{data.current_medication_and_reason}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>{formatMessage(messages.prescriptionMds)}</span>
                        <p>{data.prescription_mds}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>{formatMessage(messages.primaryDoctor)}</span>
                        <p>{data.primary_doctor}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>{formatMessage(messages.alcoholUse)}</span>
                        <p>{data.alcohol_use}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>
                          {formatMessage(messages.recreationalSubstance)}
                        </span>
                        <p>{data.recreational_substance}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>{formatMessage(messages.suicidalThoughts)}</span>
                        <p>{data.suicidal_thoughts}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>{formatMessage(messages.attemptedSuicide)}</span>
                        <p>{data.attempted_suicide}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>{formatMessage(messages.harmOthers)}</span>
                        <p>{data.harm_others}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>
                          {formatMessage(messages.psychiatricHospitalization)}
                        </span>
                        <p>{data.psychiatric_hospitalization}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>
                          {formatMessage(messages.mentalIllnessHistory)}
                        </span>
                        <p>{data.mental_illness_history}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>
                          {formatMessage(messages.relationshipNature)}
                        </span>
                        <p>{data.relationship_nature}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>{formatMessage(messages.livingSituation)}</span>
                        <p>{data.living_situation}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>{formatMessage(messages.educationLevel)}</span>
                        <p>{data.education_level}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>{formatMessage(messages.currentOccupation)}</span>
                        <p>{data.current_occupation}</p>
                      </div>
                      <div className={classnames('text')}>
                        <span>
                          {formatMessage(
                            messages.experiencedSymptomsPastSixMonths,
                          )}
                        </span>

                        <p>
                          {data.experienced_symptoms_past_six_months.join(', ')}
                        </p>
                        {data.experienced_symptoms_past_six_months.some(
                          (v) => v === 'Other',
                        ) && (
                          <p>
                            {`Other input : ${data.experienced_symptoms_past_six_months_free_text}`}
                          </p>
                        )}
                      </div>
                      <div className={classnames('text')}>
                        <span>
                          {formatMessage(messages.otherExperiencedSymptoms)}
                        </span>
                        <p>{data.other_experienced_symptoms.join(', ')}</p>
                        {data.other_experienced_symptoms.some(
                          (v) => v === 'Other',
                        ) && (
                          <p>
                            {`Other input : ${data.other_experienced_symptoms_free_text}`}
                          </p>
                        )}
                      </div>
                      <div className={classnames('text')}>
                        <span>{formatMessage(messages.otherMentions)}</span>
                        <p>{data.other_mentions}</p>
                      </div>
                    </section>
                  </Fragment>
                ) : (
                  <div>No Data Available</div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SolsticeFormResponse;
