import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import DesktopNav from '../DesktopNav';
import DiscoverNav from '../DiscoverNav';
import MobileNav from '../MobileNav';
import AuthContext from '../../../context/AuthContext';
import { USER_TYPES } from '../../../constants';

const renderHeader = (
  path,
  isSticky,
  history,
  progressPercent = 0,
  authUser,
) => {
  switch (path) {
    case '/my-account/discoverv2':
      return (
        <DiscoverNav
          isPatient={
            authUser &&
            authUser.profile_type &&
            authUser.profile_type === USER_TYPES.PATIENT
          }
          isSticky={isSticky}
          history={history}
          progressPercent={progressPercent}
        />
      );
    case '/thankyou':
    case '/swipe':
    case '/':
    case '/pre-initial-signup':
    case '/doctor-initial-signup':
    case '/join':
    case '/user-denied':
    case '/unathorize-user':
    case '/user-registered':
    case '/reset/email':
    case '/my-account/discover':
    case '/my-account/recommended-products':
    case '/my-account/intake-form':
    case '/doctor/clinician/registration':
      return null;
    case (path.match('/my-account/form') || {}).input:
      return null;
    case (path.match('/login') || {}).input:
      return null;
    case (path.match('/approval') || {}).input:
      return null;
    case (path.match('/disable') || {}).input:
      return null;
    case (path.match('/expire-token') || {}).input:
      return null;
    case (path.match('/activate') || {}).input:
      return null;
    case (path.match('/signup') || {}).input:
      return null;
    case (path.match('/Registration') || {}).input:
      return null;
    case (path.match('/forgot-password') || {}).input:
      return null;
    case (path.match('/reset-password') || {}).input:
      return null;
    case (path.match('/discoverv3') || {}).input:
      return null;
    default:
      return (
        <DesktopNav
          isHomePage={path === '/'}
          isSticky={isSticky}
          history={history}
          isDoctor={
            authUser &&
            authUser.profile_type &&
            authUser.profile_type === USER_TYPES.DOCTOR
              ? true
              : false
          }
          isResearcher={
            authUser &&
            authUser.profile_type &&
            authUser.profile_type === 'researcher'
              ? true
              : false
          }
        />
      );
  }
};

const renderMobileHeader = (path, isSticky, history, authUser) => {
  switch (path) {
    case '/':
    case '/join':
    case '/user-denied':
    case '/unathorize-user':
    case '/user-registered':
    case '/reset/email':
    case '/thankyou':
    case '/my-account/intake-form':
      return null;
    case (path.match('/my-account/form') || {}).input:
      return null;
    case (path.match('/login') || {}).input:
      return null;
    case (path.match('/approval') || {}).input:
      return null;
    case (path.match('/disable') || {}).input:
      return null;
    case (path.match('/expire-token') || {}).input:
      return null;
    case (path.match('/activate') || {}).input:
      return null;
    case (path.match('/signup') || {}).input:
      return null;
    case (path.match('/Registration') || {}).input:
      return null;
    case (path.match('/forgot-password') || {}).input:
      return null;
    case (path.match('/reset-password') || {}).input:
      return null;
    case (path.match('/discoverv3') || {}).input:
      return null;
    default:
      return (
        <MobileNav
          isSubmenu={path.includes('/my-account/')}
          goBack={history.goBack}
          isSticky={isSticky}
          isHomePage={path === '/'}
          isDoctor={
            authUser &&
            authUser.profile_type &&
            authUser.profile_type === USER_TYPES.DOCTOR
              ? true
              : false
          }
        />
      );
  }
};

const handleScroll = (setIsSticky) => setIsSticky(window.scrollY > 0);

const Header = ({ location, history }) => {
  const [isSticky, setIsSticky] = useState(false);
  const { authUser } = useContext(AuthContext);
  window.addEventListener('scroll', () => handleScroll(setIsSticky));
  return (
    <header>
      {renderHeader(location.pathname, isSticky, history, 0, authUser)}
      {renderMobileHeader(location.pathname, isSticky, history, authUser)}
    </header>
  );
};

export default withRouter(Header);
