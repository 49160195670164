import { useEffect, useState } from 'react';
import { fetchSuperbill, updateSuperbill } from '../../api/superbill';

const parseInitialValues = (superbill) => {
  const fields = {
    balance_due: '',
    client_address: '',
    client_city: '',
    client_dob: '',
    client_home_phone: '',
    client_first_name: '',
    client_last_name: '',
    client_state: '',
    client_email: '',
    client_zip_code: '',
    dr_ein_nr: '',
    dr_license_nr: '',
    dr_notes: '',
    dr_npi_nr: '',
    payment: '',
    total_charge: '',
    procedures: null,
    diagnoses: null,
    patient_viewed_at: null,
  };

  const superbillValues = {};
  Object.keys(fields).forEach((field) => {
    if (superbill[field]) {
      superbillValues[field] = superbill[field] || '';
    }
  });
  const defaultDoctorLicenseDetails = {
    dr_ein_nr: superbill.formDefaults?.dr_ein_nr,
    dr_npi_nr: superbill.formDefaults?.dr_npi_nr,
  };

  const defaultClientInfo = {
    client_first_name: superbill.formDefaults?.patient_first_name,
    client_last_name: superbill.formDefaults?.patient_last_name,
    client_email: superbill.formDefaults?.patient_email,
  };

  const prevSuperbillValues = {};
  Object.keys(fields).forEach((field) => {
    if (
      superbill.formDefaults?.previousSuperbill &&
      superbill.formDefaults?.previousSuperbill[field]
    ) {
      if (field === 'procedures') {
        const updatedDateOfService = superbill.formDefaults?.previousSuperbill[
          field
        ].map((procedure) => {
          procedure['date_of_service'] =
            superbill.formDefaults?.appointment_date;
          console.log({ procedure });
          return procedure;
        });
        prevSuperbillValues[field] = updatedDateOfService;
      } else {
        prevSuperbillValues[field] =
          superbill.formDefaults?.previousSuperbill[field];
      }
    }
  });

  return {
    ...fields,
    ...defaultDoctorLicenseDetails,
    ...defaultClientInfo,
    ...prevSuperbillValues,
    ...superbillValues,
  };
};

const useSuperbill = (superbill_id) => {
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [formDefaults, setSuperbillDefaults] = useState(null);
  const [superbillInitialValues, setSuperbillInitialValues] = useState(null);
  const [superbill, setSuperbill] = useState(null);

  const fetchSuperbillData = async () => {
    setLoading(true);
    setError(false);
    try {
      const data = await fetchSuperbill(superbill_id, true);
      if (Object.keys(data).length === 0 || data.error) {
        setError(true);
      } else {
        setSuperbill(data);
        setSuperbillDefaults(data.formDefaults);
        const initialValues = parseInitialValues(data);
        setSuperbillInitialValues(initialValues);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  useEffect(() => {
    fetchSuperbillData();
  }, [superbill_id]);

  const refetch = async () => {
    await fetchSuperbillData();
  };

  const update = async (body) => {
    setLoading(true);
    const result = await updateSuperbill(superbill_id, body);
    await refetch();
    setLoading(false);
    return result;
  };

  return {
    formDefaults,
    superbill,
    isLoading,
    error,
    refetch,
    superbillInitialValues,
    update,
  };
};

export default useSuperbill;
