import React from 'react';
import LayoutPDF from './components/LayoutPDF';
import { Text, View } from '@react-pdf/renderer';
import { AssessmentPDFStyles } from './AssessmentPDFStyles';

const AssessmentPDF = ({ item, formDetail }) => (
  <LayoutPDF item={item} formDetail={formDetail} title="Assessment">
    <>
      <Text style={AssessmentPDFStyles.content_detail.h2}>
        Personal Details
      </Text>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>Name:</Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.personalDetails && formDetail.personalDetails.name}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>Address:</Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.personalDetails && formDetail.personalDetails.address}
        </Text>
      </View>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>City:</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.personalDetails && formDetail.personalDetails.city}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>State:</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.personalDetails && formDetail.personalDetails.state}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Zip/Postal Code:
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.personalDetails &&
              formDetail.personalDetails.postalCode}
          </Text>
        </View>
      </View>
      <Text style={AssessmentPDFStyles.content_detail.h2}>Contact Details</Text>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Home Phone:
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.contactDetails && formDetail.contactDetails.homePhone}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Cell Phone:
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.contactDetails && formDetail.contactDetails.cellPhone}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Work Phone:
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.contactDetails && formDetail.contactDetails.workPhone}
          </Text>
        </View>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>Email:</Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.contactDetails && formDetail.contactDetails.email}
        </Text>
      </View>
      <Text style={AssessmentPDFStyles.content_detail.h2}>Other Details</Text>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>SSH#</Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail && formDetail.otherDetails.ssId}
        </Text>
      </View>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>Age</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.otherDetails && formDetail.otherDetails.age}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>BirthDate</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.otherDetails && formDetail.otherDetails.birthDate}
          </Text>
        </View>
      </View>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Your Gender
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.otherDetails && formDetail.otherDetails.gender}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Do you identify as transgender?
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.otherDetails &&
            formDetail.otherDetails.isTransgender === true
              ? 'Yes'
              : 'No'}
          </Text>
        </View>
      </View>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>Education</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.otherDetails && formDetail.otherDetails.education}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Current Occupation
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.otherDetails &&
              formDetail.otherDetails.currentOccupation}
          </Text>
        </View>
      </View>
      <Text style={AssessmentPDFStyles.content_detail.h2}>
        Medical Emergancy Contact Details
      </Text>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>Name</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.medicalEmergency && formDetail.medicalEmergency.name}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>Address</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.medicalEmergency && formDetail.medicalEmergency.address}
          </Text>
        </View>
      </View>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>Relation</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.medicalEmergency &&
              formDetail.medicalEmergency.relation}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>Phone</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.medicalEmergency && formDetail.medicalEmergency.phone}
          </Text>
        </View>
      </View>
      <Text style={AssessmentPDFStyles.content_detail.h2}>
        Family Doctor Details
      </Text>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>Name</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.familyDoctor && formDetail.familyDoctor.name}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>Phone:</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.familyDoctor && formDetail.familyDoctor.phone}
          </Text>
        </View>
      </View>
      <Text style={AssessmentPDFStyles.content_detail.h2}>Family Details</Text>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          RelationShip Status
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.familyDetails &&
            formDetail.familyDetails.relationshipStatus}
        </Text>
      </View>
      {formDetail.familyDetails &&
        formDetail.familyDetails.children.length > 0 && (
          <>
            <Text style={AssessmentPDFStyles.content_detail.h2}>
              Children's
            </Text>
            <View style={AssessmentPDFStyles.content_detail.detail}>
              <View style={AssessmentPDFStyles.table}>
                <View style={AssessmentPDFStyles.table.tableTr}>
                  <View
                    style={[
                      AssessmentPDFStyles.table.tableTr.tableTrDiv,
                      AssessmentPDFStyles.content_detail.column3,
                    ]}
                  >
                    <Text style={AssessmentPDFStyles.table.tableTr.span}>
                      Name
                    </Text>
                  </View>
                  <View
                    style={[
                      AssessmentPDFStyles.table.tableTr.tableTrDiv,
                      AssessmentPDFStyles.content_detail.column3,
                    ]}
                  >
                    <Text style={AssessmentPDFStyles.table.tableTr.span}>
                      Gender
                    </Text>
                  </View>
                  <View
                    style={[
                      AssessmentPDFStyles.table.tableTr.tableTrDiv,
                      AssessmentPDFStyles.content_detail.column3,
                    ]}
                  >
                    <Text style={AssessmentPDFStyles.table.tableTr.span}>
                      Age
                    </Text>
                  </View>
                </View>
                {formDetail.familyDetails.children.map((item, index) => (
                  <View key={index} style={AssessmentPDFStyles.table.tableTr}>
                    <Text
                      style={[
                        AssessmentPDFStyles.table.tableTr.p,
                        AssessmentPDFStyles.content_detail.column3,
                      ]}
                    >
                      {item.name}
                    </Text>
                    <Text
                      style={[
                        AssessmentPDFStyles.table.tableTr.p,
                        AssessmentPDFStyles.content_detail.column3,
                      ]}
                    >
                      {item.gender}
                    </Text>
                    <Text
                      style={[
                        AssessmentPDFStyles.table.tableTr.p,
                        AssessmentPDFStyles.content_detail.column3,
                      ]}
                    >
                      {item.age}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </>
        )}
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Spouse Name
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.familyDetails && formDetail.familyDetails.spouseName}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Spouse Phone:
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.familyDetails && formDetail.familyDetails.spousePhone}
          </Text>
        </View>
      </View>
      <Text style={AssessmentPDFStyles.content_detail.h2}>
        Primary Insurance Details
      </Text>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Company Name
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.insuranceDetails &&
              formDetail.insuranceDetails.companyName}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>Ins ID#</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.insuranceDetails &&
              formDetail.insuranceDetails.insuranceId}
          </Text>
        </View>
      </View>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Group Name
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.insuranceDetails &&
              formDetail.insuranceDetails.groupName}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>Group ID#</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.insuranceDetails && formDetail.insuranceDetails.groupId}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>Employer</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.insuranceDetails &&
              formDetail.insuranceDetails.employer}
          </Text>
        </View>
      </View>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Subscriber's Name
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.insuranceDetails &&
              formDetail.insuranceDetails.subscriberName}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>Relation</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.insuranceDetails &&
              formDetail.insuranceDetails.subscriberRelation}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Subscriber's DOB
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.insuranceDetails &&
              formDetail.insuranceDetails.subscriberDOB}
          </Text>
        </View>
      </View>
      <Text style={AssessmentPDFStyles.content_detail.h2}>Medical History</Text>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>Allergies</Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.allergies && formDetail.allergies.toString()}
        </Text>
      </View>
      {formDetail.currentMedications &&
        formDetail.currentMedications.length > 0 && (
          <>
            <Text style={AssessmentPDFStyles.content_detail.h2}>
              Current Medications
            </Text>
            <View style={AssessmentPDFStyles.content_detail.detail}>
              <View style={AssessmentPDFStyles.table}>
                <View style={AssessmentPDFStyles.table.tableTr}>
                  <View
                    style={[
                      AssessmentPDFStyles.table.tableTr.tableTrDiv,
                      AssessmentPDFStyles.content_detail.column3,
                    ]}
                  >
                    <Text style={AssessmentPDFStyles.table.tableTr.span}>
                      Dose
                    </Text>
                  </View>
                  <View
                    style={[
                      AssessmentPDFStyles.table.tableTr.tableTrDiv,
                      AssessmentPDFStyles.content_detail.column3,
                    ]}
                  >
                    <Text style={AssessmentPDFStyles.table.tableTr.span}>
                      frequency
                    </Text>
                  </View>
                  <View
                    style={[
                      AssessmentPDFStyles.table.tableTr.tableTrDiv,
                      AssessmentPDFStyles.content_detail.column3,
                    ]}
                  >
                    <Text style={AssessmentPDFStyles.table.tableTr.span}>
                      DateUsed
                    </Text>
                  </View>
                </View>
                {formDetail.currentMedications.map((item, index) => (
                  <View key={index} style={AssessmentPDFStyles.table.tableTr}>
                    <Text
                      style={[
                        AssessmentPDFStyles.table.tableTr.p,
                        AssessmentPDFStyles.content_detail.column3,
                      ]}
                    >
                      {item.dose}
                    </Text>
                    <Text
                      style={[
                        AssessmentPDFStyles.table.tableTr.p,
                        AssessmentPDFStyles.content_detail.column3,
                      ]}
                    >
                      {item.frequency}
                    </Text>
                    <Text
                      style={[
                        AssessmentPDFStyles.table.tableTr.p,
                        AssessmentPDFStyles.content_detail.column3,
                      ]}
                    >
                      {item.dateUsed}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </>
        )}

      {formDetail.recreationalDrugs && formDetail.recreationalDrugs.length > 0 && (
        <>
          <Text style={AssessmentPDFStyles.content_detail.h2}>
            Recreational Durgs
          </Text>
          <View style={AssessmentPDFStyles.content_detail.detail}>
            <View style={AssessmentPDFStyles.table}>
              <View style={AssessmentPDFStyles.table.tableTr}>
                <View
                  style={[
                    AssessmentPDFStyles.table.tableTr.tableTrDiv,
                    AssessmentPDFStyles.content_detail.column4,
                  ]}
                >
                  <Text style={AssessmentPDFStyles.table.tableTr.span}>
                    type
                  </Text>
                </View>
                <View
                  style={[
                    AssessmentPDFStyles.table.tableTr.tableTrDiv,
                    AssessmentPDFStyles.content_detail.column4,
                  ]}
                >
                  <Text style={AssessmentPDFStyles.table.tableTr.span}>
                    dose
                  </Text>
                </View>
                <View
                  style={[
                    AssessmentPDFStyles.table.tableTr.tableTrDiv,
                    AssessmentPDFStyles.content_detail.column4,
                  ]}
                >
                  <Text style={AssessmentPDFStyles.table.tableTr.span}>
                    frequency
                  </Text>
                </View>
                <View
                  style={[
                    AssessmentPDFStyles.table.tableTr.tableTrDiv,
                    AssessmentPDFStyles.content_detail.column4,
                  ]}
                >
                  <Text style={AssessmentPDFStyles.table.tableTr.span}>
                    DateUsed
                  </Text>
                </View>
              </View>
              {formDetail.recreationalDrugs.map((item, index) => (
                <View key={index} style={AssessmentPDFStyles.table.tableTr}>
                  <Text
                    style={[
                      AssessmentPDFStyles.table.tableTr.p,
                      AssessmentPDFStyles.content_detail.column4,
                    ]}
                  >
                    {item.type}
                  </Text>
                  <Text
                    style={[
                      AssessmentPDFStyles.table.tableTr.p,
                      AssessmentPDFStyles.content_detail.column4,
                    ]}
                  >
                    {item.dose}
                  </Text>
                  <Text
                    style={[
                      AssessmentPDFStyles.table.tableTr.p,
                      AssessmentPDFStyles.content_detail.column4,
                    ]}
                  >
                    {item.frequency}
                  </Text>
                  <Text
                    style={[
                      AssessmentPDFStyles.table.tableTr.p,
                      AssessmentPDFStyles.content_detail.column4,
                    ]}
                  >
                    {item.dateUsed}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </>
      )}
      {formDetail.medicalIllness && formDetail.medicalIllness.length > 0 && (
        <>
          <Text style={AssessmentPDFStyles.content_detail.h2}>
            Medical Illness
          </Text>
          <View style={AssessmentPDFStyles.content_detail.detail}>
            <View style={AssessmentPDFStyles.table}>
              <View style={AssessmentPDFStyles.table.tableTr}>
                <View
                  style={[
                    AssessmentPDFStyles.table.tableTr.tableTrDiv,
                    AssessmentPDFStyles.content_detail.column2,
                  ]}
                >
                  <Text style={AssessmentPDFStyles.table.tableTr.span}>
                    illness
                  </Text>
                </View>
                <View
                  style={[
                    AssessmentPDFStyles.table.tableTr.tableTrDiv,
                    AssessmentPDFStyles.content_detail.column2,
                  ]}
                >
                  <Text style={AssessmentPDFStyles.table.tableTr.span}>
                    status
                  </Text>
                </View>
              </View>
              {formDetail.medicalIllness.map((item, index) => (
                <View key={index} style={AssessmentPDFStyles.table.tableTr}>
                  <Text
                    style={[
                      AssessmentPDFStyles.table.tableTr.p,
                      AssessmentPDFStyles.content_detail.column2,
                    ]}
                  >
                    {item.illness}
                  </Text>
                  <Text
                    style={[
                      AssessmentPDFStyles.table.tableTr.p,
                      AssessmentPDFStyles.content_detail.column2,
                    ]}
                  >
                    {item.status}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </>
      )}
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          Previously psychological care or counseling?
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.hasPreviousPsychologicalCare === true ? 'Yes' : 'No'}
        </Text>
      </View>
      {formDetail.psychologicalCare && formDetail.psychologicalCare.length > 0 && (
        <>
          <Text style={AssessmentPDFStyles.content_detail.h2}>
            Psychological Care
          </Text>
          <View style={AssessmentPDFStyles.content_detail.detail}>
            <View style={AssessmentPDFStyles.table}>
              <View style={AssessmentPDFStyles.table.tableTr}>
                <View
                  style={[
                    AssessmentPDFStyles.table.tableTr.tableTrDiv,
                    AssessmentPDFStyles.content_detail.column3,
                  ]}
                >
                  <Text style={AssessmentPDFStyles.table.tableTr.span}>
                    clinicians
                  </Text>
                </View>
                <View
                  style={[
                    AssessmentPDFStyles.table.tableTr.tableTrDiv,
                    AssessmentPDFStyles.content_detail.column3,
                  ]}
                >
                  <Text style={AssessmentPDFStyles.table.tableTr.span}>
                    difficulty
                  </Text>
                </View>
                <View
                  style={[
                    AssessmentPDFStyles.table.tableTr.tableTrDiv,
                    AssessmentPDFStyles.content_detail.column3,
                  ]}
                >
                  <Text style={AssessmentPDFStyles.table.tableTr.span}>
                    date
                  </Text>
                </View>
              </View>
              {formDetail.psychologicalCare.map((item, index) => (
                <View key={index} style={AssessmentPDFStyles.table.tableTr}>
                  <Text
                    style={[
                      AssessmentPDFStyles.table.tableTr.p,
                      AssessmentPDFStyles.content_detail.column3,
                    ]}
                  >
                    {item.clinicians}
                  </Text>
                  <Text
                    style={[
                      AssessmentPDFStyles.table.tableTr.p,
                      AssessmentPDFStyles.content_detail.column3,
                    ]}
                  >
                    {item.difficulty}
                  </Text>
                  <Text
                    style={[
                      AssessmentPDFStyles.table.tableTr.p,
                      AssessmentPDFStyles.content_detail.column3,
                    ]}
                  >
                    {item.date}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </>
      )}
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          Have you been prescribed psychiatric medications?
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.hasPsychiatricMedications === true ? 'Yes' : 'No'}
        </Text>
      </View>
      {formDetail.psychiatricMedications &&
        formDetail.psychiatricMedications.length > 0 && (
          <>
            <Text style={AssessmentPDFStyles.content_detail.h2}>
              Psychiatric Medications
            </Text>
            <View style={AssessmentPDFStyles.content_detail.detail}>
              <View style={AssessmentPDFStyles.table}>
                <View style={AssessmentPDFStyles.table.tableTr}>
                  <View
                    style={[
                      AssessmentPDFStyles.table.tableTr.tableTrDiv,
                      AssessmentPDFStyles.content_detail.column3,
                    ]}
                  >
                    <Text style={AssessmentPDFStyles.table.tableTr.span}>
                      medicine
                    </Text>
                  </View>
                  <View
                    style={[
                      AssessmentPDFStyles.table.tableTr.tableTrDiv,
                      AssessmentPDFStyles.content_detail.column3,
                    ]}
                  >
                    <Text style={AssessmentPDFStyles.table.tableTr.span}>
                      dose
                    </Text>
                  </View>
                  <View
                    style={[
                      AssessmentPDFStyles.table.tableTr.tableTrDiv,
                      AssessmentPDFStyles.content_detail.column3,
                    ]}
                  >
                    <Text style={AssessmentPDFStyles.table.tableTr.span}>
                      effects
                    </Text>
                  </View>
                </View>
                {formDetail.psychiatricMedications.map((item, index) => (
                  <View key={index} style={AssessmentPDFStyles.table.tableTr}>
                    <Text
                      style={[
                        AssessmentPDFStyles.table.tableTr.p,
                        AssessmentPDFStyles.content_detail.column3,
                      ]}
                    >
                      {item.medicine}
                    </Text>
                    <Text
                      style={[
                        AssessmentPDFStyles.table.tableTr.p,
                        AssessmentPDFStyles.content_detail.column3,
                      ]}
                    >
                      {item.dose}
                    </Text>
                    <Text
                      style={[
                        AssessmentPDFStyles.table.tableTr.p,
                        AssessmentPDFStyles.content_detail.column3,
                      ]}
                    >
                      {item.effects}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </>
        )}
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          Have you ever been hospitalized for a psychological difficulty?
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.hasHospitalized === true ? 'Yes' : 'No'}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          Have you ever had feelings or thoughts that you didn’t want to live?
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.hasSuicidalThoughts === true ? 'Yes' : 'No'}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          Sleeping Habits
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.sleepingHabits}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          Do you have difficulty falling asleep?
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.difficultyFallingAsleep === true ? 'Yes' : 'No'}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          Do you have awakenings during the night?
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.awakeningDuringNight === true ? 'Yes' : 'No'}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          Unrefreshing Sleep
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.poorSleep === true ? 'Yes' : 'No'}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          How long have you been experiencing Poor Sleep Problems?
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.sleepingProblemSince}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>Weight</Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.weight}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>Height</Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.height}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          Happy with your Weight?
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.isHappyWithWeight === true ? 'Yes' : 'No'}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          Recently lost or gained weight?
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.hasLostGainedWeight === true ? 'Yes' : 'No'}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          Difficulty with appetite
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.difficultyWithAppetite}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          Depression Level
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.depressionLevel}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          Depression Since
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.depressionSince}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          experiencing anxiety
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.hasAnxiety === true ? 'Yes' : 'No'}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          anxiety Since
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.anxietySince}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          alcohol Per Week
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.alchoholPerWeek}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          alcohol Per Night
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.alchoholPerNight}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          alcohol Per Hour
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.alchoholPerHour}
        </Text>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          How often do you use recreational drugs
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.recreationalDrugsFrequency}
        </Text>
      </View>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            dependency on alcohol or recreational drugs?
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.hadAlchoholDrugDependency === true ? 'Yes' : 'No'}
          </Text>
        </View>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Nature of dependency or abuse
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.natureOfAlchoholDepenency}
          </Text>
        </View>
      </View>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            have a history of prescription drug dependency or abuse?
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.hadPrescreptionDrugDependency === true ? 'Yes' : 'No'}
          </Text>
        </View>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Nature of dependency or abuse
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.natureOfPrescreptionDependency}
          </Text>
        </View>
      </View>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Are you or could you be pregnant?
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.isPregnant === true ? 'Yes' : 'No'}
          </Text>
        </View>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Form of BC do you use
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.formOfBC}
          </Text>
        </View>
      </View>
      <Text style={AssessmentPDFStyles.content_detail.h2}>
        Has anyone in your family been diagnosed with or treated for
      </Text>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Bipolar disorder
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.familyMemberBipolarDisorder === true ? 'Yes' : 'No'}
          </Text>
        </View>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>Anxiety</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.familyMemberAnxiety === true ? 'Yes' : 'No'}
          </Text>
        </View>
      </View>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>PTSD</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.familyMemberPTSD === true ? 'Yes' : 'No'}
          </Text>
        </View>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>Anger</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.familyMemberAnger === true ? 'Yes' : 'No'}
          </Text>
        </View>
      </View>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Other substance abuse
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.familyMemberOtherSubstance === true ? 'Yes' : 'No'}
          </Text>
        </View>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Depression
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.familyMemberDepression === true ? 'Yes' : 'No'}
          </Text>
        </View>
      </View>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>Violence</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.familyMemberViolence === true ? 'Yes' : 'No'}
          </Text>
        </View>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Alcohol abuse
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.familyMemberAlcoholAbuse === true ? 'Yes' : 'No'}
          </Text>
        </View>
      </View>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Schizophrenia
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.familyMemberSchizophrenia === true ? 'Yes' : 'No'}
          </Text>
        </View>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>Suicide</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.familyMemberSuicide === true ? 'Yes' : 'No'}
          </Text>
        </View>
      </View>
      <View>
        <Text style={AssessmentPDFStyles.content_detail.span}>
          Who had what problems?
        </Text>
        <Text style={AssessmentPDFStyles.content_detail.p}>
          {formDetail.familyMemberTreatment}
        </Text>
      </View>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Has any family member been treated with a psychiatric medication?
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.hasFamilyMemberPsychatric === true ? 'Yes' : 'No'}
          </Text>
        </View>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            If yes, who was treated, what medications, treatment?
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.familyMemberPsychatricTreatment}
          </Text>
        </View>
      </View>
      <View style={AssessmentPDFStyles.content_detail.detail}>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            In your own words, what is the nature of the concern that you wish
            to address in therapy? Feel free to describe this in as much or as
            little detail as you wish. What do you consider some of your
            strengths? Weaknesses?
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.natureOfTherapy}
          </Text>
        </View>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Please list with dates any significant trauma you have experienced:
            violence—physical, sexual, emotional, abuse, natural occurrences,
            etc.
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.significantTrauma}
          </Text>
        </View>
      </View>
    </>
  </LayoutPDF>
);

export default AssessmentPDF;
