import React from 'react';
import FieldInput from '../../../../common/fields/FieldInput';

const TextInput = ({
  item,
  onChange,
  onBlur,
  dataTestId,
  value,
  label,
  placeholder = 'Enter here',
}) => {
  return (
    <FieldInput
      label={label}
      placeholder={placeholder}
      name={item.id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      data-testid={dataTestId}
    />
  );
};

export default TextInput;
