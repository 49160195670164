import { defineMessages } from 'react-intl';

export default defineMessages({
  LeaveLabel: {
    id: 'components.leaveIntakeForm.Leave',
    description: 'Leave Appointment Label',
    defaultMessage: 'Leave Intake Form',
  },
  LeaveMobileLabel: {
    id: 'components.leaveMobileIntakeForm.Leave',
    description: 'Leave Appointment Label',
    defaultMessage: 'Leave',
  },
  confirmLeaveTitle: {
    id: 'components.leaveIntakeForm.confirmLeaveTitle',
    description: 'Leave IntakeForm confirmation title',
    defaultMessage: 'Are you sure you want to leave intake form?',
  },
  confirmLeaveText: {
    id: 'components.leaveIntakeForm.confirmLeaveText',
    description: 'Leave IntakeForm confirmation text',
    defaultMessage: 'You have unsaved intake form data. It can not be undone.',
  },
  confirmLeaveRecommendation: {
    id: 'components.leaveIntakeForm.confirmLeaveRecommendation',
    description: 'Leave IntakeForm confirmation Continue label',
    defaultMessage: 'Leave Intake Form & Continue to Appointment',
  },
  confirmLeaveCancel: {
    id: 'components.leaveIntakeForm.confirmLeaveCancel',
    description: 'Leave IntakeForm confirmation cancel label',
    defaultMessage: 'Cancel',
  },
});
