import React, { useState, useContext } from 'react';
import Sidebar from 'react-sidebar';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './MobileNav.module.scss';
import SidebarMenu from '../SidebarMenu';
import BoxContext from '../../../context/BoxContext';
import menu from '../../../img/icons/menu.svg';
import Logo from '../../Logo';

const MobileNav = ({ isSubmenu, goBack, isSticky, isHomePage, isDoctor }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isBoxOpen } = useContext(BoxContext);
  const isTransparentBanner = isHomePage && !isSticky && !isBoxOpen;

  return (
    <div
      className={[
        classnames('mobile-only', {
          'fixed-nav': !isTransparentBanner,
        }),
        styles.center,
      ].join(' ')}
    >
      <div className={classnames(styles['icon-container'], styles.left)}>
        {
          <Sidebar
            sidebar={<SidebarMenu onCloseMenu={() => setIsMenuOpen(false)} />}
            open={isMenuOpen}
            onSetOpen={setIsMenuOpen}
            sidebarClassName={styles.sidebar}
            rootClassName={styles.root}
          >
            <img src={menu} alt="Menu" onClick={() => setIsMenuOpen(true)} />
          </Sidebar>
        }
      </div>
      <div className="logo-container">
        <Link to={isDoctor ? '/doctor/my-account' : '/my-account'}>
          <Logo />
        </Link>
      </div>
      <div className={classnames(styles['icon-container'], styles.right)}>
        {/* <Minicart theme={"default"} /> */}
      </div>
    </div>
  );
};

MobileNav.propTypes = {
  goBack: PropTypes.func.isRequired,
  isSubmenu: PropTypes.bool,
  isSticky: PropTypes.bool,
  isHomePage: PropTypes.bool,
};

MobileNav.defaultProps = {
  isSubmenu: false,
  isHomePage: false,
  isSticky: false,
};

export default MobileNav;
