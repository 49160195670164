import { defineMessages } from 'react-intl';

export default defineMessages({
  formTitle: {
    id: 'components.ClinicianRegistration.formTitle',
    description: 'Title of the Form',
    defaultMessage: 'Clinician Registration',
  },
  formWelcomeLabel: {
    id: 'components.ClinicianRegistration.formInfo',
    description: 'Information of the Form',
    defaultMessage: 'Welcome to',
  },
  formInfo: {
    id: 'components.ClinicianRegistration.formInfo',
    description: 'Information of the Form',
    defaultMessage:
      '. We are excited to help you manage and grow your practice and increase successful outcomes for your clients. Please answer the following questions so we can get started matching clients to you.',
  },
  required: {
    id: 'component.waitListForm.required',
    description: 'The label is for required field',
    defaultMessage: 'The this Field is required',
  },
  gender: {
    id: 'component.ClinicianRegistration.Gender',
    defaultMessage: 'Gender',
  },
  preferred_pronouns: {
    id: 'component.ClinicianRegistration.PreferredPronouns',
    defaultMessage: 'Preferred Pronouns',
  },
  certification: {
    id: 'component.ClinicianRegistration.certification',
    defaultMessage:
      ' Please indicate your clinical certification (select all that apply) ',
  },
  licenseStatus: {
    id: 'component.ClinicianRegistration.licenseStatus',
    defaultMessage: 'Are you a licensed service provider?',
  },
  states: {
    id: 'component.ClinicianRegistration.states',
    defaultMessage:
      'Please indicate what state(s) or province(s) you are currently licensed in:',
    license_number: {
      id: 'component.ClinicianRegistration.license_number',
      defaultMessage: 'License Number',
    },
    expiration_date: {
      id: 'component.ClinicianRegistration.expiration_date',
      defaultMessage: 'Expiration Date',
    },
    insurance_network: {
      id: 'component.ClinicianRegistration.insurance_network',
      defaultMessage: 'Insurance providers',
    },
  },
  locationLabel: {
    id: 'component.ClinicianRegistration.locationLabel',
    defaultMessage:
      'Please indicate the location(s) where you provide services:',
  },
  accountType: {
    id: 'component.ClinicianRegistration.accountType',
    defaultMessage: 'Choose account Type: ',
  },
  accountRole: {
    id: 'component.ClinicianRegistration.accountRole',
    defaultMessage: 'Role: ',
  },
  process: {
    id: 'component.ClinicianRegistration.process',
    defaultMessage:
      'Please select which parts of therapeutic process you are personally involved in (select all that apply):',
  },
  expertice: {
    id: 'component.ClinicianRegistration.expertice',
    defaultMessage:
      ' Please indicate which of the following you are comfortable treating:',
  },
  profilePic: {
    id: 'component.ClinicianRegistration.profilePic',
    defaultMessage: 'Upload photo',
  },
  therapyTitle: {
    id: 'component.ClinicianRegistration.therapyTitle',
    defaultMessage:
      ' Please indicate if you have strong expertise or focus in any of the following types of therapy',
  },
  descriptionLabel: {
    id: 'component.ClinicianRegistration.descriptionLabel',
    defaultMessage:
      'Please provide a short description of any other particular areas of focus in your clinical practice (i.e., disorders, issues or populations you specialize in treating, or types of therapy you focus on particularly) which you would like to appear on your profile.',
  },
  networkLabel: {
    id: 'component.ClinicianRegistration.networkLabel',
    defaultMessage: 'Insurance providers',
  },
  telehealthLabel: {
    id: 'component.ClinicianRegistration.telehealthLabel',
    defaultMessage: 'Do you provide Telehealth services?',
  },
  groupNameLabel: {
    id: 'component.ClinicianRegistration.groupNameLabel',
    defaultMessage:
      ' If you work with a group or groups, please provide the name of each',
  },
  clinicianEmail: {
    id: 'component.ClinicianRegistration.clinicianEmail',
    defaultMessage:
      '  Please enter the email address(es) for any clinician from your group(s) who performs Intake sessions for you.',
  },
  cityLabel: {
    id: 'component.ClinicianRegistration.cityLabel',
    defaultMessage: 'In what City are you located? ',
  },
  stateLabel: {
    id: 'component.ClinicianRegistration.stateLabel',
    defaultMessage: 'In what State are you located? ',
  },
  clinicNameLabel: {
    id: 'component.ClinicianRegistration.ClinicNameLabel',
    defaultMessage: 'What is your clinic’s name?',
  },
  thanksMessage: {
    id: 'component.ClinicianRegistration.thanksMessage',
    defaultMessage:
      'Thank you! Please watch for an email from Eve providing access to your account and next steps!',
  },
  contactDetails: {
    id: 'component.ClinicianRegistration.contactDetails',
    emailLabel: {
      id: 'component.ClinicianRegistration.contactDetails.emailLabel',
      defaultMessage: 'Email Address',
    },
    phoneLabel: {
      id: 'component.ClinicianRegistration.contactDetails.phoneLabel',
      defaultMessage: 'Phone number (This will NOT be shared with clients)',
    },
    contactEmailLabel: {
      id: 'component.ClinicianRegistration.contactDetails.contactEmailLabel',
      defaultMessage:
        'What is the best email for clients to use if they want to send you a message?',
    },
    firstNameLabel: {
      id: 'component.ClinicianRegistration.contactDetails.firstNameLabel',
      defaultMessage: 'First Name',
    },
    LastNameLabel: {
      id: 'component.ClinicianRegistration.contactDetails.LastNameLabel',
      defaultMessage: 'Last Name',
    },
    bioPlaceholder: {
      id: 'component.ClinicianRegistration.contactDetails.bioPlaceholder',
      description: 'The label for doctor bio field placeholder',
      defaultMessage:
        'Please provide a short description of your professional background, practice and services.',
    },
  },
  joinClinicTooltip: {
    id: 'component.ClinicianRegistration.JoinClinicTooltip',
    defaultMessage: 'This will create your Eve account as a member of the',
  },
  createClinicTooltip: {
    id: 'component.ClinicianRegistration.CreateClinicTooltip',
    defaultMessage: 'You have your own clinic or group practice',
  },
  clinicianRoleTooltip: {
    id: 'component.ClinicianRegistration.ClinicianRoleTooltip',
    defaultMessage:
      'You will be providing clinical and potentially admin services',
  },
  adminRoleTooltip: {
    id: 'component.ClinicianRegistration.AdminRoleTooltip',
    defaultMessage: 'You will be providing admin services only',
  },
  uploadPhotoTooltip: {
    id: 'component.ClinicianRegistration.UploadPhotoTooltip',
    defaultMessage:
      'This is the photo we will show to clients on your profile.',
  },
});
