import moment from 'moment';

export const isObjectEmpty = (obj) =>
  Object.entries(obj).length === 0 && obj.constructor === Object;

export const checkWeeklyAssesment = (data) => {
  let isWeeklyAssesment = true;
  if (data.patientWeeklyAssesment) {
    const { updatedAt, status } = data.patientWeeklyAssesment; // TODO updatedAt does not exist in the db
    const startDate = moment(updatedAt);
    const endDate = moment();
    const daysDifference = endDate.diff(startDate, 'days');

    if (daysDifference <= 7 && status === 'complete') {
      isWeeklyAssesment = false;
    }
  } else {
    const endDate = moment();
    const registerDaysDifference = endDate.diff(moment(data.createdAt), 'days');

    if (registerDaysDifference <= 7) {
      isWeeklyAssesment = false;
    }
  }
  return isWeeklyAssesment;
};

export const changeFaviconTitle = (link, title) => {
  let $favicon = document.querySelector('link[rel="shortcut icon"]');
  // If a <link rel="icon"> element already exists,
  // change its href to the given link.
  if ($favicon !== null) {
    $favicon.href = link;
    document.title = title;
    // Otherwise, create a new element and append it to <head>.
  } else {
    $favicon = document.createElement('link');
    $favicon.rel = 'icon';
    $favicon.href = link;
    document.head.appendChild($favicon);
    document.title = title;
  }
};

export const prepareAssessmentValues = (fields, assessmentValues) => {
  const assessments = [];
  let assessmentValue;
  fields.map((field) => {
    if (field.key && assessmentValues[field.key]) {
      if (
        assessmentValues[field.key].value ||
        assessmentValues[field.key].options ||
        field.type === 'slider'
      ) {
        if (assessmentValues[field.key].options) {
          assessments.push({
            key: [field.title],
            value: assessmentValues[field.key].options
              .map((option) => option.title)
              .toString(),
          });
        } else if (field.type === 'slider') {
          assessmentValue = field.marks.find(
            (mark) =>
              mark.value === assessmentValues[field.key].value ||
              mark.value === assessmentValues[field.key],
          ).label;
          assessments.push({ key: [field.title], value: assessmentValue });
        } else if (field.type === 'swipe-card') {
          assessments.push({
            key: [`${field.title.replace(/\.+$/, '')} ${field.subtitle}`],
            value: assessmentValues[field.key].value,
          });
        } else {
          assessments.push({
            key: [field.title],
            value: assessmentValues[field.key].value,
          });
        }
      }
    }
  });
  return assessments;
};
export const compareFun = (a, b, order) => {
  let comparison = 0;
  if (a < b) comparison = -1;
  if (b < a) comparison = 1;
  return order === 'Desc' ? comparison * -1 : comparison;
};

export const WHITE_LABEL_DATA = [
  {
    clinicName: 'healthyyoungminds',
    favicon: 'cgnCy-Favicon.png',
    pageTitle: 'Healthy Young Minds',
    'contact-us': 'hym-contact-us',
    marketingCopyKey: 'ymhc-marketing-copy',
    'privacy-policy': 'ymhc-privacy-policy',
    'terms-conditions': 'ymhc-terms-conditions',
  },
  {
    clinicName: 'wellbeings',
    favicon: 'wb-favicon.ico',
    pageTitle: 'Well Beings',
    'contact-us': 'wb-contact-us',
    marketingCopyKey: 'wb-marketing-copy',
    'privacy-policy': 'wb-privacy-policy',
    'terms-conditions': 'wb-terms-conditions',
  },
  {
    clinicName: 'psychedelicsupport',
    favicon: 'ps-favicon.ico',
    pageTitle: 'Psychedelic Support',
    'contact-us': 'ps-contact-us',
    marketingCopyKey: 'ps-marketing-copy',
    'privacy-policy': 'ps-privacy-policy',
    'terms-conditions': 'ps-terms-conditions',
  },
  {
    clinicName: 'medicinalmindfulness',
    favicon: 'mm-favicon.ico',
    pageTitle: 'Medicinal Mindfulness',
    'contact-us': 'mm-contact-us',
    marketingCopyKey: 'mm-marketing-copy',
    'privacy-policy': 'mm-privacy-policy',
    'terms-conditions': 'mm-terms-conditions',
  },
  {
    clinicName: 'advancedwellness',
    favicon: 'aw-favicon.ico',
    pageTitle: 'Advanced Wellness',
    'contact-us': 'aw-contact-us',
    marketingCopyKey: 'aw-marketing-copy',
    'privacy-policy': 'aw-privacy-policy',
    'terms-conditions': 'aw-terms-conditions',
  },
  {
    clinicName: 'reichbach',
    favicon: 'rb-favicon.ico',
    pageTitle: 'Reichbach',
    'contact-us': 'rb-contact-us',
    marketingCopyKey: 'rb-marketing-copy',
    'privacy-policy': 'rb-privacy-policy',
    'terms-conditions': 'rb-terms-conditions',
  },
];

export const addToDataLayer = (eventData) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(eventData);
  }
};

export const getDescription = (description) => {
  const desc = description ? description.replace(/\r?\n/g, '<br />') : '';
  return desc;
};

export const displayDateFormat = (date) => moment(date).format('MM/DD/YYYY');

export const groupBy = (items, key) => {
  if (items?.length) {
    return items.reduce((result, item) => {
      const val = key.split('.').reduce((p, c) => p?.[c], item);
      if (val) {
        return {
          ...result,
          [val]: [...(result[val] || []), item],
        };
      }
    }, {});
  } else {
    return {};
  }
};

export const parseSuperBillRequests = (data, idFilter, sortField, filters) => {
  if (data?.length) {
    let groupedRequests = groupBy(data, idFilter) || [];
    let lastRequestedSuperBills = [];

    Object.keys(groupedRequests).forEach((id) => {
      if (sortField) {
        groupedRequests[id].sort(function (a, b) {
          return new Date(b[sortField]) - new Date(a[sortField]);
        });
      }
      groupedRequests[id] = groupedRequests[id].filter((item) => {
        let result;
        filters.forEach((filter) => {
          const { key, value } = filter;
          const comparisonValue =
            typeof value === 'boolean' ? !!item[key] : item[key];
          if (result === false) {
            return;
          }
          result = comparisonValue === value;
        });

        return result;
      });
      if (groupedRequests[id].length) {
        lastRequestedSuperBills.push(groupedRequests[id][0]);
      }
    });
    return lastRequestedSuperBills;
  }
  return [];
};

export const getCroppedImg = (image, crop, fileName) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error('Canvas is empty'));
        console.error('Canvas is empty');
        return;
      }
      blob.name = fileName;
      resolve(blob);
    }, 'image/jpeg');
  });
};

export const onKeyPress = (event) => {
  if (event.which === 13) {
    event.preventDefault();
  }
  return;
};

export function triggerDownloadLink(link, targetBlank = false) {
  if (link) {
    // for old browsers
    const a = document.createElement('A');

    a.href = link;
    a.download = 'true';

    if (targetBlank) {
      a.target = '_blank';
      a.rel = 'noopener noreferrer';
    }
    a.style = {
      position: 'absolute',
      visibility: 'hidden',
      opacity: '0',
    };
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

export function getQS(name) {
  if (window.URLSearchParams) {
    return new window.URLSearchParams(window.location.href.split('?')[1]).get(
      name,
    );
  }
  let result = null;
  window.location.search
    .substr(1)
    .split('&')
    .forEach((item) => {
      const tmp = item.split('=');
      if (tmp[0] === name) {
        result = decodeURIComponent(tmp[1]);
      }
    });
  return result;
}
