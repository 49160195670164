import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';
import { AssessmentPDFStyles } from '../AssessmentPDFStyles';

const Table = ({ heads, rows }) => {
  const Head = () => (
    <View style={AssessmentPDFStyles.tableRow}>
      {heads.map((h, i) => (
        <View key={i} style={AssessmentPDFStyles.tableDataRow}>
          <Text style={AssessmentPDFStyles.content_detail.span}>{h}</Text>
        </View>
      ))}
    </View>
  );

  const Row = ({ values }) => (
    <View style={AssessmentPDFStyles.tableRow}>
      {values.map((v, i) => (
        <View key={i} style={AssessmentPDFStyles.tableDataRow}>
          <Text>{v}</Text>
        </View>
      ))}
    </View>
  );

  return (
    <>
      <Head />
      {rows.map((row, i) => (
        <Row values={row} key={i} />
      ))}
    </>
  );
};

Table.propTypes = {
  heads: PropTypes.array,
  rows: PropTypes.array,
};

export default Table;
