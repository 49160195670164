import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ExperienceOneImg from '../../../../img/icons/Experience/no-experience.svg';
import ExperienceTwoImg from '../../../../img/icons/Experience/some-experience.svg';
import ExperienceThreeImg from '../../../../img/icons/Experience/deep-experience.svg';
import FamiliarOneImg from '../../../../img/icons/Familiar/Familiar-175x175-01.png';
import FamiliarTwoImg from '../../../../img/icons/Familiar/Familiar-175x175-02.png';
import FamiliarThreeImg from '../../../../img/icons/Familiar/Familiar-175x175-03.png';
import MaleImg from '../../../../img/icons/areyou/male.svg';
import FemaleImg from '../../../../img/icons/areyou/female.svg';
import NonBinaryImg from '../../../../img/icons/areyou/non-binary.svg';
import styles from './CircleButtonBox.module.scss';

const selectIcon = (icon) => {
  switch (icon) {
    case 'no-experience':
      return ExperienceOneImg;
    case 'some-experience':
      return ExperienceTwoImg;
    case 'deep-experience':
      return ExperienceThreeImg;
    case 'pretty-informed':
      return FamiliarOneImg;
    case 'sort-of-curious':
      return FamiliarTwoImg;
    case 'somewhat-skeptical':
      return FamiliarThreeImg;
    case 'male':
      return MaleImg;
    case 'female':
      return FemaleImg;
    case 'non-binary':
      return NonBinaryImg;
    default:
      return false;
  }
};

const CircleButtonBox = ({
  title,
  onClick,
  isSelected,
  icon,
  'data-testid': datatestid,
}) => (
  <div
    onClick={onClick}
    className={classnames(styles['box-container'], {
      [styles['is-selected']]: isSelected,
    })}
    data-testid={datatestid}
  >
    <div className={classnames(styles['div-img'])}>
      {selectIcon(icon) && <img src={selectIcon(icon)} alt={icon} />}
    </div>
    <div className={classnames(styles['circle'])}>
      <div
        className={classnames(styles['circle-small'], {
          [styles['is-selected']]: isSelected,
        })}
      />
    </div>
    <div className={styles['text-container']}>
      <div className={styles['button-title']}>{title}</div>
    </div>
  </div>
);

CircleButtonBox.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  isSelected: PropTypes.bool,
  icon: PropTypes.string.isRequired,
};

CircleButtonBox.defaultProps = {
  isSelected: false,
};

export default CircleButtonBox;
