import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Field, Formik, Form } from 'formik';
import classnames from 'classnames';
import { Cell, Grid } from 'react-foundation';
import { Helmet } from 'react-helmet';
import CircleButton from '../../components/common/buttons/CircleButton';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import Slider from '../../components/Slider';
import TextInput from '../../components/common/inputs/TextInput';
import RoundCheckBox from '../../components/common/inputs/RoundCheckBox';
import Section from '../../components/common/Section';
import back from '../../img/icons/back.svg';
import { saveRecommendationAnswers } from '../../api/recommendations';
import { emailValidator, ageValidator } from '../../utils/validators';
import styles from './DiscoverPsychPage.module.scss';
import CircleButtonSmall from '../../components/common/buttons/CircleButtonSmall';
import useIntl from '../../hooks/useIntl';
import useScreen from '../../hooks/useScreen';
import messages from './messages';
import AuthContext from '../../context/AuthContext';
import BenefitIcon from '../../components/BenefitIcon';
import ProductTypeIcon from '../../components/ProductTypeIcon';
import RoundCheckBoxSmall from '../../components/common/inputs/RoundCheckBoxSmall';
import { getEntries } from '../../api/contentful';
import SwipeCard from '../../components/SwipeCard';

const Icon = ({ type, ...rest }) =>
  type === 'benefits' ? (
    <BenefitIcon {...rest} />
  ) : (
    <ProductTypeIcon {...rest} />
  );

const validate = (value, key, validation, field) => {
  if (!value.trim()) return 'A value is required';
  switch (validation) {
    case 'email':
      const { email } = emailValidator(value);
      return email;
    case 'age':
      const { age } = ageValidator(value);
      return age;
    default:
      return null;
  }
};

const formatTitle = ({ title, param }, answers) =>
  !param ? title : title.replace('*', answers[param].value);

const renderInput = (
  item,
  answers,
  setAnswers,
  page,
  setPage,
  initialValues,
  formatMessage,
) => {
  const { type, key, validation, marks } = item;
  switch (type) {
    case 'text':
      return (
        <div>
          <Field
            name={key}
            component={TextInput}
            submitButton={true}
            onChange={(e) =>
              setAnswers({
                ...answers,
                [key]: { value: e.currentTarget.value },
              })
            }
            value={answers[key].value}
            autoFocus={true}
            className={styles.input}
            validate={() => validate(answers[key].value, key, validation)}
            data-testid="input-test"
          />
        </div>
      );
    case 'multiple-choice':
      return (
        <Grid className={styles['grid-center']}>
          {item.choices.map((choice) => (
            <Cell small={4} medium={2} key={choice.key}>
              <Field
                label={choice.title}
                type={key}
                id={choice.key}
                isActive={
                  answers[key].options &&
                  !!answers[key].options.find(
                    (option) => option.key === choice.key,
                  )
                }
                component={RoundCheckBox}
                theme="gray"
                icon={<Icon size="100px" icon={choice.key} type={key} />}
                onClick={() => {
                  const optionExists =
                    answers[key].options &&
                    answers[key].options.find(
                      (option) => option.key === choice.key,
                    );
                  return optionExists
                    ? setAnswers({
                        ...answers,
                        [key]: {
                          options: answers[key].options.filter(
                            (option) => option.key !== choice.key,
                          ),
                        },
                      })
                    : setAnswers({
                        ...answers,
                        [key]: {
                          options: [...(answers[key].options || []), choice],
                        },
                      });
                }}
              />
            </Cell>
          ))}
          <Cell small={12}>
            <PrimaryButton
              disabled={
                !answers[key].options || answers[key].options.length === 0
              }
              label={formatMessage(messages.continueBtn)}
            />
          </Cell>
        </Grid>
      );
    case 'swipe-card':
      const swipeCardChoice = ({ onClick }) => (
        <SwipeCard
          title={currentPage.title}
          subtitle={currentPage.subtitle}
          description={currentPage.description}
          imageURL={currentPage.imageURL}
          onSwipe={onClick}
        />
      );
      return (
        <Field
          key={currentPage.key}
          component={swipeCardChoice}
          name={key}
          data-testid={`single-choice-${currentPage.key}`}
          onClick={(isRight) => {
            setAnswers({
              ...answers,
              [key]: {
                value: isRight ? 'yes' : 'no',
                key: currentPage.key,
                to: page + 1,
              },
            });
            setPage(answers[currentPage.key].to || page + 1);
          }}
        />
      );
    case 'single-choice':
      return (
        <Grid>
          {item.choices.map((choice) => (
            <Field
              key={choice.key}
              component={CircleButton}
              name={key}
              title={choice.title}
              subtitle={choice.subtitle}
              isSelected={answers[key].key === choice.key}
              icon={choice.key}
              onClick={() =>
                setAnswers({
                  ...answers,
                  [key]: {
                    value: choice.title,
                    key: choice.key,
                    to: choice['jump-to-position'],
                  },
                })
              }
              data-testid={`single-choice-${choice.key}`}
            />
          ))}
        </Grid>
      );
    case 'single-choice-small':
      return (
        <Grid style={{ justifyContent: 'center' }}>
          {item.choices.map((choice) => (
            <Cell
              medium={4}
              small={item.cols ? 12 / item.cols : 12}
              key={choice.key}
              className={styles['actual-padding']}
            >
              <Field
                key={choice.key}
                component={CircleButtonSmall}
                name={key}
                title={choice.title}
                cols={item.cols}
                isSelected={answers[key].key === choice.key}
                onClick={() => {
                  setAnswers({
                    ...answers,
                    [key]: {
                      value: choice.title,
                      key: choice.key,
                      to: choice['jump-to-position'],
                    },
                  });
                }}
                className={styles['actual-height']}
              />
            </Cell>
          ))}
        </Grid>
      );
    case 'multi-choice-small':
      return (
        <Grid className={styles['grid-center']}>
          {item.choices.map((choice) => (
            <Cell
              small={6}
              medium={4}
              key={choice.key}
              className={styles['multi-choice-small']}
            >
              <Field
                label={choice.title}
                type={key}
                id={choice.key}
                isActive={
                  answers[key].options &&
                  !!answers[key].options.find(
                    (option) => option.key === choice.key,
                  )
                }
                component={RoundCheckBoxSmall}
                theme="gray"
                icon={<Icon size="100px" icon={choice.key} type={key} />}
                onClick={() => {
                  const optionExists =
                    answers[key].options &&
                    answers[key].options.find(
                      (option) => option.key === choice.key,
                    );
                  return optionExists
                    ? setAnswers({
                        ...answers,
                        [key]: {
                          options: answers[key].options.filter(
                            (option) => option.key !== choice.key,
                          ),
                          to: answers[key].options.map((option) => {
                            if (option['jump-to-position'])
                              return option['jump-to-position'];
                            return undefined;
                          })[0],
                        },
                      })
                    : setAnswers({
                        ...answers,
                        [key]: {
                          options: [...(answers[key].options || []), choice],
                          to: choice['jump-to-position']
                            ? choice['jump-to-position']
                            : page + 1,
                        },
                      });
                }}
              />
            </Cell>
          ))}
          <Cell small={12}>
            <PrimaryButton
              disabled={
                !answers[key].options || answers[key].options.length === 0
              }
              label={formatMessage(messages.continueBtn)}
            />
          </Cell>
        </Grid>
      );
    case 'slider':
      const defaultValue = 3;
      return (
        <Grid flexContainer>
          <Cell
            small={12}
            alignX="center"
            className={styles['slider-container']}
          >
            <Field
              component={Slider}
              name={key}
              value={answers[key] || defaultValue}
              defaultValue={defaultValue}
              onChange={(event, value) =>
                setAnswers({ ...answers, [key]: value })
              }
              isModified={answers[key] !== initialValues[key]}
              type={marks}
            />
          </Cell>
          <Cell small={12}>
            <PrimaryButton
              onClick={() => {
                !isNaN(Number(answers[key])) ||
                  setAnswers({ ...answers, [key]: defaultValue });
              }}
              label={formatMessage(messages.continueBtn)}
            />
          </Cell>
        </Grid>
      );
    case 'transition':
      setTimeout(async () => {
        if (item['is-last-step']) {
          const { id } = await saveRecommendationAnswers(answers);
          localStorage.setItem('recommendations-answers-id', id);
        }
        const newPage = item['jump-to-position'];
        return newPage ? setPage(newPage) : setPage(page + 1);
      }, 2500);
      return null;
    default:
      return null;
  }
};

const handleBackPage = (page, results, setPage, setDirection) => {
  let previousPage = page - 1;
  let evaluatedPage = results[previousPage];
  while (previousPage > 0 && evaluatedPage.type === 'transition') {
    previousPage--;
    evaluatedPage = results[previousPage];
  }
  setDirection(-1);
  return setPage(previousPage);
};

const mapResults = (items, state, formatMessage) => {
  if (state && state.firstName) {
    return items.reduce((acc, item) => {
      if (item.position === 0) {
        return [
          ...acc,
          {
            position: 0,
            type: 'transition',
            title: (
              <FormattedMessage
                {...messages.welcomeBackText}
                values={{ name: state.firstName }}
              />
            ),
            'jump-to-position': 4,
          },
        ];
      }
      return [...acc, item];
    }, []);
  }
  return items;
};

let currentPage;
let answersObjectId;

const DiscoverPage = ({ location }) => {
  answersObjectId = localStorage.getItem('recommendations-answers-id');
  const { authUser } = useContext(AuthContext);
  const [answers, setAnswers] = useState({});
  const [page, setPage] = useState(0);
  const [random, setRandom] = useState(1);
  const [direction, setDirection] = useState(1);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { formatMessage } = useIntl();
  const { isMobile } = useScreen();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    const fetchQuesionnaire = async () => {
      try {
        const questionnaire = await getEntries({
          'fields.key': 'discover-psych-questionnaire',
          content_type: 'recommendationEngineQuestionnaire',
        });
        if (
          questionnaire.items.length > 0 &&
          questionnaire.items[0].fields['questions']
        ) {
          setQuestionnaire(questionnaire.items[0].fields['questions']);
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
      }
    };
    fetchQuesionnaire();
  }, []);

  useEffect(() => {
    if (questionnaire.length && Object.entries(answers).length === 0) {
      const initialAnswers = questionnaire.reduce(
        (acc, { key, parent }) =>
          key ? { ...acc, [key]: parent ? undefined : {} } : acc,
        {},
      );
      setAnswers({
        ...initialAnswers,
        name:
          authUser.firstName || (location.state && location.state.firstName)
            ? { value: authUser.firstName || location.state.firstName }
            : {},
      });
    }
  }, [answers, authUser.firstName, location.state, questionnaire]);
  if ((!isLoading && page >= questionnaire.length) || answersObjectId)
    return <Redirect to="/recommended-products" />;

  currentPage = mapResults(questionnaire, location.state, formatMessage)[page];
  console.log('currentPage', currentPage);
  if (currentPage && currentPage.parent) {
    const parentOptions = answers[currentPage.parent].options;
    if (!parentOptions.find((option) => option.key === currentPage.key)) {
      setAnswers({ ...answers, [currentPage.key]: undefined });
      setPage(page + direction);
    }
  }
  console.log('answers', answers);
  return (
    <Section>
      <Helmet>
        <meta
          name="description"
          content={formatMessage(messages.metaDescription)}
        />
      </Helmet>
      <div
        className={classnames(styles.container, styles[`background-${random}`])}
      >
        <div className={classnames('desktop-only', styles.logo)}></div>
        {!isLoading && (
          <Formik
            onSubmit={() => {
              setDirection(1);
              setPage(answers[currentPage.key].to || page + 1);
              setRandom(Math.floor(Math.random() * 5) + 1);
            }}
          >
            {({ errors, initialValues }) => (
              <Form>
                {currentPage.image && (
                  <img
                    src={
                      isMobile
                        ? require(`../../img/icons/discover/${currentPage.image}-sm.gif`)
                        : require(`../../img/icons/discover/${currentPage.image}.gif`)
                    }
                    alt={currentPage.title}
                  />
                )}
                {page === 0 ? (
                  <h2>{currentPage.title}</h2>
                ) : (
                  <h3>{formatTitle(currentPage, answers)}</h3>
                )}
                <p
                  className={
                    currentPage.type === 'transition'
                      ? styles['transition-subtitle']
                      : ''
                  }
                >
                  {currentPage.subtitle}
                </p>
                {renderInput(
                  currentPage,
                  answers,
                  setAnswers,
                  page,
                  setPage,
                  initialValues,
                  formatMessage,
                )}
                {errors[currentPage.key] && (
                  <div>{errors[currentPage.key]}</div>
                )}
                {currentPage.type !== 'transition' && (
                  <div
                    className={styles['back-link']}
                    onClick={() =>
                      handleBackPage(page, questionnaire, setPage, setDirection)
                    }
                  >
                    <img
                      src={back}
                      data-testid="back-button"
                      alt={formatMessage(messages.backLabel)}
                    />
                    <span className="desktop-only">
                      {formatMessage(messages.backLabel)}
                    </span>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        )}
      </div>
    </Section>
  );
};

export default DiscoverPage;
