import React, { useState, Fragment, useContext, useEffect } from 'react';
import styles from './ManageFormsPage.module.scss';
import classnames from 'classnames';
import Loading from '../../../components/common/Loading';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import AdminLeftNav from '../../../components/nav/AdminLeftNav';
import AuthContext from '../../../context/AuthContext';
import { Formik, Form } from 'formik';
import { Cell, Grid } from 'react-foundation';
import * as Yup from 'yup';
import MessageModal from '../../../components/MessageModal';
import FieldDropdown from '../../../components/common/fields/FieldDropdown';
import { getFormLists, assignFormAssociation } from '../../../api/intakeForm';
import { getClinics, getClinicians } from '../../../api/doctor';

const ManageFormSchema = Yup.object().shape({
  dim_form_id: Yup.number().required('Field Required'),
  clinic_id: Yup.number().required('Field Required'),
  doctor_id: Yup.number().when('clinic_id', {
    is: (value) => value === 0,
    then: Yup.number().required('Field is required'),
  }),
});

const ManageFormsPage = () => {
  const { isLoading, authUser } = useContext(AuthContext);
  const [dataLoading, setLoading] = useState(false);
  const isDataLoading = dataLoading || isLoading;
  const [initialValues] = useState({
    dim_form_id: '',
    clinic_id: '',
    doctor_id: '',
  });
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [displayMessage, setDisplayMessae] = useState({
    title: '',
    message: '',
  });
  const [formattedFormLists, setFormattedFormLists] = useState([]);
  const [formattedClinicsLists, setFormattedClinicsLists] = useState([]);
  const [formattedCliniciansLists, setFormattedCliniciansLists] = useState([]);

  const handleOnSubmit = async (values) => {
    setLoading(true);
    try {
      const params = {
        dim_form_id: values.dim_form_id || 0,
        clinic_id: values.clinic_id || 0,
        doctor_id: values.doctor_id || 0,
      };
      await assignFormAssociation(params);
      setLoading(false);
      setDisplayMessae({
        type: 'success',
        title: 'Form associated successfully',
        text: '',
      });
      setShowMessageModal(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setDisplayMessae({
        type: 'error',
        title: '',
        text: error,
      });
      setShowMessageModal(true);
    }
  };

  const fetchFormLists = async () => {
    try {
      setLoading(true);
      const lists = await getFormLists();
      // setFormLists(lists);
      const formattedLists = lists
        .map((list) => {
          return {
            ...list,
            label: list.form_name,
            value: list.id,
          };
        })
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

      setFormattedFormLists(formattedLists);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchClinicsLists = async () => {
    try {
      const { clinics } = await getClinics();
      const formattedLists = clinics
        .map((list) => {
          return {
            ...list,
            label: list.clinic_name,
            value: list.id,
          };
        })
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

      formattedLists.unshift({
        label: 'No Clinic',
        value: 0,
      });

      setFormattedClinicsLists(formattedLists);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchClinicinssLists = async () => {
    try {
      const { allDoctors } = await getClinicians(0);

      const formattedLists = allDoctors
        .map((list) => {
          return {
            ...list,
            label: `${list.first_name} ${list.last_name}`,
            value: list.id,
          };
        })
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

      setFormattedCliniciansLists(formattedLists);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authUser.id) {
      fetchFormLists();
      fetchClinicsLists();
      fetchClinicinssLists();
    }
  }, [authUser]);

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <AdminLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div className={styles.header}>
              <h1>Manage Forms</h1>
            </div>
            <div className={styles.formData}>
              <Formik
                initialValues={{ ...initialValues }}
                validationSchema={ManageFormSchema}
                onSubmit={handleOnSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => {
                  return (
                    <Form>
                      <Grid>
                        <Cell small={12}>
                          <FieldDropdown
                            label="Form"
                            name="dim_form_id"
                            errorText={errors.dim_form_id}
                            value={values.dim_form_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={formattedFormLists}
                            placeholder="Select Form"
                            dataTestId="ManageForm.DimForm.Dropdown"
                          />
                        </Cell>
                        <Cell small={12}>
                          <FieldDropdown
                            label="clinic"
                            name="clinic_id"
                            errorText={errors.clinic_id}
                            value={values.clinic_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={formattedClinicsLists}
                            placeholder="Select Clinic"
                            dataTestId="ManageForm.Clinic.Dropdown"
                          />
                        </Cell>
                        {values.clinic_id === 0 && (
                          <Cell small={12}>
                            <FieldDropdown
                              label="Clinicians"
                              name="doctor_id"
                              errorText={errors.doctor_id}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={formattedCliniciansLists}
                              placeholder="Select Clinicians"
                              dataTestId="ManageForm.Clinicians.Dropdown"
                            />
                          </Cell>
                        )}
                        <Cell small={12}>
                          <PrimaryButton
                            label="Save"
                            className={classnames(styles.saveButton, 'font-18')}
                            disabled={Object.entries(errors).length > 0}
                          />
                        </Cell>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </Fragment>
      )}
      {showMessageModal && (
        <MessageModal
          message={displayMessage}
          onCloseModal={() => setShowMessageModal(false)}
        />
      )}
    </div>
  );
};

export default ManageFormsPage;
