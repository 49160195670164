import React from 'react';
import PropTypes from 'prop-types';
import 'react-step-progress-bar/styles.css';
import { ProgressBar } from 'react-step-progress-bar';

const Progress = ({ percent, width, height }) => {
  return (
    <ProgressBar
      percent={percent}
      width={width}
      height={height}
      filledBackground="linear-gradient(to right, #EA84D2, #3BC9E1)"
    />
  );
};

Progress.propTypes = {
  percent: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};
Progress.defaultProps = {
  percent: 0,
  width: 100,
  height: 5,
};

export default Progress;
