import classnames from 'classnames';
import { Form, Formik } from 'formik';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Cell, Grid } from 'react-foundation';
import * as Yup from 'yup';

import { getClinics } from '../../../api/doctor';
import { updateClinic } from '../../../api/doctor';

import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import FieldInput from '../../../components/common/fields/FieldInput/FieldInput';
import Label from '../../../components/common/Label';
import Loading from '../../../components/common/Loading';
import Modal from '../../../components/common/Modal';
import MessageModal from '../../../components/MessageModal';
import AdminLeftNav from '../../../components/nav/AdminLeftNav';
import SwitchToggle from '../../../components/SwitchToggle/SwitchToggle';
import AuthContext from '../../../context/AuthContext';

import styles from './ManageClinics.module.scss';

const ClinicSchema = Yup.object().shape({
  clinic_name: Yup.string().required('Field Required').nullable(),
  sub_domain: Yup.string().nullable(),
});

const ManageClinics = () => {
  const { isLoading } = useContext(AuthContext);
  const [, setLoading] = useState(false);
  const [file, setFile] = useState('');

  const [clinics, setClinics] = useState();
  const [showClinicModal, setShowClinicModal] = useState(false);
  const [initialValues, setInitialValues] = useState({
    clinic_name: '',
    sub_domain: '',
    assessment_key: '',
    marketing_banner_key: '',
    internal_matching: true,
  });
  const [logoError, setLogoError] = useState('');
  const [logo, setLogo] = useState('');
  const [footerLogoError, setFooterLogoError] = useState('');
  const [footerFile, setFooterFile] = useState('');
  const [footerLogo, setFooterLogo] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [displayMessage, setDisplayMessage] = useState({
    title: '',
    message: '',
  });
  const [clinic, setClinic] = useState();

  const onFooterLogoDrop = async (files, fileRejections) => {
    const reader = new FileReader();
    reader.onload = async () => {
      setFooterLogo(reader.result);
      setFooterLogoError('');
    };
    if (files.length) {
      reader.readAsDataURL(files[0]);
      setFooterFile(files[0]);
    } else {
      fileRejections.forEach((f) => {
        f.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            setFooterLogoError('File is too large');
          }
          if (err.code === 'file-invalid-type') {
            setFooterLogoError('File type not accepted.');
          }
        });
      });
    }
  };
  const onDrop = async (files, fileRejections) => {
    const reader = new FileReader();
    reader.onload = async () => {
      setLogo(reader.result);
      setLogoError('');
    };
    if (files.length) {
      reader.readAsDataURL(files[0]);
      setFile(files[0]);
    } else {
      fileRejections.forEach((f) => {
        f.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            setLogoError('File is too large');
          }
          if (err.code === 'file-invalid-type') {
            setLogoError('File type not accepted.');
          }
        });
      });
    }
  };

  const fetchClinics = async () => {
    try {
      setLoading(true);
      const { clinics } = await getClinics();
      setClinics(clinics);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchClinics();
  }, []);

  const handleOnSubmit = async (values) => {
    if (values.white_labelled_clinic === true && !logo) {
      setLogoError('Please upload a valid logo.');
    } else if (values.white_labelled_clinic === true && !footerLogo) {
      setFooterLogoError('Please upload a valid logo.');
    } else {
      setLoading(true);
      try {
        // const data = new FormData();
        // if (file) {
        //   data.append('files', file);
        // }
        // if (footerFile) {
        //   data.append('files', footerFile);
        // }
        // for (const [key, value] of Object.entries(values)) {
        //   value !== 'null' && value !== null && data.append(key, value ?? null);
        // }

        await updateClinic(clinic.id, values);

        setInitialValues({
          clinic_name: '',
          sub_domain: '',
          assessment_key: '',
          marketing_banner_key: '',
          internal_matching: true,
        });
        setLogo('');
        setFile('');
        setFooterLogo('');
        setFooterFile('');
        setLoading(false);
        setShowClinicModal(false);
        fetchClinics();
        setDisplayMessage({
          type: 'success',
          title: 'Clinic updated successfully',
          text: '',
        });
        setShowMessageModal(true);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setDisplayMessage({
          type: 'success',
          title: '',
          text: error,
        });
        setShowMessageModal(true);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <AdminLeftNav />
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div className={styles.header}>
              <h1>Manage Clinics</h1>
            </div>
            <div className={styles.clinics}>
              {clinics && clinics.length > 0 && (
                <div className={styles.header}>
                  <h6>Clinic Name</h6>
                  <h6>Assessment Key</h6>
                </div>
              )}
              {clinics &&
                clinics.map((clinic) => (
                  <div className={styles.clinicList}>
                    <div className={styles.clinicDetails}>
                      <p>{clinic.clinic_name}</p>
                      <p>{clinic.assessment_key}</p>
                    </div>
                    <div>
                      <PrimaryButton
                        label="Edit"
                        onClick={() => {
                          setInitialValues({
                            clinic_name: clinic.clinic_name,
                            sub_domain: clinic.sub_domain,
                            assessment_key: clinic.assessment_key,
                            marketing_banner_key: clinic.marketing_banner_key,
                            white_labelled_clinic: clinic.white_labelled_clinic,
                            internal_matching: clinic.internal_matching,
                          });
                          setClinic(clinic);
                          setLogo(clinic.logo_url);
                          setFooterLogo(clinic.footer_logo_url);
                          setShowClinicModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Fragment>
      )}
      {showClinicModal && (
        <Modal className={styles.updateClinicModal}>
          <div className={styles.content}>
            <div
              className={styles.closeButton}
              onClick={() => {
                setShowClinicModal(false);
              }}
            >
              <img src={require(`../../../img/close.svg`)} alt={`Close`} />
            </div>
            <div className={styles.formData}>
              <Formik
                initialValues={{ ...initialValues }}
                validationSchema={ClinicSchema}
                onSubmit={handleOnSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <Form>
                      <Grid>
                        <Cell small={12}>
                          <FieldInput
                            autoComplete="clinic_name"
                            autoFocus={true}
                            label="Clinic Name"
                            errorText={
                              touched.clinic_name && errors.clinic_name
                            }
                            name="clinic_name"
                            value={values.clinic_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            data-testid="clinic_name"
                          />
                        </Cell>
                        {values.white_labelled_clinic === true && (
                          <Fragment>
                            <Cell small={12}>
                              <FieldInput
                                autoComplete="sub_domain"
                                label="Sub Domain"
                                errorText={
                                  touched.sub_domain && errors.sub_domain
                                }
                                name="sub_domain"
                                onChange={handleChange}
                                type="text"
                                onBlur={handleBlur}
                                value={values.sub_domain}
                                data-testid="sub_domain"
                                disabled={true}
                              />
                            </Cell>
                            <Cell small={12}>
                              <FieldInput
                                autoComplete="assessment_key"
                                label="Assessment Key"
                                errorText={
                                  touched.assessment_key &&
                                  errors.assessment_key
                                }
                                name="assessment_key"
                                onChange={handleChange}
                                type="text"
                                onBlur={handleBlur}
                                value={values.assessment_key}
                                data-testid="assessment_keyv"
                              />
                            </Cell>
                            <Cell small={12}>
                              <FieldInput
                                autoComplete="marketing_banner_key"
                                label="Marketing Banner Key"
                                errorText={
                                  touched.marketing_banner_key &&
                                  errors.marketing_banner_key
                                }
                                name="marketing_banner_key"
                                onChange={handleChange}
                                type="text"
                                onBlur={handleBlur}
                                value={values.marketing_banner_key}
                                data-testid="marketing_banner_key"
                              />
                            </Cell>

                            <Cell small={12}>
                              <Label errorText={logoError} htmlFor={logo}>
                                Logo
                              </Label>
                              <div className={styles.logoImage}>
                                {logo && <img src={logo} alt="logo" />}
                              </div>
                            </Cell>
                            <Cell small={12}>
                              <Dropzone
                                onDrop={onDrop}
                                accept="image/*"
                                maxSize={2000000}
                                preventDropOnDocument={false}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <PrimaryButton
                                      label={logo ? 'Edit Logo' : 'upload'}
                                      className="font-18"
                                      type="button"
                                      onClick={(e) => e.preventDefault()}
                                    />
                                  </div>
                                )}
                              </Dropzone>
                            </Cell>
                            <Cell small={12}>
                              <Label errorText={footerLogoError} htmlFor={logo}>
                                Footer Logo
                              </Label>
                              <div className={styles.footerlogoImage}>
                                {footerLogo && (
                                  <img src={footerLogo} alt="footerLogo" />
                                )}
                              </div>
                            </Cell>
                            <Cell small={12}>
                              <Dropzone
                                onDrop={onFooterLogoDrop}
                                accept="image/*"
                                maxSize={2000000}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <PrimaryButton
                                      label={
                                        footerLogo ? 'Edit Logo' : 'upload'
                                      }
                                      className="font-18"
                                      type="button"
                                      onClick={(e) => e.preventDefault()}
                                    />
                                  </div>
                                )}
                              </Dropzone>
                            </Cell>
                          </Fragment>
                        )}
                        <Cell>
                          <SwitchToggle
                            dataTestId="ManageClinics.Admin.InternalMatching"
                            label="INTERNAL MATCHING"
                            checked={values.internal_matching}
                            onChange={handleChange}
                            name="internal_matching"
                            value={values.internal_matching}
                          />
                        </Cell>
                        {Object.entries(errors)}
                        <Cell small={12}>
                          <PrimaryButton
                            label="Save"
                            className={classnames(styles.saveButton, 'font-18')}
                            disabled={Object.entries(errors).length > 0}
                            onClick={handleSubmit}
                          />
                        </Cell>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </Modal>
      )}
      {showMessageModal && (
        <MessageModal
          message={displayMessage}
          onCloseModal={() => setShowMessageModal(false)}
        />
      )}
    </div>
  );
};

export default ManageClinics;
