import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, View, Text } from '@react-pdf/renderer';
import { AssessmentPDFStyles } from '../AssessmentPDFStyles';
import HeaderSection from './HeaderSection';

const NoDataAvailable = () => (
  <View>
    <Text>No Data Available</Text>
  </View>
);

const LayoutPDF = ({ item, title, formDetail, children }) => {
  return (
    <Document>
      <Page size="A4" style={AssessmentPDFStyles.page}>
        <HeaderSection item={item} formTypeTitle={title} />
        <View style={AssessmentPDFStyles.content_detail}>
          {formDetail && formDetail.id ? children : <NoDataAvailable />}
        </View>
      </Page>
    </Document>
  );
};

LayoutPDF.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  formDetail: PropTypes.object,
  children: PropTypes.node,
};

export default LayoutPDF;
