import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, Circle, Svg } from '@react-pdf/renderer';
import { AssessmentPDFStyles } from '../AssessmentPDFStyles';

const ListBullet = () => (
  <Svg height={6} width={6} style={{ marginTop: '4px' }}>
    <Circle cx="3" cy="3" r="2" fill="black" />
  </Svg>
);

const List = ({ isPrimary, value }) => (
  <View style={AssessmentPDFStyles.listRow}>
    <ListBullet />
    <Text
      style={[
        AssessmentPDFStyles.content_detail.p,
        { margin: 0, marginLeft: 5 },
        !isPrimary ? { fontSize: 10 } : {},
      ]}
    >
      {value}
    </Text>
  </View>
);

List.defaultProps = {
  isPrimary: true,
};

List.propTypes = {
  isPrimary: PropTypes.bool,
  value: PropTypes.string,
};

export default List;
