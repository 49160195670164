import React from 'react';
import styles from './ResetConfirmation.module.scss';
import { Link } from 'react-router-dom';
import Success from '../../img/success.png';
import Back from '../../img/icons/back.svg';
import Logo from '../Logo';

const ResetConfirmation = () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.backDiv}>
          <Link to="/">
            <img src={Back} alt="Back" />
            <span>Back</span>
          </Link>
        </div>
        <Logo />
        <div className={styles.successImg}>
          <img src={Success} alt="Success" />
        </div>
        <h4 className={styles.confirmationTitle}>Password Reset Email sent</h4>
        <p className={styles.confirmText}>
          We’ve sent you a mail to change your password, so please check your
          email and enter new password
        </p>
      </div>
    </div>
  );
};

export default ResetConfirmation;
