import React from 'react';
import PropTypes from 'prop-types';
import styles from './LabeledCheckbox.module.scss';
import classnames from 'classnames';

const LabeledCheckbox = ({
  name,
  value,
  checked,
  disabled,
  labelName,
  'data-testid': datatestid,
  'label-testid': labeltestid,
  'error-testid': errortestid,
  onChange,
  checkboxKey,
}) => (
  <div className={styles.checkboxContainer}>
    <label
      key={checkboxKey}
      label-testid={labeltestid}
      error-testid={errortestid}
    >
      <input
        className={styles.checkboxCustom}
        name={name}
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        data-testid={datatestid}
      />
      <span
        className={classnames(styles.checkboxCustomLabel, styles.checkboxLabel)}
      >
        {labelName}
      </span>
    </label>
  </div>
);

LabeledCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  labelName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

LabeledCheckbox.defaultProps = {
  disabled: false,
};

export default LabeledCheckbox;
