import React, { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import KAPIntakeForm from '../../../components/forms/user/KAPIntakeForm';
import styles from './IntakeFormPage.module.scss';
import classnames from 'classnames';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import messages from './messages';
import useIntl from '../../../hooks/useIntl';
import Modal from '../../../components/common/Modal';
import Loading from '../../../components/common/Loading';
import { saveIntakeForm1 } from '../../../api/intakeForm';
import Signature from '../../../components/Hellosign';
import Logo from '../../../components/Logo';

const IntakeFormPage = ({ location, history }) => {
  const initialValues = {
    personalDetails: {
      name: '',
      address: '',
      postalCode: '',
      city: '',
      state: '',
    },
    contactDetails: {
      sendEmail: true,
    },
    otherDetails: {
      gender: 'male',
      isTransgender: false,
    },
    medicalEmergency: {},
    familyDoctor: {},
    familyDetails: {
      relationshipStatus: 'married',
      children: [
        {
          name: '',
          gender: '',
          age: '',
        },
      ],
    },
    insuranceDetails: {},
    allergies: [],
    currentMedications: [
      {
        dose: '',
        frequency: '',
        dateUsed: '',
      },
    ],
    recreationalDrugs: [
      {
        method: '',
        frequency: '',
        experience: '',
        dateUsed: '',
      },
    ],
    medicalIllness: [
      {
        illness: '',
        status: '',
      },
    ],
    hasPreviousPsychologicalCare: false,
    psychologicalCare: [
      {
        clinicians: '',
        date: '',
        difficulty: '',
      },
    ],
    hasPsychiatricMedications: false,
    psychiatricMedications: [
      {
        medicine: '',
        dose: '',
        effects: '',
      },
    ],
    hasHospitalized: false,
    hasSuicidalThoughts: false,
    sleepingHabits: 'Unsatisfactory',
    difficultyFallingAsleep: false,
    poorSleep: false,
    awakeningDuringNight: false,
    isHappyWithWeight: false,
    hasLostGainedWeight: false,
    depressionLevel: 'No',
    hasAnxiety: false,
    hadAlchoholDrugDependency: false,
    hadPrescreptionDrugDependency: false,
    isPregnant: false,
    familyMemberBipolarDisorder: false,
    familyMemberAnxiety: false,
    familyMemberPTSD: false,
    familyMemberAnger: false,
    familyMemberOtherSubstance: false,
    familyMemberDepression: false,
    familyMemberViolence: false,
    familyMemberAlcoholAbuse: false,
    familyMemberSchizophrenia: false,
    familyMemberSuicide: false,
    hasFamilyMemberPsychatric: false,
    sleepingProblemSince: '',
    weight: '',
    height: '',
    depressionSince: '',
    anxietySince: '',
    alchoholPerWeek: '',
    alchoholPerNight: '',
    alchoholPerHour: '',
    recreationalDrugsFrequency: '',
    natureOfAlchoholDepenency: '',
    natureOfPrescreptionDependency: '',
    formOfBC: '',
    familyMemberTreatment: '',
    familyMemberPsychatricTreatment: '',
    natureOfTherapy: '',
    significantTrauma: '',
  };

  const [patientId /*setDrName*/] = useState(
    (location.state && location.state.patient_id) || '',
  );
  const [doctorId /*setDrName*/] = useState(
    (location.state && location.state.doctor_id) || '',
  );
  const [activeStep, setActiveStep] = useState(0);
  const { formatMessage } = useIntl();
  const [, setIsConsentFomCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationModalShown, setIsConfirmationModalShown] =
    useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);

    try {
      const res = await saveIntakeForm1({
        patient_id: patientId,
        doctor_id: doctorId,
        ...values,
      });

      if (res) {
        setIsLoading(false);
        setActiveStep(1);
      }
    } catch (error) {}
  };

  const handleLeaveIntakeForm = () => {
    setIsConfirmationModalShown(false);
    history.push(`/my-account/appointment/${doctorId}`);
  };

  const onWindowMessage = (event) => {
    if (event.data) {
      const data =
        typeof event.data === 'string' || event.data instanceof String
          ? JSON.parse(event.data)
          : event.data;
      if (data.type === 'ESIGN') {
        setIsConsentFomCompleted(true);
      }
    }
  };
  useEffect(() => {
    if (window) {
      window.addEventListener('message', onWindowMessage);
    }
    return () => {
      window.removeEventListener('message', onWindowMessage);
    };
  }, []);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div className={styles.intakeForm}>
            <KAPIntakeForm
              nextBtnLabel="Next"
              backBtnLabel="Back"
              submitBtnLabel="Submit"
              initialValues={initialValues}
              handleSubmit={handleSubmit}
            />
          </div>
        );
      case 1:
        return (
          <>
            <div className={styles.consetntForm}>
              <Signature
                doctorId={doctorId}
                patientId={patientId}
                role="Patient"
                isType={true}
              />
            </div>
          </>
        );
      default:
        return;
    }
  };
  return isLoading ? (
    <Loading />
  ) : (
    <Fragment>
      <div className={styles.container}>
        <div className={classnames('desktop-only', styles.header)}>
          <div className={styles['menu-left']}>
            <span className={styles.title}>
              {activeStep + 1} / 2 Intake Forms
            </span>
          </div>
          <div className={styles.logo}>
            <Link to="/home">
              <Logo />
            </Link>
          </div>
          <div className={styles['menu-right']}>
            <span
              className={styles.link}
              onClick={() => setIsConfirmationModalShown(true)}
            >
              {formatMessage(messages.LeaveLabel)}
            </span>
          </div>
        </div>
        <div className={classnames('mobile-only', styles.headerMobile)}>
          <div className={styles.logo}>
            <Link to="/home">
              <Logo />
            </Link>
          </div>
          <div className={styles.subHeader}>
            <div className={styles['menu-left']}>
              <span className={styles.title}>
                {activeStep + 1} / 2 Intak Forms
              </span>
            </div>
            <div className={styles['menu-right']}>
              <span
                className={styles.link}
                onClick={() => setIsConfirmationModalShown(true)}
              >
                {formatMessage(messages.LeaveMobileLabel)}
              </span>
            </div>
          </div>
        </div>
        {getStepContent(activeStep)}
        {isConfirmationModalShown && (
          <Modal className={styles.modal}>
            <div className={styles.content}>
              <h1>
                <b>{formatMessage(messages.confirmLeaveTitle)}</b>
              </h1>
              <p>{formatMessage(messages.confirmLeaveText)}</p>
              <PrimaryButton
                label={formatMessage(messages.confirmLeaveRecommendation)}
                className={styles.primaryButton}
                onClick={handleLeaveIntakeForm}
              />
              <SecondaryButton
                label={formatMessage(messages.confirmLeaveCancel)}
                className={styles.secondaryButton}
                onClick={() => setIsConfirmationModalShown(false)}
              />
            </div>
          </Modal>
        )}
      </div>
    </Fragment>
  );
};

export default IntakeFormPage;
