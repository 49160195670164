import React, { useState, Fragment, useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Cell } from 'react-foundation';
import classnames from 'classnames';

import Loading from '../../../components/common/Loading';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import DoctorLeftNav from '../../../components/nav/DoctorLeftNav';

import styles from './DoctorSquareCallbackPage.module.scss';

import ApprovalImg from '../../../img/approval.svg';
import SuccessImg from '../../../img/success-new.svg';

import { generateToken } from '../../../api/doctor/index';

import useIntl from '../../../hooks/useIntl';
import messages from './messages';

import AuthContext from '../../../context/AuthContext';

const DoctorSquareCallbackPage = ({ match }) => {
  const { isLoading, authUser } = useContext(AuthContext);

  const { formatMessage } = useIntl();
  const history = useHistory();

  const [dataLoading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const isDataLoading = dataLoading || isLoading;

  const query = new URLSearchParams(useLocation().search);

  const generateAccessToken = async (code, id) => {
    const state = query.get('state');
    if (state !== sessionStorage.getItem('state')) {
      setErrorMessage('Invalid state parameter');
    } else if (query.get('error')) {
      if (
        query.get('error') === 'access_denied' &&
        'user_denied' === query.get('error_description')
      ) {
        setErrorMessage('You chose to deny access to the app.');
      } else {
        setErrorMessage(query.get('error_description'));
      }
    } else if (query.get('response_type') === 'code') {
      setLoading(true);
      try {
        await generateToken(authUser.id, {
          code,
        });
        setSuccessMessage(
          `Your Square account has been successfully connected to ${
            authUser?.clinicDetails?.clinic_name || 'Eve'
          }.To see details on payments received, visit your Payment screen.`,
        );
        setLoading(false);
      } catch (error) {
        console.log('error', error);
        setErrorMessage('Something went wrong.Please try again');
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (authUser.square_access_token) {
      history.push('/doctor/my-account');
    }
    const code = query.get('code');
    const error = query.get('error');
    if ((code || error) && authUser.id) {
      generateAccessToken(code, authUser.id);
    }
  }, [authUser]);

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftNav, 'desktop-only')}>
        <DoctorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            />
            <h1 data-testid={`square title`}>
              {formatMessage(messages.title)}
            </h1>
            {successMessage !== '' && (
              <div className={styles.message}>
                <div className={styles.warningImg}>
                  <img
                    src={SuccessImg}
                    alt="Success"
                    data-testid={`square success image`}
                  />
                </div>
                <h2 data-testid={`square success heading`}>Success!</h2>
                <p
                  className={styles.subTitle}
                  data-testid={`square success message`}
                >
                  {successMessage}
                </p>
                <Cell small={12} flexContainer alignY="middle" flexDirCol="all">
                  <PrimaryButton
                    label="Ok"
                    type="button"
                    className="font-18"
                    onClick={() => history.push('/doctor/my-account')}
                    data-testid={`square redirect to dashboard`}
                  />
                </Cell>
              </div>
            )}
            {errorMessage !== '' && (
              <div className={styles.message}>
                <div className={styles.warningImg}>
                  <img
                    src={ApprovalImg}
                    alt="Warning"
                    data-testid={`square warning image`}
                  />
                </div>
                <h2 data-testid={`square connection failed`}>
                  Connection failed
                </h2>
                <p
                  className={styles.subTitle}
                  data-testid={`square error message`}
                >
                  {errorMessage}
                </p>
                <Cell small={12} flexContainer alignY="middle" flexDirCol="all">
                  <PrimaryButton
                    label="Ok"
                    type="button"
                    className="font-18"
                    onClick={() => history.push('/doctor/my-account')}
                    data-testid={`square redirect to dashboard`}
                  />
                </Cell>
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default DoctorSquareCallbackPage;
