import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { AssessmentPDFStyles } from '../AssessmentPDFStyles';

const HeaderSection = ({ item, formTypeTitle }) => {
  const firstName = item.patient.first_name || '';
  const lastName = item.patient.last_name || '';
  const formName = item.form_name || '';
  const dateCompleted = moment(item.updatedAt).format('MMMM DD, YYYY');

  return (
    <View style={AssessmentPDFStyles.headerSection}>
      <View>
        <View>
          <Text style={AssessmentPDFStyles.headerSection.label}>
            Client Name:
          </Text>
        </View>
        <View>
          <Text>{item.patient && `${firstName} ${lastName}`}</Text>
        </View>
      </View>
      <View>
        <View>
          <Text style={AssessmentPDFStyles.headerSection.label}>
            {`${formTypeTitle}:`}
          </Text>
        </View>
        <View>
          <Text>{formName}</Text>
        </View>
      </View>
      <View>
        <View>
          <Text style={AssessmentPDFStyles.headerSection.label}>
            Date Completed:
          </Text>
        </View>
        <View>
          <Text>{dateCompleted}</Text>
        </View>
      </View>
    </View>
  );
};

HeaderSection.defaultProps = {
  formTypeTitle: 'Intake',
};

HeaderSection.propTypes = {
  item: PropTypes.object,
  formTypeTitle: PropTypes.string,
};

export default HeaderSection;
