import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class Editor extends React.Component {
  componentDidMount() {
    if (!window.activeQuillEditors) {
      window.activeQuillEditors = {};
    }
    if (this.props.editorId) {
      window.activeQuillEditors[this.props.editorId] = this.quill;
    }
  }

  componentWillUnmount() {
    if (this.props.editorId) {
      delete window.activeQuillEditors[this.props.editorId];
    }
  }

  render() {
    const editorModules = {
      toolbar: {
        container: [
          [{ header: '1' }, { header: '2' }, { font: [] }],
          [{ size: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image', 'video'],
          ['clean'],
        ],
        handlers: {
          // image: this.imageHandler,
        },
      },
    };
    return (
      <div className="text-editor">
        <ReactQuill
          ref={(el) => {
            this.quill = el;
          }}
          value={this.props.value}
          readOnly={this.props.readOnly}
          onChange={this.props.onChange}
          formats={this.props.formats}
          toolbar={this.props.toolbar}
          placeholder={this.props.placeholder}
          modules={this.props.readOnly ? this.props.modules : editorModules}
        />
      </div>
    );
  }
}

export default Editor;
