import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './Appointment.module.scss';
import PrimaryButton from '../common/buttons/PrimaryButton';
import SecondaryButton from '../common/buttons/SecondaryButton';
import { APPOINTMENT_STATUSES } from '../../constants';

const Appointment = ({ setRequestedSuperBillItem, item, history }) => {
  const [showPopup, setShowPopup] = useState(false);
  const showRequestSuperBill =
    process.env.REACT_APP_SUPERBILL_FLAG === 'true' &&
    moment(item.end_time).isBefore(new Date()) &&
    item.amount > 0 &&
    item.status !== APPOINTMENT_STATUSES.CANCELED;

  const showRequestedSuperBill = ['REQUESTED', 'EDITED'].includes(
    item?.superbill?.status,
  );

  return (
    <div className={styles.root} data-testid={`patient appointments`}>
      <div className={styles.appointmentWrapper}>
        <div className={styles.imgBox}>
          <img
            data-testid={`AppointmentPage.Patient.ProfilePicture.Doctor.${item.doctor.first_name}`}
            src={
              item.doctor.profile_pic
                ? item.doctor.profile_pic
                : require('../../img/doctor.jpg')
            }
            alt={item.doctor.first_name}
          />
        </div>
        <div className={styles.appointmentDetails}>
          <div className={styles.meetingData}>
            <div className={styles['meeting-time']}>
              <div
                className={styles.timeDiv}
                data-testid="AppointmentPage.Patient.Meeting"
              >
                <img
                  data-testid="AppointmentPage.Patient.MeetingTime.Icon"
                  className={styles['meeting-icon']}
                  src={require(`../../img/icons/calendar-black.svg`)}
                  alt={'appointment-booked'}
                />
                <p
                  className={styles.isBefore}
                  data-testid="AppointmentPage.Patient.MeetingTime"
                >
                  {moment(item.start_time).format('LT')} -{' '}
                  {moment(item.end_time).format('LT')},{' '}
                  {moment(item.end_time).format('dddd, MMMM DD, YYYY')}
                </p>
              </div>
              <div className={styles.cancelBtn}>
                {item.reschedule_url &&
                  item.status !== 'canceled' &&
                  !moment(item.end_time).isBefore(new Date()) &&
                  item.cancel_url && (
                    <>
                      <SecondaryButton
                        label="..."
                        data-testid="AppointmentPage.Patient.MoreButton"
                        className={styles.moreBtn}
                        type="button"
                        onClick={() => setShowPopup(!showPopup)}
                      />
                      {showPopup && (
                        <div className={styles.popup}>
                          <button
                            data-testid="AppointmentPage.Patient.RescheduleButton"
                            onClick={() =>
                              history.push(
                                `/my-account/appointment/reschedule/${item.id}`,
                              )
                            }
                          >
                            Reschedule
                          </button>

                          <button
                            data-testid="AppointmentPage.Patient.CancelButton"
                            onClick={() =>
                              history.push(
                                `/my-account/appointment/cancel/${item.id}`,
                              )
                            }
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </>
                  )}
              </div>
            </div>
            <div className={styles['meeting-details']}>
              <div
                className={styles.title}
                data-testid={`AppointmentPage.Patient.DoctorName.${item.doctor.first_name}${item.doctor.last_name}`}
              >
                {item.doctor && item.doctor.first_name}{' '}
                {item.doctor && item.doctor.last_name
                  ? item.doctor.last_name
                  : ''}
              </div>
              <div className={styles.text}>
                <div className={styles.type}>
                  <span>Type:</span>
                  <p data-testid="AppointmentPage.Patient.AppointmentType">
                    {item.appointment_type}
                  </p>
                </div>
                <div className={styles.location}>
                  <span>Location:</span>
                  {item.doctor && item.doctor.clinician_registration && (
                    <p data-testid="AppointmentPage.Patient.AppointmentLocation">
                      {item.doctor.clinician_registration.city},{' '}
                      {item.doctor.clinician_registration.state}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.statusDetailsWrapper}>
            {showRequestSuperBill && (
              <p
                className={styles.superbilltext}
                data-testid="AppointmentPage.Patient.LastSuperbillRequested"
              >
                {item?.superbill?.requested_at
                  ? `Superbill last requested on ${moment(
                      item.superbill.requested_at,
                    ).format('MMMM DD, YYYY')}.`
                  : ''}
              </p>
            )}
            <div className={styles.fees}>
              {item.payment ? (
                <p data-testid="AppointmentPage.Patient.PaymentFee">
                  Fee: ${item.amount} - {item.status}
                </p>
              ) : (
                <p data-testid="AppointmentPage.Patient.PaymentFee">
                  Fee: ${item.amount} - {item.status}
                </p>
              )}
            </div>
            <div className={styles.buttons}>
              {showRequestSuperBill && (
                <div>
                  {item.superbill && item.superbill.sent_at ? (
                    <PrimaryButton
                      data-testid={`view_${item.id}`}
                      className={styles.requestButton}
                      label={'View Superbill'}
                      onClick={() =>
                        history.push(
                          `/my-account/superbill/patient/${item.superbill?.id}`,
                        )
                      }
                    />
                  ) : (
                    <PrimaryButton
                      data-testid={`request_${item.id}`}
                      className={styles.requestButton}
                      label={
                        showRequestedSuperBill
                          ? 'Requested Superbill'
                          : 'Request Superbill'
                      }
                      onClick={() => setRequestedSuperBillItem(item)}
                    />
                  )}
                </div>
              )}
              {item.status === APPOINTMENT_STATUSES.UNPAID && item.amount > 0 && (
                <PrimaryButton
                  label="Pay Fee"
                  type="button"
                  className={styles.requestButton}
                  onClick={() => {
                    history.push(`/my-account/payment/${item.id}`);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Appointment.propTypes = {
  setRequestedSuperBillItem: PropTypes.func,
  history: PropTypes.object,
  item: PropTypes.shape({
    amount: PropTypes.string,
    appointment_duration_in_minutes: PropTypes.number,
    appointment_type: PropTypes.string,
    bookingType: PropTypes.object,
    booking_type: PropTypes.string,
    booking_type_label: PropTypes.string,
    calendar_type: PropTypes.string,
    calendly_event_uuid: PropTypes.string,
    calendly_invitee_uuid: PropTypes.string,
    cancel_url: PropTypes.string,
    cancellation_reason: PropTypes.string,
    createdAt: PropTypes.string,
    doctor: PropTypes.object,
    doctor_id: PropTypes.number,
    end_time: PropTypes.string,
    end_time_pretty: PropTypes.string,
    has_assessment: PropTypes.bool,
    id: PropTypes.number,
    is_flag: PropTypes.bool,
    original_status: PropTypes.string,
    patient: PropTypes.object,
    patient_id: PropTypes.number,
    patient_phone: PropTypes.string,
    patient_type: PropTypes.string,
    payment: PropTypes.object,
    payment_id: PropTypes.number,
    payment_reminder_count: PropTypes.number,
    reschedule_url: PropTypes.string,
    start_time: PropTypes.string,
    start_time_pretty: PropTypes.string,
    status: PropTypes.string,
    superbill: PropTypes.object,
    timezone: PropTypes.string,
  }),
};

export default Appointment;
