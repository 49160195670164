import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ProductTypeIcon.module.scss';
import StarBenefitIcon from '../../img/icons/benefit-star.svg';
import BathBody100pxImg from '../../img/icons/Product Type/110px/BathBody.png';
import Beauty100pxImg from '../../img/icons/Product Type/110px/Beauty.png';
import Capsules100pxImg from '../../img/icons/Product Type/110px/Capsules.png';
import Edibles100pxImg from '../../img/icons/Product Type/110px/Edibles.png';
import Oils100pxImg from '../../img/icons/Product Type/110px/Oils.png';
import Pets100pxImg from '../../img/icons/Product Type/110px/Pets.png';
import VapePens100pxImg from '../../img/icons/Product Type/110px/VapePens.png';
import Adaptogens from '../../img/icons/Product Type/110px/Adaptogens.png';

const chooseIcon = (icon) => {
  switch (icon) {
    case 'cream-salves':
    case 'bath-body':
      return BathBody100pxImg;
    case 'beauty':
    case 'beauty-face':
      return Beauty100pxImg;
    case 'capsules':
      return Capsules100pxImg;
    case 'edibles':
      return Edibles100pxImg;
    case 'oil-tinctures':
    case 'tinctures':
      return Oils100pxImg;
    case 'pets':
      return Pets100pxImg;
    case 'vaporizers':
    case 'vape-pens':
      return VapePens100pxImg;
    case 'adaptogens':
      return Adaptogens;
    default:
      return StarBenefitIcon;
  }
};

const ProductTypeIcon = ({ icon, className }) => (
  <img
    src={chooseIcon(icon)}
    alt={icon}
    className={classNames(styles.img, {
      [className]: className,
    })}
  />
);

ProductTypeIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default ProductTypeIcon;
