import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import {
  updateClinicianRegistration,
  updateClinicianMatchingState,
} from '../../api/doctor';

import SecondaryButton from '../common/buttons/SecondaryButton';
import Loading from '../common/Loading';
import { Switch } from '../common/Switch';

import styles from './CliniciansList.module.scss';

import { CLINICIAN_REGISTRATION_STATUS } from '../../constants';

const getCertification = (data) => {
  if (data) {
    const certification =
      data && data.certification && data.certification.split(',');
    let index = certification && certification.findIndex((x) => x === 'Other');
    if (index >= 0) {
      certification.splice(index, 1, data && data.other_certification);
    }
    return certification;
  }
  return '';
};

const CliniciansList = ({ item, fetchTeam, incomplete }) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [ownerAllowMatchingToClients, setOwnerAllowMatchingToClients] =
    useState(false);

  useEffect(() => {
    setOwnerAllowMatchingToClients(
      item.doctor?.ownerAllowMatchingToClients || false,
    );
  }, [item]);

  const onChangeOwnerAllowMatchingToClients = async (e) => {
    const {
      target: { checked },
    } = e;
    try {
      await updateClinicianMatchingState(item.doctor.id, {
        status: checked,
      });
      setOwnerAllowMatchingToClients(checked);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.root}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={styles.clinicianBox}>
            <div className={styles.profilePic}>
              {item.doctor && (
                <img
                  src={
                    item.doctor.profile_pic
                      ? item.doctor.profile_pic
                      : require('../../img/doctor.jpg')
                  }
                  alt="clinician_profile"
                />
              )}
              {item && !item.doctor && (
                <img
                  src={
                    item.profile_pic
                      ? item.profile_pic
                      : require('../../img/doctor.jpg')
                  }
                  alt="clinician_profile"
                />
              )}
            </div>
            <div className={styles.ClinicianDetails}>
              <div className={styles.status}>
                {incomplete ? (
                  <p className={styles.inComplete}>Incomplete</p>
                ) : (
                  <p className={styles.complete}>Complete</p>
                )}
              </div>
              <div className={styles.name}>
                {item && !item.doctor ? (
                  <p>
                    {' '}
                    {item && item.first_name} {item && item.last_name}
                  </p>
                ) : (
                  <p>
                    {item.doctor && item.doctor.first_name}{' '}
                    {item.doctor && item.doctor.last_name}
                  </p>
                )}
              </div>
              <div className={styles.speciality}>
                {item && item.certification && <p>{getCertification(item)}</p>}
              </div>
            </div>
            <div className={styles.viewProfileDiv}>
              {/*item && !item.doctor && (
                <button className={styles.approveBtn} disabled={true}>
                  Approve
                </button>
              )*/}
              {item.status === CLINICIAN_REGISTRATION_STATUS.PENDING && (
                <>
                  <button
                    className={styles.approveBtn}
                    onClick={async () => {
                      setIsLoading(true);
                      await updateClinicianRegistration(item.doctor.id, {
                        status: CLINICIAN_REGISTRATION_STATUS.ENABLE,
                      });
                      await fetchTeam();
                      setIsLoading(false);
                    }}
                  >
                    Approve
                  </button>
                  <SecondaryButton
                    label="View Profile"
                    className={styles.seeProfile}
                    onClick={() =>
                      history.push(`/doctor/view-profile/${item.doctor.id}`)
                    }
                  />
                </>
              )}
              {(item.status === CLINICIAN_REGISTRATION_STATUS.ENABLE ||
                item.status === CLINICIAN_REGISTRATION_STATUS.DISABLE) && (
                <>
                  <div className={styles.actionDiv}>
                    <button
                      className={
                        item.status === CLINICIAN_REGISTRATION_STATUS.ENABLE
                          ? classnames(styles.commonButton, styles.enable)
                          : styles.commonButton
                      }
                      onClick={async () => {
                        setIsLoading(true);
                        await updateClinicianRegistration(item.doctor.id, {
                          status: CLINICIAN_REGISTRATION_STATUS.ENABLE,
                        });
                        await fetchTeam();
                        setIsLoading(false);
                      }}
                    >
                      Enable
                    </button>

                    <button
                      className={
                        item.status === CLINICIAN_REGISTRATION_STATUS.DISABLE
                          ? classnames(styles.commonButton, styles.disable)
                          : styles.commonButton
                      }
                      onClick={async () => {
                        setIsLoading(true);
                        await updateClinicianRegistration(item.doctor.id, {
                          status: CLINICIAN_REGISTRATION_STATUS.DISABLE,
                        });
                        await fetchTeam();
                        setIsLoading(false);
                      }}
                    >
                      Disable
                    </button>
                  </div>
                  <SecondaryButton
                    label="View Profile"
                    className={styles.seeProfile}
                    onClick={() =>
                      history.push(`/doctor/view-profile/${item.doctor.id}`)
                    }
                  />
                </>
              )}
            </div>
          </div>
          <div className={styles.switchBox}>
            {!incomplete && (
              <>
                <p>Allow matching to clients?</p>
                <Switch
                  disabled={incomplete}
                  checked={ownerAllowMatchingToClients}
                  onChange={onChangeOwnerAllowMatchingToClients}
                  name="ownerAllowMatchingToClients"
                  data-testid={`allow owner matching to clients for ${item.id}`}
                  label-testid={`allow owner matching to clients label for ${item.id}`}
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default CliniciansList;
