import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Cell, Grid } from 'react-foundation';
import { useHistory } from 'react-router-dom';
import { saveIntakeForm21 } from '../../../../api/intakeForm';
import useIntl from '../../../../hooks/useIntl';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import SecondaryButton from '../../../common/buttons/SecondaryButton';
import Loading from '../../../common/Loading';
import styles from '../PersonalInfoForm/PersonalInfoForm.module.scss';
import { AnahitaFormStepOne, AnahitaFormStepTwo } from './AnahitaFormSteps';
import messages from './messages';
import { stepOneFormStructure, stepTwoFormStructure } from './formStructure';
import { onKeyPress } from '../../../../utils/common';

export const anahitaCustomStyles = {
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  pageNumber: { fontSize: 15 },
  buttonsContainer: { marginRight: 30 },
};

const AnahitaForm = ({ fact_form_id, patientId, doctorId }) => {
  const { formatMessage } = useIntl();
  const [formStep, setFormStep] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  let initialValues = {};
  [...stepOneFormStructure, ...stepTwoFormStructure].forEach((element) => {
    initialValues[element.id] = element.type === 'checkbox' ? [] : '';
    if (element.freeText) {
      initialValues[element.freeText.id] = '';
    }
  });

  const nextStep = () => {
    setFormStep(formStep + 1);
  };
  const prevStep = () => {
    setFormStep(formStep - 1);
  };

  const handleSubmit = async (values) => {
    formatValues(values);
    setIsLoading(true);
    try {
      await saveIntakeForm21({
        patient_id: patientId,
        doctor_id: doctorId,
        fact_form_id,
        intake_form_values: values,
      });
      history.push(`/my-account/intake-forms`);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const formatValues = (values) => {
    [...stepOneFormStructure, ...stepTwoFormStructure].forEach((element) => {
      if (
        element.freeText &&
        values[element.id] !== element.freeText.triggerValue
      ) {
        values[element.freeText.id] = '';
      }
    });
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.form}>
      <Formik
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        enableReinitialize
        initialValues={initialValues}
        render={({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          dirty,
        }) => {
          const handleCheckboxChange = (arrOfItems, value) => {
            const itemIndex = arrOfItems.indexOf(value);
            itemIndex === -1
              ? arrOfItems.push(value)
              : arrOfItems.splice(itemIndex, 1);
            return arrOfItems;
          };

          const AnahitaFormSteps = [AnahitaFormStepOne, AnahitaFormStepTwo].map(
            (FormStep, i) => (
              <FormStep
                key={i}
                isLoading={isLoading}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                handleCheckboxChange={handleCheckboxChange}
              />
            ),
          );

          return (
            <Form onKeyPress={onKeyPress} data-testid="Personal-info-form">
              <Grid>
                <Cell small={12}>
                  <div style={anahitaCustomStyles.paginationContainer}>
                    <h2 className={styles.formTitle}>
                      {formatMessage(messages.formTitle)}
                    </h2>
                    <p style={anahitaCustomStyles.pageNumber}>{`Page ${
                      formStep + 1
                    } of ${AnahitaFormSteps.length}`}</p>
                  </div>
                </Cell>
                {AnahitaFormSteps[formStep]}
                <Cell medium={12} flexContainer>
                  {formStep > 0 && (
                    <div style={anahitaCustomStyles.buttonsContainer}>
                      <SecondaryButton
                        type="button"
                        label={'Back'}
                        onClick={prevStep}
                        className="font-18"
                      />
                    </div>
                  )}
                  {formStep < AnahitaFormSteps.length - 1 && (
                    <PrimaryButton
                      type="button"
                      label={'Next'}
                      onClick={nextStep}
                      className="font-18"
                    />
                  )}
                  {formStep === AnahitaFormSteps.length - 1 && (
                    <PrimaryButton
                      disabled={!dirty}
                      label={'Submit'}
                      type="submit"
                      className="font-18"
                    />
                  )}
                </Cell>
              </Grid>
            </Form>
          );
        }}
      />
    </div>
  );
};

AnahitaForm.propTypes = {
  fact_form_id: PropTypes.number,
  patientId: PropTypes.number,
  doctorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AnahitaForm;
