import React from 'react';
import classnames from 'classnames';
import styles from './CheckBox.module.scss';

const CheckBox = ({
  label,
  isActive,
  onClick,
  className,
  'data-testid': datatestid,
}) => (
  <div
    className={classnames(styles.container, className?.checkbox)}
    onClick={onClick}
    data-testid={datatestid}
  >
    <div
      className={classnames(
        styles.box,
        {
          [styles['box-active']]: isActive,
        },
        className?.box,
      )}
      data-testid="box-div"
    ></div>
    <div className={styles['box-name']} data-testid={`${datatestid} label`}>
      {label}
    </div>
  </div>
);

export default CheckBox;
