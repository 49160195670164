import { doGet, doPost, doPut, doDelete } from '../fetch';

// TODO: add URL from .env
const ESIGN_API_ENDPOINT = `${window.location.protocol}//${window.location.hostname}`;

const getForms = async (userId) => {
  const forms = await doGet(`/b2c/intake-forms/${userId}`);
  return forms;
};

const getForm = async (id) => {
  const form = await doGet(`/b2c/intakeform1/${id}`);
  return form;
};

const getConsetForm = async (id) => {
  const form = await doGet(`/b2c/intakeform2/${id}`);
  return form;
};

const saveIntakeForm1 = async (formValue) => {
  const res = await doPost('/b2c/intakeform1', formValue, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return res;
};

const updateIntakeForm1 = async (id, params) => {
  const res = await doPut(`/b2c/intakeform1/${id}`, params, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return res;
};

const saveIntakeForm2 = async (formValue) => {
  const res = await doPost('/b2c/intakeform2', formValue, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return res;
};

const saveIntakeForm21 = async (formValue) => {
  const res = await doPost('/b2c/intakeform21', formValue, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return res;
};

const updateIntakeForm2 = async (id, params) => {
  const res = await doPut(`/b2c/intakeform2/${id}`, params, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return res;
};

const deleteIntakeForm1 = async (id) => {
  const res = await doDelete(`/b2c/intakeform1/${id}`);
  return res;
};

const deleteIntakeForm2 = async (id) => {
  const res = await doDelete(`/b2c/intakeform2/${id}`);
  return res;
};
const getConsentFormUrl = async (body) => {
  const res = await doPost(
    `/esign/hello-sign/callback`,
    body,
    null,
    ESIGN_API_ENDPOINT,
  );
  return res;
};

const getConsentFormUrlClinician = async (body) => {
  const res = await doPost(
    `/esign/hello-sign/clinician-callback`,
    body,
    null,
    ESIGN_API_ENDPOINT,
  );
  return res;
};

const getSignatureTemplate = async (params) => {
  const res = await doGet(
    `/esign/hello-sign/template`,
    { params },
    ESIGN_API_ENDPOINT,
  );
  return res;
};

const getIntakeConsentForm = async (body) => {
  const res = await doPost(
    `/esign/hello-sign/download`,
    body,
    null,
    ESIGN_API_ENDPOINT,
  );
  return res;
};
const getFormLists = async () => {
  const forms = await doGet(`/b2c/form-lists`);
  return forms;
};

const assignForm = async (body) => {
  const form = await doPost('/b2c/patient/assign-form', body);
  return form;
};

const getAssignedForms = async (id) => {
  const forms = await doGet(`/b2c/patient/${id}/assigned-forms`);
  return forms;
};

const getIntakeFormByFactFormId = async (id) => {
  const form = await doGet(`/b2c/intakeform1ByFactFormId/${id}`);
  return form;
};

const getMoosIntakeFormByFactFormId = async (id) => {
  const form = await doGet(`/b2c/moosIntakeFormByFactFormId/${id}`);
  return form;
};

const fetchIntakeFormByFactFormId = async (id) => {
  const form = await doGet(`/b2c/intakeFormByFactFormId/${id}`);
  return form;
};

const getConsentFormByFactFormId = async (id) => {
  const form = await doGet(`/b2c/conseform1ByFactFormId/${id}`);
  return form;
};

const assignFormAssociation = async (body) => {
  const form = await doPost('/b2c/doctor/add-form-association', body);
  return form;
};

const getClinicAssociatedFormsData = async ({ doctor_id, clinic_id }) => {
  const forms = await doGet(`/b2c/clinic/${clinic_id}/associated-forms`);
  return forms;
};

const getAssessmentByFactFormId = async (id) => {
  const form = await doGet(`/b2c/assessmentByFactFormId/${id}`);
  return form;
};

const addForm = async (body) => {
  const res = await doPost('/b2c/addForm', body);
  return res;
};

export {
  getForm,
  getForms,
  saveIntakeForm1,
  updateIntakeForm1,
  saveIntakeForm2,
  updateIntakeForm2,
  deleteIntakeForm1,
  deleteIntakeForm2,
  getConsetForm,
  getConsentFormUrl,
  getIntakeConsentForm,
  getFormLists,
  assignForm,
  getSignatureTemplate,
  getAssignedForms,
  getIntakeFormByFactFormId,
  getConsentFormByFactFormId,
  assignFormAssociation,
  getClinicAssociatedFormsData,
  getAssessmentByFactFormId,
  saveIntakeForm21,
  getMoosIntakeFormByFactFormId,
  addForm,
  getConsentFormUrlClinician,
  fetchIntakeFormByFactFormId,
};
