import React, { useState, Fragment, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import classnames from 'classnames';
import moment from 'moment';
import { Cell, Grid } from 'react-foundation';
import Loading from '../../../components/common/Loading';
import DcotorLeftNav from '../../../components/nav/DoctorLeftNav';
import styles from './ManageFormsPage.module.scss';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import AuthContext from '../../../context/AuthContext';
import deleteTrashCanIcon from '../../../img/icons/delete-trashcan.svg';
import editIcon from '../../../img/icons/edit.svg';
import editIconSolid from '../../../img/icons/edit-icon-solid.svg';
import {
  getProtocolTemplates,
  getActiveProtocols,
  getCompleteProtocols,
  deleteScheduledProtocol,
  deleteProtocolTemplate,
} from '../../../api/doctor';
import Modal from '../../../components/common/Modal';
import { MANAGE_FORM_TABS } from '../../../constants';
import UserConfirmation from '../../../components/UserConfirmation';
import MessageModal from '../../../components/MessageModal';

const tabs = [
  MANAGE_FORM_TABS.PROTOCOL_TEMPLATES,
  MANAGE_FORM_TABS.ACTIVE_PROTOCOLS,
  MANAGE_FORM_TABS.COMPLETED_PROTOCOLS,
];

const txts = {
  intake: 'Intake',
  forNewPatients: 'For new patients',
  onNewRegistration: 'On new registration',
  noTitle: 'No title',
};

const ManageFormsPage = () => {
  const history = useHistory();
  const { isLoading, authUser } = useContext(AuthContext);

  const [dataLoading, setLoading] = useState(false);
  const isDataLoading = dataLoading || isLoading;
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [templateList, setTemplateList] = useState([]);
  const [activeProtocolsList, setActiveProtocolsList] = useState([]);
  const [completeProtocolsList, setCompleteProtocolsList] = useState([]);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [displayMessage, setDisplayMessage] = useState({
    title: '',
    message: '',
  });
  const [deleteTemplate, setDeleteTemplate] = useState({
    protocolId: '',
    scheduleType: '',
  });
  const [activeProtocolDetailsModel, setActiveProtocolDetailsModel] = useState({
    show: false,
    activeProtocol: {},
  });

  const fetchProtocolTemplates = async () => {
    setLoading(true);
    try {
      if (authUser.id) {
        const list = await getProtocolTemplates(authUser.id);
        const protocolTemplatesList = list && list.protocolTemplatesWithForms;
        setTemplateList(protocolTemplatesList);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const fetchActiveProtocols = async () => {
    setLoading(true);
    try {
      if (authUser.id) {
        const list = await getActiveProtocols(authUser.id);
        const activeProtocols = list && list.activeProtocols;
        setActiveProtocolsList(activeProtocols);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const fetchCompleteProtocols = async () => {
    setLoading(true);
    try {
      if (authUser.id) {
        const list = await getCompleteProtocols(authUser.id);
        const completeProtocols = list && list.completeProtocols;
        setCompleteProtocolsList(completeProtocols);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onTabClick = (tab) => {
    setActiveTab(tab);
    if (
      tab === MANAGE_FORM_TABS.ACTIVE_PROTOCOLS &&
      activeProtocolsList.length <= 0
    ) {
      fetchActiveProtocols();
    }
    if (
      tab === MANAGE_FORM_TABS.COMPLETED_PROTOCOLS &&
      completeProtocolsList.length <= 0
    ) {
      fetchCompleteProtocols();
    }
  };

  const handleActiveProtocolDetailsModel = (showModelStatus, protocol) => {
    setActiveProtocolDetailsModel({
      show: showModelStatus,
      activeProtocol: { ...protocol },
    });
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      if (authUser.id) {
        if (deleteTemplate.scheduleType === MANAGE_FORM_TABS.ACTIVE_PROTOCOLS) {
          await deleteScheduledProtocol(deleteTemplate.protocolId).catch(() => {
            setDisplayMessage({
              type: 'error',
              title: 'whoops!',
              text: 'Failed to delete schedule',
            });
            setShowMessageModal(true);
          });
          fetchActiveProtocols();
        } else if (
          deleteTemplate.scheduleType === MANAGE_FORM_TABS.COMPLETED_PROTOCOLS
        ) {
          await deleteScheduledProtocol(deleteTemplate.protocolId).catch(() => {
            setDisplayMessage({
              type: 'error',
              title: 'whoops!',
              text: 'Failed to delete schedule',
            });
            setShowMessageModal(true);
          });
          fetchCompleteProtocols();
        } else if (
          deleteTemplate.scheduleType === MANAGE_FORM_TABS.PROTOCOL_TEMPLATES
        ) {
          await deleteProtocolTemplate(deleteTemplate.protocolId).catch(() => {
            setDisplayMessage({
              type: 'error',
              title: 'whoops!',
              text: 'Failed to delete template. Try removing affiliated schedules first.',
            });
            setShowMessageModal(true);
          });
          fetchProtocolTemplates();
        }
      }
      setShowConfirmation(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProtocolTemplates();
  }, [authUser]);

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <DcotorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            ></div>
            <div className={styles.header}>
              <div className={styles.subheader}>
                <h1 data-testid="manage_forms header">Manage Forms</h1>
                <div className={styles.createTemplateButtonRow}>
                  <PrimaryButton
                    label="Create New Template"
                    type="button"
                    className={styles.templateButton}
                    onClick={() =>
                      history.push(`/doctor/create-protocol-template`)
                    }
                    data-testid="Create New Template"
                  />
                </div>
              </div>
              <nav className={styles.tabs}>
                {tabs.map((tab) => (
                  <button
                    key={tab}
                    className={[
                      styles.tab,
                      activeTab === tab ? styles.activeTab : '',
                    ].join(' ')}
                    type="button"
                    onClick={() => {
                      onTabClick(tab);
                    }}
                    data-testid={tab}
                  >
                    <span>{tab}</span>
                    <div></div>
                  </button>
                ))}
              </nav>

              {activeTab === MANAGE_FORM_TABS.PROTOCOL_TEMPLATES && (
                <Grid className={styles.templateTable}>
                  <Cell className={styles.templateHead} small={12} large={12}>
                    <Grid className={styles.templateRow}>
                      <Cell
                        small={2}
                        large={2}
                        className={styles.heading}
                      ></Cell>
                      <Cell
                        small={3}
                        large={3}
                        className={styles.heading}
                        data-testid="title_header"
                      >
                        Title
                      </Cell>
                      <Cell
                        small={3}
                        large={3}
                        className={styles.heading}
                        data-testid="forms_header"
                      >
                        Forms/Protocol
                      </Cell>
                      <Cell
                        small={4}
                        large={4}
                        className={styles.heading}
                      ></Cell>
                    </Grid>
                  </Cell>
                  <Cell className={styles.timelineList} small={12} large={12}>
                    {templateList &&
                      templateList.length > 0 &&
                      templateList?.map((template) => {
                        const { name, ProtocolTemplateForms } = template;
                        const forms = ProtocolTemplateForms?.map(
                          (form) => form?.dim_form?.form_name,
                        ).join(', ');
                        return (
                          <Grid
                            key={template.id}
                            className={styles.templateRow}
                            data-testid="protocol_template_list"
                          >
                            <Cell
                              small={2}
                              large={2}
                              className={styles.useTemplateColumn}
                            >
                              <div>
                                <PrimaryButton
                                  label="Use Template"
                                  type="button"
                                  className={styles.useTemplateButton}
                                  onClick={() => {
                                    history.push(
                                      `/doctor/use-protocol-template/${template.id}`,
                                    );
                                  }}
                                  iconLeft={editIcon}
                                  data-testid={`Use Template_${template.id}`}
                                />
                              </div>
                            </Cell>
                            <Cell
                              small={3}
                              large={3}
                              className={styles.templateTitle}
                            >
                              <div
                                className={styles.templateTitle}
                                data-testid={`protocol template title`}
                              >
                                {name || ''}
                              </div>
                            </Cell>
                            <Cell
                              small={3}
                              large={3}
                              className={styles.formsAndProtocols}
                            >
                              <div
                                className={styles.formsAndProtocols}
                                data-testid={`forms_${template.id}`}
                              >
                                {forms || ''}
                              </div>
                            </Cell>
                            <Cell
                              small={4}
                              large={4}
                              className={styles.deleteTemplateButton}
                            >
                              <img
                                src={deleteTrashCanIcon}
                                alt="Delete template"
                                onClick={() => {
                                  setShowConfirmation(true);
                                  setDeleteTemplate({
                                    protocolId: template?.id,
                                    scheduleType:
                                      MANAGE_FORM_TABS.PROTOCOL_TEMPLATES,
                                  });
                                }}
                                data-testid={`Delete template_${template.id}`}
                              />
                            </Cell>
                          </Grid>
                        );
                      })}
                  </Cell>
                </Grid>
              )}
              {activeTab === MANAGE_FORM_TABS.ACTIVE_PROTOCOLS && (
                <Grid className={styles.templateTable}>
                  <Cell className={styles.templateHead} small={12} large={12}>
                    <Grid className={styles.templateRow}>
                      <Cell
                        small={2}
                        large={2}
                        className={styles.heading}
                        data-testid="active_title_header"
                      >
                        Title
                      </Cell>
                      <Cell
                        small={3}
                        large={3}
                        className={styles.heading}
                        data-testid="active_assigned_header"
                      >
                        Assigned to
                      </Cell>
                      <Cell
                        small={3}
                        large={3}
                        className={styles.heading}
                        data-testid="active_forms_header"
                      >
                        Forms/Protocol
                      </Cell>
                      <Cell
                        small={3}
                        large={3}
                        className={styles.heading}
                        data-testid="active_schedule_header"
                      >
                        Schedule
                      </Cell>
                      <Cell
                        small={1}
                        large={1}
                        className={styles.heading}
                      ></Cell>
                    </Grid>
                  </Cell>
                  <Cell
                    className={styles.activeProtocolList}
                    small={12}
                    large={12}
                  >
                    {activeProtocolsList &&
                      activeProtocolsList.length > 0 &&
                      activeProtocolsList?.map((protocol) => {
                        const { ScheduleProtocols, ProtocolTemplate } =
                          protocol;
                        const forms =
                          ProtocolTemplate?.ProtocolTemplateForms?.map(
                            (form) => form?.dim_form?.form_name,
                          ).join(', ');

                        let patients = [];
                        let schedule = '';

                        if (protocol?.used_for_new_patient) {
                          schedule = txts.intake;
                          patients = [txts.forNewPatients];
                        } else if (protocol?.used_for_new_registration) {
                          schedule = txts.onNewRegistration;
                          patients = [txts.forNewPatients];
                        } else {
                          patients = ScheduleProtocols?.map(
                            (schedule) =>
                              `${schedule?.Patient?.first_name} ${schedule?.Patient?.last_name}`,
                          ).join(', ');

                          schedule = `${moment(protocol?.start_date).format(
                            'DD MMM YYYY',
                          )}${
                            protocol?.end_date
                              ? ` - ${moment(protocol?.end_date).format(
                                  'DD MMM YYYY',
                                )}`
                              : ''
                          }`;
                        }
                        return (
                          <Grid
                            key={protocol.id}
                            className={styles.templateRow}
                          >
                            <Cell
                              small={2}
                              large={2}
                              className={styles.templateTitle}
                            >
                              <div
                                className={styles.templateTitle}
                                onClick={() => {
                                  handleActiveProtocolDetailsModel(
                                    true,
                                    protocol,
                                  );
                                }}
                                data-testid={`view_detail_protocol`}
                              >
                                {ProtocolTemplate?.name || txts.noTitle}
                              </div>
                            </Cell>
                            <Cell
                              small={3}
                              large={3}
                              className={styles.assignToColumn}
                            >
                              <div
                                className={styles.assignToColumn}
                                data-testid={`assigned_protocol_${protocol.id}`}
                              >
                                {patients || ''}
                              </div>
                            </Cell>
                            <Cell
                              small={3}
                              large={3}
                              className={styles.formsAndProtocols}
                            >
                              <div
                                className={styles.formsAndProtocols}
                                data-testid={`forms_protocol_${protocol.id}`}
                              >
                                {forms || ''}
                              </div>
                            </Cell>
                            <Cell
                              small={3}
                              large={3}
                              className={styles.formsAndProtocols}
                            >
                              <div
                                className={styles.formsAndProtocols}
                                data-testid={`schedule_protocol_${protocol.id}`}
                              >
                                {schedule || ''}
                              </div>
                            </Cell>
                            <Cell
                              small={1}
                              large={1}
                              className={styles.deleteAndEditButton}
                            >
                              {/*<img
                                src={editIconSolid}
                                className={styles.editButton}
                                alt="icon"
                                onClick={() => {
                                  history.push(
                                    `/doctor/edit-protocol-template/${protocol?.id}`,
                                  );
                                }}
                                data-testid={`edit_active_protocol_${protocol.id}`}
                              />*/}
                              <img
                                src={deleteTrashCanIcon}
                                className={styles.deleteButton}
                                alt="Delete template"
                                onClick={() => {
                                  setShowConfirmation(true);
                                  setDeleteTemplate({
                                    protocolId: protocol?.id,
                                    scheduleType:
                                      MANAGE_FORM_TABS.ACTIVE_PROTOCOLS,
                                  });
                                }}
                                data-testid={`Delete_active_protocol_${protocol.id}`}
                              />
                            </Cell>
                          </Grid>
                        );
                      })}
                  </Cell>
                </Grid>
              )}
              {activeTab === MANAGE_FORM_TABS.COMPLETED_PROTOCOLS && (
                <Grid className={styles.templateTable}>
                  <Cell className={styles.templateHead} small={12} large={12}>
                    <Grid className={styles.templateRow}>
                      <Cell
                        small={2}
                        large={2}
                        className={styles.heading}
                        data-testid="completed_tilte_header"
                      >
                        Title
                      </Cell>
                      <Cell
                        small={3}
                        large={3}
                        className={styles.heading}
                        data-testid="completed_assigned_header"
                      >
                        Assigned to
                      </Cell>
                      <Cell
                        small={3}
                        large={3}
                        className={styles.heading}
                        data-testid="completed_forms_header"
                      >
                        Forms/Protocol
                      </Cell>
                      <Cell
                        small={3}
                        large={3}
                        className={styles.heading}
                        data-testid="completed_schedule_header"
                      >
                        Schedule
                      </Cell>
                      <Cell
                        small={1}
                        large={1}
                        className={styles.heading}
                      ></Cell>
                    </Grid>
                  </Cell>
                  <Cell
                    className={styles.activeProtocolList}
                    small={12}
                    large={12}
                  >
                    {completeProtocolsList &&
                      completeProtocolsList.length > 0 &&
                      completeProtocolsList?.map((protocol) => {
                        const { ScheduleProtocols, ProtocolTemplate } =
                          protocol;
                        const forms =
                          ProtocolTemplate?.ProtocolTemplateForms?.map(
                            (form) => form?.dim_form?.form_name,
                          ).join(', ');

                        let patients = [];
                        let schedule = '';

                        if (protocol?.used_for_new_patient) {
                          schedule = txts.intake;
                          patients = [txts.forNewPatients];
                        } else if (protocol?.used_for_new_registration) {
                          schedule = txts.onNewRegistration;
                          patients = [txts.forNewPatients];
                        } else {
                          patients = ScheduleProtocols?.map(
                            (schedule) =>
                              `${schedule?.Patient?.first_name} ${schedule?.Patient?.last_name}`,
                          ).join(', ');

                          schedule = `${moment(protocol?.start_date).format(
                            'DD MMM YYYY',
                          )}${
                            protocol?.end_date
                              ? ` - ${moment(protocol?.end_date).format(
                                  'DD MMM YYYY',
                                )}`
                              : ''
                          }`;
                        }
                        return (
                          <Grid
                            key={protocol.id}
                            className={styles.templateRow}
                          >
                            <Cell
                              small={2}
                              large={2}
                              className={styles.templateTitle}
                            >
                              <div
                                className={styles.templateTitle}
                                data-testid={`completed_protocol_title`}
                              >
                                {ProtocolTemplate?.name || txts.noTitle}
                              </div>
                            </Cell>
                            <Cell
                              small={3}
                              large={3}
                              className={styles.assignToColumn}
                            >
                              <div
                                className={styles.assignToColumn}
                                data-testid={`completed_assigned_${protocol.id}`}
                              >
                                {patients || ''}
                              </div>
                            </Cell>
                            <Cell
                              small={3}
                              large={3}
                              className={styles.formsAndProtocols}
                            >
                              <div
                                className={styles.formsAndProtocols}
                                data-testid={`completed_forms_${protocol.id}`}
                              >
                                {forms || ''}
                              </div>
                            </Cell>
                            <Cell
                              small={3}
                              large={3}
                              className={styles.formsAndProtocols}
                            >
                              <div
                                className={styles.formsAndProtocols}
                                data-testid={`completed_schedule_${protocol.id}`}
                              >
                                {schedule || ''}
                              </div>
                            </Cell>
                            <Cell
                              small={1}
                              large={1}
                              className={styles.deleteAndEditButton}
                            >
                              <img
                                src={deleteTrashCanIcon}
                                className={styles.deleteButton}
                                alt="Delete template"
                                onClick={() => {
                                  setShowConfirmation(true);
                                  setDeleteTemplate({
                                    protocolId: protocol?.id,
                                    scheduleType:
                                      MANAGE_FORM_TABS.COMPLETED_PROTOCOLS,
                                  });
                                }}
                                data-testid={`Delete_completed_protocol_${protocol.id}`}
                              />
                            </Cell>
                          </Grid>
                        );
                      })}
                  </Cell>
                </Grid>
              )}
            </div>
          </div>
          {activeProtocolDetailsModel.show && (
            <Modal className={styles.modal}>
              <div className={styles.content}>
                <div
                  className={styles.closeButton}
                  onClick={() => {
                    handleActiveProtocolDetailsModel(false, null);
                  }}
                  data-testid={`detail_close_${activeProtocolDetailsModel?.activeProtocol?.id}`}
                >
                  <img src={require(`../../../img/close.svg`)} alt={`Close`} />
                </div>
                <h2 className={styles.modalTitle}>
                  <b data-testid="detail_title_header">
                    {
                      activeProtocolDetailsModel?.activeProtocol
                        ?.ProtocolTemplate?.name
                    }
                  </b>
                </h2>
                <p
                  className={styles.subHeader}
                  data-testid="detail_assigned_label"
                >
                  Users or Groups Assigned
                </p>
                <p
                  className={styles.details}
                  data-testid="detail_assigned_values"
                >
                  {activeProtocolDetailsModel?.activeProtocol
                    ?.used_for_new_patient && txts.forNewPatients}
                  {activeProtocolDetailsModel?.activeProtocol
                    ?.used_for_new_registration && txts.forNewPatients}
                  {activeProtocolDetailsModel?.activeProtocol?.ScheduleProtocols?.map(
                    (schedule) =>
                      `${schedule?.Patient?.first_name} ${schedule?.Patient?.last_name}`,
                  ).join(', ')}
                </p>
                <p
                  className={styles.subHeader}
                  data-testid="detail_forms_label"
                >
                  Forms/Protocol
                </p>
                <p className={styles.details} data-testid="detail_forms_values">
                  {activeProtocolDetailsModel?.activeProtocol?.ProtocolTemplate?.ProtocolTemplateForms?.map(
                    (form) => form?.dim_form?.form_name,
                  ).join(', ')}
                </p>
                <p
                  className={styles.subHeader}
                  data-testid="detail_schedule_label"
                >
                  Schedule
                </p>
                {activeProtocolDetailsModel?.activeProtocol
                  ?.used_for_new_patient && (
                  <p
                    className={styles.details}
                    data-testid="detail_schedule_values"
                  >
                    Intake
                  </p>
                )}
                {!activeProtocolDetailsModel?.activeProtocol
                  ?.used_for_new_patient && (
                  <>
                    <p
                      className={styles.subHeader}
                      data-testid="detail_schedule_start_date_label"
                    >
                      Start Date:{' '}
                      <span
                        className={styles.details}
                        data-testid="detail_schedule_start_date_values"
                      >
                        {activeProtocolDetailsModel?.activeProtocol?.start_date
                          ? moment(
                              activeProtocolDetailsModel?.activeProtocol
                                ?.start_date,
                            ).format('MMMM DD, YYYY')
                          : '-'}
                      </span>
                    </p>
                    {activeProtocolDetailsModel?.activeProtocol?.end_date && (
                      <p
                        className={styles.subHeader}
                        data-testid="detail_schedule_end_date_label"
                      >
                        End Date:{' '}
                        <span
                          className={styles.details}
                          data-testid="detail_schedule_end_date_value"
                        >
                          {activeProtocolDetailsModel?.activeProtocol?.end_date
                            ? moment(
                                activeProtocolDetailsModel?.activeProtocol
                                  ?.end_date,
                              ).format('MMMM DD, YYYY')
                            : 'Never'}
                        </span>
                      </p>
                    )}
                  </>
                )}

                {/*<PrimaryButton
                  label="Edit"
                  className={styles.primaryButton}
                  onClick={() =>
                    history.push(
                      `/doctor/edit-protocol-template/${activeProtocolDetailsModel?.activeProtocol?.id}`,
                    )
                  }
                  data-testid={`detail_edit_${activeProtocolDetailsModel?.activeProtocol?.id}`}
                />*/}
              </div>
            </Modal>
          )}
        </Fragment>
      )}
      {showConfirmation && (
        <UserConfirmation
          message={`Are you sure you want to remove?`}
          onCancel={() => {
            setShowConfirmation(false);
          }}
          onConfirm={() => handleDelete()}
        />
      )}
      {showMessageModal && (
        <MessageModal
          onCloseModal={() => {
            setShowMessageModal(false);
          }}
          message={displayMessage}
          displayLogo={false}
        />
      )}
    </div>
  );
};

export default ManageFormsPage;
