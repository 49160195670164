import React, { createContext, useState } from 'react';

export const DiscoverContext = createContext();

// This context provider is passed to any component requiring the context
export const DiscoverProvider = ({ children }) => {
  const [answers, setAnswers] = useState({});
  const [progress, setProgress] = useState(0);
  const [Step, setStep] = useState(0);

  return (
    <DiscoverContext.Provider
      value={{
        answers,
        setAnswers,
        progress,
        setProgress,
        Step,
        setStep,
      }}
    >
      {children}
    </DiscoverContext.Provider>
  );
};
