import client from './client';

const getEntries = async (params) => {
  const entries = await client.getEntries(params);
  return entries;
};

const getEntry = async (id) => {
  const entry = await client.getEntry(id);
  return entry;
};

export { getEntries, getEntry };
