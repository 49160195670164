import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Cell, Grid } from 'react-foundation';
import { useHistory } from 'react-router-dom';
import { saveIntakeForm21 } from '../../../../api/intakeForm';
import useIntl from '../../../../hooks/useIntl';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import SecondaryButton from '../../../common/buttons/SecondaryButton';
import Loading from '../../../common/Loading';
import styles from '../PersonalInfoForm/PersonalInfoForm.module.scss';
import {
  StepOne,
  StepTwo,
  StepThree,
  StepFour,
  StepFive,
  StepSix,
  StepSeven,
  StepEight,
  StepNine,
} from './FormSteps';
import messages from './messages';
import {
  stepOneFormStructure,
  stepTwoFormStructure,
  stepThreeFormStructure,
  stepFourFormStructure,
  stepFiveFormStructure,
  stepSixFormStructure,
  stepSevenFormStructure,
  stepEightFormStructure,
  stepNineFormStructure,
} from './formStructure';

export const wildHeartCustomStyles = {
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  pageNumber: { fontSize: 15 },
  buttonsContainer: { marginRight: 30 },
};

const WildHeartForm = ({ fact_form_id, patientId, doctorId, id }) => {
  const { formatMessage } = useIntl();
  const [formStep, setFormStep] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  let initialValues = {};
  [
    ...stepOneFormStructure,
    ...stepTwoFormStructure,
    ...stepThreeFormStructure,
    ...stepFourFormStructure,
    ...stepFiveFormStructure,
    ...stepSixFormStructure,
    ...stepSevenFormStructure,
    ...stepEightFormStructure,
    ...stepNineFormStructure,
  ].forEach((element) => {
    if (!element.id) {
      return;
    }
    initialValues[element.id] = element.type === 'checkbox' ? [] : '';
    if (element.dependentFields) {
      if (element.dependentFields.formElements) {
        element.dependentFields.formElements.forEach((dependency) => {
          initialValues[dependency.id] =
            dependency.type === 'checkbox' ? [] : '';
        });
      } else initialValues[element.dependentFields.id] = '';
    }
  });

  const nextStep = () => {
    setFormStep(formStep + 1);
  };
  const prevStep = () => {
    setFormStep(formStep - 1);
  };

  const handleSubmit = async (values) => {
    formatValues(values);
    setIsLoading(true);
    if (id) {
      try {
        await saveIntakeForm21(id, { ...values });
        history.push(`/my-account/intake-forms`);
      } catch (error) {}
    } else {
      setIsLoading(true);
      try {
        await saveIntakeForm21({
          patient_id: patientId,
          doctor_id: doctorId,
          fact_form_id,
          intake_form_values: values,
        });
        history.push(`/my-account/intake-forms`);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const formatValues = (values) => {
    [
      ...stepOneFormStructure,
      ...stepTwoFormStructure,
      ...stepThreeFormStructure,
      ...stepFourFormStructure,
      ...stepFiveFormStructure,
      ...stepSixFormStructure,
      ...stepSevenFormStructure,
      ...stepEightFormStructure,
      ...stepNineFormStructure,
    ].forEach((element) => {
      if (element.dependentFields) {
        const activeDependency =
          element.type === 'checkbox'
            ? !!values[element.id].includes(
                element.dependentFields.triggerValue,
              )
            : values[element.id] === element.dependentFields.triggerValue;
        if (!activeDependency) {
          if (element.dependentFields.formElements) {
            element.dependentFields.formElements.forEach((dependency) => {
              values[dependency.id] = '';
            });
          } else {
            values[element.dependentFields.id] = '';
          }
        }
      }
    });
  };

  const onKeyPress = (event) => {
    if (event.which === 13) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [formStep]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.form}>
      <Formik
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        enableReinitialize
        onKeyPress={onKeyPress}
        initialValues={initialValues}
        render={({
          values,
          handleChange,
          setFieldValue,
          // handleBlur,
          dirty,
        }) => {
          const handleCheckboxChange = (arrOfItems, value) => {
            const itemIndex = arrOfItems.indexOf(value);
            itemIndex === -1
              ? arrOfItems.push(value)
              : arrOfItems.splice(itemIndex, 1);
            return arrOfItems;
          };

          const FormSteps = [
            StepOne,
            StepTwo,
            StepThree,
            StepFour,
            StepFive,
            StepSix,
            StepSeven,
            StepEight,
            StepNine,
          ].map((FormStep, i) => (
            <FormStep
              key={i}
              isLoading={isLoading}
              values={values}
              handleChange={handleChange}
              // handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              handleCheckboxChange={handleCheckboxChange}
            />
          ));

          return (
            <Form data-testid="Personal-info-form">
              <Grid>
                <Cell small={12}>
                  <div style={wildHeartCustomStyles.paginationContainer}>
                    <h2 className={styles.formTitle}>
                      {formatMessage(messages.formTitle)}
                    </h2>
                    <p style={wildHeartCustomStyles.pageNumber}>{`Page ${
                      formStep + 1
                    } of ${FormSteps.length}`}</p>
                  </div>
                </Cell>
                {FormSteps[formStep]}
                <Cell medium={12} flexContainer>
                  {formStep > 0 && (
                    <div style={wildHeartCustomStyles.buttonsContainer}>
                      <SecondaryButton
                        type="button"
                        label={'Back'}
                        onClick={prevStep}
                        className="font-18"
                      />
                    </div>
                  )}
                  {formStep < FormSteps.length - 1 && (
                    <PrimaryButton
                      type="button"
                      label={'Next'}
                      onClick={nextStep}
                      className="font-18"
                    />
                  )}
                  {formStep === FormSteps.length - 1 && (
                    <PrimaryButton
                      disabled={!dirty}
                      label={'Submit'}
                      type="submit"
                      className="font-18"
                    />
                  )}
                </Cell>
              </Grid>
            </Form>
          );
        }}
      />
    </div>
  );
};

WildHeartForm.propTypes = {
  fact_form_id: PropTypes.number,
  patientId: PropTypes.number,
  doctorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default WildHeartForm;
