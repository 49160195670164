import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { OktaAuth } from '@okta/okta-auth-js';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import styles from './SignupPage.module.scss';

import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import FieldInput from '../../components/common/fields/FieldInput/FieldInput';
import Loading from '../../components/common/Loading';
import SecondaryButton from '../../components/common/buttons/SecondaryButton';
import Logo from '../../components/Logo';
import DynamicClinicText from '../../components/DynamicClinicText';
import MessageModal from '../../components/MessageModal';
import Datepicker from '../../components/common/Datepicker';

import { addToDataLayer } from '../../utils/common';
import { signUp, verifyUser } from '../../api/auth';
import config from '../../config';
import { USER_TYPES } from '../../constants';

const auth = new OktaAuth({
  ...config.oidc,
});

const SignupPage = () => {
  const history = useHistory();
  const param = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [user, setUser] = useState();
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [displayMessage, setDisplayMessage] = useState({
    title: '',
    message: '',
  });
  const [invitedEmail, setInvitedEmail] = useState(null);

  const Schema = Yup.object().shape({
    firstName: Yup.string().required('Field is Required'),
    lastName: Yup.string().required('Field is Required'),
    email: Yup.string().email(),
    password: Yup.string()
      .required('Field is Required')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?&-]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number',
      ),
    confirmPassword: Yup.string()
      .required('Field is Required')
      .when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          'Both password need to be the same',
        ),
      }),
  });

  const handleSignUp = async (values) => {
    try {
      setIsLoading(true);

      const { firstName, password, email, userType, lastName, dob } = values;

      const data = {
        patientId: user.id,
        firstName,
        email,
        password,
        userType,
        lastName,
        dob,
        phone: user.phone,
        clinicId: user.clinic_id,
        labId: user.lab_id,
        doctorId: user.doctor_id,
        userId: param.oktaId,
        token: param.token,
      };

      await signUp(data);

      toast('Your account created successfully.', {
        autoClose: 5000,
        className: styles.toastMsg,
        bodyClassName: styles.toastDiv,
        hideProgressBar: true,
      });

      setIsLoading(false);

      const eventData = {
        event: 'ga4_event',
        properties: {
          event_name: 'signup',
          method: 'Onsite Form',
          user_id: user.id || '',
          clinic_id: user.clinic_id || '',
          user_type: userType || '',
        },
      };
      addToDataLayer(eventData);

      history.push('/');
    } catch (error) {
      setIsLoading(false);

      if (error?.response?.data?.message) {
        setError(error.response.data.message);
      } else {
        setDisplayMessage({
          type: 'error',
          title: 'Error',
          text: 'Something went wrong',
        });
        setShowMessageModal(true);
      }

      const eventData = {
        event: 'ga4_event',
        properties: {
          event_name: 'error',
          user_id: user.id || '',
          click_text: 'Error while user sign up',
          details: (error.response && error.response.data.message) || '',
          user_type: param.userType || '',
          site_section: 'Sign up',
        },
      };
      addToDataLayer(eventData);
    }
  };

  const validateUser = async () => {
    try {
      setIsLoading(true);

      const user = {
        userType: param.userType,
        token: param.token,
        signupTime: moment().format('LLL'),
      };
      const verifiedUser = await verifyUser(user);

      if (verifiedUser.email) {
        setInvitedEmail(verifiedUser.email);
      }

      setUser(verifiedUser);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      const responseMessage = error?.response?.data?.message;

      if (responseMessage === 'Token expired') {
        if (param.oktaId) {
          history.push(
            `/expire-token/${param.userType}/${param.oktaId}/${param.token}`,
          );
        } else {
          history.push(`/expire-token/${param.userType}/${param.token}`);
        }
      } else if (responseMessage === 'completed') {
        history.push('/user-registered');
      } else {
        setDisplayMessage({
          type: 'error',
          title: 'Error',
          text: responseMessage || 'Something went wrong',
        });
        setShowMessageModal(true);
      }
    }
  };

  useEffect(() => {
    validateUser();
  }, []);

  return (
    <div className={styles.root}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.container}>
          <Logo />
          <div className={styles.content}>
            <Formik
              initialValues={{
                firstName: (user && user.first_name) || '',
                lastName: '',
                dob: null,
                email: (user && user.email) || '',
                userType: param.userType,
                password: '',
                confirmPassword: '',
                aggreement: false,
              }}
              validationSchema={Schema}
              onSubmit={async (values) => {
                if (values && values.userType) {
                  handleSignUp(values);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <h4>
                      Create your <DynamicClinicText /> account
                    </h4>
                    <FieldInput
                      className={styles.userInput}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      autoFocus={false}
                      label="First name"
                      errorText={touched.firstName && errors.firstName}
                      name="firstName"
                      data-testid="firstName"
                    />
                    <FieldInput
                      className={styles.userInput}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      autoFocus={false}
                      label="Last name"
                      errorText={touched.lastName && errors.lastName}
                      name="lastName"
                      data-testid="lastName"
                    />
                    {param.userType === USER_TYPES.PATIENT && (
                      <div className={styles.dobRow}>
                        <Datepicker
                          label="Date Of Birth"
                          selected={values.dob ? new Date(values.dob) : null}
                          name="dob"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          value={values.dob}
                          dropdownMode="select"
                          maxDate={moment().toDate()}
                          placeholderText="Date Of Birth"
                          dateFormat="MM/dd/yyyy"
                          onBlur={handleBlur}
                          onChange={(date) => {
                            setFieldValue(`dob`, date);
                          }}
                          required={param.userType === USER_TYPES.PATIENT}
                          data-testid={`dob`}
                          autoComplete="off"
                        />
                      </div>
                    )}
                    <FieldInput
                      className={[
                        styles.userInput,
                        // param.oktaId === undefined ? '' :  styles.disabled,
                        !!invitedEmail ? styles.disabled : '',
                      ].join(' ')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={invitedEmail || values.email}
                      autoFocus={false}
                      label="Email address"
                      errorText={(touched.email && errors.email) || error}
                      name="email"
                      data-testid="email"
                    />
                    <FieldInput
                      className={styles.userInput}
                      label="Password"
                      onBlur={handleBlur}
                      errorText={touched.password && errors.password}
                      name="password"
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      data-testid="password"
                    />
                    <div className={styles.validation}>
                      {values.password.length < 8 ? (
                        <p>Min. 8 characters</p>
                      ) : (
                        <p className={styles.active}>Min. 8 characters</p>
                      )}
                      {!/\d/.test(values.password) ? (
                        <p>Number</p>
                      ) : (
                        <p className={styles.active}>Number</p>
                      )}
                      {!/[a-z]/.test(values.password) ? (
                        <p>Lowercase letter</p>
                      ) : (
                        <p className={styles.active}>Lowercase letter</p>
                      )}

                      {!/[A-Z]/.test(values.password) ? (
                        <p>Upperecase letter</p>
                      ) : (
                        <p className={styles.active}>Uppercase letter</p>
                      )}
                    </div>
                    <FieldInput
                      className={styles.userInput}
                      label="Confirm Password"
                      errorText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      onBlur={handleBlur}
                      name="confirmPassword"
                      onChange={handleChange}
                      type="password"
                      value={values.confirmPassword}
                      data-testid="confirmPassword"
                    />
                    <div className={styles.aggreement}>
                      <input
                        required
                        type="checkbox"
                        name="aggreement"
                        value={values.aggreement}
                        className={styles.aggreementCheck}
                        onClick={() => {
                          setFieldValue('aggreement', !values.aggreement);
                        }}
                      />
                      <label htmlFor="aggreement">
                        By checking here I affirm that I agree to the{' '}
                        <Link to="/agreements/terms-conditions">
                          <b>Terms and Conditions</b>
                        </Link>{' '}
                        of Service and{' '}
                        <Link to="/agreements/privacy-policy">
                          <b> Privacy Policy</b>
                        </Link>{' '}
                        of Eve Health Systems
                        {/*clinic && clinic.clinic_name
                          ? clinic.clinic_name
                          : 'Eve Health Systems'*/}
                      </label>
                    </div>

                    <div className={styles.nextBtn}>
                      <PrimaryButton
                        className={styles.primaryBtn}
                        label="Create an account"
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
      {showMessageModal && (
        <MessageModal
          message={displayMessage}
          onCloseModal={() => setShowMessageModal(false)}
        />
      )}
    </div>
  );
};

export default SignupPage;
