import React, { useState, useEffect, useContext } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { SecureRoute, Security } from '@okta/okta-react';

import HomePage from '../../pages/HomePage';
import DiscoverPage from '../../pages/DiscoverPsychPage';
import DiscoverPageV2 from '../../pages/DiscoverPageV2';
import DiscoverPageV3 from '../../pages/DiscoverPageV3';
import StaticPageTemplate from '../../pages/contentful/StaticPageTemplate';
import NotFoundPage from '../../pages/NotFoundPage';
import ContactUsPage from '../../pages/agreements/ContactUsPage';
import MyAccountAppointmentPage from '../../pages/my-account/MyAccountAppointmentPage';
import DashboardPage from '../../pages/DashboardPage';
import IntakeFormPage from '../../pages/my-account/IntakeFormPage';
import MyAccountIntakeFormsPage from '../../pages/my-account/MyAccountIntakeFormsPage';
import EditIntakeFormPage from '../../pages/my-account/EditIntakeFormPage';
import MyAppointmentsPage from '../../pages/my-account/MyAppointmentsPage';
import DoctorAppointmentsPage from '../../pages/doctor/DoctorAppointmentsPage';
import MyAccountPage from '../../pages/doctor/MyAccountPage';
import BillingPage from '../../pages/doctor/BillingPage';
import PatientDetailsPage from '../../pages/doctor/PatientDetailsPage';
import DoctorPaymentPage from '../../pages/doctor/DoctorPaymentPage';
import PaymentPage from '../../pages/my-account/PaymentPage';
import Payment from '../../components/Payment';
import ReschedulePage from '../../pages/doctor/ReschedulePage';
import CancelAppointmentPage from '../../pages/doctor/CancelAppointmentPage';
import DoctorNewAppointmentPage from '../../pages/doctor/DoctorNewAppointmentPage';
import DoctorProfilePage from '../../pages/doctor/DoctorProfilePage';
import AvailabilityPage from '../../pages/doctor/AvailabilityPage';
import PatientProfilePage from '../../pages/doctor/PatientProfilePage';
import InvitePatientPage from '../../pages/InvitePatientPage';
import InviteClinicianPage from '../../pages/InviteClinicianPage';
import PatientPage from '../../pages/doctor/PatientPage';
import WaitList from '../../pages/WaitList';
import ClinianRegistration from '../../components/forms/user/ClinicianRegistration';
import ImplicitCallback from '../../pages/OktaLoginCallback';
import LoginPage from '../../pages/LoginPage';
import SignupPage from '../../pages/SingupPage';
import DoctorInitialSignupPage from '../../pages/DoctorInitialSignupPage';
import PreInitialSignupPage from '../../pages/PreInitialSignupPage';
import RegistrationPage from '../../pages/RegistrationPage';
import Confirmation from '../../components/Confirmation';
import UserNotRecognized from '../../components/UserNotRecognized';
import ResetConfirmation from '../../components/ResetConfirmation';
import ActivationLogin from '../../pages/ConfirmationPage';
import ForgotPassword from '../../pages/ForgotPassword';
import ResetPasswordPage from '../../pages/ResetPasswordPage';
import ExpireToken from '../../components/ExpireToken';
import TeamPage from '../../pages/doctor/TeamPage';
import ClinicPage from '../../pages/doctor/ClinicPage';
import Approval from '../../components/Approval';
import DisableAccount from '../../components/DisableAccount';
import TokenUsed from '../../components/TokenUsed';
import DoctorPaymentSetupPage from '../../pages/doctor/DoctorPaymentSetupPage';
import DoctorSquareCallbackPage from '../../pages/doctor/DoctorSquareCallbackPage';
import AssesmentPage from '../../pages/assesment';
import FormAssessmentPage from '../../pages/FormAssesment';
import AdminPage from '../../pages/admin/AdminPage';
import AdminInviteClinicianPage from '../../pages/admin/InviteClinicianPage';
import ViewProfile from '../../components/ViewProfile';
import ManageFormsPage from '../../pages/admin/ManageFormsPage';
import ManageFormsPageForDoctor from '../../pages/doctor/ManageFormPage';
import UpdateCliniciainProfile from '../../pages/doctor/UpdateCliniciainProfile';
import ManageClinics from '../../pages/admin/ManageClinics';
import CalendlyAuthCallbackPage from '../../pages/doctor/CalendlyAuthCallbackPage';
import CreateProtocolTemplate from '../../pages/doctor/CreateProtocolTemplate';
import UseProtocolTemplatePage from '../../pages/doctor/UseProtocolTemplatePage';
import EditActiveProtocolPage from '../../pages/doctor/EditActiveProtocolPage';
import WellnessPage from '../../pages/WellnessPage/WellnessPage';
import AdminInviteResearcherPage from '../../pages/admin/InviteResearcherPage';
import ResearcherRegistration from '../forms/user/ResearcherRegistration/ResearcherRegistration';
import ResearcherDashboardPage from '../../pages/Researcher/ResearcherDashboardPage';
import InviteParticipantsPage from '../../pages/Researcher/InviteParticipantsPage';
import InviteResearcherPage from '../../pages/Researcher/InviteResearcherPage';
import ParticipantsPage from '../../pages/Researcher/ParticipantsPage';
import DoctorNylasCallbackPage from '../../pages/doctor/DoctorNylasCallbackPage';
import NylasThankYou from '../../components/NylasThankYou';
import FormsPage from '../../pages/admin/FormsPage';
import SuperBillForm from '../../components/SuperBillForm';
import CalendlyAvailabilityPage from '../../pages/doctor/CalendlyAvailabilityPage';
import PersonalInfoForm from '../../components/forms/user/PersonalInfoForm';

import { isLoggedIn } from '../../utils/user';

import styles from './Routes.module.scss';
import config from '../../config';

import AuthContext from '../../context/AuthContext';

const oktaAuth = new OktaAuth(config.oidc);

const PrivateRoute = ({ children, type, ...rest }) => {
  const location = useLocation();
  const history = useHistory();

  const [hasSession, setHasSession] = useState(true);

  const profileType = localStorage.getItem('profile-type');

  const verifySession = async () => setHasSession(await isLoggedIn());

  const customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    history.push('/');
  };

  const restoreOriginalUri = async (oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  useEffect(() => {
    verifySession();
  }, []);

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <SecureRoute {...rest}>
        {hasSession && [profileType, 'home'].includes(type) ? (
          children
        ) : (
          <Redirect to={{ pathname: '/', state: { from: location } }} />
        )}
      </SecureRoute>
    </Security>
  );
};

const BillingPlanRoute = ({
  component,
  matching,
  patientDetails,
  appointments,
  payments,
  intakeForms,
  assessments,
  notes,
  patientFiles,
}) => {
  const { isLoading, authUser } = useContext(AuthContext);
  const location = useLocation();

  if (isLoading || !authUser?.billing_plan) {
    return <div>Loading...</div>;
  }

  if (
    (matching && !authUser?.billing_plan?.matching) ||
    (patientDetails && !authUser?.billing_plan?.patient_details) ||
    (appointments && !authUser?.billing_plan?.appointments) ||
    (payments && !authUser?.billing_plan?.payments) ||
    (intakeForms && !authUser?.billing_plan?.intake_forms) ||
    (assessments && !authUser?.billing_plan?.assessments) ||
    (notes && !authUser?.billing_plan?.notes) ||
    (patientFiles && !authUser?.billing_plan?.patient_files)
  ) {
    return <Redirect to={{ pathname: '/', state: { from: location } }} />;
  }

  return component;
};

const Routes = () => {
  const currentPage = useLocation().pathname;

  return currentPage === '/join' ? (
    <div className={styles.waitListRoutes}>
      <Switch>
        <Route exact path="/join" component={WaitList} />
      </Switch>
    </div>
  ) : (
    <div className={styles.routes}>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/implicit/callback" component={ImplicitCallback} />
        <Route exact path="/confirmation" component={Confirmation} />
        <Route exact path="/user-denied" component={UserNotRecognized} />
        <Route exact path="/unathorize-user" component={UserNotRecognized} />
        <Route exact path="/approval/:clinic" component={Approval} />
        <Route exact path="/disable/:clinic" component={DisableAccount} />

        <Route
          exact
          path="/expire-token/:userType/:oktaId?/:token"
          component={ExpireToken}
        />
        <Route
          exact
          path="/signup/:userType/:oktaId?/:token"
          component={SignupPage}
        />
        <Route
          exact
          path="/doctor-initial-signup"
          component={DoctorInitialSignupPage}
        />
        <Route
          exact
          path="/pre-initial-signup"
          component={PreInitialSignupPage}
        />

        <Route exact path="/user-registered" component={TokenUsed} />

        <Route exact path="/reset/email" component={ResetConfirmation} />
        <Route
          exact
          path="/activate/:email?/:activationId?"
          component={ActivationLogin}
        />

        <Route
          exact
          path="/Registration/:oktaId"
          component={RegistrationPage}
        />
        <Route
          exact
          path="/forgot-password/:email?"
          component={ForgotPassword}
        />
        <Route
          exact
          path="/reset-password/:resetToken"
          component={ResetPasswordPage}
        />
        <Route
          exact
          sensitive
          path="/discoverv3/:doctotId?/:questionnaireSlug?"
          render={(props) => <DiscoverPageV3 {...props} />}
        />
        <PrivateRoute path="/home" type="home">
          <Route exact path="/home" component={HomePage} />
        </PrivateRoute>
        <PrivateRoute path="/admin" type="admin">
          <Route exact path="/admin" component={AdminPage} />
          <Route
            exact
            path="/admin/invite-clinician"
            component={AdminInviteClinicianPage}
          />
          <Route exact path="/admin/manage-forms" component={ManageFormsPage} />
          <Route exact path="/admin/manage-clinics" component={ManageClinics} />
          <Route
            exact
            path="/admin/invite-researcher"
            component={AdminInviteResearcherPage}
          />
          <Route exact path="/admin/forms" component={FormsPage} />
        </PrivateRoute>
        <PrivateRoute path="/doctor" type="doctor">
          <Route exact path="/doctor/my-account" component={MyAccountPage} />
          <Route exact path="/doctor/billing" component={BillingPage} />

          <Route
            exact
            path="/doctor/appointments"
            render={(props) => (
              <BillingPlanRoute
                appointments
                component={<DoctorAppointmentsPage {...props} />}
              />
            )}
          />
          <Route
            exact
            path="/doctor/appointment/:patientId/clinician/:clinicianId"
            render={(props) => (
              <BillingPlanRoute
                appointments
                component={<DoctorNewAppointmentPage {...props} />}
              />
            )}
          />
          <Route
            exact
            path="/doctor/appointment/reschedule/:id"
            render={(props) => (
              <BillingPlanRoute
                appointments
                component={<ReschedulePage {...props} />}
              />
            )}
          />
          <Route
            exact
            path="/doctor/appointment/cancel/:id"
            render={(props) => (
              <BillingPlanRoute
                appointments
                component={<CancelAppointmentPage {...props} />}
              />
            )}
          />
          <Route
            exact
            path="/doctor/availability"
            render={(props) => (
              <BillingPlanRoute
                appointments
                component={<AvailabilityPage {...props} />}
              />
            )}
          />
          <Route
            exact
            path="/doctor/calendly-availability"
            render={(props) => (
              <BillingPlanRoute
                appointments
                component={<CalendlyAvailabilityPage {...props} />}
              />
            )}
          />

          <Route exact path="/doctor/patients" component={PatientPage} />
          <Route
            exact
            path="/doctor/manage-forms"
            component={ManageFormsPageForDoctor}
          />
          <Route
            exact
            path="/doctor/use-protocol-template/:id"
            component={UseProtocolTemplatePage}
          />
          <Route
            exact
            path="/doctor/edit-protocol-template/:id"
            component={EditActiveProtocolPage}
          />
          <Route
            exact
            path="/doctor/create-protocol-template"
            component={CreateProtocolTemplate}
          />

          <Route
            exact
            path="/doctor/view-profile/:id"
            component={ViewProfile}
          />

          <Route
            path="/doctor/patient-details/:id"
            component={PatientDetailsPage}
          />

          <Route exact path="/doctor/payment" component={DoctorPaymentPage} />

          <Route exact path="/doctor/profile" component={DoctorProfilePage} />

          <Route
            exact
            path="/doctor/invite-patient"
            component={InvitePatientPage}
          />
          <Route
            exact
            path="/doctor/invite-clinician"
            component={InviteClinicianPage}
          />

          <Route exact path="/doctor/team" component={TeamPage} />

          <Route exact path="/doctor/clinic" component={ClinicPage} />

          <Route
            path="/doctor/clinician/registration"
            component={ClinianRegistration}
          />
          <Route
            path="/doctor/update-clinician/registration"
            component={UpdateCliniciainProfile}
          />
          <Route
            exact
            path="/doctor/payment-setup"
            component={DoctorPaymentSetupPage}
          />
          <Route
            exact
            path="/doctor/squareCallback"
            component={DoctorSquareCallbackPage}
          />
          <Route
            path="/doctor/nylas_callback"
            component={DoctorNylasCallbackPage}
          />
          <Route
            exact
            path="/doctor/form/:type"
            component={EditIntakeFormPage}
          />
          <Route
            exact
            path="/doctor/auth/calendly"
            component={CalendlyAuthCallbackPage}
          />

          <Route exact path="/contact-us" component={ContactUsPage} />

          <Route
            exact
            path="/doctor/superbill/:type/:id"
            component={SuperBillForm}
          />
        </PrivateRoute>

        <PrivateRoute path="/my-account" type="patient">
          <Route exact path="/my-account/view-profile/:id">
            <ViewProfile />
          </Route>
          <Route exact path="/my-account">
            <DashboardPage />
          </Route>
          <Route exact path="/my-account/wellness">
            <WellnessPage />
          </Route>
          <Route
            exact
            path="/my-account/user-details"
            component={PersonalInfoForm}
          />
          <Route
            exact
            path="/my-account/appointment/reschedule/:id"
            component={ReschedulePage}
          />
          <Route
            exact
            path="/my-account/appointment/cancel/:id"
            component={CancelAppointmentPage}
          />
          <Route
            exact
            path="/my-account/appointment/:id"
            component={MyAccountAppointmentPage}
          />
          <Route path="/my-account/intake-form" component={IntakeFormPage} />
          <Route
            exact
            path="/my-account/form/:type"
            component={EditIntakeFormPage}
          />
          <Route
            exact
            path="/my-account/form/:type/:id"
            component={EditIntakeFormPage}
          />
          <Route
            path="/my-account/intake-forms"
            component={MyAccountIntakeFormsPage}
          />
          <Route
            path="/my-account/appointments"
            component={MyAppointmentsPage}
          />
          <Route exact path="/my-account/payment" component={PaymentPage} />
          <Route
            exact
            path="/my-account/myprofile"
            component={PatientProfilePage}
          />
          <Route exact path="/my-account/discover" component={DiscoverPage} />
          <Route
            exact
            path="/my-account/discoverv2/:questionnaireSlug?"
            render={(props) => <DiscoverPageV2 {...props} />}
          />
          <Route
            exact
            onLeave={() => {
              console.log('onLeave foo');
            }}
            path="/my-account/assesment/:questionnaireSlug?"
            render={(props) => <AssesmentPage {...props} />}
          />
          <Route
            exact
            path="/my-account/assessment-form/:questionnaireSlug?"
            render={(props) => <FormAssessmentPage {...props} />}
          />
          <Route exact path="/my-account/payment/:id" component={Payment} />
          <Route
            exact
            path="/my-account/superbill/:type/:id"
            component={SuperBillForm}
          />
        </PrivateRoute>

        <PrivateRoute path="/researcher" type="researcher">
          <Route
            exact
            path="/researcher/registration"
            component={ResearcherRegistration}
          />
          <Route
            exact
            path="/researcher/dashboard"
            component={ResearcherDashboardPage}
          />
          <Route
            exact
            path="/researcher/participants"
            component={ParticipantsPage}
          />
          <Route
            exact
            path="/researcher/invite-participants"
            component={InviteParticipantsPage}
          />
          <Route
            exact
            path="/researcher/invite-researcher"
            component={InviteResearcherPage}
          />
        </PrivateRoute>
        <Route path="/thankyou">
          <NylasThankYou />
        </Route>
        <Route exact path="/contact-us" component={ContactUsPage} />
        <Route
          exact
          path="/agreements/:pageName"
          component={StaticPageTemplate}
        />

        <Route path="/" component={NotFoundPage} />
      </Switch>
    </div>
  );
};
export default Routes;
