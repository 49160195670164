/**
 * This context provider is used to be able to show overlays that
 * are currently controlled via top nav and not accessible from children components.
 * Using this context you can trigger overlays to show from child compnents. The context is consumed
 * by the Navigation components that will eventually render the overlays.
 * TODO: all overlays should be converted to pages/routes that way it can be handled via react-router
 * and this context provider won't be needed.
 */
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const OverlayContext = createContext(false);

export const OverlayProvider = ({ children }) => {
  const [showLearnMenuOverlay, setShowLearnMenuOverlay] = useState(false);
  return (
    <OverlayContext.Provider
      value={{
        showLearnMenuOverlay,
        setShowLearnMenuOverlay,
      }}
    >
      {children}
    </OverlayContext.Provider>
  );
};

OverlayContext.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default OverlayContext;
