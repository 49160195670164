import React, { useEffect, useState } from 'react';
import { Button } from 'react-foundation';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './PrimaryButton.module.scss';

const PrimaryButton = ({
  label,
  onClick,
  className,
  disabled,
  'data-testid': datatestid,
  iconLeft,
  type,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => setIsDisabled(disabled), [disabled]);
  return (
    <Button
      type={type || 'submit'}
      className={classNames(styles.primary, {
        [className]: className,
      })}
      onClick={async (e) => {
        setIsDisabled(true);
        onClick && (await onClick(e));
        setIsDisabled(false);
      }}
      data-testid={datatestid}
      disabled={isDisabled}
    >
      {iconLeft && (
        <img src={iconLeft} className={styles.iconLeft} alt="icon" />
      )}
      {label}
    </Button>
  );
};

PrimaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

PrimaryButton.defaultProps = {
  disabled: false,
  iconLeft: null,
};

export default PrimaryButton;
