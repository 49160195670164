import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from 'react-foundation';
import { Link, Route } from 'react-router-dom';
import AuthContext from '../../../context/AuthContext';
import useIntl from '../../../hooks/useIntl';
import { logout } from '../../../api/auth';
import styles from './UserPopup.module.scss';
import messages from './messages';
import { useOktaAuth } from '@okta/okta-react';
import { addToDataLayer } from '../../../utils/common';

const UserPopup = ({ theme }) => {
  const { formatMessage } = useIntl();
  const { authUser, userAvtar, dispatch } = useContext(AuthContext);
  const { authState, oktaAuth } = useOktaAuth();

  return (
    <div className={styles.container} data-testid="container">
      <img alt="" src={userAvtar} data-testid="myaccount profile image" />
      {
        <div className={styles.popup}>
          <b data-testid="profile menu name">
            {authUser.id
              ? authUser.firstName
                ? authUser.firstName
                : authUser.first_name
              : formatMessage(messages.hiThere)}
          </b>
          <Menu isVertical>
            <MenuItem>
              {authUser.id ? (
                <Link
                  to={
                    authUser.profile_type === 'patient'
                      ? '/my-account/myprofile'
                      : authUser.profile_type === 'researcher'
                      ? '/researcher/dashboard'
                      : '/doctor/profile'
                  }
                  data-testid="myaccount profile link"
                >
                  {formatMessage(messages.profile)}
                </Link>
              ) : (
                <Link to={'/'} data-testid="signin link">{`${formatMessage(
                  messages.signIn,
                )} | ${formatMessage(messages.signUp)}`}</Link>
              )}
            </MenuItem>
            {authUser.id && (
              <MenuItem>
                <Route
                  render={({ history }) => (
                    <span
                      onClick={() => {
                        const eventData = {
                          event: 'ga4_event',
                          properties: {
                            event_name: 'log_out',
                            user_type: authUser.profile_type || '',
                            patient_id:
                              authUser.profile_type === 'patient'
                                ? authUser.id
                                : '',
                            clinician_id:
                              authUser.profile_type === 'doctor'
                                ? authUser.id
                                : '',
                          },
                        };
                        addToDataLayer(eventData);
                        if (authState?.isAuthenticated) {
                          oktaAuth.signOut({
                            postLogoutRedirectUri: window.location.origin,
                          });
                        }
                        logout(dispatch);
                        history.push('/');
                      }}
                      data-testid="logout link"
                    >
                      {formatMessage(messages.logout)}
                    </span>
                  )}
                />
              </MenuItem>
            )}
          </Menu>
        </div>
      }
    </div>
  );
};

UserPopup.propTypes = {
  theme: PropTypes.oneOf(['white', 'default']),
};

UserPopup.defaultProps = {
  theme: 'default',
};

export default UserPopup;
