import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Cell, Grid } from 'react-foundation';

import useIntl from '../../../../../hooks/useIntl';

import Datepicker from '../../../../common/Datepicker/Datepicker';
import FieldDropdownWithInput from '../../../../common/fields/FieldDropdownWithInput';
import Dropdown from '../../../../common/inputs/Dropdown';
import FieldInput from '../../../../common/fields/FieldInput/FieldInput';
import Label from '../../../../common/Label';

import { default as customStyles } from '../../PersonalInfoForm/PersonalInfoForm.module.scss';
import messages from '../messages';

const LicenseForm = ({
  push,
  licenseValue,
  setLicenseValue,
  values,
  options,
  errors,
  customDropdownStyles,
  insuranceList,
}) => {
  const { formatMessage } = useIntl();
  const [uniqueKey, setUniqueKey] = useState(0);

  useEffect(() => {
    setLicenseValue();
  }, [setLicenseValue]);

  const handleChangeFormValue = (value, field) => {
    setLicenseValue({
      ...licenseValue,
      [field]: value,
    });
  };

  const addLicense = () => {
    push(licenseValue);
    setLicenseValue();
    setUniqueKey(uniqueKey + 1);
  };

  return (
    <fieldset className={customStyles['fieldset']}>
      <Grid medium={12}>
        <Cell small={12}>
          <FieldDropdownWithInput
            uniqueKey={uniqueKey}
            isSearchable
            isClearable
            label={formatMessage(messages.states)}
            onChange={(event) => {
              if (event === null) {
                handleChangeFormValue('', 'state');
                return;
              }
              handleChangeFormValue(event.value, 'state');
            }}
            value={licenseValue?.state}
            options={options}
            placeholder="Select or type here"
            dataTestId="ClinicianRegistration.States.Dropdown"
            customStyles={customDropdownStyles}
          />
        </Cell>
        <Cell small={12} medium={6}>
          <FieldInput
            label={formatMessage(messages.states.license_number)}
            placeholder="Enter here"
            value={licenseValue?.license_number}
            onChange={(e) =>
              handleChangeFormValue(e.target.value, 'license_number')
            }
            errorText={errors?.license_number}
            data-testid={`clinician license number ${
              values?.license_number || ''
            }`}
            error-testid={`clinician license number error ${
              values?.license_number || ''
            }`}
          />
        </Cell>
        <Cell small={12} medium={6}>
          <Datepicker
            label={formatMessage(messages.states.expiration_date)}
            selected={
              licenseValue?.expire_date ? licenseValue?.expire_date : null
            }
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            value={licenseValue?.expire_date}
            dropdownMode="select"
            minDate={new Date()}
            placeholderText="MM/DD/YYYY"
            onChange={(value) => handleChangeFormValue(value, 'expire_date')}
          />
        </Cell>
        <Cell small={12}>
          <Label>{formatMessage(messages.states.insurance_network)}</Label>
          <Dropdown
            // id={`clinic_licence_states[${index}].insurance_network`}
            options={insuranceList}
            isMulti
            value={(licenseValue?.insurance_network || []).map((insurance) => {
              return {
                label: insurance,
                value: insurance,
              };
            })}
            // name={`clinic_licence_states[${index}].insurance_network`}
            onChange={(selectedValues) => {
              handleChangeFormValue(
                selectedValues === null
                  ? []
                  : selectedValues.map((v) => v.value),
                'insurance_network',
              );
            }}
            classNamePrefix="select"
            placeholder="Select..."
            data-testid={`clinician insurance network autocomplete`}
          />
        </Cell>
        <Cell className={customStyles['centeredButton']}>
          <button
            disabled={
              !licenseValue ||
              Object.keys(licenseValue).length !== 4 ||
              Object.values(licenseValue).some((elem) => !elem)
            }
            type="button"
            className={customStyles['buttonAddItem']}
            onClick={addLicense}
            data-testid="add_item_License"
          >{` + Add Location `}</button>
        </Cell>
      </Grid>
    </fieldset>
  );
};

LicenseForm.defaultProps = {
  setFieldValue: () => {},
};
LicenseForm.propTypes = {
  push: PropTypes.func,
  licenseValue: PropTypes.object,
  setLicenseValue: PropTypes.func,
  setFieldValue: PropTypes.func,
  customDropdownStyles: PropTypes.object,
};

export default LicenseForm;
