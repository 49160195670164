import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.DoctorSquareCallbackPage.title',
    description: 'Doctor Square Connect Page title',
    defaultMessage: 'Square Connect',
  },
  squareaccountexists: {
    id: 'pages.DoctorSquareCallbackPage.squareaccountexists',
    description: 'Already have a Square Account',
    defaultMessage: 'If you already have a square account',
  },
  squareaccountnotexists: {
    id: 'pages.DoctorSquareCallbackPage.squareaccountnotexists',
    defaultMessage: "Don't have a Square Account",
    description: "If you don't have a square account",
  },
});
