import { Form, Formik } from 'formik';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Cell, Grid } from 'react-foundation';
import 'react-image-crop/dist/ReactCrop.css';
import { useHistory } from 'react-router-dom';

import { getLoggedInUser } from '../../../../api/auth';
import {
  addClinic,
  addWhiteLabelledClinic,
  getClinics,
  getInsuranceList,
  getInvitationData,
  saveClinicianRegistration,
  updateClinic,
} from '../../../../api/doctor';

import DynamicClinicText from '../../../../components/DynamicClinicText';
import {
  ACCOUNT_TYPE,
  CLINICIAN_REGISTRATION_STATUS,
} from '../../../../constants';
import AuthContext from '../../../../context/AuthContext';
import useIntl from '../../../../hooks/useIntl';
import { addToDataLayer } from '../../../../utils/common';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import SecondaryButton from '../../../common/buttons/SecondaryButton';
import Loading from '../../../common/Loading';
import { bpsCustomStyles } from '../BPSForm/BPSForm';
import { genderOptions } from '../PersonalInfoForm/data';
import styles from './ClinicianRegistration.module.scss';
import {
  ClinicianRegistrationStepOne,
  ClinicianRegistrationStepThree,
  ClinicianRegistrationStepTwo,
} from './ClinicianRegistrationSteps';
import data from './data.json';
import messages from './messages';

const ClinicianRegistration = () => {
  const clinicianInformation = {
    first_name: '',
    clinic_name: '',
    doctor_id: '',
    last_name: '',
    phone: '',
    contact_email: '',
    gender: '',
    preferred_pronouns: '',
    certification: [],
    states: [],
    network: '',
    work_type: '',
    parts_of_therapeutic: [],
    practiciane_email: '',
    city: '',
    state: '',
    treatment: [],
    therapy: [],
    description: '',
    profile_pic: '',
    clinic_id: null,
    license_status: '',
    license_number: '',
    currently_licence_states: [],
    clinic_licence_states: [],
    expire_date: '',
    bio: '',
    group: '',
    role: '',
    other_certification: '',
    telehealth: '',
    clinic_locations: [],
  };

  const { formatMessage } = useIntl();
  const history = useHistory();
  const formikRef = useRef();

  const { authUser, dispatch } = useContext(AuthContext);

  const [dataLoading, setLoading] = useState(false);
  const [fetchClinicIsLoading, setFetchClinicIsLoading] = useState(true);
  const [fetchInsuranceListIsLoading, setFetchInsuranceListIsLoading] =
    useState(true);
  const [
    getClinicInvitationDataIsLoading,
    setGetClinicInvitationDataIsLoading,
  ] = useState(true);
  const [showGroupName, setShowGroupName] = useState(false);
  const [profile, setProfile] = useState('');
  const [clinicOwner, setClinicOwner] = useState([]);
  const [insuranceList, setInsuranceList] = useState([]);
  const [invitedClinic, setInvitedClinic] = useState('');
  const [initialRegistrationValues, setInitialRegistrationValues] =
    useState(clinicianInformation);
  const [formStep, setFormStep] = useState(0);
  const [licenseValue, setLicenseValue] = useState();
  const [locationValue, setLocationValue] = useState();
  const [firstStepValidation, setFirstStepValidation] = useState({});
  const [secondStepValidation, setSecondStepValidation] = useState({});

  const { services } = data;

  const options = services.choices.sort((a, b) => (a.value > b.value ? 1 : -1));

  const fetchClinics = async () => {
    try {
      setFetchClinicIsLoading(true);
      if (authUser.id) {
        const clinicsData = await getClinics();
        const owner = clinicsData.clinics.filter(
          (element) => element.doctor_id === authUser.id,
        );
        if (owner) {
          setClinicOwner(owner);
        }
      }
      setFetchClinicIsLoading(false);
    } catch (err) {
      setFetchClinicIsLoading(false);
    }
  };

  const fetchInsuranceList = async () => {
    try {
      setFetchInsuranceListIsLoading(true);
      if (authUser.id) {
        const insurances = await getInsuranceList();
        const formattedInsurances = insurances.map((insurance) => {
          return {
            label: insurance.company_name,
            value: insurance.company_name,
          };
        });
        formattedInsurances.unshift({
          label: 'None',
          value: 'None',
        });
        setInsuranceList(formattedInsurances);
      }
      setFetchInsuranceListIsLoading(false);
    } catch (err) {
      setFetchInsuranceListIsLoading(false);
    }
  };

  const getClinicInvitationData = async () => {
    try {
      setGetClinicInvitationDataIsLoading(true);
      if (authUser.email) {
        const invitationData = await getInvitationData(authUser.email);

        setInvitedClinic(invitationData.clinic);

        const invitedClinicGroup = invitationData.clinic
          ? invitationData.clinic.user.isOwner
            ? ACCOUNT_TYPE.OWNER
            : ACCOUNT_TYPE.MEMBER
          : initialRegistrationValues.group;

        setInitialRegistrationValues((prev) => ({
          ...prev,
          first_name: authUser.first_name,
          last_name: authUser.last_name,
          phone: authUser.phone,
          contact_email: authUser.email,
          group: invitedClinicGroup,
        }));

        setGetClinicInvitationDataIsLoading(false);
      }
    } catch (error) {
      setGetClinicInvitationDataIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);

      /**
       * format values
       */
      const formattedValues = { ...values };

      if (licenseValue && Object.values(licenseValue).every(Boolean)) {
        if (!formattedValues.clinic_licence_states) {
          formattedValues.clinic_licence_states = [];
        }
        formattedValues.clinic_licence_states.push({
          state: licenseValue.state,
          license_number: licenseValue.license_number,
          expire_date: licenseValue.expire_date,
          insurance_network: licenseValue.insurance_network,
        });
      }

      if (locationValue) {
        if (!formattedValues.clinic_locations) {
          formattedValues.clinic_locations = [];
        }
        formattedValues.clinic_locations.push({
          city: locationValue.city,
          state: locationValue.state,
          country: locationValue.country,
        });
      }

      /**
       * we dont have invitedClinic if user self_registered
       * formattedValues.group === ACCOUNT_TYPE.NEWCLINIC if invited doctor by another doctor selects to create his own clinic
       * formattedValues.group === ACCOUNT_TYPE.MEMBER if invited doctor by another doctor prefers to be in invited doctor's clinic
       * formattedValues.group === ACCOUNT_TYPE.OWNER if invited doctor by an admin whose selected the doctor to be an owner of unclaim clinic
       */
      if (
        (!invitedClinic || formattedValues.group === ACCOUNT_TYPE.NEWCLINIC) &&
        formattedValues.clinic_name
      ) {
        const newClinic = await addClinic({
          clinic_name: formattedValues.clinic_name,
        });

        formattedValues.clinic_id = newClinic.id;
        formattedValues.clinic_name = newClinic.clinic_name;
        formattedValues.is_clinic_owner = true;
        formattedValues.work_type = 'Group(s)'; // seems to be redundant because now we dont create SOLO doctor
      }

      /**
       * prepare license data
       */
      if (formattedValues.license_status === 'no') {
        formattedValues.clinic_licence_states = null;
        formattedValues.states.push('not licensed');
      } else {
        formattedValues.clinic_licence_states =
          formattedValues.clinic_licence_states.map((element) => {
            return {
              state: element.state,
              license_number: element.license_number,
              expire_date: element.expire_date,
              insurance_network: element.insurance_network,
            };
          });
        formattedValues.states = formattedValues?.currently_licence_states.map(
          (state) => state.value,
        );
      }
      delete formattedValues.currently_licence_states;

      formattedValues.profile_pic = profile;
      formattedValues.doctor_id = authUser.id;

      if (invitedClinic && formattedValues.group === ACCOUNT_TYPE.MEMBER) {
        formattedValues.clinic_id = invitedClinic.id;
        formattedValues.clinic_name = invitedClinic.clinic_name;
        formattedValues.is_clinic_owner = false;
        formattedValues.work_type = 'Group(s)'; // seems to be redundant because now we dont create SOLO doctor
      }
      // if (formattedValues.group === ACCOUNT_TYPE.NEWCLINIC) {
      //   if (clinicOwner.length > 0) {
      //     formattedValues.clinic_name = clinicOwner[0].clinic_name;
      //     formattedValues.clinic_id = clinicOwner[0].id;
      //     formattedValues.is_clinic_owner = true;
      //     formattedValues.work_type = 'Group(s)';
      //   } else {
      //     setShowAddClinic(true);
      //   }
      // }
      // if (formattedValues.group === ACCOUNT_TYPE.SOLO) {
      //   formattedValues.clinic_name = null;
      //   formattedValues.clinic_id = 0;
      //   formattedValues.is_clinic_owner = false;
      //   formattedValues.work_type = 'Solo';
      // }
      if (invitedClinic && formattedValues.group === ACCOUNT_TYPE.OWNER) {
        formattedValues.clinic_id = invitedClinic.id;
        formattedValues.clinic_name = invitedClinic.clinic_name;
        formattedValues.is_clinic_owner = true;
        formattedValues.work_type = 'Group(s)'; // seems to be redundant because now we dont create SOLO doctor
      }

      !formattedValues.role && (formattedValues.role = 'clinician');

      if (invitedClinic && formattedValues.group === ACCOUNT_TYPE.OWNER) {
        await updateClinic(invitedClinic.id, { doctor_id: authUser.id });
      }

      const clinicianRegistration = await saveClinicianRegistration(
        formattedValues,
      );

      if (
        clinicianRegistration?.data === CLINICIAN_REGISTRATION_STATUS.PENDING
      ) {
        history.push(`/approval/${clinicianRegistration.clinic_name}`);
      } else if (
        clinicianRegistration?.data === CLINICIAN_REGISTRATION_STATUS.DISABLE
      ) {
        history.push(`/disable/${clinicianRegistration.clinic_name}`);
      } else if (
        clinicianRegistration?.data === CLINICIAN_REGISTRATION_STATUS.ENABLE
      ) {
        history.push('/doctor/my-account');
      } else {
        history.push('/');
      }

      await getLoggedInUser(dispatch);

      setLoading(false);

      const eventData = {
        event: 'ga4_event',
        properties: {
          event_name: 'clinician_registration',
          user_id: authUser.id || '',
        },
      };
      addToDataLayer(eventData);
    } catch (err) {
      const eventData = {
        event: 'ga4_event',
        properties: {
          event_name: 'error',
          user_id: authUser.id || '',
          click_text: 'Error while clinician registration',
          details: (err.response && err.response.data.message) || '',
          user_type: 'clinician',
          site_section: 'Clinician Registration',
        },
      };
      addToDataLayer(eventData);

      setLoading(false);
    }
  };

  // Add db custom values to the input dropdown options.
  const addCustomDropdownOption = (options, value) => {
    if (value && !options.some((el) => el.value === value)) {
      options.push({
        label: value,
        value: value,
      });
    }
  };

  const nextStep = () => {
    setFormStep(formStep + 1);
  };
  const prevStep = () => {
    setFormStep(formStep - 1);
  };

  useEffect(() => {
    if (authUser) {
      fetchClinics();
      fetchInsuranceList();
      getClinicInvitationData();
    }
  }, [authUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [formStep]);

  const isLoading =
    dataLoading ||
    fetchClinicIsLoading ||
    fetchInsuranceListIsLoading ||
    getClinicInvitationDataIsLoading;

  return (
    <div className={styles.root}>
      {isLoading && <Loading />}
      <Fragment>
        <div className={styles.form}>
          <Grid>
            <Cell small={12}>
              <div style={bpsCustomStyles.paginationContainer}>
                <h2 className={styles.formTitle}>
                  <DynamicClinicText /> {formatMessage(messages.formTitle)}
                </h2>
                <p style={bpsCustomStyles.pageNumber}>{`Page ${
                  formStep + 1
                } of 3`}</p>
              </div>
            </Cell>
            <Cell small={12} medium={12}>
              <h4
                className={styles.formInfo}
                data-testid="clinician registration description"
              >
                {formatMessage(messages.formWelcomeLabel)} <DynamicClinicText />
                {formatMessage(messages.formInfo)}
              </h4>
            </Cell>
          </Grid>
          <Formik
            enableReinitialize
            initialValues={initialRegistrationValues}
            onSubmit={handleSubmit}
            innerRef={formikRef}
          >
            {({ values, touched, handleChange, handleBlur, setFieldValue }) => {
              const handleMultipleChoices = (arrOfItems, arrOfValues) => {
                arrOfItems = [];
                arrOfValues.map((item) => {
                  const itemIndex = arrOfItems.indexOf(item.value);
                  if (itemIndex === -1) {
                    arrOfItems.push(item.value);
                  }
                });
                return arrOfItems;
              };

              addCustomDropdownOption(genderOptions, values.gender);

              const clinicianRegistrationSteps = [
                ClinicianRegistrationStepOne,
                ClinicianRegistrationStepTwo,
                ClinicianRegistrationStepThree,
              ].map((FormStep, i) => (
                <FormStep
                  key={i}
                  isLoading={isLoading}
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  invitedClinic={invitedClinic}
                  setLoading={setLoading}
                  setShowGroupName={setShowGroupName}
                  clinicOwner={clinicOwner}
                  showGroupName={showGroupName}
                  fetchClinics={fetchClinics}
                  options={options}
                  insuranceList={insuranceList}
                  authUser={authUser}
                  dispatch={dispatch}
                  setProfile={setProfile}
                  handleMultipleChoices={handleMultipleChoices}
                  licenseValue={licenseValue}
                  setLicenseValue={setLicenseValue}
                  locationValue={locationValue}
                  setLocationValue={setLocationValue}
                  firstStepValidation={firstStepValidation}
                  setFirstStepValidation={setFirstStepValidation}
                  touched={touched}
                  secondStepValidation={secondStepValidation}
                  setSecondStepValidation={setSecondStepValidation}
                />
              ));

              return (
                <Form>
                  <Grid>
                    {clinicianRegistrationSteps[formStep]}
                    <Cell medium={12} className={styles.buttonsContainer}>
                      {formStep > 0 && (
                        <div style={bpsCustomStyles.leftButtonStyles}>
                          <SecondaryButton
                            type="button"
                            label={'Back'}
                            onClick={prevStep}
                            className="font-18"
                          />
                        </div>
                      )}
                      {formStep === 0 && values.role !== ACCOUNT_TYPE.ADMIN && (
                        <PrimaryButton
                          disabled={Object.values(firstStepValidation).some(
                            (v) => !!v,
                          )}
                          type="button"
                          label={'Next'}
                          onClick={nextStep}
                          className="font-18"
                        />
                      )}
                      {formStep === 0 && values.role === ACCOUNT_TYPE.ADMIN && (
                        <PrimaryButton
                          disabled={Object.values(firstStepValidation).some(
                            (v) => v,
                          )}
                          label={'Submit'}
                          type="submit"
                          className="font-18"
                        />
                      )}
                      {formStep === 1 && values.role !== ACCOUNT_TYPE.ADMIN && (
                        <PrimaryButton
                          disabled={Object.values(secondStepValidation).some(
                            (v) => v,
                          )}
                          type="button"
                          label={'Next'}
                          onClick={nextStep}
                          className="font-18"
                        />
                      )}
                      {formStep === clinicianRegistrationSteps.length - 1 && (
                        <PrimaryButton
                          disabled={
                            Object.values(firstStepValidation).some((v) => v) ||
                            Object.values(secondStepValidation).some((v) => v)
                          }
                          label={'Submit'}
                          type="submit"
                          className="font-18"
                        />
                      )}
                    </Cell>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Fragment>
      )}
    </div>
  );
};

export default ClinicianRegistration;
