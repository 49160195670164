import React, { useEffect } from 'react';
import { Cell } from 'react-foundation';
import useIntl from '../../../../../hooks/useIntl';
import FieldDropdown from '../../../../common/fields/FieldDropdown';
import FieldInput from '../../../../common/fields/FieldInput';
import RadioGroupWithLabel from '../../../../common/inputs/RadioGroupWithLabel';
import Loading from '../../../../common/Loading';
import { customDropdownStyles } from '../../PersonalInfoForm/PersonalInfoForm';
import styles from '../../PersonalInfoForm/PersonalInfoForm.module.scss';
import { binaryResponses, legalStatuses } from '../data';
import messages from '../messages';

const BPSFormStepSeven = ({
  values,
  handleChange,
  setFieldValue,
  handleBlur,
  isLoading,
}) => {
  const { formatMessage } = useIntl();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Cell small={12}>
        <h4 className={styles.section}>
          {formatMessage(messages.legalHistory)}
        </h4>
      </Cell>
      <hr className={styles.form.hr} />
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(messages.legalHistory.legalInvolvement)}
          componentName="legal_involvement"
          options={binaryResponses}
          dataTestId="BPSFormStepSeven.LegalHistory.LegalInvolvement.RadioGroup"
          labelTestId="BPSFormStepSeven.LegalHistory.LegalInvolvement.Label"
          value={values.legal_involvement}
          onChange={(value) => setFieldValue('legal_involvement', value)}
        />
      </Cell>
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.legalHistory.otherListLabel)}
          placeholder="Enter here"
          name="other_legal_involvement"
          value={values.other_legal_involvement}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepSeven.LegalHistory.OtherLegalInvolvement.Input"
        />
      </Cell>
      <Cell small={12}>
        <FieldDropdown
          label={formatMessage(messages.legalHistory.legalStatus)}
          name="legal_status"
          placeholder="Select here"
          value={values.legal_status}
          onChange={(e) => {
            setFieldValue('legal_status', e.target.value);
          }}
          options={legalStatuses}
          onBlur={handleBlur}
          dataTestId="BPSFormStepSeven.legalHistory.LegalStatus.Dropdown"
          customStyles={customDropdownStyles}
        />
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(messages.legalHistory.mentalHealthTreatment)}
          componentName="mental_health_treatment"
          options={binaryResponses}
          dataTestId="BPSFormStepSeven.LegalHistory.MentalHealthTreatment.RadioGroup"
          labelTestId="BPSFormStepSeven.LegalHistory.MentalHealthTreatment.Label"
          value={values.mental_health_treatment}
          onChange={(value) => setFieldValue('mental_health_treatment', value)}
        />
      </Cell>
      <Cell small={12}>
        <h4 className={styles.section}>
          {formatMessage(messages.culturalHistory)}
        </h4>
      </Cell>
      <hr className={styles.form.hr} />
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(messages.culturalHistory.religionRole)}
          componentName="religion_role"
          options={binaryResponses}
          dataTestId="BPSFormStepSeven.CulturalHistory.ReligionRole.RadioGroup"
          labelTestId="BPSFormStepSeven.CulturalHistory.ReligionRole.Label"
          value={values.religion_role}
          onChange={(value) => setFieldValue('religion_role', value)}
        />
      </Cell>
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.culturalHistory.religionPractices)}
          placeholder="Enter here"
          name="religion_practices"
          value={values.religion_practices}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepSeven.CulturalHistory.ReligionPractices.Input"
        />
      </Cell>
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.culturalHistory.racialIdentity)}
          placeholder="Enter here"
          name="racial_identity"
          value={values.racial_identity}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepSeven.CulturalHistory.RacialIdentity.Input"
        />
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(messages.culturalHistory.specificCulture)}
          componentName="specific_culture"
          options={binaryResponses}
          dataTestId="BPSFormStepSeven.CulturalHistory.SpecificCulture.RadioGroup"
          labelTestId="BPSFormStepSeven.CulturalHistory.SpecificCulture.Label"
          value={values.specific_culture}
          onChange={(value) => setFieldValue('specific_culture', value)}
        />
      </Cell>
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.culturalHistory.hobbies)}
          placeholder="Enter here"
          name="hobbies"
          value={values.hobbies}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepSeven.CulturalHistory.Hobbies.Input"
        />
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(messages.culturalHistory.mentalHealthResult)}
          componentName="mental_health_result"
          options={binaryResponses}
          dataTestId="BPSFormStepSeven.CulturalHistory.MentalHealthResult.RadioGroup"
          labelTestId="BPSFormStepSeven.CulturalHistory.MentalHealthResult.Label"
          value={values.mental_health_result}
          onChange={(value) => setFieldValue('mental_health_result', value)}
        />
      </Cell>
      <Cell small={12}>
        <h4 className={styles.section}>{formatMessage(messages.assessment)}</h4>
      </Cell>
      <hr className={styles.form.hr} />
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.assessment.therapyGoals)}
          placeholder="Enter here"
          name="therapy_goals"
          value={values.therapy_goals}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepSeven.Assessment.TherapyGoals.Input"
        />
      </Cell>
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.assessment.possibleBarriers)}
          placeholder="Enter here"
          name="possible_barriers"
          value={values.possible_barriers}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepSeven.Assessment.PossibleBarriers.Input"
        />
      </Cell>

      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.assessment.therapyNeeds)}
          placeholder="Enter here"
          name="therapy_needs"
          value={values.therapy_needs}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepSeven.Assessment.TherapyNeeds.Input"
        />
      </Cell>
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.assessment.currentStrengths)}
          placeholder="Enter here"
          name="current_strengths"
          value={values.current_strengths}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepSeven.Assessment.currentStrengths.Input"
        />
      </Cell>
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.assessment.therapistAdvice)}
          placeholder="Enter here"
          name="therapist_advice"
          value={values.therapist_advice}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepSeven.Assessment.TherapistAdvice.Input"
        />
      </Cell>
    </>
  );
};

BPSFormStepSeven.propTypes = {};

BPSFormStepSeven.defaultProps = {};

export default BPSFormStepSeven;
