import { doPost } from '../fetch';

const saveRecommendationAnswers = (recommendation) =>
  doPost('/recommendations-engine/answers', recommendation, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

export { saveRecommendationAnswers };
