import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';

import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import FieldInput from '../../components/common/fields/FieldInput/FieldInput';
import Modal from '../../components/common/Modal';
import Loading from '../../components/common/Loading';
import UserExists from '../../components/UserExists';
import Logo from '../../components/Logo';

import { addToDataLayer } from '../../utils/common';
import { emailValidator } from '../../utils/validators';

import { checkUser, initialDoctorSignup } from '../../api/auth';
import styles from './DoctorInitialSignupPage.module.scss';

const DoctorInitialSignupPage = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showEmailExistsModal, setShowEmailExistsModal] = useState(false);

  const handleSignUp = async (values) => {
    try {
      setIsLoading(true);

      await initialDoctorSignup({
        first_name: values.first_name,
        email: values.email,
      });

      const eventData = {
        event: 'ga4_event',
        properties: {
          event_name: 'Initial Doctor Signup',
          method: 'Onsite Form',
        },
      };
      addToDataLayer(eventData);

      setIsLoading(false);

      history.push('/confirmation');
    } catch (err) {
      const eventData = {
        event: 'ga4_event',
        properties: {
          event_name: 'error',
          user_id: '',
          click_text: 'Error while user sign up',
          details: err?.message || '',
          user_type: '',
          site_section: 'DoctroInitialSignup',
        },
      };
      addToDataLayer(eventData);

      setIsLoading(false);
    }
  };

  const checkUserEmail = async (values, setSubmitting) => {
    try {
      let existingUser = await checkUser(values.email);
      if (existingUser?.message === 'success') {
        setShowEmailExistsModal(true);
        setSubmitting(false);

        return;
      }
    } catch (err) {
      if (err?.response?.data?.message === 'user_not_found') {
        setSubmitting(false);
        handleSignUp(values);
      } else {
        const eventData = {
          event: 'ga4_event',
          properties: {
            event_name: 'error',
            user_id: '',
            click_text: 'Error while user sign up',
            details: err?.message || '',
            user_type: '',
            site_section: 'DoctroInitialSignup',
          },
        };
        addToDataLayer(eventData);

        setSubmitting(false);
      }
    }
  };

  return (
    <React.Fragment>
      {showEmailExistsModal && (
        <Modal className={styles.emailExistsModal}>
          <UserExists onClose={() => setShowEmailExistsModal(false)} />
        </Modal>
      )}
      <div className={styles.root}>
        {isLoading ? (
          <Loading />
        ) : (
          <div className={styles.container}>
            <div>
              <Logo />
            </div>
            <div className={styles.content}>
              <Formik
                initialValues={{
                  first_name: '',
                  email: '',
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.first_name) {
                    errors.first_name = 'Field Required';
                  }
                  if (!values.email) {
                    errors.email = 'Field Required';
                  } else if (!!emailValidator(values.email.trim()).email) {
                    errors.email = 'Invalid email address';
                  }
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  checkUserEmail(values, setSubmitting);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <h4 data-testid="login header">
                      Sign Up to Create an Account on Eve
                    </h4>
                    <>
                      <FieldInput
                        className={styles.userInput}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name}
                        label="First name"
                        errorText={touched.first_name && errors.first_name}
                        name="first_name"
                        data-testid="first_name"
                        label-testid="first_name label"
                        error-testid="first_name error"
                      />

                      <FieldInput
                        className={styles.userInput}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        label="Email address"
                        errorText={touched.email && errors.email}
                        name="email"
                        data-testid="email"
                        label-testid="email label"
                        error-testid="email error"
                      />

                      <div className={styles.bottom}>
                        <div className={styles.nextBtn}>
                          <PrimaryButton
                            disabled={isSubmitting}
                            className={styles.primaryBtn}
                            label="Sign Up"
                            data-testid="Signup button"
                          />
                        </div>
                        <div className={styles.bottomLinks}>
                          <Link to={`/`}>Login to existing account</Link>
                        </div>
                      </div>
                    </>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default DoctorInitialSignupPage;
