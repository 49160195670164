import { Field } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Cell, Grid } from 'react-foundation';
import Places from '../../../../../components/Places';
import useIntl from '../../../../../hooks/useIntl';
import Label from '../../../../common/Label';
import customDropdownStyles from '../../PersonalInfoForm/PersonalInfoForm';
import customStyles from '../../PersonalInfoForm/PersonalInfoForm.module.scss';
import styles from '../ClinicianRegistration.module.scss';
import messages from '../messages';

const LocationForm = ({
  push,
  locationValue,
  setLocationValue,
  handleBlur,
}) => {
  const { formatMessage } = useIntl();
  const [locationInput, setLocationInput] = useState();

  useEffect(() => {
    setLocationValue();
  }, [setLocationValue]);

  const handleChangeFormValue = (value) => {
    setLocationValue(value);
  };

  const addLocation = () => {
    push(locationValue);
    setLocationValue();
  };

  const extractData = (address) => {
    if (!address) return;
    const result = {};
    address.map((item) => {
      if (item.types.includes('locality')) {
        result.city = item.long_name;
      } else if (item.types.includes('administrative_area_level_1')) {
        result.state = item.long_name;
      } else if (item.types.includes('country')) {
        result.country = item.long_name;
      }
    });
    return result;
  };

  return (
    <fieldset className={customStyles['fieldset']}>
      <Grid medium={12}>
        <Cell small={12}>
          <Label>{formatMessage(messages.locationLabel)}</Label>
          <Field
            style={customDropdownStyles}
            component={(field) => {
              return <Places {...field} />;
            }}
            customInputStyles={styles.input}
            submitButton={false}
            placeholder="Enter here"
            onPlaceLoaded={(e) => {
              const data = extractData(e.address_components);
              handleChangeFormValue(data);
              setLocationInput(e.formatted_address);
            }}
            value={locationValue ? locationInput : ''}
            onBlur={handleBlur}
            data-testid="clinician location"
            label-testid="clinician location label"
            error-testid="clinician location error"
          />
        </Cell>
        <Cell className={customStyles['centeredButton']}>
          <button
            disabled={
              !locationValue ||
              Object.values(locationValue).some((elem) => !elem)
            }
            type="button"
            className={customStyles['buttonAddItem']}
            onClick={addLocation}
            data-testid="add_item_Location"
          >{` + Add Location `}</button>
        </Cell>
      </Grid>
    </fieldset>
  );
};

LocationForm.propTypes = {
  push: PropTypes.func,
  locationValue: PropTypes.object,
  setLocationValue: PropTypes.func,
  customDropdownStyles: PropTypes.object,
};

export default LocationForm;
