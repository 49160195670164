import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.calendlyAvailabilityPage.title',
    description: 'Page title',
    defaultMessage: 'Edit Availability',
  },
  subTitle: {
    id: 'pages.calendlyAvailabilityPage.subTitle',
    description: 'Page sub title',
    defaultMessage:
      'These steps describe how to generate and access your authentication token in Calendly',
  },
  stepOne: {
    id: 'pages.calendlyAvailabilityPage.stepOne',
    description: 'Page step one',
    defaultMessage: '1. Log in to Calendly',
  },
  stepTwo: {
    id: 'pages.calendlyAvailabilityPage.stepTwo',
    description: 'Page step two',
    defaultMessage: '2. Navigate to the Integrations page',
  },
  stepThree: {
    id: 'pages.calendlyAvailabilityPage.stepThree',
    description: 'Page step three',
    defaultMessage: '3. Copy your API Key',
  },
  nextBtnLabel: {
    id: 'pages.calendlyAvailabilityPage.nextBtnLabel',
    description: 'Next button lable',
    defaultMessage: 'Next',
  },
  backBtnLabel: {
    id: 'pages.calendlyAvailabilityPage.backBtnLabel',
    description: 'Back button lable',
    defaultMessage: 'Back',
  },
  apiKeyDesc: {
    id: 'pages.calendlyAvailabilityPage.apiKeyDesc',
    defaultMessage: 'On the Integrations page, you will find your API Key!',
    description: 'API key step description',
  },
  apiKeyPlaceholder: {
    id: 'pages.calendlyAvailabilityPage.apiKeyPlaceholder',
    description: 'API key placeholder',
    defaultMessage: 'Enter your Calendly API key',
  },
  chooseEventType: {
    id: 'pages.calendlyAvailabilityPage.chooseEventType',
    description: 'Choose event type label',
    defaultMessage: 'Choose event type',
  },
  saveBtnLabel: {
    id: 'pages.calendlyAvailabilityPage.saveBtnLabel',
    description: 'Save button lable',
    defaultMessage: 'Save',
  },
});
