import { doGet, doPost, doPut } from '../fetch';

const getResearcherInvitationData = async (email) => {
  const researcherList = await doGet(
    `/b2c/researcher/invitation-details/${email}`,
  );
  return researcherList;
};

const addLab = async (body) => {
  const labs = await doPost(`/b2c/researcher/add-lab`, body);
  return labs;
};

const getLabs = async () => {
  const labs = await doGet('/b2c/researcher/labs');
  return labs;
};

const updateLab = async (id, body) => {
  const labs = await doPut(`/b2c/researcher/lab/${id}`, body);
  return labs;
};

const saveResearcherRegistration = async (body) => {
  const researcher = await doPost(
    `/b2c/researcher/researcher-registration`,
    body,
  );
  return researcher;
};

const getResearcherRegistrationData = async (id) => {
  const researcher = await doGet(
    `/b2c/researcher/researcher-registration/${id}`,
  );
  return researcher;
};

const getResearcher = async (id) => {
  const researcher = await doGet(`/b2c/researcher/researcher/${id}`);
  return researcher;
};

const inviteResearcher = async (body) => {
  const researcher = await doPost('/b2c/invite-researcher', body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return researcher;
};

const getLabFromSubdomain = async (domain) => {
  const lab = await doGet(`/b2c/researcher/lab-by-domain/${domain}`);
  return lab;
};

const getLab = async () => {
  const labs = await doGet('/b2c/researcher/lab');
  return labs;
};

export {
  getResearcherInvitationData,
  addLab,
  getLabs,
  updateLab,
  saveResearcherRegistration,
  getResearcherRegistrationData,
  getResearcher,
  inviteResearcher,
  getLabFromSubdomain,
  getLab,
};
