import React from 'react';
import HeaderSection from './components/HeaderSection';
import { Page, Document, Text, View } from '@react-pdf/renderer';
import { AssessmentPDFStyles } from './AssessmentPDFStyles';

const IntakeFormPDF = ({ item, formDetail }) => {
  return (
    <Document>
      <Page size="A4" style={AssessmentPDFStyles.page} wrap={true}>
        <HeaderSection item={item} formTypeTitle="Assessment" />
        {formDetail && formDetail.length ? (
          formDetail.map((intakeValue, index) => {
            if (intakeValue.isFieldArray) {
              return (
                <View>
                  <Text style={AssessmentPDFStyles.content_detail.span}>
                    {intakeValue.key}
                  </Text>
                  <View style={AssessmentPDFStyles.detail}>
                    <View style={AssessmentPDFStyles.tableIntake}>
                      <View style={AssessmentPDFStyles.tableIntake.table_tr}>
                        {intakeValue.tableHeads.map((element, index) => (
                          <View
                            style={
                              AssessmentPDFStyles.tableIntake.table_tr.trDiv
                            }
                            key={index}
                          >
                            <Text
                              styles={
                                AssessmentPDFStyles.tableIntake.table_tr.trP
                              }
                            >
                              {element.title}
                            </Text>
                          </View>
                        ))}
                      </View>
                      <View style={AssessmentPDFStyles.tableIntake.table_td}>
                        {intakeValue.rows.map((item, index) => (
                          <View
                            style={
                              AssessmentPDFStyles.tableIntake.table_td.tdRow
                            }
                            key={index}
                          >
                            {intakeValue.tableHeads.map((element) => {
                              return (
                                <View
                                  style={
                                    AssessmentPDFStyles.tableIntake.table_td
                                      .tdDiv
                                  }
                                  key={index}
                                >
                                  <Text
                                    styles={
                                      AssessmentPDFStyles.tableIntake.table_td
                                        .tdP
                                    }
                                  >
                                    {item[element.key]}
                                  </Text>
                                </View>
                              );
                            })}
                          </View>
                        ))}
                      </View>
                    </View>
                  </View>
                </View>
              );
            } else {
              return (
                <View key={index}>
                  <Text style={AssessmentPDFStyles.content_detail.span}>
                    {intakeValue.key}
                  </Text>
                  <Text style={AssessmentPDFStyles.content_detail.p}>
                    {intakeValue.value}
                  </Text>
                </View>
              );
            }
          })
        ) : (
          <>
            <View>
              <Text>No Data Available</Text>
            </View>
          </>
        )}
      </Page>
    </Document>
  );
};

export default IntakeFormPDF;
