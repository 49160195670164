import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../inputs/TextInput';
import Label from '../../Label';
import styles from '../../Label/Label.module.scss';

const FieldInput = ({
  disabled,
  label,
  errorText,
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  type,
  className,
  'data-testid': datatestid,
  autoComplete,
  autoFocus,
  'label-testid': labeltestid,
  'error-testid': errortestid,
}) => {
  return (
    <div>
      <Label
        label-testid={labeltestid}
        error-testid={errortestid}
        errorText={errorText}
        htmlFor={name}
        dataTestId={datatestid}
      >
        {label}
      </Label>
      <TextInput
        value={value}
        onChange={onChange}
        type={type}
        data-testid={datatestid}
        className={className}
        onBlur={onBlur}
        name={name}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        placeholder={placeholder}
        disabled={disabled}
      />
      <div
        className={styles['error-label']}
        data-testid={`${datatestid}.Error`}
      >
        {errorText}
      </div>
    </div>
  );
};

FieldInput.propTypes = {
  label: PropTypes.string,
  errorText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['email', 'text', 'password', 'number']),
  value: PropTypes.string,
};

FieldInput.defaultProps = {
  type: 'text',
  value: '',
  label: null,
};

export default FieldInput;
