import React, { useState, Fragment, useContext } from 'react';
import styles from './AdminPage.module.scss';
import classnames from 'classnames';
import Loading from '../../../components/common/Loading';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import AdminLeftNav from '../../../components/nav/AdminLeftNav';
import AuthContext from '../../../context/AuthContext';
import { createClinicDomain } from '../../../api/user';
import { Formik, Form } from 'formik';
import { Cell, Grid } from 'react-foundation';
import FieldInput from '../../../components/common/fields/FieldInput/FieldInput';
import * as Yup from 'yup';
import Dropzone from 'react-dropzone';
import Label from '../../../components/common/Label';
import MessageModal from '../../../components/MessageModal';

const ClinicSchema = Yup.object().shape({
  clinic_name: Yup.string().required('Field Required'),
  sub_domain: Yup.string().required('Field Required'),
});

const AdminPage = () => {
  const { isLoading } = useContext(AuthContext);
  const [dataLoading, setLoading] = useState(false);
  const isDataLoading = dataLoading || isLoading;
  const [logo, setLogo] = useState('');
  const [logoError, setLogoError] = useState('');
  const [file, setFile] = useState('');
  const [footerLogo, setFooterLogo] = useState('');
  const [footerLogoError, setFooterLogoError] = useState('');
  const [footerFile, setFooterFile] = useState('');
  const [initialValues, setInitialValues] = useState({
    clinic_name: '',
    sub_domain: '',
  });
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [displayMessage, setDisplayMessae] = useState({
    title: '',
    message: '',
  });

  const onDrop = async (files, fileRejections) => {
    const reader = new FileReader();
    reader.onload = async () => {
      setLogo(reader.result);
      setLogoError('');
    };
    if (files.length) {
      reader.readAsDataURL(files[0]);
      setFile(files[0]);
    } else {
      fileRejections.forEach((f) => {
        f.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            setLogoError('File is too large');
          }
          if (err.code === 'file-invalid-type') {
            setLogoError('File type not accepted.');
          }
        });
      });
    }
  };

  const onFooterLogoDrop = async (files, fileRejections) => {
    const reader = new FileReader();
    reader.onload = async () => {
      setFooterLogo(reader.result);
      setFooterLogoError('');
    };
    if (files.length) {
      reader.readAsDataURL(files[0]);
      setFooterFile(files[0]);
    } else {
      fileRejections.forEach((f) => {
        f.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            setFooterLogoError('File is too large');
          }
          if (err.code === 'file-invalid-type') {
            setFooterLogoError('File type not accepted.');
          }
        });
      });
    }
  };
  const handleOnSubmit = async (values) => {
    if (!logo) {
      setLogoError('Please upload a valid logo.');
    } else if (!footerLogo) {
      setFooterLogoError('Please upload a valid logo.');
    } else {
      setLoading(true);
      try {
        const data = new FormData();
        data.append('files', file);
        data.append('files', footerFile);
        data.append('clinic_name', values.clinic_name);
        data.append('sub_domain', values.sub_domain);
        await createClinicDomain(data);
        setInitialValues({
          clinic_name: '',
          sub_domain: '',
        });
        setLogo('');
        setFile('');
        setFooterLogo('');
        setFooterFile('');
        setLoading(false);
        setDisplayMessae({
          type: 'success',
          title: 'Clinic Created successfully',
          text: '',
        });
        setShowMessageModal(true);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setDisplayMessae({
          type: 'success',
          title: '',
          text: error,
        });
        setShowMessageModal(true);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <AdminLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div className={styles.header}>
              <h1>Create Clinic</h1>
            </div>
            <div className={styles.formData}>
              <Formik
                initialValues={{ ...initialValues }}
                validationSchema={ClinicSchema}
                onSubmit={handleOnSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <Form>
                      <Grid>
                        <Cell small={12}>
                          <FieldInput
                            autoComplete="clinic_name"
                            autoFocus={true}
                            label="Clinic Name"
                            errorText={
                              touched.clinic_name && errors.clinic_name
                            }
                            name="clinic_name"
                            value={values.clinic_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            data-testid="clinic_name"
                          />
                        </Cell>
                        <Cell small={12}>
                          <FieldInput
                            autoComplete="sub_domain"
                            label="Sub Domain"
                            errorText={touched.sub_domain && errors.sub_domain}
                            name="sub_domain"
                            onChange={handleChange}
                            type="text"
                            onBlur={handleBlur}
                            value={values.sub_domain}
                            data-testid="sub_domain"
                          />
                        </Cell>
                        <Cell small={12}>
                          <Label errorText={logoError} htmlFor={logo}>
                            Logo
                          </Label>
                          <div className={styles.logoImage}>
                            {logo && <img src={logo} alt="logo" />}
                          </div>
                        </Cell>
                        <Cell small={12}>
                          <Dropzone
                            onDrop={onDrop}
                            accept="image/*"
                            maxSize={2000000}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <PrimaryButton
                                  label="Upload"
                                  className="font-18"
                                  type="button"
                                />
                              </div>
                            )}
                          </Dropzone>
                        </Cell>
                        <Cell small={12}>
                          <Label errorText={footerLogoError} htmlFor={logo}>
                            Footer Logo
                          </Label>
                          <div className={styles.footerlogoImage}>
                            {footerLogo && (
                              <img src={footerLogo} alt="footerLogo" />
                            )}
                          </div>
                        </Cell>
                        <Cell small={12}>
                          <Dropzone
                            onDrop={onFooterLogoDrop}
                            accept="image/*"
                            maxSize={2000000}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <PrimaryButton
                                  label="Upload"
                                  className="font-18"
                                  type="button"
                                />
                              </div>
                            )}
                          </Dropzone>
                        </Cell>
                        <Cell small={12}>
                          <PrimaryButton
                            label="Save"
                            className={classnames(styles.saveButton, 'font-18')}
                            data-testid="SaveButton"
                            disabled={Object.entries(errors).length > 0}
                          />
                        </Cell>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </Fragment>
      )}
      {showMessageModal && (
        <MessageModal
          message={displayMessage}
          onCloseModal={() => setShowMessageModal(false)}
        />
      )}
    </div>
  );
};

export default AdminPage;
