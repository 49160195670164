import React from 'react';
import { Page, Document, Text, View } from '@react-pdf/renderer';
import HeaderSection from './components/HeaderSection';
import { AssessmentPDFStyles } from './AssessmentPDFStyles';

const AssessmentPDF2 = ({ item, formassessments }) => (
  <Document>
    <Page size="A4" style={AssessmentPDFStyles.page} wrap={true}>
      <HeaderSection item={item} formTypeTitle="Assessment" />
      <View style={AssessmentPDFStyles.content_detail}>
        {formassessments && formassessments.length ? (
          formassessments.map((assessment, index) => (
            <View key={index}>
              <Text style={AssessmentPDFStyles.content_detail.span}>
                {assessment.key}
              </Text>
              <Text style={AssessmentPDFStyles.content_detail.p}>
                {assessment.value}
              </Text>
            </View>
          ))
        ) : (
          <View>
            <Text style={AssessmentPDFStyles.content_detail.h2}>
              No Data Available
            </Text>
          </View>
        )}
      </View>
    </Page>
  </Document>
);

export default AssessmentPDF2;
