import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'component.waitListForm.title',
    description: 'The label for title',
    defaultMessage: 'Interested in joining our waitlist?',
  },
  invalidEmail: {
    id: 'component.waitListForm.invalid',
    description: 'The label when there is any invalid field',
    defaultMessage: 'The email you have entered is invalid',
  },
  required: {
    id: 'component.waitListForm.required',
    description: 'The label is for required field',
    defaultMessage: 'The this Field is required',
  },
  emailPlaceholder: {
    id: 'component.waitListForm.emailPlaceholder',
    description: 'The label for email field placeholder',
    defaultMessage: 'Email',
  },
  submitInBtn: {
    id: 'component.waitListForm.submitInBtn',
    description: 'The label for submit in button',
    defaultMessage: 'Submit',
  },
  backToSignInBtn: {
    id: 'component.waitListForm.backToSignInBtn',
    description: 'The label for back to sign in page',
    defaultMessage: 'Back to Sign In',
  },
});
