import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Label from '../Label';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import styles from './Datepicker.module.scss';
import classnames from 'classnames';
const Datepicker = ({
  disabled,
  label,
  name,
  dateFormat,
  selected,
  onChange,
  className,
  placeholderText,
  maxDate,
  minDate,
  dropdownMode,
  required,
  autoComplete,
  'data-testid': datatestid,
}) => {
  const [errorText, setErrorText] = useState('');
  const handleOnBlur = ({ target: { value } }) => {
    if (required && !value) {
      setErrorText('Field is required');
    }
  };
  const handleOnChange = (e) => {
    onChange(e);
    setErrorText('');
  };
  return (
    <div>
      <Label errorText={errorText} htmlFor={name} dataTestId={datatestid}>
        {label}
      </Label>
      <div
        className={classnames(styles.container, className, {
          [disabled]: disabled,
        })}
      >
        <DatePicker
          autoComplete={autoComplete}
          selected={selected}
          dateFormat={dateFormat}
          className={classnames(className, styles['date-picker'])}
          name={name}
          maxDate={maxDate}
          minDate={minDate}
          placeholderText={placeholderText}
          onChange={handleOnChange}
          dropdownMode={dropdownMode}
          disabled={disabled}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          required={required}
          onBlur={handleOnBlur}
          id={datatestid}
          data-testid={datatestid}
        />
      </div>
    </div>
  );
};

Datepicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  dateFormat: PropTypes.string || PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  placeholderText: PropTypes.string,
  autoComplete: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  dropdownMode: PropTypes.oneOf(['scroll', 'select']),
};

Datepicker.defaultProps = {
  dropdownMode: 'select',
  dateFormat: 'MM/dd/yyyy',
};

export default Datepicker;
