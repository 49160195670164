import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Cell, Grid } from 'react-foundation';
import useIntl from '../../../../../hooks/useIntl';
import FieldDropdown from '../../../../common/fields/FieldDropdown';
import FieldInput from '../../../../common/fields/FieldInput/FieldInput';
import messages from '../messages';
import customStyles from '../PersonalInfoForm.module.scss';
import { frequencyOptions } from './../data';

const MedicationsForm = ({
  push,
  medicationValue,
  setMedicationValue,
  customDropdownStyles,
}) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    setMedicationValue();
  }, [setMedicationValue]);

  const handleChangeFormValue = (value, field) => {
    setMedicationValue({
      ...medicationValue,
      [field]: value,
    });
  };

  const addMedication = () => {
    push(medicationValue);
    setMedicationValue();
  };

  return (
    <fieldset className={customStyles['fieldset']}>
      <Grid medium={12}>
        <Cell small={12} medium={4}>
          <FieldInput
            label={formatMessage(messages.currentMedications.nameLabel)}
            placeholder={'Enter here'}
            value={medicationValue?.name}
            onChange={(e) => handleChangeFormValue(e.target.value, 'name')}
          />
        </Cell>
        <Cell small={12} medium={4}>
          <FieldInput
            label={formatMessage(messages.currentMedications.doseLabel)}
            placeholder={'Enter here'}
            value={medicationValue?.dose}
            onChange={(e) => handleChangeFormValue(e.target.value, 'dose')}
          />
        </Cell>
        <Cell small={12} medium={4}>
          <FieldDropdown
            key={medicationValue?.frequency || 0}
            label={formatMessage(messages.currentMedications.frequencyLabel)}
            name="currentMedications.frequency"
            placeholder="Select here"
            value={
              medicationValue?.frequency ? medicationValue?.frequency : null
            }
            onChange={(e) => {
              handleChangeFormValue(e.target.value, 'frequency');
            }}
            options={frequencyOptions}
            dataTestId="PersonalInfoForm.currentMedications.Frequency.Dropdown"
            customStyles={customDropdownStyles}
          />
        </Cell>
        <Cell className={customStyles['centeredButton']}>
          <button
            disabled={
              !medicationValue ||
              Object.keys(medicationValue).length !== 3 ||
              Object.values(medicationValue).some((elem) => !elem)
            }
            type="button"
            className={customStyles['buttonAddItem']}
            onClick={addMedication}
            data-testid="add_item_medication"
          >{` + Add Item `}</button>
        </Cell>
      </Grid>
    </fieldset>
  );
};

MedicationsForm.propTypes = {
  push: PropTypes.func,
  medicationValue: PropTypes.object,
  setMedicationValue: PropTypes.func,
  customDropdownStyles: PropTypes.object,
};

export default MedicationsForm;
