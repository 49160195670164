import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.doctorProfilePage.title',
    description: 'Doctor Profile Page title',
    defaultMessage: 'My Account Profile',
  },

  topUpButtonLabel: {
    id: 'pages.doctorProfilePage.topUpButtonLabel',
    description: 'Top up balance button label',
    defaultMessage: 'Top up balance',
  },
  nameLabel: {
    id: 'pages.doctorProfilePage.nameLabel',
    description: 'Name label',
    defaultMessage: 'Name:',
  },
  emailLabel: {
    id: 'pages.doctorProfilePage.emailLabel',
    description: 'Email label',
    defaultMessage: 'Email:',
  },
  passwordLabel: {
    id: 'pages.doctorProfilePage.passwordLabel',
    description: 'Password label',
    defaultMessage: 'Password:',
  },
  phoneLabel: {
    id: 'pages.doctorProfilePage.phoneLabel',
    description: 'Phone label',
    defaultMessage: 'Phone Number:',
  },
  doctorBioLable: {
    id: 'pages.doctorProfilePage.doctorBioLable',
    description: 'Doctor Bio label',
    defaultMessage: 'Doctor Bio:',
  },
  matchingToClientsLabel: {
    id: 'pages.doctorProfilePage.matchingToClientsLabel',
    description: 'Allow matching to clients label',
    defaultMessage: 'Allow matching to clients?',
  },
  editProfileLinkLable: {
    id: 'pages.doctorProfilePage.editProfileLinkLable',
    description: 'Edit profile link label',
    defaultMessage: 'Edit Profile',
  },
  closeAccountLinkLable: {
    id: 'pages.patientProfilePage.closeAccountLinkLable',
    description: 'Close Account link label',
    defaultMessage: 'Close My Account',
  },
  invalid: {
    id: 'component.doctorProfilePage.invalid',
    description: 'The label when there is any invalid field',
    defaultMessage: 'The email or password you have entered is invalid',
  },
  emailPlaceholder: {
    id: 'component.doctorProfilePage.emailPlaceholder',
    description: 'The label for email field placeholder',
    defaultMessage: 'Email',
  },
  passwordPlaceholder: {
    id: 'component.doctorProfilePage.passwordPlaceholder',
    description: 'The label for password field placeholder',
    defaultMessage: 'Password',
  },
  firstNamePlaceholder: {
    id: 'component.doctorProfilePage.firstNamePlaceholder',
    description: 'The label for first name field placeholder',
    defaultMessage: 'First Name',
  },
  lastNamePlaceholder: {
    id: 'component.doctorProfilePage.lastNamePlaceholder',
    description: 'The label for last name field placeholder',
    defaultMessage: 'Last Name',
  },
  phonePlaceholder: {
    id: 'component.doctorProfilePage.phonePlaceholder',
    description: 'The label for phone field placeholder',
    defaultMessage: 'Phone Number',
  },
  bioPlaceholder: {
    id: 'component.doctorProfilePage.phonePlaceholder',
    description: 'The label for doctor bio field placeholder',
    defaultMessage: 'Doctor Bio',
  },
  saveBtn: {
    id: 'component.doctorProfilePage.saveBtn',
    description: 'The label for save button',
    defaultMessage: 'Save',
  },
  cancelBtn: {
    id: 'component.doctorProfilePage.cancelBtn',
    description: 'The label for calcel button',
    defaultMessage: 'Cancel',
  },
  modalTitle: {
    id: 'component.doctorProfilePage.modalTitle',
    description: 'The title of the model',
    defaultMessage: 'Edit Profile',
  },
  uploadProfileBtnLabel: {
    id: 'component.doctorProfilePage.uploadProfileBtnLabel',
    description: 'The label for upload profile photo button',
    defaultMessage: 'Upload Profile Photo',
  },
  editProfileBtnLabel: {
    id: 'component.doctorProfilePage.editProfileBtnLabel',
    description: 'The label for edit profile photo button',
    defaultMessage: 'Edit Profile Photo',
  },
  removeProfileBtnLabel: {
    id: 'component.doctorProfilePage.removeProfileBtnLabel',
    description: 'The label for remove profile photo button',
    defaultMessage: 'Remove Profile Photo',
  },
  profilePicModalTitle: {
    id: 'component.doctorProfilePage.profilePicModalTitle',
    description: 'The title of the profile picture model',
    defaultMessage: 'Edit your profile photo',
  },
  profilePicModalFileSize: {
    id: 'component.doctorProfilePage.profilePicModalFileSize',
    description: 'The title of the profile picture model file size label',
    defaultMessage: 'Max file size: 2 MB',
  },
  profilePicChooseAnotherBtn: {
    id: 'component.doctorProfilePage.profilePicChooseAnotherBtn',
    description: 'The label for choose another photo button',
    defaultMessage: 'Choose another photo',
  },
  profilePicSaveBtn: {
    id: 'component.doctorProfilePage.profilePicSaveBtn',
    description: 'The label for save button',
    defaultMessage: 'Save',
  },
  profilePicCancelBtn: {
    id: 'component.doctorProfilePage.profilePicCancelBtn',
    description: 'The label for calcel button',
    defaultMessage: 'Cancel',
  },
});
