import React from 'react';
import RadioGroupWithLabel from '../../../../common/inputs/RadioGroupWithLabel';

const Radio = ({ item, dataTestId, labelTestId, value, onChange, label }) => {
  return (
    <RadioGroupWithLabel
      label={label}
      componentName={item.id}
      name={item.id}
      options={item.options}
      dataTestId={dataTestId}
      labelTestId={labelTestId}
      value={value}
      onChange={onChange}
    />
  );
};

export default Radio;
