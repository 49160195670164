import React, { useState, Fragment, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Cell, Grid } from 'react-foundation';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';

import useIntl from '../../../hooks/useIntl';

import styles from './MyAccountAppointmentPage.module.scss';

import FieldDropdown from '../../../components/common/fields/FieldDropdown';
import RadioChildrenSelector from '../../../components/common/RadioChildrenSelector';
import FieldInput from '../../../components/common/fields/FieldInput/FieldInput';

import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import Calendly from '../../../components/Calendly';
import NylasScheduler from '../../../components/NylasScheduler';
import Modal from '../../../components/common/Modal';
import useSquarePayment from '../../../hooks/useSquarePayment';
import Loading from '../../../components/common/Loading';
import CheckBox from '../../../components/common/inputs/CheckBox';
import SquareupContext from '../../../context/SquareupContext';
import Progress from '../../../components/Progress';
import Logo from '../../../components/Logo';
import MessageModal from '../../../components/MessageModal';

import AuthContext from '../../../context/AuthContext';
import { isObjectEmpty, addToDataLayer } from '../../../utils/common';
import { requiredValidator, phoneValidator } from '../../../utils/validators';
import {
  createPayment,
  updateAppointment,
  createPreBookingAppointment,
  getPreBookingAppointment,
  getAppointmentByPrebooking,
} from '../../../api/appointment';
import { getUserProfile } from '../../../api/user';
import { getPatientCards, deletePatientCard } from '../../../api/patient';
import {
  getDoctor,
  getBookingTypes,
  checkDoctorSquareConnection,
} from '../../../api/doctor';

import Back from '../../../img/icons/back.svg';
import ApprovalImg from '../../../img/approval.svg';

import {
  APPOINTEMNT_URLS,
  APPOINTEMNT_STATUS,
  CALENDAR_TYPES,
} from '../../../constants';
import messages from './messages';

const fieldValidators = ({ booking_type, patient_phone, patient_name }) => ({
  ...requiredValidator(booking_type, 'booking_type'),
  ...requiredValidator(patient_name, 'patient_name'),
  ...phoneValidator(patient_phone, 'patient_phone'),
  ...requiredValidator(patient_phone, 'patient_phone'),
});

const isInvalidPaymentToCheckout = (payment) => {
  return isObjectEmpty(payment);
};
const { REACT_APP_NYLAS_FEATURE_FLAG } = process.env;

const MyAccountAppointmentPage = () => {
  const cancelBtnLable = 'Cancel';

  const history = useHistory();
  const { authUser } = useContext(AuthContext);
  const { dispatch } = useContext(SquareupContext);
  const { formatMessage } = useIntl();
  const { id: doctorId } = useParams();

  const [doctor, setDoctor] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [appointmentType, setAppointmentType] = useState(0);
  const [amount, setAmount] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showCalendly, setShowCalendly] = useState(false);
  const [appointmentObj, setAppointmentObj] = useState({});
  const [paymentObj, setPaymentObj] = useState({});
  const [calendlyParams, setCalendlyParams] = useState('');
  const [profile, setProfile] = useState({});
  const [patientCards, setPatientCards] = useState([]);
  const [cardId, setCardId] = useState(0);
  const [isNewCard, setIsNewCard] = useState(false);
  const [isRememberCard, setIsRememberCard] = useState(false);
  const [isAppointmentConfirmed, setIsAppointmentConfirmed] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [bookingTypeLabel, setBookingTypeLabel] = useState('');
  const [bookingAmountLabel, setBookingAmountLabel] = useState('');
  const [bookingTypes, setBookingTypes] = useState([]);
  const [calendlyUrl, setCalendlyUrl] = useState('');
  const [error, setError] = useState('');
  const [showCardForm, setShowCardForm] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [uuid, setUuid] = useState('');
  const [percent, setPercent] = useState(1);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showPaymentError, setShowPaymentError] = useState(false);
  const [showSquareError, setShowSquareError] = useState(false);
  const [showAppoinmentBooked, setShowAppoinmentBooked] = useState(false);
  const [displayMessage, setDisplayMessage] = useState({
    title: '',
    message: '',
    buttonLabel: '',
    redirectUrl: '',
  });

  const fetchDoctor = async () => {
    if (doctorId) {
      try {
        const resp = await getDoctor(doctorId);
        setDoctor(resp.doctor);
        setPaymentObj({
          doctor_id: doctorId,
          clinic_id: resp.doctor.clinic_id,
        });
        const bookingType =
          REACT_APP_NYLAS_FEATURE_FLAG === 'true'
            ? CALENDAR_TYPES.NYLAS
            : CALENDAR_TYPES.CALENDLY;
        const { bookingTypes: savedBookingTypes } = await getBookingTypes(
          doctorId,
          bookingType,
        );
        const formattedBookingTypes = savedBookingTypes
          .filter((bookingType) => !!bookingType.available_for_patient)
          .map((bookingType) => {
            return {
              ...bookingType,
              label: `${bookingType.name} $${bookingType.amount} (${bookingType.duration}min)`,
              value: bookingType.id,
            };
          });

        const progress = (percent * 100) / 3;
        setPercent(progress);
        setStep1(true);
        setBookingTypes(formattedBookingTypes);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const fetchProfile = async () => {
    if (authUser.id) {
      try {
        const { patient } = await getUserProfile();
        setProfile(patient);
        const patient_name = `${patient.first_name ? patient.first_name : ''}${
          patient.last_name ? ` ${patient.last_name}` : ''
        }`;
        setInitialValues({
          patient_type: 'New',
          patient_name,
          patient_phone: patient.phone || '',
        });
        setAppointmentObj({
          ...initialValues,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const deleteCard = async (cardId) => {
    setIsLoading(true);
    setShowCardForm(false);
    try {
      await deletePatientCard(profile.id, cardId, doctor.id);
      const { cards: patientCards } = await getPatientCards(profile.id);
      setShowCardForm(true);
      setPatientCards(patientCards);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (nonceToken) => {
    setIsLoading(true);
    try {
      const payment = await createPayment(nonceToken, paymentObj);
      await updateAppointment(uuid, {
        status: 'Paid',
        payment_id: payment.id,
      });
      toast('Your appointment booked successfully', {
        autoClose: 5000,
        className: styles.toastMsg,
        bodyClassName: styles.toastDiv,
        hideProgressBar: true,
      });
      setIsLoading(false);
      const calendlyParamsObj = {
        ...payment,
        ...appointmentObj,
        booking_type_label: bookingTypeLabel,
      };
      setCalendlyParams(
        `prefilled_readonly=true&name=${appointmentObj.patient_name}&email=${
          profile.email
        }&utm_source=appointment&utm_content=${JSON.stringify(
          calendlyParamsObj,
        )}`,
      );
      setTimeout(() => {
        history.push(APPOINTEMNT_URLS.PATIENT);
      }, 500);
    } catch (e) {
      setError(e.response && e.response.data && e.response.data.message);
      setIsLoading(false);
      setShowPaymentError(true);
      setTimeout(() => {
        onReDrawFrom();
      }, 500);
    }
  };

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      const calendlyParamsObj = {
        ...appointmentObj,
        ...values,
        doctor_id: doctorId,
        patient_id: profile.id,
        has_assessment: profile.has_assessment,
        status: APPOINTEMNT_STATUS.UNPAID,
        amount: amount,
      };
      if (REACT_APP_NYLAS_FEATURE_FLAG === 'true') {
        calendlyParamsObj.booking_type_label = bookingTypeLabel;
      }
      const preBookingObj = {
        patient_id: profile.id,
        doctor_id: doctorId,
        patient_type: calendlyParamsObj.patient_type,
        patient_name: calendlyParamsObj.patient_name,
        booking_type: calendlyParamsObj.booking_type,
        booking_type_label: bookingTypeLabel,
        has_assessment: calendlyParamsObj.has_assessment,
        patient_phone: profile.phone ? profile.phone : '',
        patient_email: profile.email,
        amount,
        status: APPOINTEMNT_STATUS.UNPAID,
      };
      const { prebooking } = await createPreBookingAppointment(preBookingObj);
      setIsLoading(false);
      if (REACT_APP_NYLAS_FEATURE_FLAG === 'true') {
        setCalendlyParams(
          `prefilled_readonly=true&prebooking_id=${prebooking.id}&name=${values.patient_name}&email=${profile.email}`,
        );
      } else {
        setCalendlyParams(
          `&name=${profile.first_name}&email=${profile.email}&guests=${
            authUser.email
          }&utm_source=appointment&utm_content=${JSON.stringify(
            calendlyParamsObj,
          )}`,
        );
      }
      setTimeout(() => {
        setShowCalendly(true);
      }, 500);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      let errorMsg = '';
      if (e.response && e.response.data && e.response.data.message) {
        errorMsg = e.response.data.message;
      }
      setDisplayMessage({
        type: 'error',
        title: '',
        text:
          errorMsg && errorMsg !== ''
            ? errorMsg
            : 'Something went wrong. Try again later',
      });
      setIsAppointmentConfirmed(true);
    }
  };

  const { initialized, onGetCardNonce, onReDrawFrom } = useSquarePayment({
    styles: styles['card-input'],
    handleOnSubmit: handleSubmit,
    isValidCartToCheckout:
      !isInvalidPaymentToCheckout(paymentObj) && showCardForm,
  });

  const handleEvent = async (e) => {
    try {
      const eventData = {
        event: 'ga4_event',
        properties: {
          event_name: 'book_appointment',
          step_name: 'date_time_confirmed',
          step_number: '6',
        },
      };
      addToDataLayer(eventData);
      const params = e.detail;

      /**
       * wait for n sek for an appointment to be created by Nylas webhook
       */
      let isAppoitmentSuccessfullyCreated = false;
      async function waitForNewAppointment(attemptCount) {
        try {
          const preNewAppointment = await getAppointmentByPrebooking(
            params.prebooking_id,
          );
          isAppoitmentSuccessfullyCreated = true;
        } catch (err) {
          if (err?.response?.status === 404 && attemptCount < 30) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            await waitForNewAppointment(++attemptCount);
          } else {
            throw err;
          }
        }
      }
      await waitForNewAppointment(1);

      if (!isAppoitmentSuccessfullyCreated) {
        throw new Error(
          'Time has exceeded for waiting an appointment to be created',
        );
      }

      /**
       * proceed with payment
       */
      setUuid(e.detail.event_id);
      setIsAppointmentConfirmed(true);
      const { prebooking } = await getPreBookingAppointment(
        params.prebooking_id,
      );
      /**
       * prebooking.amountis amount of how much money user has to pay for this appointment
       */
      if (prebooking.amount <= 0) {
        setDisplayMessage({
          type: 'sucess',
          title: 'Appointment',
          text: 'Appointment Booked',
          buttonLabel: 'OK',
          redirectUrl: APPOINTEMNT_URLS.PATIENT,
        });
        setShowAppoinmentBooked(true);
      } else {
        setIsLoading(true);
        setShowCalendly(false);

        const connection = await checkDoctorSquareConnection(
          prebooking.doctor_id,
        );
        if (connection.success === false) {
          setShowSquareError(true);
          setIsLoading(false);
        }

        const { cards } = await getPatientCards(prebooking.patient_id);
        const formattedCards = cards.map((card) => ({
          ...card,
          isChecked: false,
        }));

        setShowCardForm(true);
        setPatientCards(formattedCards);
        setShowPayment(true);
        setIsLoading(false);
        setStep3(true);
        setPercent(100);
      }
    } catch (error) {
      console.log('error', error);
      let errorMsg = '';
      if (error?.response?.data?.message) {
        errorMsg = error.response.data.message;
      }
      setIsLoading(false);
      setDisplayMessage({
        type: 'error',
        title: '',
        text:
          errorMsg && errorMsg !== ''
            ? errorMsg
            : 'Something went wrong. Try again later',
      });
      setShowAppoinmentBooked(true);
    }
  };

  const isCalendlyEvent = (e) => {
    return e.data.event && e.data.event.indexOf('calendly') === 0;
  };

  const onWindowMessage = (e) => {
    if (isCalendlyEvent(e)) {
      if (e.data.event === 'calendly.event_scheduled') {
        const calendlyUrl = e.data.payload.event.uri;
        const data = calendlyUrl.split('/');
        const calendlyUuid = data[data.length - 1];
        const eventData = {
          event: 'ga4_event',
          properties: {
            event_name: 'book_appointment',
            step_name: 'date_time_confirmed',
            step_number: '6',
          },
        };
        addToDataLayer(eventData);
        setUuid(calendlyUuid);
        setIsAppointmentConfirmed(true);
      }
    }
  };

  const handleBookingTypeChange = (e) => {
    const bookingType = bookingTypes.find(
      (type) => type.value === e.target.value,
    );
    setAmount(bookingType.amount);
    setBookingTypeLabel(bookingType.name);
    setBookingAmountLabel(
      `$${bookingType.amount} (${bookingType.duration}min)`,
    );
    setCalendlyUrl(bookingType.url);
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch({ type: 'LOADING', payload: true });

    fetchDoctor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorId]);

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  useEffect(() => {
    if (window) {
      window.document.addEventListener(
        'onScheduleComplete',
        handleEvent,
        false,
      );
      window.addEventListener('message', onWindowMessage);
    }
    return () => {
      window.document.removeEventListener(
        'onScheduleComplete',
        handleEvent,
        false,
      );
      window.removeEventListener('message', onWindowMessage);
    };
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.contentDiv}>
        {
          <Fragment>
            <div
              className={styles.title}
              data-testid="ChooseAppointment.Patient.Header"
            >
              {!showPayment ? formatMessage(messages.title) : 'Make Payment'}
            </div>
            <div className={styles['schedule-header']}>
              <div className={styles['header-left']}>
                <div className={styles['schedule-user']}>
                  <img
                    src={
                      doctor.profile_pic
                        ? doctor.profile_pic
                        : require('../../../img/doctor.jpg')
                    }
                    alt={`${doctor.first_name} ${
                      doctor.last_name ? doctor.last_name : ''
                    }`}
                    data-testid="ChooseAppointment.Patient.DoctorPic"
                  />
                </div>
                <h3 data-testid="ChooseAppointment.Patient.DoctorName">
                  {doctor.first_name} {doctor.last_name ? doctor.last_name : ''}
                </h3>
              </div>
              <div className={styles['header-right']}>
                <div className={styles['schedule-content']}>
                  <h3 data-testid="ChooseAppointment.Patient.Booking.Type">
                    {' '}
                    {bookingTypeLabel}{' '}
                  </h3>
                  <h6 data-testid="ChooseAppointment.Patient.Booking.Amount">
                    {' '}
                    {bookingAmountLabel}{' '}
                  </h6>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.progressHead}>
                <p
                  className={step1 ? styles.stepHead : ''}
                  data-testid="ChooseAppointment.Patient.Booking.InfoLabel"
                >
                  Booking Info
                </p>
                <p
                  className={step1 && step2 ? styles.stepHead : ''}
                  data-testid="ChooseAppointment.Patient.DateTime.Label"
                >
                  Date & Time
                </p>
                <p
                  className={step1 && step2 && step3 ? styles.stepHead : ''}
                  data-testid="ChooseAppointment.Patient.Payment.Label"
                >
                  Payment
                </p>
              </div>
              <div className={styles.progressBar}>
                <Progress width={700} height={5} percent={percent} />
              </div>
            </div>
          </Fragment>
        }
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            {!showCalendly && !showPayment && (
              <Formik
                validate={fieldValidators}
                initialValues={initialValues}
                enableReinitialize={true}
                render={({
                  values,
                  handleChange,
                  errors,
                  touched,
                  isValid,
                  setFieldValue,
                  handleBlur,
                }) => {
                  return (
                    <Form
                      data-testid="ChooseAppointment.Patient.Appointment.Form"
                      className={styles.form}
                    >
                      <Grid className={styles.grid}>
                        <div className={styles.cell}>
                          <FieldDropdown
                            label={formatMessage(messages.bookingType)}
                            errorText={
                              touched.booking_type && errors.booking_type
                            }
                            name="booking_type"
                            value={values.booking_type}
                            onChange={(e) => {
                              setFieldValue('booking_type', e.target.value);
                              handleBookingTypeChange(e);
                            }}
                            onBlur={handleBlur}
                            options={bookingTypes}
                            dataTestId="ChooseAppointment.Patient.Appointment.BookingType.Dropdown"
                            placeholder="select appointment type"
                          />
                        </div>
                        <div
                          className={[styles.cell, styles.radioBtn].join(' ')}
                        >
                          <span
                            className={styles['radio-label']}
                            data-testid="have you seen before label"
                          >
                            {' '}
                            {formatMessage(messages.radioLable)}{' '}
                          </span>
                          <RadioChildrenSelector
                            name="appointmentType"
                            handleOnChange={(value) => {
                              value === 0 && handleChange(null, true);
                              setAppointmentType(!value ? 0 : 1);
                              setFieldValue(
                                'patient_type',
                                !value ? 'New' : 'Old',
                              );
                            }}
                            selected={appointmentType}
                            direction="row"
                          >
                            <div
                              className={styles['radio-buttons']}
                              data-testid="ChooseAppointment.Patient.Appointment.RadioButton.NewPatient.Label"
                            >
                              {formatMessage(messages.newPatientLabel)}
                            </div>
                            <div
                              className={styles['radio-buttons']}
                              data-testid="ChooseAppointment.Patient.Appointment.RadioButton.OldPatient.Label"
                            >
                              {formatMessage(messages.oldPatientLable)}
                            </div>
                          </RadioChildrenSelector>
                        </div>
                        <Cell
                          small={12}
                          medium={6}
                          className={[
                            styles['cell-md-6'],
                            styles['cell-sm-6'],
                          ].join(' ')}
                        >
                          <FieldInput
                            label={formatMessage(messages.patientName)}
                            className={styles.textInput}
                            placeholder="Add patient name"
                            name="patient_name"
                            value={values.patient_name}
                            onChange={handleChange}
                            errorText={
                              touched.patient_name && errors.patient_name
                            }
                            onBlur={handleBlur}
                            data-testid="ChooseAppointment.Patient.Appointment.Form.PatientName"
                            label-testid="patient_name label"
                            error-testid="patient_name error"
                          />
                        </Cell>
                        <Cell
                          small={12}
                          medium={6}
                          className={styles['cell-sm-6']}
                        >
                          <FieldInput
                            label={formatMessage(messages.patientPhoneNumber)}
                            placeholder="Add phone number"
                            className={styles.textInput}
                            name="patient_phone"
                            value={values.patient_phone}
                            onChange={handleChange}
                            errorText={
                              touched.patient_phone && errors.patient_phone
                            }
                            onBlur={handleBlur}
                            data-testid="ChooseAppointment.Patient.Appointment.Form.PatientPhone"
                            label-testid="patient_phone label"
                            error-testid="patient_phone error"
                          />
                        </Cell>
                      </Grid>
                      {error && (
                        <div className={styles['error-container']}>
                          <div
                            className={styles.error}
                            data-testid="choose_appointment error"
                          >
                            {error}
                          </div>
                        </div>
                      )}
                      {!showCalendly && <hr />}
                      <Cell
                        small={12}
                        flexContainer
                        alignY="middle"
                        className={styles['actions-container']}
                      >
                        <PrimaryButton
                          disabled={!isValid}
                          data-testid="ChooseAppointment.Patient.Appointment.Button.Next"
                          label="Next"
                          type="submit"
                          className="font-18"
                          onClick={async (e) => {
                            setAppointmentObj(
                              Object.assign(
                                values,
                                { doctor_id: doctorId },
                                { patient_id: profile.id },
                                { has_assessment: profile.has_assessment },
                              ),
                            );
                            const eventData = {
                              event: 'ga4_event',
                              properties: {
                                event_name: 'book_appointment',
                                step_name: 'continue_appointment',
                                step_number: '3',
                                clinician_name: doctor.first_name,
                                clinician_id: doctorId,
                                patient_id: profile.id,
                              },
                            };
                            addToDataLayer(eventData);
                            await onSubmit(values);
                            setShowCalendly(true);
                            const progress = percent * 2;
                            setPercent(progress);
                            setStep2(true);
                          }}
                        />

                        <SecondaryButton
                          label={cancelBtnLable}
                          className="font-18"
                          type="button"
                          onClick={() => {
                            setShowCancelModal(true);
                          }}
                          data-testid="ChooseAppointment.Patient.Appointment.Button.Cancel"
                        />
                      </Cell>
                    </Form>
                  );
                }}
              />
            )}
            {showCalendly && (
              <>
                <div className={styles.calendlyBox}>
                  {REACT_APP_NYLAS_FEATURE_FLAG === 'true' ? (
                    <NylasScheduler url={`${calendlyUrl}?${calendlyParams}`} />
                  ) : (
                    <Calendly url={calendlyUrl} queryString={calendlyParams} />
                  )}
                  {!isAppointmentConfirmed && <hr />}
                </div>
                {!isAppointmentConfirmed && (
                  <div className={styles.btnDiv}>
                    <SecondaryButton
                      label={cancelBtnLable}
                      className="font-18"
                      type="button"
                      onClick={() => {
                        setShowCancelModal(true);
                      }}
                      data-testid="ChooseAppointment.Patient.DateTime.Button.Cancel"
                    />
                  </div>
                )}
              </>
            )}
            {isAppointmentConfirmed && !showPayment && (
              <div className={styles.btnDiv}>
                <SecondaryButton
                  label={cancelBtnLable}
                  className="font-18"
                  type="button"
                  onClick={() => {
                    setShowCancelModal(true);
                  }}
                />
                <div className={styles.backBtn}>
                  <PrimaryButton
                    label="Next"
                    data-testid="ChooseAppointment.Patient.DateTime.Button.Next"
                    className={styles.backButton}
                    onClick={async (e) => {
                      if (amount <= 0) {
                        setShowAppoinmentBooked(true);
                        setDisplayMessage({
                          type: 'sucess',
                          title: 'Appointment',
                          text: 'Appointment Booked',
                          buttonLabel: 'OK',
                          redirectUrl: APPOINTEMNT_URLS.PATIENT,
                        });
                      } else {
                        setIsLoading(true);
                        setShowCalendly(false);
                        if (
                          doctor.clinic_id === 0 &&
                          !doctor.square_access_token
                        ) {
                          setShowSquareError(true);
                          setIsLoading(false);
                        } else {
                          const connection = await checkDoctorSquareConnection(
                            doctorId,
                          );
                          if (connection.success === false) {
                            setShowSquareError(true);
                            setIsLoading(false);
                          }
                        }
                        const { cards } = await getPatientCards(profile.id);
                        const formattedCards = cards.map((card) => ({
                          ...card,
                          isChecked: false,
                        }));
                        setShowCardForm(true);
                        setPatientCards(formattedCards);
                        setShowPayment(true);
                        setIsLoading(false);
                        setStep3(true);
                        setPercent(100);
                      }
                    }}
                  ></PrimaryButton>
                </div>
              </div>
            )}

            {initialized &&
              showCardForm &&
              showPayment &&
              isAppointmentConfirmed && (
                <div className={styles['contact-container']}>
                  <div className={styles.cards}>
                    {patientCards.length > 0 &&
                      patientCards.map((card, index) => (
                        <Fragment key={`radio-${index}`}>
                          <div
                            key={`radio-${index}${card.id}`}
                            data-testid={`ChooseAppointment.Patient.SavedCard.RadioButton.${index}`}
                            className={[
                              styles['card-radio'],
                              card.isChecked
                                ? styles['card-radio-checked']
                                : styles['card-radio-default'],
                            ].join(' ')}
                            onClick={() => {
                              setPatientCards(
                                [...patientCards].map((cardObj) => {
                                  if (cardObj.id === card.id) {
                                    return {
                                      ...cardObj,
                                      isChecked: true,
                                    };
                                  }
                                  return {
                                    ...cardObj,
                                    isChecked: false,
                                  };
                                }),
                              );
                              setCardId(card.id);
                              setIsNewCard(false);
                            }}
                          ></div>
                          <div key={index} className={styles.cardItem}>
                            <span
                              data-testid={`ChooseAppointment.Patient.SavedCard.Brand.${card.brand}`}
                              className={styles[card.cardBrand.toLowerCase()]}
                            ></span>
                            <p
                              data-testid={`ChooseAppointment.Patient.SavedCard.Last4Digits.${card.last4}`}
                            >
                              Ending with {card.last4}
                            </p>
                            <p
                              data-testid={`ChooseAppointment.Patient.SavedCard.ExpDate.${card.expMonth}${card.expYear}`}
                            >
                              Exp on {card.expMonth}/{card.expYear}
                            </p>
                            <button
                              type="button"
                              className="secondary"
                              data-testid="ChooseAppointment.Patient.SavedCard.Button.Delete"
                              onClick={() => deleteCard(card.id)}
                            ></button>
                          </div>
                        </Fragment>
                      ))}
                  </div>
                  {patientCards.length ? (
                    <>
                      <span
                        data-testid="ChooseAppointment.Patient.NewCard.Label"
                        className={styles.newCard}
                      >
                        New Card
                      </span>
                      <div
                        data-testid="ChooseAppointment.Patient.NewCard.RadioButton"
                        className={[
                          styles['card-radio'],
                          isNewCard
                            ? styles['card-radio-checked']
                            : styles['card-radio-default'],
                        ].join(' ')}
                        onClick={() => {
                          setPatientCards(
                            [...patientCards].map((cardObj) => {
                              return { ...cardObj, isChecked: false };
                            }),
                          );
                          setIsNewCard(true);
                          setCardId(0);
                        }}
                      ></div>
                    </>
                  ) : (
                    ''
                  )}
                  <Grid className={patientCards.length ? styles.formGrid : ''}>
                    <div className={styles.payment}>
                      <label
                        htmlFor="ccnumber"
                        data-testid="ChooseAppointment.Patient.NewCard.CardNumber"
                      >
                        {formatMessage(messages.cardNumber)}
                      </label>
                      <div id="ccnumber" />
                      <span
                        data-testid="ChooseAppointment.Patient.NewCard.CardNumber.Error"
                        id="cardNumber-error"
                        className="error-message"
                      />
                    </div>
                    <div className={styles.paymentInput}>
                      <label
                        htmlFor="ccexp"
                        data-testid="ChooseAppointment.Patient.NewCard.CardExpire"
                      >
                        {formatMessage(messages.expirationDate)}
                      </label>
                      <div id="ccexp" />
                      <span
                        data-testid="ChooseAppointment.Patient.NewCard.CardExpire.Error"
                        id="expirationDate-error"
                        className="error-message"
                      />
                    </div>
                    <div className={styles.paymentInput}>
                      <label
                        htmlFor="cvv"
                        data-testid="ChooseAppointment.Patient.NewCard.CardCVV"
                      >
                        {formatMessage(messages.cvvCode)}
                      </label>
                      <div id="cvv" />
                      <span
                        data-testid="ChooseAppointment.Patient.NewCard.CardCVV.Error"
                        id="cvv-error"
                        className="error-message"
                      />
                    </div>
                    <div className={styles.paymentInput}>
                      <label
                        htmlFor="postalCodeBilling"
                        data-testid="ChooseAppointment.Patient.NewCard.PostalCodeBilling"
                      >
                        {formatMessage(messages.postalCode)}
                      </label>
                      <div id="postalCodeBilling" />
                      <span
                        data-testid="ChooseAppointment.Patient.NewCard.PostalCodeBilling.Error"
                        id="postalCode-error"
                        className="error-message"
                      />
                    </div>
                    <Cell
                      small={12}
                      medium={12}
                      className={styles['checkboxBtn']}
                    >
                      <CheckBox
                        label="Save Payment Method"
                        name="rememberCard"
                        value={isRememberCard}
                        isActive={
                          (!patientCards.length && isRememberCard) ||
                          (isNewCard && isRememberCard)
                        }
                        onClick={() =>
                          setIsRememberCard((currentState) => !currentState)
                        }
                        data-testid="ChooseAppointment.Patient.NewCard.SavePaymentMethod"
                      />
                    </Cell>
                  </Grid>
                  <div className={styles.btnDiv}>
                    <SecondaryButton
                      label={cancelBtnLable}
                      className="font-18"
                      type="button"
                      data-testid="ChooseAppointment.Patient.Payment.Button.Cancel"
                      onClick={() => {
                        const eventData = {
                          event: 'ga4_event',
                          properties: {
                            event_name: 'book_appointment',
                            step_name: 'cancel',
                            step_number: '7',
                            clinician_name: doctor.first_name,
                            clinician_id: doctorId,
                            patient_id: profile.id,
                          },
                        };
                        addToDataLayer(eventData);
                        history.push(`/my-account/`);
                      }}
                    />

                    {isNewCard || !patientCards.length ? (
                      <PrimaryButton
                        label={`Confirm Payment`}
                        type="submit"
                        className="font-18"
                        data-testid="ChooseAppointment.Patient.Payment.Button.Submit"
                        onClick={async (e) => {
                          const eventData = {
                            event: 'ga4_event',
                            properties: {
                              event_name: 'book_appointment',
                              step_name: 'payment',
                              step_number: '7',
                              amount: amount,
                              currency: 'USD',
                            },
                          };
                          addToDataLayer(eventData);
                          setPaymentObj({
                            amount,
                            doctor_id: doctorId,
                            clinic_id: doctor.clinic_id,
                            patient_id: profile.id,
                            isRememberCard:
                              (!patientCards.length && isRememberCard) ||
                              (isNewCard && isRememberCard),
                          });
                          onGetCardNonce(e);
                        }}
                      />
                    ) : (
                      <PrimaryButton
                        disabled={!cardId}
                        label={`Confirm Payment`}
                        type="submit"
                        className="font-18"
                        data-testid="ChooseAppointment.Patient.Payment.Button.Submit"
                        onClick={async (e) => {
                          setIsLoading(true);
                          const eventData = {
                            event: 'ga4_event',
                            properties: {
                              event_name: 'book_appointment',
                              step_name: 'payment',
                              step_number: '7',
                              amount: amount,
                              currency: 'USD',
                            },
                          };
                          addToDataLayer(eventData);
                          const payment = await createPayment(cardId, {
                            amount,
                            doctor_id: doctorId,
                            patient_id: profile.id,
                            clinic_id: doctor.clinic_id,
                            isRememberCard:
                              (!patientCards.length && isRememberCard) ||
                              (isNewCard && isRememberCard),
                          });
                          const appointment = await updateAppointment(uuid, {
                            status: 'paid',
                            payment_id: payment.id,
                          });
                          toast('Your appointment booked successfully', {
                            autoClose: 5000,
                            className: styles.toastMsg,
                            bodyClassName: styles.toastDiv,
                            hideProgressBar: true,
                          });
                          setIsLoading(false);
                          if (appointment) {
                            setTimeout(() => {
                              history.push(APPOINTEMNT_URLS.PATIENT);
                            }, 500);
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
          </Fragment>
        )}
        {showConfirmationModal && (
          <Modal className={styles.modal}>
            <div className={styles.content}>
              <div className={styles.icon}>
                <img
                  className={styles['img-bg']}
                  src={require(`../../../img/icons/appointment-booked.svg`)}
                  alt={'appointment-booked'}
                />
              </div>
              <h4>
                <b>{formatMessage(messages.modalTitle)}</b>
              </h4>
              <p>
                You are scheduled with {doctor.first_name}{' '}
                {doctor.last_name ? doctor.last_name : ''}
              </p>
              <span className={styles.hr}></span>
              <div className={styles['meeting-section']}>
                <div className={styles['meeting-details']}>
                  <span className={styles.dot}></span>
                  <p className={styles['meeting-duration']}>
                    30 Minute Meeting
                  </p>
                </div>
                <div className={styles['meeting-details']}>
                  <img
                    className={styles['meeting-icon']}
                    src={require(`../../../img/icons/calendar-grey.svg`)}
                    alt={'appointment-booked'}
                  />
                  <p className={styles['meeting-timing']}>
                    9:30am - 10:00am, Friday, June 12, 2020
                  </p>
                </div>
                <div className={styles['meeting-details']}>
                  <img
                    className={styles['meeting-icon']}
                    src={require(`../../../img/icons/globe.svg`)}
                    alt={'appointment-booked'}
                  />
                  <p className={styles['meeting-timezone']}>
                    India Standard Time
                  </p>
                </div>
                <p className={styles['invitation-message']}>
                  A calendar invitation has been sent to your email address.
                </p>
              </div>
              <span className={styles.hr}></span>
              <PrimaryButton
                label={formatMessage(messages.modalButton)}
                className={styles.primaryButton}
                onClick={() => setShowConfirmationModal(false)}
              />
            </div>
          </Modal>
        )}
        {showCancelModal && (
          <Modal className={styles.resetmodal}>
            <div className={styles.content}>
              <div
                className={styles.closeButton}
                onClick={() => {
                  setShowCancelModal(false);
                }}
              >
                <img src={require(`../../../img/close.svg`)} alt={`Close`} />
              </div>
              <h1>
                <b data-testid="cancel appointment confirmatin title">
                  Are you sure you want to cancel?
                </b>
              </h1>
              <div className={styles.box}>
                <p data-testid="cancel appointment confirmatin subtext">
                  This action will redirect you to the home page.
                </p>
              </div>
              <PrimaryButton
                label="Yes, Cancel"
                className={styles.primaryButton}
                data-testid="cancel appointment confirmatin yes button"
                onClick={() => {
                  const step_number_count = step1 && step2 ? '7' : '6';
                  const eventData = {
                    event: 'ga4_event',
                    properties: {
                      event_name: 'book_appointment',
                      step_name: 'cancel',
                      step_number: step_number_count,
                      clinician_name: doctor.first_name,
                      clinician_id: doctorId,
                      patient_id: profile.id,
                    },
                  };
                  addToDataLayer(eventData);
                  history.push(`/my-account/`);
                }}
              />
              <SecondaryButton
                label="No"
                className={styles.secondaryButton}
                data-testid="cancel appointment confirmatin no button"
                onClick={() => {
                  setShowCancelModal(false);
                }}
              />
            </div>
          </Modal>
        )}
        {showPaymentError && (
          <Modal className={styles.paymentErrorModal}>
            <div className={styles.content}>
              <div className={styles.container}>
                <div className={styles.backDiv}>
                  <img
                    src={Back}
                    alt="Back"
                    data-testid="payment_error back image"
                  />

                  <span
                    onClick={() => {
                      setShowPaymentError(false);
                    }}
                    data-testid="payment_error back label"
                  >
                    Back
                  </span>
                </div>
                <Logo />
                <div className={styles.warningImg}>
                  <img
                    src={ApprovalImg}
                    alt="Warning"
                    data-testid="payment_error icon image"
                  />
                </div>
                <h4
                  className={styles.confirmationTitle}
                  data-testid="payment_error title"
                >
                  Payment Failed
                </h4>
                <br />
                <br />
                <p className={styles.text} data-testid="payment_error text">
                  {error ? error : 'Please check the information you provided'}
                </p>
              </div>
            </div>
          </Modal>
        )}
        {showSquareError && (
          <Modal className={styles.paymentErrorModal}>
            <div className={styles.content}>
              <div className={styles.container}>
                <div className={styles.backDiv}>
                  <img
                    src={Back}
                    alt="Back"
                    data-testid="square_payment error back image"
                  />

                  <span
                    onClick={() => {
                      history.push('/my-account');
                      setShowSquareError(false);
                    }}
                    data-testid="square_payment error back"
                  >
                    Back
                  </span>
                </div>
                <Logo />
                <div className={styles.warningImg}>
                  <img
                    src={ApprovalImg}
                    alt="Warning"
                    data-testid="square_payment error image"
                  />
                </div>
                <h4
                  className={styles.confirmationTitle}
                  data-testid="payment_error title"
                >
                  Payment Error
                </h4>
                <br />
                <br />
                <p
                  className={styles.text}
                  data-testid="square_payment error text"
                >
                  We cannot process your payment at the current time. Please try
                  again later.
                </p>
              </div>
            </div>
          </Modal>
        )}
        {showAppoinmentBooked && (
          <MessageModal
            message={displayMessage}
            displayLogo={false}
            onCloseModal={() => setShowAppoinmentBooked(false)}
          />
        )}
      </div>
    </div>
  );
};

export default MyAccountAppointmentPage;
