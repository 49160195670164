import React from 'react';
import { Cell } from 'react-foundation';
import useIntl from '../../../../../hooks/useIntl';
import FieldDropdown from '../../../../common/fields/FieldDropdown';
import FieldInput from '../../../../common/fields/FieldInput';
import RadioGroupWithLabel from '../../../../common/inputs/RadioGroupWithLabel';
import { customDropdownStyles } from '.././../PersonalInfoForm/PersonalInfoForm';
import { basicResponses, periodicityOptions } from '../data';
import messages from '../messages';

const SymptomsForm = ({
  isDropdownDisplayed,
  isInputDisplayed,
  isRadioGroupDisplayed,
  dropdownName,
  radioGroupName,
  inputName,
  handleChange,
  handleBlur,
  setFieldValue,
  dropdownValue,
  inputValue,
  radioGroupValue,
  dropdownDataTestId,
  inputDataTestId,
  radioGroupDataTestId,
  radioGroupLabelTestId,
  radioGroupLabel,
  inputLabel,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {isDropdownDisplayed && (
        <Cell small={12}>
          <FieldDropdown
            label={formatMessage(messages.currentWellbeing.symptomsPeriod)}
            name={dropdownName}
            placeholder="Select here"
            value={dropdownValue}
            onChange={(e) => {
              setFieldValue(dropdownName, e.target.value);
            }}
            options={periodicityOptions}
            onBlur={handleBlur}
            dataTestId={dropdownDataTestId}
            customStyles={customDropdownStyles}
          />
        </Cell>
      )}
      {isRadioGroupDisplayed && (
        <Cell small={12}>
          <RadioGroupWithLabel
            label={radioGroupLabel}
            componentName={radioGroupName}
            options={basicResponses}
            dataTestId={radioGroupDataTestId}
            labelTestId={radioGroupLabelTestId}
            value={radioGroupValue}
            onChange={(value) => setFieldValue(radioGroupName, value)}
          />
        </Cell>
      )}
      {isInputDisplayed && (
        <Cell small={12}>
          <FieldInput
            label={inputLabel}
            placeholder="Enter here"
            name={inputName}
            value={inputValue}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid={inputDataTestId}
          />
        </Cell>
      )}
    </>
  );
};

SymptomsForm.propTypes = {};

SymptomsForm.defaultProps = {
  isDropdownDisplayed: true,
  isInputDisplayed: true,
  isRadioGroupDisplayed: true,
  radioGroupLabel:
    'Do the symptoms you are experiencing result in distress and/or impairment at work, socially, or in other areas of your life?',
  inputLabel: 'If YES, please provide a brief description.',
  setFieldValue: () => {},
};

export default SymptomsForm;
