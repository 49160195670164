import axios from 'axios';
import { doGet, doDelete, doPost } from '../fetch';

export const generatenNylasToken = async (body) => {
  const { data } = await doPost(`/b2c/nylas/generate-token`, body);
  return data;
};

export const getClinicianNylasDetail = async () => {
  const res = await doGet(`/b2c/nylas/account-detail`);
  return res;
};

export const disconnectNylasAccount = async () => {
  const { doctor } = await doDelete(`/b2c/nylas/disconnect`);
  return doctor;
};

export const saveNylasBookingEvents = async (id, params) => {
  const data = await doPost(`/b2c/nylas/nylas-events`, params);
  return data;
};

export const getDoctorNylasEvents = async (nylasToken) => {
  const { data } = await axios.get(
    'https://api.schedule.nylas.com/manage/pages',
    {
      headers: {
        Authorization: `Bearer ${nylasToken}`,
        'Content-Type': 'application/json',
      },
    },
  );
  return data;
};
