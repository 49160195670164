import React, { Children } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './RadioChildrenSelector.module.scss';

const renderChildren = (
  content,
  index,
  { name, disabled, handleOnChange, selected },
) => {
  const id = `${name}-${index}`;
  return (
    <div
      className={classnames(styles['children-container'], {
        hide: !content,
      })}
      key={id}
      data-testid={id}
    >
      <div className={styles['radio-button-container']}>
        <input
          type="radio"
          className={
            index === selected
              ? styles['radio-button-selected']
              : styles['radio-button']
          }
          name={name}
          id={id}
          disabled={disabled}
          onChange={() => handleOnChange(index)}
          data-testid={`input-${id}`}
        />
        <label data-testid={`label-${id}`} htmlFor={id}>
          <span />
        </label>
      </div>
      <div className={styles['content-container']}>{content}</div>
    </div>
  );
};

const RadioChildrenSelector = ({
  children,
  name,
  disabled,
  handleOnChange,
  selected,
  direction,
}) => (
  <div
    className={classnames(styles[`content-container--${direction}`], {
      [styles['container-disabled']]: disabled,
    })}
  >
    {Children.map(children, (child, index) =>
      renderChildren(child, index, {
        name,
        disabled,
        handleOnChange,
        selected,
      }),
    )}
  </div>
);

RadioChildrenSelector.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handleOnChange: PropTypes.func.isRequired,
  selected: PropTypes.number.isRequired,
  direction: PropTypes.oneOf(['column', 'row']),
};

RadioChildrenSelector.defaultProps = {
  disabled: false,
  direction: 'column',
};

export default RadioChildrenSelector;
