import { defineMessages } from 'react-intl';

export default defineMessages({
  plantBasedWellness: {
    id: 'pages.dashboardPage.plantBasedWellness',
    description: 'Plant based wellness label',
    defaultMessage: 'Plant-Based Wellness',
  },
  title: {
    id: 'pages.dashboardPage.title',
    description: 'DashboardPage title',
    defaultMessage: 'curated for {name}',
  },
  plantBasedSubtitle: {
    id: 'pages.dashboardPage.plantBasedSubtitle',
    description: 'DashboardPage subtitle',
    defaultMessage:
      'These plant-based products have been selected especially for you and are recommended to improve your wellbeing.',
  },
  confirmResetTitle: {
    id: 'components.dashboardPage.confirmResetTitle',
    description: 'Reset Recommendation confirmation title',
    defaultMessage:
      'Are you sure you want to reset your recommendation and wellness profile?',
  },
  confirmResetText: {
    id: 'components.dashboardPage.confirmResetText',
    description: 'Reset Recommendation confirmation text',
    defaultMessage:
      'This will reset your wellness scores and progress for every benefit you previously selected and offer you a new product recommendation',
  },
  reset: {
    id: 'components.resetRecommendations.reset',
    description: 'Reset Recommendation Label',
    defaultMessage: 'Reset Recommendation',
  },
  confirmResetCancel: {
    id: 'components.dashboardPage.confirmResetCancel',
    description: 'Reset Recommendation confirmation cancel label',
    defaultMessage: 'Cancel',
  },
  modalTitle: {
    id: 'pages.dashboardPage.modalTitle',
    description: 'Appointment confirmation modal title',
    defaultMessage: 'Intake form',
  },
  modalText: {
    id: 'pages.dashboardPage.modalText',
    description: 'Appointment confirmation modal text',
    defaultMessage:
      'Please fill in form before the consultation. You may skip and fill after appointment booking.',
  },
  modalButtonPrimary: {
    id: 'pages.dashboardPage.modalButtonPrimary',
    description: 'Appointment confirmation primary button label',
    defaultMessage: 'Fill in form',
  },
  modalButtonSecondary: {
    id: 'pages.dashboardPage.modalButtonSecondary',
    description: 'Appointment confirmation secondary button label',
    defaultMessage: 'Skip',
  },
});
