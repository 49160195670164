import { doPost, doGet, doPut } from '../fetch';

const createPayment = async (nonceToken, paymentObj) => {
  const payment = await doPost(`/b2c/payment`, {
    payment_token: nonceToken,
    paymentObj,
  });
  return payment;
};

const updatePayment = async (id, body) => {
  const payment = await doPut(`/b2c/payment/${id}`, body);
  return payment;
};

const getAppointment = async (id) => {
  const appointment = await doGet(`/b2c/appointment/${id}`);
  return appointment;
};

const getAppointmentByPrebooking = async (prebookingId) => {
  const appointment = await doGet(`/b2c/appointment/by-prebooking/${prebookingId}`);
  return appointment;
};

const requestSuperBill = async (id) => {
  let response;

  try {
    response = await doPost(`/b2c/superbill`, {
      appointment_id: id,
    });
  } catch {
    return { type: 'error' };
  }

  return response;
};

const updateAppointment = async (id, params) => {
  const res = await doPut(`/b2c/appointment/${id}`, params, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return res;
};

const getLastAppointment = async (id) => {
  const appointment = await doGet(`/b2c/last-appointment/${id}`);
  return appointment;
};

const createAppointment = async (body) => {
  const res = await doPost(`/b2c/create_appointment`, body);
  return res;
};

const rescheduleAppointment = async (body) => {
  const res = await doPost(`/b2c/reschedule_appointment`, body);
  return res;
};

const cancelAppointment = async (id, body) => {
  const res = await doPost(`/b2c/cancel_appointment/${id}`, body);
  return res;
};

const createPreBookingAppointment = async (body) => {
  const res = await doPost(`/b2c/prebooking_appointment`, body);
  return res;
};

const getPreBookingAppointment = async (id) => {
  const res = await doGet(`/b2c/prebooking_appointment/${id}`);
  return res;
};

export {
  createPayment,
  getAppointment,
  getAppointmentByPrebooking,
  updateAppointment,
  updatePayment,
  getLastAppointment,
  requestSuperBill,
  createAppointment,
  rescheduleAppointment,
  cancelAppointment,
  createPreBookingAppointment,
  getPreBookingAppointment,
};
