import { doPost, doGet } from '../fetch';

const createCheckoutSession = async (body) => {
  const session = await doPost('/b2c/stripe/create_checkout_session', body);
  return session;
};

const getPortalSession = async (body) => {
  const session = await doGet('/b2c/stripe/portal_session', body);
  return session;
};

export { createCheckoutSession, getPortalSession };
