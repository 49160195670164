import React, { useState, Fragment, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Iframe from 'react-iframe';
import styles from './EditIntakeFormPage.module.scss';
import classnames from 'classnames';

import messages from './messages';
import useIntl from '../../../hooks/useIntl';

import KAPIntakeForm from '../../../components/forms/user/KAPIntakeForm';
import Modal from '../../../components/common/Modal';
import Loading from '../../../components/common/Loading';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import Signature from '../../../components/Hellosign';
import Logo from '../../../components/Logo';
import KAPIntakeForm21 from '../../../components/forms/user/KAPIntakeForm21/KAPIntakeForm21';
import PersonalInfoForm from '../../../components/forms/user/PersonalInfoForm/PersonalInfoForm';
import BPSForm from '../../../components/forms/user/BPSForm/BPSForm';
import SolsticeForm from '../../../components/forms/user/SolsticeForm';
import AnahitaForm from '../../../components/forms/user/AnahitaForm';
import WildHeartForm from '../../../components/forms/user/WildHeartForm';

import { getForm, getConsetForm } from '../../../api/intakeForm';

const EditIntakeFormPage = ({ location, history }) => {
  const { formatMessage } = useIntl();
  const { id, type } = useParams();

  const [patientId /*setDrName*/] = useState(
    (location.state && location.state.patient_id) || null,
  );
  const [doctorId /*setDrName*/] = useState(
    (location.state && location.state.doctor_id) || null,
  );
  const [fact_form_id /*setDrName*/] = useState(
    (location.state && location.state.fact_form_id) || '',
  );
  const [templateName] = useState(
    (location.state && location.state.template_name) || '',
  );
  const [formData] = useState(
    (location.state && location.state.formData) || '',
  );
  const [dimForm] = useState((location.state && location.state.dimForm) || '');

  const [isLoading] = useState(false);
  const [isConfirmationModalShown, setIsConfirmationModalShown] =
    useState(false);
  const [, setInitialValues] = useState();
  const [consetFormValues, setConsetFormValues] = useState({});

  const fetchIntakeform = async (id, type) => {
    try {
      if (type === 'intakeform') {
        const { intakeForm1 } = await getForm(id);
        setInitialValues(intakeForm1);
      } else {
        const { intakeForm2 } = await getConsetForm(id);
        setConsetFormValues(intakeForm2);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) fetchIntakeform(id, type);
  }, [id, type]);

  const handleLeaveIntakeForm = () => {
    setIsConfirmationModalShown(false);
    history.push('/my-account/intake-forms');
  };

  const getStepContent = (step) => {
    switch (type) {
      case 'intakeform':
        switch (dimForm.intake_type) {
          case 'moosIntake':
            return (
              <div className={styles.intakeForm}>
                <KAPIntakeForm21
                  history={history}
                  patientId={patientId}
                  doctorId={doctorId}
                  fact_form_id={fact_form_id}
                  nextBtnLabel="Next"
                  backBtnLabel="Back"
                  submitBtnLabel="Submit"
                  isEditMode={true}
                />
              </div>
            );
          case 'patientDetailsIntake':
            return (
              <div className={styles.intakeForm}>
                <PersonalInfoForm
                  id={id}
                  patientId={patientId}
                  doctorId={doctorId}
                  fact_form_id={fact_form_id}
                />
              </div>
            );
          case 'bpsIntake':
            return (
              <div className={styles.intakeForm}>
                <BPSForm
                  fact_form_id={fact_form_id}
                  patientId={patientId}
                  doctorId={doctorId}
                  id={id}
                />
              </div>
            );
          case 'solsticeIntake':
            return (
              <div className={styles.intakeForm}>
                <SolsticeForm
                  fact_form_id={fact_form_id}
                  patientId={patientId}
                  doctorId={doctorId}
                  id={id}
                />
              </div>
            );
          case 'anahitaIntake':
            return (
              <div className={styles.intakeForm}>
                <AnahitaForm
                  fact_form_id={fact_form_id}
                  patientId={patientId}
                  doctorId={doctorId}
                  id={id}
                />
              </div>
            );
          case 'wildHeartIntake':
            return (
              <div className={styles.intakeForm}>
                <WildHeartForm
                  fact_form_id={fact_form_id}
                  patientId={patientId}
                  doctorId={doctorId}
                  id={id}
                />
              </div>
            );
          case '' || null:
            return (
              <div className={styles.intakeForm}>
                <KAPIntakeForm
                  id={id}
                  history={history}
                  patientId={patientId}
                  doctorId={doctorId}
                  fact_form_id={fact_form_id}
                  nextBtnLabel="Next"
                  backBtnLabel="Back"
                  submitBtnLabel="Submit"
                  isEditMode={true}
                />
              </div>
            );
          default:
            return;
        }
      case 'consentform':
        return (
          <>
            <div className={styles.consentForm}>
              {id ? (
                <Iframe
                  url={consetFormValues.link_to_signed_pdf}
                  width="900px"
                  height="600px"
                  id="consentForm"
                  className="consentForm"
                  display="initial"
                  position="relative"
                />
              ) : (
                <Signature
                  doctorId={doctorId}
                  patientId={patientId}
                  fact_form_id={fact_form_id}
                  templateName={templateName}
                  isType={false}
                  role="Patient"
                  formData={formData}
                />
              )}
            </div>
          </>
        );
      default:
        return;
    }
  };
  return isLoading ? (
    <Loading />
  ) : (
    <Fragment>
      <div className={styles.container}>
        <div className={classnames('desktop-only', styles.header)}>
          <div className={styles.logo}>
            <Link to="/my-account">
              <Logo />
            </Link>
          </div>
          <div className={styles['menu-right']}>
            <span
              className={styles.link}
              onClick={() => setIsConfirmationModalShown(true)}
            >
              {formatMessage(messages.LeaveLabel)}
            </span>
          </div>
        </div>
        <div className={classnames('mobile-only', styles.headerMobile)}>
          <div className={styles.logo}>
            <Link to="/my-account">
              <Logo />
            </Link>
          </div>
          <div className={styles.subHeader}>
            <div className={styles['menu-right']}>
              <span
                className={styles.link}
                onClick={() => setIsConfirmationModalShown(true)}
              >
                {formatMessage(messages.LeaveMobileLabel)}
              </span>
            </div>
          </div>
        </div>
        {getStepContent(type)}
        {isConfirmationModalShown && (
          <Modal className={styles.modal}>
            <div className={styles.content}>
              <h1>
                <b>{formatMessage(messages.confirmLeaveTitle)}</b>
              </h1>
              <p>{formatMessage(messages.confirmLeaveText)}</p>
              <PrimaryButton
                label={formatMessage(messages.confirmLeaveRecommendation)}
                className={styles.primaryButton}
                onClick={handleLeaveIntakeForm}
              />
              <SecondaryButton
                label={formatMessage(messages.confirmLeaveCancel)}
                className={styles.secondaryButton}
                onClick={() => setIsConfirmationModalShown(false)}
              />
            </div>
          </Modal>
        )}
      </div>
    </Fragment>
  );
};

export default EditIntakeFormPage;
