import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import { Cell, Grid } from 'react-foundation';
import FieldInput from '../../../common/fields/FieldInput/FieldInput';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import useIntl from '../../../../hooks/useIntl';
import { Column } from 'react-foundation';
import messages from './messages';
import styles from './KAPIntakeForm21.module.scss';
import Datepicker from '../../../common/Datepicker';
import FieldArrayItem from '../../../common/fields/FieldArrayItem';
import SecondaryButton from '../../../common/buttons/SecondaryButton';
import RadioButton from '../../../common/inputs/RadioButton/RadioButton';
import data from './data.json';
import LabeledCheckbox from '../../../common/inputs/LabeledCheckbox';
import Label from '../../../common/Label';
import moment from 'moment';
import Loading from '../../../common/Loading';
import { saveIntakeForm21 } from '../../../../api/intakeForm';

const preferredMethodOfContactValues = {
  email: 'Email',
  phone: 'Phone',
};

const { priorCurrentHistory } = data;

const sensitiveToMedicationValues = {
  sensitive: 'Sensitive',
  average: 'Average',
  insensitive: 'Insensitive',
  doNotKnow: "Don't know",
};

const pregnantValues = {
  yes: 'Yes',
  no: 'No',
  notApplicable: 'Not Applicable',
};

const significantHistoryOfTraumaValues = {
  extensive: 'Extensive',
  moderate: 'Moderate',
  limited: 'Limited',
  none: 'None',
};

const psychotherapyCompletedValues = {
  extensive: 'Extensive',
  moderate: 'Moderate',
  limited: 'Limited',
  none: 'None',
};

const typesDosesFrequencySettingsFields = [
  { placeholder: 'Types', name: 'types' },
  { placeholder: 'Doses', name: 'doses' },
  { placeholder: 'Frequency', name: 'frequency' },
  { placeholder: 'Settings', name: 'settings' },
];

const motionSicknessNauseaValues = {
  yes: 'Yes',
  no: 'No',
};

const antiNauseaMotionSicknessInPastValues = {
  yes: 'Yes',
  no: 'No',
};

const regularMeditationPracticeValues = {
  yes: 'Yes',
  no: 'No',
  sometimes: 'Sometimes',
};

const experienceWithPsychedelicsValues = {
  limited: 'Limited',
  intermediate: 'Intermediate',
  extensive: 'Extensive',
  none: 'None',
};

const experienceWithKetamineValues = {
  limited: 'Limited (1-5 times) ',
  intermediate: 'Intermediate (5-10 times)',
  extensive: 'Extensive (>10 times)',
  none: 'None',
};

const KAPIntakeForm21 = ({
  history,
  patientId,
  doctorId,
  fact_form_id,
  nextBtnLabel,
  submitBtnLabel,
  backBtnLabel,
  onCancel,
  parentOnChange,
  isEditMode,
}) => {
  const initialValues = {
    personalDetails: {
      name: '',
      preferredName: '',
      age: '',
      dob: '',
      phoneNumber: '',
      email: '',
    },
    preferredMethodOfContact: '',
    emergencyContact: { name: '', phoneNumber: '' },
    weight: '',
    pregnant: '',
    medicalIssue: '',
    currentMedications: '',
    medicalHistory: '',
    psychiatricHistory: '',
    medicationAllergies: '',
    foodAllergies: '',
    concernsForTreatment: '',
    sensitiveToMedication: '',
    priorCurrentHistory: [],
    historyExplanation: '',
    significantHistoryOfTrauma: '',
    psychotherapyCompleted: '',
    motionSicknessNausea: '',
    antiNauseaMotionSicknessInPast: '',
    regularMeditationPractice: '',
    experienceWithPsychedelics: '',
    typesDosesFrequencySettings: [
      { types: '', doses: '', frequency: '', settings: '' },
    ],
    experienceWithKetamine: '',
    pastKetamineExperience: '',
    concernsOnKetamine: '',
    intentionsGoalsOnKetamine: '',
  };
  const { formatMessage } = useIntl();

  const onKeyPress = (event) => {
    if (event.which === 13) {
      event.preventDefault();
    }
  };

  const validate = Yup.object().shape({
    personalDetails: Yup.object().shape({
      name: Yup.string().required('Field is required'),
      preferredName: Yup.string().required('Field is required'),
      age: Yup.number()
        .typeError('Amount must be a number')
        .min(1, 'Age must be at least 1')
        .max(99, 'Age must be at most 99')
        .required('Field is required'),
      dob: Yup.string().required('Field is required'),
      phoneNumber: Yup.number()
        .typeError('Amount must be a number')
        .required('Field is required'),
      email: Yup.string().email('Invalid email').required('Field is required'),
    }),
    preferredMethodOfContact: Yup.string().required('Field is required'),
    emergencyContact: Yup.object().shape({
      name: Yup.string().required('Field is required'),
      phoneNumber: Yup.number()
        .required('Field is required')
        .typeError('Amount must be a number'),
    }),
    weight: Yup.number()
      .required('Field is required')
      .typeError('Amount must be a number'),
    pregnant: Yup.string().required('Field is required'),
    medicationAllergies: Yup.string().required('Field is required'),
    foodAllergies: Yup.string().required('Field is required'),
    sensitiveToMedication: Yup.string().required('Field is required'),

    significantHistoryOfTrauma: Yup.string().required('Field is required'),
    psychotherapyCompleted: Yup.string().required('Field is required'),
    motionSicknessNausea: Yup.string().required('Field is required'),
    antiNauseaMotionSicknessInPast: Yup.string().required('Field is required'),
    regularMeditationPractice: Yup.string().required('Field is required'),

    experienceWithPsychedelics: Yup.string().required('Field is required'),

    experienceWithKetamine: Yup.string().required('Field is required'),

    priorCurrentHistory: Yup.array().nullable(),
  });

  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);

    setIsLoading(true);
    try {
      await saveIntakeForm21({
        patient_id: patientId,
        doctor_id: doctorId,
        fact_form_id,
        intake_form_values: { ...values },
      });
      history.push(`/my-account/intake-forms`);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.form}>
      <Formik
        validationSchema={validate}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
        enableReinitialize
        onKeyPress={onKeyPress}
        initialValues={initialValues}
        render={({
          values,
          handleChange,
          errors,
          touched,
          isValid,
          setFieldValue,
          handleBlur,
        }) => {
          return (
            <Form data-testid="KAP-intake-form">
              <Grid>
                <Cell
                  small={12}
                  medium={12}
                  className={styles['step-container']}
                >
                  <h2 className={styles.formTitle}>
                    {formatMessage(messages.formTitle)}
                  </h2>
                  <h4 className={styles.stepInfo}>Step {step} of 4</h4>
                </Cell>
                {step === 1 && (
                  <>
                    <Cell small={12} medium={12}>
                      <h4 className={styles.section}>Personal Details</h4>
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(
                          messages.personalDetails.nameLabel,
                        )}
                        placeholder="E.g., James"
                        errorText={
                          touched &&
                          errors &&
                          errors.personalDetails &&
                          touched.personalDetails &&
                          touched.personalDetails.name &&
                          errors.personalDetails.name
                        }
                        name="personalDetails.name"
                        value={values.personalDetails.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label="Preferred name"
                        placeholder="E.g., Jam"
                        errorText={
                          touched &&
                          errors &&
                          errors.personalDetails &&
                          touched.personalDetails &&
                          touched.personalDetails.preferredName &&
                          errors.personalDetails.preferredName
                        }
                        name="personalDetails.preferredName"
                        value={values.personalDetails.preferredName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12}>
                      <FieldInput
                        label={formatMessage(messages.personalDetails.age)}
                        placeholder="Age"
                        errorText={
                          touched &&
                          errors &&
                          errors.personalDetails &&
                          touched.personalDetails &&
                          touched.personalDetails.age &&
                          errors.personalDetails.age
                        }
                        name="personalDetails.age"
                        value={values.personalDetails.age}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={6}>
                      <Datepicker
                        label="Date of birth"
                        id="dob"
                        selected={
                          values.personalDetails.dob
                            ? new Date(values.personalDetails.dob)
                            : null
                        }
                        value={values.dob}
                        autoComplete="off"
                        name="dob"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText={moment(new Date()).format(
                          'dddd, MMM DD',
                        )}
                        dateFormat="dd MMM, yyyy"
                        onChange={(date) => {
                          setFieldValue('personalDetails.dob', date);
                        }}
                        required={true}
                        data-testid="edit_protocol_schedule_dob"
                      />
                    </Cell>
                    <Cell small={12} medium={6}>
                      <FieldInput
                        label={formatMessage(
                          messages.personalDetails.phoneNumber,
                        )}
                        errorText={
                          touched &&
                          errors &&
                          errors.personalDetails &&
                          touched.personalDetails &&
                          touched.personalDetails.phoneNumber &&
                          errors.personalDetails.phoneNumber
                        }
                        name="personalDetails.phoneNumber"
                        placeholder="Phone number"
                        value={values.personalDetails.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(messages.personalDetails.email)}
                        errorText={
                          touched &&
                          errors &&
                          errors.personalDetails &&
                          touched.personalDetails &&
                          touched.personalDetails.email &&
                          errors.personalDetails.email
                        }
                        name="personalDetails.email"
                        placeholder="Email"
                        value={values.personalDetails.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell className={styles.cell} large={12}>
                      <Label
                        errorText={
                          touched &&
                          errors &&
                          touched.preferredMethodOfContact &&
                          errors.preferredMethodOfContact
                        }
                      >
                        Preferred method of contact:
                      </Label>
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="preferredMethodOfContact"
                          />
                        )}
                        value={preferredMethodOfContactValues.email}
                        htmlFor={preferredMethodOfContactValues.email}
                        name="preferredMethodOfContact"
                        checked={
                          preferredMethodOfContactValues.email ===
                          values.preferredMethodOfContact
                        }
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'preferredMethodOfContact' },
                          });
                        }}
                        labelName="Email"
                        data-testid="intake_form email"
                        label-testid="intake_form email label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="preferredMethodOfContact"
                          />
                        )}
                        value={preferredMethodOfContactValues.phone}
                        htmlFor={preferredMethodOfContactValues.phone}
                        name="preferredMethodOfContact"
                        checked={
                          preferredMethodOfContactValues.phone ===
                          values.preferredMethodOfContact
                        }
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'preferredMethodOfContact' },
                          });
                        }}
                        labelName="Phone"
                        data-testid="intake_form phone"
                        label-testid="intake_form phone label"
                      />
                    </Cell>
                    <Cell small={12} medium={12}>
                      <h4 className={styles.section}>
                        {formatMessage(messages.emergencyContact)}
                      </h4>
                    </Cell>
                    <Cell small={12} medium={6}>
                      <FieldInput
                        label={formatMessage(messages.emergencyContact.name)}
                        errorText={
                          errors.emergencyContact &&
                          touched.emergencyContact &&
                          touched.emergencyContact.name &&
                          errors.emergencyContact.name
                        }
                        name="emergencyContact.name"
                        placeholder="Email"
                        value={values.emergencyContact.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={6}>
                      <FieldInput
                        label={formatMessage(
                          messages.emergencyContact.phoneNumber,
                        )}
                        errorText={
                          errors.emergencyContact &&
                          touched.emergencyContact &&
                          touched.emergencyContact.phoneNumber &&
                          errors.emergencyContact.phoneNumber
                        }
                        name="emergencyContact.phoneNumber"
                        placeholder="Phone Number"
                        value={values.emergencyContact.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell
                      small={12}
                      flexContainer
                      alignY="middle"
                      flexDirCol="all"
                    >
                      {nextBtnLabel && (
                        <PrimaryButton
                          disabled={
                            errors.personalDetails ||
                            errors.preferredMethodOfContact ||
                            errors.emergencyContact ||
                            (Object.entries(errors).length <= 0 && !isValid)
                          }
                          label={nextBtnLabel}
                          type="button"
                          className={styles['secondary_button']}
                          onClick={() => {
                            setStep(2);
                            window.scrollTo(0, 0);
                          }}
                        />
                      )}
                    </Cell>
                  </>
                )}
                {step === 2 && (
                  <>
                    <Cell small={12} medium={12}>
                      <h4 className={styles.section}>
                        {formatMessage(messages.medicalSafetyScreening)}
                      </h4>
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(messages.weight)}
                        errorText={
                          errors && touched && touched.weight && errors.weight
                        }
                        name="weight"
                        placeholder="Weight"
                        value={values.weight}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell className={styles.cell} large={12}>
                      <Label
                        errorText={
                          touched &&
                          errors &&
                          touched.pregnant &&
                          errors.pregnant
                        }
                      >
                        Are you pregnant, or is there a chance you could be
                        pregnant?
                      </Label>
                      <Field
                        component={(field) => (
                          <RadioButton {...field} name="pregnant" />
                        )}
                        value={pregnantValues.yes}
                        htmlFor={'pregnant' + pregnantValues.yes}
                        name="pregnant"
                        checked={pregnantValues.yes === values.pregnant}
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'pregnant' },
                          });
                        }}
                        labelName="Yes"
                        data-testid="intake_form pregnant yes"
                        label-testid="intake_form pregnant yes label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton {...field} name="pregnant" />
                        )}
                        value={pregnantValues.no}
                        htmlFor={'pregnant' + pregnantValues.no}
                        name="pregnant"
                        checked={pregnantValues.no === values.pregnant}
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'pregnant' },
                          });
                        }}
                        labelName="No"
                        data-testid="intake_form pregnant no"
                        label-testid="intake_form pregnant no label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton {...field} name="pregnant" />
                        )}
                        value={pregnantValues.notApplicable}
                        htmlFor={'pregnant' + pregnantValues.notApplicable}
                        name="pregnant"
                        checked={
                          pregnantValues.notApplicable === values.pregnant
                        }
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'pregnant' },
                          });
                        }}
                        labelName="Not applicable"
                        data-testid="intake_form_not_applicable_phone"
                        label-testid="intake_form_not_applicable_label"
                      />
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(messages.medicalIssue)}
                        errorText={
                          errors &&
                          touched &&
                          touched.medicalIssue &&
                          errors.medicalIssue
                        }
                        name="medicalIssue"
                        placeholder="Medical Issue"
                        value={values.medicalIssue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(messages.currentMedications)}
                        errorText={
                          errors &&
                          touched &&
                          touched.currentMedications &&
                          errors.currentMedications
                        }
                        name="currentMedications"
                        placeholder="Medical Issue"
                        value={values.currentMedications}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(messages.medicalHistory)}
                        errorText={
                          errors &&
                          touched &&
                          touched.medicalHistory &&
                          errors.medicalHistory
                        }
                        name="medicalHistory"
                        placeholder="Medical History"
                        value={values.medicalHistory}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(messages.psychiatricHistory)}
                        errorText={
                          errors &&
                          touched &&
                          touched.psychiatricHistory &&
                          errors.psychiatricHistory
                        }
                        name="psychiatricHistory"
                        placeholder="Medical History"
                        value={values.psychiatricHistory}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell
                      small={12}
                      flexContainer
                      alignY="middle"
                      alignX="center"
                      className={styles['actions-container']}
                    >
                      {backBtnLabel && (
                        <SecondaryButton
                          label={backBtnLabel}
                          className={styles['secondary_button']}
                          type="button"
                          onClick={() => {
                            setStep(1);
                            window.scrollTo(0, 0);
                          }}
                        />
                      )}
                      {nextBtnLabel && (
                        <PrimaryButton
                          disabled={errors.weight || errors.pregnant}
                          label={nextBtnLabel}
                          type="button"
                          className={styles['secondary_button']}
                          onClick={() => {
                            setStep(3);
                            window.scrollTo(0, 0);
                          }}
                        />
                      )}
                    </Cell>
                  </>
                )}
                {step === 3 && (
                  <>
                    <Cell small={12} medium={12}>
                      <h4 className={styles.section}>
                        Medial Safety Screening
                      </h4>
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(messages.medicationAllergies)}
                        errorText={
                          touched &&
                          errors &&
                          touched.medicationAllergies &&
                          errors.medicationAllergies
                        }
                        name="medicationAllergies"
                        placeholder="Medication Allergies"
                        value={values.medicationAllergies}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>

                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(messages.foodAllergies)}
                        errorText={
                          errors &&
                          touched &&
                          touched.foodAllergies &&
                          errors.foodAllergies
                        }
                        name="foodAllergies"
                        placeholder="Food Allergies"
                        value={values.foodAllergies}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>

                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(messages.concernsForTreatment)}
                        errorText={
                          touched &&
                          errors &&
                          touched.concernsForTreatment &&
                          errors.concernsForTreatment
                        }
                        name="concernsForTreatment"
                        placeholder="Concerns"
                        value={values.concernsForTreatment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell className={styles.cell} large={12}>
                      <Label
                        errorText={
                          touched &&
                          errors &&
                          touched.sensitiveToMedication &&
                          errors.sensitiveToMedication
                        }
                      >
                        Do you tend to be sensitive or insensitive to
                        medications?
                      </Label>
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="sensitiveToMedication"
                          />
                        )}
                        value={sensitiveToMedicationValues.sensitive}
                        htmlFor={
                          'sensitiveToMedication' +
                          sensitiveToMedicationValues.sensitive
                        }
                        name="sensitiveToMedication"
                        checked={
                          sensitiveToMedicationValues.sensitive ===
                          values.sensitiveToMedication
                        }
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'sensitiveToMedication' },
                          });
                        }}
                        labelName="Sensitive"
                        data-testid="intake_form_sensitive_medications"
                        label-testid="intake_form_sensitive_medications_label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="sensitiveToMedication"
                          />
                        )}
                        value={sensitiveToMedicationValues.average}
                        htmlFor={
                          'sensitiveToMedication' +
                          sensitiveToMedicationValues.average
                        }
                        name="sensitiveToMedication"
                        checked={
                          sensitiveToMedicationValues.average ===
                          values.sensitiveToMedication
                        }
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'sensitiveToMedication' },
                          });
                        }}
                        labelName="Average"
                        data-testid="intake_form_average_medications"
                        label-testid="intake_form_average_medications_label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="sensitiveToMedication"
                          />
                        )}
                        value={sensitiveToMedicationValues.insensitive}
                        htmlFor={
                          'sensitiveToMedication' +
                          sensitiveToMedicationValues.insensitive
                        }
                        name="sensitiveToMedication"
                        checked={
                          sensitiveToMedicationValues.insensitive ===
                          values.sensitiveToMedication
                        }
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'sensitiveToMedication' },
                          });
                        }}
                        labelName="Insensitive"
                        data-testid="intake_form_insensitive_medications"
                        label-testid="intake_form_insensitive_medications_label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="sensitiveToMedication"
                          />
                        )}
                        value={sensitiveToMedicationValues.doNotKnow}
                        htmlFor={
                          'sensitiveToMedication' +
                          sensitiveToMedicationValues.doNotKnow
                        }
                        name="sensitiveToMedication"
                        checked={
                          sensitiveToMedicationValues.doNotKnow ===
                          values.sensitiveToMedication
                        }
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'sensitiveToMedication' },
                          });
                        }}
                        labelName="Don't know"
                        data-testid="intake_form_doNotKnow_medications"
                        label-testid="intake_form_doNotKnow_medications_label"
                      />
                    </Cell>

                    <Cell
                      small={12}
                      medium={12}
                      className={styles['checkboxBtn']}
                    >
                      <Label
                        errorText={
                          touched &&
                          errors &&
                          touched.priorCurrentHistory &&
                          errors.priorCurrentHistory
                        }
                      >
                        Do you have any prior/current history of any of the
                        following?
                      </Label>
                      {priorCurrentHistory.choices.map((choice) => (
                        <Column
                          small={12}
                          medium={7}
                          key={choice.key}
                          className={styles['grid-cell']}
                        >
                          <LabeledCheckbox
                            checkboxKey={choice.key}
                            name="priorCurrentHistory"
                            labelName={choice.title}
                            value={choice.key}
                            checked={values.priorCurrentHistory.includes(
                              choice.key,
                            )}
                            onChange={(e) => {
                              const { checked, value } = e.target;
                              if (checked) {
                                setFieldValue('priorCurrentHistory', [
                                  ...values.priorCurrentHistory,
                                  value,
                                ]);
                              } else {
                                setFieldValue(
                                  'priorCurrentHistory',
                                  values.priorCurrentHistory.filter(
                                    (v) => v !== value,
                                  ),
                                );
                              }
                            }}
                            data-testid={`intake_form_21_priorCurrentHistory ${choice.title}`}
                            label-testid={`intake_form_21_priorCurrentHistory label ${choice.title}`}
                            error-testid={`intake_form_21_priorCurrentHistory error ${choice.title}`}
                          />
                        </Column>
                      ))}
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(messages.historyExplanation)}
                        errorText={
                          errors &&
                          touched &&
                          touched.historyExplanation &&
                          errors.historyExplanation
                        }
                        name="historyExplanation"
                        placeholder="Concerns"
                        value={values.historyExplanation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell
                      small={12}
                      flexContainer
                      alignY="middle"
                      alignX="center"
                      className={styles['actions-container']}
                    >
                      {backBtnLabel && (
                        <SecondaryButton
                          label={backBtnLabel}
                          className={styles['secondary_button']}
                          type="button"
                          onClick={() => {
                            setStep(2);
                            window.scrollTo(0, 0);
                          }}
                        />
                      )}
                      {nextBtnLabel && (
                        <PrimaryButton
                          label={nextBtnLabel}
                          disabled={
                            errors.medicationAllergies ||
                            errors.foodAllergies ||
                            errors.sensitiveToMedication
                          }
                          type="button"
                          className={styles['secondary_button']}
                          onClick={() => {
                            setStep(4);
                            window.scrollTo(0, 0);
                          }}
                        />
                      )}
                    </Cell>
                  </>
                )}

                {step === 4 && (
                  <>
                    <Cell small={12} medium={12}>
                      <h4 className={styles.section}>
                        Additional Background Questions
                      </h4>
                    </Cell>
                    <Cell className={styles.cell} large={12}>
                      <Label
                        errorText={
                          touched &&
                          errors &&
                          touched.significantHistoryOfTrauma &&
                          errors.significantHistoryOfTrauma
                        }
                      >
                        Do you have a significant history of trauma?
                      </Label>
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="significantHistoryOfTrauma"
                          />
                        )}
                        value={significantHistoryOfTraumaValues.extensive}
                        htmlFor={
                          'significantHistoryOfTrauma' +
                          significantHistoryOfTraumaValues.extensive
                        }
                        name="significantHistoryOfTrauma"
                        checked={
                          significantHistoryOfTraumaValues.extensive ===
                          values.significantHistoryOfTrauma
                        }
                        onChange={(value) => {
                          handleChange({
                            target: {
                              value,
                              name: 'significantHistoryOfTrauma',
                            },
                          });
                        }}
                        labelName="Extensive"
                        data-testid="intake_form_significantHistoryOfTrauma_extensive"
                        label-testid="intake_form_significantHistoryOfTrauma_extensive_label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="significantHistoryOfTrauma"
                          />
                        )}
                        value={significantHistoryOfTraumaValues.moderate}
                        htmlFor={
                          'significantHistoryOfTrauma' +
                          significantHistoryOfTraumaValues.moderate
                        }
                        name="significantHistoryOfTrauma"
                        checked={
                          significantHistoryOfTraumaValues.moderate ===
                          values.significantHistoryOfTrauma
                        }
                        onChange={(value) => {
                          handleChange({
                            target: {
                              value,
                              name: 'significantHistoryOfTrauma',
                            },
                          });
                        }}
                        labelName="Moderate"
                        data-testid="intake_form_significantHistoryOfTrauma_moderate"
                        label-testid="intake_form_significantHistoryOfTrauma_moderate_label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="significantHistoryOfTrauma"
                          />
                        )}
                        value={significantHistoryOfTraumaValues.limited}
                        htmlFor={
                          'significantHistoryOfTrauma' +
                          significantHistoryOfTraumaValues.limited
                        }
                        name="significantHistoryOfTrauma"
                        checked={
                          significantHistoryOfTraumaValues.limited ===
                          values.significantHistoryOfTrauma
                        }
                        onChange={(value) => {
                          handleChange({
                            target: {
                              value,
                              name: 'significantHistoryOfTrauma',
                            },
                          });
                        }}
                        labelName="Limited"
                        data-testid="intake_form_significantHistoryOfTrauma_limited"
                        label-testid="intake_form_significantHistoryOfTrauma_limited_label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="significantHistoryOfTrauma"
                          />
                        )}
                        value={significantHistoryOfTraumaValues.none}
                        htmlFor={
                          'significantHistoryOfTrauma' +
                          significantHistoryOfTraumaValues.none
                        }
                        name="significantHistoryOfTrauma"
                        checked={
                          significantHistoryOfTraumaValues.none ===
                          values.significantHistoryOfTrauma
                        }
                        onChange={(value) => {
                          handleChange({
                            target: {
                              value,
                              name: 'significantHistoryOfTrauma',
                            },
                          });
                        }}
                        labelName="None"
                        data-testid="intake_form_significantHistoryOfTrauma_none"
                        label-testid="intake_form_significantHistoryOfTrauma_none_label"
                      />
                    </Cell>

                    <Cell className={styles.cell} large={12}>
                      <Label
                        errorText={
                          touched &&
                          errors &&
                          touched.psychotherapyCompleted &&
                          errors.psychotherapyCompleted
                        }
                      >
                        How much psychotherapy have you completed?
                      </Label>
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="psychotherapyCompleted"
                          />
                        )}
                        value={psychotherapyCompletedValues.extensive}
                        htmlFor={
                          'psychotherapyCompleted' +
                          psychotherapyCompletedValues.extensive
                        }
                        name="psychotherapyCompleted"
                        checked={
                          psychotherapyCompletedValues.extensive ===
                          values.psychotherapyCompleted
                        }
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'psychotherapyCompleted' },
                          });
                        }}
                        labelName="Extensive"
                        data-testid="intake_form_psychotherapyCompleted_extensive"
                        label-testid="intake_form_psychotherapyCompleted_extensive_label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="psychotherapyCompleted"
                          />
                        )}
                        value={psychotherapyCompletedValues.moderate}
                        htmlFor={
                          'psychotherapyCompleted' +
                          psychotherapyCompletedValues.moderate
                        }
                        name="psychotherapyCompleted"
                        checked={
                          psychotherapyCompletedValues.moderate ===
                          values.psychotherapyCompleted
                        }
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'psychotherapyCompleted' },
                          });
                        }}
                        labelName="Moderate"
                        data-testid="intake_form_psychotherapyCompleted_moderate"
                        label-testid="intake_form_psychotherapyCompleted_moderate_label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="psychotherapyCompleted"
                          />
                        )}
                        value={psychotherapyCompletedValues.limited}
                        htmlFor={
                          'psychotherapyCompleted' +
                          psychotherapyCompletedValues.limited
                        }
                        name="psychotherapyCompleted"
                        checked={
                          psychotherapyCompletedValues.limited ===
                          values.psychotherapyCompleted
                        }
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'psychotherapyCompleted' },
                          });
                        }}
                        labelName="Limited"
                        data-testid="intake_form_psychotherapyCompleted_limited"
                        label-testid="intake_form_psychotherapyCompleted_limited_label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="psychotherapyCompleted"
                          />
                        )}
                        value={psychotherapyCompletedValues.none}
                        htmlFor={
                          'psychotherapyCompleted' +
                          psychotherapyCompletedValues.none
                        }
                        name="psychotherapyCompleted"
                        checked={
                          psychotherapyCompletedValues.none ===
                          values.psychotherapyCompleted
                        }
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'psychotherapyCompleted' },
                          });
                        }}
                        labelName="None"
                        data-testid="intake_form_psychotherapyCompleted_none"
                        label-testid="intake_form_psychotherapyCompleted_none_label"
                      />
                    </Cell>
                    <Cell className={styles.cell} large={12}>
                      <Label
                        errorText={
                          touched &&
                          errors &&
                          touched.motionSicknessNausea &&
                          errors.motionSicknessNausea
                        }
                      >
                        Do you have a history of motion sickness or are you
                        quick to nausea?
                      </Label>
                      <Field
                        component={(field) => (
                          <RadioButton {...field} name="motionSicknessNausea" />
                        )}
                        value={motionSicknessNauseaValues.yes}
                        htmlFor={
                          'motionSicknessNausea' +
                          motionSicknessNauseaValues.yes
                        }
                        name="motionSicknessNausea"
                        checked={
                          motionSicknessNauseaValues.yes ===
                          values.motionSicknessNausea
                        }
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'motionSicknessNausea' },
                          });
                        }}
                        labelName="Yes"
                        data-testid="intake_form_motionSicknessNausea_yes"
                        label-testid="intake_form_motionSicknessNausea_yes label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton {...field} name="motionSicknessNausea" />
                        )}
                        value={motionSicknessNauseaValues.no}
                        htmlFor={
                          'motionSicknessNausea' + motionSicknessNauseaValues.no
                        }
                        name="motionSicknessNausea"
                        checked={
                          motionSicknessNauseaValues.no ===
                          values.motionSicknessNausea
                        }
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'motionSicknessNausea' },
                          });
                        }}
                        labelName="No"
                        data-testid="intake_form_motionSicknessNausea_no"
                        label-testid="intake_form_motionSicknessNausea_no label"
                      />
                    </Cell>

                    <Cell className={styles.cell} large={12}>
                      <Label
                        errorText={
                          touched &&
                          errors &&
                          touched.antiNauseaMotionSicknessInPast &&
                          errors.antiNauseaMotionSicknessInPast
                        }
                      >
                        Have you required anti-nausea or motion-sickness
                        medication in the past?
                      </Label>
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="antiNauseaMotionSicknessInPast"
                          />
                        )}
                        value={antiNauseaMotionSicknessInPastValues.yes}
                        htmlFor={
                          'antiNauseaMotionSicknessInPast' +
                          antiNauseaMotionSicknessInPastValues.yes
                        }
                        name="antiNauseaMotionSicknessInPast"
                        checked={
                          antiNauseaMotionSicknessInPastValues.yes ===
                          values.antiNauseaMotionSicknessInPast
                        }
                        onChange={(value) => {
                          handleChange({
                            target: {
                              value,
                              name: 'antiNauseaMotionSicknessInPast',
                            },
                          });
                        }}
                        labelName="Yes"
                        data-testid="intake_form_antiNauseaMotionSicknessInPast_yes"
                        label-testid="intake_form_antiNauseaMotionSicknessInPast_yes label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="antiNauseaMotionSicknessInPast"
                          />
                        )}
                        value={antiNauseaMotionSicknessInPastValues.no}
                        htmlFor={
                          'antiNauseaMotionSicknessInPast' +
                          antiNauseaMotionSicknessInPastValues.no
                        }
                        name="antiNauseaMotionSicknessInPast"
                        checked={
                          antiNauseaMotionSicknessInPastValues.no ===
                          values.antiNauseaMotionSicknessInPast
                        }
                        onChange={(value) => {
                          handleChange({
                            target: {
                              value,
                              name: 'antiNauseaMotionSicknessInPast',
                            },
                          });
                        }}
                        labelName="No"
                        data-testid="intake_form_antiNauseaMotionSicknessInPast_no"
                        label-testid="intake_form_antiNauseaMotionSicknessInPast_no label"
                      />
                    </Cell>

                    <Cell className={styles.cell} large={12}>
                      <Label
                        errorText={
                          touched &&
                          errors &&
                          touched.regularMeditationPractice &&
                          errors.regularMeditationPractice
                        }
                      >
                        Do you have a regular meditation practice?
                      </Label>
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="regularMeditationPractice"
                          />
                        )}
                        labelName="Yes"
                        value={regularMeditationPracticeValues.yes}
                        htmlFor={
                          'regularMeditationPractice' +
                          regularMeditationPracticeValues.yes
                        }
                        name="regularMeditationPractice"
                        onChange={(value) => {
                          handleChange({
                            target: {
                              value,
                              name: 'regularMeditationPractice',
                            },
                          });
                        }}
                        checked={
                          regularMeditationPracticeValues.yes ===
                          values.regularMeditationPractice
                        }
                        data-testid="intake_form_regularMeditationPractice_yes"
                        label-testid="intake_form_regularMeditationPractice_yes label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="regularMeditationPractice"
                          />
                        )}
                        labelName="No"
                        value={regularMeditationPracticeValues.no}
                        htmlFor={
                          'regularMeditationPractice' +
                          regularMeditationPracticeValues.no
                        }
                        name="regularMeditationPractice"
                        checked={
                          regularMeditationPracticeValues.no ===
                          values.regularMeditationPractice
                        }
                        onChange={(value) => {
                          handleChange({
                            target: {
                              value,
                              name: 'regularMeditationPractice',
                            },
                          });
                        }}
                        data-testid="intake_form_regularMeditationPractice_no"
                        label-testid="intake_form_regularMeditationPractice_no label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="regularMeditationPractice"
                          />
                        )}
                        labelName="Sometimes"
                        value={regularMeditationPracticeValues.sometimes}
                        htmlFor={
                          'regularMeditationPractice' +
                          regularMeditationPracticeValues.sometimes
                        }
                        name="regularMeditationPractice"
                        checked={
                          regularMeditationPracticeValues.sometimes ===
                          values.regularMeditationPractice
                        }
                        onChange={(value) => {
                          handleChange({
                            target: {
                              value,
                              name: 'regularMeditationPractice',
                            },
                          });
                        }}
                        data-testid="intake_form_regularMeditationPractice_sometimes"
                        label-testid="intake_form_regularMeditationPractice_sometimes label"
                      />
                    </Cell>

                    <Cell className={styles.cell} large={12}>
                      <Label
                        errorText={
                          touched &&
                          errors &&
                          touched.experienceWithPsychedelics &&
                          errors.experienceWithPsychedelics
                        }
                      >
                        Prior experience with psychedelics?
                      </Label>
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="experienceWithPsychedelics"
                          />
                        )}
                        value={experienceWithPsychedelicsValues.extensive}
                        htmlFor={
                          'experienceWithPsychedelics' +
                          experienceWithPsychedelicsValues.extensive
                        }
                        name="experienceWithPsychedelics"
                        checked={
                          experienceWithPsychedelicsValues.extensive ===
                          values.experienceWithPsychedelics
                        }
                        onChange={(value) => {
                          handleChange({
                            target: {
                              value,
                              name: 'experienceWithPsychedelics',
                            },
                          });
                        }}
                        labelName="Extensive"
                        data-testid="intake_form_experienceWithPsychedelics_extensive"
                        label-testid="intake_form_experienceWithPsychedelics_extensive_label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="experienceWithPsychedelics"
                          />
                        )}
                        value={experienceWithPsychedelicsValues.intermediate}
                        htmlFor={
                          'experienceWithPsychedelics' +
                          experienceWithPsychedelicsValues.intermediate
                        }
                        name="experienceWithPsychedelics"
                        checked={
                          experienceWithPsychedelicsValues.intermediate ===
                          values.experienceWithPsychedelics
                        }
                        onChange={(value) => {
                          handleChange({
                            target: {
                              value,
                              name: 'experienceWithPsychedelics',
                            },
                          });
                        }}
                        labelName="Intermediate"
                        data-testid="intake_form_experienceWithPsychedelics_intermediate"
                        label-testid="intake_form_experienceWithPsychedelics_intermediate_label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="experienceWithPsychedelics"
                          />
                        )}
                        value={experienceWithPsychedelicsValues.limited}
                        htmlFor={
                          'experienceWithPsychedelics' +
                          experienceWithPsychedelicsValues.limited
                        }
                        name="experienceWithPsychedelics"
                        checked={
                          experienceWithPsychedelicsValues.limited ===
                          values.experienceWithPsychedelics
                        }
                        onChange={(value) => {
                          handleChange({
                            target: {
                              value,
                              name: 'experienceWithPsychedelics',
                            },
                          });
                        }}
                        labelName="Limited"
                        data-testid="intake_form_experienceWithPsychedelics_limited"
                        label-testid="intake_form_experienceWithPsychedelics_limited_label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="experienceWithPsychedelics"
                          />
                        )}
                        value={experienceWithPsychedelicsValues.none}
                        htmlFor={
                          'experienceWithPsychedelics' +
                          experienceWithPsychedelicsValues.none
                        }
                        name="experienceWithPsychedelics"
                        checked={
                          experienceWithPsychedelicsValues.none ===
                          values.experienceWithPsychedelics
                        }
                        onChange={(value) => {
                          handleChange({
                            target: {
                              value,
                              name: 'experienceWithPsychedelics',
                            },
                          });
                        }}
                        labelName="None"
                        data-testid="intake_form_experienceWithPsychedelics_none"
                        label-testid="intake_form_experienceWithPsychedelics_none_label"
                      />
                    </Cell>

                    <Cell small={12} medium={12}>
                      <FieldArray
                        name="typesDosesFrequencySettings"
                        render={({ remove, push }) => (
                          <FieldArrayItem
                            arrayName="typesDosesFrequencySettings"
                            label="Please indicate types, doses, frequency, and settings if known"
                            remove={remove}
                            push={push}
                            items={values.typesDosesFrequencySettings}
                            fields={typesDosesFrequencySettingsFields}
                            maxLength={5}
                          />
                        )}
                      />
                    </Cell>

                    <Cell className={styles.cell} large={12}>
                      <Label
                        errorText={
                          touched &&
                          errors &&
                          touched.experienceWithKetamine &&
                          errors.experienceWithKetamine
                        }
                      >
                        Prior experience with ketamine?
                      </Label>

                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="experienceWithKetamine"
                          />
                        )}
                        value={experienceWithKetamineValues.limited}
                        htmlFor={
                          'experienceWithKetamine' +
                          experienceWithKetamineValues.limited
                        }
                        name="experienceWithKetamine"
                        checked={
                          experienceWithKetamineValues.limited ===
                          values.experienceWithKetamine
                        }
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'experienceWithKetamine' },
                          });
                        }}
                        labelName="Limited (1-5 times)"
                        data-testid="intake_form_experienceWithKetamine_limited"
                        label-testid="intake_form_experienceWithKetamine_limited_label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="experienceWithKetamine"
                          />
                        )}
                        value={experienceWithKetamineValues.intermediate}
                        htmlFor={
                          'experienceWithKetamine' +
                          experienceWithKetamineValues.intermediate
                        }
                        name="experienceWithKetamine"
                        checked={
                          experienceWithKetamineValues.intermediate ===
                          values.experienceWithKetamine
                        }
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'experienceWithKetamine' },
                          });
                        }}
                        labelName="Intermediate (5-10 times)"
                        data-testid="intake_form_experienceWithKetamine_Intermediate"
                        label-testid="intake_form_experienceWithKetamine_Intermediate_label"
                      />
                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="experienceWithKetamine"
                          />
                        )}
                        value={experienceWithKetamineValues.extensive}
                        htmlFor={
                          'experienceWithKetamine' +
                          experienceWithKetamineValues.extensive
                        }
                        name="experienceWithKetamine"
                        checked={
                          experienceWithKetamineValues.extensive ===
                          values.experienceWithKetamine
                        }
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'experienceWithKetamine' },
                          });
                        }}
                        labelName="Extensive (>10 times)"
                        data-testid="intake_form_experienceWithKetamine_extensive"
                        label-testid="intake_form_experienceWithKetamine_extensive_label"
                      />

                      <Field
                        component={(field) => (
                          <RadioButton
                            {...field}
                            name="experienceWithKetamine"
                          />
                        )}
                        value={experienceWithKetamineValues.none}
                        htmlFor={
                          'experienceWithKetamine' +
                          experienceWithKetamineValues.none
                        }
                        name="experienceWithKetamine"
                        checked={
                          experienceWithKetamineValues.none ===
                          values.experienceWithKetamine
                        }
                        onChange={(value) => {
                          handleChange({
                            target: { value, name: 'experienceWithKetamine' },
                          });
                        }}
                        labelName="None"
                        data-testid="intake_form_experienceWithKetamine_none"
                        label-testid="intake_form_experienceWithKetamine_none_label"
                      />
                    </Cell>

                    <Cell small={12} medium={12}>
                      <FieldInput
                        label="Briefly describe your past ketamine experience(s)"
                        errorText={
                          errors &&
                          touched &&
                          touched.pastKetamineExperience &&
                          errors.pastKetamineExperience
                        }
                        name="pastKetamineExperience"
                        placeholder="Ketamine experience"
                        value={values.pastKetamineExperience}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label="How do you feel about taking ketamine? Any concerns?"
                        errorText={
                          errors &&
                          touched &&
                          touched.concernsOnKetamine &&
                          errors.concernsOnKetamine
                        }
                        name="concernsOnKetamine"
                        placeholder="Ketamine concerns"
                        value={values.concernsOnKetamine}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>

                    <Cell small={12} medium={12}>
                      <FieldInput
                        label="Please describe your intentions and goals of working with ketamine"
                        errorText={
                          errors &&
                          touched &&
                          touched.intentionsGoalsOnKetamine &&
                          errors.intentionsGoalsOnKetamine
                        }
                        name="intentionsGoalsOnKetamine"
                        placeholder="Intentions and goals of working with ketamine"
                        value={values.intentionsGoalsOnKetamine}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Cell>

                    <Cell
                      small={12}
                      flexContainer
                      alignY="middle"
                      className={styles['actions-container']}
                    >
                      {backBtnLabel && (
                        <SecondaryButton
                          label={backBtnLabel}
                          className={styles['secondary_button']}
                          type="button"
                          onClick={() => {
                            setStep(3);
                            window.scrollTo(0, 0);
                          }}
                        />
                      )}
                      {submitBtnLabel && (
                        <PrimaryButton
                          disabled={Object.entries(errors).length > 0}
                          label={submitBtnLabel}
                          type="submit"
                          className={styles['secondary_button']}
                        />
                      )}
                    </Cell>
                  </>
                )}

                <div></div>
              </Grid>
            </Form>
          );
        }}
      />
    </div>
  );
};

KAPIntakeForm21.propTypes = {
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  nextBtnLabel: PropTypes.string,
  backBtnLabel: PropTypes.string,
  submitBtnLabel: PropTypes.string,
};

KAPIntakeForm21.defaultProps = {};

export default KAPIntakeForm21;
