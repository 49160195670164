import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import ReactSwing from 'react-swing';
import styles from './SwipeCardCustom.module.scss';

const useStyles = makeStyles({
  card: {
    height: '430px',
    width: '400px',
    maxWidth: '400px',
    border: '2px solid #000000',
    'border-radius': '8px',
    'background-color': '#F6F6F6',
    'margin-top': '10px',
    'margin-bottom': '20px',
    '@media (max-width: 767px)': {
      height: 'auto',
      width: '240px',
      maxWidth: '240px',
    },
  },
  media: {
    height: '380px',
    margin: '0px 5px;',
    '@media (max-width: 767px)': {
      height: '220px',
      margin: '0px 7px 7px 7px',
    },
  },
  content: {
    color: '#000000',
    'font-family': 'Montserrat',
    'font-size': '18px',
    'font-weight': '600',
    'letter-spacing': '0',
    'line-height': '24px',
    padding: '8px',
    '@media (max-width: 767px)': {
      'line-height': '22px',
      'font-size': '16px',
      padding: '8px',
    },
  },
});

const App = ({ subtitle, description, imageURL, onSwipe }) => {
  const stackEl = React.createRef();
  const classes = useStyles();
  const [stack, setStack] = useState([]);
  const [cardThrown, setCardThrown] = useState(false);

  // Event Handlers
  const throwOutCard = (e) => {
    console.log('throwout', e);
    console.log('stack', stack);
    setCardThrown(true);
  };
  const thrownInCard = (e) => {
    console.log('thrown in', e);
    console.log('stack', stack);
    setCardThrown(false);
  };

  const swingConfig = {
    allowedDirections: [ReactSwing.DIRECTION.LEFT, ReactSwing.DIRECTION.RIGHT],
    throwOutConfidence: (xOffset, yOffset, element) => {
      console.log('throwOutConfidence', Math.abs(xOffset), Math.abs(yOffset));
      if (Math.abs(xOffset) >= 190) {
        return 1;
      }
      return 0;
    },
  };
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <ReactSwing
          className="stack"
          setStack={(stack) => setStack(stack)}
          ref={stackEl}
          config={swingConfig}
          throwout={throwOutCard}
          throwoutleft={() => onSwipe(false)}
          throwoutright={() => onSwipe(true)}
          throwin={thrownInCard}
        >
          <Card className={classes.card} ref="card1">
            {subtitle && (
              <CardContent className={classes.content}>
                <div className={classes.subtitle}>{subtitle}</div>
              </CardContent>
            )}
            {imageURL && (
              <CardMedia className={classes.media} image={imageURL} />
            )}
            {description && (
              <CardContent>
                <div>{description}</div>
              </CardContent>
            )}
          </Card>
        </ReactSwing>
        {cardThrown && <div>Card thrown!</div>}
        <div className="controls"></div>
      </div>
    </div>
  );
};

export default App;
