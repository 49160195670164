import { defineMessages } from 'react-intl';

export default defineMessages({
  resetModalTitle: {
    id: 'pages.formsList.modalTitle',
    description: 'FormList Reset confirmation modal title',
    defaultMessage: 'Reset Form',
  },
  resetModalText: {
    id: 'pages.formsList.modalText',
    description: 'FormList Reset  confirmation modal text',
    defaultMessage: 'Are you sure you want to reset this form?',
  },
  resetModalButtonPrimary: {
    id: 'pages.formsList.modalButtonPrimary',
    description: 'FormList Reset  confirmation primary button label',
    defaultMessage: 'Reset',
  },
  resetModalButtonSecondary: {
    id: 'pages.formsList.modalButtonSecondary',
    description: 'FormList Reset confirmation secondary button label',
    defaultMessage: 'Cancel',
  },
  messageModalText: {
    id: 'pages.formsList.message',
    description: 'Please complete the Informed Consent Form before proceeding',
    defaultMessage:
      'Please complete the Informed Consent Form before proceeding',
  },
});
