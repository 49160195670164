export const binaryResponses = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

const mapOptionsValues = (values) =>
  values.map((e) => ({
    label: e,
    value: e,
  }));

export const stepOneFormStructure = [
  {
    id: 'name',
    type: 'textInput',
    label: 'name',
  },
  {
    id: 'dob',
    type: 'textInput',
    label: 'dob',
  },
  {
    id: 'regular_updates_permission',
    type: 'radio',
    options: binaryResponses,
    label: 'regularUpdatesPermission',
  },
  {
    id: 'current_therapist',
    type: 'textInput',
    label: 'currentTherapist',
  },
  {
    id: 'therapist_phone',
    type: 'textInput',
    label: 'therapistPhone',
  },
  {
    id: 'problems_description',
    type: 'textInput',
    label: 'problemsDescription',
  },
  {
    id: 'treatment_goals',
    type: 'textInput',
    label: 'treatmentGoals',
  },
  {
    id: 'treatment_checklist',
    type: 'checkbox',
    label: 'treatmentChecklist',
    options: mapOptionsValues([
      'Anxiety attacks',
      'Avoidance',
      'Change in appetite',
      'Concentration/forgetfulness',
      'Crying spells',
      'Decrease need for sleep',
      'Decreased libido',
      'Depressed mood',
      'Excessive energy',
      'Excessive guilt',
      'Excessive worry',
      'Fatigue',
      'Impulsivity',
      'Increase risky behavior',
      'Increased irritability',
      'Increased libido',
      'Loss of interest',
      'Racing thoughts',
      'Sleep pattern disturbance',
      'Suspiciousness',
      'Unable to enjoy activities',
      'Other (Please write in)',
    ]),
    dependentFields: {
      triggerValue: 'Other (Please write in)',
      id: 'treatment_checklist_free_text',
      type: 'textInput',
    },
  },
  {
    id: 'major_symptoms',
    type: 'textInput',
    label: 'majorSymptoms',
  },
];

export const stepTwoFormStructure = [
  {
    type: 'sectionTitle',
    label: 'suicideRiskAssessmentSectionTitle',
  },
  {
    id: 'life_negative_thoughts',
    type: 'radio',
    options: binaryResponses,
    label: 'hadLifeNegativeThoughts',
  },
  {
    type: 'sectionSubTitle',
    label: 'suicideThoughtsTextSpacer',
  },
  {
    id: 'suicidal_thoughts',
    type: 'radio',
    options: binaryResponses,
    label: 'hasLifeNegativeThoughts',
  },
  {
    id: 'life_negative_thoughts_frequency',
    type: 'textInput',
    label: 'lifeNegativeThoughtsFrequency',
  },
  {
    id: 'last_time_life_negative_thoughts',
    type: 'textInput',
    label: 'lastTimeLifeNegativeThoughts',
  },
  {
    id: 'life_negative_thoughts_trigger_event',
    type: 'radio',
    options: binaryResponses,
    label: 'lifeNegativeThoughtsTriggerEvent',
  },
  {
    id: 'life_negative_thoughts_intensity_scale',
    type: 'textInput',
    label: 'lifeNegativeThoughtsIntensityScale',
  },
  {
    id: 'life_negative_thoughts_improvement_factors',
    type: 'textInput',
    label: 'lifeNegativeThoughtsImprovementFactors',
  },
  {
    id: 'envisaged_suicide_method',
    type: 'radio',
    options: binaryResponses,
    label: 'envisagedSuicideMethod',
  },
  {
    id: 'suicide_method_availability',
    type: 'radio',
    options: binaryResponses,
    label: 'suicideMethodAvailability',
  },
  {
    id: 'suicide_planned_time',
    type: 'radio',
    options: binaryResponses,
    label: 'suicidePlannedTime',
  },
  {
    id: 'suicide_abandon_possibility',
    type: 'radio',
    options: binaryResponses,
    label: 'suicideAbandonPossibility',
  },
  {
    id: 'feeling_hopeless',
    type: 'radio',
    options: binaryResponses,
    label: 'feelingHopeless',
  },
  {
    id: 'previous_self_harming_attempts',
    type: 'radio',
    options: binaryResponses,
    label: 'previousSelfHarmingAttempts',
  },
  {
    id: 'guns_access',
    type: 'radio',
    options: binaryResponses,
    label: 'gunsAccess',
    dependentFields: {
      triggerValue: 'yes',
      id: 'guns_access_free_text',
      type: 'textInput',
      label: 'gunsAccessDependentFields',
    },
  },
];

export const stepThreeFormStructure = [
  // // PAGE 3
  {
    type: 'sectionTitle',
    label: 'pastMedicalHistorySectionTitle',
  },
  { id: 'allergies', type: 'textInput', label: 'allergies' },
  { id: 'current_weight', type: 'textInput', label: 'currentWeight' },
  { id: 'height', type: 'textInput', label: 'height' },
  {
    id: 'prescribed_medications',
    type: 'textarea',
    label: 'prescribedMedications',
  },
  {
    id: 'taken_medications_supplements',
    type: 'textInput',
    label: 'takenMedicationsSupplements',
  },
  {
    id: 'current_medical_problems',
    type: 'textInput',
    label: 'currentMedicalProblems',
  },
  {
    id: 'past_medical_problems',
    type: 'textInput',
    label: 'pastMedicalProblems',
  },
  {
    id: 'past_ekg',
    type: 'radio',
    options: binaryResponses,
    label: 'pastEKG',
    dependentFields: {
      triggerValue: 'yes',
      formElements: [
        {
          id: 'past_ekg_time',
          type: 'textInput',
          triggerValue: 'yes',
          label: 'pastEKGTime',
        },
        {
          id: 'past_ekg_result',
          type: 'radio',
          triggerValue: 'yes',
          label: 'pastEKGResult',
          options: mapOptionsValues(['Normal', 'Abnormal', 'Unknown']),
        },
      ],
    },
  },

  { type: 'sectionTitle', label: 'womenOnlySectionTitle' },
  {
    id: 'last_menstrual_period',
    // TODO: add date field support
    type: 'textInput',
    label: 'lastMenstrualPeriod',
  },
  {
    id: 'pregnancy_status',
    type: 'radio',
    options: binaryResponses,
    label: 'pregnancyStatus',
  },
  {
    id: 'pregnancy_plans',
    type: 'radio',
    options: binaryResponses,
    label: 'pregnancyPlans',
  },
  {
    id: 'birth_control_method',
    type: 'textInput',
    label: 'birthControlMethod',
  },
  {
    id: 'number_of_pregnancies',
    type: 'textInput',
    label: 'numberOfPregnancies',
  },
  { id: 'live_births', type: 'textInput', label: 'liveBirths' },
];

export const stepFourFormStructure = [
  // // PAGE 4
  { type: 'sectionTitle', label: 'familySectionTitle' },
  {
    id: 'physical_health_concerns',
    type: 'radio',
    options: binaryResponses,
    label: 'physicalHealthConcerns',
  },
  {
    id: 'physical_exam_date_and_place',
    type: 'textInput',
    label: 'physicalExamDateAndPlace',
  },
  {
    type: 'sectionSubTitle',
    label: 'familyAilmentsTextDelimiter',
  },
  {
    id: 'thyroid_disease',
    type: 'radio',
    label: 'thyroidDisease',
    options: mapOptionsValues(['You ', 'Your Family']),
    dependentFields: {
      label: 'familyMemberWithAffectionsDependentFields',
      triggerValue: 'Your Family',
      type: 'textInput',
      id: 'family_member_with_thyroid_disease',
    },
  },
  {
    id: 'anemia',
    type: 'radio',
    label: 'anemia',
    options: mapOptionsValues(['You ', 'Your Family']),
    dependentFields: {
      label: 'familyMemberWithAffectionsDependentFields',
      triggerValue: 'Your Family',
      type: 'textInput',
      id: 'family_member_with_anemia',
    },
  },
  {
    id: 'liver_disease',
    type: 'radio',
    label: 'liverDisease',
    options: mapOptionsValues(['You ', 'Your Family']),
    dependentFields: {
      label: 'familyMemberWithAffectionsDependentFields',
      triggerValue: 'Your Family',
      type: 'textInput',
      id: 'family_member_with_liver_disease',
    },
  },
  {
    id: 'chronic_fatigue',
    type: 'radio',
    label: 'chronicFatigue',
    options: mapOptionsValues(['You ', 'Your Family']),
    dependentFields: {
      label: 'familyMemberWithAffectionsDependentFields',
      triggerValue: 'Your Family',
      type: 'textInput',
      id: 'family_member_with_chronic_fatigue',
    },
  },
  {
    id: 'kidney_disease',
    type: 'radio',
    label: 'kidneyDisease',
    options: mapOptionsValues(['You ', 'Your Family']),
    dependentFields: {
      label: 'familyMemberWithAffectionsDependentFields',
      triggerValue: 'Your Family',
      type: 'textInput',
      id: 'family_member_with_kidney_disease',
    },
  },
  {
    id: 'diabetes',
    type: 'radio',
    label: 'diabetes',
    options: mapOptionsValues(['You ', 'Your Family']),
    dependentFields: {
      label: 'familyMemberWithAffectionsDependentFields',
      triggerValue: 'Your Family',
      type: 'textInput',
      id: 'family_member_with_diabetes',
    },
  },
  {
    id: 'asthma_respiratory_problems',
    type: 'radio',
    label: 'asthmaRespiratoryProblems',
    options: mapOptionsValues(['You ', 'Your Family']),
    dependentFields: {
      label: 'familyMemberWithAffectionsDependentFields',
      triggerValue: 'Your Family',
      type: 'textInput',
      id: 'family_member_asthma_respiratory_problems',
    },
  },
  {
    id: 'stomach_or_intestinal_problems',
    type: 'radio',
    label: 'stomachOrIntestinalProblems',
    options: mapOptionsValues(['You ', 'Your Family']),
    dependentFields: {
      label: 'familyMemberWithAffectionsDependentFields',
      triggerValue: 'Your Family',
      type: 'textInput',
      id: 'family_member_stomach_or_intestinal_problems',
    },
  },
  {
    id: 'cancer',
    label: 'cancer',
    type: 'radio',
    options: mapOptionsValues(['You ', 'Your Family']),
    dependentFields: {
      label: 'familyMemberWithAffectionsDependentFields',
      triggerValue: 'Your Family',
      type: 'textInput',
      id: 'family_member_cancer',
    },
  },
  {
    id: 'fibromyalgia',
    type: 'radio',
    label: 'fibromyalgia',
    options: mapOptionsValues(['You ', 'Your Family']),
    dependentFields: {
      label: 'familyMemberWithAffectionsDependentFields',
      triggerValue: 'Your Family',
      type: 'textInput',
      id: 'family_member_fibromyalgia',
    },
  },
  {
    id: 'heart_disease',
    type: 'radio',
    label: 'heartDisease',
    options: mapOptionsValues(['You ', 'Your Family']),
    dependentFields: {
      label: 'familyMemberWithAffectionsDependentFields',
      triggerValue: 'Your Family',
      type: 'textInput',
      id: 'family_member_heart_disease',
    },
  },
  {
    id: 'epilepsy_or_seizures',
    type: 'radio',
    label: 'epilepsyOrSeizures',
    options: mapOptionsValues(['You ', 'Your Family']),
    dependentFields: {
      label: 'familyMemberWithAffectionsDependentFields',
      triggerValue: 'Your Family',
      type: 'textInput',
      id: 'family_member_epilepsy_or_seizures',
    },
  },
  {
    id: 'chronic_pain',
    type: 'radio',
    label: 'chronicPain',
    options: mapOptionsValues(['You ', 'Your Family']),
    dependentFields: {
      label: 'familyMemberWithAffectionsDependentFields',
      triggerValue: 'Your Family',
      type: 'textInput',
      id: 'family_member_chronic_pain',
    },
  },
  {
    id: 'high_cholesterol',
    type: 'radio',
    label: 'highCholesterol',
    options: mapOptionsValues(['You ', 'Your Family']),
    dependentFields: {
      label: 'familyMemberWithAffectionsDependentFields',
      triggerValue: 'Your Family',
      type: 'textInput',
      id: 'family_member_high_cholesterol',
    },
  },
  {
    id: 'high_blood_pressure',
    type: 'radio',
    label: 'highBloodPressure',
    options: mapOptionsValues(['You ', 'Your Family']),
    dependentFields: {
      label: 'familyMemberWithAffectionsDependentFields',
      triggerValue: 'Your Family',
      type: 'textInput',
      id: 'family_member_high_blood_pressure',
    },
  },
  {
    id: 'head_trauma',
    type: 'radio',
    label: 'headTrauma',
    options: mapOptionsValues(['You ', 'Your Family']),
    dependentFields: {
      label: 'familyMemberWithAffectionsDependentFields',
      triggerValue: 'Your Family',
      type: 'textInput',
      id: 'family_member_head_trauma',
    },
  },
  {
    id: 'liver_problems',
    type: 'radio',
    label: 'liverProblems',
    options: mapOptionsValues(['You ', 'Your Family']),
    dependentFields: {
      label: 'familyMemberWithAffectionsDependentFields',
      triggerValue: 'Your Family',
      type: 'textInput',
      id: 'family_member_liver_problems',
    },
  },
  {
    id: 'additional_medical_history',
    type: 'radio',
    options: binaryResponses,
    label: 'additionalMedicalHistory',
    dependentFields: {
      type: 'textInput',
      label: 'additionalMedicalHistoryDependentFields',
      triggerValue: 'yes',
      id: 'additional_medical_history_free_text',
    },
  },
  {
    id: 'mother_pregnancy_complications',
    type: 'radio',
    options: binaryResponses,
    label: 'motherPregnancyComplications',
  },
];

export const stepFiveFormStructure = [
  {
    type: 'sectionTitle',
    label: 'pastPsychiatricHistoryTitle',
  },
  {
    id: 'outpatient_treatment',
    type: 'radio',
    options: binaryResponses,
    label: 'outpatientTreatment',
    dependentFields: {
      type: 'textarea',
      triggerValue: 'yes',
      label: 'outpatientTreatmentDependentFields',
      id: 'outpatient_treatment_free_text',
    },
  },
  {
    id: 'psychiatric_hospitalization',
    type: 'radio',
    options: binaryResponses,
    label: 'psychiatricHospitalization',
    dependentFields: {
      type: 'textarea',
      triggerValue: 'yes',
      label: 'psychiatricHospitalizationDependentFields',
      id: 'psychiatric_hospitalization_free_text',
    },
  },
  {
    type: 'sectionTitle',
    label: 'pastPsychiatricHistorySectionTitle',
  },
  {
    type: 'sectionSubTitle',
    label: 'pastPsychiatricHistorySectionSubTitle',
  },
  {
    type: 'sectionTextDelimiter',
    label: 'antiDepressantsTextDelimiter',
    inlineStyle: {
      paddingBottom: 0,
    },
  },
  {
    type: 'delimiter',
  },
  {
    id: 'anafranil',
    type: 'checkbox',
    options: mapOptionsValues(['Anafril']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Anafril',
      id: 'anafranildependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'celexa',
    type: 'checkbox',
    options: mapOptionsValues(['Celexa (citalopram)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Celexa (citalopram)',
      id: 'celexadependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'cymbalta',
    type: 'checkbox',
    options: mapOptionsValues(['Cymbalta (duloxetine)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Cymbalta (duloxetine)',
      id: 'cymbaltadependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'effexor',
    type: 'checkbox',
    options: mapOptionsValues(['Effexor (venlafaxine)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Effexor (venlafaxine)',
      id: 'effexordependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'elavil',
    type: 'checkbox',
    options: mapOptionsValues(['Elavil (amitriptyline)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Elavil (amitriptyline)',
      id: 'elavildependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'lexapro',
    type: 'checkbox',
    options: mapOptionsValues(['Lexapro (escitalopram)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Lexapro (escitalopram)',
      id: 'lexaprodependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'luvox_paxil',
    type: 'checkbox',
    options: mapOptionsValues(['Luvox (fluvoxamine) Paxil (paroxetine)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Luvox (fluvoxamine) Paxil (paroxetine)',
      id: 'luvox_paxildependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'pamelor',
    type: 'checkbox',
    options: mapOptionsValues(['Pamelor (nortriptyline)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Pamelor (nortriptyline)',
      id: 'pamelordependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'prozac',
    type: 'checkbox',
    options: mapOptionsValues(['Prozac (fluoxetine)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Prozac (fluoxetine)',
      id: 'prozacdependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'remeron',
    type: 'checkbox',
    options: mapOptionsValues(['Remeron (mirtazapine)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Remeron (mirtazapine)',
      id: 'remerondependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'serzone',
    type: 'checkbox',
    options: mapOptionsValues(['Serzone (nefazodone)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Serzone (nefazodone)',
      id: 'serzonedependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'tofranil',
    type: 'checkbox',
    options: mapOptionsValues(['Tofranil (imipramine)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Tofranil (imipramine)',
      id: 'tofranildependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'wellbutrin',
    type: 'checkbox',
    options: mapOptionsValues(['Wellbutrin (bupropion)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Wellbutrin (bupropion)',
      id: 'wellbutrindependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },

  {
    type: 'sectionTextDelimiter',
    label: 'antipsychoticsTextDelimiter',
    inlineStyle: {
      marginTop: '10px',
      paddingBottom: 0,
    },
  },
  { type: 'delimiter' },
  {
    id: 'abilify',
    type: 'checkbox',
    options: mapOptionsValues(['Abilify (aripiprazole)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Abilify (aripiprazole)',
      id: 'abilifydependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'clozaril',
    type: 'checkbox',
    options: mapOptionsValues(['Clozaril (clozapine)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Clozaril (clozapine)',
      id: 'clozarildependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'depakote',
    type: 'checkbox',
    options: mapOptionsValues(['Depakote (valproate)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Depakote (valproate)',
      id: 'depakotedependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'geodon',
    type: 'checkbox',
    options: mapOptionsValues(['Geodon (ziprasidone)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Geodon (ziprasidone)',
      id: 'geodondependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'Haldol',
    type: 'checkbox',
    options: mapOptionsValues(['Haldol (haloperidol)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Haldol (haloperidol)',
      id: 'HaldoldependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'lamictal',
    type: 'checkbox',
    options: mapOptionsValues(['Lamictal (lamotrigine)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Lamictal (lamotrigine)',
      id: 'lamictaldependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'lithium',
    type: 'checkbox',
    options: mapOptionsValues(['Lithium']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Lithium',
      id: 'lithiumdependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'prolixin',
    type: 'checkbox',
    options: mapOptionsValues(['Prolixin (fluphenazine)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Prolixin (fluphenazine)',
      id: 'prolixindependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'risperdal',
    type: 'checkbox',
    options: mapOptionsValues(['Risperdal (risperidone)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Risperdal (risperidone)',
      id: 'risperdaldependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'tegretol',
    type: 'checkbox',
    options: mapOptionsValues(['Tegretol (carbamazepine)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Tegretol (carbamazepine)',
      id: 'tegretoldependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'topamax',
    type: 'checkbox',
    options: mapOptionsValues(['Topamax (topiramate)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Topamax (topiramate)',
      id: 'topamaxdependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'zyprexa',
    type: 'checkbox',
    options: mapOptionsValues(['Zyprexa (olanzepine)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Zyprexa (olanzepine)',
      id: 'zyprexadependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    type: 'sectionTextDelimiter',
    label: 'sedativesHyptnoticsTextDelimiter',
    inlineStyle: {
      marginTop: '10px',
      paddingBottom: 0,
    },
  },
  {
    type: 'delimiter',
  },
  {
    id: 'ambien',
    type: 'checkbox',
    options: mapOptionsValues(['Ambien (zolpidem)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Ambien (zolpidem)',
      id: 'ambiendependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'desyrel',
    type: 'checkbox',
    options: mapOptionsValues(['Desyrel (trazodone)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Desyrel (trazodone)',
      id: 'desyreldependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'sonata',
    type: 'checkbox',
    options: mapOptionsValues(['Sonata (zaleplon)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Sonata (zaleplon)',
      id: 'sonatadependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'restoril',
    type: 'checkbox',
    options: mapOptionsValues(['Restoril (temazepam)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Restoril (temazepam)',
      id: 'restorildependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'rozerem',
    type: 'checkbox',
    options: mapOptionsValues(['Rozerem (ramelteon)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Rozerem (ramelteon)',
      id: 'rozeremdependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    type: 'sectionTextDelimiter',
    label: 'ADHDMedicationsTextDelimiter',
    inlineStyle: {
      marginTop: '10px',
      paddingBottom: 0,
    },
  },
  {
    type: 'delimiter',
  },
  {
    id: 'adderall',
    type: 'checkbox',
    options: mapOptionsValues(['Adderall (amphetamine)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Adderall (amphetamine)',
      id: 'adderalldependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'concerta',
    type: 'checkbox',
    options: mapOptionsValues(['Concerta (methylphenidate)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Concerta (methylphenidate)',
      id: 'concertadependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'ritalin',
    type: 'checkbox',
    options: mapOptionsValues(['Ritalin (methylphenidate)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Ritalin (methylphenidate)',
      id: 'ritalindependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'strattera',
    type: 'checkbox',
    options: mapOptionsValues(['Strattera (atomoxetine)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Strattera (atomoxetine)',
      id: 'stratteradependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    type: 'sectionTextDelimiter',
    label: 'antianxietyMedicationsTextDelimiter',
    inlineStyle: {
      marginTop: '10px',
      paddingBottom: 0,
    },
  },
  {
    type: 'delimiter',
  },
  {
    id: 'ativan',
    type: 'checkbox',
    options: mapOptionsValues(['Ativan (lorazepam)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Ativan (lorazepam)',
      id: 'ativandependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'buspar',
    type: 'checkbox',
    options: mapOptionsValues(['Buspar (buspirone)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Buspar (buspirone)',
      id: 'buspardependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'klonopin',
    type: 'checkbox',
    options: mapOptionsValues(['Klonopin (clonazepam)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Klonopin (clonazepam)',
      id: 'klonopindependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'tranxene',
    type: 'checkbox',
    options: mapOptionsValues(['Tranxene (clorazepate)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Tranxene (clorazepate)',
      id: 'tranxenedependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'valium',
    type: 'checkbox',
    options: mapOptionsValues(['Valium (diazepam)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Valium (diazepam)',
      id: 'valiumdependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'xanax',
    type: 'checkbox',
    options: mapOptionsValues(['Xanax (alprazolam)']),
    inlineStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dependentFields: {
      inlineStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '40px',
      },
      triggerValue: 'Xanax (alprazolam)',
      id: 'xanaxdependentFields',
      type: 'textarea',
      label: 'antiDepressantsdependentFields',
    },
  },
  {
    id: 'other_psychiatric_medications',
    type: 'textarea',
    label: 'otherPsychiatricMedications',
  },
];
export const stepSixFormStructure = [
  {
    type: 'sectionTitle',
    label: 'exerciseLevelSectionTitle',
  },
  {
    id: 'regularly_exercise',
    type: 'radio',
    options: binaryResponses,
    label: 'regularlyExercise',
  },
  {
    id: 'exercise_days_per_week',
    type: 'textInput',
    label: 'exerciseDaysPerWeek',
  },
  {
    id: 'exercise_time_per_day',
    type: 'textInput',
    label: 'exerciseTimePerDay',
  },
  {
    id: 'exercise_type',
    type: 'textInput',
    label: 'exerciseType',
  },
  {
    type: 'sectionTitle',
    label: 'familyPsychiatricHistorySectionTitle',
  },
  {
    type: 'sectionSubTitle',
    label: 'familyPsychiatricHistorySubSectionTitle',
  },
  {
    id: 'alcohol_abuse',
    type: 'radio',
    options: binaryResponses,
    label: 'alcoholAbuse',
    dependentFields: {
      triggerValue: 'yes',
      id: 'alcohol_abuse_free_text',
      type: 'textInput',
      label: 'familyMemberSubstanceAbusedependentFields',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'anger',
    type: 'radio',
    options: binaryResponses,
    label: 'anger',
    dependentFields: {
      triggerValue: 'yes',
      id: 'anger_free_text',
      type: 'textInput',
      label: 'familyMemberSubstanceAbusedependentFields',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'anxiety',
    type: 'radio',
    options: binaryResponses,
    label: 'anxiety',
    dependentFields: {
      triggerValue: 'yes',
      id: 'anxiety_free_text',
      type: 'textInput',
      label: 'familyMemberSubstanceAbusedependentFields',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'bipolar_disorder',
    type: 'radio',
    options: binaryResponses,
    label: 'bipolarDisorder',
    dependentFields: {
      triggerValue: 'yes',
      id: 'bipolar_disorder_free_text',
      type: 'textInput',
      label: 'familyMemberSubstanceAbusedependentFields',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'depression',
    type: 'radio',
    options: binaryResponses,
    label: 'depression',
    dependentFields: {
      triggerValue: 'yes',
      id: 'depression_free_text',
      type: 'textInput',
      label: 'familyMemberSubstanceAbusedependentFields',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'other_substance_abuse',
    type: 'radio',
    options: binaryResponses,
    label: 'otherSubstanceAbuse',
    dependentFields: {
      triggerValue: 'yes',
      id: 'other_substance_abuse_free_text',
      type: 'textInput',
      label: 'familyMemberSubstanceAbusedependentFields',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'post_traumatic_stress',
    type: 'radio',
    options: binaryResponses,
    label: 'postTraumaticStress',
    dependentFields: {
      triggerValue: 'yes',
      id: 'post_traumatic_stress_free_text',
      type: 'textInput',
      label: 'familyMemberSubstanceAbusedependentFields',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'schizophrenia',
    type: 'radio',
    options: binaryResponses,
    label: 'schizophrenia',
    dependentFields: {
      triggerValue: 'yes',
      id: 'schizophrenia_free_text',
      type: 'textInput',
      label: 'familyMemberSubstanceAbusedependentFields',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'suicide',
    type: 'radio',
    options: binaryResponses,
    label: 'suicide',
    dependentFields: {
      triggerValue: 'yes',
      id: 'suicide_free_text',
      type: 'textInput',
      label: 'familyMemberSubstanceAbusedependentFields',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'violence',
    type: 'radio',
    options: binaryResponses,
    label: 'violence',
    dependentFields: {
      triggerValue: 'yes',
      id: 'violence_free_text',
      type: 'textInput',
      label: 'familyMemberSubstanceAbusedependentFields',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'family_member_psychiatric_medication',
    type: 'radio',
    options: binaryResponses,
    label: 'familyMemberPsychiatricMedication',
    dependentFields: {
      triggerValue: 'yes',
      id: 'family_member_psychiatric_medication_free_text',
      type: 'textInput',
      label: 'familyMemberPsychiatricMedicationdependentFields',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    type: 'sectionTitle',
    label: 'substanceUseSectionTitle',
  },
  {
    id: 'alcohol_drug_abuse_treated',
    type: 'radio',
    options: binaryResponses,
    label: 'alcoholDrugAbuseTreated',
    dependentFields: {
      triggerValue: 'yes',
      formElements: [
        {
          id: 'alcohol_drug_abuse_free_text',
          type: 'textInput',
          label: 'alcoholDrugAbusedependentFields',
          inlineStyle: {
            paddingTop: 0,
          },
        },
        {
          id: 'alcohol_drug_abuse_treated_free_text',
          type: 'textInput',
          label: 'alcoholDrugAbuseTreateddependentFields',
          inlineStyle: {
            paddingTop: 0,
          },
        },
      ],
    },
  },
  {
    id: 'alcohol_days_per_week',
    type: 'textInput',
    label: 'alcoholDaysPerWeek',
  },
  {
    id: 'min_drinks_in_a_day',
    type: 'textInput',
    label: 'minDrinksInADay',
  },
  {
    id: 'max_drinks_in_a_day',
    type: 'textInput',
    label: 'maxDrinksInADay',
  },
  {
    id: 'max_drinks_in_three_months',
    type: 'textInput',
    label: 'maxDrinksInThreeMonths',
  },
  {
    id: 'substance_cut_down_plan',
    type: 'radio',
    options: binaryResponses,
    label: 'substanceCutDownPlan',
  },
  {
    id: 'annoyed_by_people_criticism',
    type: 'radio',
    options: binaryResponses,
    label: 'annoyedByPeopleCriticism',
  },
  {
    id: 'drinking_remorse',
    type: 'radio',
    options: binaryResponses,
    label: 'drinkingRemorse',
  },
  {
    id: 'drinking_as_hangover_cure',
    type: 'radio',
    options: binaryResponses,
    label: 'drinkingAsHangoverCure',
  },
  {
    id: 'acknowledge_drinking_problem',
    type: 'radio',
    options: binaryResponses,
    label: 'acknowledgeDrinkingProblem',
  },
  {
    id: 'street_drugs_past_three_months',
    type: 'radio',
    options: binaryResponses,
    label: 'streetDrugsPastThreeMonths',
    dependentFields: {
      type: 'textInput',
      label: 'streetDrugsPastThreeMonthsdependentFields',
      id: 'street_drugs_past_three_months_free_text',
      triggerValue: 'yes',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'prescribed_medication_abuse',
    type: 'radio',
    options: binaryResponses,
    label: 'prescribedMedicationAbuse',
    dependentFields: {
      type: 'textInput',
      label: 'prescribedMedicationAbusedependentFields',
      id: 'prescribed_medication_abuse_free_text',
      triggerValue: 'yes',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  { type: 'sectionTitle', label: 'substancesTriedSectionTitle' },
  {
    id: 'tried_alcohol',
    type: 'radio',
    options: binaryResponses,
    label: 'triedAlcohol',
    dependentFields: {
      type: 'textInput',
      label: 'substancesTrieddependentFields',
      id: 'tried_alcohol_free_text',
      triggerValue: 'yes',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'tried_cocaine',
    type: 'radio',
    options: binaryResponses,
    label: 'triedCocaine',
    dependentFields: {
      type: 'textInput',
      label: 'substancesTrieddependentFields',
      id: 'tried_cocaine_free_text',
      triggerValue: 'yes',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'tried_ecstasy',
    type: 'radio',
    options: binaryResponses,
    label: 'triedEcstasy',
    dependentFields: {
      type: 'textInput',
      label: 'substancesTrieddependentFields',
      id: 'tried_ecstasy_free_text',
      triggerValue: 'yes',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'tried_heroin',
    type: 'radio',
    options: binaryResponses,
    label: 'triedHeroin',
    dependentFields: {
      type: 'textInput',
      label: 'substancesTrieddependentFields',
      id: 'tried_heroin_free_text',
      triggerValue: 'yes',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'tried_lsd_hallucinogens',
    type: 'radio',
    options: binaryResponses,
    label: 'triedLSDHallucinogens',
    dependentFields: {
      type: 'textInput',
      label: 'substancesTrieddependentFields',
      id: 'tried_lsd_hallucinogens_free_text',
      triggerValue: 'yes',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'tried_marijuana',
    type: 'radio',
    options: binaryResponses,
    label: 'triedMarijuana',
    dependentFields: {
      type: 'textInput',
      label: 'substancesTrieddependentFields',
      id: 'tried_marijuana_free_text',
      triggerValue: 'yes',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'tried_methadone',
    type: 'radio',
    options: binaryResponses,
    label: 'triedMethadone',
    dependentFields: {
      type: 'textInput',
      label: 'substancesTrieddependentFields',
      id: 'tried_methadone_free_text',
      triggerValue: 'yes',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'tried_methamphetamine',
    type: 'radio',
    options: binaryResponses,
    label: 'triedMethamphetamine',
    dependentFields: {
      type: 'textInput',
      label: 'substancesTrieddependentFields',
      id: 'tried_methamphetamine_free_text',
      triggerValue: 'yes',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'tried_painKillers',
    type: 'radio',
    options: binaryResponses,
    label: 'triedPainKillers',
    dependentFields: {
      type: 'textInput',
      label: 'substancesTrieddependentFields',
      id: 'tried_pain_killers_free_text',
      triggerValue: 'yes',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'tried_stimulants',
    type: 'radio',
    options: binaryResponses,
    label: 'triedStimulants',
    dependentFields: {
      type: 'textInput',
      label: 'substancesTrieddependentFields',
      id: 'tried_stimulants_free_text',
      triggerValue: 'yes',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'tried_tranquilizer_sleeping_pills',
    type: 'radio',
    options: binaryResponses,
    label: 'triedTranquilizerSleepingPills',
    dependentFields: {
      type: 'textInput',
      label: 'substancesTrieddependentFields',
      id: 'tried_tranquilizer_sleeping_pills_free_text',
      triggerValue: 'yes',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'other_substances_tried',
    type: 'textarea',
    label: 'otherSubstancesTried',
  },
  {
    type: 'sectionSubTitle',
    label: 'caffeinatedBeveragesPerDayTitle',
  },
  {
    id: 'coffee_per_day',
    label: 'coffeePerDay',
    type: 'textInput',
  },
  {
    id: 'sodas_per_day',
    label: 'sodasPerDay',
    type: 'textInput',
  },
  {
    id: 'tea_per_day',
    label: 'teaPerDay',
    type: 'textInput',
  },
  {
    label: 'tobaccoHistorySectionTitle',
    type: 'sectionTitle',
  },
  {
    id: 'smoked_cigarettes',
    label: 'smokedCigarettes',
    type: 'radio',
    options: binaryResponses,
    dependentFields: {
      triggerValue: 'yes',
      formElements: [
        {
          id: 'currently_smoking',
          label: 'currentlySmoking',
          type: 'radio',
          options: binaryResponses,
        },
        {
          id: 'years_of_smoking_and_daily_packs',
          label: 'yearsOfSmokingAndDailyPacks',
          type: 'textarea',
        },
      ],
    },
  },
  {
    id: 'smoked_before',
    label: 'smokedBefore',
    type: 'radio',
    options: binaryResponses,
    dependentFields: {
      id: 'smoked_before_free_text',
      triggerValue: 'yes',
      type: 'textInput',
      label: 'yearsOfSmokingAndQuittingReason',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'pipe_use',
    label: 'pipeUse',
    type: 'radio',
    options: binaryResponses,
    dependentFields: {
      id: 'pipe_use_free_text',
      triggerValue: 'yes',
      type: 'textInput',
      label: 'pipeUsedependentFields',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'ever_used_pipe',
    label: 'everUsedPipe',
    type: 'radio',
    options: binaryResponses,
    dependentFields: {
      id: 'ever_used_pipe_free_text',
      triggerValue: 'yes',
      type: 'textarea',
      label: 'everUsedPipedependentFields',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    id: 'years_of_smoking',
    label: 'yearsOfSmoking',
    type: 'textInput',
  },
];
export const stepSevenFormStructure = [
  {
    type: 'sectionTitle',
    label: 'familyBackgroundSectionTitle',
  },
  {
    id: 'adopted',
    type: 'radio',
    options: binaryResponses,
    label: 'adopted',
  },
  {
    id: 'grow_up_place',
    type: 'textInput',
    label: 'growUpPlace',
  },
  {
    id: 'siblings_info',
    type: 'textarea',
    label: 'siblingsInfo',
  },
  {
    id: 'father_occupation',
    type: 'textInput',
    label: 'fatherOccupation',
  },
  {
    id: 'mother_occupation',
    type: 'textInput',
    label: 'motherOccupation',
  },
  {
    id: 'divorced_parents',
    type: 'radio',
    options: binaryResponses,
    label: 'divorcedParents',
    dependentFields: {
      triggerValue: 'yes',
      formElements: [
        {
          id: 'divorced_parents_at_age',
          type: 'textInput',
          label: 'divorcedParentsAtAge',
        },
        {
          id: 'person_living_with_on_parents_divorce',
          type: 'textInput',
          label: 'personLivingWithOnParentsDivorce',
        },
      ],
    },
  },
  {
    id: 'father_relationship',
    type: 'textInput',
    label: 'fatherRelationship',
  },
  {
    id: 'mother_relationship',
    type: 'textInput',
    label: 'motherRelationship',
  },
  {
    id: 'left_home_at_age',
    type: 'textInput',
    label: 'leftHomeAtAge',
  },
  {
    id: 'immediate_family_deaths',
    type: 'radio',
    options: binaryResponses,
    label: 'immediateFamilyDeaths',
    dependentFields: {
      triggerValue: 'yes',
      id: 'immediate_family_deaths_info',
      type: 'textInput',
      label: 'immediateFamilyDeathsInfo',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    type: 'sectionTitle',
    label: 'traumaHistorySectionTitle',
  },
  {
    id: 'abuse_history',
    type: 'radio',
    options: binaryResponses,
    label: 'abuseHistory',
    dependentFields: {
      triggerValue: 'yes',
      id: 'abuse_history_info',
      type: 'textInput',
      label: 'abuseHistoryInfo',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
];
export const stepEightFormStructure = [
  {
    type: 'sectionTitle',
    label: 'educationalHistorySectionTitle',
  },
  {
    id: 'highest_grade_completed',
    type: 'textInput',
    label: 'highestGradeCompleted',
  },
  {
    id: 'highest_grade_completed_location',
    type: 'textInput',
    label: 'highestGradeCompletedLocation',
  },
  {
    id: 'has_attended_college',
    type: 'radio',
    options: binaryResponses,
    label: 'hasAttendedCollege',
    dependentFields: {
      id: 'attended_college_info',
      type: 'textarea',
      triggerValue: 'yes',
      label: 'attendedCollegeInfo',
    },
  },
  {
    id: 'highest_education_attained',
    type: 'textInput',
    label: 'highestEducationAttained',
  },
  {
    type: 'sectionTitle',
    label: 'occupationalHistoryTitle',
  },
  {
    id: 'current_occupation',
    type: 'checkbox',
    options: mapOptionsValues([
      'Working',
      'Student',
      'Unemployed',
      'Disabled',
      'Retired',
    ]),
    label: 'currentOccupation',
  },
  {
    id: 'present_position_length',
    type: 'textInput',
    label: 'presentPositionLength',
  },
  {
    id: 'occupation',
    type: 'textInput',
    label: 'occupation',
  },
  {
    id: 'working_location',
    type: 'textInput',
    label: 'workingLocation',
  },
  {
    id: 'military_service',
    type: 'radio',
    options: binaryResponses,
    label: 'militaryService',
    dependentFields: {
      triggerValue: 'yes',

      id: 'military_service_info',
      type: 'textInput',
      label: 'militaryServiceInfo',
    },
  },
  {
    id: 'honorable_discharge',
    type: 'radio',
    options: mapOptionsValues(['Yes', 'No', 'Other type discharge']),
    label: 'honorableDischarge',
    dependentFields: {
      triggerValue: 'Other type discharge',
      id: 'honorable_discharge_info',
      type: 'textInput',
      label: 'honorableDischargeInfo',
    },
  },
  {
    type: 'sectionTitle',
    label: 'relationshipHistorySectionTitle',
  },
  {
    id: 'relationship_status',
    type: 'radio',
    options: mapOptionsValues([
      'Married',
      'Partnered',
      'Divorced',
      'Single',
      'Widowed',
    ]),
    label: 'relationshipStatus',
  },
  {
    id: 'relationship_status_duration',
    type: 'textInput',
    label: 'relationshipStatusDuration',
  },
  {
    id: 'currently_in_relationship',
    type: 'radio',
    options: binaryResponses,
    label: 'currentlyInRelationship',
    dependentFields: {
      id: 'current_relationship_duration',
      type: 'textInput',
      label: 'currentRelationshipDuration',
      triggerValue: 'yes',
    },
  },
  {
    id: 'sexually_active',
    type: 'radio',
    options: binaryResponses,
    label: 'sexuallyActive',
  },
  {
    id: 'sexual_orientation',
    type: 'radio',
    label: 'sexualOrientation',
    options: mapOptionsValues([
      'Straight/Heterosexual',
      'Lesbian/Gay/Homosexual',
      'Bisexual',
      'Transsexual',
      'Unsure/Questioning',
      'Asexual',
      'Other',
      'Prefer not to answer',
    ]),
  },
  {
    id: 'spouse_occupation',
    type: 'textarea',
    label: 'spouseOccupation',
  },
  {
    id: 'spouse_relationship_description',
    type: 'textarea',
    label: 'spouseRelationShipDescription',
  },
  {
    id: 'previous_marriages',
    type: 'radio',
    options: binaryResponses,
    label: 'previousMarriages',
    dependentFields: {
      triggerValue: 'yes',
      formElements: [
        {
          label: 'previousMarriagesNumber',
          id: 'previous_marriages_number',
          type: 'textInput',
        },
        {
          label: 'previousMarriagesDuration',
          id: 'previous_marriages_duration',
          type: 'textInput',
        },
      ],
    },
  },
  {
    id: 'has_children',
    type: 'radio',
    options: binaryResponses,
    label: 'hasChildren',
    dependentFields: {
      triggerValue: 'yes',
      formElements: [
        {
          label: 'childrenInfo',
          id: 'children_info',
          type: 'textarea',
        },
        {
          label: 'childrenRelationshipDescription',
          id: 'children_relationship_description',
          type: 'textarea',
        },
      ],
    },
  },
  {
    label: 'othersCoHabitants',
    id: 'others_cohabitants',
    type: 'textarea',
  },
];
export const stepNineFormStructure = [
  {
    type: 'sectionTitle',
    label: 'legalHistorySectionTitle',
  },
  {
    type: 'radio',
    label: 'beenArrested',
    id: 'been_arrested',
    options: binaryResponses,
  },
  {
    type: 'radio',
    label: 'pendingLegalProblems',
    id: 'pending_legal_problems',
    options: binaryResponses,
  },
  {
    type: 'sectionTitle',
    label: 'spiritualLifeSectionTitle',
  },
  {
    type: 'radio',
    label: 'belongsToReligionGroup',
    id: 'belongs_to_religion_group',
    options: binaryResponses,
    dependentFields: {
      type: 'textInput',
      label: 'belongsToReligionGroupdependentFields',
      id: 'belongs_to_religion_group_free_text',
      triggerValue: 'yes',
      inlineStyle: {
        paddingTop: 0,
      },
    },
  },
  {
    type: 'radio',
    label: 'religionGroupInvolvementResult',
    id: 'religion_group_involvement_result',
    options: binaryResponses,
  },
  {
    type: 'textarea',
    label: 'otherMentions',
    id: 'other_mentions',
  },
  {
    type: 'textInput',
    label: 'emergencyContact',
    id: 'emergency_contact',
  },
  {
    type: 'textInput',
    label: 'telephoneNumber',
    id: 'telephone_number',
  },
];
