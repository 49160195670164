import { Field } from 'formik';
import React, { useEffect, Fragment } from 'react';
import { Cell } from 'react-foundation';
import 'react-image-crop/dist/ReactCrop.css';

import { deleteClinic } from '../../../../../api/doctor';
import { ACCOUNT_TYPE } from '../../../../../constants';
import useIntl from '../../../../../hooks/useIntl';

import { requiredValidator } from '../../../../../utils/validators';
import FieldInput from '../../../../common/fields/FieldInput/FieldInput';
import RadioButton from '../../../../common/inputs/RadioButton';
import Label from '../../../../common/Label';
import errorStyles from '../../../../common/Label/Label.module.scss';
import Loading from '../../../../common/Loading';

import messages from '../../ClinicianRegistration/messages';
import styles from '../ClinicianRegistration.module.scss';

const ClinicianRegistrationStepOne = ({
  isLoading,
  values,
  handleChange,
  // handleBlur,
  setFieldValue,
  invitedClinic,
  setLoading,
  setShowGroupName,
  clinicOwner,
  showGroupName,
  fetchClinics,
  firstStepValidation,
  setFirstStepValidation,
  touched,
}) => {
  const { formatMessage } = useIntl();

  const fieldValidators = (value, fieldName) => {
    const validationResult = requiredValidator(value, fieldName);

    let result = '';

    if (Object.keys(validationResult).length) {
      result = validationResult[fieldName];
    }

    return { fieldName, result };
  };

  let validationFields = ['first_name', 'last_name', 'group', 'role'];

  useEffect(() => {
    let newFirstStepValidation = {};

    if (!invitedClinic && validationFields.includes('group')) {
      validationFields.splice(validationFields.indexOf('group'), 1);
      validationFields.push('clinic_name');
      const { fieldName: fn, result } = fieldValidators(
        values.clinic_name,
        'clinic_name',
      );
      newFirstStepValidation[fn] = result;
    }
    if (values.group === 'NewClinic') {
      const { fieldName: fn, result } = fieldValidators(
        values.clinic_name,
        'clinic_name',
      );
      newFirstStepValidation[fn] = result;
    }
    if (values.group !== 'NewClinic' && invitedClinic) {
      newFirstStepValidation.clinic_name = '';
    }

    validationFields.forEach((fieldName) => {
      const { fieldName: fn, result } = fieldValidators(
        values[fieldName],
        fieldName,
      );
      newFirstStepValidation[fn] = result;
    });

    setFirstStepValidation(newFirstStepValidation);
  }, [values]);

  return isLoading ? (
    <Loading />
  ) : (
    <Fragment>
      <Cell small={12}>
        <FieldInput
          placeholder="Enter here"
          label={formatMessage(messages.contactDetails.firstNameLabel)}
          errorText={!values.first_name ? firstStepValidation?.first_name : ''}
          name="first_name"
          onChange={handleChange}
          // onBlur={handleBlur}
          value={values.first_name}
          data-testid="clinician first name"
          label-testid="clinician first name label"
          error-testid="clinician first name error"
        />
      </Cell>
      <Cell small={12}>
        <FieldInput
          placeholder="Enter here"
          label={formatMessage(messages.contactDetails.LastNameLabel)}
          errorText={!values.last_name ? firstStepValidation?.last_name : ''}
          name="last_name"
          onChange={handleChange}
          // onBlur={handleBlur}
          value={values.last_name}
          data-testid="clinician last name"
          label-testid="clinician last name label"
          error-testid="clinician last name error"
        />
      </Cell>
      {invitedClinic && (
        <Fragment>
          <Cell small={12} className={styles['radioBtn']}>
            <Label data-testid={`clinician account type`} htmlFor={'group'}>
              {formatMessage(messages.accountType)}
            </Label>
            {!invitedClinic.user.isOwner && (
              <Fragment>
                <Field
                  component={(field) => <RadioButton {...field} name="group" />}
                  value={ACCOUNT_TYPE.MEMBER}
                  htmlFor={ACCOUNT_TYPE.MEMBER}
                  checked={ACCOUNT_TYPE.MEMBER === values.group}
                  name="group"
                  onChange={(value) => {
                    setFieldValue('group', value);
                    setShowGroupName(false);
                  }}
                  // onBlur={handleBlur}
                  tooltipDescription={`${formatMessage(
                    messages.joinClinicTooltip,
                  )} ${invitedClinic?.clinic_name || 'Unnamed'}`}
                  displayTooltip="inline-block"
                  // style={{ width: 'fit-content' }}
                  labelName={`Join ${invitedClinic?.clinic_name || 'Unnamed'}`}
                  data-testid={`clinician account type member`}
                />
                <Field
                  component={(field) => <RadioButton {...field} name="group" />}
                  value={ACCOUNT_TYPE.NEWCLINIC}
                  htmlFor={ACCOUNT_TYPE.NEWCLINIC}
                  checked={ACCOUNT_TYPE.NEWCLINIC === values.group}
                  name="group"
                  onChange={(value) => {
                    setFieldValue('group', value);
                    setShowGroupName(true);
                  }}
                  // onBlur={handleBlur}
                  tooltipDescription={formatMessage(
                    messages.createClinicTooltip,
                  )}
                  displayTooltip="inline-block"
                  labelName={`Create New Clinic or Group`}
                  data-testid={`clinician account type new clinic or group owner`}
                />
              </Fragment>
            )}
            {invitedClinic.user.isOwner && (
              <Field
                component={(field) => <RadioButton {...field} name="group" />}
                value={ACCOUNT_TYPE.OWNER}
                htmlFor={ACCOUNT_TYPE.OWNER}
                checked={ACCOUNT_TYPE.OWNER === values.group}
                name="group"
                onChange={(value) => {
                  setFieldValue('group', value);
                  setShowGroupName(false);
                }}
                // onBlur={handleBlur}
                disabled
                labelName={`Owner of ${
                  invitedClinic && invitedClinic.clinic_name
                }`}
                data-testid={`clinician account type owner of ${
                  invitedClinic && invitedClinic.clinic_name
                }`}
              />
            )}
            {Object.entries(touched).length > 0 && !values.group && (
              <div
                className={errorStyles['error-label']}
                error-testid={`clinician account type error`}
              >
                {!values.group ? firstStepValidation?.group : ''}
              </div>
            )}
          </Cell>
          {clinicOwner &&
            showGroupName &&
            clinicOwner.map((clinic) => {
              return (
                <div className={styles.newClinic} key={clinic.id}>
                  {clinic.clinic_name}
                  <button
                    className={styles.closeBtn}
                    onClick={async () => {
                      setLoading(true);
                      try {
                        await deleteClinic(clinic.id);
                        await fetchClinics();
                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                      }
                    }}
                  ></button>
                </div>
              );
            })}
        </Fragment>
      )}
      {(!invitedClinic || values.group === ACCOUNT_TYPE.NEWCLINIC) && (
        <Cell small={12}>
          <FieldInput
            placeholder="Enter here"
            label={formatMessage(messages.clinicNameLabel)}
            errorText={
              !values.clinic_name ? firstStepValidation?.clinic_name : ''
            }
            name="clinic_name"
            onChange={handleChange}
            // onBlur={handleBlur}
            value={values.clinic_name}
            data-testid="clinician last name"
            label-testid="clinician last name label"
            error-testid="clinician last name error"
          />
        </Cell>
      )}
      <Fragment>
        <Cell small={12} medium={12} className={styles['radioBtn']}>
          <Label>{formatMessage(messages.accountRole)}</Label>
          <Field
            component={(field) => <RadioButton {...field} name="role" />}
            value={`clinician`}
            htmlFor={`clinician`}
            checked={`clinician` === values.role}
            name="role"
            onChange={(value) => {
              setFieldValue('role', value);
            }}
            // onBlur={handleBlur}
            tooltipDescription={formatMessage(messages.clinicianRoleTooltip)}
            displayTooltip="inline-block"
            labelName={`Clinician/Therapist`}
            data-testid={`clinician role Clinician/Therapist`}
          />
          <Field
            component={(field) => <RadioButton {...field} name="role" />}
            value={`admin`}
            htmlFor={`administrative`}
            checked={`admin` === values.role}
            name="role"
            onChange={(value) => {
              setFieldValue('role', value);
            }}
            // onBlur={handleBlur}
            tooltipDescription={formatMessage(messages.adminRoleTooltip)}
            displayTooltip="inline-block"
            labelName={`Administrative Only`}
            data-testid={`clinician role Administrative Only`}
          />
          {Object.entries(touched).length > 0 && !values.role && (
            <div
              className={errorStyles['error-label']}
              error-testid={`clinician account type error`}
            >
              {!values.role ? firstStepValidation.role : ''}
            </div>
          )}
        </Cell>
      </Fragment>
    </Fragment>
  );
};

ClinicianRegistrationStepOne.propTypes = {};

ClinicianRegistrationStepOne.defaultProps = {};

export default ClinicianRegistrationStepOne;
