import { defineMessages } from 'react-intl';

export default defineMessages({
  intakeTitle: {
    id: 'pages.myAccountIntakeFormsPage.intake.title',
    description: 'Intake Form',
    defaultMessage: 'Intake Form',
  },
  consentTitle: {
    id: 'pages.myAccountIntakeFormsPage.consent.title',
    description: 'Informed Consent Form',
    defaultMessage: 'Informed Consent Form',
  },
});
