import React, { useState, Fragment, useContext, useEffect } from 'react';
import classnames from 'classnames';
import Loading from '../../../components/common/Loading';
import DoctorLeftNav from '../../../components/nav/DoctorLeftNav';
import styles from './CalendlyAvailabilityPage.module.scss';
import AuthContext from '../../../context/AuthContext';
import { getDoctor, getBookingTypes } from '../../../api/doctor';
import {
  getDoctorEvents,
  connectCalendly,
  disconnectCalendly,
} from '../../../api/calendly';
import useIntl from '../../../hooks/useIntl';
import messages from './messages';
import { Formik, Form } from 'formik';
import { requiredValidator } from '../../../utils/validators';
import { Cell, Grid } from 'react-foundation';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import FieldInput from '../../../components/common/fields/FieldInput/FieldInput';
import CalendlyEvent from '../../../components/CalendlyEvent';
import MessageModal from '../../../components/MessageModal';
import { CALENDAR_TYPES } from '../../../constants';

const fieldValidators = ({ apiKey }) => ({
  ...requiredValidator(apiKey, 'apiKey'),
});
const CalendlyAvailabilityPage = () => {
  const { formatMessage } = useIntl();
  const { isLoading, authUser } = useContext(AuthContext);
  const [dataLoading, setLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const isDataLoading = dataLoading || isLoading;
  const [step, setStep] = useState(1);
  const [apiKeyError, setApiKeyError] = useState('');
  const [events, setEvents] = useState([]);
  const [bookingTypes, setBookingTypes] = useState([]);
  const [apiKey, setApiKey] = useState('');
  const [isAmountChange, setIsAmountChange] = useState(false);
  const [isEventPrepared, setIsEventPrepared] = useState(false);
  const [isAmountNotAdded, setIsAmountNotAdded] = useState(false);
  const [calendlyKey, setCalendlyKey] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [displayMessage, setDisplayMessae] = useState({
    title: '',
    message: '',
  });

  const fetchBookingTypes = async () => {
    if (authUser && authUser.id) {
      try {
        const { doctor } = await getDoctor(authUser.id);
        const { bookingTypes: savedBookingTypes } = await getBookingTypes(
          authUser.id,
          CALENDAR_TYPES.CALENDLY,
        );
        setBookingTypes(savedBookingTypes);
        setProfile(doctor);
        if (doctor.calendlyApiKey) {
          await getEvents({ apiKey: doctor.calendlyApiKey });
          setStep(3);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    localStorage.removeItem('doctorRedirectionUrl');
    fetchBookingTypes();
  }, [authUser]);

  const getEvents = async (values) => {
    setApiKeyError('');
    setLoading(true);
    const headers = {
      'X-TOKEN': values.apiKey,
    };
    try {
      const { data } = await getDoctorEvents({ headers });
      setApiKey(values.apiKey);
      const events = data.filter((event) => event.attributes.active);
      setEvents(events);
      setStep(3);
      setLoading(false);
    } catch (e) {
      let errorMsg = 'Invalid token';
      if (e.response && e.response.data && e.response.data.message) {
        errorMsg = e.response.data.message;
      }
      setApiKey('');
      setApiKeyError(errorMsg);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const amountNotAdded = bookingTypes.some(
      (e) => e.amount === '' || e.amount === undefined,
    );
    setIsAmountNotAdded(amountNotAdded);
    if (!amountNotAdded) {
      setLoading(true);
      try {
        const obj = {
          calendlyApiKey: apiKey,
          bookingTypes,
          calendar_type: CALENDAR_TYPES.CALENDLY,
        };
        const { doctor } = await connectCalendly(profile.id, obj);
        setProfile(doctor);
        setLoading(false);
        setShowMessageModal(true);
        setDisplayMessae({
          type: 'success',
          title: 'Calendly connected successfully',
          text: '',
        });
      } catch (e) {
        let errorMsg =
          'Please make sure that Calendly connection is up to date';
        if (e.response && e.response.data && e.response.data.message) {
          errorMsg = e.response.data.message;
        }
        setLoading(false);
        setShowMessageModal(true);
        setDisplayMessae({
          type: 'error',
          title: 'Calendly Connection Error',
          text: errorMsg,
        });
      }
    }
  };

  const handleCardClick = (e, event) => {
    if (!isAmountChange) {
      if (checkIsEventSelected(event.id)) {
        setBookingTypes((bookingTypes) =>
          bookingTypes.filter((e) => e.id !== event.id),
        );
      } else {
        setBookingTypes((bookingTypes) => [
          ...bookingTypes,
          {
            id: event.id,
            name: event.attributes.name,
            calendly_name: event.attributes.name,
            url: event.attributes.url,
            doctor_id: authUser.id,
            duration: event.attributes.duration,
            amount: event.amount,
          },
        ]);
      }
    }
  };

  const handleDisconnectCalendly = async () => {
    setLoading(true);
    try {
      const { doctor } = await disconnectCalendly(profile.id);
      setProfile(doctor);
      setApiKey('');
      setBookingTypes([]);
      setStep(1);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const checkIsEventSelected = (id) =>
    bookingTypes.find((event) => event.id === id);

  const handleOnChangeAmount = (e, index) => {
    if (Math.sign(e.target.value) < 0) {
      return;
    }
    setIsAmountChange(true);
    const tempEvents = [...events];
    tempEvents[index].amount = e.target.value;
    setEvents([...tempEvents]);
    const tempBookingTypes = bookingTypes.map((event) => {
      if (event.id === tempEvents[index].id) {
        event.amount = tempEvents[index].amount;
      }
      return event;
    });
    setBookingTypes([...tempBookingTypes]);
  };

  const prepareSelectedEvents = () => {
    if (!isEventPrepared && events.length && bookingTypes.length) {
      let eve = {};
      const selectedBookingTypes = [];
      const mergedEvents = events.map((event) => {
        eve = bookingTypes.find((e) => e.id === event.id);
        if (eve) {
          selectedBookingTypes.push({
            ...eve,
            ...{
              name: event.attributes.name,
              calendly_name: event.attributes.name,
              url: event.attributes.url,
              duration: event.attributes.duration,
            },
          });
          return { ...event, amount: eve.amount, name: event.name };
        } else {
          event.calendly_name = event.name;
        }
        return event;
      });
      setEvents(mergedEvents);
      setBookingTypes([...selectedBookingTypes]);
      setIsEventPrepared(true);
    }
  };

  useEffect(() => {
    prepareSelectedEvents();
  }, [bookingTypes, events]);

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftNav, 'desktop-only')}>
        <DoctorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            ></div>
            <h1>{formatMessage(messages.title)}</h1>

            <Fragment>
              {step === 1 && (
                <Fragment>
                  <p className={styles.subTitle}>
                    {formatMessage(messages.subTitle)}
                  </p>
                  <hr />
                  <h2>{formatMessage(messages.stepOne)}</h2>
                  <hr />
                  <p className={styles.stepDesc}>
                    Log in to your Calendly account at{' '}
                    <a target="_blank" href="https://calendly.com/login">
                      https://calendly.com/login
                    </a>
                    .
                  </p>
                  <h2 className={styles.marginTop20}>
                    {formatMessage(messages.stepTwo)}
                  </h2>
                  <hr />
                  <img
                    src={require(`../../../img/calendly_integrations.png`)}
                    alt="calendly_integrations"
                  />
                  <h2 className={styles.marginTop20}>
                    {formatMessage(messages.stepThree)}
                  </h2>
                  <hr />
                  <p className={styles.stepDesc}>
                    {formatMessage(messages.apiKeyDesc)}
                  </p>
                  <img
                    className={styles.api_key_img}
                    src={require(`../../../img/calendly_api_key.png`)}
                    alt="calendly_api_key"
                  />
                  <Cell
                    small={12}
                    flexContainer
                    alignY="middle"
                    flexDirCol="all"
                  >
                    <PrimaryButton
                      label={formatMessage(messages.nextBtnLabel)}
                      type="button"
                      className="font-18"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setStep(2);
                      }}
                    />
                  </Cell>
                </Fragment>
              )}
              <Formik
                initialValues={{
                  apiKey: '',
                }}
                validate={fieldValidators}
                enableReinitialize
                render={({ values, handleChange, errors, setFieldValue }) => {
                  return (
                    <Form noValidate>
                      <Grid>
                        {step === 2 && (
                          <Fragment>
                            <Cell small={8}>
                              <FieldInput
                                autoComplete="apiKey"
                                autoFocus={true}
                                label={formatMessage(
                                  messages.apiKeyPlaceholder,
                                )}
                                errorText={errors.apiKey || apiKeyError}
                                name="apiKey"
                                value={values.apiKey}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  setFieldValue('apiKey', e.target.value);
                                  setCalendlyKey(e.target.value);
                                }}
                                data-testid="apiKey"
                              />
                            </Cell>
                            <Cell
                              small={12}
                              flexContainer
                              alignY="middle"
                              className={styles['actions-container']}
                            >
                              <SecondaryButton
                                label={formatMessage(messages.backBtnLabel)}
                                className="font-18"
                                type="button"
                                onClick={() => {
                                  setStep(1);
                                  setApiKeyError('');
                                }}
                              />
                              <PrimaryButton
                                disabled={
                                  !values.apiKey ||
                                  Object.entries(errors).length > 0
                                }
                                label={formatMessage(messages.nextBtnLabel)}
                                type="button"
                                className="font-18"
                                onClick={() => getEvents(values)}
                              />
                            </Cell>
                          </Fragment>
                        )}
                        {step === 3 && (
                          <Fragment>
                            <div className={styles.eventContainer}>
                              <div className={styles.stepDesc}>
                                {formatMessage(messages.chooseEventType)}
                              </div>
                              <div className={styles.events}>
                                {events.map((event, index) => (
                                  <CalendlyEvent
                                    key={index}
                                    name={event.attributes.name}
                                    duration={event.attributes.duration}
                                    onClick={(e) => handleCardClick(e, event)}
                                    isActive={checkIsEventSelected(event.id)}
                                    amount={event.amount}
                                    onAmountChange={(e) =>
                                      handleOnChangeAmount(e, index)
                                    }
                                    onAmountBlur={() =>
                                      setIsAmountChange(false)
                                    }
                                    onAmountFocus={() =>
                                      setIsAmountChange(true)
                                    }
                                    index={index}
                                  />
                                ))}
                              </div>
                            </div>
                            {isAmountNotAdded && (
                              <div>
                                <p className={styles.errorMsg}>
                                  Please add amount in each selected event type
                                </p>
                              </div>
                            )}
                            <Cell
                              small={12}
                              flexContainer
                              alignY="middle"
                              className={styles['actions-container']}
                            >
                              <SecondaryButton
                                label={formatMessage(messages.backBtnLabel)}
                                className="font-18"
                                type="button"
                                onClick={() => {
                                  setFieldValue('apiKey', calendlyKey);
                                  setStep(2);
                                  setBookingTypes([]);
                                }}
                              />
                              <PrimaryButton
                                disabled={!bookingTypes.length}
                                label={formatMessage(messages.saveBtnLabel)}
                                type="submit"
                                className="font-18"
                                onClick={() => handleSubmit(values)}
                              />
                            </Cell>
                            {profile.calendlyApiKey && (
                              <p className={styles.stepDesc}>
                                Your account has been connected with Calendly,
                                click{' '}
                                <span
                                  className={styles.link}
                                  onClick={() => handleDisconnectCalendly()}
                                >
                                  here
                                </span>{' '}
                                to disconnect.
                              </p>
                            )}
                          </Fragment>
                        )}
                      </Grid>
                    </Form>
                  );
                }}
              />
            </Fragment>
          </div>
        </Fragment>
      )}
      {showMessageModal && (
        <MessageModal
          message={displayMessage}
          onCloseModal={() => setShowMessageModal(false)}
        />
      )}
    </div>
  );
};

export default CalendlyAvailabilityPage;
