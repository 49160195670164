export const binaryResponses = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

export const diseaseList = [
  {
    label: 'Cancer',
    value: 'Cancer',
  },
  {
    label: 'Heart disease',
    value: 'Heart disease',
  },
  {
    label: 'Diabetes',
    value: 'Diabetes',
  },
  {
    label: 'High blood pressure',
    value: 'High blood pressure',
  },
  {
    label: 'Liver disease',
    value: 'Liver disease',
  },
  {
    label: 'High cholesterol',
    value: 'High cholesterol',
  },
  {
    label: 'Overdose',
    value: 'Overdose',
  },
  {
    label:
      'Auto-Immune disorders (such as thyroid condition, lupus, rheumatoid arthritis, etc.)',
    value:
      'Auto-Immune disorders (such as thyroid condition, lupus, rheumatoid arthritis, etc.)',
  },
  {
    label:
      'Neurological conditions (migraine, head injuries, seizures, strokes, etc.)',
    value:
      'Neurological conditions (migraine, head injuries, seizures, strokes, etc.)',
  },
  {
    label: 'Other  (If other please describe)',
    value: 'other',
  },
];

export const depressionSymptoms = [
  {
    label: 'Sadness',
    value: 'Sadness',
  },
  {
    label: 'Feeling down',
    value: 'Feeling down',
  },
  {
    label: 'Depressed',
    value: 'Depressed',
  },
  {
    label: 'Empty',
    value: 'Empty',
  },
  {
    label: 'Hopeless more days than not',
    value: 'Hopeless more days than not',
  },
  {
    label: 'Loss of interest or pleasure in activities more days than not',
    value: 'Loss of interest or pleasure in activities more days than not',
  },
  {
    label: 'Unintentional weight gain',
    value: 'Unintentional weight gain',
  },
  {
    label: 'Unintentional weight loss',
    value: 'Unintentional weight loss',
  },
  {
    label: 'Decreased appetite',
    value: 'Decreased appetite',
  },
  {
    label: 'Increased appetite',
    value: 'Increased appetite',
  },
  {
    label: 'Agitation or sluggishness more days than not',
    value: 'Agitation or sluggishness more days than not',
  },
  {
    label: 'Fatigue or having little energy more days than not',
    value: 'Fatigue or having little energy more days than not',
  },
  {
    label:
      'Feelings of worthlessness or feeling bad about yourself or guilt or low self-esteem',
    value:
      'Feelings of worthlessness or feeling bad about yourself or guilt or low self-esteem',
  },
  {
    label:
      'Difficulty concentrating, staying on task or decision-making more days than not',
    value:
      'Difficulty concentrating, staying on task or decision-making more days than not',
  },
  {
    label: 'Thoughts about death, suicidal or suicide attempt(s)',
    value: 'Thoughts about death, suicidal or suicide attempt(s)',
  },
];

export const anxietySymptoms = [
  {
    label: 'Uncontrollable worry or fear most of the time/more days than not',
    value: 'Uncontrollable worry or fear most of the time/more days than not',
  },
  {
    label: 'Nervousness that something bad will happen in the future',
    value: 'Nervousness that something bad will happen in the future',
  },
  {
    label: 'Irritability',
    value: 'Irritability',
  },
  {
    label: 'Restlessness',
    value: 'Restlessness',
  },
  {
    label: 'Muscle tension',
    value: 'Muscle tension',
  },
  {
    label: 'Social withdrawal/isolation',
    value: 'Social withdrawal/isolation',
  },
  {
    label: 'Fear of dying',
    value: 'Fear of dying',
  },
  {
    label: 'Heart racing, headaches, stomach discomfort or nausea',
    value: 'Heart racing, headaches, stomach discomfort or nausea',
  },
  {
    label: 'Panic attacks',
    value: 'Panic attacks',
  },
];

export const stressSymptoms = [
  {
    label: 'Flashbacks',
    value: 'Flashbacks',
  },
  {
    label: 'Nightmares',
    value: 'Nightmares',
  },
  {
    label: 'Significant anxiety, such as being easily startled',
    value: 'Significant anxiety, such as being easily startled',
  },
  {
    label: 'Frequently thinking about a previous traumatic event',
    value: 'Frequently thinking about a previous traumatic event',
  },
  {
    label:
      'Significant changes to your behavior, thoughts, or social interactions after any event(s)',
    value:
      'Significant changes to your behavior, thoughts, or social interactions after any event(s)',
  },
  {
    label: 'Other (please explain)',
    value: 'other',
  },
];

export const maniaSymptoms = [
  {
    label: 'Persistent elevation in mood or irritability more days than not',
    value: 'Persistent elevation in mood or irritability more days than not',
  },
  {
    label: 'Inflated self-esteem or grandiosity',
    value: 'Inflated self-esteem or grandiosity',
  },
  {
    label: 'Decreased need for sleep or feeling rested after 3 hours of sleep',
    value: 'Decreased need for sleep or feeling rested after 3 hours of sleep',
  },
  {
    label: 'More talkative than usual',
    value: 'More talkative than usual',
  },
  {
    label:
      'Racing thoughts or thoughts rapidly jumping to different ideas or topics',
    value:
      'Racing thoughts or thoughts rapidly jumping to different ideas or topics',
  },
  {
    label: 'Easily distracted, difficulty concentrating or staying on task',
    value: 'Easily distracted, difficulty concentrating or staying on task',
  },
  {
    label: 'Increase in goal-directed activities or agitation',
    value: 'Increase in goal-directed activities or agitation',
  },
  {
    label:
      'Increase involvement in activities with potential negative consequences',
    value:
      'Increase involvement in activities with potential negative consequences',
  },
  {
    label: 'Other (please explain)',
    value: 'other',
  },
];

export const ocdSymptoms = [
  {
    label:
      'Persistent unwanted ideas, images or impulses that seem silly or horrible and cause you distress Worry excessively about dirt, germs, or chemicals',
    value:
      'Persistent unwanted ideas, images or impulses that seem silly or horrible and cause you distress Worry excessively about dirt, germs, or chemicals',
  },
  {
    label:
      'Constantly worry that something bad will happen because you forgot something important like locking the door or turning off appliances',
    value:
      'Constantly worry that something bad will happen because you forgot something important like locking the door or turning off appliances',
  },
  {
    label: 'Always afraid you will lose something of importance',
    value: 'Always afraid you will lose something of importance',
  },
  {
    label:
      "Afraid you will act or speak aggressively when you really don't want to",
    value:
      "Afraid you will act or speak aggressively when you really don't want to",
  },
  {
    label:
      'Feeling like you must do something excessively or have thoughts you must think repeatedly to feel comfortable or ease anxiety',
    value:
      'Feeling like you must do something excessively or have thoughts you must think repeatedly to feel comfortable or ease anxiety',
  },
  {
    label: 'Wash yourself or things around you excessively',
    value: 'Wash yourself or things around you excessively',
  },
  {
    label:
      'Check things over and over or repeat actions many times to be sure they are done properly',
    value:
      'Check things over and over or repeat actions many times to be sure they are done properly',
  },
  {
    label:
      "Keep many useless things because you feel that you can't throw them away",
    value:
      "Keep many useless things because you feel that you can't throw them away",
  },
  {
    label: 'Other (please explain)',
    value: 'other',
  },
];

export const behaviorConcernsSymptoms = [
  {
    label: 'Anger management challenges',
    value: 'Anger management challenges',
  },
  {
    label:
      'Actively refuse to comply with rules Often deliberately annoy others',
    value:
      'Actively refuse to comply with rules Often deliberately annoy others',
  },
  {
    label:
      'Often blame others for your mistakes Often touchy or easily annoyed by others Spiteful and vindictive',
    value:
      'Often blame others for your mistakes Often touchy or easily annoyed by others Spiteful and vindictive',
  },
  {
    label:
      'Aggressive, violent behavior or physical altercations Threatening others with harm',
    value:
      'Aggressive, violent behavior or physical altercations Threatening others with harm',
  },
  {
    label: 'Property destruction',
    value: 'Property destruction',
  },
  {
    label: 'Other (please explain)',
    value: 'other',
  },
];

export const attentionalIssuesSymptoms = [
  {
    label:
      'Often fail to give close attention to details or makes careless mistakes in schoolwork, work or other activities Often have difficulty sustaining attention in tasks or play activities',
    value:
      'Often fail to give close attention to details or makes careless mistakes in schoolwork, work or other activities Often have difficulty sustaining attention in tasks or play activities',
  },
  {
    label: 'Often do not seem to listen when spoken to directly',
    value: 'Often do not seem to listen when spoken to directly',
  },
  {
    label:
      'Often do not follow through on instructions and fails to finish schoolwork, chores or duties in the work-place Often have difficulties organizing tasks and activities',
    value:
      'Often do not follow through on instructions and fails to finish schoolwork, chores or duties in the work-place Often have difficulties organizing tasks and activities',
  },
  {
    label:
      'Often avoid, dislike or are reluctant to engage in tasks that require sustained mental effort Often lose things necessary for tasks or activities',
    value:
      'Often avoid, dislike or are reluctant to engage in tasks that require sustained mental effort Often lose things necessary for tasks or activities',
  },
  {
    label: 'Are often easily distracted by extraneous stimuli',
    value: 'Are often easily distracted by extraneous stimuli',
  },
  {
    label: 'Are often forgetful in daily activities',
    value: 'Are often forgetful in daily activities',
  },
  {
    label: 'Often fidget with hands or feet, or squirm in seat',
    value: 'Often fidget with hands or feet, or squirm in seat',
  },
  {
    label:
      'Often leave seat or in other situations in which remaining seated is expected',
    value:
      'Often leave seat or in other situations in which remaining seated is expected',
  },
  {
    label: 'Often run about or feeling restlessness',
    value: 'Often run about or feeling restlessness',
  },
  {
    label:
      'Often have difficulty playing or engaging in leisure activities quietly',
    value:
      'Often have difficulty playing or engaging in leisure activities quietly',
  },
  {
    label: 'Are often "on the go" or acts as if "driven by a motor"',
    value: 'Are often "on the go" or acts as if "driven by a motor"',
  },
  {
    label: 'Often talk excessively',
    value: 'Often talk excessively',
  },
  {
    label: 'Often blurt out answers before questions have been completed',
    value: 'Often blurt out answers before questions have been completed',
  },
  {
    label: 'Often have difficulty awaiting turn',
    value: 'Often have difficulty awaiting turn',
  },
  {
    label: 'Often interrupt others (e.g., butt into conversations)',
    value: 'Often interrupt others (e.g., butt into conversations)',
  },
  {
    label: 'Other (please explain)',
    value: 'other',
  },
];

export const eatingProblemsSymptoms = [
  {
    label:
      'Preoccupation with body weight Restrictive eating habits Excessive exercise',
    value:
      'Preoccupation with body weight Restrictive eating habits Excessive exercise',
  },
  {
    label: 'Food hoarding',
    value: 'Food hoarding',
  },
  {
    label: 'Low weight',
    value: 'Low weight',
  },
  {
    label: 'Binge behaviors',
    value: 'Binge behaviors',
  },
  {
    label: 'Missed menstrual periods',
    value: 'Missed menstrual periods',
  },
  {
    label: 'Use of diet pills/diuretics/laxatives to lose weight',
    value: 'Use of diet pills/diuretics/laxatives to lose weight',
  },
  {
    label:
      'Preoccupation with counting calories and/or balancing the "scale" of energy in vs energy out',
    value:
      'Preoccupation with counting calories and/or balancing the "scale" of energy in vs energy out',
  },
  {
    label: 'Other (please explain)',
    value: 'other',
  },
];

export const psychosisSymptoms = [
  {
    label: 'Thoughts that people are out to get you or harm you',
    value: 'Thoughts that people are out to get you or harm you',
  },
  {
    label:
      'Persistent thoughts that you have superpowers or are smarter/better than everyone else Hear or see things that others cannot see or hear',
    value:
      'Persistent thoughts that you have superpowers or are smarter/better than everyone else Hear or see things that others cannot see or hear',
  },
  {
    label: 'Trouble keeping thoughts organized',
    value: 'Trouble keeping thoughts organized',
  },
  {
    label: 'Other (please explain)',
    value: 'other',
  },
];

export const psychiatricTreatment = [
  {
    label: 'Inpatient hospitalization',
    value: 'Inpatient hospitalization',
  },
  {
    label: 'Residential',
    value: 'Residential',
  },
  {
    label: 'Partial Hospitalization Program (PHP)',
    value: 'Partial Hospitalization Program (PHP)',
  },
  {
    label: 'Intensive Outpatient Program (IOP)',
    value: 'Intensive Outpatient Program (IOP)',
  },
  {
    label:
      'Outpatient treatment (including therapy and/or medication evaluation and monitoring)',
    value:
      'Outpatient treatment (including therapy and/or medication evaluation and monitoring)',
  },
];

export const previousMentalConditions = [
  {
    label:
      'Mood disorder (Major depressive disorder, Dysthymic disorder, Bipolar disorder, Disruptive mood dysregulation disorder)',
    value:
      'Mood disorder (Major depressive disorder, Dysthymic disorder, Bipolar disorder, Disruptive mood dysregulation disorder)',
  },
  {
    label:
      'Anxiety disorder (Generalized anxiety disorder, Panic disorder, Social anxiety disorder) ',
    value:
      'Anxiety disorder (Generalized anxiety disorder, Panic disorder, Social anxiety disorder) ',
  },
  {
    label:
      'Trauma or Stress related disorder (Adjustment disorder, Bereavement, PTSD) Attention disorder (ADHD, ADD)',
    value:
      'Trauma or Stress related disorder (Adjustment disorder, Bereavement, PTSD) Attention disorder (ADHD, ADD)',
  },
  {
    label: 'Alcohol or Substance use disorder',
    value: 'Alcohol or Substance use disorder',
  },
  {
    label: "Autism/Asperger's disorder",
    value: "Autism/Asperger's disorder",
  },
  {
    label: 'Personality disorder',
    value: 'Personality disorder',
  },
  {
    label:
      'Psychotic disorder (Schizophrenia, Schizoaffective disorder, Psychosis)',
    value:
      'Psychotic disorder (Schizophrenia, Schizoaffective disorder, Psychosis)',
  },
  {
    label: 'Other (please describe)',
    value: 'other',
  },
];

export const periodicityOptions = [
  {
    label: 'Not applicable/No symptoms',
    value: 'Not applicable/No symptoms',
  },
  {
    label: 'Less than a month',
    value: 'Less than a month',
  },
  {
    label: 'A month or two',
    value: 'A month or two',
  },
  {
    label: 'Three to six months',
    value: 'Three to six months',
  },
  {
    label: 'Six months to a year',
    value: 'Six months to a year',
  },
  {
    label: 'A year or two',
    value: 'A year or two',
  },
  {
    label: 'More than two years',
    value: 'More than two years',
  },
];

export const basicResponses = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Not applicable',
    value: 'not applicable',
  },
];

export const frequencyOptions = [
  {
    label: 'Daily',
    value: 'Daily',
  },
  {
    label: 'Weekly',
    value: 'Weekly',
  },
  {
    label: 'Weekly',
    value: 'Weekly',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const genderIdentities = [
  {
    label: 'Agender',
    value: 'Agender',
  },
  {
    label: 'Female',
    value: 'Female',
  },
  {
    label: 'Gender Fluid',
    value: 'Gender Fluid',
  },
  {
    label: 'Gender Neutral',
    value: 'Gender Neutral',
  },
  {
    label: 'Genderqueer',
    value: 'Genderqueer',
  },
  {
    label: 'Intersex',
    value: 'Intersex',
  },
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Non-conforming',
    value: 'non-conforming',
  },
  {
    label: 'Questioning',
    value: 'Questioning',
  },
  {
    label: 'Third Gender',
    value: 'Third Gender',
  },
  {
    label: 'Transgender',
    value: 'Transgender',
  },
  {
    label: 'Two-Spirit',
    value: 'Two-Spirit',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const sexualOrientations = [
  {
    label: 'Asexual',
    value: 'Asexual',
  },
  {
    label: 'Bisexual',
    value: 'Bisexual',
  },
  {
    label: 'Demisexual',
    value: 'Demisexual',
  },
  {
    label: 'Gay',
    value: 'Gay',
  },
  {
    label: 'Flexible',
    value: 'Flexible',
  },
  {
    label: 'Heterosexual/Straight',
    value: 'Heterosexual/Straight',
  },
  {
    label: 'Lesbian',
    value: 'Lesbian',
  },
  {
    label: 'Pansexual',
    value: 'Pansexual',
  },
  {
    label: 'Questioning',
    value: 'Questioning',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const relationshipStatuses = [
  {
    label: 'Single',
    value: 'Single',
  },
  {
    label: 'Short term (less than 1 year)',
    value: 'Short term (less than 1 year)',
  },
  {
    label: 'Long term',
    value: 'Long term',
  },
  {
    label: 'Domestic Partnership',
    value: 'Domestic Partnership',
  },
  {
    label: 'Married',
    value: 'Married',
  },
  {
    label: 'Divorced',
    value: 'Divorced',
  },
  {
    label: 'Widowed',
    value: 'Widowed',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const relationshipTypes = [
  {
    label: 'Monogamous',
    value: 'Monogamous',
  },
  {
    label: 'Polyamorous',
    value: 'Polyamorous',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const residentTypes = [
  {
    label: 'Alone',
    value: 'Alone',
  },
  {
    label: 'Spouse/Significant Other',
    value: 'Spouse/Significant Other',
  },
  {
    label: 'Family',
    value: 'Family',
  },
  {
    label: 'Roommates/Friends',
    value: 'Roommates/Friends',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const employmentStatuses = [
  {
    label: 'Employed Full-time',
    value: 'Employed Full-time',
  },
  {
    label: 'Employed Part-time',
    value: 'Employed Part-time',
  },
  {
    label: 'Unemployed',
    value: 'Unemployed',
  },
  {
    label: 'Self-employed',
    value: 'Self-employed',
  },
  {
    label: 'Student Full-time',
    value: 'Student Full-time',
  },
  {
    label: 'Student Part-time',
    value: 'FamStudent Part-timeily',
  },
  {
    label: 'Active Military',
    value: 'Active Military',
  },
  {
    label: 'Retired',
    value: 'Retired',
  },
];

export const incomeSources = [
  {
    label: 'Employment',
    value: 'Employment',
  },
  {
    label: 'Spouse/Partner’s Income',
    value: 'Spouse/Partner’s Income',
  },
  {
    label: 'Disability',
    value: 'Disability',
  },
  {
    label: 'Pension',
    value: 'Pension',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const emotionalSupport = [
  {
    label: 'Family',
    value: 'Family',
  },
  {
    label: 'Friends',
    value: 'Friends',
  },
  {
    label:
      'Community Support group (such as church, school or activity groups)',
    value:
      'Community Support group (such as church, school or activity groups)',
  },
  {
    label: 'None',
    value: 'None',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const legalStatuses = [
  {
    label: 'N/A',
    value: 'N/A',
  },
  {
    label: 'Court case pending',
    value: 'Court case pending',
  },
  {
    label: 'Probation',
    value: 'Probation',
  },
  {
    label: 'Parole',
    value: 'Parole',
  },
];

export const sexAtBirth = [
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Female',
    value: 'Female',
  },
  {
    label: 'Intersex',
    value: 'Intersex',
  },
];
