import React, { useState, Fragment, useEffect, useContext } from 'react';
import { Form } from 'formik';
import LeftNav from '../../../components/nav/LeftNav';
import { Cell, Grid } from 'react-foundation';
import useIntl from '../../../hooks/useIntl';
import messages from './messages';
import styles from './PaymentPage.module.scss';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import useSquarePayment from '../../../hooks/useSquarePayment';
import Loading from '../../../components/common/Loading';
import AuthContext from '../../../context/AuthContext';
import { isObjectEmpty } from '../../../utils/common';
import {
  savePatientCard,
  getPatientCards,
  deletePatientCard,
} from '../../../api/patient';
import classnames from 'classnames';
import SquareupContext from '../../../context/SquareupContext';

const isInvalidToCheckout = (authUser) => {
  return isObjectEmpty(authUser);
};

const PaymentPage = () => {
  const [cards, setCards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const { authUser } = useContext(AuthContext);
  const { formatMessage } = useIntl();
  const [patientId, setPatientId] = useState(null);
  const { dispatch } = useContext(SquareupContext);
  const [error, setError] = useState('');

  const fetchProfile = async () => {
    setIsLoading(true);
    if (authUser.id) {
      try {
        const { cards: patientCards } = await getPatientCards(authUser.id);
        if (!patientCards.length) {
          setShowCardForm(true);
        }
        setCards(patientCards);
        setPatientId(authUser.id);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [authUser]);

  useEffect(() => {
    if (showCardForm) {
      dispatch({ type: 'FORM_LOADING', payload: true });
    }
  }, [showCardForm]);

  const handleSubmit = async (nonceToken) => {
    setIsLoading(true);
    try {
      await savePatientCard(patientId, nonceToken);
      const { cards } = await getPatientCards(patientId);
      setCards(cards);
      setIsLoading(false);
      setShowCardForm(false);
    } catch (e) {
      setError(
        (e.response && e.response.data && e.response.data.message) ||
          formatMessage(messages.invalidCardDetails),
      );
      setIsLoading(false);
      setShowCardForm(false);
      setTimeout(() => {
        setShowCardForm(true);
      }, 500);
    }
  };

  const deleteCard = async (cardId) => {
    setIsLoading(true);
    setShowCardForm(false);
    try {
      await deletePatientCard(patientId, cardId);
      const { cards: patientCards } = await getPatientCards(patientId);
      if (!patientCards.length) {
        setShowCardForm(true);
      }
      setCards(patientCards);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const { initialized, onGetCardNonce } = useSquarePayment({
    styles: styles['card-input'],
    handleOnSubmit: handleSubmit,
    isValidCartToCheckout: !isInvalidToCheckout(authUser) && showCardForm,
  });

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <LeftNav />
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            />
            <h1 className={styles.title} data-testid="payment header">
              {formatMessage(messages.title)}
            </h1>
            <div className={styles.paymentContainer}>
              <div className={styles.cards}>
                {cards.length > 0 &&
                  cards.map((card, index) => (
                    <div key={index} className={styles.cardItem}>
                      <span
                        className={styles[card.card_brand.toLowerCase()]}
                      ></span>
                      <p>Ending with {card.last_4}</p>
                      <p>
                        Exp on {card.exp_month}/{card.exp_year}
                      </p>
                      <button
                        type="button"
                        className="secondary"
                        onClick={() => deleteCard(card.id)}
                        data-testid={card.id}
                      ></button>
                    </div>
                  ))}
              </div>
              {cards.length > 0 && (
                <PrimaryButton
                  disabled={showCardForm}
                  label={formatMessage(messages.addNewCardBtn)}
                  type="button"
                  className="font-18"
                  onClick={() => setShowCardForm(true)}
                  data-testid="addnew button"
                />
              )}
              {isLoading && !initialized ? (
                <Loading />
              ) : (
                <Fragment>
                  {showCardForm && (
                    <Form data-testid="card-form">
                      <div className={styles.form}>
                        <Grid>
                          <Cell small={12} medium={6}>
                            <label htmlFor="ccnumber">
                              {formatMessage(messages.cardNumber)}
                            </label>
                            <div id="ccnumber" />
                            <span
                              id="cardNumber-error"
                              className="error-message"
                            />
                          </Cell>
                          <Cell small={6} medium={3}>
                            <label htmlFor="ccexp">
                              {formatMessage(messages.expirationDate)}
                            </label>
                            <div id="ccexp" />
                            <span
                              id="expirationDate-error"
                              className="error-message"
                            />
                          </Cell>
                          <Cell small={6} medium={3}>
                            <label htmlFor="cvv">
                              {formatMessage(messages.cvvCode)}
                            </label>
                            <div id="cvv" />
                            <span id="cvv-error" className="error-message" />
                          </Cell>
                          <Cell small={6} medium={3}>
                            <label htmlFor="postalCodeBilling">
                              {formatMessage(messages.postalCode)}
                            </label>
                            <div id="postalCodeBilling" />
                            <span
                              id="postalCode-error"
                              className="error-message"
                            />
                          </Cell>
                          {error && (
                            <div className={styles['error-container']}>
                              <div
                                className={styles.error}
                                data-testid={`payment-error`}
                              >
                                {error}
                              </div>
                            </div>
                          )}
                          <Cell
                            small={12}
                            flexContainer
                            alignY="middle"
                            className={styles['actions-container']}
                          >
                            <SecondaryButton
                              label={formatMessage(messages.cancelBtnLable)}
                              className="font-18"
                              type="button"
                              onClick={() => {
                                setError('');
                                cards.length
                                  ? setShowCardForm(false)
                                  : setShowCardForm(true);
                              }}
                              data-testid="cancel button"
                            />
                            <PrimaryButton
                              label={formatMessage(messages.saveCardBtn)}
                              type="submit"
                              className="font-18"
                              disabled={!initialized}
                              onClick={async (e) => {
                                onGetCardNonce(e);
                              }}
                              data-testid="submit button"
                            />
                          </Cell>
                        </Grid>
                      </div>
                    </Form>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default PaymentPage;
