import React from 'react';
import TextAreaInputWithLabel from '../../../../common/inputs/TextAreaInputWithLabel';

const TextInput = ({
  item,
  onChange,
  onBlur,
  dataTestId,
  value,
  label,
  placeholder = 'Enter here',
  rows = 2,
  autoFocus = true,
}) => {
  return (
    <TextAreaInputWithLabel
      label={label}
      name={item.id}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      onBlur={onBlur}
      autoFocus={autoFocus}
      data-testid={dataTestId}
      rows={rows}
    />
  );
};

export default TextInput;
