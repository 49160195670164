import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.myAccount.appointment.title',
    description: 'Title for the appointment section',
    defaultMessage: 'Choose appointment type',
  },
  bookingType: {
    id: 'pages.myAccount.appointment.bookingType',
    description: 'Lable for the booking type dropdown field',
    defaultMessage: 'Appointment Type',
  },
  radioLable: {
    id: 'component.myAccount.appointment.radioLable',
    description: 'The label for radio button',
    defaultMessage: 'Is this a new or returning client?',
  },
  newPatientLabel: {
    id: 'component.myAccount.appointment.newPatientLabel',
    description: 'The label for new patient radio button',
    defaultMessage: 'New client',
  },
  oldPatientLable: {
    id: 'component.myAccount.appointment.oldPatientLable',
    description: 'The label for old patient radio button',
    defaultMessage: 'Returning client',
  },
  patientName: {
    id: 'component.myAccount.appointment.patientName',
    description: 'The label for patient name',
    defaultMessage: 'Patient name',
  },
  patientPhoneNumber: {
    id: 'component.myAccount.appointment.patientPhoneNumber',
    description: 'The label for patient phone number',
    defaultMessage: 'Phone number where the doctor can contact you',
  },
  modalTitle: {
    id: 'pages.appointmentPage.modalTitle',
    description: 'Appointment confirmation modal title',
    defaultMessage: 'Confirmed',
  },
  modalText: {
    id: 'pages.appointmentPage.modalText',
    description: 'Appointment confirmation modal text',
    defaultMessage:
      'Your appointment details will be sent to your registered email address.',
  },
  modalButton: {
    id: 'pages.appointmentPage.modalButton',
    description: 'Appointment confirmation button label',
    defaultMessage: 'Close',
  },
  paymentTitle: {
    id: 'pages.checkoutPage.paymentTitle',
    description: 'Title for the payment section',
    defaultMessage: 'Payment',
  },
  cardNumber: {
    id: 'component.paymentForm.cardNumber',
    description: 'Label for card number',
    defaultMessage: 'Card number',
  },
  expirationDate: {
    id: 'component.paymentForm.expirationDate',
    description: 'Label for expiration date',
    defaultMessage: 'Expiration date',
  },
  cvvCode: {
    id: 'component.paymentForm.cvvCode',
    description: 'Label for cvv code',
    defaultMessage: 'Security code',
  },
  postalCode: {
    id: 'component.paymentForm.postalCode',
    description: 'Label for postal code',
    defaultMessage: 'Billing zip code',
  },
  intakeFormText: {
    id: 'component.paymentForm.intakeFormText',
    description: 'Intake form text',
    defaultMessage:
      'One step closer to health - please complete the intake forms.',
  },
  intakeFormLable: {
    id: 'component.paymentForm.intakeFormLable',
    description: 'Intake form lable',
    defaultMessage: 'Intake form',
  },
  intakeFormDesc: {
    id: 'component.paymentForm.intakeFormDesc',
    description: 'Intake form decsription',
    defaultMessage: 'Complete the intake form to start your consultation.',
  },
  intakeFormBtnLable: {
    id: 'component.paymentForm.intakeFormBtLable',
    description: 'Intake form button lable',
    defaultMessage: 'Begin Intake',
  },
});
