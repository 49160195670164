import React, { useState, Fragment, useContext, useEffect } from 'react';
import classnames from 'classnames';

import Loading from '../../../components/common/Loading';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import SquareDropdown from '../../../components/common/inputs/SquareDropdown';
import PatientList from '../../../components/PatientList';
import DcotorLeftNav from '../../../components/nav/DoctorLeftNav';

import styles from './PatientPage.module.scss';
import AuthContext from '../../../context/AuthContext';

import { getPatientList } from '../../../api/doctor';
import zoom from '../../../img/icons/zoom.svg';

const sortByOptions = [
  {
    value: { field: 'patient.first_name', order: 'asc' },
    label: 'First name A-Z',
  },
  {
    value: { field: 'patient.first_name', order: 'desc' },
    label: 'First name Z-A',
  },
  {
    value: { field: 'patient.last_name', order: 'asc' },
    label: 'Last name A-Z',
  },
  {
    value: { field: 'patient.last_name', order: 'desc' },
    label: 'Last name Z-A',
  },
];

const PatientPage = () => {
  const { isLoading, authUser } = useContext(AuthContext);

  const [dataLoading, setLoading] = useState(false);
  const [limit] = useState(10);
  const [skip, setSkip] = useState(0);
  const isDataLoading = dataLoading || isLoading;
  const [patientList, setPatientList] = useState([]);
  const [totalPatients, setTotalPatients] = useState([]);
  const [sortby, setSortBy] = useState();
  const [formattedPatientList, setFormattedPatientList] = useState([]);
  const [search, setSearch] = useState('');

  /**
   * TODO this must to be profiled, because what sense to get all data, only for search select options and also get another request with pagination
   */
  const fetchPatinetList = async (filterParams = null, sortBy, search) => {
    if (authUser.id) {
      try {
        setLoading(true);
        const params = {
          doctor_id: authUser.id,
          clinic_id: authUser.clinic_id,
          fitler: filterParams,
          limit,
          skip: search ? 0 : skip,
        };
        if (sortBy) {
          params.sortBy = sortBy;
        }
        if (search) {
          params.search = search;
        }
        let { patientList, total } = await getPatientList(params);
        setPatientList(patientList);
        setTotalPatients(total);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  /**
   * TODO this must to be profiled, have to be implemented more gracefully, because now I see the same code in multiple places
   */
  const loadMore = async () => {
    const nextSkip = skip + limit;
    setSkip(nextSkip);
    try {
      const params = {
        doctor_id: authUser.id,
        clinic_id: authUser.clinic_id,
        sortBy: sortby,
        limit,
        skip: nextSkip,
      };
      let { patientList: patients, total } = await getPatientList(params);
      if (patients.length > 0) {
        setPatientList((patientList) => [...patientList, ...patients]);
        setTotalPatients(total);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSkip(0);
    fetchPatinetList(null, sortby, search);
  }, [authUser, sortby, search]);

  /**
   * TODO this must to be profiled, because what sense to get all data, only for search select options and also get another request with pagination
   */
  useEffect(() => {
    if (authUser.id) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const params = {
            doctor_id: authUser.id,
            clinic_id: authUser.clinic_id,
          };
          let { patientList } = await getPatientList(params);
          const formattedPatientList = patientList.map((patient) => {
            return {
              label: `${patient.patient.first_name} ${
                patient.patient.last_name ? patient.patient.last_name : ''
              }`,
              value: `${patient.patient.id}`,
            };
          });
          setFormattedPatientList(formattedPatientList);

          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [authUser]);

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <DcotorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            ></div>
            <div className={styles.header}>
              <h1 data-testid="patientlist header">Patients</h1>
              <div className={styles.filters}>
                <div className={styles.searchfilter}>
                  <SquareDropdown
                    options={formattedPatientList}
                    isSearchable
                    emoji={<img src={zoom} alt="Search" />}
                    name="Search"
                    value={search}
                    onChange={(selectedValues) => {
                      setSearch(
                        selectedValues?.value ? selectedValues?.value : '',
                      );
                    }}
                    dataTestId="Patients.Search.Dropdown"
                    placeholder="Search"
                    noOptionsMessage="Not found"
                    dropDownIndicator={false}
                  />
                </div>
                <div className={styles.sortByfilter}>
                  <SquareDropdown
                    options={sortByOptions}
                    name="sortby"
                    value={sortby}
                    onChange={(selectedValues) => {
                      setSortBy(
                        selectedValues?.value
                          ? selectedValues?.value
                          : sortByOptions[0].value,
                      );
                    }}
                    dataTestId="Patients.Sort.Dropdown"
                    placeholder="Sort by"
                    dropDownIndicator={false}
                  />
                </div>
              </div>
            </div>
            {patientList.length ? (
              <Fragment>
                <div
                  className={styles.listContainer}
                  data-testid="patientLists"
                >
                  {patientList.map((patient, index) => (
                    <PatientList item={patient} key={index} />
                  ))}
                </div>
                {totalPatients > patientList.length && (
                  <div className={styles.buttons}>
                    <SecondaryButton
                      label={`Load More`}
                      className={styles.btn}
                      type="button"
                      onClick={loadMore}
                      data-testid="patientlist loadmore"
                    />
                  </div>
                )}
              </Fragment>
            ) : (
              <div className={styles.nodata} data-testid="no patient label">
                No Patient available
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default PatientPage;
