import { defineMessages } from 'react-intl';

export default defineMessages({
  myAccount: {
    id: 'component.nav.desktop.userPopup.myAccount',
    defaultMessage: 'My Account',
  },
  profile: {
    id: 'component.nav.desktop.userPopup.profile',
    defaultMessage: 'My Account',
  },
  doctorAccount: {
    id: 'component.nav.desktop.userPopup.doctorAccount',
    defaultMessage: 'Doctor Account',
  },
  signIn: {
    id: 'component.nav.desktop.userPopup.signIn',
    defaultMessage: 'Sign In',
  },
  signUp: {
    id: 'component.nav.desktop.userPopup.signUp',
    defaultMessage: 'Sign Up',
  },
  recommendations: {
    id: 'component.nav.desktop.userPopup.recommendations',
    defaultMessage: 'Recommendations',
  },
  hiThere: {
    id: 'component.nav.desktop.userPopup.hiThere',
    defaultMessage: 'Hi there!',
  },
  logout: {
    id: 'component.nav.desktop.userPopup.logout',
    defaultMessage: 'Log out',
  },
  userAlt: {
    id: 'component.nav.desktop.userPopup.userAlt',
    defaultMessage: 'User',
  },
});
