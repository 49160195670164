import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'react-foundation';
import styles from './TagsInput.module.scss';
import TextInput from '../TextInput';
import Label from '../../Label';

const TagsInput = ({
  label,
  items,
  push,
  remove,
  arrayName,
  placeholder,
  required,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [errorText, setErrorText] = useState('');
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (e.target.value.trim().length && !items.includes(e.target.value)) {
        push(e.target.value);
      }
      setInputValue('');
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (required) {
      if (items.length === 0) {
        setErrorText('Field is required');
      } else {
        setErrorText('');
      }
    }
  }, [items, required]);

  return (
    <React.Fragment>
      <Cell small={12} medium={12} className={styles['input-label']}>
        <Label errorText={errorText}>{label}</Label>
      </Cell>
      <Cell className={styles['input-field']}>
        <TextInput
          className={inputValue ? '' : styles['placeholder-right']}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={onKeyDown}
          name="tags-input"
          placeholder={placeholder}
          autoFocus={true}
        />
      </Cell>
      <Cell small={12} medium={12}>
        {items.length > 0 &&
          items.map((item, index) => (
            <div key={index} className={styles.tags}>
              <span key={index}>{item}</span>
              <a onClick={() => remove(index)}> x </a>
            </div>
          ))}
      </Cell>
    </React.Fragment>
  );
};

TagsInput.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.array,
  push: PropTypes.func,
  remove: PropTypes.func,
  arrayName: PropTypes.string,
  fields: PropTypes.array,
  required: PropTypes.bool,
};

export default TagsInput;
