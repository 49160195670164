import React from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import {
  withStyles,
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import './SliderTypeIcon.module.scss';

// TODO: temporarily not localized as it will move to Contentful
const defaultMarks = [
  {
    value: 1,
    label: 'Poor',
  },
  {
    value: 2,
    label: 'Fair',
  },
  {
    value: 3,
    label: 'Good',
  },
  {
    value: 4,
    label: 'Very Good',
  },
  {
    value: 5,
    label: 'Excellent',
  },
];

const valueText = (marks, value) => {
  const mark = marks.filter((m) => m.value === value);
  return mark[0].label;
};

const styles = (theme) => ({
  root: {
    'margin-bottom': '50px',
    [theme.breakpoints.down('sm')]: {
      'margin-bottom': '0px',
    },
  },
  rail: {
    height: '10px',
    'border-radius': '5px',
    'background-color': '#F5F5F5',
    opacity: 1,
    'margin-top': '30px',
  },
  mark: {
    background: 'none',
  },
  thumb: {
    width: '24px',
    height: '24px',
    marginTop: '-8px',
    marginLeft: '-10px',
    background: 'linear-gradient(90deg, #40C7E1 0%, #E387D3 100%)',
    'margin-top': '22px',
  },
  track: {
    background: '#46C5E1',
    height: '10px',
    display: 'block !important',
    'border-radius': '5px',
    'margin-top': '30px',
  },
  markLabel: {
    color: '#000000',
    'font-family': 'Montserrat',
    'font-size': '16px',
    'font-weight': 600,
    'letter-spacing': 0,
    'line-height': '22px',
    'white-space': 'normal',
    width: '180px',
    maxWidth: '200px',
    display: 'inline-block',
    top: '-40px',
    [theme.breakpoints.down('md')]: {
      top: '-30px',
      'font-size': '16px',
      'font-weight': 600,
      'letter-spacing': 0,
      'line-height': '18px',
    },
    [theme.breakpoints.down('sm')]: {
      top: '-30px',
      width: '75px',
      'white-space': 'normal',
      'font-size': '12px',
      'line-height': '18px',
    },
  },
});

const StyledSlider = withStyles(styles)(Slider);

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'none',
      },
    },
  },
});

const CustomSlider = ({
  onChange,
  value,
  marks,
  leftLabel,
  rightLabel,
  subtype,
  max,
  min,
}) => {
  function valueLabelComponent(props) {
    const { children, open } = props;
    const mark = marks.find((mark) => mark.value === value);
    let icon = null;

    if (subtype !== 'number') {
      icon = mark?.icon || require(`../../img/icons/slider/${value}.svg`);
    }

    return (
      <React.Fragment>
        <MuiThemeProvider theme={theme}>
          <Tooltip
            open={open}
            enterTouchDelay={0}
            placement="top"
            title={
              <React.Fragment>
                <div
                  style={{
                    height: '45px',
                    display: 'block',
                    marginBottom: '55px',
                  }}
                >
                  <img width="45px" height="45px" src={icon} alt={value} />
                </div>
              </React.Fragment>
            }
            PopperProps={{
              disablePortal: true,
              popperOptions: {
                modifiers: {},
              },
            }}
          >
            {children}
          </Tooltip>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }

  const sideLabelStyles = {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 'bold',
    minWidth: '100px',
    margin: '30px 10px',
  };

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return subtype === 'number' ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: mobile ? '0 -120px' : '0 -150px',
      }}
    >
      <div style={sideLabelStyles}>{leftLabel}</div>
      <StyledSlider
        aria-labelledby="slider-icon"
        getAriaValueText={(value) => valueText(marks, value)}
        min={min}
        max={max}
        marks={marks}
        onChange={onChange}
        value={value}
      />
      <div style={sideLabelStyles}>{rightLabel}</div>
    </div>
  ) : (
    <StyledSlider
      aria-labelledby="slider-icon"
      getAriaValueText={(value) => valueText(marks, value)}
      min={min}
      max={max}
      marks={marks}
      step={null}
      track={false}
      onChange={onChange}
      value={value}
      ValueLabelComponent={valueLabelComponent}
      valueLabelDisplay="on"
    />
  );
};

CustomSlider.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marks: PropTypes.arrayOf(PropTypes.any),
};

CustomSlider.defaultProps = {
  marks: defaultMarks,
};

export default CustomSlider;
