import React, { useState, Fragment, useContext, useEffect } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Loading from '../../../components/common/Loading';
import LeftNav from '../../../components/nav/LeftNav';
import styles from './MyAppointmentsPage.module.scss';
import AuthContext from '../../../context/AuthContext';
import Appointment from '../../../components/Appointment';
import SuperBillModal from '../../../components/common/SuperBillModal';

import { getPatientAppointments } from '../../../api/user';
import { requestSuperBill } from '../../../api/appointment';

const tabs = ['upcoming', 'past'];
const MyAccountIntakeFormsPage = () => {
  const [requestedSuperBillItem, setRequestedSuperBillItem] = useState(null);
  const { isLoading, authUser } = useContext(AuthContext);
  const [dataLoading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [displayMessage, setDisplayMessage] = useState({
    title: '',
    message: '',
  });
  const isDataLoading = dataLoading || isLoading;
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const history = useHistory();

  const fetchAppointments = async (filterParams) => {
    setLoading(true);
    if (authUser.id) {
      try {
        const { appointments: patientAppoinements } =
          await getPatientAppointments({
            patient_id: authUser.id,
            filter: filterParams,
          });
        setAppointments(patientAppoinements);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleOnSubmit = async (resendRequest) => {
    if (!requestedSuperBillItem) {
      return null;
    }

    let response;

    if (!requestedSuperBillItem?.superbill?.requested_count || resendRequest) {
      setLoading(true);
      response = await requestSuperBill(requestedSuperBillItem.id);
    }

    setLoading(false);
    if (response && response.type && response.type === 'error') {
      setDisplayMessage({
        type: 'error',
        title: '',
        text: 'Failed to request a SuperBill',
        requestLabel: 'Ok',
      });
    } else if (response) {
      setDisplayMessage({
        type: 'success',
        title: 'Request Sent',
        text: 'A SuperBill will be requested.',
        requestLabel: 'Ok',
      });
    } else {
      setDisplayMessage({
        type: 'alert',
        title: 'Request again?',
        text: `A superbill for this appointment was requested on ${moment(
          requestedSuperBillItem.superbill.requested_at,
        ).format('MMMM DD, YYYY')}.`,
        requestLabel: 'Ok',
        closeLabel: 'Cancel',
      });
    }
  };

  useEffect(() => {
    if (requestedSuperBillItem) {
      handleOnSubmit();
    } else {
      fetchAppointments(activeTab);
    }
  }, [authUser, requestedSuperBillItem]);

  const onclickTab = (tab) => {
    setActiveTab(tab);
    fetchAppointments(tab);
  };

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <LeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            />
            <h1 data-testid="MyAppointmentsPage.Patient.HeaderTitle">
              Appointments
            </h1>
            <div>
              <nav className={styles.tabs}>
                {tabs.map((tab) => (
                  <button
                    key={tab}
                    className={[
                      styles.tab,
                      activeTab === tab ? styles.activeTab : '',
                    ].join(' ')}
                    type="button"
                    onClick={() => onclickTab(tab)}
                  >
                    <span data-testid={tab}>{tab}</span>
                    <div></div>
                  </button>
                ))}
              </nav>
              {appointments.length === 0 ? (
                <div
                  className={styles.nodata}
                  data-testid="no appointment available text"
                >
                  No appointments available
                </div>
              ) : (
                <>
                  {appointments.map((item, Index) => (
                    <Appointment
                      setRequestedSuperBillItem={setRequestedSuperBillItem}
                      item={item}
                      key={Index}
                      history={history}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
          {requestedSuperBillItem && (
            <SuperBillModal
              message={displayMessage}
              onCloseModal={() => setRequestedSuperBillItem(null)}
              onRequest={handleOnSubmit}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default MyAccountIntakeFormsPage;
