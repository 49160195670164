import React from 'react';
import { Cell } from 'react-foundation';
import useIntl from '../../../../../hooks/useIntl';
import RadioGroupWithLabel from '../../../../common/inputs/RadioGroupWithLabel';
import TextAreaInputWithLabel from '../../../../common/inputs/TextAreaInputWithLabel';
import FieldInput from '../../../../common/fields/FieldInput';
import messages from '../messages';

const FormStep = ({
  item,
  stepName,
  values,
  handleChange,
  setFieldValue,
  handleCheckboxChange,
  handleBlur,
}) => {
  const { formatMessage } = useIntl();
  const label = messages[item.label] ? formatMessage(messages[item.label]) : '';

  return (
    <Cell small={12}>
      {item.type === 'textarea' && (
        <TextAreaInputWithLabel
          label={label}
          name={item.id}
          value={values[item.id]}
          onChange={handleChange}
          placeholder="Enter here"
          onBlur={handleBlur}
          autoFocus={true}
          data-testid={`${stepName}.${item.id}.Textarea`}
          rows={2}
        />
      )}
      {item.type === 'radio' && (
        <>
          <RadioGroupWithLabel
            label={label}
            componentName={item.id}
            name={item.id}
            options={item.options}
            dataTestId={`${stepName}.${item.id}.RadioGroup`}
            labelTestId={`${stepName}.${item.id}.Label`}
            value={values[item.id]}
            onChange={(value) => setFieldValue(item.id, value)}
          />
        </>
      )}
      {item.type === 'textInput' && (
        <FieldInput
          label={label}
          placeholder="Enter here"
          name={item.id}
          value={values[item.id]}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid={`${stepName}.${item.id}.Input`}
        />
      )}
      {item.type === 'checkbox' && (
        <RadioGroupWithLabel
          type="checkbox"
          label={item.label ? formatMessage(messages[item.label]) : ''}
          componentName={item.id}
          name={item.id}
          options={item.options || []}
          data-testid={`${stepName}.${item.id}.Radio`}
          labelTestId={`${stepName}.${item.id}.RadioLabel`}
          value={values[item.id]}
          onChange={(value) => {
            const arrOfItems = handleCheckboxChange(values[item.id], value);
            setFieldValue(item.id, arrOfItems);
          }}
        />
      )}
      {item.freeText && values[item.id] === item.freeText.triggerValue && (
        <FieldInput
          label={
            item.freeText.label
              ? formatMessage(messages[item.freeText.label])
              : ''
          }
          placeholder="Enter here"
          name={item.freeText.id}
          value={values[item.freeText.id]}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid={`${stepName}.${item.freeText.id}.Input`}
        />
      )}
    </Cell>
  );
};

FormStep.propTypes = {};

FormStep.defaultProps = {};

export default FormStep;
