import { defineMessages } from 'react-intl';

export default defineMessages({
  formTitle: {
    id: 'components.BPSForm.formTitle',
    defaultMessage: 'NEST Harm Reduction',
  },
  formInfo: {
    id: 'components.BPSForm.formInfo',
    defaultMessage: 'Biopsychosocial Assessment',
  },
  formSubtitle: {
    id: 'components.BPSForm.formSubtitle',
    defaultMessage: 'Biopsychosocial Assessment',
  },
  formReference: {
    id: 'components.BPSForm.formReference',
    defaultMessage: 'Who referred you to NEST Harm Reduction?',
  },
  otherInput: {
    id: 'components.BPSForm.formReference',
    defaultMessage: 'Who referred you to NEST Harm Reduction?',
  },
  medicalHistory: {
    id: 'component.BPSForm.medicalHistory',
    defaultMessage: 'Medical History',
    primaryCarePhysician: {
      id: 'component.BPSForm.medicalHistory.primaryCarePhysician',
      defaultMessage: 'Primary Care Physician',
      physicianName: {
        id: 'component.BPSForm.medicalHistory.physicianName',
        defaultMessage: "Physician's Name",
      },
      physicianPhone: {
        id: 'component.BPSForm.medicalHistory.physicianPhone',
        defaultMessage: 'Phone Number',
      },
      healthCareProviders: {
        id: 'component.BPSForm.medicalHistory.healthCareProviders',
        defaultMessage:
          'Are there any other healthcare providers you are currently working with?',
      },
      providersName: {
        id: 'component.BPSForm.medicalHistory.healthCareProviders.providersName',
        defaultMessage: 'Name',
      },
      providersSpecialty: {
        id: 'component.BPSForm.medicalHistory.healthCareProviders.providersSpecialty',
        defaultMessage: 'Specialty',
      },
      providersPhoneNumber: {
        id: 'component.BPSForm.medicalHistory.healthCareProviders.providersPhoneNumber',
        defaultMessage: 'Phone Number',
      },
      contactProviders: {
        id: 'component.BPSForm.medicalHistory.contactProviders',
        defaultMessage:
          'Do you want your NEST provider to contact and coordinate care with any other providers?',
      },
      medicallyHospitalized: {
        id: 'component.BPSForm.medicalHistory.medicalHospitalized',
        defaultMessage:
          'Have you been medically hospitalized in the last 30 days?',
      },
      physicalExam: {
        id: 'component.BPSForm.medicalHistory.physicalExam',
        defaultMessage: 'When was your last physical exam?',
      },
      headInjury: {
        id: 'component.BPSForm.medicalHistory.headInjury',
        defaultMessage:
          'Have you had a head injury since your last physical exam?',
      },
      chestPain: {
        id: 'component.BPSForm.medicalHistory.chestPain',
        defaultMessage:
          'In the past week, have you experienced any chest pain?',
      },
      personalHistory: {
        id: 'component.BPSForm.medicalHistory.personalHistory',
        defaultMessage:
          'Do you have a personal history of any of the following (check all that apply):',
      },
      familyDiagnoses: {
        id: 'component.BPSForm.medicalHistory.headInjury',
        defaultMessage:
          'Has anyone in the family ever been diagnosed with any of the following (check all that apply):',
      },
    },
  },
  currentWellbeing: {
    id: 'component.BPSForm.currentWellbeing',
    defaultMessage: 'Current Mental Well-Being',
    visitReason: {
      id: 'component.BPSForm.currentWellbeing.visitReason',
      defaultMessage:
        'What is the reason for your upcoming visit? What would you identify as your primary areas of concern?',
    },
    depressionSymptoms: {
      id: 'component.BPSForm.currentWellbeing.depressionSymptoms',
      defaultMessage:
        'Please select all current and/or recurring symptoms of DEPRESSION you are experiencing. Please check all that apply.',
    },
    symptomsPeriod: {
      id: 'component.BPSForm.currentWellbeing.depressionSymptomsPeriod',
      defaultMessage:
        'How long have you been experiencing ANY of the above symptoms? (If Any)',
    },
    symptomsCause: {
      id: 'component.BPSForm.currentWellbeing.symptomsCause',
      defaultMessage:
        'Do the symptoms you are experiencing result in distress and/or impairment at work, socially, or in other areas of your life?',
    },
    affirmativeCause: {
      id: 'component.BPSForm.currentWellbeing.affirmativeCause',
      defaultMessage: 'If YES, please provide a brief description.',
    },
    anxietySymptoms: {
      id: 'component.BPSForm.currentWellbeing.anxietySymptoms',
      defaultMessage:
        'Please select all current and/or recurring symptoms of ANXIETY you are experiencing. Please check all that apply. ',
    },
    panicAttacks: {
      id: 'component.BPSForm.currentWellbeing.panicAttacks',
      defaultMessage:
        'If you have been experiencing panic attacks, how often have they occurred?',
    },
    otherSymptom: {
      id: 'component.BPSForm.currentWellbeing.otherAnxietySymptom',
      defaultMessage: 'If other, please describe',
    },
    stressSymptoms: {
      id: 'component.BPSForm.currentWellbeing.stressSymptoms',
      defaultMessage:
        'Please select all current and/or recurring symptoms of STRESS or TRAUMA RESPONSE you are experiencing. Please check all that apply.',
    },
    traumaticEvents: {
      id: 'component.BPSForm.currentWellbeing.traumaticEvents',
      defaultMessage:
        'History of significant traumatic event(s), including being in or witnessing events such as vehicle accidents, natural disasters, abuse, war, bullying.',
    },
    affirmativeTraumaticEvent: {
      id: 'component.BPSForm.currentWellbeing.affirmativeTraumaticEvent',
      defaultMessage:
        'If YES, please indicate the type of event(s) and how old you were when each occurred',
    },
    maniaSymptoms: {
      id: 'component.BPSForm.currentWellbeing.maniaSymptoms',
      defaultMessage:
        'Please select all current and/or recurring symptoms of MANIA you are experiencing. Please check all that apply.',
    },
    ocdSymptoms: {
      id: 'component.BPSForm.currentWellbeing.ocdSymptoms',
      defaultMessage:
        'Please select all current and/or recurring symptoms of Obsessive Compulsive Disorder (OCD) you are experiencing. Please check all that apply.',
    },
    behaviorConcernsSymptoms: {
      id: 'component.BPSForm.currentWellbeing.behaviorConcernsSymptoms',
      defaultMessage:
        'Please select all current and/or recurring symptoms of BEHAVIOR CONCERNS you are experiencing. Please check all that apply.',
    },
    attentionalIssuesSymptoms: {
      id: 'component.BPSForm.currentWellbeing.attentionalIssuesSymptoms',
      defaultMessage:
        'Please select all current and/or recurring symptoms of ATTENTIONAL ISSUES you are experiencing. Please check all that apply.',
    },
    eatingProblemsSymptoms: {
      id: 'component.BPSForm.currentWellbeing.eatingProblemsSymptoms',
      defaultMessage:
        'Please select all current and/or recurring symptoms of EATING PROBLEMS you are experiencing. Please check all that apply.',
    },
    psychosisSymptoms: {
      id: 'component.BPSForm.currentWellbeing.psycosisSymptoms',
      defaultMessage:
        'Please select all current and/or recurring symptoms of PSYCHOSIS you are experiencing. Please check all that apply.',
    },
  },
  substanceUse: {
    id: 'component.BPSForm.substanceUse',
    defaultMessage: 'Substance Use',
    substanceSectionDescription: {
      id: 'component.BPSForm.substanceUse.substanceSectionDescription',
      defaultMessage:
        'Please be open and honest, substance use is addressed from a harm reduction approach and NEST providers do not require sobriety/abstinence',
    },
    substancesHistory: {
      id: 'component.BPSForm.substanceUse.substancesHistory',
      defaultMessage:
        'Do you have a history of abusing or overusing substances, such as alcohol, medications, or drugs? ',
    },
    drinkingOrDrugUse: {
      id: 'component.BPSForm.substanceUse.drinkingOrDrugUse',
      defaultMessage:
        'Have you ever felt that you ought to cut down on your drinking or drug use?',
    },
    useCriticizing: {
      id: 'component.BPSForm.substanceUse.useCriticizing',
      defaultMessage: 'Have people annoyed you by criticizing your use?',
    },
    feltBad: {
      id: 'component.BPSForm.substanceUse.feltBad',
      defaultMessage: 'Have you ever felt bad or guilty about your use?',
    },
    hangover: {
      id: 'component.BPSForm.substanceUse.chestPain',
      defaultMessage:
        'Have you ever had to use first thing in the morning to steady your nerves or to get rid of a hangover?',
    },
  },
  pastTreatment: {
    id: 'component.BPSForm.pastTreatment',
    defaultMessage: 'Past Mental Health Treatment',
    psychiatricTreatment: {
      id: 'component.BPSForm.pastTreatment.psychiatricTreatment',
      defaultMessage:
        'Have you had any past therapy or psychiatric treatment (including childhood)? Check all that apply.',
    },
    pastFacility: {
      id: 'component.BPSForm.pastTreatment.pastProvider',
      defaultMessage:
        'Please list any past provider/facility name(s), the month/year of treatment, length of treatment, and issues addressed:',
    },
    previousMentalConditions: {
      id: 'component.BPSForm.pastTreatment.previousMentalConditions',
      defaultMessage:
        'Have you previously been diagnosed with any mental health conditions?',
    },
    previousMedications: {
      id: 'component.BPSForm.pastTreatment.previousMedications',
      defaultMessage:
        'Please list any previous medications for mental health concerns or diagnosis (i.e. depression, anxiety, attention, etc.):',
    },
    familyMentalConditions: {
      id: 'component.BPSForm.pastTreatment.familyMentalConditions',
      defaultMessage:
        'Has any of your family previously been diagnosed with any of the following mental health conditions?',
    },
    facilityName: {
      id: 'component.BPSForm.pastTreatment.facilityName',
      defaultMessage: 'Provider/Facility Name',
    },
    treatmentDate: {
      id: 'component.BPSForm.pastTreatment.treatmentDate',
      defaultMessage: 'Treatment Date',
    },
    treatmentLength: {
      id: 'component.BPSForm.pastTreatment.treatmentLength',
      defaultMessage: 'Length of Treatment',
    },
    issuesAddressed: {
      id: 'component.BPSForm.pastTreatment.issuesAddressed',
      defaultMessage: 'Issues Addressed',
    },
    familySuicide: {
      id: 'component.BPSForm.pastTreatment.familySuicide',
      defaultMessage: 'Has anyone in your family attempted/completed suicide?',
    },
    yourFamilyRelationship: {
      id: 'component.BPSForm.pastTreatment.yourFamilyRelationship',
      defaultMessage: 'If YES, please describe their relationship to you.',
    },
  },
  familyAndSocialHistory: {
    id: 'component.BPSForm.familyAndSocialHistory',
    defaultMessage: 'Family and Social History',
    sexAtBirth: {
      id: 'component.BPSForm.familyAndSocialHistory.sexAtBirth',
      defaultMessage: 'Sex Assigned at Birth',
    },
    gender: {
      id: 'component.BPSForm.familyAndSocialHistory.gender',
      defaultMessage: 'Gender Identity',
    },
    preferredGender: {
      id: 'component.BPSForm.familyAndSocialHistory.preferredGender',
      defaultMessage:
        'If non-conforming or other, please indicate your preferred gender term.',
    },
    sexualOrientation: {
      id: 'component.BPSForm.familyAndSocialHistory.sexualOrientation',
      defaultMessage: 'Sexual Orientation',
    },
    otherIdentifyLabel: {
      id: 'component.BPSForm.familyAndSocialHistory.otherIdentifyLabel',
      defaultMessage: 'If other, please identify:',
    },
    relationshipStatus: {
      id: 'component.BPSForm.familyAndSocialHistory.relationshipStatus',
      defaultMessage: 'Relationship Status',
    },
    currentRelationship: {
      id: 'component.BPSForm.familyAndSocialHistory.currentRelationship',
      defaultMessage: 'If currently in a relationship, please indicate',
    },
    nrOfChildren: {
      id: 'component.BPSForm.familyAndSocialHistory.nrOfChildren',
      defaultMessage: 'Number of children',
    },
    nrOfPregnancies: {
      id: 'component.BPSForm.familyAndSocialHistory.nrOfPregnancies',
      defaultMessage: 'Number of pregnancies',
    },
    nrOfAdoptions: {
      id: 'component.BPSForm.familyAndSocialHistory.nrOfAdoptions',
      defaultMessage: 'Number of adoptions',
    },
    resideWith: {
      id: 'component.BPSForm.familyAndSocialHistory.resideWith',
      defaultMessage: 'Who do you reside with?',
    },
    otherWriteInLabel: {
      id: 'component.BPSForm.familyAndSocialHistory.otherWriteInLabel',
      defaultMessage: 'If other, please write-in',
    },
    otherDescribeLabel: {
      id: 'component.BPSForm.familyAndSocialHistory.otherDescribeLabel',
      defaultMessage: 'If other, please write-in',
    },
    employmentStatus: {
      id: 'component.BPSForm.familyAndSocialHistory.employmentStatus',
      defaultMessage: 'What is your current employment status?',
    },
    sourceOfIncome: {
      id: 'component.BPSForm.familyAndSocialHistory.sourceOfIncome',
      defaultMessage: 'What is your primary source of income?',
    },
    emotionalSupport: {
      id: 'component.BPSForm.familyAndSocialHistory.emotionalSupport',
      defaultMessage: 'Who is your source of emotional support?',
    },
    developmentalMilestones: {
      id: 'component.BPSForm.familyAndSocialHistory.developmentalMilestones',
      defaultMessage:
        'Did you meet all developmental milestones (such as crawling, walking, talking, fine motor) within age appropriate limits?',
    },
    developmentalDelays: {
      id: 'component.BPSForm.familyAndSocialHistory.developmentalDelays',
      defaultMessage:
        'If no, please explain (developmental delays or learning disabilities requiring interventions such as physical, occupational or speech therapy, special education)',
    },
    familyRelationship: {
      id: 'component.BPSForm.familyAndSocialHistory.familyRelationship',
      defaultMessage:
        'Describe how your family gets along and relates to one another (currently and in the past)',
    },
  },
  legalHistory: {
    id: 'component.BPSForm.legalHistory',
    defaultMessage: 'Legal History',
    legalInvolvement: {
      id: 'component.BPSForm.legalHistory.legalInvolvement',
      defaultMessage:
        'Do you have a history of legal involvement? (Arrested or incarcerated for any reason?)',
    },
    legalStatus: {
      id: 'component.BPSForm.legalHistory.legalStatus',
      defaultMessage: 'Current legal status',
    },
    mentalHealthTreatment: {
      id: 'component.BPSForm.legalHistory.mentalHealthTreatment',
      defaultMessage:
        'Are you court-ordered to receive mental health treatment?',
    },
    otherListLabel: {
      id: 'component.BPSForm.legalHistory.otherListLabel',
      defaultMessage: 'If yes, please list details',
    },
  },
  culturalHistory: {
    id: 'component.BPSForm.culturalHistory',
    defaultMessage: 'Cultural History and Preferences',
    religionRole: {
      id: 'component.BPSForm.culturalHistory.religionRole',
      defaultMessage:
        'Has religion or spirituality played an important role in your life?',
    },
    religionPractices: {
      id: 'component.BPSForm.culturalHistory.religionPractices',
      defaultMessage:
        'If yes, please describe practices you engage in to express your beliefs (i.e. attending formal services, prayer, meditation, chanting, ceremonies, rituals, etc.)',
    },
    racialIdentity: {
      id: 'component.BPSForm.culturalHistory.racialIdentity',
      defaultMessage:
        'How would you describe your racial identity and/or ethnic background?',
    },
    specificCulture: {
      id: 'component.BPSForm.culturalHistory.specificCulture',
      defaultMessage:
        'Were you raised in a specific culture that you feel may influence your recovery?',
    },
    hobbies: {
      id: 'component.BPSForm.culturalHistory.hobbies',
      defaultMessage:
        'List any hobbies, recreational interests, sports, games, or other leisure activities that you enjoy',
    },
    mentalHealthResult: {
      id: 'component.BPSForm.culturalHistory.mentalHealthResult',
      defaultMessage:
        'Has there been any recent changes in your interests and activities as a result of your mental health and/or substance use issues?',
    },
  },
  assessment: {
    id: 'component.BPSForm.assessment',
    defaultMessage: 'Assessment',
    therapyGoals: {
      id: 'component.BPSForm.assessment.therapyGoals',
      defaultMessage:
        'What are your goals in therapy? How will you know therapy has been successful/what will have changed?',
    },
    possibleBarriers: {
      id: 'component.BPSForm.assessment.possibleBarriers',
      defaultMessage:
        'What do you foresee as possible barriers for making change in your life?',
    },
    therapyNeeds: {
      id: 'component.BPSForm.assessment.therapyNeeds',
      defaultMessage:
        'What are your needs/preferences in therapy, such as cultural, religious or therapeutic needs and preferences?',
    },
    currentStrengths: {
      id: 'component.BPSForm.assessment.specificCulture',
      defaultMessage: 'What are your current strengths?',
    },
    therapistAdvice: {
      id: 'component.BPSForm.assessment.therapistAdvice',
      defaultMessage:
        'What else would be helpful for the therapist to know about to partner with you towards these goals?',
    },
  },
});
