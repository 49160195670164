import { defineMessages } from 'react-intl';

export default defineMessages({
  formTitle: {
    id: 'components.KAPIntakeForm21.formTitle',
    description: 'Title of the Form',
    defaultMessage: 'Client Information',
  },
  formInfo: {
    id: 'components.KAPIntakeForm21.formInfo',
    description: 'Information of the Form',
    defaultMessage:
      'Welcome to our practice. We want to make the most of each appointment you have with us. One way of doing this is for you to write down some basic information in advance of your first appointment. Please fill out the following as completely as possible. This information is confidential.',
  },
  formSubTitle: {
    id: 'components.KAPIntakeForm21.formSubTitle',
    description: 'Sub Title of the Form',
    defaultMessage: 'Fill in your details',
  },

  personalDetails: {
    nameLabel: {
      id: 'component.KAPIntakeForm21.personalDetails.nameLabel',
      description: 'The label for name',
      defaultMessage: 'Name',
    },
    preferredName: {
      id: 'component.KAPIntakeForm21.personalDetails.preferredName',
      description: 'The label for preferred name',
      defaultMessage: 'Preferred name',
    },
    age: {
      id: 'component.KAPIntakeForm21.personalDetails.age',
      description: 'The label for age',
      defaultMessage: 'Age',
    },
    dob: {
      id: 'component.KAPIntakeForm21.personalDetails.preferredName',
      description: 'The label for dob',
      defaultMessage: 'Date of birth',
    },
    phoneNumber: {
      id: 'component.KAPIntakeForm21.personalDetails.phoneNumber',
      description: 'The label for phoneNumber',
      defaultMessage: 'Phone Number',
    },
    email: {
      id: 'component.KAPIntakeForm21.personalDetails.email',
      description: 'The label for email',
      defaultMessage: 'Email',
    },
  },

  emergencyContact: {
    id: 'component.KAPIntakeForm21.emergencyContact',
    description: 'Emergency Contact',
    defaultMessage: 'Emergency Contact',
    name: {
      id: 'component.KAPIntakeForm21.emergencyContact.name',
      description: 'The label for name',
      defaultMessage: 'Name',
    },
    phoneNumber: {
      id: 'component.KAPIntakeForm21.emergencyContact.phoneNumber',
      description: 'The label for phoneNumber',
      defaultMessage: 'Phone Number',
    },
  },
  weight: {
    id: 'component.KAPIntakeForm21.weight',
    description: 'The label for weight',
    defaultMessage: 'Weight',
  },
  medicalSafetyScreening: {
    id: 'component.KAPIntakeForm21.medicalSafetyScreening',
    description: 'The label for Medical Safety Screening',
    defaultMessage: 'Medical Safety Screening',
  },
  medicalIssue: {
    id: 'component.KAPIntakeForm21.medicalIssue',
    description: 'Medical issues',
    defaultMessage: 'Please list any current medical issues',
  },
  currentMedications: {
    id: 'component.KAPIntakeForm21.currentMedications',
    description: 'Please list any current medications/supplements',
    defaultMessage: 'Please list any current medications/supplements',
  },
  medicalHistory: {
    id: 'component.KAPIntakeForm21.medicalHistory',
    description: 'Medical history',
    defaultMessage:
      'Any significant past medical history? Please include dates to the best of your ability',
  },
  psychiatricHistory: {
    id: 'component.KAPIntakeForm21.psychiatricHistory',
    description: 'Psychiatric history',
    defaultMessage:
      'Any past/current psychiatric history? Please include dates to the best of your ability',
  },
  medicationAllergies: {
    id: 'component.KAPIntakeForm21.medicationAllergies',
    description: 'Medication allergies',
    defaultMessage:
      'Please list any MEDICATION allergies you have. If none, please put NA',
  },
  foodAllergies: {
    id: 'component.KAPIntakeForm21.foodAllergies',
    description: 'Food allergies',
    defaultMessage:
      'Please list any FOOD allergies you have. If none, please put NA',
  },
  concernsForTreatment: {
    id: 'component.KAPIntakeForm21.concernsForTreatment',
    description:
      'medical, medication or personal concerns about this treatment',
    defaultMessage:
      'Any medical, medication or personal concerns about this treatment?',
  },
  historyExplanation: {
    id: 'component.KAPIntakeForm21.historyExplanation',
    description: 'Please explain any positive answers above:',
    defaultMessage: 'Please explain any positive answers above',
  },
});
