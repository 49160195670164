import { PDFDownloadLink } from '@react-pdf/renderer';
import classnames from 'classnames';
import moment from 'moment';
import React, { Fragment } from 'react';
import useIntl from '../../../../hooks/useIntl';
import { displayDateFormat } from '../../../../utils/common';
import PersonalInfoPDF from '../../../AssessmentPDF/PersonalInfoPDF';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import Modal from '../../../common/Modal';
import styles from '../../../FormListView/FormListView.module.scss';
import { alcoholAndDrugsOptions } from './data';
import messages from './messages';

const PatientInformationResponse = ({
  onClick,
  item,
  personalInfo,
  showPatientDetailsModal,
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {showPatientDetailsModal && (
        <Modal className={styles.modal}>
          <div className={styles.content}>
            <div className={styles.closeButton} onClick={onClick}>
              <img src={require(`../../../../img/close.svg`)} alt={`Close`} />
            </div>
            <h1>
              <b>{item.form_name}</b>
            </h1>
            <div className={styles.box}>
              <div className={styles['content-detail']}>
                <div className={classnames([styles.headerSection])}>
                  <div>
                    <p className={styles.label}>Client Name:</p>
                    <p>
                      {`${personalInfo.patient?.first_name || ''} ${
                        personalInfo.patient?.last_name || ''
                      }`}
                    </p>
                  </div>
                  <div>
                    <p className={styles.label}>Assessment:</p>
                    <p>{item.form_name || ''}</p>
                  </div>
                  <div>
                    <p className={styles.label}>Date Completed:</p>
                    <p>{moment(item.updatedAt).format('MMMM DD, YYYY')}</p>
                  </div>
                  <PDFDownloadLink
                    document={
                      <PersonalInfoPDF
                        item={item}
                        formDetail={personalInfo.patient}
                      />
                    }
                    fileName={`${item.form_name || 'form'}.pdf`}
                  >
                    <PrimaryButton
                      label={`Download PDF`}
                      type="button"
                      className={styles.btn}
                    />
                  </PDFDownloadLink>
                </div>
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles['content-detail']}>
                {personalInfo.patient && personalInfo.patient.id ? (
                  <Fragment>
                    <section className={styles['content-detail-section']}>
                      <h2>{formatMessage(messages.personalDetails)}:</h2>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.personalDetails.firstNameLabel,
                            )}
                            :
                          </span>
                          <p>{personalInfo.patient.first_name}</p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.personalDetails.lastNameLabel,
                            )}
                            :
                          </span>
                          <p>{personalInfo.patient.last_name}</p>
                        </div>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>
                          {formatMessage(messages.personalDetails.allergies)}:
                        </span>
                        <p>{personalInfo.patient.details.allergies}</p>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(messages.personalDetails.gender)}:
                          </span>
                          <p>{personalInfo.patient.details.gender}</p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(messages.personalDetails.pronouns)}:
                          </span>
                          <p>
                            {personalInfo.patient.details.preferred_pronouns}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.personalDetails.relationshipStatus,
                            )}
                            :
                          </span>
                          <p>
                            {personalInfo.patient.details.relationship_status}
                          </p>
                        </div>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>
                          {formatMessage(messages.personalDetails.dateOfBirth)}:
                        </span>
                        <p>
                          {displayDateFormat(personalInfo.patient.details.dob)}
                        </p>
                      </div>
                    </section>

                    <section className={styles['content-detail-section']}>
                      <h2>{formatMessage(messages.contactInformation)}:</h2>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.contactInformation.streetAddress,
                            )}
                            :
                          </span>
                          <p>{personalInfo.patient.details.street_address}</p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.contactInformation.cityLabel,
                            )}
                            :
                          </span>
                          <p>{personalInfo.patient.details.city}</p>
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.contactInformation.stateLabel,
                            )}
                            :
                          </span>
                          <p>{personalInfo.patient.details.state}</p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.contactInformation.zipCodeLabel,
                            )}
                            :
                          </span>
                          <p>{personalInfo.patient.details.zip}</p>
                        </div>
                      </div>

                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.contactInformation.homePhoneLabel,
                            )}
                            :
                          </span>
                          <p>{personalInfo.patient.details.home_phone}</p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.contactInformation.cellPhoneLabel,
                            )}
                            :
                          </span>
                          <p>{personalInfo.patient.phone}</p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.contactInformation.workPhoneLabel,
                            )}
                            :
                          </span>
                          <p>{personalInfo.patient.details.work_phone}</p>
                        </div>
                      </div>
                      <div className={styles.text}>
                        <span>
                          {formatMessage(
                            messages.contactInformation.emailLabel,
                          )}
                          :
                        </span>
                        <p>{personalInfo.patient.email}</p>
                      </div>
                      <div className={styles.text}>
                        <span>
                          {formatMessage(
                            messages.contactInformation.contactMethods,
                          )}
                        </span>
                        <p>
                          {personalInfo?.patient?.details.contact_method?.join(
                            ', ',
                          )}
                        </p>
                      </div>
                    </section>

                    <section className={styles['content-detail-section']}>
                      <h2>
                        {formatMessage(messages.emergencyContactInformation)}:
                      </h2>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.emergencyContactInformation.contactName,
                            )}
                            :
                          </span>
                          <p>
                            {
                              personalInfo.patient.details
                                .emergency_contact_name
                            }
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.emergencyContactInformation
                                .contactRelationship,
                            )}
                            :
                          </span>
                          <p>
                            {
                              personalInfo.patient.details
                                .emergency_contact_relationship
                            }
                          </p>
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.emergencyContactInformation.contactPhone,
                            )}
                            :
                          </span>
                          <p>
                            {
                              personalInfo.patient.details
                                .emergency_contact_phone
                            }
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.emergencyContactInformation.contactEmail,
                            )}
                            :
                          </span>
                          <p>
                            {
                              personalInfo.patient.details
                                .emergency_contact_email
                            }
                          </p>
                        </div>
                      </div>
                    </section>

                    <section className={styles['content-detail-section']}>
                      <h2>{formatMessage(messages.insuranceInformation)}:</h2>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.insuranceInformation.SSNLabel,
                            )}
                            :
                          </span>
                          <p>{personalInfo.patient.details.ssn}</p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.insuranceInformation
                                .insuranceProviderLabel,
                            )}
                            :
                          </span>
                          <p>
                            {personalInfo.patient.details.insurance_provider}
                          </p>
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.insuranceInformation.insuranceIdLabel,
                            )}
                            :
                          </span>
                          <p>{personalInfo.patient.details.insurance_id}</p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.insuranceInformation.groupIdLabel,
                            )}
                            :
                          </span>
                          <p>{personalInfo.patient.details.group_id}</p>
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.insuranceInformation.insuredNameLabel,
                            )}
                            :
                          </span>
                          <p>{personalInfo.patient.details.name_insured}</p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.insuranceInformation
                                .primaryDoctorNameLabel,
                            )}
                            :
                          </span>
                          <p>
                            {personalInfo.patient.details.primary_care_doctor}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.insuranceInformation.doctorsContactLabel,
                            )}
                            :
                          </span>
                          <p>
                            {
                              personalInfo.patient.details
                                .primary_care_doctor_phone
                            }
                          </p>
                        </div>
                      </div>
                      <div className={classnames('text', styles.column3)}>
                        <span>
                          {formatMessage(
                            messages.insuranceInformation
                              .preferredPharmacyLabel,
                          )}
                          :
                        </span>
                        <p>
                          {
                            personalInfo.patient.details
                              .preferred_pharmacy_address
                          }
                        </p>
                      </div>
                    </section>
                    <section className={styles['content-detail-section']}>
                      <h2>{formatMessage(messages.paymentInformation)}:</h2>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.contactInformation.streetAddress,
                            )}
                            :
                          </span>
                          <p>
                            {
                              personalInfo.patient.details
                                .billing_street_address
                            }
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.contactInformation.cityLabel,
                            )}
                            :
                          </span>
                          <p>{personalInfo.patient.details.billing_city}</p>
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.contactInformation.stateLabel,
                            )}
                            :
                          </span>
                          <p>{personalInfo.patient.details.billing_state}</p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            {formatMessage(
                              messages.contactInformation.zipCodeLabel,
                            )}
                            :
                          </span>
                          <p>{personalInfo.patient.details.billing_zip}</p>
                        </div>
                      </div>
                    </section>

                    {personalInfo.patient.details.current_medications &&
                      personalInfo.patient.details.current_medications.length >
                        0 && (
                        <section className={styles['content-detail-section']}>
                          <h2>{formatMessage(messages.currentMedications)}:</h2>
                          <div className={styles.detail}>
                            <div className={classnames(styles[`table`])}>
                              <div className={classnames(styles[`table-tr`])}>
                                <div className={classnames('text')}>
                                  {formatMessage(
                                    messages.currentMedications.nameLabel,
                                  )}
                                  :
                                </div>
                                <div className={classnames('text')}>
                                  {formatMessage(
                                    messages.currentMedications.doseLabel,
                                  )}
                                  :
                                </div>
                                <div className={classnames('text')}>
                                  {formatMessage(
                                    messages.currentMedications.frequencyLabel,
                                  )}
                                  :
                                </div>
                              </div>
                              {personalInfo.patient.details.current_medications.map(
                                (item, index) => (
                                  <div
                                    key={index}
                                    className={classnames(styles[`table-tr`])}
                                  >
                                    <p>{item.name}</p>
                                    <p>{item.dose}</p>
                                    <p>{item.frequency}</p>
                                  </div>
                                ),
                              )}
                            </div>
                          </div>
                        </section>
                      )}
                    {personalInfo.patient.details.illnesses_injuries &&
                      personalInfo.patient.details.illnesses_injuries.length >
                        0 && (
                        <section className={styles['content-detail-section']}>
                          <Fragment>
                            <h2>
                              {formatMessage(messages.illnessesAndInjuries)}:
                            </h2>
                            <div className={styles.detail}>
                              <div className={classnames(styles[`table`])}>
                                <div className={classnames(styles[`table-tr`])}>
                                  <div
                                    className={classnames(
                                      'text',
                                      styles.column3,
                                    )}
                                  >
                                    {formatMessage(
                                      messages.illnessesAndInjuries
                                        .descriptionLabel,
                                    )}
                                    :
                                  </div>
                                  <div
                                    className={classnames(
                                      'text',
                                      styles.column3,
                                    )}
                                  >
                                    {formatMessage(
                                      messages.illnessesAndInjuries
                                        .dateOfOnsetLabel,
                                    )}
                                    :
                                  </div>
                                </div>
                                {personalInfo.patient.details.illnesses_injuries.map(
                                  (item, index) => (
                                    <div
                                      key={index}
                                      className={classnames(styles[`table-tr`])}
                                    >
                                      <p>{item.description}</p>
                                      <p>
                                        {displayDateFormat(item.dateOfOnset)}
                                      </p>
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          </Fragment>
                        </section>
                      )}

                    <section className={styles['content-detail-section']}>
                      <h2>{formatMessage(messages.alcoholAndDrugUse)}:</h2>

                      {alcoholAndDrugsOptions.map((item, index) => (
                        <div key={index} className={styles.detail}>
                          <div className={styles.text}>
                            <span>{item.label}</span>
                            <p>{personalInfo.patient.details[item.id]}</p>
                          </div>
                        </div>
                      ))}

                      <div className={styles.text}>
                        <span>
                          {formatMessage(messages.alcoholAndDrugUse.otherLabel)}
                          :
                        </span>
                        <p>{personalInfo.patient.details.others}</p>
                      </div>
                    </section>
                  </Fragment>
                ) : (
                  <div>No Data Available</div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PatientInformationResponse;
