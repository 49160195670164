import React, { useState, Fragment, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Cell, Grid } from 'react-foundation';
import moment from 'moment';
import classnames from 'classnames';
import styles from './Payment.module.scss';
import useIntl from '../../hooks/useIntl';
import messages from './messages';

import Progress from '../Progress';
import useSquarePayment from '../../hooks/useSquarePayment';
import { isObjectEmpty, addToDataLayer } from '../../utils/common';

import AuthContext from '../../context/AuthContext';

import { getUserProfile } from '../../api/user';
import {
  createPayment,
  updateAppointment,
  getAppointment,
} from '../../api/appointment';
import { getPatientCards, deletePatientCard } from '../../api/patient';
import { checkDoctorSquareConnection } from '../../api/doctor';

import Loading from '../common/Loading';
import Modal from '../common/Modal';
import CheckBox from '../common/inputs/CheckBox';
import PrimaryButton from '../common/buttons/PrimaryButton';
import SecondaryButton from '../common/buttons/SecondaryButton';

import Back from '../../img/icons/back.svg';
import ApprovalImg from '../../img/approval.svg';
import Logo from '../Logo';

const isInvalidPaymentToCheckout = (payment) => {
  return isObjectEmpty(payment);
};
const Payment = () => {
  const { formatMessage } = useIntl();
  const { id: appointmentId } = useParams();
  const [doctor, setDoctor] = useState({});
  const [amount, setAmount] = useState();
  const [paymentObj, setPaymentObj] = useState({});
  const { authUser } = useContext(AuthContext);
  const [profile, setProfile] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [patientCards, setPatientCards] = useState([]);
  const [, setAppointmentObj] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [cardId, setCardId] = useState(0);
  const [isNewCard, setIsNewCard] = useState(false);
  const [isRememberCard, setIsRememberCard] = useState(false);
  const [doctorId, setDoctorId] = useState();
  const history = useHistory();
  const [uuid, setUuid] = useState('');
  const [showCardForm, setShowCardForm] = useState(false);
  const [error, setError] = useState('');
  const [bookingTypeLabel] = useState('');
  const [bookingAmountLabel, setBookingAmountLabel] = useState('');
  const [appointment, setAppointment] = useState({});
  const [showPaymentError, setShowPaymentError] = useState(false);
  const [showSquareError, setShowSquareError] = useState(false);

  const fetchProfile = async () => {
    setIsLoading(true);
    if (authUser.id) {
      try {
        const { patient } = await getUserProfile();
        setProfile(patient);
        const patient_name = `${patient.first_name ? patient.first_name : ''}${
          patient.last_name ? ` ${patient.last_name}` : ''
        }`;
        setInitialValues({
          patient_type: 'New',
          patient_name,
          patient_phone: patient.phone || '',
        });
        setAppointmentObj({
          ...initialValues,
        });
        const { cards } = await getPatientCards(patient.id);
        const formattedCards = cards.map((card) => ({
          ...card,
          isChecked: false,
        }));
        setPatientCards(formattedCards);
        if (cards) {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);

    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const deleteCard = async (cardId) => {
    setIsLoading(true);
    setShowCardForm(false);
    try {
      await deletePatientCard(profile.id, cardId, doctorId);
      const { cards: patientCards } = await getPatientCards(profile.id);
      if (!patientCards.length) {
        setShowCardForm(true);
      }
      setPatientCards(patientCards);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const fetchAppointment = async (appointmentId) => {
    setIsLoading(true);
    const { appointment } = await getAppointment(appointmentId);
    if (
      appointment.doctor.clinic_id === 0 &&
      !appointment.doctor.square_access_token
    ) {
      setShowSquareError(true);
      setIsLoading(false);
    } else {
      const connection = await checkDoctorSquareConnection(
        appointment.doctor_id,
      );
      if (connection.success === false) {
        setShowSquareError(true);
        setIsLoading(false);
      }
      setAppointment(appointment);
      setDoctor(appointment.doctor);
      setBookingAmountLabel(
        `$${appointment.amount} (${appointment.appointment_duration_in_minutes}min)`,
      );
      setAmount(appointment.amount);
      setDoctorId(appointment.doctor_id);
      setUuid(appointment.calendly_event_uuid);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchAppointment(appointmentId);
    setShowCardForm(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId]);

  const handleSubmit = async (nonceToken) => {
    try {
      const payment = await createPayment(nonceToken, paymentObj);
      await updateAppointment(uuid, {
        status: 'Paid',
        payment_id: payment.id,
      });
      toast('Your appointment booked successfully', {
        autoClose: 5000,
        className: styles.toastMsg,
        bodyClassName: styles.toastDiv,
        hideProgressBar: true,
      });
      setIsLoading(false);
      setTimeout(() => {
        history.push('/my-account/appointments');
      }, 500);
    } catch (e) {
      setError(e.response && e.response.data && e.response.data.message);
      setIsLoading(false);
      setShowPaymentError(true);
      setTimeout(() => {
        onReDrawFrom();
      }, 500);
    }
  };
  const { onGetCardNonce, onReDrawFrom } = useSquarePayment({
    styles: styles['card-input'],
    handleOnSubmit: handleSubmit,
    isValidCartToCheckout:
      !isInvalidPaymentToCheckout({ paymentObj }) && showCardForm,
  });
  return (
    <div className={styles.root}>
      {isLoading && <Loading />}
      <Fragment>
        <div className={styles.title} data-testid="schedule_payment header">
          {formatMessage(messages.title)}
        </div>
        <div className={styles['schedule-header']}>
          <div className={styles['header-left']}>
            <div className={styles['schedule-user']}>
              <img
                src={
                  doctor.profile_pic
                    ? doctor.profile_pic
                    : require('../../img/doctor.jpg')
                }
                alt={`${doctor.first_name} ${
                  doctor.last_name ? doctor.last_name : ''
                }`}
                data-testid="schedule_payment clinician profilepic"
              />
            </div>
            <div>
              <h3 data-testid="schedule_doctor_name">
                {doctor.first_name} {doctor.last_name ? doctor.last_name : ''}
              </h3>
              <div className={styles['meeting-time']}>
                {moment(appointment.start_time).isAfter(new Date()) && (
                  <>
                    <img
                      className={styles['meeting-icon']}
                      src={require(`../../img/icons/calendar-blue.svg`)}
                      alt={'appointment-booked'}
                    />
                    <p
                      className={styles.isAfter}
                      data-testid="schedule_payment datetime"
                    >
                      {moment(appointment.start_time).format('LT')} -{' '}
                      {moment(appointment.end_time).format('LT')},{' '}
                      {moment(appointment.end_time).format(
                        'dddd, MMMM DD, YYYY',
                      )}
                    </p>
                  </>
                )}
                {moment(appointment.start_time).isBefore(new Date()) && (
                  <>
                    <img
                      className={styles['meeting-icon']}
                      src={require(`../../img/icons/calendar-black.svg`)}
                      alt={'appointment-booked'}
                    />
                    <p
                      className={styles.isBefore}
                      data-testid="schedule_payment datetime"
                    >
                      {moment(appointment.start_time).format('LT')} -{' '}
                      {moment(appointment.end_time).format('LT')},{' '}
                      {moment(appointment.end_time).format(
                        'dddd, MMMM DD, YYYY',
                      )}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={styles['header-right']}>
            <div className={styles['schedule-content']}>
              <h3 data-testid="bookingTypeLabel"> {bookingTypeLabel} </h3>
              <h6 data-testid="bookingAmountLabel"> {bookingAmountLabel} </h6>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.appointmentInfo}>
            <div className={styles.text}>
              <div className={classnames(styles.type, styles.typeDiv)}>
                <span data-testid="schedule_payment appointment type label">
                  Type:
                </span>
                <p data-testid="schedule_payment appointment type value">
                  {appointment.patient_type}
                </p>
              </div>
              <div className={classnames(styles.location, styles.mobileDiv)}>
                <span data-testid="schedule_payment appointment mobile label">
                  Mobile:
                </span>
                <p data-testid="schedule_payment appointment mobie value">
                  {appointment.patient_phone}
                </p>
              </div>
              <div className={classnames(styles.location, styles.reason)}>
                <span data-testid="schedule_payment appointment booking_type label">
                  Booking Type:
                </span>
                <p data-testid="schedule_payment appointment booking_type value">
                  {appointment.bookingType && appointment.bookingType.name
                    ? appointment.bookingType.name
                    : '-'}
                </p>
              </div>
              <div
                className={classnames(styles.location, styles.assessmentDiv)}
              >
                <span data-testid="schedule_payment appointment assessment label">
                  Assessment:
                </span>
                <p data-testid="schedule_payment appointment assessment value">
                  {appointment.has_assessment ? 'Yes' : 'No'}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.progressHead}>
            <p data-testid="schedule_payment progress payment label">Payment</p>
          </div>
          <div className={styles.progressBar}>
            <Progress width={700} height={5} percent={100} />
          </div>
        </div>
      </Fragment>
      {
        <div className={styles['contact-container']}>
          <div className={styles.cards}>
            {patientCards.length > 0 &&
              patientCards.map((card, index) => (
                <Fragment key={`radio-${index}`}>
                  <div
                    key={`radio-${index}${card.id}`}
                    className={[
                      styles['card-radio'],
                      card.isChecked
                        ? styles['card-radio-checked']
                        : styles['card-radio-default'],
                    ].join(' ')}
                    onClick={() => {
                      setPatientCards(
                        [...patientCards].map((cardObj) => {
                          if (cardObj.id === card.id) {
                            return {
                              ...cardObj,
                              isChecked: true,
                            };
                          }
                          return {
                            ...cardObj,
                            isChecked: false,
                          };
                        }),
                      );
                      setCardId(card.id);
                      setIsNewCard(false);
                    }}
                  ></div>
                  <div key={index} className={styles.cardItem}>
                    <span
                      className={styles[card.cardBrand.toLowerCase()]}
                    ></span>
                    <p>Ending with {card.last4}</p>
                    <p>
                      Exp on {card.expMonth}/{card.expYear}
                    </p>
                    <button
                      type="button"
                      className="secondary"
                      onClick={() => deleteCard(card.id)}
                    ></button>
                  </div>
                </Fragment>
              ))}
          </div>
          {patientCards.length ? (
            <>
              <span className={styles.newCard}>New Card</span>
              <div
                className={[
                  styles['card-radio'],
                  isNewCard
                    ? styles['card-radio-checked']
                    : styles['card-radio-default'],
                ].join(' ')}
                onClick={() => {
                  setPatientCards(
                    [...patientCards].map((cardObj) => {
                      return { ...cardObj, isChecked: false };
                    }),
                  );
                  setIsNewCard(true);
                  setCardId(0);
                }}
              ></div>
            </>
          ) : (
            ''
          )}
          <Grid className={patientCards.length ? styles.formGrid : ''}>
            <div className={styles.payment}>
              <label htmlFor="ccnumber">
                {formatMessage(messages.cardNumber)}
              </label>
              <div id="ccnumber" />
              <span id="cardNumber-error" className="error-message" />
            </div>
            <div className={styles.paymentInput}>
              <label htmlFor="ccexp">
                {formatMessage(messages.expirationDate)}
              </label>
              <div id="ccexp" />
              <span id="expirationDate-error" className="error-message" />
            </div>
            <div className={styles.paymentInput}>
              <label htmlFor="cvv">{formatMessage(messages.cvvCode)}</label>
              <div id="cvv" />
              <span id="cvv-error" className="error-message" />
            </div>
            <div className={styles.paymentInput}>
              <label htmlFor="postalCodeBilling">
                {formatMessage(messages.postalCode)}
              </label>
              <div id="postalCodeBilling" />
              <span id="postalCode-error" className="error-message" />
            </div>
            <Cell small={12} medium={12} className={styles['checkboxBtn']}>
              <CheckBox
                label="Save Payment Method"
                name="rememberCard"
                value={isRememberCard}
                isActive={
                  (!patientCards.length && isRememberCard) ||
                  (isNewCard && isRememberCard)
                }
                onClick={() =>
                  setIsRememberCard((currentState) => !currentState)
                }
              />
            </Cell>
          </Grid>
          <div className={styles.btnDiv}>
            <SecondaryButton
              label="Cancel"
              className="font-18"
              type="button"
              onClick={() => {
                const eventData = {
                  event: 'ga4_event',
                  properties: {
                    event_name: 'book_appointment',
                    step_name: 'cancel',
                    step_number: '7',
                    clinician_name: doctor.first_name,
                    clinician_id: doctorId,
                    patient_id: profile.id,
                  },
                };
                addToDataLayer(eventData);
                history.push(`/my-account/`);
              }}
              data-testid="schedule_payment cancel button"
            />
            {isNewCard || !patientCards.length ? (
              <PrimaryButton
                label="Confirm Payment"
                type="submit"
                className="font-18"
                onClick={async (e) => {
                  setIsLoading(true);
                  const eventData = {
                    event: 'ga4_event',
                    properties: {
                      event_name: 'book_appointment',
                      step_name: 'payment',
                      step_number: '7',
                      amount: amount || '',
                      currency: 'USD',
                    },
                  };
                  addToDataLayer(eventData);
                  setPaymentObj({
                    amount,
                    doctor_id: doctorId,
                    patient_id: profile.id,
                    clinic_id: doctor.clinic_id,
                    isRememberCard:
                      (!patientCards.length && isRememberCard) ||
                      (isNewCard && isRememberCard),
                  });
                  setIsLoading(false);
                  onGetCardNonce(e);
                }}
                data-testid="schedule_payment pay button"
              />
            ) : (
              <PrimaryButton
                disabled={!cardId}
                label="Confirm Payment"
                type="submit"
                className="font-18"
                data-testid="schedule_payment pay button"
                onClick={async (e) => {
                  setIsLoading(true);
                  const eventData = {
                    event: 'ga4_event',
                    properties: {
                      event_name: 'book_appointment',
                      step_name: 'payment',
                      step_number: '7',
                      amount: amount || '',
                      currency: 'USD',
                    },
                  };
                  addToDataLayer(eventData);
                  const payment = await createPayment(cardId, {
                    amount,
                    doctor_id: doctorId,
                    patient_id: profile.id,
                    clinic_id: doctor.clinic_id,
                    isRememberCard:
                      (!patientCards.length && isRememberCard) ||
                      (isNewCard && isRememberCard),
                  });
                  toast('Your appointment booked successfully', {
                    autoClose: 5000,
                    className: styles.toastMsg,
                    bodyClassName: styles.toastDiv,
                    hideProgressBar: true,
                  });

                  const appointment = await updateAppointment(uuid, {
                    status: 'paid',
                    payment_id: payment.id,
                  });
                  setIsLoading(false);
                  if (appointment) {
                    setTimeout(() => {
                      history.push('/my-account/appointments');
                    }, 500);
                  }
                }}
              />
            )}
          </div>
        </div>
      }
      {showPaymentError && (
        <Modal className={styles.paymentErrorModal}>
          <div className={styles.content}>
            <div
              className={styles.container}
              data-testid="schedule_payment payment error container"
            >
              <div className={styles.backDiv}>
                <img src={Back} alt="Back" />

                <span
                  onClick={() => {
                    setShowPaymentError(false);
                  }}
                  data-testid="schedule_payment payment back link"
                >
                  Back
                </span>
              </div>
              <Logo />
              <div className={styles.warningImg}>
                <img src={ApprovalImg} alt="Warning" />
              </div>
              <h4
                className={styles.confirmationTitle}
                data-testid="schedule_payment payment_error title"
              >
                Payment Failed
              </h4>
              <br />
              <br />
              <p
                className={styles.paymentErrortext}
                data-testid="schedule_payment payment error"
              >
                {error ? error : 'Please check the information you provided'}
              </p>
            </div>
          </div>
        </Modal>
      )}
      {showSquareError && (
        <Modal className={styles.paymentErrorModal}>
          <div className={styles.content}>
            <div className={styles.container}>
              <div className={styles.backDiv}>
                <img
                  src={Back}
                  alt="Back"
                  data-testid="square_payment error back image"
                />

                <span
                  onClick={() => {
                    history.push('/my-account');
                    setShowSquareError(false);
                  }}
                  data-testid="square_payment error back"
                >
                  Back
                </span>
              </div>
              <Logo />
              <div className={styles.warningImg}>
                <img
                  src={ApprovalImg}
                  alt="Warning"
                  data-testid="square_payment error image"
                />
              </div>
              <h4
                className={styles.confirmationTitle}
                data-testid="payment_error title"
              >
                Payment Error
              </h4>
              <br />
              <br />
              <p
                className={styles.paymentErrortext}
                data-testid="square_payment error text"
              >
                We cannot process your payment at the current time. Please try
                again later.
              </p>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Payment;
