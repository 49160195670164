import { doGet, doPut, doPatch, doDelete, doPost } from '../fetch';

const getDoctor = async (id) => {
  const doctor = await doGet(`/b2c/doctor/${id}`);
  return doctor;
};

const getFactMatchedDoctors = async (params) => {
  const query = Object.keys(params)
    .map((pk) => `${pk}=${params[pk]}`)
    .join('&');

  const doctors = await doGet(`/b2c/doctor/fact-matched?${query}`);
  return doctors;
};

const getClinicianProfile = async (id) => {
  const doctor = await doGet(`/b2c/doctor/profile/${id}`);
  return doctor;
};

const getSuperBillRequests = async () => {
  const response = await doGet('/b2c/superbill?includeCompleted=true');
  return response;
};

const getPatient = async (id) => {
  const patient = await doGet(`/b2c/doctor/patient/${id}`);
  return patient;
};
const getPatientList = async (params) => {
  const patientList = await doPost(`/b2c/doctor/patients-list`, params);
  return patientList;
};
const checkDoctorSquareConnection = async (id) => {
  const data = await doGet(`/b2c/doctor/check-square-connection/${id}`);
  return data;
};

const getInvitationData = async (email) => {
  const clinicianList = await doGet(`/b2c/doctor/invited/clinician/${email}`);
  return clinicianList;
};

const getDoctorPayment = async (id) => {
  const doctor = await doGet(`/b2c/doctor/${id}/payment`);
  return doctor;
};

const updateDoctorProfile = async (params) => {
  const profile = await doPatch(`/b2c/doctor`, params);
  return profile;
};

const uploadDoctorProfilePic = async ({ id, params }, dispatch) => {
  const profile = await doPut(`/b2c/doctor/${id}/upload-pic`, params);
  dispatch({ type: 'UPDATE_AVATAR', payload: profile.doctor.profile_pic });
  return profile;
};

const removeDoctorProfilePic = async ({ id }, dispatch) => {
  const profile = await doDelete(`/b2c/doctor/${id}/upload-pic`);
  dispatch({ type: 'UPDATE_AVATAR', payload: require('../../img/doctor.jpg') });
  return profile;
};

const getBookingTypes = async (id, type) =>
  await doGet(`/b2c/doctor/${id}/booking-types/${type}`);

const inviteClinician = async (body) => {
  const patient = await doPost('/b2c/auth/invite-clinician', body);
  return patient;
};

const getInsuranceList = async () => {
  const insurances = await doGet('/b2c/insurances');
  return insurances;
};
const getClinics = async () => {
  const clinics = await doGet('/b2c/clinic/data');
  return clinics;
};
const deleteClinic = async (id) => {
  const clinics = await doDelete(`/b2c/clinic/${id}`);
  return clinics;
};
const getClinic = async (id) => {
  const clinics = await doGet(`/b2c/doctor/clinic/${id}`);
  return clinics;
};
const getClinicFromSubdomain = async () => {
  const clinics = await doGet(`/b2c/clinic`);
  return clinics;
};
const updateClinic = async (id, body) => {
  const clinics = await doPut(`/b2c/doctor/clinic/${id}`, body);
  return clinics;
};

const addClinic = async (body) => {
  const clinics = await doPost(`/b2c/doctor/add-clinic`, body);
  return clinics;
};

const addWhiteLabelledClinic = async (body) => {
  const clinics = await doPost(`/b2c/doctor/add-white-labelled-clinic`, body);
  return clinics;
};

const getClinicianRegistrationData = async (id) => {
  const clinician = await doGet(`/b2c/doctor/clinician-registration/${id}`);
  return clinician;
};

const saveClinicianRegistration = async (body) => {
  const clinician = await doPost(`/b2c/doctor/clinician-registration`, body);
  return clinician;
};

const updateClinicianRegistration = async (id, body) => {
  const clinician = await doPost(
    `/b2c/doctor/update-clinician-registration/${id}`,
    body,
  );
  return clinician;
};

const getTeamMembers = async (id) => {
  const clinician = await doGet(`/b2c/doctor/${id}/clinic-members`);
  return clinician;
};

const generateToken = async (id, body) => {
  const clinician = await doPost(`/b2c/doctor/generate-token/${id}`, body);
  return clinician;
};

const getUnclaimClinics = async () => {
  const clinics = await doGet('/b2c/unclaimClinics');
  return clinics;
};

const getClinicians = async (id) => {
  const clinics = await doGet(`/b2c/doctor/allclinicians/${id}`);
  return clinics;
};

const checkClinicianCalendlyStatus = async (id) => {
  const doctor = await doGet(`/b2c/doctor/checkCalendlyStatus/${id}`);
  return doctor;
};

const closeClinicianAccount = async (id) => {
  const res = doDelete(`/b2c/patient/close-clinician-account/${id}`);
  return res;
};

const createProtocolTemplate = async (body) => {
  const res = await doPost(`/b2c/doctor/create-protocol-template`, body);
  return res;
};

const getProtocolTemplates = async (id) => {
  const res = await doGet(`/b2c/doctor/protocol-templates/${id}`);
  return res;
};

const getProtocolTemplateForms = async (id) => {
  const res = await doGet(`/b2c/doctor/protocol-template-forms/${id}`);
  return res;
};

const getAllMyClients = async (id) => {
  const patientList = await doGet(`/b2c/doctor/allMyClients/${id}`);
  return patientList;
};

const createSchedule = async (body) => {
  const res = await doPost(`/b2c/doctor/create-schedule`, body);
  return res;
};

const updateSchedule = async (body) => {
  const res = await doPost(`/b2c/doctor/update-schedule`, body);
  return res;
};

const getActiveProtocols = async (id) => {
  const res = await doGet(`/b2c/doctor/get-active-protocols/${id}`);
  return res;
};

const getCompleteProtocols = async (id) => {
  const res = await doGet(`/b2c/doctor/get-complete-protocols/${id}`);
  return res;
};

const getActiveProtocol = async (id) => {
  const res = await doGet(`/b2c/doctor/get-active-protocol/${id}`);
  return res;
};

const removeAssignedForm = async (id) => {
  const res = await doDelete(`/b2c/doctor/remove-assign-form/${id}`);
  return res;
};

const deleteScheduledProtocol = async (id) => {
  const res = await doDelete(`/b2c/doctor/delete-scheduled-protocol/${id}`);
  return res;
};

const deleteProtocolTemplate = async (id) => {
  const res = await doDelete(`/b2c/doctor/delete-protocol-template/${id}`);
  return res;
};

const updateClinicianMatchingState = async (id, body) =>
  doPost(`/b2c/doctor/update-clinician-matching-state/${id}`, body);

const updateClinicianMatchingStateByDoctor = async (id, body) =>
  doPost(`/b2c/doctor/update-clinician-matching-state-by-doctor/${id}`, body);

export {
  getDoctor,
  getFactMatchedDoctors,
  getDoctorPayment,
  updateDoctorProfile,
  uploadDoctorProfilePic,
  removeDoctorProfilePic,
  getPatient,
  getBookingTypes,
  inviteClinician,
  saveClinicianRegistration,
  getClinics,
  getClinicianRegistrationData,
  getPatientList,
  addClinic,
  getTeamMembers,
  updateClinicianRegistration,
  getClinic,
  getInsuranceList,
  generateToken,
  getInvitationData,
  deleteClinic,
  updateClinic,
  getClinicFromSubdomain,
  getUnclaimClinics,
  getClinicianProfile,
  checkDoctorSquareConnection,
  getClinicians,
  checkClinicianCalendlyStatus,
  closeClinicianAccount,
  createProtocolTemplate,
  getProtocolTemplates,
  getProtocolTemplateForms,
  getAllMyClients,
  createSchedule,
  getActiveProtocols,
  getActiveProtocol,
  updateSchedule,
  getCompleteProtocols,
  removeAssignedForm,
  deleteScheduledProtocol,
  deleteProtocolTemplate,
  updateClinicianMatchingState,
  updateClinicianMatchingStateByDoctor,
  addWhiteLabelledClinic,
  getSuperBillRequests,
};
