export const binaryResponses = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

const mapOptionsValues = (values) =>
  values.map((e) => ({
    label: e,
    value: e,
  }));

export const stepOneFormStructure = [
  {
    id: 'overall_health',
    type: 'radio',
    options: mapOptionsValues([
      '10 - Best',
      '9',
      '8',
      '7',
      '6',
      '5',
      '4',
      '3',
      '2',
      '1 - Worst',
    ]),
  },
  {
    id: 'current_profession',
    label: 'currentProfession',
    type: 'textInput',
  },
  {
    id: 'diagnosis_history',
    label: 'diagnosisHistory',
    type: 'checkbox',
    options: mapOptionsValues([
      'Irregular Blood Pressure',
      'Irregular Heart Conditions (surgery, pacemakers, arrhythmia)',
      'Hypoglycemia',
      'Epilepsy',
      'Recent Surgeries',
      'Fibromyalgia',
      'Chronic Fatigue Syndrome, Lupus',
      'Chronic Pain',
      'Physical limitations (broken bones, strains, sprains, amputations)',
      'Anxiety Disorders/Panic Attacks',
      'Psychiatric conditions (bipolar, schizophrenia, PTSD, severe depression, borderline personality disorder)',
      'Suicidal ideation or attempt',
    ]),
  },
  {
    id: 'pregnant_status',
    label: 'pregnantStatus',
    type: 'radio',
    options: mapOptionsValues(['Yes', 'No', 'Not applicable']),
  },
  {
    id: 'physical_mental_emotional_concerns',
    label: 'physicalMentalEmotionalConcerns',
    type: 'textarea',
  },
  {
    id: 'trauma_history',
    label: 'traumaHistory',
    type: 'textarea',
  },
  {
    id: 'surgeries_status',
    label: 'surgeriesStatus',
    type: 'radio',
    options: binaryResponses,
    freeText: {
      id: 'surgeries_status_yes_details',
      label: 'surgeriesStatusYesDetails',
      triggerValue: 'yes',
    },
  },
  {
    id: 'standard_diet',
    label: 'standardDiet',
    type: 'textarea',
  },
];

export const stepTwoFormStructure = [
  {
    id: 'sleep_patterns',
    label: 'sleepPatterns',
    type: 'textarea',
  },
  {
    id: 'family_diagnoses',
    label: 'familyDiagnoses',
    type: 'radio',
    options: binaryResponses,
    freeText: {
      id: 'family_diagnoses_details',
      label: 'familyDiagnosesDetails',
      triggerValue: 'yes',
    },
  },
  {
    id: 'partner_approval_healing_practice',
    label: 'partnerApprovalHealingPractice',
    type: 'radio',
    options: mapOptionsValues(['Yes', 'No', 'Not applicable']),
  },
  {
    id: 'strengths_list',
    label: 'strengthsList',
    type: 'textarea',
  },
  {
    id: 'meditative_spiritual_practice',
    label: 'meditativeSpiritualPractice',
    type: 'radio',
    options: binaryResponses,
    freeText: {
      id: 'meditative_spiritual_practice_details',
      label: 'meditativeSpiritualPracticeDetails',
      triggerValue: 'yes',
    },
  },
  {
    id: 'supplements_taken',
    label: 'supplementsTaken',
    type: 'textarea',
  },
  {
    id: 'ideal_outcome',
    label: 'idealOutcome',
    type: 'textarea',
  },
  {
    id: 'additional_information',
    label: 'additionalInformation',
    type: 'textarea',
  },
];
