import React, {
  useState,
  Fragment,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import Loading from '../../../components/common/Loading';
import LeftNav from '../../../components/nav/LeftNav';
import styles from './MyAccountIntakeFormsPage.module.scss';
import AuthContext from '../../../context/AuthContext';
import useIntl from '../../../hooks/useIntl';
import messages from './messages';
import FormsList from '../../../components/FormsList';
import FormListView from '../../../components/FormListView';
import { deleteIntakeForm1, deleteIntakeForm2 } from '../../../api/intakeForm';
import { getPatientAssessment } from '../../../api/patient';
import { getEntries } from '../../../api/contentful';
import { getAssignedForms } from '../../../api/intakeForm';
import { FORM_AND_ASSESSMENT_TABS, FORM } from '../../../constants';

const tabs = [FORM_AND_ASSESSMENT_TABS.NEW, FORM_AND_ASSESSMENT_TABS.COMPLETED];
const MyAccountIntakeFormsPage = () => {
  const history = useHistory();
  const { isLoading, authUser } = useContext(AuthContext);
  const [dataLoading, setLoading] = useState(false);
  const [forms, setForms] = useState({});
  const { formatMessage } = useIntl();
  const isDataLoading = dataLoading || isLoading;
  const [assignedForms, setAssignedForms] = useState([]);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [filteredAssesAndForm, setFilterAssesAndForm] = useState();
  const [hasAssignedConsent, setHasAssignedConsent] = useState(false);

  const prepareAssessmentValues = (fields, assessmentValues) => {
    const assessments = [];
    let assessmentValue;
    fields.map((field) => {
      if (field.key && assessmentValues[field.key]) {
        if (
          assessmentValues[field.key].value ||
          assessmentValues[field.key].options ||
          field.type === 'slider'
        ) {
          if (assessmentValues[field.key].options) {
            assessments.push({
              key: [field.title],
              value: assessmentValues[field.key].options
                .map((option) => option.title)
                .toString(),
            });
          } else if (field.type === 'slider') {
            assessmentValue = field.marks.find(
              (mark) =>
                mark.value === assessmentValues[field.key].value ||
                mark.value === assessmentValues[field.key],
            ).label;
            assessments.push({ key: [field.title], value: assessmentValue });
          } else if (field.type === 'swipe-card') {
            assessments.push({
              key: [`${field.title.replace(/\.+$/, '')} ${field.subtitle}`],
              value: assessmentValues[field.key].value,
            });
          } else {
            assessments.push({
              key: [field.title],
              value: assessmentValues[field.key].value,
            });
          }
        }
      }
    });
    return assessments;
  };

  const fetchIntakeforms = useCallback(async () => {
    setLoading(true);
    if (authUser.id) {
      try {
        const forms = [];
        const { assessment } = await getPatientAssessment(authUser.id);
        if (assessment && assessment.assessment_name) {
          const questionnaire = await getEntries({
            'fields.title': assessment.assessment_name,
            content_type: 'recommendationEngineQuestionnaire',
          });
          if (
            assessment.assessment_values &&
            questionnaire.items.length > 0 &&
            questionnaire.items[0].fields['questions']
          ) {
            forms.assessment = Object.assign({
              id: assessment.id,
              updatedAt: assessment.updatedAt,
              assessments: prepareAssessmentValues(
                questionnaire.items[0].fields['questions'],
                assessment.assessment_values,
              ),
            });
          } else {
            forms.assessment = {};
          }
        } else {
          forms.assessment = {};
        }
        setForms(forms);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  }, [authUser.id]);

  const fetchAssignedForms = useCallback(async () => {
    try {
      setLoading(true);
      if (authUser.id) {
        const { assignForms } = await getAssignedForms(authUser.id);
        setAssignedForms(assignForms);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [authUser.id]);

  useEffect(() => {
    setFilterAssesAndForm(
      assignedForms.filter((form) => form.status !== FORM.STATUS.COMPLETED),
    );

    setHasAssignedConsent(
      assignedForms.some(
        (form) =>
          form.status === FORM.STATUS.ASSIGNED &&
          form.form_type === FORM.TYPE.CONSENT,
      ),
    );
  }, [assignedForms]);

  useEffect(() => {
    if (authUser.id) {
      fetchIntakeforms();
      fetchAssignedForms();
    }
  }, [authUser, fetchAssignedForms, fetchIntakeforms]);

  const handleFillInForm = (type, item) => {
    if (type === 'intake') {
      if (
        item &&
        item.dim_form &&
        item.dim_form.template_name === 'intake_form_21'
      ) {
        history.push(
          `/my-account/form/intakeform/${item.dim_form.template_name}`,
          {
            patient_id: authUser.id,
            fact_form_id: item.id,
            dimForm: item.dim_form,
          },
        );
      } else {
        history.push(`/my-account/form/intakeform/`, {
          patient_id: authUser.id,
          fact_form_id: item.id,
          dimForm: item.dim_form,
        });
      }
    } else if (type === 'consent') {
      history.push(`/my-account/form/consentform/`, {
        patient_id: authUser.id,
        fact_form_id: item.id,
        template_name: item.dim_form.template_name,
        doctor_id: item.doctor_id,
      });
    } else {
      if (item && item.dim_form)
        history.push(
          `/my-account/assessment-form/${item.dim_form.assessment_key}`,
          {
            fact_form_id: item.id,
          },
        );
      else {
        if (authUser?.whitelabelDetails?.assessment_key) {
          history.push(
            `/my-account/discoverv2/${authUser.whitelabelDetails.assessment_key}`,
            { patientId: authUser.id },
          );
        } else {
          history.push('/my-account/discoverv2', { patientId: authUser.id });
        }
      }
    }
  };

  const handleResetForm = async (title, item) => {
    setLoading(true);
    if (title === formatMessage(messages.intakeTitle)) {
      try {
        await deleteIntakeForm1(item.id);
        setLoading(false);
        fetchIntakeforms();
      } catch (error) {
        setLoading(false);
      }
    } else if (title === 'Matching Assessment') {
      try {
        if (authUser?.whitelabelDetails?.assessment_key) {
          history.push(
            `/my-account/discoverv2/${authUser.whitelabelDetails.assessment_key}`,
            { patientId: authUser.id },
          );
        } else {
          history.push('/my-account/discoverv2', { patientId: authUser.id });
        }
        localStorage.removeItem('recommendations-answers-id');
      } catch (error) {
        setLoading(false);
      }
    } else {
      try {
        await deleteIntakeForm2(item.id);
        setLoading(false);
        fetchIntakeforms();
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const onTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === 'Completed') {
      setFilterAssesAndForm(
        assignedForms.filter((form) => form.status === FORM.STATUS.COMPLETED),
      );
      return;
    }
    setFilterAssesAndForm(
      assignedForms.filter((form) => form.status !== FORM.STATUS.COMPLETED),
    );
  };

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <LeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            />
            <h1>Forms and Assessments</h1>
            <nav className={styles.tabs}>
              {tabs.map(
                (tab) =>
                  tab && (
                    <button
                      key={tab}
                      className={[
                        styles.tab,
                        activeTab === tab ? styles.activeTab : '',
                      ].join(' ')}
                      type="button"
                      onClick={() => onTabClick(tab)}
                      data-testid={tab}
                    >
                      <span data-testid={tab}>{tab}</span>
                    </button>
                  ),
              )}
            </nav>
            {filteredAssesAndForm?.length > 0 &&
              filteredAssesAndForm.map((form, index) => (
                <Fragment key={index}>
                  <FormListView
                    item={form}
                    onFillInForm={handleFillInForm}
                    onResetForm={handleResetForm}
                    userType={authUser.profile_type}
                    hasAssignedConsent={hasAssignedConsent}
                  />
                </Fragment>
              ))}
            {!authUser?.invitation_status &&
              forms.assessment &&
              ((forms.assessment.id &&
                activeTab === FORM_AND_ASSESSMENT_TABS.COMPLETED) ||
                (!forms.assessment.id &&
                  activeTab === FORM_AND_ASSESSMENT_TABS.NEW)) && (
                <FormsList
                  form={forms.assessment}
                  title="Matching Assessment"
                  onFillInForm={handleFillInForm}
                  isAssessment={true}
                  onResetForm={handleResetForm}
                />
              )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default MyAccountIntakeFormsPage;
