import React, { useEffect, createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { getLoggedInUser } from '../api/auth';
import { getAccessToken } from '../utils/user';

const AuthContext = createContext({});

const initialState = {
  authUser: {},
  userAvtar: require('../img/patient.jpg'),
  cart: {},
  lineItemsError: [],
  isLoading: true,
  isError: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_USER': {
      return { ...state, authUser: action.payload, isLoading: false };
    }
    case 'UPDATE_CART': {
      return { ...state, cart: action.payload || {}, isLoading: false };
    }
    case 'LINE_ITEM_REMOVE': {
      return {
        ...state,
        lineItemsError: state.lineItemsError.filter(
          ({ sku }) => sku !== action.payload,
        ),
      };
    }
    case 'LINEITEM_ERROR': {
      return { ...state, lineItemsError: action.payload };
    }
    case 'LOADING': {
      return { ...state, isLoading: action.payload };
    }
    case 'ERROR': {
      return { ...state, isError: true };
    }
    case 'UPDATE_AVATAR': {
      return { ...state, userAvtar: action.payload };
    }
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [
    { authUser, cart, isLoading, isError, lineItemsError, userAvtar },
    dispatch,
  ] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchUser = async () => {
      const token = await getAccessToken();

      if (token) {
        dispatch({ type: 'LOADING', payload: true });
        try {
          await getLoggedInUser(dispatch);
        } catch ({ response }) {
          dispatch({ type: 'ERROR', payload: true });
        }
      }

      dispatch({ type: 'LOADING', payload: false });
    };
    fetchUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isError,
        isLoading,
        authUser,
        cart,
        lineItemsError,
        dispatch,
        userAvtar,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AuthContext;
