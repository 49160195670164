import React, { useState, Fragment, useContext, useEffect } from 'react';
import classnames from 'classnames';
import Loading from '../../../components/common/Loading';
import DoctorLeftNav from '../../../components/nav/DoctorLeftNav';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import styles from './DoctorPaymentSetupPage.module.scss';
import AuthContext from '../../../context/AuthContext';
import useIntl from '../../../hooks/useIntl';
import messages from './messages';
import CryptoJS from 'crypto-js';

const { REACT_APP_SQUARE_APPLICATION_ID, REACT_APP_SQUARE_BASE_PATH } =
  process.env;

const DoctorPaymentSetupPage = () => {
  const { formatMessage } = useIntl();
  const { isLoading, authUser } = useContext(AuthContext);
  const [dataLoading] = useState(false);
  const isDataLoading = dataLoading || isLoading;
  const state = CryptoJS.MD5(new Date().getTime());
  sessionStorage.setItem('state', state);

  useEffect(() => {}, [authUser]);

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftNav, 'desktop-only')}>
        <DoctorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            />
            <h1 data-testid={`square connection title`}>
              {formatMessage(messages.title)}
            </h1>
            <div className={styles.mainBoxx}>
              <p
                className={styles.subTitle}
                data-testid={`square connection subtitle`}
              >
                &nbsp;
                <a
                  data-testid={`square connection URL`}
                  href={`${REACT_APP_SQUARE_BASE_PATH}/oauth2/authorize?client_id=${REACT_APP_SQUARE_APPLICATION_ID}&scope=MERCHANT_PROFILE_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE+PAYMENTS_READ+CUSTOMERS_WRITE+CUSTOMERS_READ&state=${state}`}
                >
                  <SecondaryButton
                    label={`Connect Square`}
                    className={styles.btn}
                    type="button"
                    data-testid={`square connection subtitle`}
                  />
                </a>
              </p>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default DoctorPaymentSetupPage;
