import React from 'react';
import PropTypes from 'prop-types';
import styles from './CircleButtonSmall.module.scss';
import classnames from 'classnames';

const CircleButtonSmall = ({ title, isSelected, onClick, className }) => (
  <button
    type="submit"
    className={classnames(styles['circle-small-container'], className)}
    onClick={onClick}
  >
    <div
      className={classnames(styles['circle-small'], {
        [styles['is-selected']]: isSelected,
      })}
    />
    <div className={styles['circle-small-text']}>{title}</div>
  </button>
);

CircleButtonSmall.propTypes = {
  title: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default CircleButtonSmall;
