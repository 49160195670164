import React, { useState, useEffect, Fragment, useContext } from 'react';
import classnames from 'classnames';
import { Cell, Grid } from 'react-foundation';

import styles from './BillingPage.module.scss';

import DoctorLeftNav from '../../../components/nav/DoctorLeftNav';
import Loading from '../../../components/common/Loading';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import MessageModal from '../../../components/MessageModal';

import { createCheckoutSession, getPortalSession } from '../../../api/stripe';

import { getQS } from '../../../utils/common';

import AuthContext from '../../../context/AuthContext';

const BillingPage = () => {
  const { isLoading, authUser } = useContext(AuthContext);

  const [dataLoading, setLoading] = useState(false);
  const [paymentSuccessModal, showPaymentSuccessModal] = useState(false);

  const isDataLoading = dataLoading || isLoading;

  async function handleUpdatePlan(priceId) {
    try {
      setLoading(true);
      const session = await createCheckoutSession({
        price_id: priceId,
      });
      window.location.href = session.url;
    } catch (err) {
      setLoading(false);
    }
  }

  async function handleManagePlan() {
    try {
      setLoading(true);
      const session = await getPortalSession();
      window.location.href = session.url;
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    const stripePaymentSuccess = getQS('success');
    if (stripePaymentSuccess) {
      showPaymentSuccessModal(true);
    }
  }, []);

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <DoctorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            ></div>
            <div className={styles.header}>
              <h1>Billing</h1>
            </div>
            <Grid>
              {!authUser?.billing_plan?.stripe_customer_id && (
                <div className={styles.billingPlank}>
                  <h4 className={styles.title}>Provider</h4>
                  <div className={styles.price}>$99/mo</div>

                  <div className={styles.divline} />

                  <div className={styles.feature}>
                    <span className={styles.featName}>Whitelabeling</span>
                  </div>
                  <div className={styles.feature}>
                    <span className={styles.featName}>
                      Wearable integrations
                    </span>
                  </div>
                  <div className={styles.feature}>
                    <span className={styles.featName}>Gamification</span>
                  </div>
                  <div className={styles.feature}>
                    <span className={styles.featName}>Matching</span>
                    <span className={styles.featDesc}>Standart</span>
                  </div>
                  <div className={styles.feature}>
                    <span className={styles.featName}>Analytics</span>
                    <span className={styles.featDesc}>Standart</span>
                  </div>
                  <div className={styles.feature}>
                    <span className={styles.featName}>EHR & PMS Lite</span>
                  </div>
                  <div className={styles.feature}>
                    <span className={styles.featName}>Support</span>
                    <span className={styles.featDesc}>Standart</span>
                  </div>

                  <PrimaryButton
                    label={'Update plan'}
                    type="button"
                    className={styles.billingPlankBtn}
                    onClick={() =>
                      handleUpdatePlan(
                        process.env.REACT_APP_STRIPE_MONTH_PRICE_ID,
                      )
                    }
                    data-testid={`Update plan'`}
                  />
                </div>
              )}
              {authUser?.billing_plan?.stripe_customer_id && (
                <div>
                  <Cell
                    small={12}
                    flexContainer
                    alignY="middle"
                    className={styles['actions-container']}
                  >
                    <PrimaryButton
                      label={'Manage subscription'}
                      type="button"
                      onClick={handleManagePlan}
                      data-testid={`Manage subscription`}
                    />
                  </Cell>
                </div>
              )}
            </Grid>
          </div>
        </Fragment>
      )}

      {paymentSuccessModal && (
        <MessageModal
          message={{
            text: 'You have successfully subscribed',
            type: 'success',
          }}
          onCloseModal={() => showPaymentSuccessModal(false)}
        />
      )}
    </div>
  );
};

export default BillingPage;
