import React, { useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './ResearcherLeftNav.module.scss';
import AuthContext from '../../../context/AuthContext';
import Loading from '../../../components/common/Loading';

const ResearcherLeftNav = () => {
  const { authUser } = useContext(AuthContext);
  const [isLoading] = useState(false);

  if (authUser.id && window.UserLeap) {
    // eslint-disable-next-line no-undef
    UserLeap('setUserId', authUser.id);
  }
  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div className={styles.title} data-testid="researcher username label">
            Hi {authUser.first_name}
          </div>
          <ul className={styles.menu}>
            <li>
              <NavLink
                exact
                to="/researcher/dashboard"
                activeClassName={styles.active}
              >
                <span data-testid="Dashboard tab">Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/researcher/participants"
                activeClassName={styles.active}
              >
                <span data-testid="Participants tab">Participants</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/researcher/invite-participants"
                activeClassName={styles.active}
              >
                <span data-testid="invite participants tab">
                  Invite Participants
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/researcher/invite-researcher"
                activeClassName={styles.active}
              >
                <span data-testid="invite researcher tab">
                  Invite Researcher
                </span>
              </NavLink>
            </li>
            {authUser['labs.id'] && (
              <li>
                <NavLink to="/researcher/lab" activeClassName={styles.active}>
                  <span data-testid="my lab tab">My lab</span>
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ResearcherLeftNav;
