import React from 'react';
import LayoutPDF from './components/LayoutPDF';
import FullRowLabelledData from './components/FullRowLabelledData';
import { messages } from '../forms/user/AnahitaForm/messages';

const AnahitaIntakePDF = ({ item, formDetail, data }) => {
  return (
    <LayoutPDF item={item} formDetail={formDetail}>
      <>
        <FullRowLabelledData
          label={messages.overallHealth.defaultMessage}
          value={data.overall_health}
        />
        <FullRowLabelledData
          label={messages.currentProfession.defaultMessage}
          value={data.current_profession}
        />
        <FullRowLabelledData
          label={messages.diagnosisHistory.defaultMessage}
          value={data.diagnosis_history}
        />
        <FullRowLabelledData
          label={messages.pregnantStatus.defaultMessage}
          value={data.pregnant_status}
        />
        <FullRowLabelledData
          label={messages.physicalMentalEmotionalConcerns.defaultMessage}
          value={data.physical_mental_emotional_concerns}
        />
        <FullRowLabelledData
          label={messages.traumaHistory.defaultMessage}
          value={data.trauma_history}
        />
        <FullRowLabelledData
          label={messages.surgeriesStatus.defaultMessage}
          value={data.surgeries_status}
        />
        {!!data?.surgeries_status_yes_details && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.surgeriesStatusYesDetails.defaultMessage}
            value={data.surgeries_status_yes_details}
          />
        )}
        <FullRowLabelledData
          label={messages.standardDiet.defaultMessage}
          value={data.standard_diet}
        />
        <FullRowLabelledData
          label={messages.sleepPatterns.defaultMessage}
          value={data.sleep_patterns}
        />
        <FullRowLabelledData
          label={messages.familyDiagnoses.defaultMessage}
          value={data.family_diagnoses}
        />
        {!!data?.family_diagnoses_details && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.familyDiagnosesDetails.defaultMessage}
            value={data.family_diagnoses_details}
          />
        )}
        <FullRowLabelledData
          label={messages.partnerApprovalHealingPractice.defaultMessage}
          value={data.partner_approval_healing_practice}
        />
        <FullRowLabelledData
          label={messages.strengthsList.defaultMessage}
          value={data.strengths_list}
        />
        <FullRowLabelledData
          label={messages.meditativeSpiritualPractice.defaultMessage}
          value={data.meditative_spiritual_practice}
        />
        {!!data?.meditative_spiritual_practice_details && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.meditativeSpiritualPracticeDetails.defaultMessage}
            value={data.meditative_spiritual_practice_details}
          />
        )}
        <FullRowLabelledData
          label={messages.supplementsTaken.defaultMessage}
          value={data.supplements_taken}
        />
        <FullRowLabelledData
          label={messages.idealOutcome.defaultMessage}
          value={data.ideal_outcome}
        />
        <FullRowLabelledData
          label={messages.additionalInformation.defaultMessage}
          value={data.additional_information}
        />
      </>
    </LayoutPDF>
  );
};

export default AnahitaIntakePDF;
