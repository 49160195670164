import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.DoctorPaymentSetupPage.title',
    description: 'Doctor Square Connect Page title',
    defaultMessage: 'Square Connect',
  },
  squareaccountexists: {
    id: 'pages.DoctorPaymentSetupPage.squareaccountexists',
    description: 'Already have a Square Account',
    defaultMessage: 'If you already have a square account',
  },
  squareaccountnotexists: {
    id: 'pages.DoctorPaymentSetupPage.squareaccountnotexists',
    defaultMessage: "Don't have a Square Account",
    description: "If you don't have a square account",
  },
  topUpButtonLabel: {
    id: 'pages.DoctorPaymentSetupPage.topUpButtonLabel',
    description: 'Top up balance button label',
    defaultMessage: 'Top up balance',
  },
  availablePayoutLabel: {
    id: 'pages.DoctorPaymentSetupPage.availablePayoutLabel',
    description: 'Available to pay out label',
    defaultMessage: 'Available to pay out',
  },
  onTheWayToYourBankLabel: {
    id: 'pages.DoctorPaymentSetupPage.onTheWayToYourBankLabel',
    description: 'On the way to your bank label',
    defaultMessage: 'On the way to your bank',
  },
  willBeAvailableSoonLabel: {
    id: 'pages.DoctorPaymentSetupPage.willBeAvailableSoonLabel',
    description: 'Will be available soon label',
    defaultMessage: 'Will be available soon',
  },
  totalLabel: {
    id: 'pages.DoctorPaymentSetupPage.totalLabel',
    description: 'Total label',
    defaultMessage: 'Total',
  },
  fundWillBeAvailableSoonLabel: {
    id: 'pages.DoctorPaymentSetupPage.fundWillBeAvailableSoonLabel',
    description: 'These funds should arrive in your bank account soon label',
    defaultMessage: 'These funds should arrive in your bank account soon.',
  },
});
