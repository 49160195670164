import { useParams } from 'react-router-dom';
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import moment from 'moment';
import { Cell, Grid } from 'react-foundation';
import FieldInput from '../common/fields/FieldInput/FieldInput';
import PrimaryButton from '../common/buttons/PrimaryButton';
import FieldDropdown from '../common/fields/FieldDropdown';
import TextAreaInputWithLabel from '../common/inputs/TextAreaInputWithLabel';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './SuperBillForm.module.scss';
import SecondaryButton from '../common/buttons/SecondaryButton';
import ProcedureForm from './ProcedureForm/ProcedureForm';
import DiagnosisForm from './DiagnosisForm/DiagnosisForm';
import AddedItem from '../common/AddedItem/AddedItem';
import Modal from '../common/Modal';
import colors from '../../styles/_colors.scss';
import Loading from '../common/Loading';
import useSuperbill from '../../hooks/useSuperbill';
import AuthContext from '../../context/AuthContext';
import { sendSuperbill, downloadSuperbill } from '../../api/superbill/index';
import SuperBillMessageModal from './SuperBillMessageModal/SuperBillMessageModal';
import PatientSuperBillForm, {
  clientRequiredFields,
} from './PatientSuperBillForm/PatientSuperBillForm';
import Logo from '../../img/logo.svg';
import { USER_TYPES } from '../../constants';

const parseSelectOptions = (valuesArray) =>
  valuesArray.map((value) => ({
    label: value,
    value,
  }));

export const dropdownStyles = {
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  control: (base) => ({
    ...base,
    borderRadius: '100px',
    width: '100%',
    height: '40px',
    maxHeight: '40px',
    boxShadow: 0,
    border: `1px solid ${colors.grayDark}`,
    '&:focus': {
      outlineWidth: 0,
      boxShadow: 'none',
    },
    background: 'white',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: colors.grayDark,
    paddingRight: '15px',
    transition: 'all .2s ease',
    transform: state.isFocused ? 'rotate(180deg)' : null,
    '&:hover': {
      color: colors.grayLight2,
    },
  }),
  singleValue: (base) => ({ ...base, marginLeft: '1rem' }),
  valueContainer: (base) => ({
    ...base,
    height: '100%',
    input: { boxShadow: 'none !important' },
  }),
};

export const DoctorDetails = ({ formDefaults }) => {
  if (formDefaults) {
    return (
      <Cell>
        <p>{formDefaults.superbill_date}</p>
        <br></br>
        <p>{formDefaults.doctor_full_name}</p>
        {formDefaults.doctor_clinic?.clinic_name && (
          <p>{formDefaults.doctor_clinic?.clinic_name}</p>
        )}
        {formDefaults.doctor_clinic?.address && (
          <p>{formDefaults.doctor_clinic?.address}</p>
        )}
        {(formDefaults.doctor_clinic?.city ||
          formDefaults.doctor_clinic?.state ||
          formDefaults.doctor_clinic?.zip) && (
          <p>
            {formDefaults.doctor_clinic?.city && (
              <span>{`${formDefaults.doctor_clinic?.city}${
                formDefaults.doctor_clinic?.state ? ', ' : ''
              }`}</span>
            )}
            {formDefaults.doctor_clinic?.state && (
              <span>{`${formDefaults.doctor_clinic.state}${
                formDefaults.doctor_clinic?.zip ? ' ' : ''
              }`}</span>
            )}
            {formDefaults.doctor_clinic?.zip && (
              <span>{formDefaults.doctor_clinic?.zip}</span>
            )}
            {formDefaults.doctor_clinic?.zip && (
              <span>{formDefaults.doctor_clinic?.zip}</span>
            )}
          </p>
        )}
        {formDefaults.doctor_clinic?.phone && (
          <p>
            <span>Phone: </span>
            {formDefaults.doctor_clinic?.phone}
          </p>
        )}
        {formDefaults?.doctor_email && <p>{formDefaults?.doctor_email}</p>}
      </Cell>
    );
  }
  return;
};

export const incompleteFields = (requiredArray, values) => {
  let disabled = false;

  requiredArray.forEach((field) => {
    if (!values?.[field] || values?.[field].length === 0) {
      disabled = true;
    }
  });
  return disabled;
};

export const procedureRequiredFields = [
  'place_of_service',
  'date_of_service',
  'description',
  'units',
  'fee',
];

const SuperBillForm = ({ parentOnChange }) => {
  const [diagnosisValue, setDiagnosisValue] = useState();
  const [procedureValue, setProcedureValue] = useState();
  const [instance, setInstance] = useState(0);
  const [pdfRenderLoading, setPdfRenderLoading] = useState(false);
  const path = useLocation().pathname.split('/');
  const superbillId = path[path.length - 1];
  const history = useHistory();
  const { type } = useParams();

  const {
    formDefaults,
    isLoading: superbillLoading,
    error: superbillError,
    superbillInitialValues,
    update: updateSuperbill,
  } = useSuperbill(superbillId, true);

  const [editItemDiagnosis, setEditItemDiagnosis] = useState();
  const [editItemProcedure, setEditItemProcedure] = useState();
  const [sentSuperBill, setSentSuperBill] = useState(null);
  const [downloadedSuperBill, setDownloadedSuperBill] = useState(null);

  const { isLoading: userAuthLoading, authUser } = useContext(AuthContext);
  if (!userAuthLoading && (!authUser || !Object.keys(authUser).length)) {
    history.push('/');
  }

  const loading = userAuthLoading || pdfRenderLoading || superbillLoading;

  const requiredFields = [
    'balance_due',
    'dr_license_nr',
    'dr_ein_nr',
    'payment',
    'procedures',
    'total_charge',
  ];

  const disableSend = (requiredFields, values) => {
    const procedureIncomplete =
      (!values.procedures || values.procedures.length === 0) &&
      incompleteFields(procedureRequiredFields, procedureValue);
    return incompleteFields(requiredFields, values) && procedureIncomplete;
  };

  const PatientDetails = () => (
    <Grid className={styles.clientInfo}>
      {(superbillInitialValues?.client_first_name ||
        formDefaults?.patient_first_name) && (
        <Cell large={6} medium={6}>
          <p className={styles.mb10}>First Name</p>
          <strong>
            {superbillInitialValues.client_first_name ||
              formDefaults.patient_first_name}
          </strong>
        </Cell>
      )}
      {(superbillInitialValues?.client_last_name ||
        formDefaults?.patient_last_name) && (
        <Cell large={6} medium={6}>
          <p className={styles.mb10}>Last name</p>
          <strong>
            {superbillInitialValues?.client_last_name ||
              formDefaults?.patient_last_name}
          </strong>
        </Cell>
      )}
      {superbillInitialValues?.client_address && (
        <Cell large={5} medium={5}>
          <p className={styles.mb10}>Address</p>
          <strong>{superbillInitialValues?.client_address}</strong>
        </Cell>
      )}
      {superbillInitialValues?.client_city && (
        <Cell large={3} medium={3}>
          <p className={styles.mb10}>City</p>
          <strong>{superbillInitialValues?.client_city}</strong>
        </Cell>
      )}
      {superbillInitialValues?.client_state && (
        <Cell large={2} medium={2}>
          <p className={styles.mb10}>State</p>
          <strong>{superbillInitialValues?.client_state}</strong>
        </Cell>
      )}
      {superbillInitialValues?.client_zip_code && (
        <Cell large={2} medium={2}>
          <p className={styles.mb10}>Zip Code</p>
          <strong>{superbillInitialValues?.client_zip_code}</strong>
        </Cell>
      )}
      {superbillInitialValues?.client_cell_phone && (
        <Cell large={12} medium={12}>
          <p className={styles.mb10}>Phone #</p>
          <strong>{superbillInitialValues?.client_cell_phone}</strong>
        </Cell>
      )}
      {(superbillInitialValues?.client_email ||
        formDefaults?.patient_email) && (
        <Cell large={12} medium={12}>
          <p className={styles.mb10}>Email</p>
          <strong>
            {superbillInitialValues?.client_email ||
              formDefaults?.patient_email}
          </strong>
        </Cell>
      )}
      {superbillInitialValues?.client_dob && (
        <Cell large={12} medium={12}>
          <p className={styles.mb10}>Date of Birth</p>
          <strong>
            {moment(new Date(superbillInitialValues.client_dob)).format(
              'MM/DD/YYYY',
            )}
          </strong>
        </Cell>
      )}
    </Grid>
  );

  const formatValues = (values) => {
    if (diagnosisValue && Object.values(diagnosisValue).every(Boolean)) {
      if (!values.diagnoses) {
        values.diagnoses = [];
      }
      values.diagnoses.push({
        code: diagnosisValue.code,
        description: diagnosisValue.description,
      });
    }

    if (
      procedureValue &&
      !incompleteFields(procedureRequiredFields, procedureValue)
    ) {
      if (!values.procedures) {
        values.procedures = [];
      }
      values.procedures.push({
        place_of_service: procedureValue.place_of_service,
        date_of_service: procedureValue.date_of_service,
        cpt_code: procedureValue.cpt_code,
        description: procedureValue.description,
        units: procedureValue.units,
        modifier: procedureValue.modifier,
        fee: procedureValue.fee,
      });
    }
    delete values.diagnosis;
    delete values.procedure;

    Object.keys(values).forEach((field) => {
      values[field] = !!values[field] ? values[field] : null;
      if (superbillInitialValues[field] === undefined) {
        delete values[field];
      }
    });

    clientRequiredFields.forEach((field) => {
      delete values[field];
    });
  };

  const reset = () => {
    setEditItemProcedure();
  };

  const handleSave = async (values) => {
    formatValues(values);
    values.sent_at = null;
    delete values.patient_viewed_at;
    try {
      await updateSuperbill(values);
    } catch (error) {
      console.error(error);
    }
    setProcedureValue();
    setInstance(instance + 1);
  };

  const handleSend = async (values) => {
    formatValues(values);
    delete values.patient_viewed_at;
    try {
      const response = await sendSuperbill(superbillId, values);
      setSentSuperBill(response && response[0]);
    } catch {
      setSentSuperBill({ error: 'Something went wrong.' });
    }
  };

  const handleDownload = async (values) => {
    await handleSave(values);
    try {
      setPdfRenderLoading(true);
      const response = await downloadSuperbill(superbillId);
      setDownloadedSuperBill(response);
      setPdfRenderLoading(false);
    } catch {
      setDownloadedSuperBill({ error: "Couldn't download the PDF." });
    }
  };

  const calculateTotalCharge = (values) => {
    const { procedures } = values;
    let total = 0;

    // Calculate total fees from the added procedures
    if (procedures) {
      total = Object.values(procedures).reduce(function (acc, procedure) {
        return acc + parseFloat(procedure.units) * parseFloat(procedure.fee);
      }, 0);
    }

    // Calculate total fees from the procedure add form hanging data
    if (!incompleteFields(procedureRequiredFields, procedureValue)) {
      total +=
        parseFloat(procedureValue.units) * parseFloat(procedureValue.fee);
    }
    values.total_charge = total.toFixed(2).toString();
    return values.total_charge;
  };

  const calculateBalanceDue = (values) => {
    const paymentVal = isNaN(parseFloat(values.payment))
      ? 0
      : parseFloat(values.payment);
    const result = parseFloat(calculateTotalCharge(values)) - paymentVal;
    values.balance_due = result.toFixed(2).toString();
    return values.balance_due;
  };

  if (loading) {
    return <Loading />;
  }

  if (superbillError) {
    return 'Something went wrong. Please reload the page and try again.';
  }

  if (type === USER_TYPES.CLINICIAN) {
    return (
      <div className={styles.formWrapper}>
        <Formik
          enableReinitialize={true}
          onSubmit={async (values) => {
            handleSend(values);
          }}
          initialValues={superbillInitialValues}
        >
          {({ values, handleChange, handleBlur, errors, touched, isValid }) => {
            const handlePaymentOnBlur = () => {
              if (values.payment) {
                values.payment = values?.payment?.toFixed(2);
              }
              handleBlur();
            };
            const handleCustomChange = (e) => {
              parentOnChange && parentOnChange(e, isValid);
              handleChange(e);
            };

            return (
              <div className={styles.superBillForm}>
                <img
                  className={styles.logo}
                  src={Logo}
                  alt="logo-eve"
                  data-testid="logo image"
                />
                <h1>Superbill</h1>
                <Form data-testid="clinician-form">
                  <Grid>
                    <DoctorDetails formDefaults={formDefaults} />
                    <Cell large={12}>
                      <h2 className={styles.formTitle}>Fill in your details</h2>
                    </Cell>
                    <fieldset>
                      <legend>Provider Details</legend>
                      <Cell large={12}>
                        <FieldDropdown
                          className={`${styles.selectDropdown} ${
                            values.dr_license_nr ? styles.touched : ''
                          }`}
                          customStyles={dropdownStyles}
                          placeholder={'Enter here.'}
                          label={'License # - state'}
                          errorText={
                            touched.dr_license_nr && errors.dr_license_nr
                          }
                          autoFocus
                          name="dr_license_nr"
                          value={values.dr_license_nr}
                          options={parseSelectOptions(
                            formDefaults.options.doctor_licenses,
                          )}
                          onChange={handleCustomChange}
                          onBlur={handleBlur}
                          dataTestId="Superbill.Doctor.License"
                        />
                      </Cell>
                      <Cell large={12}>
                        <FieldInput
                          placeholder={'Enter here.'}
                          label={'EIN/TIN #'}
                          errorText={touched.dr_ein_nr && errors.dr_ein_nr}
                          name="dr_ein_nr"
                          value={values.dr_ein_nr}
                          onChange={handleCustomChange}
                          onBlur={handleBlur}
                        />
                      </Cell>
                      <Cell large={12}>
                        <FieldInput
                          placeholder={'Enter here.'}
                          label={'NPI #'}
                          errorText={touched.dr_npi_nr && errors.dr_npi_nr}
                          name="dr_npi_nr"
                          value={values.dr_npi_nr}
                          onChange={handleCustomChange}
                          onBlur={handleBlur}
                        />
                      </Cell>
                      <Cell large={12}>
                        <TextAreaInputWithLabel
                          label={'Additional Notes'}
                          errorText={touched.dr_notes && errors.dr_notes}
                          name="dr_notes"
                          value={values.dr_notes}
                          onChange={handleCustomChange}
                          placeholder={'Enter here.'}
                          onBlur={handleBlur}
                        />
                      </Cell>
                    </fieldset>
                    <fieldset>
                      <legend>Client information</legend>
                      <PatientDetails />
                    </fieldset>

                    <FieldArray
                      name="diagnoses"
                      render={({ remove, replace, push }) => (
                        <>
                          <DiagnosisForm
                            push={push}
                            diagnosisValue={
                              !editItemDiagnosis ? diagnosisValue : null
                            }
                            setDiagnosisValue={setDiagnosisValue}
                          />
                          {values.diagnoses?.length > 0 && (
                            <AddedItem
                              displayFields={['code', 'description']}
                              replace={replace}
                              remove={remove}
                              items={values.diagnoses}
                              maxLength={10}
                              setEditItem={setEditItemDiagnosis}
                            />
                          )}
                          {/* Edit Modal */}
                          {!!editItemDiagnosis && !editItemDiagnosis.delete && (
                            <Modal className={styles.mainContainer}>
                              <SecondaryButton
                                className={styles.closeButton}
                                onClick={() => {
                                  setEditItemDiagnosis();
                                  setDiagnosisValue();
                                }}
                                label={'Close'}
                              />
                              <DiagnosisForm
                                diagnosisValue={diagnosisValue}
                                setDiagnosisValue={setDiagnosisValue}
                                closeModal={setEditItemDiagnosis}
                                editItemDiagnosis={editItemDiagnosis}
                                replace={replace}
                                setEditItemDiagnosis={setEditItemDiagnosis}
                              />
                            </Modal>
                          )}
                          {!!editItemDiagnosis && editItemDiagnosis.delete && (
                            <SuperBillMessageModal type="error">
                              <h1>Deleting Diagnosis</h1>
                              <p>Are you sure do you want to proceed?</p>
                              <div className={styles.buttonsWrapperDelete}>
                                <SecondaryButton
                                  className={styles.cancel}
                                  onClick={() => {
                                    setEditItemDiagnosis(null);
                                  }}
                                  label={'Cancel'}
                                />
                                <PrimaryButton
                                  label="Yes"
                                  onClick={() => {
                                    remove(editItemDiagnosis.index);
                                    setEditItemDiagnosis(null);
                                  }}
                                />
                              </div>
                            </SuperBillMessageModal>
                          )}
                        </>
                      )}
                    />
                    <FieldArray
                      name="procedures"
                      render={({ remove, replace, push }) => (
                        <>
                          <ProcedureForm
                            procedureValue={
                              !editItemProcedure ? procedureValue : {}
                            }
                            formDefaults={formDefaults}
                            setProcedureValue={setProcedureValue}
                            styles={styles}
                            dropdownStyles={dropdownStyles}
                            onBlur={handleBlur}
                            push={push}
                            handleChange={handleCustomChange}
                            touched={touched}
                            errors={errors}
                            placesOptions={parseSelectOptions(
                              formDefaults.options.places_of_service,
                            )}
                            instance={instance}
                            setInstance={setInstance}
                          />
                          {values.procedures?.length > 0 && (
                            <AddedItem
                              replace={replace}
                              remove={remove}
                              items={values.procedures}
                              displayFields={['cpt_code', 'description']}
                              maxLength={10}
                              setEditItem={setEditItemProcedure}
                            />
                          )}
                          {/* Edit Modal */}
                          {!!editItemProcedure && !editItemProcedure.delete && (
                            <Modal
                              key={instance}
                              className={styles.mainContainer}
                            >
                              <SecondaryButton
                                className={styles.closeButton}
                                onClick={reset}
                                label={'Close'}
                              />
                              <ProcedureForm
                                handleCustomChange={handleCustomChange}
                                instance={instance}
                                formDefaults={formDefaults}
                                setInstance={setInstance}
                                procedureValue={procedureValue}
                                setProcedureValue={setProcedureValue}
                                closeModal={setEditItemProcedure}
                                styles={styles}
                                dropdownStyles={dropdownStyles}
                                onBlur={handleBlur}
                                placesOptions={parseSelectOptions(
                                  formDefaults.options.places_of_service,
                                )}
                                setEditItemProcedure={setEditItemProcedure}
                                editItemProcedure={editItemProcedure}
                                replace={replace}
                              />
                            </Modal>
                          )}

                          {!!editItemProcedure && editItemProcedure.delete && (
                            <SuperBillMessageModal type="error">
                              <h1>Deleting Procedure</h1>
                              <p>Are you sure do you want to proceed?</p>
                              <div className={styles.buttonsWrapperDelete}>
                                <SecondaryButton
                                  className={styles.cancel}
                                  onClick={() => {
                                    setInstance(instance + 1);
                                    setEditItemProcedure(null);
                                  }}
                                  label={'Cancel'}
                                />
                                <PrimaryButton
                                  label="Yes"
                                  onClick={() => {
                                    remove(editItemProcedure.index);
                                    setEditItemProcedure(null);
                                  }}
                                />
                              </div>
                            </SuperBillMessageModal>
                          )}
                        </>
                      )}
                    />
                    <div className={styles.paymentLabel}>
                      <Cell large={12}>
                        <FieldInput
                          onChange={Function.prototype}
                          label={'Total charge'}
                          errorText={
                            touched.total_charge && errors.total_charge
                          }
                          name="total_charge"
                          value={calculateTotalCharge(values)}
                          onBlur={handleBlur}
                          type="number"
                          placeholder={'Enter here.'}
                        />
                      </Cell>
                      <Cell large={12}>
                        <FieldInput
                          label={'Payments'}
                          errorText={touched.payment && errors.payment}
                          name="payment"
                          value={values?.payment?.toString()}
                          onChange={handleCustomChange}
                          onBlur={handlePaymentOnBlur}
                          type="number"
                          placeholder={'Enter here.'}
                        />
                      </Cell>
                      <Cell large={12}>
                        <FieldInput
                          onChange={Function.prototype}
                          label={'Balance Due'}
                          errorText={touched.balance_due && errors.balance_due}
                          name="balance_due"
                          value={calculateBalanceDue(values)}
                          onBlur={handleBlur}
                          type="number"
                          placeholder={'Enter here.'}
                        />
                      </Cell>
                    </div>
                    <Cell>
                      <div className={styles.buttonsWrapper}>
                        <PrimaryButton
                          type="button"
                          data-testid="save_superbill"
                          label={'Save'}
                          onClick={() => handleSave(values)}
                        />
                        <SecondaryButton
                          data-testid="send_superbill"
                          type="submit"
                          disabled={disableSend(requiredFields, values)}
                          label={'Send'}
                        />
                      </div>
                    </Cell>
                    <Cell
                      small={12}
                      flexContainer
                      alignY="middle"
                      flexDirCol="all"
                    >
                      <strong>
                        <a
                          className={styles.viewPdf}
                          onClick={() => handleDownload(values)}
                        >
                          Download PDF
                        </a>
                      </strong>
                    </Cell>
                  </Grid>
                  {sentSuperBill && (
                    <SuperBillMessageModal
                      type={!sentSuperBill?.error ? 'success' : 'error'}
                    >
                      <h1>
                        {sentSuperBill?.error
                          ? 'Error'
                          : 'Superbill Notification Sent'}
                      </h1>
                      <p>
                        {sentSuperBill.error || 'We have notified the client.'}
                      </p>
                      <div className={styles.buttonsWrapperDelete}>
                        <PrimaryButton
                          label="Ok"
                          onClick={() => {
                            history.push('/doctor/appointments');
                          }}
                        />
                      </div>
                    </SuperBillMessageModal>
                  )}
                  {downloadedSuperBill && (
                    <SuperBillMessageModal
                      type={!downloadedSuperBill?.error ? 'success' : 'error'}
                    >
                      <h1>
                        {downloadedSuperBill?.error
                          ? downloadedSuperBill?.error
                          : 'Download completed successfully!'}
                      </h1>
                      <div className={styles.buttonsWrapperDelete}>
                        <PrimaryButton
                          label="Ok"
                          onClick={() => {
                            history.push(
                              `/doctor/superbill/clinician/${superbillId}`,
                            );
                          }}
                        />
                      </div>
                    </SuperBillMessageModal>
                  )}
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
    );
  }
  return (
    <PatientSuperBillForm
      formDefaults={formDefaults}
      superbillInitialValues={superbillInitialValues}
      superbillId={superbillId}
    />
  );
};

SuperBillForm.propTypes = {
  parentOnChange: PropTypes.func,
};

export default SuperBillForm;
