import React, { useState, Fragment, useContext, useEffect } from 'react';
import styles from './FormsPage.module.scss';
import classnames from 'classnames';
import Loading from '../../../components/common/Loading';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import AdminLeftNav from '../../../components/nav/AdminLeftNav';
import AuthContext from '../../../context/AuthContext';
import { Formik, Form } from 'formik';
import { Cell, Grid } from 'react-foundation';
import * as Yup from 'yup';
import MessageModal from '../../../components/MessageModal';
import FieldDropdown from '../../../components/common/fields/FieldDropdown';
import { addForm } from '../../../api/intakeForm';
import FieldInput from '../../../components/common/fields/FieldInput';
import { INTAKE_OPTIONS } from '../../../constants';

const alphabetizeIntakeOptions = INTAKE_OPTIONS.sort((a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
});

const FormsSchema = Yup.object().shape({
  form_name: Yup.string('Form name must be in alphabets.').required(
    'Field is required',
  ),
  form_type: Yup.string('Form name must be in alphabets.').required(
    'Field is required',
  ),
  template_name: Yup.string().when('form_type', {
    is: (value) => value === 'consent',
    then: Yup.string().required('Field is required'),
  }),
  assessment_key: Yup.string().when('form_type', {
    is: (value) => value === 'assessment',
    then: Yup.string().required('Field is required'),
  }),
});

const formTypeOptions = [
  { label: 'Intake', value: 'intake' },
  { label: 'Consent', value: 'consent' },
  { label: 'Assessment', value: 'assessment' },
];

const FormsPage = () => {
  const { isLoading, authUser } = useContext(AuthContext);

  const [dataLoading, setLoading] = useState(false);
  const isDataLoading = dataLoading || isLoading;
  const [initialValues, setInitialValues] = useState({
    form_name: '',
    form_type: '',
    template_name: '',
    assessment_key: '',
    intake_type: '',
  });
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [displayMessage, setDisplayMessage] = useState({
    title: '',
    message: '',
  });

  const handleOnSubmit = async (values) => {
    setLoading(true);
    try {
      const params = {
        form_name: values.form_name,
        form_type: values.form_type,
        template_name: null,
        assessment_key: null,
        intake_type: values.intake_type || null,
      };
      if (values.form_type === 'assessment') {
        params.assessment_key = values.assessment_key;
        params.template_name = null;
      } else if (values.form_type === 'consent') {
        params.template_name = values.template_name;
        params.assessment_key = null;
      }

      await addForm(params);
      setInitialValues({
        form_name: '',
        form_type: '',
        template_name: '',
        assessment_key: '',
        intake_type: '',
      });
      setLoading(false);
      setDisplayMessage({
        type: 'success',
        title: 'Form added successfully',
        text: '',
      });
      setShowMessageModal(true);
    } catch (error) {
      setLoading(false);
      setDisplayMessage({
        type: 'error',
        title: '',
        text: 'Failed to add new form',
      });
      setShowMessageModal(true);
    }
  };

  useEffect(() => {}, [authUser]);

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftNav, 'desktop-only')}>
        <AdminLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div className={styles.header}>
              <h1>Forms</h1>
            </div>
            <div className={styles.formData}>
              <Formik
                initialValues={{ ...initialValues }}
                validationSchema={FormsSchema}
                onSubmit={handleOnSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => {
                  return (
                    <Form>
                      <Grid>
                        <Cell small={12}>
                          <FieldInput
                            label="Form Name"
                            errorText={touched.form_name && errors.form_name}
                            name="form_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.form_name}
                            data-testid="form name"
                            label-testid="form name label"
                            error-testid="form name error"
                          />
                        </Cell>
                        <Cell small={12}>
                          <FieldDropdown
                            label="Form Type"
                            name="form_type"
                            errorText={touched.form_type && errors.form_type}
                            value={values.form_type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={formTypeOptions}
                            placeholder="Select Form Type"
                            dataTestId="FormsPage.FormType.Dropdown"
                          />
                        </Cell>
                        {values.form_type === 'intake' && (
                          <Cell small={12}>
                            <FieldDropdown
                              label="Intake Type"
                              name="intake_type"
                              errorText={
                                touched.intake_type && errors.intake_type
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.intake_type}
                              data-testid="intake_type"
                              label-testid="intake_type label"
                              error-testid="intake_type error"
                              options={alphabetizeIntakeOptions}
                            />
                          </Cell>
                        )}
                        {values.form_type === 'assessment' && (
                          <Cell small={12}>
                            <FieldInput
                              label="Assessment Key"
                              errorText={
                                touched.assessment_key && errors.assessment_key
                              }
                              name="assessment_key"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.assessment_key}
                              data-testid="assessment key"
                              label-testid="assessment key label"
                              error-testid="assessment key error"
                            />
                          </Cell>
                        )}
                        {values.form_type === 'consent' && (
                          <Cell small={12}>
                            <FieldInput
                              label="Template Name"
                              errorText={
                                touched.template_name && errors.template_name
                              }
                              name="template_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.template_name}
                              data-testid="template name"
                              label-testid="template name label"
                              error-testid="template name error"
                            />
                          </Cell>
                        )}
                        <Cell small={12}>
                          <PrimaryButton
                            label="Add Form"
                            className={classnames(styles.saveButton, 'font-18')}
                            disabled={Object.entries(errors).length > 0}
                            data-testid="add form button"
                            onClick={handleSubmit}
                          />
                        </Cell>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </Fragment>
      )}
      {showMessageModal && (
        <MessageModal
          message={displayMessage}
          onCloseModal={() => setShowMessageModal(false)}
        />
      )}
    </div>
  );
};

export default FormsPage;
