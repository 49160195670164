import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import colors from '../../../../styles/_colors.scss';
import Label from '../../Label';
import errorStyles from '../../Label/Label.module.scss';

const styles = {
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  control: (base) => ({
    ...base,
    borderRadius: '1.625rem',
    width: '100%',
    height: '3.25rem',
    boxShadow: 0,
    border: `1px solid ${colors.grayLight2}`,
    '&:hover': {
      border: `1px solid ${colors.grayLight2}`,
    },
    '&:focus': {
      outlineWidth: 0,
      boxShadow: 'none',
      border: 'none',
    },
    background: 'white',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: colors.grayLight2,
    paddingRight: '0.5rem',
    transition: 'all .2s ease',
    transform: state.isFocused ? 'rotate(180deg)' : null,
    '&:hover': {
      color: colors.grayLight2,
    },
  }),
  singleValue: (base) => ({ ...base, marginLeft: '1rem' }),
  multiValue: () => ({
    height: 'fit-content',
    marginLeft: '1rem',
    height: 'fit-content',
    backgroundColor: colors.grayBackground,
    padding: '3px',
  }),
  valueContainer: (base) => ({
    ...base,
    input: {
      boxShadow: 'none !important',
      marginLeft: '1rem',
      height: 'fit-content',
      alignItems: 'flex-start',
    },
  }),
  placeholder: (base) => ({
    ...base,
    paddingLeft: '1rem',
  }),
};

const SelectContainer = (props) => {
  return (
    <components.SelectContainer
      {...props}
      innerProps={Object.assign({}, props.innerProps, {
        'data-testid': props.selectProps.dataTestId,
      })}
    />
  );
};

const Input = (props) => {
  const { maxLength } = props.selectProps;
  return <components.Input {...props} maxLength={maxLength} />;
};

const Option = (props) => (
  <components.Option
    {...props}
    innerProps={Object.assign({}, props.innerProps, {
      'data-testid': props.data.label,
    })}
  />
);

const FieldDropdownWithInput = ({
  label,
  errorText,
  value,
  onChange,
  onBlur,
  options,
  placeholder,
  className,
  customStyles,
  dataTestId,
  maxLength,
  isSearchable,
  isMulti,
  uniqueKey,
  ...props
}) => {
  return (
    <div>
      <Label
        id={dataTestId}
        errorText={errorText}
        dataTestId={dataTestId}
        htmlFor={`select_${dataTestId}`}
      >
        {label}
      </Label>
      <CreatableSelect
        {...props}
        key={`${dataTestId}${uniqueKey}`}
        formatCreateLabel={(value) => <span>Add "{value}"</span>}
        isMulti={isMulti}
        className={className || ''}
        isSearchable={isSearchable}
        id={`select_${dataTestId}`}
        aria-label={dataTestId}
        aria-labelledby={dataTestId}
        options={options}
        blurInputOnSelect={true}
        maxLength={maxLength}
        dataTestId={dataTestId}
        onChange={onChange}
        value={options.find((option) => option.value === value)}
        styles={customStyles || styles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: colors.grayLight2,
            primary: colors.black,
            primary50: colors.grayLight2,
          },
        })}
        components={{
          SelectContainer,
          Option,
          Input,
          IndicatorSeparator: () => null,
        }}
        placeholder={placeholder}
      />
      <div className={errorStyles['error-label']}>{errorText}</div>
    </div>
  );
};

FieldDropdownWithInput.propTypes = {
  label: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  dataTestId: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.any,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  customStyles: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
};

export default FieldDropdownWithInput;
