import { defineMessages } from 'react-intl';

export default defineMessages({
  topImage: {
    id: 'pages.notFoundPage.topImage',
    description:
      "Alternative message for 404 error image when it can't be displayed",
    defaultMessage: 'Error 404. Page Not Found.',
  },
  title: {
    id: 'pages.notFoundPage.title',
    description: 'The typical surprise exclamation when a page is not found',
    defaultMessage: 'whoops!',
  },
  explanation: {
    id: 'pages.notFoundPage.explanation',
    description: 'The explanation message of how did you get a 404 page',
    defaultMessage:
      'The page you requested could not be found! Any chance you were looking for one of these?',
  },
  discover: {
    id: 'pages.notFoundPage.discover',
    description: 'The message for the Discover link',
    defaultMessage: 'Discover',
  },
  shopAll: {
    id: 'pages.notFoundPage.shopAll',
    description: 'The message for the Shop All link',
    defaultMessage: 'Shop All',
  },
});
