import React, { Fragment, useEffect } from 'react';

const NylasThankYou = () => {
  const notifyParent = () => {
    const params = new URLSearchParams(window.location.search);
    const paramObj = {};
    for (var value of params.keys()) {
      paramObj[value] = params.get(value);
    }
    const event = new CustomEvent('onScheduleComplete', { detail: paramObj });
    window.parent.document.dispatchEvent(event);
  };
  useEffect(() => {
    notifyParent();
  }, []);
  return (
    <div>
      <Fragment>
        <div>Thank you. Your appointment is being confirmed.</div>
      </Fragment>
    </div>
  );
};

export default NylasThankYou;
