import React, { useState, Fragment, useContext, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { Cell } from 'react-foundation';
import * as Yup from 'yup';
import classnames from 'classnames';

import styles from './InviteClinicianPage.module.scss';

import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import FieldInput from '../../../components/common/fields/FieldInput/FieldInput';
import FieldDropdown from '../../../components/common/fields/FieldDropdown';
import Loading from '../../../components/common/Loading';
import AdminLeftNav from '../../../components/nav/AdminLeftNav';
import MessageModal from '../../../components/MessageModal';

import AuthContext from '../../../context/AuthContext';

import { inviteClinician, getUnclaimClinics } from '../../../api/doctor';

import { INVITED_CLINICIAN_INVITED_STATUS } from '../../../constants';

const ClinicSchema = Yup.object().shape({
  clinic_id: Yup.string().required('Field Required'),
  firstName: Yup.string().required('Field Required'),
  email: Yup.string().required('Field Required'),
});

const InviteClinicianPage = () => {
  const { authUser } = useContext(AuthContext);

  const [error, setError] = useState('');
  const [dataLoading, setLoading] = useState(false);
  const [clinicsLists, setClinicLists] = useState([]);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [displayMessage, setDisplayMessae] = useState({
    title: '',
    message: '',
  });

  const fetchClinics = async () => {
    const { clinics } = await getUnclaimClinics();
    const formattedClinics = clinics.map((clinic) => {
      return {
        label: clinic.clinic_name,
        value: clinic.id,
      };
    });
    formattedClinics.unshift({
      label: 'None',
      value: 0,
    });
    setClinicLists(formattedClinics);
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);

      const { email, firstName, clinic_id } = values;

      await inviteClinician({
        email,
        first_name: firstName,
        clinic_id: clinic_id || null,
        is_owner: clinic_id ? true : false,
      });

      setLoading(false);

      setDisplayMessae({
        type: 'success',
        title: 'Invitation sent successfully',
        text: '',
      });
      setShowMessageModal(true);
    } catch (error) {
      let errorMsg = '';

      if (error?.response?.data?.message) {
        errorMsg = error.response.data.message;
      }

      setError(errorMsg);
      setLoading(false);

      setDisplayMessae({
        type: 'error',
        title: '',
        text: errorMsg,
      });
      setShowMessageModal(true);
    }
  };

  useEffect(() => {
    if (authUser.id) {
      fetchClinics();
    }
  }, [authUser]);

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <AdminLeftNav />
      </div>
      {dataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.lineArt}>
            <img src={require(`../../../img/LineArt.svg`)} alt="line-art" />
          </div>
          <div className={styles.contentDiv}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            ></div>

            <Formik
              initialValues={{ email: '', firstName: '', clinic_id: '' }}
              validationSchema={ClinicSchema}
              onSubmit={async (values) => {
                handleSubmit(values);
              }}
            >
              {({
                errors,
                handleChange,
                values,
                touched,
                handleBlur,
                setFieldValue,
                isValid,
              }) => {
                return (
                  <Form className={styles['content']}>
                    <div className={classnames(styles[`form-div`])}>
                      <h2>Invite Clinician</h2>

                      <div className={styles.formInput}>
                        <div className={styles.clinicList}>
                          <Cell small={6} medium={3}>
                            <FieldDropdown
                              label="Clinic Owned"
                              name="clinic_id"
                              errorText={touched.clinic_id && errors.clinic_id}
                              value={values.clinic_id}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={clinicsLists}
                              placeholder="Select Clinic"
                              dataTestId="InviteClinician.Clinic.Dropdown"
                            />
                          </Cell>
                        </div>
                        <Cell small={12} medium={12}>
                          <FieldInput
                            label="First Name"
                            className={styles.input}
                            name="firstName"
                            errorText={touched.firstName && errors.firstName}
                            onChange={(e) => {
                              setFieldValue('firstName', e.target.value);
                            }}
                            onBlur={handleBlur}
                            value={values.firstName}
                            placeholder="First Name"
                          />
                        </Cell>
                        <Cell small={12} medium={12}>
                          <FieldInput
                            label="email"
                            className={styles.input}
                            name="email"
                            errorText={touched.email && errors.email}
                            onChange={(e) => {
                              setError('');
                              setFieldValue('email', e.target.value);
                            }}
                            onBlur={handleBlur}
                            value={values.email}
                            placeholder="Clinician's Email Address"
                          />
                        </Cell>
                      </div>
                      {error && <div className={styles.error}>{error}</div>}
                      <div className={classnames(styles[`button-row`])}>
                        <PrimaryButton
                          label="Invite"
                          className={classnames(styles[`button`])}
                          disabled={!isValid}
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Fragment>
      )}
      {showMessageModal && (
        <MessageModal
          message={displayMessage}
          onCloseModal={() => setShowMessageModal(false)}
        />
      )}
    </div>
  );
};

export default InviteClinicianPage;
