import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './PatientList.module.scss';
import { addToDataLayer } from '../../utils/common';
import SecondaryButton from '../common/buttons/SecondaryButton';
import PrimaryButton from '../common/buttons/PrimaryButton';
import AuthContext from '../../context/AuthContext';
import { PATIENT_DETAILS_STATUS } from '../../constants';

const PatientList = ({ item }) => {
  const history = useHistory();
  const { authUser } = useContext(AuthContext);

  const firstName = item?.patient?.first_name ? item.patient.first_name : '';
  const lastName = item?.patient?.last_name ? item.patient.last_name : '';
  const profilePic = item?.patient?.profile_pic
    ? item.patient.profile_pic
    : require('../../img/patient.jpg');

  return (
    <div className={styles.root}>
      <div className={styles.patientBox}>
        <div className={styles.profilePic}>
          <img
            src={profilePic}
            alt="patient_profile"
            data-testid={`Patient.Card.ProfilePic.${firstName}${lastName}`}
          />
        </div>
        <div className={styles.detail}>
          <div className={styles.name}>
            <h4 data-testid={`Patient.Card.Name.${firstName}${lastName}`}>
              {`${firstName} ${lastName}`}
            </h4>
          </div>
          {item.patient &&
          item.patient.appointments &&
          item.patient.appointments.id ? (
            <div
              data-testid="Patient.Card.StatusType.Returning"
              className={styles.returning}
            >
              Returning
            </div>
          ) : (
            <div
              data-testid="Patient.Card.StatusType.New"
              className={styles.new}
            >
              New
            </div>
          )}
        </div>
        <div className={styles.buttons}>
          <PrimaryButton
            label="Schedule"
            className={styles.schedule}
            disabled={item.patient.status !== PATIENT_DETAILS_STATUS.ACTIVE}
            onClick={() =>
              history.push(
                `/doctor/appointment/${item.patient.id}/clinician/${authUser.id}`,
              )
            }
            data-testid={`Patient.Card.Button.Schedule.${firstName}${lastName}`}
          />
          <SecondaryButton
            label="View Profile"
            onClick={() => {
              const eventData = {
                event: 'ga4_event',
                properties: {
                  event_name: 'view_patient_profile',
                  patient_id: item.patient.id,
                },
              };
              addToDataLayer(eventData);
              history.push(`/doctor/patient-details/${item.patient.id}`);
            }}
            data-testid={`Patient.Card.Button.ViewProfile.${firstName}${lastName}`}
          />
        </div>
      </div>
    </div>
  );
};
export default PatientList;
