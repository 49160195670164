import React, { useState, Fragment, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Cell, Grid } from 'react-foundation';
import { Formik, Form } from 'formik';

import styles from './DoctorNewAppointmentPage.module.scss';

import FieldDropdown from '../../../components/common/fields/FieldDropdown';
import FieldInput from '../../../components/common/fields/FieldInput/FieldInput';
import RadioChildrenSelector from '../../../components/common/RadioChildrenSelector';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import Modal from '../../../components/common/Modal';
import Loading from '../../../components/common/Loading';
import Calendly from '../../../components/Calendly';
import Progress from '../../../components/Progress';
import NylasScheduler from '../../../components/NylasScheduler';
import MessageModal from '../../../components/MessageModal';

import {
  createPreBookingAppointment,
  getAppointmentByPrebooking,
} from '../../../api/appointment';
import { getDoctor, getBookingTypes } from '../../../api/doctor';
import { getUserProfile } from '../../../api/user';

import { addToDataLayer } from '../../../utils/common';
import { requiredValidator } from '../../../utils/validators';

import {
  APPOINTEMNT_URLS,
  APPOINTEMNT_STATUS,
  CALENDAR_TYPES,
} from '../../../constants';
import messages from './messages';
import SquareupContext from '../../../context/SquareupContext';
import AuthContext from '../../../context/AuthContext';
import useIntl from '../../../hooks/useIntl';

const fieldValidators = ({ booking_type, patient_name }) => ({
  ...requiredValidator(booking_type, 'booking_type'),
  ...requiredValidator(patient_name, 'patient_name'),
});

const { REACT_APP_NYLAS_FEATURE_FLAG } = process.env;

const DoctorNewAppointmentPage = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { patientId, clinicianId } = useParams();

  const { authUser } = useContext(AuthContext);
  const { dispatch } = useContext(SquareupContext);

  const [doctor, setDoctor] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [appointmentType, setAppointmentType] = useState(0);
  const [amount, setAmount] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showCalendly, setShowCalendly] = useState(false);
  const [appointmentObj, setAppointmentObj] = useState({});
  const [, setPaymentObj] = useState({});
  const [calendlyParams, setCalendlyParams] = useState('');
  const [profile, setProfile] = useState({});
  const [isAppointmentConfirmed, setIsAppointmentConfirmed] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [bookingTypeLabel, setBookingTypeLabel] = useState('');
  const [bookingAmountLabel, setBookingAmountLabel] = useState('');
  const [bookingTypes, setBookingTypes] = useState([]);
  const [calendlyUrl, setCalendlyUrl] = useState('');
  const [error] = useState('');
  const [showPayment] = useState(false);
  const [, setUuid] = useState('');
  const [percent, setPercent] = useState(1);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [displayMessage, setDisplayMessage] = useState({
    title: '',
    message: '',
    buttonLabel: '',
    redirectUrl: '',
  });

  const fetchDoctor = async () => {
    if (clinicianId) {
      try {
        const resp = await getDoctor(clinicianId);
        setDoctor(resp.doctor);
        setPaymentObj({
          doctor_id: clinicianId,
        });
        const bookingType =
          REACT_APP_NYLAS_FEATURE_FLAG === 'true'
            ? CALENDAR_TYPES.NYLAS
            : CALENDAR_TYPES.CALENDLY;
        const { bookingTypes: savedBookingTypes } = await getBookingTypes(
          clinicianId,
          bookingType,
        );
        const formattedBookingTypes = savedBookingTypes
          .filter((bookingType) => !!bookingType.available_for_doctor)
          .map((bookingType) => {
            return {
              ...bookingType,
              label: `${bookingType.name} $${bookingType.amount} (${bookingType.duration}min)`,
              value: bookingType.id,
            };
          });
        const progress = (percent * 100) / 2;
        setPercent(progress);
        setStep1(true);
        setBookingTypes(formattedBookingTypes);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch({ type: 'LOADING', payload: true });

    fetchDoctor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser.id]);

  const fetchProfile = async () => {
    if (authUser.id) {
      try {
        const { patient } = await getUserProfile(patientId);
        setProfile(patient);
        const patient_name = `${patient.first_name ? patient.first_name : ''}${
          patient.last_name ? ` ${patient.last_name}` : ''
        }`;
        setInitialValues({
          patient_type: 'New',
          patient_name,
          patient_phone: '',
        });
        setAppointmentObj({
          ...initialValues,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const onSubmit = async (values) => {
    try {
      const calendlyParamsObj = {
        ...appointmentObj,
        ...values,
        doctor_id: clinicianId,
        patient_id: patientId,
        clinic_id: (doctor && doctor.clinics && doctor.clinics.id) || 0,
        has_assessment: profile.has_assessment,
        status: APPOINTEMNT_STATUS.UNPAID,
        amount: amount,
      };
      if (REACT_APP_NYLAS_FEATURE_FLAG === 'true') {
        calendlyParamsObj.booking_type_label = bookingTypeLabel;
      }

      const preBookingObj = {
        patient_id: patientId,
        doctor_id: clinicianId,
        patient_type: calendlyParamsObj.patient_type,
        patient_name: calendlyParamsObj.patient_name,
        booking_type: calendlyParamsObj.booking_type,
        booking_type_label: bookingTypeLabel,
        has_assessment: calendlyParamsObj.has_assessment,
        patient_phone: profile.phone ? profile.phone : '',
        patient_email: profile.email,
        amount,
        status: APPOINTEMNT_STATUS.UNPAID,
      };

      const { prebooking } = await createPreBookingAppointment(preBookingObj);
      if (REACT_APP_NYLAS_FEATURE_FLAG === 'true') {
        setCalendlyParams(
          `prefilled_readonly=true&prebooking_id=${prebooking.id}&name=${profile.first_name}&email=${profile.email}`,
        );
      } else {
        setCalendlyParams(
          `name=${profile.first_name}&email=${
            profile.email
          }&utm_source=appointment&utm_content=${JSON.stringify(
            calendlyParamsObj,
          )}`,
        );
      }

      setTimeout(() => {
        setShowCalendly(true);
      }, 500);
    } catch (e) {
      setIsLoading(false);
      let errorMsg = '';
      if (e?.response?.data?.message) {
        errorMsg = e.response.data.message;
      }
      setDisplayMessage({
        type: 'error',
        title: '',
        text:
          errorMsg && errorMsg !== ''
            ? errorMsg
            : 'Something went wrong. Try again later',
      });
      setIsAppointmentConfirmed(true);
    }
  };

  const isCalendlyEvent = (e) => {
    return e.data.event && e.data.event.indexOf('calendly') === 0;
  };

  const onWindowMessage = (e) => {
    if (isCalendlyEvent(e)) {
      if (e.data.event === 'calendly.event_scheduled') {
        const calendlyUrl = e.data.payload.event.uri;
        const data = calendlyUrl.split('/');
        const calendlyUuid = data[data.length - 1];
        const eventData = {
          event: 'ga4_event',
          properties: {
            event_name: 'book_appointment',
            step_name: 'date_time_confirmed',
            step_number: '6',
          },
        };
        addToDataLayer(eventData);
        setUuid(calendlyUuid);
        setDisplayMessage({
          type: 'sucess',
          title: 'Appointment',
          text: 'Appointment Booked',
          buttonLabel: 'OK',
          redirectUrl: APPOINTEMNT_URLS.DOCTOR,
        });
        setIsAppointmentConfirmed(true);
      }
    }
  };

  const handleEvent = async (e) => {
    try {
      const params = e.detail;
      const eventData = {
        event: 'ga4_event',
        properties: {
          event_name: 'book_appointment',
          step_name: 'date_time_confirmed',
          step_number: '6',
        },
      };
      addToDataLayer(eventData);

      /**
       * wait for n sek for an appointment to be created by Nylas webhook
       */
      let isAppoitmentSuccessfullyCreated = false;
      async function waitForNewAppointment(attemptCount) {
        try {
          const preNewAppointment = await getAppointmentByPrebooking(
            params.prebooking_id,
          );
          isAppoitmentSuccessfullyCreated = true;
        } catch (err) {
          if (err?.response?.status === 404 && attemptCount < 30) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            await waitForNewAppointment(++attemptCount);
          } else {
            throw err;
          }
        }
      }
      await waitForNewAppointment(1);

      if (!isAppoitmentSuccessfullyCreated) {
        throw new Error(
          'Time has exceeded for waiting an appointment to be created',
        );
      }

      setUuid(e.detail.event_id);
      setDisplayMessage({
        type: 'sucess',
        title: 'Appointment',
        text: 'Appointment Booked',
        buttonLabel: 'OK',
        redirectUrl: APPOINTEMNT_URLS.DOCTOR,
      });
      setIsAppointmentConfirmed(true);
    } catch (error) {
      let errorMsg = '';
      if (error?.response?.data?.message) {
        errorMsg = error.response.data.message;
      }
      setIsLoading(false);
      setDisplayMessage({
        type: 'error',
        title: '',
        text:
          errorMsg && errorMsg !== ''
            ? errorMsg
            : 'Something went wrong. Try again later',
      });
      setIsAppointmentConfirmed(true);
    }
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('message', onWindowMessage);
      window.document.addEventListener(
        'onScheduleComplete',
        handleEvent,
        false,
      );
    }
    return () => {
      window.removeEventListener('message', onWindowMessage);
      window.document.removeEventListener(
        'onScheduleComplete',
        handleEvent,
        false,
      );
    };
  });

  const handleBookingTypeChange = (e) => {
    const bookingType = bookingTypes.find(
      (type) => type.value === e.target.value,
    );
    setAmount(bookingType.amount);
    setBookingTypeLabel(bookingType.name);
    setBookingAmountLabel(
      `$${bookingType.amount} (${bookingType.duration}min)`,
    );
    setCalendlyUrl(bookingType.url);
  };
  return (
    <div className={styles.root}>
      <div className={styles.contentDiv}>
        {
          <Fragment>
            <div className={styles.title} data-testid="appointment header">
              {formatMessage(messages.title)}
            </div>
            <div className={styles['schedule-header']}>
              <div className={styles['header-left']}>
                <div className={styles['schedule-user']}>
                  <img
                    data-testid="doctor profile pic"
                    src={
                      doctor.profile_pic
                        ? doctor.profile_pic
                        : require('../../../img/doctor.jpg')
                    }
                    alt={`${doctor.first_name} ${
                      doctor.last_name ? doctor.last_name : ''
                    }`}
                  />
                </div>
                <h3 data-testid="doctor name">
                  {doctor.first_name} {doctor.last_name ? doctor.last_name : ''}
                </h3>
              </div>
              <div className={styles['header-right']}>
                <div className={styles['schedule-content']}>
                  <h3 data-testid="booking type"> {bookingTypeLabel} </h3>
                  <h6 data-testid="booking amount"> {bookingAmountLabel} </h6>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.progressHead}>
                <p
                  className={step1 ? styles.stepHead : ''}
                  data-testid="booking info"
                >
                  Booking Info
                </p>
                <p
                  className={step1 && step2 ? styles.stepHead : ''}
                  data-testid="date time"
                >
                  Date & Time
                </p>
              </div>
              <div className={styles.progressBar}>
                <Progress width={700} height={5} percent={percent} />
              </div>
            </div>
          </Fragment>
        }
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            {!showCalendly && !showPayment && (
              <>
                <Formik
                  validate={fieldValidators}
                  initialValues={initialValues}
                  enableReinitialize={true}
                  render={({
                    values,
                    handleChange,
                    errors,
                    touched,
                    isValid,
                    setFieldValue,
                    handleBlur,
                  }) => {
                    return (
                      <Form
                        data-testid="appointment-form"
                        className={styles.form}
                      >
                        <Grid className={styles.grid}>
                          <div className={styles.cell}>
                            <FieldDropdown
                              label={formatMessage(messages.bookingType)}
                              errorText={
                                touched.booking_type && errors.booking_type
                              }
                              name="booking_type"
                              value={values.booking_type}
                              onChange={(e) => {
                                setFieldValue('booking_type', e.target.value);
                                handleBookingTypeChange(e);
                              }}
                              onBlur={handleBlur}
                              options={bookingTypes}
                              dataTestId="DoctorAppointmentPage.AppointmentType.Dropdown"
                            />
                          </div>
                          <div
                            className={[styles.cell, styles.radioBtn].join(' ')}
                          >
                            <span
                              className={styles['radio-label']}
                              data-testid="new or returning client"
                            >
                              {' '}
                              {formatMessage(messages.radioLable)}{' '}
                            </span>
                            <RadioChildrenSelector
                              name="appointmentType"
                              handleOnChange={(value) => {
                                value === 0 && handleChange(null, true);
                                setAppointmentType(!value ? 0 : 1);
                                setFieldValue(
                                  'patient_type',
                                  !value ? 'New' : 'Old',
                                );
                              }}
                              selected={appointmentType}
                              direction="row"
                            >
                              <div
                                className={styles['radio-buttons']}
                                data-testid="new client"
                              >
                                {formatMessage(messages.newPatientLabel)}
                              </div>
                              <div
                                className={styles['radio-buttons']}
                                data-testid="return client"
                              >
                                {formatMessage(messages.oldPatientLable)}
                              </div>
                            </RadioChildrenSelector>
                          </div>
                          <Cell
                            small={12}
                            medium={6}
                            className={[
                              styles['cell-md-6'],
                              styles['cell-sm-6'],
                            ].join(' ')}
                          >
                            <FieldInput
                              label={formatMessage(messages.patientName)}
                              placeholder="Add patient name"
                              name="patient_name"
                              className={styles.textInput}
                              value={values.patient_name}
                              onChange={handleChange}
                              errorText={
                                touched.patient_name && errors.patient_name
                              }
                              onBlur={handleBlur}
                              label-testid="patient name label"
                              data-testid="patient name"
                              error-testid="patient name error"
                            />
                          </Cell>
                        </Grid>
                        {error && (
                          <div className={styles['error-container']}>
                            <div className={styles.error}>{error}</div>
                          </div>
                        )}
                        {!showCalendly && <hr />}
                        <Cell
                          small={12}
                          flexContainer
                          alignY="middle"
                          className={styles['actions-container']}
                        >
                          <PrimaryButton
                            disabled={!isValid}
                            label="Next"
                            type="submit"
                            className="font-18"
                            onClick={async (e) => {
                              setAppointmentObj(
                                Object.assign(
                                  values,
                                  { doctor_id: clinicianId },
                                  { patient_id: profile.id },
                                  { has_assessment: profile.has_assessment },
                                ),
                              );
                              const eventData = {
                                event: 'ga4_event',
                                properties: {
                                  event_name: 'book_appointment',
                                  step_name: 'continue_appointment',
                                  step_number: '3',
                                  clinician_name: doctor.first_name,
                                  clinician_id: clinicianId,
                                  patient_id: profile.id,
                                },
                              };
                              addToDataLayer(eventData);
                              await onSubmit(values);
                              setShowCalendly(true);
                              const progress = percent * 2;
                              setPercent(progress);
                              setStep2(true);
                            }}
                            data-testid="next button"
                          />

                          <SecondaryButton
                            data-testid="cancel button"
                            label={'Cancel'}
                            className="font-18"
                            type="button"
                            onClick={() => setShowCancelModal(true)}
                          />
                        </Cell>
                      </Form>
                    );
                  }}
                />
              </>
            )}
            {showCalendly && (
              <>
                <div className={styles.calendlyBox}>
                  {REACT_APP_NYLAS_FEATURE_FLAG === 'true' ? (
                    <NylasScheduler url={`${calendlyUrl}?${calendlyParams}`} />
                  ) : (
                    <Calendly url={calendlyUrl} queryString={calendlyParams} />
                  )}

                  {!isAppointmentConfirmed && <hr />}
                </div>
                {!isAppointmentConfirmed && (
                  <div className={styles.btnDiv}>
                    <SecondaryButton
                      label={'Cancel'}
                      className={styles.appointmentBtn}
                      type="button"
                      onClick={() => setShowCancelModal(true)}
                      data-testid="cancel button"
                    />
                  </div>
                )}
              </>
            )}
            {isAppointmentConfirmed && !showPayment && (
              <div className={styles.btnDiv}>
                <SecondaryButton
                  label="Back"
                  className={styles.appointmentBtn}
                  type="button"
                  onClick={() => history.push('/doctor/my-account')}
                  data-testid="back button"
                />
              </div>
            )}
          </Fragment>
        )}
        {showConfirmationModal && (
          <Modal className={styles.modal}>
            <div className={styles.content}>
              <div className={styles.icon}>
                <img
                  className={styles['img-bg']}
                  src={require(`../../../img/icons/appointment-booked.svg`)}
                  alt={'appointment-booked'}
                />
              </div>
              <h4>
                <b data-testid="modal header">
                  {formatMessage(messages.modalTitle)}
                </b>
              </h4>
              <p>
                You are scheduled with {doctor.first_name}{' '}
                {doctor.last_name ? doctor.last_name : ''}
              </p>
              <span className={styles.hr}></span>
              <div className={styles['meeting-section']}>
                <div className={styles['meeting-details']}>
                  <span className={styles.dot}></span>
                  <p className={styles['meeting-duration']}>
                    30 Minute Meeting
                  </p>
                </div>
                <div className={styles['meeting-details']}>
                  <img
                    className={styles['meeting-icon']}
                    src={require(`../../../img/icons/calendar-grey.svg`)}
                    alt={'appointment-booked'}
                  />
                  <p className={styles['meeting-timing']}>
                    9:30am - 10:00am, Friday, June 12, 2020
                  </p>
                </div>
                <div className={styles['meeting-details']}>
                  <img
                    className={styles['meeting-icon']}
                    src={require(`../../../img/icons/globe.svg`)}
                    alt={'appointment-booked'}
                  />
                  <p className={styles['meeting-timezone']}>
                    India Standard Time
                  </p>
                </div>
                <p className={styles['invitation-message']}>
                  A calendar invitation has been sent to your email address.
                </p>
              </div>
              <span className={styles.hr}></span>
              <PrimaryButton
                label={formatMessage(messages.modalButton)}
                className={styles.primaryButton}
                onClick={() => setShowConfirmationModal(false)}
                data-testid="close button"
              />
            </div>
          </Modal>
        )}
        {showCancelModal && (
          <Modal className={styles.resetmodal}>
            <div className={styles.content}>
              <div
                className={styles.closeButton}
                onClick={() => {
                  setShowCancelModal(false);
                }}
              >
                <img
                  data-testid="close modal icon"
                  src={require(`../../../img/close.svg`)}
                  alt={`Close`}
                />
              </div>
              <h1>
                <b data-testid="cancel modal header">
                  Are you sure you want to cancel?
                </b>
              </h1>
              <div className={styles.box}>
                <p data-testid="cancel modal sub-header">
                  This action will redirect you to the home page.
                </p>
              </div>
              <PrimaryButton
                label="Yes, Cancel"
                className={styles.primaryButton}
                onClick={() => {
                  const step_number_count = step1 && step2 ? '7' : '6';
                  const eventData = {
                    event: 'ga4_event',
                    properties: {
                      event_name: 'book_appointment',
                      step_name: 'cancel',
                      step_number: step_number_count,
                      clinician_name: doctor.first_name,
                      clinician_id: clinicianId,
                      patient_id: profile.id,
                    },
                  };
                  addToDataLayer(eventData);
                  history.push(`/my-account/`);
                }}
                data-testid="yes button"
              />
              <SecondaryButton
                label="No"
                className={styles.secondaryButton}
                onClick={() => {
                  setShowCancelModal(false);
                }}
                data-testid="no button"
              />
            </div>
          </Modal>
        )}
        {isAppointmentConfirmed && (
          <MessageModal
            message={displayMessage}
            displayLogo={false}
            onCloseModal={() => {
              history.push(APPOINTEMNT_URLS.DOCTOR);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DoctorNewAppointmentPage;
