import { OktaAuth } from '@okta/okta-auth-js';
import config from '../../config';

const authClient = new OktaAuth({ ...config.oidc });
const tokenManager = authClient.tokenManager;

const isLoggedIn = async () => {
  try {
    const accessToken = await tokenManager.get('accessToken');
    const tokenExpiresAt =
      accessToken !== undefined ? accessToken.expiresAt * 1000 : 0;
    const tokenExpired = tokenExpiresAt < new Date().getTime();
    return tokenExpired ? false : true;
  } catch (error) {
    return false;
  }
};

const getAccessToken = async () => {
  try {
    const accessToken = await tokenManager.get('accessToken');
    return accessToken !== undefined ? accessToken.accessToken : null;
  } catch (error) {
    return false;
  }
};

const clearAuthLocalStorageData = () => {
  // place your reentry code
  localStorage.removeItem('token');
  localStorage.removeItem('recommendations-answers-id');
  localStorage.removeItem('okta-cache-storage');
  localStorage.removeItem('okta-token-storage');
  localStorage.removeItem('assessment-id');
  localStorage.removeItem('profile-type');
  window.location.href = '/';
};

export { isLoggedIn, getAccessToken, clearAuthLocalStorageData };
