import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import TooltipIcon from '../../../../img/icons/common/tooltip.svg';
import styles from './RadioButton.module.scss';

const RadioButton = ({
  name,
  value,
  htmlFor,
  checked,
  disabled,
  labelName,
  onChange,
  'data-testid': datatestid,
  'label-testid': labeltestid,
  type,
  tooltipDescription,
  displayTooltip,
  onBlur,
}) => (
  <div className={styles['radio-container']}>
    <input
      id={htmlFor}
      type={type || 'radio'}
      checked={checked}
      disabled={disabled}
      value={value}
      name={name}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      data-testid={datatestid}
      className={styles.radioCustom}
    />
    <label
      htmlFor={htmlFor}
      className={styles.radioCustomLabel}
      data-testid={labeltestid}
      style={{ display: 'grid', gridTemplateColumns: '0.2fr 18fr' }}
    >
      <span className={styles['circle']}>
        {labelName}
        <Tooltip
          title={
            <React.Fragment>
              <Typography
                style={{ color: 'white', width: '100%', textAlign: 'center' }}
              >
                {tooltipDescription}
              </Typography>
            </React.Fragment>
          }
          style={{ display: displayTooltip }}
          arrow
          placement="right"
        >
          <img
            src={TooltipIcon}
            style={{ marginLeft: '10px', display: displayTooltip }}
          />
        </Tooltip>
      </span>
    </label>
  </div>
);

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  labelName: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
    .isRequired,
  onChange: PropTypes.func.isRequired,
};

RadioButton.defaultProps = {
  disabled: false,
  displayTooltip: 'none',
  tooltipDescription: '',
};

export default RadioButton;
