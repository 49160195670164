import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Cell, Grid } from 'react-foundation';
import useIntl from '../../../../../hooks/useIntl';
import FieldInput from '../../../../common/fields/FieldInput/FieldInput';
import customStyles from '../../PersonalInfoForm/PersonalInfoForm.module.scss';
import messages from '../messages';

const FacilitiesForm = ({ push, facilityValue, setFacilityValue }) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    setFacilityValue();
  }, [setFacilityValue]);

  const handleChangeFormValue = (value, field) => {
    setFacilityValue({
      ...facilityValue,
      [field]: value,
    });
  };

  const addFacility = () => {
    push(facilityValue);
    setFacilityValue();
  };

  return (
    <fieldset className={customStyles['fieldset']}>
      <Grid medium={12}>
        <Cell small={12}>
          <FieldInput
            label={formatMessage(messages.pastTreatment.facilityName)}
            placeholder={'Enter here'}
            value={facilityValue?.facility_name}
            onChange={(e) =>
              handleChangeFormValue(e.target.value, 'facility_name')
            }
          />
        </Cell>
        <Cell small={12} medium={6}>
          <FieldInput
            label={formatMessage(messages.pastTreatment.treatmentDate)}
            placeholder={'Enter here'}
            value={facilityValue?.treatment_date}
            onChange={(e) =>
              handleChangeFormValue(e.target.value, 'treatment_date')
            }
          />
        </Cell>
        <Cell small={12} medium={6}>
          <FieldInput
            label={formatMessage(messages.pastTreatment.treatmentLength)}
            placeholder={'Enter here'}
            value={facilityValue?.treatment_length}
            onChange={(e) =>
              handleChangeFormValue(e.target.value, 'treatment_length')
            }
          />
        </Cell>
        <Cell small={12}>
          <FieldInput
            label={formatMessage(messages.pastTreatment.issuesAddressed)}
            placeholder={'Enter here'}
            value={facilityValue?.issues_addressed}
            onChange={(e) =>
              handleChangeFormValue(e.target.value, 'issues_addressed')
            }
          />
        </Cell>
        <Cell className={customStyles['centeredButton']}>
          <button
            disabled={
              !facilityValue ||
              Object.keys(facilityValue).length !== 4 ||
              Object.values(facilityValue).some((elem) => !elem)
            }
            type="button"
            className={customStyles['buttonAddItem']}
            onClick={addFacility}
            data-testid="add_item_Facility"
          >{` + Add Item `}</button>
        </Cell>
      </Grid>
    </fieldset>
  );
};

FacilitiesForm.propTypes = {
  push: PropTypes.func,
  facilityValue: PropTypes.object,
  setFacilityValue: PropTypes.func,
  customDropdownStyles: PropTypes.object,
};

export default FacilitiesForm;
