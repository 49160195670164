import React from 'react';
import classnames from 'classnames';
import styles from './CheckBoxSmall.module.scss';

const CheckBoxSmall = ({
  label,
  isActive,
  onClick,
  'data-testid': datatestid,
}) => (
  <div className={styles.container} onClick={onClick} data-testid={datatestid}>
    <div>
      <div
        data-testid="box-div"
        className={classnames(styles.box, {
          [styles['box-active']]: isActive,
        })}
      ></div>
    </div>
    <div className={styles['box-name']}>{label}</div>
  </div>
);

export default CheckBoxSmall;
