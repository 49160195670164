import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import styles from './UserNotRecognized.module.scss';
import Warning from '../../img/approval.svg';
import Back from '../../img/icons/back.svg';

import Logo from '../Logo';
import DynamicClinicText from '../DynamicClinicText';

import WhitelabelContext from '../../context/WhitelabelContext';

const UserNotRecognized = ({ onClose }) => {
  const { whitelabel } = useContext(WhitelabelContext);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {!!onClose && (
          <div className={styles.backDiv} onClick={onClose}>
            <img src={Back} alt="Back" data-testid="user-denied back image" />
            <span data-testid="user-denied back label">Back</span>
          </div>
        )}
        {!onClose && (
          <Link to="/">
            <img src={Back} alt="Back" />
            <span>Back</span>
          </Link>
        )}

        <Logo />
        <div className={styles.warningImg}>
          <img
            src={Warning}
            alt="Success"
            data-testid="user-denied alert image"
          />
        </div>
        <h4
          className={styles.confirmationTitle}
          data-testid="user-denied title"
        >
          User Not Recognized
        </h4>
        <br />
        <p className={styles.text} data-testid="user-denied sub-text">
          We do not have a record of your account.
        </p>
        <br />
        <p className={styles.text} data-testid="user-denied text">
          Please contact{' '}
          {whitelabel && whitelabel.subdomain ? (
            <b>hello@{whitelabel.subdomain}</b>
          ) : (
            <b>hello@evehealthsystems.com</b>
          )}{' '}
          if you are experiencing difficulties using an invitation, or would
          like to learn more about <DynamicClinicText />.
        </p>
      </div>
    </div>
  );
};

export default UserNotRecognized;
