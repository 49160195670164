import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.patientProfilePage.title',
    description: 'Patient Profile Page title',
    defaultMessage: 'My Account Profile',
  },

  nameLabel: {
    id: 'pages.patientProfilePage.nameLabel',
    description: 'Name label',
    defaultMessage: 'Name:',
  },
  emailLabel: {
    id: 'pages.patientProfilePage.emailLabel',
    description: 'Email label',
    defaultMessage: 'Email:',
  },
  passwordLabel: {
    id: 'pages.patientProfilePage.passwordLabel',
    description: 'Password label',
    defaultMessage: 'Password:',
  },
  phoneLabel: {
    id: 'pages.patientProfilePage.phoneLabel',
    description: 'Phone label',
    defaultMessage: 'Phone Number:',
  },
  editProfileLinkLabel: {
    id: 'pages.patientProfilePage.editProfileLinkLabel',
    description: 'Edit profile link label',
    defaultMessage: 'Edit My Account Profile',
  },
  editPersonalInfoLinkLabel: {
    id: 'pages.patientProfilePage.editPersonalInfoLinkLabel',
    description: 'Edit personal info link label',
    defaultMessage: 'Edit My Personal Details',
  },
  closeAccountLinkLabel: {
    id: 'pages.patientProfilePage.closeAccountLinkLabel',
    description: 'Close Account link label',
    defaultMessage: 'Close My Account',
  },
  emailPlaceholder: {
    id: 'component.patientProfilePage.emailPlaceholder',
    description: 'The label for email field placeholder',
    defaultMessage: 'Email',
  },
  firstNamePlaceholder: {
    id: 'component.patientProfilePage.firstNamePlaceholder',
    description: 'The label for first name field placeholder',
    defaultMessage: 'First Name',
  },
  lastNamePlaceholder: {
    id: 'component.patientProfilePage.lastNamePlaceholder',
    description: 'The label for last name field placeholder',
    defaultMessage: 'Last Name',
  },
  phonePlaceholder: {
    id: 'component.patientProfilePage.phonePlaceholder',
    description: 'The label for phone field placeholder',
    defaultMessage: 'Phone Number',
  },
  saveBtn: {
    id: 'component.patientProfilePage.saveBtn',
    description: 'The label for save button',
    defaultMessage: 'Save',
  },
  cancelBtn: {
    id: 'component.patientProfilePage.cancelBtn',
    description: 'The label for cancel button',
    defaultMessage: 'Cancel',
  },
  modalTitle: {
    id: 'component.patientProfilePage.modalTitle',
    description: 'The title of the model',
    defaultMessage: 'Edit Profile',
  },
  uploadProfileBtnLabel: {
    id: 'component.patientProfilePage.uploadProfileBtnLabel',
    description: 'The label for upload profile photo button',
    defaultMessage: 'Upload Profile Photo',
  },
  editProfileBtnLabel: {
    id: 'component.patientProfilePage.editProfileBtnLabel',
    description: 'The label for edit profile photo button',
    defaultMessage: 'Edit Profile Photo',
  },
  removeProfileBtnLabel: {
    id: 'component.patientProfilePage.removeProfileBtnLabel',
    description: 'The label for remove profile photo button',
    defaultMessage: 'Remove Profile Photo',
  },
  profilePicModalTitle: {
    id: 'component.patientProfilePage.profilePicModalTitle',
    description: 'The title of the profile picture model',
    defaultMessage: 'Edit your profile photo',
  },
  profilePicModalFileSize: {
    id: 'component.patientProfilePage.profilePicModalFileSize',
    description: 'The title of the profile picture model file size label',
    defaultMessage: 'Max file size: 2 MB',
  },
  profilePicChooseAnotherBtn: {
    id: 'component.patientProfilePage.profilePicChooseAnotherBtn',
    description: 'The label for choose another photo button',
    defaultMessage: 'Choose another photo',
  },
  profilePicSaveBtn: {
    id: 'component.patientProfilePage.profilePicSaveBtn',
    description: 'The label for save button',
    defaultMessage: 'Save',
  },
  profilePicCancelBtn: {
    id: 'component.patientProfilePage.profilePicCancelBtn',
    description: 'The label for cancel button',
    defaultMessage: 'Cancel',
  },
});
