import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { OktaAuth } from '@okta/okta-auth-js';

import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import FieldInput from '../../components/common/fields/FieldInput/FieldInput';
import Loading from '../../components/common/Loading';
import SecondaryButton from '../../components/common/buttons/SecondaryButton';
import Datepicker from '../../components/common/Datepicker';
import Logo from '../../components/Logo';
import DynamicClinicText from '../../components/DynamicClinicText';
import MessageModal from '../../components/MessageModal';

import { addToDataLayer } from '../../utils/common';
import config from '../../config';

import { signUp, verifyUninvitedUser } from '../../api/auth';
import styles from './RegistrationPage.module.scss';

const auth = new OktaAuth({
  ...config.oidc,
});

const Schema = Yup.object().shape({
  firstName: Yup.string().required('Field is Required'),
  lastName: Yup.string().required('Field is Required'),
  email: Yup.string().email(),
  password: Yup.string()
    .required('Field is Required')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?&-]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number',
    ),
  confirmPassword: Yup.string()
    .required('Field is Required')
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Both password need to be the same',
      ),
    }),
});

const RegistrationPage = () => {
  const history = useHistory();
  const param = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [displayMessage, setDisplayMessae] = useState({
    title: '',
    message: '',
  });

  const handleSignUp = async (values) => {
    try {
      setIsLoading(true);
      const { firstName, password, email, userType, lastName, dob } = values;

      const eventData = {
        event: 'ga4_event',
        properties: {
          event_name: 'signup',
          user_type: userType || '',
        },
      };
      addToDataLayer(eventData);

      await signUp({
        firstName,
        email,
        password,
        userType,
        lastName,
        userId: param.oktaId,
        dob,
      });

      toast('Your account created successfully.', {
        autoClose: 5000,
        className: styles.toastMsg,
        bodyClassName: styles.toastDiv,
        hideProgressBar: true,
      });

      setIsLoading(false);

      history.push('/');
    } catch (error) {
      setIsLoading(false);

      let errorText = 'Something go wrong';

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorText = error.response.data.message;
      }

      setError(errorText);
      setDisplayMessae({
        type: 'error',
        title: '',
        text: errorText,
        buttonLabel: 'OK',
        redirectUrl: '/',
      });
      setShowMessageModal(true);
    }
  };

  const validateUser = async () => {
    try {
      setIsLoading(true);
      const verifiedUser = await verifyUninvitedUser(param.oktaId);
      setUser(verifiedUser);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      let errorText = 'Something go wrong';

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorText = error.response.data.message;
      }

      setDisplayMessae({
        type: 'error',
        title: '',
        text: errorText,
        buttonLabel: 'OK',
        redirectUrl: '/',
      });
      setShowMessageModal(true);
    }
  };

  useEffect(() => {
    if (!user) {
      validateUser();
    }
  }, [param.oktaId, user]);

  return (
    <div className={styles.root}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.container}>
          <Logo />
          <div className={styles.content}>
            <Formik
              initialValues={{
                firstName: (user && user.first_name) || '',
                lastName: '',
                dob: null,
                email: (user && user.email) || '',
                userType: 'patient',
                password: '',
                confirmPassword: '',
                aggreement: false,
              }}
              validationSchema={Schema}
              onSubmit={async (values) => {
                if (values && values.userType) {
                  handleSignUp(values);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <h4>
                    Create your <DynamicClinicText /> account
                  </h4>
                  <>
                    <FieldInput
                      className={styles.userInput}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      autoComplete="firstName"
                      autoFocus={false}
                      label="First name"
                      errorText={touched.firstName && errors.firstName}
                      name="firstName"
                      data-testid="firstName"
                    />
                    <FieldInput
                      className={styles.userInput}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      autoComplete="lastName"
                      autoFocus={false}
                      label="Last name"
                      errorText={touched.lastName && errors.lastName}
                      name="lastName"
                      data-testid="lastName"
                    />
                    <div className={styles.dobRow}>
                      <Datepicker
                        label="Date Of Birth"
                        selected={values.dob ? new Date(values.dob) : null}
                        className={styles.expireDate}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        value={values.dob}
                        autoComplete="off"
                        dropdownMode="select"
                        maxDate={moment().toDate()}
                        placeholderText="Date Of Birth"
                        dateFormat="MM/dd/yyyy"
                        onBlur={handleBlur}
                        onChange={(date) => {
                          setFieldValue(`dob`, date);
                        }}
                        required={true}
                        data-testid={`dob`}
                      />
                    </div>
                    <FieldInput
                      className={[styles.userInput, styles.disabled].join(' ')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      autoComplete="email"
                      autoFocus={false}
                      label="Email address"
                      errorText={(touched.email && errors.email) || error}
                      name="email"
                      data-testid="email"
                    />
                    <FieldInput
                      className={styles.userInput}
                      autoComplete="current-password"
                      label="Password"
                      onBlur={handleBlur}
                      errorText={touched.password && errors.password}
                      name="password"
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      data-testid="password"
                    />
                    <div className={styles.validation}>
                      {values.password.length < 8 ? (
                        <p>Min. 8 characters</p>
                      ) : (
                        <p className={styles.active}>Min. 8 characters</p>
                      )}
                      {!/\d/.test(values.password) ? (
                        <p>Number</p>
                      ) : (
                        <p className={styles.active}>Number</p>
                      )}
                      {!/[a-z]/.test(values.password) ? (
                        <p>Lowercase letter</p>
                      ) : (
                        <p className={styles.active}>Lowercase letter</p>
                      )}

                      {!/[A-Z]/.test(values.password) ? (
                        <p>Upperecase letter</p>
                      ) : (
                        <p className={styles.active}>Upperecase letter</p>
                      )}
                    </div>
                    <FieldInput
                      className={styles.userInput}
                      label="Confirm Password"
                      errorText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      onBlur={handleBlur}
                      name="confirmPassword"
                      onChange={handleChange}
                      type="password"
                      value={values.confirmPassword}
                      data-testid="confirmPassword"
                    />
                    <div className={styles.aggreement}>
                      <input
                        required
                        type="checkbox"
                        name="aggreement"
                        value={values.aggreement}
                        className={styles.aggreementCheck}
                        onClick={() => {
                          setFieldValue('aggreement', !values.aggreement);
                        }}
                      />
                      <label htmlFor="aggreement">
                        By checking here I affirm that I agree to the{' '}
                        <Link to="/agreements/terms-conditions">
                          <b>Terms and Conditions</b>
                        </Link>{' '}
                        of Service and{' '}
                        <Link to="/agreements/privacy-policy">
                          <b> Privacy Policy</b>
                        </Link>
                      </label>
                    </div>

                    <div className={styles.nextBtn}>
                      <PrimaryButton
                        className={styles.primaryBtn}
                        label="Create an account"
                      />
                    </div>
                  </>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
      {showMessageModal && (
        <MessageModal
          message={displayMessage}
          displayLogo={false}
          onCloseModal={() => {
            setShowMessageModal(false);
          }}
        />
      )}
    </div>
  );
};

export default RegistrationPage;
