import React from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styles from './DoctorAppointment.module.scss';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import { APPOINTMENT_STATUSES, SUPERBILL_REQUESTED } from '../../constants';
import { requestSuperBill } from '../../api/appointment';
import { downloadSuperbill } from '../../api/superbill/index';

const DoctorAppointment = ({ item, isProfile = true }) => {
  const history = useHistory();

  const isBefore = moment(item.end_time).isBefore(Date.now());

  const showSuperbillButton =
    process.env.REACT_APP_SUPERBILL_FLAG === 'true' &&
    isBefore &&
    item.status !== APPOINTMENT_STATUSES.CANCELED &&
    item.amount > 0;

  const createSuperbill = async (item) => {
    let response;
    let superbillId;
    if (!item?.superbill) {
      response = await requestSuperBill(item.id);

      if (response && response.type && response.type === 'error') {
        //show error
      } else if (response) {
        superbillId = response[0]?.id;
      }
    } else {
      superbillId = item.superbill.id;
    }

    history.push(`/doctor/superbill/clinician/${superbillId}`);
  };

  return (
    <div className={styles.root} data-testid={`dashboard doctor appointment`}>
      <div className={styles.leftcontent}>
        <div className={styles['meeting-details']}>
          <div className={styles['meeting-time']}>
            <>
              <img
                className={styles['meeting-icon']}
                src={require(`../../img/icons/calendar-black.svg`)}
                alt={'appointment-booked'}
                data-testid={`dashboard doctor past appointment image`}
              />
              <p
                className={styles.isBefore}
                data-testid="past appointment time"
              >
                {moment(item.start_time).format('LT')} -{' '}
                {moment(item.end_time).format('LT')},{' '}
                {moment(item.end_time).format('dddd, MMMM DD, YYYY')}
              </p>
            </>
          </div>
          <div className={styles.profileDiv}>
            <img
              data-testid="patient profile pic"
              src={
                item.patient && item.patient.profile_pic
                  ? item.patient.profile_pic
                  : require('../../img/patient.jpg')
              }
              alt="patient_profile"
            />
            <div className={styles.timeDiv}>
              {' '}
              <div className={styles.title} data-testid="doctor name">
                {item.patient && item.patient.first_name}{' '}
                {item.patient && item.patient.last_name}
                {isProfile && (
                  <div className={styles.seeProfile}>
                    <span
                      data-testid="see profile"
                      onClick={() =>
                        history.push(
                          `/doctor/patient-details/${item.patient.id}`,
                        )
                      }
                    >
                      See Profile
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={styles.text}>
            <div className={classnames(styles.type, styles.typeDiv)}>
              <span data-testid="type label">Type:</span>
              <p data-testid="patient type">{item.patient_type}</p>
            </div>
            <div className={classnames(styles.location, styles.mobileDiv)}>
              <span data-testid="mobile label">Phone:</span>
              <p data-testid="phone number">{item.patient_phone}</p>
            </div>
            {showSuperbillButton && (
              <div className={classnames(styles.location, styles.superbillDiv)}>
                <span data-testid="superbill label">Superbill sent:</span>
                <p data-testid="superbill value">
                  {item.superbill?.sent_at
                    ? moment(item.superbill.sent_at).format('MMMM DD, YYYY')
                    : 'Not yet sent'}
                </p>
              </div>
            )}
            <div className={styles.location}>
              {item.status &&
                item.status.toLowerCase() === APPOINTMENT_STATUSES.PAID && (
                  <>
                    <span data-testid="fee label">Fee</span>
                    <span data-testid="fee status" className={styles.status}>
                      {item.status}
                      {`: `}
                    </span>
                    <span data-testid="fee amount" className={styles.feeamount}>
                      ${item.amount}
                    </span>
                  </>
                )}
              {item.status &&
                item.status.toLowerCase() === APPOINTMENT_STATUSES.UNPAID && (
                  <>
                    <span data-testid="fee label">Fee Due: </span>
                    <span data-testid="fee amount" className={styles.feeamount}>
                      ${item.amount}
                    </span>
                  </>
                )}
            </div>
            <div className={styles.buttons}>
              {showSuperbillButton && (
                <div className={styles.superbillButtons}>
                  <PrimaryButton
                    data-testid={`request_${item.id}`}
                    className={styles.superbillButton}
                    label={`${
                      !item.superbill ||
                      item.superbill?.status === SUPERBILL_REQUESTED
                        ? 'Create Superbill'
                        : 'Edit Superbill'
                    }`}
                    onClick={() => createSuperbill(item)}
                  />
                  {item.superbill?.sent_at && (
                    <PrimaryButton
                      data-testid={`send_${item.id}`}
                      className={styles.superbillButton}
                      label={'View Superbill'}
                      onClick={() => downloadSuperbill(item.superbill.id)}
                    />
                  )}
                </div>
              )}
              {item.reschedule_url &&
                !moment(item.start_time).isBefore(new Date()) && (
                  <PrimaryButton
                    label="Reschedule"
                    className="font-16"
                    type="button"
                    onClick={() =>
                      history.push(`/doctor/appointment/reschedule/${item.id}`)
                    }
                    data-testid="reschedule button"
                  />
                )}
              {item.cancel_url &&
                !moment(item.start_time).isBefore(new Date()) && (
                  <PrimaryButton
                    label="Cancel"
                    className="font-16"
                    type="button"
                    onClick={() =>
                      history.push(`/doctor/appointment/cancel/${item.id}`)
                    }
                    data-testid="cancel button"
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DoctorAppointment;
