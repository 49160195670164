import React from 'react';
import { FormElement } from '../FormElements';

const supportedTypes = [
  'textarea',
  'radio',
  'textInput',
  'checkbox',
  'stepTwoFormStructure',
  'sectionTitle',
  'sectionSubTitle',
  'sectionTextDelimiter',
  'delimiter',
];

const FormStep = ({
  item,
  stepName,
  values,
  handleChange,
  setFieldValue,
  handleCheckboxChange,
  handleBlur,
}) => {
  if (!supportedTypes.includes(item.type)) {
    return null;
  }

  const openDependentField =
    item.dependentFields &&
    (item.type === 'checkbox'
      ? values[item.id].includes(item.dependentFields.triggerValue)
      : values[item.id] === item.dependentFields.triggerValue);

  const props = {
    item,
    stepName,
    values,
    handleChange,
    setFieldValue,
    handleCheckboxChange,
    handleBlur,
  };

  return (
    <>
      <FormElement {...props} />
      {openDependentField &&
        (item.dependentFields.formElements ? (
          item.dependentFields.formElements.map((elem, i) => (
            <FormElement key={i} {...props} item={elem} />
          ))
        ) : (
          <FormElement {...props} item={item.dependentFields} />
        ))}
    </>
  );
};

FormStep.propTypes = {};

FormStep.defaultProps = {};

export default FormStep;
