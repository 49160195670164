import { doGet } from '../fetch';

export const getWhitelabel = async (id) => {
  const whitelabel = await doGet(`/b2c/whitelabel/${id}`);
  return whitelabel;
};

export const getWhitelabelBySubdomain = async () => {
  const whitelabel = await doGet(`/b2c/whitelabel/by-subdomain`);
  return whitelabel;
};
