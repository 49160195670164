import { useState, useEffect, useContext } from 'react';
import SquareupContext from '../../context/SquareupContext';
import { SQUARE_APPLICATION_ID } from '../../utils/envVariables';

/*
 * Must create fields on component when invoke that hook
 *
 * <div id="ccnumber" />
 * <span id="cardNumber-error" className="error-message" />
 *
 * <div id="ccexp" />
 * <span id="expirationDate-error" className="error-message" />
 *
 * <div id="cvv" />
 * <span id="cvv-error" className="error-message" />
 *
 * <div id="postalCode" />
 * <span id="postalCode-error" className="error-message" />
 */
const { REACT_APP_SQUARE_JS_URL } = process.env;

let form;

const onGetCardNonce = (e) => {
  e.preventDefault();
  form.requestCardNonce();
};

export default ({ styles, handleOnSubmit, isValidCartToCheckout }) => {
  const [initialized, setInitialized] = useState(false);
  const [nonceToken, setNonceToken] = useState(null);
  const { dispatch } = useContext(SquareupContext);
  const [reloadForm, setReloadForm] = useState(false);

  useEffect(() => {
    if (nonceToken) handleOnSubmit(nonceToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonceToken]);

  const cardNonceResponseReceived = (errors, nonce) => {
    if (errors) {
      errors.forEach((error) => {
        document.getElementById(`${error.field}-error`).innerText =
          error.message;
      });

      return;
    }
    setNonceToken(nonce);
    setReloadForm(false);
  };

  const inputEventReceived = (inputEvent) => {
    switch (inputEvent.eventType) {
      case 'errorClassRemoved':
        document.getElementById(`${inputEvent.field}-error`).innerText = '';
        break;
      default:
        break;
    }
  };

  const paymentFormLoaded = () => {
    dispatch({ type: 'FORM_LOADING', payload: false });
  };

  const onReDrawFrom = () => {
    setReloadForm(true);
  };

  const sqPaymentFormOpts = {
    applicationId: SQUARE_APPLICATION_ID,
    autoBuild: false,
    inputStyles: [{ fontSize: '100%', lineHeight: '218.75%' }],
    cardNumber: {
      elementId: 'ccnumber',
      placeholder: '',
    },
    cvv: {
      elementId: 'cvv',
      placeholder: '',
    },
    expirationDate: {
      elementId: 'ccexp',
      placeholder: '',
    },
    postalCode: {
      elementId: 'postalCodeBilling',
      placeholder: '',
    },
    callbacks: {
      cardNonceResponseReceived,
      inputEventReceived,
      paymentFormLoaded,
    },
  };

  useEffect(() => {
    if (isValidCartToCheckout || reloadForm) {
      const paymentScript = document.createElement('script');
      paymentScript.setAttribute('src', REACT_APP_SQUARE_JS_URL);
      document.head.appendChild(paymentScript);
      paymentScript.onload = () => {
        setInitialized(true);
        // eslint-disable-next-line no-undef
        form = new SqPaymentForm({
          ...sqPaymentFormOpts,
          inputClass: styles,
        });
        form.build();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidCartToCheckout, reloadForm]);

  return { initialized, onGetCardNonce, onReDrawFrom };
};
