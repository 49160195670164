import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './RecommendPsychotherapists.module.scss';
import Psychotherapist from '../Psychotherapist';

const RecommendPsychotherapists = ({
  psychotherapists,
  onBookAppointment,
  history,
  onFillAssessment,
}) => {
  return (
    <div className={styles.root} data-testid="recommend psychotherapists">
      <h1 data-testid="recommend psychotherapists title">
        Recommended Providers
      </h1>
      {psychotherapists.length === 0 ? (
        <>
          <div medium={12} className={styles.noData}>
            Unfortunately, there are no therapists currently registered on Eve
            that are a good match for you based on your initial assessment.
          </div>
          <div medium={12} className={styles.noData}>
            Please&nbsp;
            <span className={styles['link']}>
              <Link
                onClick={() => {
                  onFillAssessment();
                }}
                data-testid="recommend psychotherapists click here link"
              >
                click here
              </Link>
            </span>
            &nbsp;if you'd like to update your assessment to find new matches.
          </div>
        </>
      ) : (
        <div className={styles.list}>
          {psychotherapists.map((item) => (
            <Psychotherapist
              key={item.id}
              item={item}
              onBookAppointment={onBookAppointment}
              history={history}
            />
          ))}
        </div>
      )}
    </div>
  );
};

RecommendPsychotherapists.propTypes = {
  onBookAppointment: PropTypes.func.isRequired,
  onFillAssessment: PropTypes.func,
  psychotherapists: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.shape({}).isRequired,
};
export default RecommendPsychotherapists;
