import React from 'react';
import WaitListForm from '../../components/WaitListForm/WaitListForm';
import styles from './WaitList.module.scss';

const WaitListPage = () => {
  return (
    <div className={styles.container}>
      <WaitListForm />
    </div>
  );
};

export default WaitListPage;
