import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Cell, Grid } from 'react-foundation';
import FieldInput from '../common/fields/FieldInput/FieldInput';
import { emailValidator, requiredValidator } from '../../utils/validators';
import PrimaryButton from '../common/buttons/PrimaryButton';
import useIntl from '../../hooks/useIntl';
import messages from './messages';
import styles from './WaitListForm.module.scss';
import { addUserToWaitlist } from '../../api/waitlist';
import { useHistory } from 'react-router-dom';
import Logo from '../Logo';
import Label from '../../components/common/Label';
import Loading from '../../components/common/Loading';

const fieldValidators = ({ email, type }) => ({
  ...emailValidator(email),
  ...requiredValidator(type),
});
const item = ['Clinician', 'Patient', 'Researcher'];
const WaitListForm = ({ formStyles = styles }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const handleBack = () => {
    history.push('/');
  };
  const { formatMessage } = useIntl();

  return (
    <div className={formStyles.waitListSignIn}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={formStyles.SignInBox}>
          <Logo />
          <div className={formStyles.waitListForm}>
            <Formik
              initialValues={{ email: '', type: '' }}
              validate={fieldValidators}
              onSubmit={async (
                { email, type },
                { setFieldError, setSubmitting, setStatus },
              ) => {
                setSubmitting(true);
                try {
                  const user = { email, type };
                  const data = await addUserToWaitlist(user);
                  if (data) {
                    setStatus({
                      status: 'sucess',
                    });
                  }
                } catch (error) {
                  setIsLoading(false);
                  setFieldError('email', formatMessage(messages.invalidEmail));
                  setFieldError('type', formatMessage(messages.required));
                  setSubmitting(false);
                }
              }}
            >
              {({ errors, isSubmitting, handleChange, values, status }) => {
                return status === undefined ? (
                  <>
                    <p className={formStyles.title}>
                      {formatMessage(messages.title)}
                    </p>
                    <Form>
                      <Grid>
                        <Cell small={12} className={formStyles.emailField}>
                          <FieldInput
                            autoComplete="username"
                            autoFocus={true}
                            label={formatMessage(messages.emailPlaceholder)}
                            errorText={errors.email}
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            data-testid="email"
                          />
                        </Cell>
                        <div className={styles.radioDiv}>
                          <Label errorText={errors.field}>
                            Choose User Type:
                          </Label>
                          {item.map((choice, index) => (
                            <Cell
                              small={6}
                              medium={6}
                              key={index}
                              className={formStyles['multi-choice-small']}
                            >
                              <label className={formStyles.categorySelection}>
                                {choice}
                                <input
                                  type="radio"
                                  name="type"
                                  value={choice}
                                  onClick={handleChange}
                                />
                                <span className={formStyles.checkmark}></span>
                              </label>
                            </Cell>
                          ))}
                        </div>
                        <Cell small={12}>
                          <PrimaryButton
                            className={formStyles.submitInBtn}
                            label={formatMessage(messages.submitInBtn)}
                            disabled={isSubmitting}
                          />
                          <div className={formStyles.backSignInLink}>
                            <Link to="/">Back to Sign In</Link>
                          </div>
                        </Cell>
                      </Grid>
                    </Form>
                  </>
                ) : (
                  <>
                    <div className={formStyles.sucessMessage}>
                      <p>Congratulations</p>
                      <p>
                        Welcome to the Eve waitlist. We will send you the login
                        information soon.
                      </p>
                    </div>
                    <Cell small={12}>
                      <PrimaryButton
                        className={formStyles.backSignInButton}
                        label={formatMessage(messages.backToSignInBtn)}
                        onClick={handleBack}
                      />
                    </Cell>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default WaitListForm;
