import { defineMessages } from 'react-intl';

export default defineMessages({
  modalTitle: {
    id: 'pages.invitePatient.modalTitle',
    description: 'Invite Patient Info Modal Title',
    defaultMessage: 'User invitation',
  },
  modalSuccessText: {
    id: 'pages.invitePatient.modalSuccessText',
    description: 'Invite Patient Info Modal Success Text',
    defaultMessage: 'Patient successfully invited.',
  },
  modalFailureText: {
    id: 'pages.invitePatient.modalFailureText',
    description: 'Invite Patient Info Modal Failure Text',
    defaultMessage: 'Patient invitation failed.',
  },
  modalEmailInUseText: {
    id: 'pages.invitePatient.modalEmailInUseText',
    description: 'Invite Patient Info Modal Failure Text',
    defaultMessage: 'The email entered already in use.',
  },
  modalAlreadyExistsText: {
    id: 'pages.invitePatient.modalAlreadyExistsText',
    description: 'Invite Patient Info Modal Failure Text',
    defaultMessage: 'Existing User',
  },
});
