import { useContext } from 'react';
// import ClinicContext from '../../context/ClinicContext';

const DynamicClinicText = () => {
  // const { clinic } = useContext(ClinicContext);
  // return clinic && clinic.clinic_name ? clinic.clinic_name : 'Eve';
  return 'Eve';
};

export default DynamicClinicText;
