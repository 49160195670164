import React from 'react';
import LayoutPDF from './components/LayoutPDF';
import FullRowLabelledData from './components/FullRowLabelledData';

const SolsticeIntakePDF = ({ item, formDetail, data }) => {
  return (
    <LayoutPDF item={item} formDetail={formDetail}>
      <>
        <FullRowLabelledData
          label="How did you find out about our practice?"
          value={data.practice_heard_of_origin}
        />
        <FullRowLabelledData
          label="What brings you to counseling at this time? Is there something specific, such as a particular event? Be as detailed as you can."
          value={data.counseling_reason}
        />
        <FullRowLabelledData
          label="What are your goals for counseling?"
          value={data.counseling_goals}
        />
        <FullRowLabelledData
          label="What has psychotherapy been like for you in the past?"
          value={data.psychotherapy_history}
        />
        <FullRowLabelledData
          label="Specify all medications and supplements you are presently taking and for what reason."
          value={data.current_medication_and_reason}
        />
        <FullRowLabelledData
          label="If taking prescription medication, who is your prescribing MD? Please include type of MD, name and phone number."
          value={data.prescription_mds}
        />
        <FullRowLabelledData
          label="Who is your primary care physician? Please include type of MD, name and phone number."
          value={data.primary_doctor}
        />
        <FullRowLabelledData
          label="Describe your current alcohol use, if any?"
          value={data.alcohol_use}
        />
        <FullRowLabelledData
          label="Describe your recreational substance use, if any?"
          value={data.recreational_substance}
        />
        <FullRowLabelledData
          label="Do you have suicidal thoughts?"
          value={data.suicidal_thoughts}
        />
        <FullRowLabelledData
          label="Have you ever attempted suicide?"
          value={data.attempted_suicide}
        />
        <FullRowLabelledData
          label="Do you have thoughts or urges to harm others?"
          value={data.harm_others}
        />
        <FullRowLabelledData
          label="Have you ever been hospitalized for a psychiatric issue?"
          value={data.psychiatric_hospitalization}
        />
        <FullRowLabelledData
          label="Is there a history of mental illness in your family?"
          value={data.mental_illness_history}
        />
        <FullRowLabelledData
          label="If you are in a relationship, please describe the nature of the relationship and months or years together."
          value={data.relationship_nature}
        />
        <FullRowLabelledData
          label="Describe your current living situation. Do you live alone, with others, with family, etc.?"
          value={data.living_situation}
        />
        <FullRowLabelledData
          label="What is your level of education? Highest grade/degree and type of degree."
          value={data.education_level}
        />
        <FullRowLabelledData
          label="What is your current occupation? What do you do? How long have you been doing it?"
          value={data.current_occupation}
        />
        <FullRowLabelledData
          label="Please check any of the following you have experienced in the past six months:"
          value={data.experienced_symptoms_past_six_months}
        />
        {!!data?.experienced_symptoms_past_six_months_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.experienced_symptoms_past_six_months_free_text}
          />
        )}
        <FullRowLabelledData
          label="Please check any of the following that apply:"
          value={data.other_experienced_symptoms}
        />
        {!!data?.other_experienced_symptoms_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.other_experienced_symptoms_free_text}
          />
        )}
        <FullRowLabelledData
          label="What else would you like me to know?"
          value={data.other_mentions}
        />
      </>
    </LayoutPDF>
  );
};

export default SolsticeIntakePDF;
