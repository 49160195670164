import React from 'react';

import './index.scss';

export const Switch = ({
  'data-testid': datatestid,
  'label-testid': labeltestid,
  ...rest
}) => (
  <label className="myswitch myswitch-left-right">
    <input
      className="myswitch-input"
      type="checkbox"
      data-testid={datatestid}
      {...rest}
    />
    <span
      className="myswitch-label"
      data-on={'YES'}
      data-off={'NO'}
      label-testid={labeltestid}
    ></span>
    <span className="myswitch-handle"></span>
  </label>
);
