import React, { Fragment } from 'react';
import Modal from '../common/Modal';
import PrimaryButton from '../common/buttons/PrimaryButton';
import SecondaryButton from '../common/buttons/SecondaryButton';
import styles from './UserConfirmation.module.scss';
import Component from '../../img/approval.svg';
import Close from '../../img/close.svg';

const UserConfirmation = ({ message, onConfirm, onCancel, buttonLabel }) => {
  return (
    <Fragment>
      <Modal className={styles.modal}>
        <div className={styles.content}>
          <div className={styles.backDiv}>
            <img
              onClick={onCancel}
              src={Close}
              alt="Close"
              data-testid="confirmation Close image"
            />
          </div>
          <div className={styles.warningImg}>
            <img src={Component} alt="Alert" data-testid="confirmation image" />
          </div>
          <p data-testid="confirmation message">{message}</p>
          <div className={styles.buttonsrow}>
            <SecondaryButton
              label="Cancel"
              className={styles.secondaryButton}
              onClick={onCancel}
              data-testid="confirmation cancel button"
            />
            <PrimaryButton
              label={buttonLabel || 'Remove'}
              className={styles.primaryButton}
              onClick={onConfirm}
              data-testid="confirmation remove button"
            />
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default UserConfirmation;
