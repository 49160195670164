import React from 'react';
import PropTypes from 'prop-types';
import styles from './AppointmentCancellation.module.scss';
import moment from 'moment';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PrimaryButton from '../common/buttons/PrimaryButton';
import FieldInput from '../common/fields/FieldInput/FieldInput';

const AppointmentCancellation = ({ appointmentData, onCancelClick }) => {
  const cancellationSchema = Yup.object().shape({
    cancellation_reason: Yup.string().required('Field Required'),
  });
  return (
    <>
      <div className={styles.root} data-testid={`appointment cancellation`}>
        <h1
          className={styles.title}
          data-testid="cancellation appointment title"
        >
          {appointmentData.booking_type_label}
        </h1>
        <div className={styles.container}>
          <div className={styles.cancelview}>
            <div className={styles.leftside}>
              <div className={styles.BookingSummary}>
                <h2 data-testid="cancellation appointment day">
                  {moment(appointmentData.start_time).format('dddd')}
                </h2>
                <h2 data-testid="cancellation appointment date">
                  {moment(appointmentData.start_time).format('MMMM  DD, YYYY')}
                </h2>
                <h4
                  className={styles.isAfter}
                  data-testid="cancellation appointment time"
                >
                  {moment(appointmentData.start_time).format('LT')} -{' '}
                  {moment(appointmentData.end_time).format('LT')}
                </h4>
              </div>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.rightside}>
              <div>
                <Formik
                  initialValues={{ cancellation_reason: '' }}
                  validationSchema={cancellationSchema}
                  onSubmit={async (values) => {
                    onCancelClick(values);
                  }}
                >
                  {({ errors, values, handleBlur, setFieldValue }) => {
                    return (
                      <Form className={styles['formcontent']}>
                        <div className={styles.formFields}>
                          <FieldInput
                            label="Reason for canceling"
                            className={styles.input}
                            name="cancellation_reason"
                            errorText={errors.cancellation_reason}
                            onChange={(e) => {
                              setFieldValue(
                                'cancellation_reason',
                                e.target.value,
                              );
                            }}
                            onBlur={handleBlur}
                            value={values.cancellation_reason}
                            date-testid="cancellation_reason"
                            label-testid="cancellation_reason label"
                            error-testid="cancellation_reason error"
                          />
                        </div>
                        <PrimaryButton
                          date-testid={`Cancel Appointment`}
                          label="Cancel Appointment"
                          className={styles.cancelAppointment}
                        />
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AppointmentCancellation.propTypes = {
  onCancelClick: PropTypes.func,
  appointmentData: PropTypes.shape({
    start_time: PropTypes.string.isRequired,
    end_time: PropTypes.string.isRequired,
    booking_type_label: PropTypes.string.isRequired,
  }),
};

export default AppointmentCancellation;
