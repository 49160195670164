import React from 'react';
import moment from 'moment';
import { Text, View } from '@react-pdf/renderer';
import FullRowLabelledData from './components/FullRowLabelledData';
import LayoutPDF from './components/LayoutPDF';
import Hr from './components/Hr';
import TitleSection from './components/TitleSection';
import SubtitleSection from './components/SubtitleSection';
import Table from './components/Table';
import { AssessmentPDFStyles } from './AssessmentPDFStyles';

const BPSIntakePDF = ({ item, formDetail, data }) => {
  return (
    <LayoutPDF item={item} formDetail={formDetail}>
      <>
        <View style={AssessmentPDFStyles.content_detail.rowContainer}>
          <FullRowLabelledData
            label="Who referred you to NEST Harm Reduction?"
            value={data && data.reference}
          />
        </View>
        {/* Step 1: Medical History */}
        <TitleSection title="Medical History" />
        <SubtitleSection title="Primary Care Physician" />
        <Hr />
        {[
          {
            label: "Physician's Name",
            value: data.physician_name,
          },
          {
            label: 'Phone Number',
            value: data.physician_phone,
          },
        ].map((el, i) => (
          <FullRowLabelledData
            key={i}
            isPrimary={true}
            label={el.label}
            value={el.value}
          />
        ))}
        <FullRowLabelledData
          label="Are there any other healthcare providers you are currently working with?"
          value={data.health_care_providers}
        />
        {!!data?.health_care_providers_list?.length && (
          <Table
            heads={['Name', 'Specialty', 'Phone Number']}
            rows={data.health_care_providers_list.map((e) => Object.values(e))}
          />
        )}
        <FullRowLabelledData
          label="Do you want your N.E.S.T. provider to contact and coordinate care with any other providers?"
          value={data.providers_contact}
        />
        <FullRowLabelledData
          label="Have you been medically hospitalized in the last 30 days?"
          value={data.medically_hospitalized}
        />
        {!!data?.physical_exam_date && (
          <FullRowLabelledData
            isPrimary={false}
            label="When was your last physical exam?"
            value={moment(data.physical_exam_date).format('MMMM DD, YYYY')}
          />
        )}
        <FullRowLabelledData
          label="Have you had a head injury since your last physical exam?"
          value={data.head_injury}
        />
        <FullRowLabelledData
          label="In the past week, have you experienced any chest pain?"
          value={data.chest_pain}
        />
        <FullRowLabelledData
          label="Do you have a personal history of any of the following (check all that apply):"
          value={data.personal_history}
        />
        {!!data?.personal_disease_input && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.personal_disease_input}
          />
        )}
        <FullRowLabelledData
          label="Has anyone in the family ever been diagnosed with any of the following (check all that apply):"
          value={data.family_diagnoses}
        />
        {!!data?.family_diagnoses_input && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.family_diagnoses_input}
          />
        )}
        {/* Step 2: Current Mental Well-Being */}
        <FullRowLabelledData
          isPrimary={false}
          label="What is the reason for your upcoming visit? What would you identify as your primary areas of concern?"
          value={data.visit_reason}
        />
        <FullRowLabelledData
          label="Please select all current and/or recurring symptoms of DEPRESSION you are experiencing. Please check all that apply."
          value={data.depression_symptoms}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="How long have you been experiencing ANY of the above symptoms? (If Any)"
          value={data.depression_symptoms_period}
        />
        <FullRowLabelledData
          label="Do the symptoms you are experiencing result in distress and/or impairment at work, socially, or in other areas of your life?"
          value={data.depression_symptoms_cause}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="If YES, please provide a brief description."
          value={data.depression_question_response}
        />
        <FullRowLabelledData
          label="Please select all current and/or recurring symptoms of ANXIETY you are experiencing. Please check all that apply."
          value={data.anxiety_symptoms}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="If you have been experiencing panic attacks, how often have they occurred?"
          value={data.panic_attacks}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="If other, please describe"
          value={data.other_anxiety_symptom}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="How long have you been experiencing ANY of the above symptoms? (If Any)"
          value={data.anxiety_symptoms_period}
        />
        <FullRowLabelledData
          label="Do the symptoms you are experiencing result in distress and/or impairment at work, socially, or in other areas of your life?"
          value={data.anxiety_symptoms_cause}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="If YES, please provide a brief description."
          value={data.anxiety_question_response}
        />
        <FullRowLabelledData
          label="Please select all current and/or recurring symptoms of STRESS or TRAUMA RESPONSE you are experiencing. Please check all that apply."
          value={data.stress_symptoms}
        />
        {!!data?.stress_symptoms_input && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.stress_symptoms_input}
          />
        )}
        <FullRowLabelledData
          isPrimary={false}
          label="How long have you been experiencing ANY of the above symptoms? (If Any)"
          value={data.traumatic_symptoms_period}
        />
        <FullRowLabelledData
          label="History of significant traumatic event(s), including being in or witnessing events such as vehicle accidents, natural disasters, abuse, war, bullying."
          value={data.traumatic_symptoms_cause}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="If YES, please indicate the type of event(s) and how old you were when each occurred"
          value={data.traumatic_question_response}
        />
        <FullRowLabelledData
          label="Do the symptoms you are experiencing result in distress and/or impairment at work, socially, or in other areas of your life?"
          value={data.stress_symptoms_cause}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="If YES, please provide a brief description."
          value={data.stress_question_response}
        />
        {/* Step 3: Current Mental Well-Being */}
        <FullRowLabelledData
          label="Please select all current and/or recurring symptoms of MANIA you are experiencing. Please check all that apply."
          value={data.mania_symptoms}
        />
        {!!data?.mania_symptoms_input && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input: "
            value={data.mania_symptoms_input}
          />
        )}
        <FullRowLabelledData
          isPrimary={false}
          label="How long have you been experiencing ANY of the above symptoms? (If Any)"
          value={data.mania_symptoms_period}
        />
        <FullRowLabelledData
          label="Do the symptoms you are experiencing result in distress and/or impairment at work, socially, or in other areas of your life?"
          value={data.mania_symptoms_cause}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="If YES, please provide a brief description."
          value={data.mania_question_response}
        />
        <FullRowLabelledData
          label="Please select all current and/or recurring symptoms of Obsessive Compulsive Disorder (OCD) you are experiencing. Please check all that apply."
          value={data.ocd_symptoms}
        />
        {!!data?.ocd_symptoms_input && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.ocd_symptoms_input}
          />
        )}
        <FullRowLabelledData
          isPrimary={false}
          label="How long have you been experiencing ANY of the above symptoms? (If Any)"
          value={data.ocd_symptoms_period}
        />
        <FullRowLabelledData
          label="Do the symptoms you are experiencing result in distress and/or impairment at work, socially, or in other areas of your life?"
          value={data.ocd_symptoms_cause}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="If YES, please provide a brief description."
          value={data.ocd_question_response}
        />
        <FullRowLabelledData
          label="Please select all current and/or recurring symptoms of BEHAVIOR CONCERNS you are experiencing. Please check all that apply."
          value={data.behavior_concerns_symptoms}
        />
        {!!data?.behavior_concerns_symptoms_input && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.behavior_concerns_symptoms_input}
          />
        )}
        <FullRowLabelledData
          isPrimary={false}
          label="How long have you been experiencing ANY of the above symptoms? (If Any)"
          value={data.behavior_concerns_symptoms_period}
        />
        <FullRowLabelledData
          label="Do the symptoms you are experiencing result in distress and/or impairment at work, socially, or in other areas of your life?"
          value={data.behavior_concerns_symptoms_cause}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="If YES, please provide a brief description."
          value={data.behavior_concerns_question_response}
        />
        {/* Step 4: Current Mental Well-Being */}
        <FullRowLabelledData
          label="Please select all current and/or recurring symptoms of ATTENTIONAL ISSUES you are experiencing. Please check all that apply."
          value={data.attentional_issues_symptoms}
        />
        {!!data?.attentional_issues_symptoms_input && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.attentional_issues_symptoms_input}
          />
        )}
        <FullRowLabelledData
          isPrimary={false}
          label="How long have you been experiencing ANY of the above symptoms? (If Any)"
          value={data.attentional_issues_symptoms_period}
        />
        <FullRowLabelledData
          label="Do the symptoms you are experiencing result in distress and/or impairment at work, socially, or in other areas of your life?"
          value={data.attentional_issues_symptoms_cause}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="If YES, please provide a brief description."
          value={data.attentional_issues_question_response}
        />
        <FullRowLabelledData
          label="Please select all current and/or recurring symptoms of EATING PROBLEMS you are experiencing. Please check all that apply."
          value={data.eating_problems_symptoms}
        />
        {!!data?.eating_problems_symptoms_input && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.eating_problems_symptoms_input}
          />
        )}
        <FullRowLabelledData
          isPrimary={false}
          label="How long have you been experiencing ANY of the above symptoms? (If Any)"
          value={data.eating_problems_symptoms_period}
        />
        <FullRowLabelledData
          label="Do the symptoms you are experiencing result in distress and/or impairment at work, socially, or in other areas of your life?"
          value={data.eating_problems_symptoms_cause}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="If YES, please provide a brief description."
          value={data.eating_problems_question_response}
        />
        <FullRowLabelledData
          label="Please select all current and/or recurring symptoms of PSYCHOSIS you are experiencing. Please check all that apply."
          value={data.psychosis_symptoms}
        />
        {!!data?.psychosis_symptoms_input && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.psychosis_symptoms_input}
          />
        )}
        <FullRowLabelledData
          isPrimary={false}
          label="How long have you been experiencing ANY of the above symptoms? (If Any)"
          value={data.psychosis_symptoms_period}
        />
        <FullRowLabelledData
          label="Do the symptoms you are experiencing result in distress and/or impairment at work, socially, or in other areas of your life?"
          value={data.psychosis_symptoms_cause}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="If YES, please provide a brief description."
          value={data.psychosis_question_response}
        />
        {/* Step 5: Substance Use */}
        <SubtitleSection title="Substance Use" />
        <Hr />
        <SubtitleSection title="Please be open and honest, substance use is addressed from a harm reduction approach and NEST providers do not require sobriety/abstinence" />
        <FullRowLabelledData
          label="Do you have a history of abusing or overusing substances, such as alcohol, medications, or drugs?"
          value={data.substance_history}
        />
        <FullRowLabelledData
          label="Have you ever felt that you ought to cut down on your drinking or drug use?"
          value={data.drinking_or_drug_use}
        />
        <FullRowLabelledData
          label="Have people annoyed you by criticizing your use?"
          value={data.use_criticizing}
        />
        <FullRowLabelledData
          label="Have you ever felt bad or guilty about your use?"
          value={data.felt_bad}
        />
        <FullRowLabelledData
          label="Have you ever had to use first thing in the morning to steady your nerves or to get rid of a hangover?"
          value={data.hangover}
        />
        <SubtitleSection title="Past Mental Health Treatment" />
        <Hr />
        <FullRowLabelledData
          label="Have you had any past therapy or psychiatric treatment (including childhood)? Check all that apply."
          value={data.psychiatric_treatment}
        />
        {!!data?.past_treatment_facilities?.length && (
          <>
            <Text style={AssessmentPDFStyles.content_detail.span}>
              Please list any past provider/facility name(s), the month/year of
              treatment, length of treatment, and issues addressed:
            </Text>
            <Table
              heads={[
                'Provider/Facility Name',
                'Treatment Date',
                'Length of Treatment',
                'Issues Addressed',
              ]}
              rows={data.past_treatment_facilities.map((e) => Object.values(e))}
            />
          </>
        )}
        <FullRowLabelledData
          label="Have you previously been diagnosed with any mental health conditions?"
          value={data.previous_mental_conditions}
        />
        {!!data?.previous_mental_conditions_input && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.previous_mental_conditions_input}
          />
        )}
        <FullRowLabelledData
          isPrimary={false}
          label="Please list any previous medications for mental health concerns or diagnosis (i.e. depression, anxiety, attention, etc.):"
          value={data.previous_medications}
        />
        <FullRowLabelledData
          label="Has any of your family previously been diagnosed with any of the following mental health conditions?"
          value={data.family_mental_conditions}
        />
        {!!data?.family_mental_conditions_input && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.family_mental_conditions_input}
          />
        )}
        <FullRowLabelledData
          isPrimary={false}
          label="If YES, please provide a brief description."
          value={data.mental_conditions_question_response}
        />
        <FullRowLabelledData
          label="Has anyone in your family attempted/completed suicide?"
          value={data.family_suicide}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="If YES, please describe their relationship to you."
          value={data.your_family_relationship}
        />
        {/* Step 6: Family and Social History */}
        <SubtitleSection title="Family and Social History" />
        <Hr />
        <FullRowLabelledData
          isPrimary={false}
          label="Sex Assigned at Birth"
          value={data.sex_at_birth}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="Gender Identity"
          value={data.gender_identity}
        />
        {!!data?.preferred_gender && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.preferred_gender}
          />
        )}
        <FullRowLabelledData
          isPrimary={false}
          label="Sexual Orientation"
          value={data.sexual_orientation}
        />
        {!!data?.other_sexual_orientation && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.other_sexual_orientation}
          />
        )}
        <FullRowLabelledData
          isPrimary={false}
          label="Relationship Status"
          value={data.relationship_status}
        />
        {!!data?.other_relationship_status && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.other_relationship_status}
          />
        )}
        <FullRowLabelledData
          isPrimary={false}
          label="If currently in a relationship, please indicate"
          value={data.relationship_type}
        />
        {!!data?.other_relationship_type && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.other_relationship_type}
          />
        )}
        <FullRowLabelledData
          isPrimary={false}
          label="Number of children"
          value={data.nr_of_children}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="Number of pregnancies"
          value={data.nr_of_pregnancies}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="Number of adoptions"
          value={data.nr_of_adoptions}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="Who do you reside with?"
          value={data.resident_type}
        />
        {!!data?.other_resident_type && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.other_resident_type}
          />
        )}

        <FullRowLabelledData
          isPrimary={false}
          label="What is your current employment status?"
          value={data.employment_status}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="What is your primary source of income?"
          value={data.income_source}
        />
        {!!data?.other_income_source && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.other_income_source}
          />
        )}
        <FullRowLabelledData
          isPrimary={false}
          label="Who is your source of emotional support?"
          value={data.emotional_support}
        />
        {!!data?.other_emotional_support && (
          <FullRowLabelledData
            isPrimary={false}
            label="Other input:"
            value={data.other_emotional_support}
          />
        )}
        <FullRowLabelledData
          isPrimary={false}
          label="Who is your source of emotional support?"
          value={data.emotional_support}
        />
        <FullRowLabelledData
          label="Did you meet all developmental milestones (such as crawling, walking, talking, fine motor) within age appropriate limits?"
          value={data.developmental_milestones}
        />
        {!!data.developmental_delays && (
          <FullRowLabelledData
            isPrimary={false}
            label="If no, please explain (developmental delays or learning disabilities requiring interventions such as physical, occupational or speech therapy, special education)"
            value={data.developmental_delays}
          />
        )}
        <FullRowLabelledData
          isPrimary={false}
          label="Describe how your family gets along and relates to one another (currently and in the past)"
          value={data.family_relationship}
        />
        <SubtitleSection title="Legal History" />
        <Hr />
        <FullRowLabelledData
          label="Do you have a history of legal involvement? (Arrested or incarcerated for any reason?)"
          value={data.legal_involvement}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="If yes, please list details"
          value={data.other_legal_involvement}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="Current legal status"
          value={data.legal_status}
        />
        <FullRowLabelledData
          label="Are you court-ordered to receive mental health treatment?"
          value={data.mental_health_treatment}
        />
        <SubtitleSection title="Cultural History and Preferences" />
        <Hr />
        <FullRowLabelledData
          label="Has religion or spirituality played an important role in your life?"
          value={data.religion_role}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="If yes, please describe practices you engage in to express your beliefs (i.e. attending formal services, prayer, meditation, chanting, ceremonies, rituals, etc.)"
          value={data.religion_practices}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="How would you describe your racial identity and/or ethnic background?"
          value={data.racial_identity}
        />
        <FullRowLabelledData
          label="Were you raised in a specific culture that you feel may influence your recovery?"
          value={data.specific_culture}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="List any hobbies, recreational interests, sports, games, or other leisure activities that you enjoy"
          value={data.hobbies}
        />
        <FullRowLabelledData
          label="Has there been any recent changes in your interests and activities as a result of your mental health and/or substance use issues?"
          value={data.mental_health_result}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="What are your goals in therapy? How will you know therapy has been successful/what will have changed?"
          value={data.therapy_goals}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="What do you foresee as possible barriers for making change in your life?"
          value={data.possible_barriers}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="What are your needs/preferences in therapy, such as cultural, religious or therapeutic needs and preferences?"
          value={data.therapy_needs}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="What are your current strengths?"
          value={data.current_strengths}
        />
        <FullRowLabelledData
          isPrimary={false}
          label="What else would be helpful for the therapist to know about to partner with you towards these goals?"
          value={data.therapist_advice}
        />
      </>
    </LayoutPDF>
  );
};

export default BPSIntakePDF;
