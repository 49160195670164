import React, { useEffect, useState, Fragment, useContext } from 'react';
import styles from './MarketingCopy.module.scss';
import AuthContext from '../../context/AuthContext';
import { getEntries } from '../../api/contentful';

const MarketingCopy = () => {
  const { authUser } = useContext(AuthContext);
  const [show, setShow] = useState(true);
  const [copy, setCopy] = useState();
  const hostname = window && window.location && window.location.hostname;

  useEffect(() => {
    const fetchContent = async () => {
      const clinicMarketingBannerKey =
        authUser?.whitelabelDetails?.marketing_banner_key;
      const content = await getEntries({
        'fields.key': clinicMarketingBannerKey || 'marketing-copy',
        content_type: 'marketing',
      });
      setCopy(content.items[0]?.fields);
    };

    fetchContent();
  }, [authUser, hostname]);

  return (
    <Fragment>
      {show && copy && (
        <div className={styles.root} data-testid="marketing copy">
          <div className={styles.img}>
            <img
              src={copy.image.fields.file.url}
              alt="marketing"
              data-testid="marketing image"
            ></img>
          </div>
          <div className={styles.content}>
            <h2 data-testid="marketing title">{copy.contentTitle}</h2>
            <div className={styles.text} data-testid="marketing description">
              {copy.description}
            </div>
          </div>
          <div
            data-testid="marketing close button"
            className={styles.closeButton}
            onClick={() => setShow(false)}
          >
            <img
              src={require(`../../img/icons/close-white.png`)}
              alt={`Close`}
              data-testid="marketing close button image"
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default MarketingCopy;
