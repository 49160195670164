import React, { Fragment, useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import Section from '../../components/common/Section';
import styles from './FormAsessmentPage.module.scss';
import useIntl from '../../hooks/useIntl';
import messages from './messages';
import AuthContext from '../../context/AuthContext';
import { getEntries } from '../../api/contentful';
import SwipeCardInfo from '../../components/common/SwipeCardInfo';
import { DiscoverContext } from '../../context/DiscoverContext';
import Progress from '../../components/Progress';
import { saveWeeklyAssessments } from '../../api/user';
import { getLastAppointment } from '../../api/appointment';
import { addToDataLayer } from '../../utils/common';
import AssessmentInputs from '../../components/AssessmentInputs';

const formatTitle = ({ title, param }, answers) =>
  !param ? title : title.replace('*', answers[param].value);

const handleBackPage = (page, results, setPage, setDirection) => {
  let previousPage = page - 1;
  let evaluatedPage = results[previousPage];
  while (previousPage > 0 && evaluatedPage.type === 'modal') {
    previousPage--;
    evaluatedPage = results[previousPage];
  }
  setDirection(-1);
  return setPage(previousPage);
};

const mapResults = (items, state, formatMessage) => {
  if (state && state.firstName) {
    return items.reduce((acc, item) => {
      if (item.position === 0) {
        return [
          ...acc,
          {
            position: 0,
            type: 'transition',
            title: (
              <FormattedMessage
                {...messages.welcomeBackText}
                values={{ name: state.firstName }}
              />
            ),
            'jump-to-position': 4,
          },
        ];
      }
      return [...acc, item];
    }, []);
  }
  return items;
};

let currentPage;

const FormAsessmentPage = ({ match, location }) => {
  const questionnaireSlug =
    match && match.params && match.params.questionnaireSlug
      ? match.params.questionnaireSlug
      : 'eve-discover';
  const { authUser } = useContext(AuthContext);
  const [answers, setAnswers] = useState({});
  const [page, setPage] = useState(0);
  const [, /*random*/ setRandom] = useState(1);
  const [direction, setDirection] = useState(1);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { formatMessage } = useIntl();
  const discover = useContext(DiscoverContext);
  const history = useHistory();
  const [isLastStep, setIsLastStep] = useState(0);
  const [assessment, setAssessment] = useState({});
  const [fact_form_id /*setDrName*/] = useState(
    (location.state && location.state.fact_form_id) || '',
  );

  useEffect(() => {
    const fetchQuesionnaire = async () => {
      try {
        let questionnaire = await getEntries({
          'fields.key': questionnaireSlug,
          content_type: 'recommendationEngineQuestionnaire',
        });
        if (
          questionnaire.items.length > 0 &&
          questionnaire.items[0].fields['questions']
        ) {
          setQuestionnaire(questionnaire.items[0].fields['questions']);
          setAssessment(questionnaire.items[0]);
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
      }
    };
    fetchQuesionnaire();
  }, [questionnaireSlug]);
  useEffect(() => {
    if (Object.entries(answers).length > 0) {
      const progress = (page / questionnaire.length) * 100;
      console.log('progress: ', progress);
      discover.setProgress(progress);
    }
    if (questionnaire.length && Object.entries(answers).length === 0) {
      const initialAnswers = questionnaire.reduce(
        (
          acc,
          { key, parent, type, marks, persist, subtype, defaultValue = 3 },
        ) =>
          key
            ? {
                ...acc,
                [key]:
                  type === 'slider'
                    ? {
                        value: defaultValue,
                        min: subtype === 'number' ? marks[0].value : 1,
                        max:
                          marks[0].value === 0
                            ? marks.length - 1
                            : marks.length,
                        persist: persist,
                      }
                    : {},
              }
            : acc,
        {},
      );
      setAnswers({
        ...initialAnswers,
        first_name:
          authUser.first_name || (location.state && location.state.firstName)
            ? { value: authUser.first_name || location.state.firstName }
            : {},
      });
    }
    discover.setStep(page);
  }, [
    answers,
    authUser.first_name,
    location.state,
    questionnaire,
    discover,
    page,
  ]);

  currentPage = mapResults(questionnaire, location.state, formatMessage)[page];
  if (currentPage && currentPage.parent) {
    const parentOptions = answers[currentPage.parent].options;
    if (!parentOptions.find((option) => option.key === currentPage.key)) {
      setAnswers({ ...answers, [currentPage.key]: undefined });
      setPage(page + direction);
    }
  }
  const onCardInforButtonClick = (item, isRight) => {
    setAnswers({
      ...answers,
      [item.key]: {
        value: isRight ? 'yes' : 'no',
        key: item.key,
        to: page + 1,
      },
    });
    const eventData = {
      event: 'ga4_event',
      properties: {
        event_name: 'assessment',
        clinician_id: 'NA',
        clinician_name: 'NA',
        patient_id: authUser ? authUser.id : '',
        step_name: (currentPage && currentPage.title) || '',
        step_number: page,
        details: {
          value: isRight ? 'yes' : 'no',
        },
        assessment_name: assessment.fields['title'],
        [currentPage.key]: {
          value: isRight ? 'yes' : 'no',
        },
      },
    };
    addToDataLayer(eventData);
    setPage(answers[item.key].to || page + 1);
  };

  document.onkeydown = (e) => {
    e = e || window.event;
    if (e.keyCode && (e.keyCode === 37 || e.keyCode === 39)) {
      if (currentPage.type === 'swipe-card') {
        onCardInforButtonClick(currentPage, e.keyCode === 39);
      }
    }
  };

  useEffect(() => {
    const saveAnswer = async () => {
      try {
        const appointment = await getLastAppointment(authUser.id);
        let doctorId = null;
        if (appointment && appointment.doctor_id) {
          doctorId = appointment.doctor_id;
        }
        await saveWeeklyAssessments({
          assessment_name: assessment.fields['title'],
          external_id: assessment.sys['id'],
          answers,
          patient_id: authUser.id,
          assessment_type: assessment.fields['key'],
          doctor_id: doctorId,
          clinic_id: (authUser.invites && authUser.invites.clinic_id) || 0,
          fact_form_id: fact_form_id,
        });
        setIsLoading(false);
        if (history.assessments && history.assessments.length > 0) {
          const currentAsssessment = history.assessments.pop();
          history.push(currentAsssessment.url, {
            fact_form_id: currentAsssessment.id,
          });
        } else {
          history.push('/my-account/intake-forms');
        }
      } catch (err) {
        setIsLoading(false);
      }
    };
    if (isLastStep) {
      saveAnswer();
    }
  }, [
    answers,
    assessment.fields,
    assessment.sys,
    authUser.id,
    authUser.invites,
    fact_form_id,
    history,
    isLastStep,
  ]);

  return (
    <Section>
      <Helmet>
        <meta
          name="description"
          content={formatMessage(messages.metaDescription)}
        />
      </Helmet>
      <div
        className={classnames(
          styles[`quiz-container-bg`],
          styles[`container-background`],
        )}
      >
        {page !== 0 && (
          <div className={styles.header}>
            <div className={styles['header-left']}>
              <span>
                {answers &&
                  answers.first_name &&
                  answers.first_name.value &&
                  `Welcome ${answers.first_name.value}`}
              </span>
            </div>
            <div
              className={styles['header-right']}
              style={{ maxHeight: '5px' }}
            >
              <div>
                <Progress width={103} height={5} percent={discover.progress} />
              </div>
            </div>
          </div>
        )}
        <div className={styles.lineArt}>
          <img src={require(`../../img/LineArt.svg`)} alt="line-art" />
        </div>
        {!isLoading && (
          <Formik
            onSubmit={() => {
              setDirection(1);
              const eventData = {
                event: 'ga4_event',
                properties: {
                  event_name: 'assessment',
                  clinician_id: 'NA',
                  clinician_name: 'NA',
                  patient_id: authUser ? authUser.id : '',
                  step_name: (currentPage && currentPage.title) || '',
                  details: answers[currentPage.key],
                  step_number: page,
                  assessment_name: assessment.fields['title'],
                  [currentPage.key]: answers[currentPage.key],
                },
              };
              addToDataLayer(eventData);
              setPage(
                (page !== 0 &&
                  currentPage.type !== 'modal' &&
                  currentPage.type !== 'transition-button' &&
                  answers[currentPage?.key].to) ||
                  page + 1,
              );
              setRandom(Math.floor(Math.random() * 5) + 1);
            }}
          >
            {({ errors, initialValues }) => (
              <Fragment>
                <Form
                  className={
                    currentPage.type === 'modal' ||
                    currentPage.type === 'swipe-card'
                      ? styles['modal-content']
                      : styles['content']
                  }
                >
                  <div className={classnames(styles[`form-div`])}>
                    {currentPage.image && (
                      <div
                        className={classnames(
                          styles[`icon`],
                          styles[`${currentPage.image}`],
                        )}
                      >
                        <img
                          className={classnames(styles[`img-bg`])}
                          src={require(`../../img/icons/${currentPage.image}.svg`)}
                          alt={currentPage.title}
                        />
                      </div>
                    )}
                    {page === 0 ? (
                      <h1>{currentPage.title}</h1>
                    ) : (
                      <h2>{formatTitle(currentPage, answers)}</h2>
                    )}
                    {!currentPage.swipCardInfo && currentPage.subtitle && (
                      <div className={styles['sub-text']}>
                        {currentPage.subtitle}
                      </div>
                    )}
                    {page === 0 && currentPage.cta && (
                      <div className={classnames(styles[`button-row`])}>
                        <PrimaryButton
                          label={currentPage.cta}
                          className={classnames(styles[`button`])}
                        />
                      </div>
                    )}
                    <AssessmentInputs
                      currentPage={currentPage}
                      answers={answers}
                      setAnswers={setAnswers}
                      page={page}
                      setPage={setPage}
                      initialValues={initialValues}
                      formatMessage={formatMessage}
                      history={history}
                      isLastStep={isLastStep}
                      setIsLastStep={setIsLastStep}
                      assessment={assessment}
                      authUser={authUser}
                    />
                    {errors[currentPage.key] && (
                      <div className={styles.error}>
                        {errors[currentPage.key]}
                      </div>
                    )}
                  </div>
                  {page !== 0 && currentPage.type !== 'transition' && (
                    <div
                      className={
                        currentPage.type === 'swipe-card'
                          ? styles['single-button-row']
                          : styles['buttons-row']
                      }
                    >
                      {currentPage.key !== 'name' && (
                        <div
                          className={classnames(styles[`buttons-row-left`])}
                          onClick={() =>
                            handleBackPage(
                              page,
                              questionnaire,
                              setPage,
                              setDirection,
                            )
                          }
                        >
                          <span className={classnames(styles[`back-button`])}>
                            {formatMessage(messages.backLabel)}
                          </span>
                        </div>
                      )}
                      {currentPage.cta && (
                        <div
                          className={
                            currentPage.key !== 'name'
                              ? styles['buttons-row-right']
                              : ''
                          }
                        >
                          <PrimaryButton
                            disabled={
                              currentPage.type !== 'slider' &&
                              currentPage.key &&
                              (!answers[currentPage.key].value ||
                                answers[currentPage.key].value.length === 0) &&
                              (!answers[currentPage.key].options ||
                                answers[currentPage.key].options.length ===
                                  0) &&
                              currentPage.noResponse !== 'true'
                            }
                            onClick={() => {
                              currentPage.type === 'slider' &&
                                (!isNaN(
                                  Number(answers[currentPage.key].value),
                                ) ||
                                  setAnswers({
                                    ...answers,
                                    [currentPage.key]: {
                                      value: 3,
                                      min: 1,
                                      max: currentPage.marks.length,
                                      persist: currentPage.persist,
                                    },
                                  }));
                              currentPage.noResponse === 'true' &&
                                setAnswers({
                                  ...answers,
                                  [currentPage.key]: { value: 'no-response' },
                                });
                            }}
                            label={
                              currentPage.type !== 'modal'
                                ? currentPage.cta
                                : 'Next'
                            }
                            className={classnames(styles[`button`])}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Form>
                <Fragment>
                  {currentPage.swipCardInfo && currentPage.type !== 'modal' && (
                    <div className={styles.swipeCardContent}>
                      <div className={styles.swipeCardInfo}>
                        <SwipeCardInfo
                          onClickYes={() =>
                            onCardInforButtonClick(currentPage, true)
                          }
                          onClickNo={() =>
                            onCardInforButtonClick(currentPage, false)
                          }
                        />
                      </div>
                    </div>
                  )}
                </Fragment>
              </Fragment>
            )}
          </Formik>
        )}
      </div>
    </Section>
  );
};

export default FormAsessmentPage;
