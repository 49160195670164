import React from 'react';
import styles from '../WildHeartForm.module.scss';

const SectionTitle = ({ label }) => {
  return (
    <b>
      <p className={styles.formSubtitle}>{label}</p>
    </b>
  );
};

export default SectionTitle;
