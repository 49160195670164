import { FieldArray } from 'formik';
import React, { useEffect } from 'react';
import { Cell } from 'react-foundation';
import useIntl from '../../../../../hooks/useIntl';
import AddedItem from '../../../../common/AddedItem/AddedItem';
import PrimaryButton from '../../../../common/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/buttons/SecondaryButton';
import FieldInput from '../../../../common/fields/FieldInput';
import RadioGroupWithLabel from '../../../../common/inputs/RadioGroupWithLabel';
import TextInput from '../../../../common/inputs/TextInput';
import Loading from '../../../../common/Loading';
import SuperBillMessageModal from '../../../../SuperBillForm/SuperBillMessageModal/SuperBillMessageModal';
import styles from '../../PersonalInfoForm/PersonalInfoForm.module.scss';
import {
  basicResponses,
  binaryResponses,
  previousMentalConditions,
  psychiatricTreatment,
} from '../data';
import FacilitiesForm from '../FacilitiesForm/FacilitiesForm';
import messages from '../messages';
import SymptomsForm from '../SymptomsForm/SymptomsForm';

const BPSFormStepFive = ({
  setFacilityValue,
  editItemFacility,
  facilityValue,
  values,
  handleChange,
  setEditItemFacility,
  handleBlur,
  isLoading,
  setFieldValue,
  handleCheckboxChange,
}) => {
  const { formatMessage } = useIntl();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Cell small={12}>
        <h4 className={styles.section}>
          {formatMessage(messages.substanceUse)}
        </h4>
      </Cell>
      <hr className={styles.form.hr} />
      <Cell small={12}>
        <h4 className={styles.subsection}>
          {formatMessage(messages.substanceUse.substanceSectionDescription)}
        </h4>
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(messages.substanceUse.substancesHistory)}
          componentName="substance_history"
          options={binaryResponses}
          dataTestId="BPSFormStepFive.SubstanceUse.SubstancesHistory.RadioGroup"
          labelTestId="BPSFormStepFive.SubstanceUse.SubstancesHistory.Label"
          value={values.substance_history}
          onChange={(value) => setFieldValue('substance_history', value)}
        />
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(messages.substanceUse.drinkingOrDrugUse)}
          componentName="drinking_or_drug_use"
          options={basicResponses}
          dataTestId="BPSFormStepFive.SubstanceUse.DrinkingOrDrugUse.RadioGroup"
          labelTestId="BPSFormStepFive.SubstanceUse.DrinkingOrDrugUse.Label"
          value={values.drinking_or_drug_use}
          onChange={(value) => setFieldValue('drinking_or_drug_use', value)}
        />
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(messages.substanceUse.useCriticizing)}
          componentName="use_criticizing"
          options={binaryResponses}
          dataTestId="BPSFormStepFive.SubstanceUse.UseCriticizing.RadioGroup"
          labelTestId="BPSFormStepFive.SubstanceUse.UseCriticizing.Label"
          value={values.use_criticizing}
          onChange={(value) => setFieldValue('use_criticizing', value)}
        />
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(messages.substanceUse.feltBad)}
          componentName="felt_bad"
          options={binaryResponses}
          dataTestId="BPSFormStepFive.SubstanceUse.FeltBad.RadioGroup"
          labelTestId="BPSFormStepFive.SubstanceUse.FeltBad.Label"
          value={values.felt_bad}
          onChange={(value) => setFieldValue('felt_bad', value)}
        />
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(messages.substanceUse.hangover)}
          componentName="hangover"
          options={binaryResponses}
          dataTestId="BPSFormStepFive.SubstanceUse.Hangover.RadioGroup"
          labelTestId="BPSFormStepFive.SubstanceUse.Hangover.Label"
          value={values.hangover}
          onChange={(value) => setFieldValue('hangover', value)}
        />
      </Cell>
      <Cell small={12}>
        <h4 className={styles.section}>
          {formatMessage(messages.pastTreatment)}
        </h4>
      </Cell>
      <hr className={styles.form.hr} />
      <Cell small={12}>
        <RadioGroupWithLabel
          type="checkbox"
          label={formatMessage(messages.pastTreatment.psychiatricTreatment)}
          componentName="psychiatric_treatment"
          options={psychiatricTreatment}
          dataTestId="BPSFormStepFive.PastTreatment.PsychiatricTreatment.RadioGroup"
          labelTestId="BPSFormStepFive.PastTreatment.PsychiatricTreatment.Label"
          value={values.psychiatric_treatment}
          onChange={(value) => {
            const arrOfItems = handleCheckboxChange(
              values.psychiatric_treatment,
              value,
            );
            setFieldValue('psychiatric_treatment', arrOfItems);
          }}
        />
      </Cell>
      <Cell small={12}>
        <h5>{formatMessage(messages.pastTreatment.pastFacility)}</h5>
      </Cell>
      <FieldArray
        name="past_treatment_facilities"
        render={({ remove, push }) => (
          <>
            <FacilitiesForm
              push={push}
              values={values?.past_treatment_facilities}
              facilityValue={!editItemFacility ? facilityValue : null}
              setFacilityValue={setFacilityValue}
            />
            {values?.past_treatment_facilities?.length > 0 && (
              <Cell>
                <AddedItem
                  displayFields={[
                    'facility_name',
                    'treatment_date',
                    'treatment_length',
                    'issues_addressed',
                  ]}
                  displayEditItem="none"
                  remove={remove}
                  items={values?.past_treatment_facilities}
                  maxLength={10}
                  setEditItem={setEditItemFacility}
                />
              </Cell>
            )}
            {!!editItemFacility && editItemFacility.delete && (
              <SuperBillMessageModal type="error">
                <h1>Deleting Facilities</h1>
                <p>Are you sure do you want to proceed?</p>
                <div className={styles.buttonsWrapperDelete}>
                  <SecondaryButton
                    data-testid="BPSFormStepFive.PastTreatment.CancelButton"
                    className={styles.cancel}
                    onClick={() => {
                      setEditItemFacility();
                    }}
                    label={'Cancel'}
                  />
                  <PrimaryButton
                    data-testid="BPSFormStepFive.PastTreatment.YesButton"
                    label="Yes"
                    onClick={() => {
                      remove(editItemFacility.index);
                      setEditItemFacility();
                    }}
                  />
                </div>
              </SuperBillMessageModal>
            )}
          </>
        )}
      />
      <Cell small={12}>
        <RadioGroupWithLabel
          type="checkbox"
          label={formatMessage(messages.pastTreatment.previousMentalConditions)}
          componentName="previous_mental_conditions"
          options={previousMentalConditions}
          dataTestId="BPSFormStepFive.PastTreatment.PreviousMentalConditions.RadioGroup"
          labelTestId="BPSFormStepFive.PastTreatment.PreviousMentalConditions.Label"
          value={values.previous_mental_conditions}
          onChange={(value) => {
            const arrOfItems = handleCheckboxChange(
              values.previous_mental_conditions,
              value,
            );
            setFieldValue('previous_mental_conditions', arrOfItems);
          }}
        />
        {values.previous_mental_conditions.includes('other') && (
          <TextInput
            placeholder="Enter here"
            name="previous_mental_conditions_input"
            value={values.previous_mental_conditions_input}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepFive.PastTreatment.PreviousMentalConditions.Input"
          />
        )}
      </Cell>
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.pastTreatment.previousMedications)}
          placeholder="Enter here"
          name="previous_medications"
          value={values.previous_medications}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepFive.PastTreatment.PreviousMedications.Input"
        />
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          type="checkbox"
          label={formatMessage(messages.pastTreatment.familyMentalConditions)}
          componentName="family_mental_conditions"
          options={previousMentalConditions}
          dataTestId="BPSFormStepFive.PastTreatment.FamilyMentalConditions.RadioGroup"
          labelTestId="BPSFormStepFive.PastTreatment.FamilyMentalConditions.Label"
          value={values.family_mental_conditions}
          onChange={(value) => {
            const arrOfItems = handleCheckboxChange(
              values.family_mental_conditions,
              value,
            );
            setFieldValue('family_mental_conditions', arrOfItems);
          }}
        />
        {values.family_mental_conditions.includes('other') && (
          <TextInput
            placeholder="Enter here"
            name="family_mental_conditions_input"
            value={values.family_mental_conditions_input}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepFive.PastTreatment.FamilyMentalConditions.Input"
          />
        )}
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(messages.pastTreatment.familySuicide)}
          componentName="family_suicide"
          options={binaryResponses}
          dataTestId="BPSFormStepFive.PastTreatment.FamilySuicide.RadioGroup"
          labelTestId="BPSFormStepFive.PastTreatment.FamilySuicide.Label"
          value={values.family_suicide}
          onChange={(value) => setFieldValue('family_suicide', value)}
        />
      </Cell>
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.pastTreatment.yourFamilyRelationship)}
          placeholder="Enter here"
          name="your_family_relationship"
          value={values.your_family_relationship}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepFive.PastTreatment.YourFamilyRelationship.Input"
        />
      </Cell>
    </>
  );
};

BPSFormStepFive.propTypes = {};

BPSFormStepFive.defaultProps = {};

export default BPSFormStepFive;
