import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import RadioButton from '../../../common/inputs/RadioButton';
import errorStyles from '../../../common/Label/Label.module.scss';
import styles from '../../../forms/user/PersonalInfoForm/PersonalInfoForm.module.scss';
import Label from '../../Label';

const RadioGroupWithLabel = ({
  options,
  componentName,
  value,
  dataTestId,
  label,
  labelTestId,
  onChange,
  type,
  errorText,
}) => {
  return (
    <>
    <Label errorText={errorText}>{label}</Label>
      {options.map((option, index) => (
        <div key={index} className={styles.radioButtonList}>
          <Field
            component={(field) => (
              <RadioButton {...field} name={componentName} type={type} />
            )}
            value={option.value}
            htmlFor={componentName + option.value}
            name={componentName}
            checked={option.value === value || value.includes(option.value)}
            onChange={onChange}
            labelName={option.label}
            data-testid={dataTestId}
            label-testid={labelTestId}
          />
        </div>
      ))}
      <div className={errorStyles['error-label']}>{errorText}</div>
    </>
  );
};

RadioGroupWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  componentName: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  dataTestId: PropTypes.string,
  labelTestId: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

RadioGroupWithLabel.defaultProps = {
  value: '',
  label: null,
};

export default RadioGroupWithLabel;
