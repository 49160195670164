import { defineMessages } from 'react-intl';

export default defineMessages({
  formTitle: {
    id: 'components.SolsticeForm.formTitle',
    defaultMessage: 'Confidential Client Information',
  },
  formInfoStepOne: {
    id: 'components.SolsticeForm.formInfoStepOne',
    defaultMessage:
      'Welcome to our practice. We want to make the most of each appointment you have with us. One way of doing this is for you to write down some basic information in advance of your first appointment. Please fill out the following as completely as possible. This information is confidential.',
  },
  formInfoStepTwo: {
    id: 'components.SolsticeForm.formInfoStepTwo',
    defaultMessage:
      'Please fill out the following as completely as possible. This information is confidential.',
  },
  practiceHeardOfOrigin: {
    id: 'components.SolsticeForm.practiceHeardOfOrigin',
    defaultMessage: 'How did you find out about our practice?',
  },
  counselingReason: {
    id: 'components.SolsticeForm.counselingReason',
    defaultMessage:
      'What brings you to counseling at this time? Is there something specific, such as a particular event? Be as detailed as you can.',
  },
  counselingGoals: {
    id: 'components.SolsticeForm.counselingGoals',
    defaultMessage: 'What are your goals for counseling?',
  },
  psychotherapyHistory: {
    id: 'components.SolsticeForm.psychotherapyHistory',
    defaultMessage: 'What has psychotherapy been like for you in the past?',
  },
  currentMedicationAndReason: {
    id: 'components.SolsticeForm.currentMedicationAndReason',
    defaultMessage:
      'Specify all medications and supplements you are presently taking and for what reason.',
  },
  prescriptionMds: {
    id: 'components.SolsticeForm.prescriptionMds',
    defaultMessage:
      'If taking prescription medication, who is your prescribing MD? Please include type of MD, name and phone number.',
  },
  primaryDoctor: {
    id: 'components.SolsticeForm.primaryDoctor',
    defaultMessage:
      'Who is your primary care physician? Please include type of MD, name and phone number.',
  },
  alcoholUse: {
    id: 'components.SolsticeForm.alcoholUse',
    defaultMessage: 'Describe your current alcohol use, if any?',
  },
  recreationalSubstance: {
    id: 'components.SolsticeForm.recreationalSubstance',
    defaultMessage: 'Describe your recreational substance use, if any?',
  },
  suicidalThoughts: {
    id: 'components.SolsticeForm.suicidalThoughts',
    defaultMessage: 'Do you have suicidal thoughts?',
  },
  attemptedSuicide: {
    id: 'components.SolsticeForm.attemptedSuicide',
    defaultMessage: 'Have you ever attempted suicide?',
  },
  harmOthers: {
    id: 'components.SolsticeForm.harmOthers',
    defaultMessage: 'Do you have thoughts or urges to harm others?',
  },
  psychiatricHospitalization: {
    id: 'components.SolsticeForm.psychiatricHospitalization',
    defaultMessage: 'Have you ever been hospitalized for a psychiatric issue?',
  },
  mentalIllnessHistory: {
    id: 'components.SolsticeForm.mentalIllnessHistory',
    defaultMessage: 'Is there a history of mental illness in your family?',
  },
  relationshipNature: {
    id: 'components.SolsticeForm.relationshipNature',
    defaultMessage:
      'If you are in a relationship, please describe the nature of the relationship and months or years together.',
  },
  livingSituation: {
    id: 'components.SolsticeForm.livingSituation',
    defaultMessage:
      'Describe your current living situation. Do you live alone, with others, with family, etc.?',
  },
  educationLevel: {
    id: 'components.SolsticeForm.educationLevel',
    defaultMessage:
      'What is your level of education? Highest grade/degree and type of degree.',
  },
  currentOccupation: {
    id: 'components.SolsticeForm.currentOccupation',
    defaultMessage:
      'What is your current occupation? What do you do? How long have you been doing it?',
  },
  experiencedSymptomsPastSixMonths: {
    id: 'components.SolsticeForm.experiencedSymptomsPastSixMonths',
    defaultMessage:
      'Please check any of the following you have experienced in the past six months:',
  },
  otherExperiencedSymptoms: {
    id: 'components.SolsticeForm.otherExperiencedSymptoms',
    defaultMessage: 'Please check any of the following that apply:',
  },
  otherMentions: {
    id: 'components.SolsticeForm.otherMentions',
    defaultMessage: 'What else would you like me to know?',
  },
});
