export const binaryResponses = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

export const stepTwoFormStructure = [
  {
    id: 'relationship_nature',
    label: 'relationshipNature',
    type: 'textarea',
  },
  {
    id: 'living_situation',
    label: 'livingSituation',
    type: 'textarea',
  },
  {
    id: 'education_level',
    label: 'educationLevel',
    type: 'textarea',
  },
  {
    id: 'current_occupation',
    label: 'currentOccupation',
    type: 'textarea',
  },
  {
    id: 'experienced_symptoms_past_six_months',
    label: 'experiencedSymptomsPastSixMonths',
    type: 'radio',
    freeText: {
      id: 'experienced_symptoms_past_six_months_free_text',
    },
    options: [
      'Increased appetite',
      'Decreased appetite',
      'Trouble concentrating',
      'Difficulty sleeping',
      'Excessive sleep',
      'Low motivation',
      'Isolation from others',
      'Fatigue/low energy',
      'Low self-esteem',
      'Depressed mood',
      'Tearful or crying spells',
      'Anxiety',
      'Fear',
      'Hopelessness',
      'Panic',
      'Other',
    ].map((e) => ({
      label: e,
      value: e,
    })),
  },
  {
    id: 'other_experienced_symptoms',
    label: 'otherExperiencedSymptoms',
    type: 'radio',
    freeText: {
      id: 'other_experienced_symptoms_free_text',
    },
    options: [
      'Headache',
      'High blood pressure',
      'Gastritis or esophagitis',
      'Hormone-related problems',
      'Head injury',
      'Angina or chest pain',
      'Irritable bowel',
      'Chronic pain',
      'Loss of consciousness',
      'Heart attack',
      'Bone or joint problems',
      'Seizures',
      'Kidney-related issues',
      'Chronic fatigue',
      'Dizziness',
      'Faintness',
      'Heart valve problems',
      'Urinary tract problems',
      'Fibromyalgia',
      'Numbness & tingling',
      'Shortness of breath',
      'Diabetes',
      'Hepatitis',
      'Asthma',
      'Arthritis',
      'Thyroid issues',
      'HIV/AIDS',
      'Cancer',
      'Other',
    ].map((e) => ({
      label: e,
      value: e,
    })),
  },
  {
    id: 'other_mentions',
    label: 'otherMentions',
    type: 'textarea',
  },
];
export const stepOneFormStructure = [
  {
    id: 'practice_heard_of_origin',
    label: 'practiceHeardOfOrigin',
    type: 'textarea',
  },
  {
    id: 'counseling_reason',
    label: 'counselingReason',
    type: 'textarea',
  },
  {
    id: 'counseling_goals',
    label: 'counselingGoals',
    type: 'textarea',
  },
  {
    id: 'psychotherapy_history',
    label: 'psychotherapyHistory',
    type: 'textarea',
  },
  {
    id: 'current_medication_and_reason',
    label: 'currentMedicationAndReason',
    type: 'textarea',
  },
  {
    id: 'prescription_mds',
    label: 'prescriptionMds',
    type: 'textarea',
  },
  {
    id: 'primary_doctor',
    label: 'primaryDoctor',
    type: 'textarea',
  },
  {
    id: 'alcohol_use',
    label: 'alcoholUse',
    type: 'textarea',
  },
  {
    id: 'recreational_substance',
    label: 'recreationalSubstance',
    type: 'textarea',
  },
  {
    id: 'suicidal_thoughts',
    label: 'suicidalThoughts',
    type: 'radio',
  },
  {
    id: 'attempted_suicide',
    label: 'attemptedSuicide',
    type: 'radio',
  },
  {
    id: 'harm_others',
    label: 'harmOthers',
    type: 'radio',
  },
  {
    id: 'psychiatric_hospitalization',
    label: 'psychiatricHospitalization',
    type: 'radio',
  },
  {
    id: 'mental_illness_history',
    label: 'mentalIllnessHistory',
    type: 'radio',
  },
];
