import { FieldArray } from 'formik';
import React from 'react';
import { Cell } from 'react-foundation';
import useIntl from '../../../../../hooks/useIntl';
import AddedItem from '../../../../common/AddedItem/AddedItem';
import PrimaryButton from '../../../../common/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/buttons/SecondaryButton';
import Datepicker from '../../../../common/Datepicker';
import FieldInput from '../../../../common/fields/FieldInput';
import RadioGroupWithLabel from '../../../../common/inputs/RadioGroupWithLabel';
import TextInput from '../../../../common/inputs/TextInput';
import Loading from '../../../../common/Loading';
import SuperBillMessageModal from '../../../../SuperBillForm/SuperBillMessageModal/SuperBillMessageModal';
import styles from '../../PersonalInfoForm/PersonalInfoForm.module.scss';
import { binaryResponses, diseaseList } from '../data';
import messages from '../messages';
import ProvidersForm from '../ProvidersForm/ProvidersForm';

const BPSFormStepOne = ({
  isLoading,
  values,
  handleChange,
  setFieldValue,
  handleBlur,
  editItemProvider,
  providerValue,
  setProviderValue,
  setEditItemProvider,
  handleCheckboxChange,
}) => {
  const { formatMessage } = useIntl();

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.formReference)}
          placeholder="Enter here"
          name="reference"
          value={values.reference}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepOne.Reference.Input"
          autoFocus={true}
        />
      </Cell>
      <Cell small={12}>
        <h4 className={styles.section}>
          {formatMessage(messages.medicalHistory)}
        </h4>
      </Cell>
      <Cell small={12}>
        <h4 className={styles.subsection}>
          {formatMessage(messages.medicalHistory.primaryCarePhysician)}
        </h4>
      </Cell>
      <hr className={styles.form.hr} />
      <Cell small={12}>
        <FieldInput
          label={formatMessage(
            messages.medicalHistory.primaryCarePhysician.physicianName,
          )}
          placeholder="Enter here"
          name="physician_name"
          value={values.physician_name}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepOne.PhysicianName.Input"
        />
      </Cell>
      <Cell small={12}>
        <FieldInput
          label={formatMessage(
            messages.medicalHistory.primaryCarePhysician.physicianPhone,
          )}
          placeholder="Enter here"
          name="physician_phone"
          value={values.physician_phone}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepOne.PhysicianPhone.Input"
        />
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(
            messages.medicalHistory.primaryCarePhysician.healthCareProviders,
          )}
          key="health_care_providers"
          componentName="health_care_providers"
          options={binaryResponses}
          dataTestId="BPSFormStepOne.HealthCareProviders.RadioGroup"
          labelTestId="BPSFormStepOne.HealthCareProviders.Label"
          value={values.health_care_providers}
          onChange={(value) => setFieldValue('health_care_providers', value)}
        />
      </Cell>
      <FieldArray
        name="health_care_providers_list"
        render={({ remove, push }) => (
          <>
            <ProvidersForm
              push={push}
              values={values?.health_care_providers_list}
              providerValue={!editItemProvider ? providerValue : null}
              setProviderValue={setProviderValue}
            />
            {values?.health_care_providers_list?.length > 0 && (
              <Cell>
                <AddedItem
                  displayFields={['name', 'specialty', 'phone']}
                  displayEditItem="none"
                  remove={remove}
                  items={values?.health_care_providers_list}
                  maxLength={10}
                  setEditItem={setEditItemProvider}
                />
              </Cell>
            )}
            {!!editItemProvider && editItemProvider.delete && (
              <SuperBillMessageModal type="error">
                <h1>Deleting Providers</h1>
                <p>Are you sure do you want to proceed?</p>
                <div className={styles.buttonsWrapperDelete}>
                  <SecondaryButton
                    data-testid="BPSFormStepOne.HealthCareProviders.CancelButton"
                    className={styles.cancel}
                    onClick={() => {
                      setEditItemProvider();
                    }}
                    label={'Cancel'}
                  />
                  <PrimaryButton
                    data-testid="BPSFormStepOne.HealthCareProviders.YesButton"
                    label="Yes"
                    onClick={() => {
                      remove(editItemProvider.index);
                      setEditItemProvider();
                    }}
                  />
                </div>
              </SuperBillMessageModal>
            )}
          </>
        )}
      />
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(
            messages.medicalHistory.primaryCarePhysician.contactProviders,
          )}
          componentName="providers_contact"
          options={binaryResponses}
          dataTestId="BPSFormStepOne.HealthCareProviders.ContactProviders.RadioGroup"
          labelTestId="BPSFormStepOne.HealthCareProviders.ContactProviders.Label"
          value={values.providers_contact}
          onChange={(value) => setFieldValue('providers_contact', value)}
        />
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(
            messages.medicalHistory.primaryCarePhysician.medicallyHospitalized,
          )}
          componentName="medically_hospitalized"
          options={binaryResponses}
          dataTestId="BPSFormStepOne.HealthCareProviders.MedicallyHospitalized.RadioGroup"
          labelTestId="BPSFormStepOne.HealthCareProviders.MedicallyHospitalized.Label"
          value={values.medically_hospitalized}
          onChange={(value) => setFieldValue('medically_hospitalized', value)}
        />
      </Cell>
      <Cell small={12} className={styles['datepicker']}>
        <Datepicker
          label={formatMessage(
            messages.medicalHistory.primaryCarePhysician.physicalExam,
          )}
          selected={values.physical_exam_date}
          className="form-control"
          name="physical_exam_date"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          maxDate={new Date()}
          placeholderText="MM/DD/YYYY"
          onChange={(value) => setFieldValue('physical_exam_date', value)}
          data-testid="BPSFormStepOne.HealthCareProviders.PhysicalExam.DatePicker"
        />
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(
            messages.medicalHistory.primaryCarePhysician.headInjury,
          )}
          componentName="head_injury"
          options={binaryResponses}
          dataTestId="BPSFormStepOne.HealthCareProviders.HeadInjury.RadioGroup"
          labelTestId="BPSFormStepOne.HealthCareProviders.HeadInjury.Label"
          value={values.head_injury}
          onChange={(value) => setFieldValue('head_injury', value)}
        />
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(
            messages.medicalHistory.primaryCarePhysician.chestPain,
          )}
          componentName="chest_pain"
          options={binaryResponses}
          dataTestId="BPSFormStepOne.HealthCareProviders.ChestPain.RadioGroup"
          labelTestId="BPSFormStepOne.HealthCareProviders.ChestPain.Label"
          value={values.chest_pain}
          onChange={(value) => setFieldValue('chest_pain', value)}
        />
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          type="checkbox"
          label={formatMessage(
            messages.medicalHistory.primaryCarePhysician.personalHistory,
          )}
          componentName="personal_history"
          options={diseaseList}
          dataTestId="BPSFormStepOne.HealthCareProviders.PersonalHistory.RadioGroup"
          labelTestId="BPSFormStepOne.HealthCareProviders.PersonalHistory.Label"
          value={values.personal_history}
          onChange={(value) => {
            const arrOfItems = handleCheckboxChange(
              values.personal_history,
              value,
            );
            setFieldValue('personal_history', arrOfItems);
          }}
        />
        {values.personal_history.includes('other') && (
          <TextInput
            placeholder="Enter here"
            name="personal_disease_input"
            value={values.personal_disease_input}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepOne.HealthCareProviders.PersonalDisease.Input"
          />
        )}
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          type="checkbox"
          label={formatMessage(
            messages.medicalHistory.primaryCarePhysician.familyDiagnoses,
          )}
          componentName="family_diagnoses"
          options={diseaseList}
          dataTestId="BPSFormStepOne.HealthCareProviders.FamilyDiagnoses.RadioGroup"
          labelTestId="BPSFormStepOne.HealthCareProviders.FamilyDiagnoses.Label"
          value={values.family_diagnoses}
          onChange={(value) => {
            const arrOfItems = handleCheckboxChange(
              values.family_diagnoses,
              value,
            );
            setFieldValue('family_diagnoses', arrOfItems);
          }}
        />
        {values.family_diagnoses.includes('other') && (
          <TextInput
            placeholder="Enter here"
            name="family_diagnoses_input"
            value={values.family_diagnoses_input}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepOne.HealthCareProviders.FamilyDiagnoses.Input"
          />
        )}
      </Cell>
    </>
  );
};

BPSFormStepOne.propTypes = {};

BPSFormStepOne.defaultProps = {};

export default BPSFormStepOne;
