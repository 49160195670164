import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import config from '../../config';

const ImplicitCallback = (props) => {
  const { authState } = useOktaAuth();

  const authClient = new OktaAuth({
    url: config.oidc.issuer.split('/oauth2/')[0],
    clientId: config.oidc.clientId,
    issuer: config.oidc.issuer,
    redirectUri: config.oidc.redirectUri,
  });

  useEffect(() => {
    // to check what error is coming from the okta
    const strURL = window.location.href;
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    const params = {};
    let match;
    while ((match = regex.exec(strURL))) {
      params[match[1]] = match[2];
    }
    if (
      params.error === 'invalid_request' ||
      params.error === 'access_denied'
    ) {
      authClient.tokenManager.clear();
      window.location.href = '/unathorize-user';
      return;
    }

    if (authClient.isLoginRedirect()) {
      const f1 = async () => {
        // Parse token from redirect url
        const data = await authClient.token.parseFromUrl();
        const { idToken, accessToken } = data.tokens;
        // Store parsed token in Token Manager
        await authClient.tokenManager.add('idToken', idToken);
        await authClient.tokenManager.add('accessToken', accessToken);
        window.location.href = '/';
      };
      f1();
    } else {
      const f2 = async () => {
        const idToken = await authClient.tokenManager.get('idToken');
        console.log(idToken);
        if (idToken) {
          console.log(`Hi ${idToken.claims.email}!`);
        } else {
          // authClient.token.getWithRedirect({
          //   responseType: "id_token"
          // });
        }
      };
      f2();
    }
  }, [authClient]);

  if (authState && authState.error) {
    return <p>{authState.error.toString()}</p>;
  }
  return false;
};

export default ImplicitCallback;
