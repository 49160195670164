import { doGet, doPost, doPut } from '../fetch';

const getUserProfile = async (userId = '') => {
  const user = await doGet(`/b2c/profile/patient/${userId}`);
  return user;
};
const getPatientDetails = async (userId = '') => {
  const user = await doGet(`/b2c/profile/patient-details/${userId}`);
  return user;
};

const saveAssessments = async ({
  external_id,
  assessment_name,
  answers,
  patient_id,
  assessment_type,
  doctor_id,
  clinic_id,
  url,
  isInvited,
  white_labelled_clinic,
}) => {
  const res = await doPost(
    '/b2c/assessment',
    {
      external_id,
      assessment_name,
      assessment_values: answers,
      patient_id,
      assessment_type,
      doctor_id,
      clinic_id,
      url,
      isInvited,
      white_labelled_clinic,
    },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  return res;
};

const getAssessment = async (id) => {
  const res = doGet(`/b2c/assessment/${id}`);
  return res;
};

const deleteAssessments = async (id, body) => {
  const res = doPost(`/b2c/assessment/${id}`, body);
  return res;
};

const getPatientAppointments = async (params) => {
  const appointments = await doPost(`/b2c/appointments`, params);
  return appointments;
};

const getDoctorAppointments = async (params) => {
  const appointments = await doPost(`/b2c/appointments`, params);
  return appointments;
};

// TODO duplicate route from api/doctor -> getDoctor
const getDoctor = async (Id) => {
  const doctor = await doGet(`/b2c/doctor/${Id}`);
  return doctor;
};

const getEvents = async (Id, doctorId) => {
  const events = await doGet(`/b2c/events/${Id}`);
  return events;
};

const getEventDetail = async (params) => {
  const detail = await doPost(`/b2c/timelineDetails`, params);
  return detail;
};
const saveWeeklyAssessments = async ({
  external_id,
  assessment_name,
  answers,
  patient_id,
  assessment_type,
  doctor_id,
  clinic_id,
  status,
  fact_form_id,
}) => {
  const res = await doPost(
    '/b2c/weeklyassessment',
    {
      external_id,
      assessment_name,
      assessment_values: answers,
      patient_id,
      assessment_type,
      doctor_id,
      clinic_id,
      status,
      fact_form_id,
    },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  return res;
};

const createClinicDomain = async (body) => {
  const clinic = await doPost('/b2c/clinicDomain', body);
  return clinic;
};

export {
  getUserProfile,
  saveAssessments,
  getPatientAppointments,
  deleteAssessments,
  getDoctorAppointments,
  getDoctor,
  getEvents,
  getEventDetail,
  getAssessment,
  getPatientDetails,
  saveWeeklyAssessments,
  createClinicDomain,
};
