import { USER_TYPES, CLINICIAN_REGISTRATION_STATUS } from '../../constants';
import { doGet, doPost, doPut, doDelete, doPatch } from '../fetch';
import { getClinicianRegistrationData } from '../doctor';

const login = async ({ email, password, anonymousCartId }, dispatch) => {
  const { token, recommendations, ...rest } = await doPost(
    '/b2c/auth/login',
    { email, password, profile_type: 'patient' },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  localStorage.setItem('token', token);
  if (recommendations)
    localStorage.setItem('recommendations-answers-id', recommendations);
  localStorage.removeItem('anonymousId');
  const { cart, ...user } = rest;
  dispatch({ type: 'UPDATE_CART', payload: cart });
  dispatch({
    type: 'UPDATE_USER',
    payload: user,
  });
  return rest;
};

const signUp = async (body) => {
  const data = doPost(`/b2c/auth/signup`, body);
  return data;
};

const initialPatientSignup = async (body) => {
  const data = doPost(`/b2c/auth/initial-patient-signup`, body);
  return data;
};

const initialDoctorSignup = async (body) => {
  const data = doPost(`/b2c/auth/initial-doctor-signup`, body);
  return data;
};

const forgotPassword = async (body) => {
  const data = doPost(`/b2c/auth/forgot-password`, body);
  return data;
};
const emailActivation = async (userId) => {
  const data = doPost(`/b2c/sendgrid/confirmation/${userId}`);
  return data;
};
const getLoggedInUser = async (dispatch) => {
  const anonymousId = localStorage.getItem('anonymousId');
  let email = null;
  let oktaTokenStorage = null;

  if (localStorage.getItem('okta-token-storage')) {
    try {
      oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    } catch (err) {
      console.error('err', err);
    }
  }

  if (oktaTokenStorage?.idToken?.claims?.email) {
    email = oktaTokenStorage.idToken.claims.email;
  }

  const user = await doGet('/b2c/auth/validate-user', {
    params: { anonymousId, email },
  });

  if (user.anonymousId) {
    localStorage.setItem('anonymousId', user.anonymousId);
  }
  if (user.profile_type) {
    localStorage.setItem('profile-type', user.profile_type);
  }

  const { cart, ...userData } = user;
  let defaultProfilePic = require('../../img/patient.jpg'); // default set to patient

  if (userData.profile_type === USER_TYPES.DOCTOR) {
    defaultProfilePic = require('../../img/doctor.jpg');

    /**
     * verify doctor's registration form status
     * and redirect if status is not "enable"
     *
     * we are using try/catch in case if getClinicianRegistrationData returns 404 error
     *
     * IMPORTANT to do it before verifying billing plan
     */
    let redirectedHref = null;
    try {
      const clinicianRegistrationData = await getClinicianRegistrationData(
        userData.id,
      );

      if (
        clinicianRegistrationData?.data?.status ===
        CLINICIAN_REGISTRATION_STATUS.PENDING
      ) {
        redirectedHref = `/approval/${clinicianRegistrationData.data.clinic_name}`;
      } else if (
        clinicianRegistrationData?.data?.status ===
        CLINICIAN_REGISTRATION_STATUS.DISABLE
      ) {
        redirectedHref = `/disable/${clinicianRegistrationData.data.clinic_name}`;
      } else if (!clinicianRegistrationData?.data?.status) {
        redirectedHref = `/`;
      }
    } catch (err) {
      if (err.response.status === 404) {
        redirectedHref = '/doctor/clinician/registration';
      } else {
        console.error(err);
      }
    }

    if (
      redirectedHref &&
      !window.location.pathname.includes('approval') &&
      !window.location.pathname.includes('disable') &&
      !window.location.pathname.includes('doctor/clinician/registration')
    ) {
      window.location.href = redirectedHref;
    }

    /**
     * verify doctor's billing plan
     * and redirect if there is no billing plan available
     * or if billing plan is expired
     */
    let billingPlanRedirectHref = null;
    if (!userData.billing_plan) {
      billingPlanRedirectHref = 'doctor/billing';
    }

    if (
      billingPlanRedirectHref &&
      !window.location.pathname.includes(billingPlanRedirectHref)
    ) {
      window.location.href = `/${billingPlanRedirectHref}`;
    }
  }

  await Promise.all([
    dispatch({
      type: 'UPDATE_AVATAR',
      payload: userData.profile_pic || defaultProfilePic,
    }),
    dispatch({
      type: 'UPDATE_CART',
      payload: cart,
    }),
    dispatch({
      type: 'UPDATE_USER',
      payload: userData,
    }),
  ]);

  return userData;
};

const resetPassword = (email) => {
  return doGet(`/b2c/auth/forgot-password`, {
    params: { email },
  });
};

const setNewPassword = async ({ tokenValue, newPassword }, dispatch) => {
  const { token, ...rest } = await doPost(
    '/b2c/auth/reset-password',
    { tokenValue, newPassword },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  localStorage.setItem('token', token);
  const { cart, ...user } = rest;
  dispatch({ type: 'UPDATE_CART', payload: cart });
  return dispatch({
    type: 'UPDATE_USER',
    payload: user,
  });
};

const logout = async (dispatch) => {
  const anonymousId = 'anonymousId';
  localStorage.removeItem('token');
  localStorage.removeItem('recommendations-answers-id');
  localStorage.removeItem('okta-cache-storage');
  localStorage.removeItem('okta-token-storage');
  localStorage.removeItem('assessment-id');
  localStorage.removeItem('profile-type');

  localStorage.setItem('anonymousId', anonymousId);

  dispatch({ type: 'UPDATE_CART', payload: null });
  return dispatch({
    type: 'UPDATE_USER',
    payload: { anonymousId },
  });
};

const updateUser = async (
  { firstName, lastName, email, newPassword, currentPassword },
  dispatch,
) => {
  const user = await doPut('/b2c/customers/customer', {
    firstName,
    lastName,
    email,
    newPassword,
    currentPassword,
  });
  return dispatch({ type: 'UPDATE_USER', payload: user });
};

const addShippingAddress = async (
  {
    firstName,
    lastName,
    streetName,
    apartment,
    postalCode,
    city,
    state,
    email,
    phone,
    setAsPrimary,
  },
  dispatch,
) => {
  const user = await doPost('/b2c/customers/address', {
    firstName,
    lastName,
    streetName,
    apartment,
    postalCode,
    city,
    state,
    email,
    phone,
    setAsPrimary,
  });
  return dispatch({ type: 'UPDATE_USER', payload: user });
};

const setAsPrimaryShippingAddress = async (addressId, dispatch) => {
  const user = await doPatch('/b2c/customers/address/set-default-shipping', {
    addressId,
  });
  return dispatch({ type: 'UPDATE_USER', payload: user });
};

const deleteShippingAddress = async (addressId, dispatch) => {
  const user = await doDelete(`/b2c/customers/address/${addressId}`);

  return dispatch({ type: 'UPDATE_USER', payload: user });
};

const updateShippingAddress = async (address, addressId, dispatch) => {
  const user = await doPut(`/b2c/customers/address/${addressId}`, address);

  return dispatch({ type: 'UPDATE_USER', payload: user });
};

const subscribeToNewsletter = async (email) => {
  return doPost('/b2c/customers/subscribe', { email });
};

const checkUser = async (username, isForRegistration = false) => {
  const user = await doPost(
    `/b2c/okta/user/${username}?isForRegistration=${isForRegistration}`,
  );
  return user;
};

const verifyUser = async (body) => {
  const user = await doPost(`/b2c/auth/verify-user`, body);
  return user;
};

const verifyUninvitedUser = async (id) => {
  const user = await doGet(`/b2c/auth/uninvited/verify-user/${id}`);
  return user;
};

const requestNewToken = async (body) => {
  const user = await doPost(`/b2c/auth/request-new-token`, body);
  return user;
};

export {
  getLoggedInUser,
  login,
  logout,
  resetPassword,
  setNewPassword,
  updateUser,
  addShippingAddress,
  setAsPrimaryShippingAddress,
  deleteShippingAddress,
  subscribeToNewsletter,
  updateShippingAddress,
  signUp,
  emailActivation,
  forgotPassword,
  checkUser,
  verifyUser,
  requestNewToken,
  initialPatientSignup,
  initialDoctorSignup,
  verifyUninvitedUser,
};
