import React from 'react';
import styles from './TokenUsed.module.scss';
import { Link } from 'react-router-dom';
import Warning from '../../img/warning.png';
import Back from '../../img/icons/back.svg';
import Logo from '../Logo';

const TokenUsed = () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.backDiv}>
          <Link to="/">
            <img src={Back} alt="Back" />
            <span>Back</span>
          </Link>
        </div>
        <Logo />
        <div className={styles.warningImg}>
          <img src={Warning} alt="Warning" />
        </div>
        <h4 className={styles.confirmationTitle}>Token expired</h4>

        <p className={styles.text}>
          This URL is already used. You already created your account.
        </p>
      </div>
    </div>
  );
};

export default TokenUsed;
