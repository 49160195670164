import React, { useEffect } from 'react';
import { Cell } from 'react-foundation';
import useIntl from '../../../../../hooks/useIntl';
import RadioGroupWithLabel from '../../../../common/inputs/RadioGroupWithLabel';
import TextInput from '../../../../common/inputs/TextInput';
import Loading from '../../../../common/Loading';
import styles from '../../PersonalInfoForm/PersonalInfoForm.module.scss';
import { behaviorConcernsSymptoms, maniaSymptoms, ocdSymptoms } from '../data';
import messages from '../messages';
import SymptomsForm from '../SymptomsForm/SymptomsForm';

const BPSFormStepThree = ({
  values,
  handleChange,
  setFieldValue,
  handleBlur,
  isLoading,
  handleCheckboxChange,
}) => {
  const { formatMessage } = useIntl();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Cell small={12}>
        <h4 className={styles.section}>
          {formatMessage(messages.currentWellbeing)}
        </h4>
      </Cell>
      <hr className={styles.form.hr} />
      <Cell small={12}>
        <RadioGroupWithLabel
          type="checkbox"
          label={formatMessage(messages.currentWellbeing.maniaSymptoms)}
          componentName="mania_symptoms"
          options={maniaSymptoms}
          dataTestId="BPSFormStepThree.CurrentWellbeing.ManiaSymptoms.RadioGroup"
          labelTestId="BPSFormStepThree.CurrentWellbeing.ManiaSymptoms.Label"
          value={values.mania_symptoms}
          onChange={(value) => {
            const arrOfItems = handleCheckboxChange(
              values.mania_symptoms,
              value,
            );
            setFieldValue('mania_symptoms', arrOfItems);
          }}
        />
        {values.mania_symptoms.includes('other') && (
          <TextInput
            placeholder="Enter here"
            name="mania_symptoms_input"
            value={values.mania_symptoms_input}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepThree.CurrentWellbeing.ManiaSymptoms.Input"
          />
        )}
      </Cell>
      <SymptomsForm
        dropdownName="mania_symptoms_period"
        inputName="mania_question_response"
        radioGroupName="mania_symptoms_cause"
        dropdownValue={values.mania_symptoms_period}
        inputValue={values.mania_question_response}
        radioGroupValue={values.mania_symptoms_cause}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        dropdownDataTestId="BPSFormStepThree.CurrentWellbeing.ManiaSymptomsPeriod.Dropdown"
        inputDataTestId="BPSFormStepThree.CurrentWellbeing.ManiaQuestionResponse.Input"
        radioGroupDataTestId="BPSFormStepThree.CurrentWellbeing.ManiaSymptomsCause.RadioGroup"
        radioGroupLabelTestId="BPSFormStepThree.CurrentWellbeing.ManiaSymptomsCause.Label"
      />
      <Cell small={12}>
        <RadioGroupWithLabel
          type="checkbox"
          label={formatMessage(messages.currentWellbeing.ocdSymptoms)}
          componentName="ocd_symptoms"
          options={ocdSymptoms}
          dataTestId="BPSFormStepThree.CurrentWellbeing.OCDSymptoms.RadioGroup"
          labelTestId="BPSFormStepThree.CurrentWellbeing.OCDSymptoms.Label"
          value={values.ocd_symptoms}
          onChange={(value) => {
            const arrOfItems = handleCheckboxChange(values.ocd_symptoms, value);
            setFieldValue('ocd_symptoms', arrOfItems);
          }}
        />
        {values.ocd_symptoms.includes('other') && (
          <TextInput
            placeholder="Enter here"
            name="ocd_symptoms_input"
            value={values.ocd_symptoms_input}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepThree.CurrentWellbeing.OCDSymptoms.Input"
          />
        )}
      </Cell>
      <SymptomsForm
        dropdownName="ocd_symptoms_period"
        inputName="ocd_question_response"
        radioGroupName="ocd_symptoms_cause"
        dropdownValue={values.ocd_symptoms_period}
        inputValue={values.ocd_question_response}
        radioGroupValue={values.ocd_symptoms_cause}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        dropdownDataTestId="BPSFormStepThree.CurrentWellbeing.OCDSymptomsPeriod.Dropdown"
        inputDataTestId="BPSFormStepThree.CurrentWellbeing.OCDQuestionResponse.Input"
        radioGroupDataTestId="BPSFormStepThree.CurrentWellbeing.OCDSymptomsCause.RadioGroup"
        radioGroupLabelTestId="BPSFormStepThree.CurrentWellbeing.OCDSymptomsCause.Label"
      />
      <Cell small={12}>
        <RadioGroupWithLabel
          type="checkbox"
          label={formatMessage(
            messages.currentWellbeing.behaviorConcernsSymptoms,
          )}
          componentName="behavior_concerns_symptoms"
          options={behaviorConcernsSymptoms}
          dataTestId="BPSFormStepThree.CurrentWellbeing.BehaviorConcernsSymptoms.RadioGroup"
          labelTestId="BPSFormStepThree.CurrentWellbeing.BehaviorConcernsSymptoms.Label"
          value={values.behavior_concerns_symptoms}
          onChange={(value) => {
            const arrOfItems = handleCheckboxChange(
              values.behavior_concerns_symptoms,
              value,
            );
            setFieldValue('behavior_concerns_symptoms', arrOfItems);
          }}
        />
        {values.behavior_concerns_symptoms.includes('other') && (
          <TextInput
            placeholder="Enter here"
            name="behavior_concerns_symptoms_input"
            value={values.behavior_concerns_symptoms_input}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepThree.CurrentWellbeing.BehaviorConcernsSymptoms.Input"
          />
        )}
      </Cell>
      <SymptomsForm
        dropdownName="behavior_concerns_symptoms_period"
        inputName="behavior_concerns_question_response"
        radioGroupName="behavior_concerns_symptoms_cause"
        dropdownValue={values.behavior_concerns_symptoms_period}
        inputValue={values.behavior_concerns_question_response}
        radioGroupValue={values.behavior_concerns_symptoms_cause}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        dropdownDataTestId="BPSFormStepThree.CurrentWellbeing.BehaviorConcernsSymptomsPeriod.Dropdown"
        inputDataTestId="BPSFormStepThree.CurrentWellbeing.BehaviorConcernsQuestionResponse.Input"
        radioGroupDataTestId="BPSFormStepThree.CurrentWellbeing.BehaviorConcernsSymptomsCause.RadioGroup"
        radioGroupLabelTestId="BPSFormStepThree.CurrentWellbeing.BehaviorConcernsSymptomsCause.Label"
      />
    </>
  );
};

BPSFormStepThree.propTypes = {};

BPSFormStepThree.defaultProps = {};

export default BPSFormStepThree;
