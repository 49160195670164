import { StyleSheet } from '@react-pdf/renderer';

export const AssessmentPDFStyles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    flexDirection: 'column',
  },
  hr: {
    margin: '10 0',
    borderBottom: '1px solid black',
  },
  headerSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 10,
    borderBottom: '1px solid black',
    label: {
      fontSize: 15,
      fontWeight: 'bold',
      lineHeight: 0,
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
  tableRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #aaa',
    marginTop: -1,
  },
  tableDataRow: {
    alignSelf: 'flex-start',
    flexGrow: 1,
    flexBasis: 0,
    padding: '5 0',
  },
  listRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginLeft: 5,
  },
  content_detail: {
    backgroundColor: '#FFFFFF',
    paddingTop: 10,
    paddingBottom: 10,

    h2: {
      paddingTop: '10px',
      color: '#000000',
      fontSize: '18px',
      fontWeight: 'normal',
      textAlign: 'left',
      marginBottom: 10,
    },
    h3: {
      paddingTop: '10px',
      color: '#000000',
      fontSize: '15px',
      fontWeight: 'bold',
      textAlign: 'left',
      marginBottom: 10,
    },

    span: {
      color: '#B3B3B3',
      fontSize: 12,
    },
    p: {
      color: '#000000',
      fontSize: '12px',
      marginBottom: 10,
    },

    questionSection: {
      letterSpacing: '0.75px',
      marginBottom: 10,
    },
    detail: {
      display: 'flex',
    },
    rowContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    column3: {
      width: '33%',
    },
    column2: {
      width: '50%',
    },
  },
  table: {
    width: '100%',
    tableTr: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      tableTrDiv: {
        width: '23%',
        color: '#B3B3B3',
        fontSize: 12,
      },
      span: {
        color: '#B3B3B3',
        fontSize: 12,
      },
      p: {
        width: '23%',
        color: '#000000',
        fontSize: 12,
        marginBottom: 10,
      },
    },
  },
  tableIntake: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    table_tr: {
      display: 'flex',
      flexDirection: 'row',
      trDiv: {
        width: '23%',
        color: '#000000',
        fontSize: 12,
        letterSpacing: '0',
        lineHeight: 0,
        borderWith: 2,
        borderColor: '#cdcdcd',
        textDecoration: 'underline',
      },
    },
    table_td: {
      display: 'flex',
      flexDirection: 'column',
      tdRow: {
        display: 'flex',
        flexDirection: 'row',
      },
      tdDiv: {
        width: '23%',
        color: '#000000',
        fontSize: 12,
        letterSpacing: '0',
        lineHeight: 0,
      },
      tdP: {
        color: '#000000',
        fontSize: 12,
        letterSpacing: '0',
        lineHeight: 0,
      },
    },
  },
});
