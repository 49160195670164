import React, { useState, Fragment, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../../context/AuthContext';
import styles from './ResearcherDashboardPage.module.scss';
import classnames from 'classnames';
import Loading from '../../../components/common/Loading';
import ResearcherLeftNav from '../../../components/nav/ResearcherLeftNav';
import {
  getResearcherRegistrationData,
  getResearcher,
} from '../../../api/researcher';

const ResearcherDashboardPage = () => {
  const history = useHistory();
  const { isLoading, authUser } = useContext(AuthContext);
  const [, setProfile] = useState({});
  const [, setResearcher] = useState({});
  const [dataLoading, setLoading] = useState(false);
  const isDataLoading = dataLoading || isLoading;

  const fetchProfile = async () => {
    if (authUser.id) {
      try {
        const data = await getResearcherRegistrationData(authUser.id);
        if (data.researcher.status === 'pending' && data.researcher.lab_name) {
          history.push(`/approval/${data.researcher.lab_name}`);
        }
        if (data.researcher.status === 'disable') {
          history.push(`/disable/${data.researcher.lab_name}`);
        }

        setProfile(data);
        const resp = await getResearcher(authUser.id);
        setResearcher(resp.researcher);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.data.success === false) {
          history.push('/researcher/registration');
        }
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [authUser]);

  useEffect(() => {
    if (authUser.id) {
    }
  }, [authUser]);

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftNav, 'desktop-only')}>
        <ResearcherLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.topNav, 'mobile-only')}
            ></div>
            <div className={styles.header}>
              <h1 data-testid="Dashboard header">Dashboard</h1>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default ResearcherDashboardPage;
