import { defineMessages } from 'react-intl';

export default defineMessages({
  formTitle: {
    id: 'components.KAPIntakeForm.formTitle',
    description: 'Title of the Form',
    defaultMessage: 'Confidential Client Information',
  },
  formInfo: {
    id: 'components.KAPIntakeForm.formInfo',
    description: 'Information of the Form',
    defaultMessage:
      'Welcome to our practice. We want to make the most of each appointment you have with us. One way of doing this is for you to write down some basic information in advance of your first appointment. Please fill out the following as completely as possible. This information is confidential.',
  },
  formSubTitle: {
    id: 'components.KAPIntakeForm.formSubTitle',
    description: 'Sub Title of the Form',
    defaultMessage: 'Fill in your details',
  },
  personalDetails: {
    id: 'component.KAPIntakeForm.personalDetails',
    description: 'Text for personal details section',
    defaultMessage: 'Personal Details',
    nameLable: {
      id: 'component.KAPIntakeForm.personalDetails.nameLable',
      description: 'The label for name',
      defaultMessage: 'Name',
    },
    addressLabel: {
      id: 'component.KAPIntakeForm.personalDetails.addressLabel',
      description: 'The label for address',
      defaultMessage: 'Address',
    },
    cityLabel: {
      id: 'component.KAPIntakeForm.personalDetails.cityLabel',
      description: 'The label for city',
      defaultMessage: 'City',
    },
    stateLabel: {
      id: 'component.KAPIntakeForm.personalDetails.stateLabel',
      description: 'The label for state',
      defaultMessage: 'State',
    },
    zipCodeLabel: {
      id: 'component.KAPIntakeForm.personalDetails.zipCodeLabel',
      description: 'The label for zip code',
      defaultMessage: 'Zip Code',
    },
  },
  contactDetails: {
    id: 'component.KAPIntakeForm.contactDetails',
    description: 'Text for contact details section',
    defaultMessage: 'Contact Details',
    homePhoneLabel: {
      id: 'component.KAPIntakeForm.homePhoneLabel',
      description: 'The label for home phone',
      defaultMessage: 'Home Phone',
    },
    cellPhoneLabel: {
      id: 'component.KAPIntakeForm.contactDetails.cellPhoneLabel',
      description: 'The label for cell phone',
      defaultMessage: 'Cell Phone',
    },
    workPhoneLabel: {
      id: 'component.KAPIntakeForm.contactDetails.workPhoneLabel',
      description: 'The label for work phone',
      defaultMessage: 'Work Phone',
    },
    emailLabel: {
      id: 'component.KAPIntakeForm.contactDetails.emailLabel',
      description: 'The label for email',
      defaultMessage: 'Email',
    },
    sendEmailLabel: {
      id: 'component.KAPIntakeForm.contactDetails.sendEmailLabel',
      description: 'The label for send email checkbox',
      defaultMessage: 'May we leave a message?',
    },
  },
  otherDetails: {
    id: 'component.KAPIntakeForm.otherDetails',
    description: 'Text for other details section',
    defaultMessage: 'Other Details',
    SSIdLabel: {
      id: 'component.KAPIntakeForm.otherDetails.SSIdLabel',
      description: 'The label for SS#',
      defaultMessage: 'SS#',
    },
    ageLabel: {
      id: 'component.KAPIntakeForm.otherDetails.ageLabel',
      description: 'The label for the age',
      defaultMessage: 'Age',
    },
    birthDateLabel: {
      id: 'component.KAPIntakeForm.otherDetails.birthDateLabel',
      description: 'The label for the birth date',
      defaultMessage: 'Birthdate',
    },
    cancelButtonLabel: {
      id: 'component.KAPIntakeForm.otherDetails.cancelButtonLabel',
      description: 'The label for the cancel button',
      defaultMessage: 'Cancel',
    },
    maleLabel: {
      id: 'component.KAPIntakeForm.otherDetails.male',
      description: 'Label for male',
      defaultMessage: 'Male',
    },
    femaleLabel: {
      id: 'component.KAPIntakeForm.otherDetails.female',
      description: 'Label for female',
      defaultMessage: 'Female',
    },
    transgenderYesLable: {
      id: 'component.KAPIntakeForm.otherDetails.transgenderYesLable',
      description: 'Label for transgender yes',
      defaultMessage: 'Yes',
    },
    transgenderNoLable: {
      id: 'component.KAPIntakeForm.otherDetails.transgenderNoLable',
      description: 'Label for transgeder no',
      defaultMessage: 'No',
    },
    educationLable: {
      id: 'component.KAPIntakeForm.otherDetails.educationLable',
      description: 'Label for education',
      defaultMessage: 'Education (Grade/Degree completed, any Post secondary)',
    },
    currentOccupationLabel: {
      id: 'component.KAPIntakeForm.otherDetails.currentOccupationLabel',
      description: 'Label for current occupation',
      defaultMessage: 'Current Occupation',
    },
  },
  medicalEmergency: {
    id: 'component.KAPIntakeForm.medicalEmergency',
    description: 'Text for medical emergency section',
    defaultMessage: 'Person to alert in the event of Medical Emergency',
    nameLable: {
      id: 'component.KAPIntakeForm.medicalEmergency.nameLable',
      description: 'The label for name',
      defaultMessage: 'Name',
    },
    addressLabel: {
      id: 'component.KAPIntakeForm.medicalEmergency.addressLabel',
      description: 'The label for address',
      defaultMessage: 'Address',
    },
    relationLabel: {
      id: 'component.KAPIntakeForm.medicalEmergency.relationLabel',
      description: 'The label for relation',
      defaultMessage: 'Relation',
    },
    phoneLabel: {
      id: 'component.KAPIntakeForm.medicalEmergency.phoneLabel',
      description: 'The label for phone',
      defaultMessage: 'Phone',
    },
  },
  familyDoctor: {
    id: 'component.KAPIntakeForm.familyDoctor',
    description: 'Text for family doctor details section',
    defaultMessage: 'Family Doctor Details',
    nameLable: {
      id: 'component.KAPIntakeForm.familyDoctor.nameLable',
      description: 'The label for name',
      defaultMessage: 'Name',
    },
    phoneLabel: {
      id: 'component.KAPIntakeForm.familyDoctor.phoneLabel',
      description: 'The label for phone',
      defaultMessage: 'Phone',
    },
  },
  familyDetails: {
    id: 'component.KAPIntakeForm.familyDetails',
    description: 'Text for family details section',
    defaultMessage: 'Family Details',
    relationshipStatusLabel: {
      id: 'component.KAPIntakeForm.familyDetails.relationshipStatusLabel',
      description: 'The label for the relationship status',
      defaultMessage: 'Relationship Status',
    },
    nameLable: {
      id: 'component.KAPIntakeForm.familyDetails.nameLable',
      description: 'The label for the partner name',
      defaultMessage: "Spouse's/Partner's Name",
    },
    phoneLabel: {
      id: 'component.KAPIntakeForm.familyDetails.phoneLabel',
      description: 'The label for phone',
      defaultMessage: 'Phone',
    },
    childrenLabel: {
      id: 'component.KAPIntakeForm.familyDetails.childrenLabel',
      description: 'The label for children',
      defaultMessage: 'Children',
    },
  },
  insuranceDetails: {
    id: 'component.KAPIntakeForm.insuranceDetails',
    description: 'Text for primary insurance details section',
    defaultMessage: 'Primary Insurance Details',
    companyNameLabel: {
      id: 'component.KAPIntakeForm.insuranceDetails.companyNameLabel',
      description: 'The label for the company name',
      defaultMessage: 'Company Name',
    },
    insuranceIdLable: {
      id: 'component.KAPIntakeForm.insuranceDetails.insuranceIdLable',
      description: 'The label for the insurance Id',
      defaultMessage: 'Ins. ID#',
    },
    groupNameLabel: {
      id: 'component.KAPIntakeForm.insuranceDetails.groupNameLabel',
      description: 'The label for the group name',
      defaultMessage: 'Group Name',
    },
    groupIdLabel: {
      id: 'component.KAPIntakeForm.insuranceDetails.groupIdLabel',
      description: 'The label for group Id',
      defaultMessage: 'Group #',
    },
    employerLabel: {
      id: 'component.KAPIntakeForm.insuranceDetails.employerLabel',
      description: 'The label for the employer',
      defaultMessage: 'Employer',
    },
    subscriberNameLabel: {
      id: 'component.KAPIntakeForm.insuranceDetails.subscriberNameLabel',
      description: 'The label for the subscriber name',
      defaultMessage: "Subscriber's Name",
    },
    relationLabel: {
      id: 'component.KAPIntakeForm.insuranceDetails.relationLabel',
      description: 'The label for the relation',
      defaultMessage: 'Relation',
    },
    subscriberDOBLabel: {
      id: 'component.KAPIntakeForm.insuranceDetails.subscriberDOBLabel',
      description: 'The label for the subscriber date of birth',
      defaultMessage: "Subscriber's DOB",
    },
  },
  certifyCheckboxLabel: {
    id: 'component.KAPIntakeForm.certifyCheckboxLabel',
    description: 'The label for certify checkbox',
    defaultMessage:
      'I certify to the best of my knowledge that all information provided is true and correct.',
  },
  allergiesTagsLabel: {
    id: 'component.allergiesTagsLabel',
    description: 'The label for allergies tags',
    defaultMessage: 'Do you have any allergies? If yes please mention below.',
  },
  medicationLabel: {
    id: 'component.medicationLabel',
    description: 'The label for medication',
    defaultMessage:
      'Are you taking medication currently? If yes please mention dose, frequency & date last taken.',
  },
  currentMedicationsLabel: {
    id: 'component.currentMedicationsLabel',
    description: 'The label for current medications',
    defaultMessage: 'Current Medications',
  },
  recreationalDrugString: {
    id: 'component.recreationalDrugString',
    description: 'The label for recreational drug string',
    defaultMessage:
      'Are you taking recreational drugs? If yes please mention type, dose, frequency & date last used.',
  },
  recreationalDrugsLabel: {
    id: 'component.recreationalDrugsLabel',
    description: 'The label for recreational drugs',
    defaultMessage: 'Recreational Drugs',
  },
  medicalIllnessString: {
    id: 'component.medicalIllnessString',
    description: 'The label for medical illness string',
    defaultMessage:
      'Do you have any Medical illnesses? If yes please mention current & past illnesses.',
  },
  medicalIllnessLabel: {
    id: 'component.medicalIllnessLabel',
    description: 'The label for medical illness',
    defaultMessage: 'Medical Illness',
  },
  psychologicalCareRadioLabel: {
    id: 'component.psychologicalCareRadioLabel',
    description: 'The label for psychological care radio button',
    defaultMessage: 'Have you had previous psychological care or counseling?',
  },
  psychologicalCareDetailsLabel: {
    id: 'component.psychologicalCareDetailsLabel',
    description: 'The label for psychological care details',
    defaultMessage:
      'If yes, please give the name of the clinician(s), the dates you saw them (e.g. Nov 2017), and the nature of the difficulty at that time.',
  },
  psychiatricMedicationRadioLabel: {
    id: 'component.psychiatricMedicationRadioLabel',
    description: 'The label for psychiatric medications radio button',
    defaultMessage:
      'Have you been prescribed psychiatric medications in the past (e.g. antidepressants, antipsychotics, mood stabilizers, stimulants, tranquilizers)?',
  },
  psychiatricMedicationDetailsLabel: {
    id: 'component.psychiatricMedicationDetailsLabel',
    description: 'The label for psychiatric medications details',
    defaultMessage:
      'If yes, please list each one with dose and describe their effects.',
  },
  psychologicalDifficultyRadioLabel: {
    id: 'component.psychologicalDifficultyRadioLabel',
    description: 'The label for psychological difficulty radio button',
    defaultMessage:
      'Have you ever been hospitalized for a psychological difficulty?',
  },
  suicidalThoughtsRadioLabel: {
    id: 'component.suicidalThoughtsRadioLabel',
    description: 'The label for suicidal thoughts radio button',
    defaultMessage:
      'Have you ever had feelings or thoughts that you didn’t want to live?',
  },
  sleepingHabitsRadioLabel: {
    id: 'component.sleepingHabitsRadioLabel',
    description: 'The label for sleeping habits radio button',
    defaultMessage: 'How would you rate your current sleeping habits?',
  },
  difficultyFallingAsleepRadioLabel: {
    id: 'component.difficultyFallingAsleepRadioLabel',
    description: 'The label for difficulty falling asleep radio button',
    defaultMessage: 'Do you have difficulty falling asleep?',
  },
  awakeningDuringNightRadioLabel: {
    id: 'component.awakeningDuringNightRadioLabel',
    description: 'The label for awekening during night radio button',
    defaultMessage: 'Do you have awakenings during the night?',
  },
  poorSleepRadioLabel: {
    id: 'component.poorSleepRadioLabel',
    description: 'The label for poor sleep radio button',
    defaultMessage: 'Do you have poor or unrefreshing sleep?',
  },
  sleepingProblemLabel: {
    id: 'component.sleepingProblemLabel',
    description: 'The label for poor sleep since text box',
    defaultMessage:
      'If so, for how long have you been experiencing this problem(s)?',
  },
  currentWeightLabel: {
    id: 'component.currentWeightLabel',
    description: 'The label current weight',
    defaultMessage: 'Current Weight (lbs)',
  },
  currentHeightLabel: {
    id: 'component.currentHeightLabel',
    description: 'The label current height',
    defaultMessage: 'Height (inches)',
  },
  isPregnantLabel: {
    id: 'component.isPregnantLabel',
    description: 'Are you or could you be pregnant?',
    defaultMessage: 'Are you or could you be pregnant?',
  },
  natureOfTherapyLabel: {
    id: 'component.natureOfTherapyLabel',
    description:
      'In your own words, what is the nature of the concern that you wish to address in therapy? Feel free to describe this in as much or as little detail as you wish.  What do you consider  some of your strengths?  Weaknesses?',
    defaultMessage:
      'In your own words, what is the nature of the concern that you wish to address in therapy? Feel free to describe this in as much or as little detail as you wish.  What do you consider  some of your strengths?  Weaknesses?',
  },
  significantTraumaLabel: {
    id: 'component.significantTraumaLabel',
    description:
      'Please list with dates any significant trauma you have experienced: violence—physical, sexual, emotional, abuse, natural occurrences, etc.',
    defaultMessage:
      'Please list with dates any significant trauma you have experienced: violence—physical, sexual, emotional, abuse, natural occurrences, etc.',
  },
});
