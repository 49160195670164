import React from 'react';

class NylasScheduler extends React.Component {
  nylasScriptSrc =
    'https://schedule.nylas.com/schedule-editor/v1.0/schedule-editor.js';
  buildCalendlyUrl = (url, queryString) =>
    `${url}?primary_color=000000&${queryString}`;

  loadCalender1 = () => {
    window.nylas.scheduler.show({
      //pageDomain: 'https://schedule.nylas.com/kaushal-patel-30min?prefilled_readonly=true&cost=5&name=Albert',
      pageDomain: 'https://schedule.nylas.com/kaushal-patel-30min',
      auth: {
        accessToken: 'LpHi0VJQNQUh4mi5yI5BRGKEnYu6qb',
      },
    });
  };

  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', this.nylasScriptSrc);
    script.setAttribute('id', 'nylas-script');
    head.appendChild(script);
    // script.onload = () => {
    //   if (this.loadCalender1) this.loadCalender1();
    // };
  }

  componentWillUnmount() {
    const head = document.querySelector('head');
    const script = document.getElementById('nylas-script');
    script && head.removeChild(script);
  }
  render() {
    const { url } = this.props;
    return (
      <div id="schedule_form">
        <iframe
          src={url}
          width="100%"
          height="600"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="Scheduler"
        />
      </div>
    );
  }
}

export default NylasScheduler;
