import { defineMessages } from 'react-intl';

export const messages = {
  formTitle: {
    id: 'components.AnahitaForm.formTitle',
    defaultMessage: 'Anahita Anais: Health History Form',
  },
  formStepOneSubtitle: {
    id: 'components.AnahitaForm.formStepOneSubtitle',
    defaultMessage:
      'Please fill out the following as completely as possible. This information is confidential.',
  },
  formStepTwoSubtitle: {
    id: 'components.AnahitaForm.formStepTwoSubtitle',
    defaultMessage:
      'Please fill out the following as completely as possible. This information is confidential.',
  },
  sectionOneTitle: {
    id: 'components.AnahitaForm.sectionOneTitle',
    defaultMessage: 'General Health Rating:',
  },
  overallHealth: {
    id: 'components.AnahitaForm.overallHealth',
    defaultMessage: `How do you rate your overall health? (select one) 1 - being the "Worst" and 10 - being the "Best"`,
  },
  currentProfession: {
    id: 'components.AnahitaForm.currentProfession',
    defaultMessage: 'What is your current profession?',
  },
  diagnosisHistory: {
    id: 'components.AnahitaForm.diagnosisHistory',
    defaultMessage:
      'Have you ever had any of the following: (check any that apply)',
  },
  pregnantStatus: {
    id: 'components.AnahitaForm.pregnantStatus',
    defaultMessage: 'Are you pregnant?',
  },
  physicalMentalEmotionalConcerns: {
    id: 'components.AnahitaForm.physicalMentalEmotionalConcerns',
    defaultMessage:
      'List any other physical, mental and/or emotional problems or concerns that are currently affecting you:',
  },
  traumaHistory: {
    id: 'components.AnahitaForm.traumaHistory',
    defaultMessage: 'Describe any history of significant trauma.',
  },
  surgeriesStatus: {
    id: 'components.AnahitaForm.surgeriesStatus',
    defaultMessage: 'Have you had any surgeries?',
  },
  surgeriesStatusYesDetails: {
    id: 'components.AnahitaForm.surgeriesStatusYesDetails',
    defaultMessage:
      'If YES, please provide details including approximate date.',
  },
  standardDiet: {
    id: 'components.AnahitaForm.standardDiet',
    defaultMessage: 'Describe your standard diet.',
  },
  sleepPatterns: {
    id: 'components.AnahitaForm.sleepPatterns',
    defaultMessage: 'Describe your sleep patterns.',
  },
  familyDiagnoses: {
    id: 'components.AnahitaForm.familyDiagnoses',
    defaultMessage:
      'Is anyone in your immediate family diagnosed with schizophrenia, bipolar disorder, or borderline personality disorder?',
  },
  familyDiagnosesDetails: {
    id: 'components.AnahitaForm.familyDiagnosesDetails',
    defaultMessage: 'If so, please provide details.',
  },
  partnerApprovalHealingPractice: {
    id: 'components.AnahitaForm.partnerApprovalHealingPractice',
    defaultMessage:
      'If you are in a long-term relationship, is your spouse or partner aware and supportive of your choice to engage in this healing practice?',
  },
  strengthsList: {
    id: 'components.AnahitaForm.strengthsList',
    defaultMessage:
      'List your strengths: (ex. faith, supportive family or friends, qualities like persistence, optimism, etc.)',
  },
  meditativeSpiritualPractice: {
    id: 'components.AnahitaForm.meditativeSpiritualPractice',
    defaultMessage: 'Do you engage in meditative or spiritual practice?',
  },
  meditativeSpiritualPracticeDetails: {
    id: 'components.AnahitaForm.meditativeSpiritualPracticeDetails',
    defaultMessage: 'If YES, please describe.',
  },
  supplementsTaken: {
    id: 'components.AnahitaForm.supplementsTaken',
    defaultMessage: 'List all herbs and supplements you are currently taking.',
  },
  idealOutcome: {
    id: 'components.AnahitaForm.idealOutcome',
    defaultMessage: 'What ideal outcome are you looking for?',
  },
  additionalInformation: {
    id: 'components.AnahitaForm.additionalInformation',
    defaultMessage:
      'Please add any additional information that you would like to share:',
  },
};

export default defineMessages(messages);
