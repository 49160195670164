import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import styles from './Donut.module.scss';

export function Donut({ colors = ['#e5e5e5', '#e5e5e5'], filledPercentage }) {
  const [fillColors, setFillColors] = useState([]);
  const [dashArray, setDashArray] = useState([]);

  useEffect(() => {
    setDashArray([(250 * filledPercentage) / 100, 250].join(','));
    const gap = filledPercentage / colors.length;
    const percentList = [...Array(colors.length + 1).keys()].map(
      (i) => i * gap,
    );
    setFillColors(
      [...colors, colors[colors.length - 1]].map((color, i) => ({
        percent: `${percentList[i]}%`,
        gradient: color,
      })),
    );
  }, [colors, filledPercentage]);

  const randomID = `linear-${Math.floor(Math.random() * (100 - 0 + 1)) + 100}`;

  const blendColors = (colorA, colorB, amount) => {
    const [rA, gA, bA] = colorA.match(/\w\w/g).map((c) => parseInt(c, 16));
    const [rB, gB, bB] = colorB.match(/\w\w/g).map((c) => parseInt(c, 16));
    const r = Math.round(rA + (rB - rA) * amount)
      .toString(16)
      .padStart(2, '0');
    const g = Math.round(gA + (gB - gA) * amount)
      .toString(16)
      .padStart(2, '0');
    const b = Math.round(bA + (bB - bA) * amount)
      .toString(16)
      .padStart(2, '0');
    return '#' + r + g + b;
  };

  const getColors = (colors) => {
    return (
      <>
        {colors.map((item, i) => (
          <stop offset={item.percent} stopColor={item.gradient} key={i} />
        ))}
      </>
    );
  };

  return (
    <div className={styles.chartWrapper}>
      <h2
        className={classnames(styles.chartCaption, styles.circle)}
        style={{ color: blendColors(colors[0], colors[1], 0.5) }}
      >
        {filledPercentage}%
      </h2>
      <svg width="100" height="100">
        <defs>
          <linearGradient id={randomID} x1="0%" y1="0%" x2="0%" y2="100%">
            {getColors(fillColors)}
          </linearGradient>
        </defs>
        <circle
          cx="50"
          cy="50"
          r="40"
          stroke="#e5e5e5"
          strokeWidth="10"
          fill="transparent"
        />
        <path
          d="M 50,10 A 40 40 0 1 1 50 90 A 40 40 0 1 1 50 10"
          stroke={`url(#${randomID})`}
          strokeWidth="10"
          fill="transparent"
          strokeDasharray={dashArray}
          className={styles.circleAnimation}
        />
      </svg>
    </div>
  );
}
