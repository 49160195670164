import { defineMessages } from 'react-intl';

export default defineMessages({
  clientName: {
    id: 'pages.formHeaderSection.clientName',
    defaultMessage: 'Client Name',
  },
  dateCompleted: {
    id: 'pages.formHeaderSection.dateCompleted',
    defaultMessage: 'Date Completed',
  },
  button: {
    id: 'pages.formHeaderSection.button',
    defaultMessage: 'Download PDF',
  },
});
