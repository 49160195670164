import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'react-foundation';
import useIntl from '../../../../../hooks/useIntl';
import Loading from '../../../../common/Loading';
import styles from '../WildHeartForm.module.scss';
import messages from '../messages';
import { stepNineFormStructure } from '../formStructure';
import FormStep from './FormStep';

const StepFour = ({
  isLoading,
  values,
  handleChange,
  setFieldValue,
  handleCheckboxChange,
  handleBlur,
}) => {
  const { formatMessage } = useIntl();

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Cell small={12}>
        <p className={styles.formSubtitle}>
          {formatMessage(messages.formSubtitle)}
        </p>
      </Cell>
      {stepNineFormStructure.map((item, i) => (
        <FormStep
          key={i}
          stepName="WildHeartStepFour"
          item={item}
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          handleCheckboxChange={handleCheckboxChange}
          handleBlur={handleBlur}
        />
      ))}
    </>
  );
};

StepFour.propTypes = {
  isLoading: PropTypes.bool,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  setFieldValue: PropTypes.func,
};

export default StepFour;
