import React, { useState, Fragment, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import classnames from 'classnames';
import CryptoJS from 'crypto-js';

import Loading from '../../../components/common/Loading';
import StepCard from '../../../components/StepCard';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import DoctorLeftNav from '../../../components/nav/DoctorLeftNav';
import NylasAvailability from '../../../components/NylasAvailability';
import DoctorAppointment from '../../../components/DoctorAppointment';
import BillingPlanFeature from '../../../components/BillingPlanFeature';

import SuperBillCard from '../SuperBillCard';
import PreNylasIntegrationPopup from '../PreNylasIntegrationPopup';

import { updateAppointment } from '../../../api/appointment';
import { getDoctorAppointments } from '../../../api/user';
import { getDoctor, getSuperBillRequests } from '../../../api/doctor';

import { SQUARE_APPLICATION_ID } from '../../../utils/envVariables';

import { parseSuperBillRequests } from '../../../utils/common';
import { CLINICIAN_REGISTRATION_STATUS } from '../../../constants';

import AuthContext from '../../../context/AuthContext';

import styles from './MyAccountPage.module.scss';

const { REACT_APP_SQUARE_BASE_PATH, REACT_APP_NYLAS_FEATURE_FLAG } =
  process.env;

const MyAccountPage = () => {
  const history = useHistory();

  const { isLoading, authUser } = useContext(AuthContext);

  const [dataLoading, setLoading] = useState(false);
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [isAppointmentsLoading, setIsAppointmentsLoading] = useState(true);
  const [isSuperbillLoading, setIsSuperbillLoading] = useState(true);

  const [appointments, setAppointments] = useState([]);
  const [requestedSuperBills, setRequestedSuperBills] = useState([]);
  const [doctor, setDoctor] = useState({});
  const [isOnPreNylasIntegration, setIsOnPreNylasIntegration] = useState(false);

  const isDataLoading =
    isLoading ||
    dataLoading ||
    isProfileLoading ||
    isAppointmentsLoading ||
    isSuperbillLoading;

  const state = CryptoJS.MD5(new Date().getTime());
  sessionStorage.setItem('state', state);

  const fetchAppointments = async () => {
    if (authUser.id) {
      try {
        setIsAppointmentsLoading(true);

        const params = {
          doctor_id: authUser.id,
          filter: 'upcoming',
          limit: 5,
          isViewByDoctor: true,
        };
        const { appointments } = await getDoctorAppointments(params);
        setAppointments(appointments);

        setIsAppointmentsLoading(false);
      } catch (error) {
        setIsAppointmentsLoading(false);
      }
    }
  };

  const fetchProfile = async () => {
    if (authUser.id) {
      try {
        setIsProfileLoading(true);

        const resp = await getDoctor(authUser.id);
        setDoctor(resp.doctor);

        setIsProfileLoading(false);
      } catch (error) {
        setIsProfileLoading(false);
      }
    }
  };

  const fetchSuperBill = async () => {
    if (authUser) {
      try {
        setIsSuperbillLoading(true);

        const data = await getSuperBillRequests();

        const parsingRequestsFilters = [
          {
            key: 'status',
            value: 'REQUESTED',
          },
          {
            key: 'requested_at',
            value: true,
          },
        ];
        const response = parseSuperBillRequests(
          data,
          'appointment.patient.id',
          'requested_at',
          parsingRequestsFilters,
        );

        setRequestedSuperBills(response);

        setIsSuperbillLoading(false);
      } catch (error) {
        setIsSuperbillLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchProfile();
    fetchAppointments();
    fetchSuperBill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const handleFlagClick = async (item) => {
    try {
      setLoading(true);
      const { appointment } = await updateAppointment(item.id, {
        is_flag: !item.is_flag,
      });
      const index = appointments.findIndex((apnmnt) => apnmnt.id === item.id);
      const UpdateAppointments = [...appointments];
      UpdateAppointments[index] = appointment;
      setAppointments(UpdateAppointments);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleEditAvailability = async () => {
    history.push('/doctor/availability');
  };

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <DoctorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            >
              <div className={styles.header} data-testid="dashboard firstname">
                Hi {authUser.first_name}
              </div>
            </div>
            <div className={styles.stepsCard}>
              <BillingPlanFeature appointments>
                {REACT_APP_NYLAS_FEATURE_FLAG === 'true' ? (
                  <>
                    {!doctor?.nylas_token && (
                      <StepCard
                        step={`Step 1`}
                        head={'Set-up available appointment types and times'}
                        btnText={`Set-up`}
                        onClick={() => setIsOnPreNylasIntegration(true)}
                      />
                    )}
                    {doctor?.nylas_token && (
                      <StepCard
                        step={`Step 1`}
                        head={
                          !doctor?.calendlyApiKey
                            ? 'Setup preferred time to get patient appointments'
                            : 'Update times, types or fees for appointments'
                        }
                        btnText={`Edit Availability`}
                        onClick={handleEditAvailability}
                      />
                    )}
                  </>
                ) : (
                  <StepCard
                    step={`Step 1`}
                    head={
                      !doctor?.calendlyApiKey
                        ? 'Setup preferred time to get patient appointments'
                        : 'Update times, types or fees for appointments'
                    }
                    btnText={`Edit Availability`}
                    onClick={() =>
                      history.push('/doctor/calendly-availability')
                    }
                  />
                )}
              </BillingPlanFeature>

              <BillingPlanFeature payments>
                {!doctor?.square_access_token &&
                  ((authUser && authUser.clinics && authUser.clinics.id) ||
                    authUser.clinic_id === 0) && (
                    <StepCard
                      step={`Step 2`}
                      head={`Connect your account to receive payments`}
                      isHeadAmount={false}
                      btnText={`Setup`}
                      onClick={() =>
                        (window.location.href = `${REACT_APP_SQUARE_BASE_PATH}/oauth2/authorize?client_id=${SQUARE_APPLICATION_ID}&scope=MERCHANT_PROFILE_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE+PAYMENTS_READ+CUSTOMERS_WRITE+CUSTOMERS_READ&state=${state}`)
                      }
                    />
                  )}
              </BillingPlanFeature>
            </div>

            {process.env.REACT_APP_SUPERBILL_FLAG === 'true' &&
              requestedSuperBills.length > 0 && (
                <ul className={styles.superBillWrapper}>
                  {requestedSuperBills.map((item, index) => (
                    <li key={index}>
                      <SuperBillCard
                        title={'Superbill Request'}
                        message={`
                      ${item.appointment.patient.first_name} ${
                          item.appointment.patient.last_name
                        } has requested a superbill for their appointment on ${moment(
                          item.appointment.start_time,
                        ).format('dddd, MMMM DD, YYYY')}`}
                        buttonClass={'createSuperBillButton'}
                        buttonLabel={'Create Superbill'}
                        onClick={() =>
                          history.push(`/doctor/superbill/clinician/${item.id}`)
                        }
                      />
                    </li>
                  ))}
                </ul>
              )}

            <BillingPlanFeature appointments>
              <h1 data-testid={`dashboard upcoming appointments`}>
                Upcoming appointments
              </h1>
              {appointments.length > 0 ? (
                <Fragment>
                  {appointments.map((item, Index) => (
                    <DoctorAppointment
                      item={item}
                      key={Index}
                      onFlagClick={handleFlagClick}
                    />
                  ))}
                </Fragment>
              ) : (
                <div
                  className={styles.nodata}
                  data-testid={`dashboard no appointments available`}
                >
                  No appointments available
                </div>
              )}
              {appointments.length > 0 && (
                <div className={styles.buttons}>
                  <SecondaryButton
                    label={`See All`}
                    className={styles.btn}
                    type="button"
                    onClick={() => history.push('/doctor/appointments')}
                    data-testid={`dashboard doctor appointment button`}
                  />
                </div>
              )}
            </BillingPlanFeature>
          </div>

          {isOnPreNylasIntegration && (
            <PreNylasIntegrationPopup
              onCloseModal={() => setIsOnPreNylasIntegration(false)}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default MyAccountPage;
