import * as Yup from 'yup';

export const validate = Yup.object().shape({
  first_name: Yup.string()
    .matches(/(.|\s)*\S(.|\s)*/, 'Please enter your first name')
    .required('Field is required'),
  last_name: Yup.string()
    .matches(/(.|\s)*\S(.|\s)*/, 'Please enter your last name')
    .required('Field is required'),
  home_phone: Yup.string().matches(
    /^.{5,}[0-9]+$|^$/,
    'Please enter valid phone number',
  ),
  phone: Yup.string().matches(
    /^.{5,}[0-9]+$|^$/,
    'Please enter valid phone number',
  ),
  work_phone: Yup.string().matches(
    /^.{5,}[0-9]+$|^$/,
    'Please enter valid phone number',
  ),
  email: Yup.string().email('Invalid email'),
  emergency_contact_phone: Yup.string().matches(
    /^.{5,}[0-9]+$|^$/,
    'Please enter valid phone number',
  ),
  emergency_contact_email: Yup.string().email('Invalid email'),
  primary_care_doctor_phone: Yup.string().matches(
    /^.{5,}[0-9]+$|^$/,
    'Please enter valid phone number',
  ),
});
