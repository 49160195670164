import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import styles from '../../FormListView.module.scss';
import useIntl from '../../../../hooks/useIntl';
import messages from './messages';

const FormDownloadLink = ({ item, pdfDownload, formType }) => {
  const { formatMessage } = useIntl();

  const firstName = item.patient?.first_name || '';
  const lastName = item.patient?.last_name || '';
  const formName = item.form_name || '';
  const fileName = item.form_name || 'form';
  const completedDate = moment(item.updatedAt).format('MMMM DD, YYYY');

  return (
    <div className={styles.box}>
      <div className={styles['content-detail']}>
        <div className={classnames([styles.headerSection])}>
          <div>
            <p className={styles.label}>{formatMessage(messages.clientName)}</p>
            <p>{`${firstName} ${lastName}`}</p>
          </div>
          <div>
            <p className={styles.label}>{formType}</p>
            <p>{formName}</p>
          </div>
          <div>
            <p className={styles.label}>
              {formatMessage(messages.dateCompleted)}
            </p>
            <p>{completedDate}</p>
          </div>
          <PDFDownloadLink document={pdfDownload} fileName={`${fileName}.pdf`}>
            <PrimaryButton
              label={formatMessage(messages.button)}
              type="button"
              className={styles.btn}
            />
          </PDFDownloadLink>
        </div>
      </div>
    </div>
  );
};

FormDownloadLink.defaultProps = {
  formType: 'Intake',
};

FormDownloadLink.propTypes = {
  item: PropTypes.object,
  pdfDownload: PropTypes.node,
  formType: PropTypes.string,
};

export default FormDownloadLink;
