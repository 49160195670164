import React from 'react';
import styles from './Psychotherapist.module.scss';
import PrimaryButton from '../common/buttons/PrimaryButton';
import SecondaryButton from '../common/buttons/SecondaryButton';
import { addToDataLayer } from '../../utils/common';

const Psychotherapist = ({ item, onBookAppointment, history }) => {
  const getDescription = (description) => {
    const desc = description.replace(/\r?\n/g, '<br />');
    return desc;
  };
  return (
    <>
      <div className={styles.root} data-testid={`psychotherapist ${item.id}`}>
        <div className={styles.content}>
          <div className={styles.imgBox}>
            <img
              src={
                item.profile_pic
                  ? item.profile_pic
                  : require('../../img/doctor.jpg')
              }
              alt={`${item.first_name} ${item.last_name ? item.last_name : ''}`}
              data-testid={`psychotherapist image ${item.id}`}
            />
          </div>
          <div className={styles.info}>
            <div className={styles.title} data-testid={`psychotherapist_title`}>
              {item.first_name} {item.last_name ? item.last_name : ''}
            </div>
            {item.description && (
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: getDescription(item.description),
                }}
                data-testid={`psychotherapist description ${item.id}`}
              />
            )}
          </div>
        </div>
        <div className={styles.buttonDiv}>
          <SecondaryButton
            label={`View Profile`}
            className={styles.button}
            onClick={() => {
              const eventData = {
                event: 'ga4_event',
                properties: {
                  event_name: 'view_clinician_profile',
                  clinician_id: item.id,
                  clinician_name: item.first_name,
                },
              };
              addToDataLayer(eventData);
              history.push(`/my-account/view-profile/${item.id}`);
            }}
            data-testid={`psychotherapist view profile button ${item.id}`}
          />
          <PrimaryButton
            label={`Book Now`}
            className={styles.button}
            onClick={() => {
              const eventData = {
                event: 'ga4_event',
                properties: {
                  event_name: 'book_now',
                  clinician_id: item.id,
                  clinician_name: item.first_name,
                },
              };
              addToDataLayer(eventData);
              onBookAppointment(item);
            }}
            data-testid={`psychotherapist view book appointment button ${item.id}`}
          />
        </div>
      </div>
    </>
  );
};

export default Psychotherapist;
