import { defineMessages } from 'react-intl';

export default defineMessages({
  close: {
    id: 'component.nav.mobile.closeAltText',
    defaultMessage: 'Close',
  },
  user: {
    id: 'component.nav.mobile.userAltText',
    defaultMessage: 'User',
  },
  discover: {
    id: 'component.nav.mobile.discoverLink',
    defaultMessage: 'discover',
  },
  shop: {
    id: 'component.nav.mobile.shopLink',
    defaultMessage: 'shop',
  },
  shopAll: {
    id: 'component.nav.mobile.shopAllLink',
    defaultMessage: 'Shop All',
  },
  learn: {
    id: 'component.nav.mobile.learnLink',
    defaultMessage: 'learn',
  },
  explore: {
    id: 'component.nav.mobile.exploreLink',
    defaultMessage: 'about',
  },
  myAccount: {
    id: 'component.nav.mobile.myAccountLink',
    defaultMessage: 'My Account',
  },
  doctorAccount: {
    id: 'component.nav.mobile.doctorAccountLink',
    defaultMessage: 'My Account',
  },
  recommendations: {
    id: 'component.nav.mobile.recommendationsLink',
    defaultMessage: 'Recommendations',
  },
  signIn: {
    id: 'component.nav.mobile.signInLink',
    defaultMessage: 'Sign In',
  },
  logout: {
    id: 'component.nav.mobile.logout',
    defaultMessage: 'Log out',
  },
  resetModalTitle: {
    id: 'pages.formsList.modalTitle',
    description: 'FormList Reset confirmation modal title',
    defaultMessage: 'Reset Form',
  },
  resetModalText: {
    id: 'pages.formsList.modalText',
    description: 'FormList Reset  confirmation modal text',
    defaultMessage: 'Are you sure you want to reset this form?',
  },
  resetModalButtonPrimary: {
    id: 'pages.formsList.modalButtonPrimary',
    description: 'FormList Reset  confirmation primary button label',
    defaultMessage: 'Reset',
  },
  resetModalButtonSecondary: {
    id: 'pages.formsList.modalButtonSecondary',
    description: 'FormList Reset confirmation secondary button label',
    defaultMessage: 'Cancel',
  },
});
