import React, { useContext } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Logo from '../Logo';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import DynamicClinicText from '../DynamicClinicText';

import styles from './ExpireToken.module.scss';

import Warning from '../../img/warning.png';
import Back from '../../img/icons/back.svg';

import { requestNewToken } from '../../api/auth';

import WhitelabelContext from '../../context/WhitelabelContext';

const ExpireToken = () => {
  const param = useParams();
  const history = useHistory();

  const { whitelabel } = useContext(WhitelabelContext);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.backDiv}>
          <Link to="/">
            <img src={Back} alt="Back" />
            <span>Back</span>
          </Link>
        </div>
        <Logo />
        <div className={styles.warningImg}>
          <img src={Warning} alt="Success" />
        </div>
        <h4 className={styles.confirmationTitle}>Token expired</h4>

        <p className={styles.text}>
          Please contact{' '}
          {whitelabel && whitelabel.subdomain ? (
            <b>hello@{whitelabel.subdomain}</b>
          ) : (
            <b>hello@evehealthsystems.com</b>
          )}{' '}
          if you are experiencing difficulties using an invitation, or would
          like to learn more about <DynamicClinicText />
        </p>
        <div className={styles.nextBtn}>
          <PrimaryButton
            className={styles.primaryBtn}
            label="Request New Token"
            onClick={() => {
              requestNewToken({
                token: param.token,
                userType: param.userType,
                oktaId: param.oktaId || null,
              });

              toast('New token sent to your email', {
                autoClose: 5000,
                className: styles.toastMsg,
                bodyClassName: styles.toastDiv,
                hideProgressBar: true,
              });
              history.push('/');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ExpireToken;
