import React, { Fragment, useContext, useState } from 'react';
import { Cell, Grid, Menu, MenuItem } from 'react-foundation';
import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Modal from '../../common/Modal';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import SecondaryButton from '../../common/buttons/SecondaryButton';
import FieldDropdown from '../../common/fields/FieldDropdown';
import { getPatientList } from '../../../api/doctor';
import Loading from '../../../components/common/Loading';
import AuthContext from '../../../context/AuthContext';
import { logout } from '../../../api/auth';
import user from '../../../img/patient.jpg';
import close from '../../../img/close.svg';
import styles from './SidebarMenu.module.scss';
import { useHistory } from 'react-router-dom';
import { getFactMatchedDoctors } from '../../../api/doctor';
import useIntl from '../../../hooks/useIntl';
import messages from './messages';
import { useOktaAuth } from '@okta/okta-react';
import Logo from '../../Logo';
import { compareFun, addToDataLayer } from '../../../utils/common';
import { USER_TYPES, PATIENT_DETAILS_STATUS } from '../../../constants';

const SidebarMenu = ({ onCloseMenu }) => {
  const [doctorList, setDoctorList] = useState([]);
  const [doctorId, setDoctorId] = useState('');
  const [patientId, setPatientId] = useState('');
  const [patientList, setPatientList] = useState([]);
  const [error, setError] = useState('');
  const history = useHistory();
  const { authUser, dispatch, userAvtar } = useContext(AuthContext);
  const [showBookAppointment, setShowBookAppointment] = useState(false);
  const [showDoctorBookAppointment, setShowDoctorBookAppointment] =
    useState(false);
  const { formatMessage } = useIntl();
  const [clinicianId, setClinicianId] = useState('');
  const [clinicianList, setClinicianList] = useState([]);
  const { authState, oktaAuth } = useOktaAuth();
  const [isDataLoading, setDataIsLoading] = useState(false);

  const fetchDoctors = async () => {
    setDataIsLoading(true);
    const params = {
      patient_id: authUser.id,
      clinic_id: (authUser.invites && authUser.invites.clinic_id) || 0,
    };
    if (authUser.profile_type === 'doctor') {
      params.filter = 'clinic';
    }
    let { doctors } = await getFactMatchedDoctors(params);

    if (doctors.length > 0) {
      doctors = doctors.sort((a, b) => {
        return compareFun(
          a.first_name.toLowerCase(),
          b.first_name.toLowerCase(),
          'Asc',
        );
      });
    }
    const formattedDoctorList = doctors.map((doctor) => {
      return {
        label: `${doctor.first_name} ${doctor.last_name}`,
        value: `${doctor.id}`,
      };
    });
    setDataIsLoading(false);

    setDoctorList(formattedDoctorList);
  };

  const fetchPatients = async () => {
    setDataIsLoading(true);
    const params = {
      doctor_id: authUser.id,
      clinic_id: authUser.clinic_id,
    };
    let { patientList } = await getPatientList(params);

    let patients = patientList
      .map((patients) => patients.patient)
      .filter((x) => x.status === PATIENT_DETAILS_STATUS.ACTIVE);

    if (patients.length > 0) {
      // sort patient by alphbetical order
      patients = patients.sort((a, b) => {
        return compareFun(
          a.first_name && a.first_name.toLowerCase(),
          b.first_name && b.first_name.toLowerCase(),
          'Asc',
        );
      });
    }

    if (authUser.clinic_id !== 0) {
      const doctorParams = {
        clinic_id: authUser.clinic_id || 0,
        filter: 'clinic',
      };
      let { doctors } = await getFactMatchedDoctors(doctorParams);

      if (patients.length > 0) {
        // sort doctors by alphbetical order
        doctors = doctors.sort((a, b) => {
          return compareFun(
            a.first_name.toLowerCase(),
            b.first_name.toLowerCase(),
            'Asc',
          );
        });
      }
      const formattedClinicianList = doctors.map((doctor) => {
        return {
          label: `${doctor.first_name} ${
            doctor.last_name ? doctor.last_name : ''
          }`,
          value: `${doctor.id}`,
        };
      });
      setClinicianList(formattedClinicianList);
    }
    const formattedPatientList = patients.map((patient) => {
      return {
        label: `${patient.first_name} ${
          patient.last_name ? patient.last_name : ''
        }`,
        value: `${patient.id}`,
      };
    });
    setDataIsLoading(false);
    setPatientList(formattedPatientList);
  };
  return (
    <Grid>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <Cell small={12} className={styles.header}>
            <div
              className={styles['icon-container']}
              onClick={onCloseMenu}
              data-testid="close-button"
            >
              <img src={close} alt={formatMessage(messages.close)} />
            </div>

            <div className={styles.logoDiv}>
              <Logo />
            </div>

            <div className={styles['icon-container']}></div>
          </Cell>
          <Cell small={12} className={styles.menu}>
            <Menu isVertical>
              {isDataLoading ? (
                <MenuItem>
                  <Link to="/" onClick={onCloseMenu}>
                    ...
                  </Link>
                </MenuItem>
              ) : authUser.id ? (
                <Fragment>
                  <Link
                    to={
                      authUser.profile_type === USER_TYPES.PATIENT
                        ? '/my-account'
                        : '/doctor/my-account'
                    }
                    onClick={onCloseMenu}
                    data-testid="menu myaccount link"
                  >
                    <div className={styles.user}>
                      <img src={userAvtar} alt={formatMessage(messages.user)} />
                      <h4>
                        <strong data-testid="profile menu name">
                          {authUser.first_name} {authUser.last_name}
                        </strong>
                      </h4>
                    </div>
                  </Link>
                  <div className={styles.submenu}>
                    <MenuItem>
                      <Link
                        to={
                          authUser.profile_type === USER_TYPES.PATIENT
                            ? '/my-account/myprofile'
                            : '/doctor/profile'
                        }
                        onClick={onCloseMenu}
                        data-testid="myaccount profile link"
                      >
                        {authUser.profile_type === USER_TYPES.PATIENT
                          ? formatMessage(messages.myAccount)
                          : formatMessage(messages.doctorAccount)}
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Route
                        render={({ history }) => (
                          <span
                            className={styles.logout}
                            data-testid="logout link"
                            onClick={() => {
                              const eventData = {
                                event: 'ga4_event',
                                properties: {
                                  event_name: 'log_out',
                                  user_type: authUser.profile_type || '',
                                  patient_id:
                                    authUser.profile_type === 'patient'
                                      ? authUser.id
                                      : '',
                                  clinician_id:
                                    authUser.profile_type === 'doctor'
                                      ? authUser.id
                                      : '',
                                },
                              };
                              addToDataLayer(eventData);
                              if (authState.isAuthenticated) {
                                oktaAuth.signOut({
                                  postLogoutRedirectUri: window.location.origin,
                                });
                              }
                              logout(dispatch);
                              onCloseMenu();
                              history.push('/');
                            }}
                          >
                            {formatMessage(messages.logout)}
                          </span>
                        )}
                      />
                    </MenuItem>
                    <hr />
                    {authUser.profile_type === USER_TYPES.PATIENT && (
                      <>
                        <li>
                          <NavLink
                            exact
                            to="/my-account"
                            activeClassName={styles.active}
                            onClick={onCloseMenu}
                          >
                            <span>Dashboard</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/my-account/appointments"
                            activeClassName={styles.active}
                            onClick={onCloseMenu}
                          >
                            <span>Appointments</span>
                          </NavLink>
                        </li>
                        {process.env.REACT_APP_WELLNESS_FEATURE_FLAG ===
                          'true' && (
                          <li>
                            <NavLink
                              exact
                              to="/my-account/wellness"
                              activeClassName={styles.active}
                            >
                              <span data-testid="patient Wellness tab">
                                Wellness
                              </span>
                            </NavLink>
                          </li>
                        )}
                        <li>
                          <div
                            className={styles.bookAppointment}
                            onClick={async () => {
                              setDataIsLoading(true);
                              await fetchDoctors();
                              setShowBookAppointment(true);
                              setDataIsLoading(false);
                            }}
                          >
                            <span>Book New Appointment</span>
                          </div>
                        </li>
                        <li>
                          <NavLink
                            to="/my-account/intake-forms"
                            activeClassName={styles.active}
                            onClick={onCloseMenu}
                          >
                            <span>Forms and Assessments</span>
                          </NavLink>
                        </li>
                        {authUser && !authUser.invitation_status && (
                          <li>
                            <NavLink
                              to="/my-account/discoverv2"
                              activeClassName={styles.active}
                              onClick={onCloseMenu}
                            >
                              <span>Assessment</span>
                            </NavLink>
                          </li>
                        )}
                        <li>
                          <a>
                            <span onClick={onCloseMenu}>Give Feedback</span>
                          </a>
                        </li>
                      </>
                    )}
                    {authUser.profile_type === USER_TYPES.DOCTOR && (
                      <>
                        <li>
                          <NavLink
                            exact
                            to="/doctor/my-account"
                            activeClassName={styles.active}
                            onClick={onCloseMenu}
                          >
                            <span>Dashboard</span>
                            <div></div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/doctor/appointments"
                            activeClassName={styles.active}
                            onClick={onCloseMenu}
                          >
                            <span>Appointments</span>
                          </NavLink>
                        </li>
                        <li>
                          <div
                            className={styles.bookAppointment}
                            onClick={async () => {
                              setDataIsLoading(true);
                              await fetchPatients();
                              setShowDoctorBookAppointment(true);
                              setDataIsLoading(false);
                            }}
                          >
                            <span> Book New Appointment</span>
                          </div>
                        </li>
                        <li>
                          <NavLink
                            to="/doctor/patients"
                            activeClassName={styles.active}
                            onClick={onCloseMenu}
                          >
                            <span>Patients</span>
                          </NavLink>
                        </li>
                        {process.env.REACT_APP_MANAGE_FORM_FUNCTIONALITY ===
                          'true' && (
                          <li>
                            <NavLink
                              to="/doctor/manage-forms"
                              activeClassName={styles.active}
                              onClick={onCloseMenu}
                            >
                              <span>Manage Forms</span>
                            </NavLink>
                          </li>
                        )}
                        {((authUser &&
                          authUser.clinics &&
                          authUser.clinics.id) ||
                          authUser.clinic_id === 0) && (
                          <li>
                            <NavLink
                              to="/doctor/payment"
                              activeClassName={styles.active}
                              onClick={onCloseMenu}
                            >
                              <span>Payment</span>
                            </NavLink>
                          </li>
                        )}
                        <li>
                          <NavLink
                            to="/doctor/invite-patient"
                            activeClassName={styles.active}
                            onClick={onCloseMenu}
                          >
                            <span>Invite Patient</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/doctor/invite-clinician"
                            activeClassName={styles.active}
                            onClick={onCloseMenu}
                          >
                            <span>Invite Clinician</span>
                          </NavLink>
                        </li>
                        {authUser && authUser.clinics && authUser.clinics.id && (
                          <li>
                            <NavLink
                              to="/doctor/team"
                              activeClassName={styles.active}
                              onClick={onCloseMenu}
                            >
                              <span>Team</span>
                            </NavLink>
                          </li>
                        )}
                        {authUser && authUser.clinics && authUser.clinics.id && (
                          <li>
                            <NavLink
                              to="/doctor/clinic"
                              activeClassName={styles.active}
                              onClick={onCloseMenu}
                            >
                              <span>My Clinic</span>
                            </NavLink>
                          </li>
                        )}
                        <li>
                          <a>
                            <span onClick={onCloseMenu}>Give Feedback</span>
                          </a>
                        </li>
                      </>
                    )}
                  </div>
                </Fragment>
              ) : (
                <MenuItem>
                  <Link to="/" onClick={onCloseMenu}>
                    <div className={styles.user}>
                      <img src={user} alt={formatMessage(messages.user)} />
                      <span>{formatMessage(messages.signIn)}</span>
                    </div>
                  </Link>
                </MenuItem>
              )}
            </Menu>
          </Cell>
          <div>
            {showBookAppointment && (
              <Modal className={styles.bookAppointmentModal}>
                <div className={styles.content}>
                  <div
                    className={styles.closeButton}
                    onClick={() => {
                      setShowBookAppointment(false);
                      setError('');
                      setDoctorId('');
                    }}
                  >
                    <img
                      src={require(`../../../img/close.svg`)}
                      alt={`Close`}
                    />
                  </div>
                  <h1>
                    <b>Appointment With?</b>
                  </h1>
                  <div className={styles.box}>
                    <p>Please select provider to book appointment with</p>
                  </div>
                  <Cell small={12} medium={12} className={styles.dropDown}>
                    <FieldDropdown
                      label=""
                      name="doctor"
                      errorText={error}
                      value={doctorId}
                      onChange={(e) => {
                        setError('');
                        setDoctorId(e.target.value);
                      }}
                      options={doctorList}
                      placeholder="Select Provider"
                      dataTestId="SidebarMenu.SelectProvider"
                    />
                  </Cell>
                  <PrimaryButton
                    label="Continue Appointment"
                    className={styles.primaryButton}
                    onClick={() => {
                      if (doctorId) {
                        setShowBookAppointment(false);
                        onCloseMenu();
                        history.push(`/my-account/appointment/${doctorId}`);
                        setDoctorId('');
                      } else {
                        setError('Please Select Physician');
                      }
                    }}
                  />
                  <SecondaryButton
                    label={formatMessage(messages.resetModalButtonSecondary)}
                    className={styles.secondaryButton}
                    onClick={() => {
                      setShowBookAppointment(false);
                      setError('');
                      setDoctorId('');
                    }}
                  />
                </div>
              </Modal>
            )}
            {showDoctorBookAppointment && (
              <Modal className={styles.bookAppointmentModal}>
                <div className={styles.content}>
                  <div
                    className={styles.closeButton}
                    onClick={() => {
                      setShowDoctorBookAppointment(false);
                      setError('');
                      setPatientId('');
                    }}
                  >
                    <img
                      src={require(`../../../img/close.svg`)}
                      alt={`Close`}
                    />
                  </div>
                  <h1>
                    <b>Appointment With?</b>
                  </h1>
                  <div className={styles.box}>
                    <p>Please select patient to book appointment with</p>
                  </div>
                  {patientList.length > 0 && (
                    <Cell small={12} medium={12} className={styles.dropDown}>
                      <FieldDropdown
                        label=""
                        name="patient"
                        errorText={error}
                        value={patientId}
                        onChange={(e) => {
                          setError('');
                          setPatientId(e.target.value);
                        }}
                        options={patientList}
                        placeholder="Select Patient"
                        isSearchable={true}
                        dataTestId="SidebarMenu.SelectPatient"
                      />
                    </Cell>
                  )}
                  {clinicianList.length > 0 && (
                    <>
                      <div className={styles.box}>
                        <p>Add a clinician </p>
                      </div>

                      <Cell small={6} medium={3} className={styles.dropDown}>
                        <FieldDropdown
                          label=""
                          name="doctor"
                          value={clinicianId}
                          onChange={(e) => {
                            setError('');
                            setClinicianId(e.target.value);
                          }}
                          options={clinicianList}
                          placeholder="Select Clinician"
                          dataTestId="SidebarMenu.SelectClinician"
                          isSearchable={true}
                        />
                      </Cell>
                    </>
                  )}
                  {patientList.length > 0 ? (
                    <>
                      <PrimaryButton
                        label="Continue Appointment"
                        className={styles.primaryButton}
                        onClick={() => {
                          if (patientId) {
                            if (clinicianId) {
                              setShowDoctorBookAppointment(false);
                              onCloseMenu();
                              history.push(
                                `/doctor/appointment/${patientId}/clinician/${clinicianId}`,
                              );
                              setClinicianId('');
                              setPatientId('');
                            } else {
                              setShowDoctorBookAppointment(false);
                              onCloseMenu();
                              history.push(
                                `/doctor/appointment/${patientId}/clinician/${authUser.id}`,
                              );
                              setPatientId('');
                              setClinicianId('');
                            }
                          } else {
                            setError('Please Select Patient');
                          }
                        }}
                      />
                      <SecondaryButton
                        label={formatMessage(
                          messages.resetModalButtonSecondary,
                        )}
                        className={styles.secondaryButton}
                        onClick={() => {
                          setShowDoctorBookAppointment(false);
                          setError('');
                          setPatientId('');
                          setClinicianId('');
                        }}
                      />
                    </>
                  ) : (
                    <div className={styles.notInvited}>
                      <p>No Patients Found!!</p>
                    </div>
                  )}
                </div>
              </Modal>
            )}
          </div>
        </Fragment>
      )}
    </Grid>
  );
};

SidebarMenu.propTypes = {
  onCloseMenu: PropTypes.func.isRequired,
};

export default SidebarMenu;
