import { useState, useEffect } from 'react';
import breaks from '../../styles/_breaks.scss';

const useScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const resize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  return {
    isDesktop: width > breaks.breakSmall.split('px')[0],
    isMobile: width < breaks.breakSmall.split('px')[0],
  };
};

export default useScreen;
