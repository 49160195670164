import React, { useState, Fragment, useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Cell } from 'react-foundation';
import classnames from 'classnames';

import Loading from '../../../components/common/Loading';
import DoctorLeftNav from '../../../components/nav/DoctorLeftNav';

import styles from './DoctorNylasCallbackPage.module.scss';
import AuthContext from '../../../context/AuthContext';
import useIntl from '../../../hooks/useIntl';

import messages from './messages';
import ApprovalImg from '../../../img/approval.svg';
import SuccessImg from '../../../img/success-new.svg';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';

import { generatenNylasToken } from '../../../api/nylas';

const DoctorNylasCallbackPage = ({ match }) => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const { isLoading, authUser, dispatch } = useContext(AuthContext);

  const [dataLoading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const isDataLoading = dataLoading || isLoading;

  const query = new URLSearchParams(useLocation().search);

  const generateAccessToken = async (code, id) => {
    try {
      const newNylasData = await generatenNylasToken({
        code,
      });

      dispatch({
        type: 'UPDATE_USER',
        payload: {
          ...authUser,
          nylas_token: newNylasData.nylas_token,
          nylas_account_id: newNylasData.nylas_account_id,
        },
      });

      setSuccessMessage(`Your calendar has been successfully connected to Eve`);
      setLoading(false);
    } catch (error) {
      setErrorMessage('Something went wrong.Please try again');
      setLoading(false);
    }
  };

  useEffect(() => {
    const code = query.get('code');
    const error = query.get('error');
    if ((code || error) && authUser.id && !authUser.nylas_token) {
      generateAccessToken(code, authUser.id);
    }
  }, [authUser]);

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftNav, 'desktop-only')}>
        <DoctorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            />
            <h1 data-testid={`nylas title`}>{formatMessage(messages.title)}</h1>
            {successMessage !== '' && (
              <div className={styles.message}>
                <div className={styles.warningImg}>
                  <img
                    src={SuccessImg}
                    alt="Success"
                    data-testid={`nylas success image`}
                  />
                </div>
                <h2 data-testid={`nylas success heading`}>Success!</h2>
                <p
                  className={styles.subTitle}
                  data-testid={`nylas success message`}
                >
                  {successMessage}
                </p>
                <Cell small={12} flexContainer alignY="middle" flexDirCol="all">
                  <PrimaryButton
                    label="Ok"
                    type="button"
                    className="font-18"
                    onClick={() => history.push('/doctor/my-account')}
                    data-testid={`nylas redirect to dashboard`}
                  />
                </Cell>
              </div>
            )}
            {errorMessage !== '' && (
              <div className={styles.message}>
                <div className={styles.warningImg}>
                  <img
                    src={ApprovalImg}
                    alt="Warning"
                    data-testid={`nylas warning image`}
                  />
                </div>
                <h2 data-testid={`nylas connection failed`}>
                  Connection failed
                </h2>
                <p
                  className={styles.subTitle}
                  data-testid={`nylas error message`}
                >
                  {errorMessage}
                </p>
                <Cell small={12} flexContainer alignY="middle" flexDirCol="all">
                  <PrimaryButton
                    label="Ok"
                    type="button"
                    className="font-18"
                    onClick={() => history.push('/doctor/my-account')}
                    data-testid={`nylas redirect to dashboard`}
                  />
                </Cell>
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default DoctorNylasCallbackPage;
