import React from 'react';
import PropTypes from 'prop-types';
import RoundDropdown from '../../inputs/RoundDropdown';
import Label from '../../Label';

const FieldDropdown = ({
  label,
  errorText,
  name,
  value,
  onChange,
  onBlur,
  options,
  disabled,
  placeholder,
  className,
  customStyles,
  dataTestId,
  isSearchable,
  autoFocus,
}) => {
  return (
    <div>
      <Label
        id={dataTestId}
        errorText={errorText}
        dataTestId={dataTestId}
        htmlFor={`select_${dataTestId}`}
      >
        {label}
      </Label>
      <RoundDropdown
        autoFocus={autoFocus}
        customStyles={customStyles || null}
        className={className || ''}
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        dataTestId={dataTestId}
        isSearchable={isSearchable}
      />
    </div>
  );
};

FieldDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSearchable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
};
export default FieldDropdown;
