import React, { useState, Fragment, useContext, useEffect } from 'react';
import { Cell, Grid } from 'react-foundation';
import { Formik, Form } from 'formik';
import classnames from 'classnames';
import * as Yup from 'yup';

import styles from './ClinicPage.module.scss';

import Loading from '../../../components/common/Loading';
import Modal from '../../../components/common/Modal';
import FieldInput from '../../../components/common/fields/FieldInput/FieldInput';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import DoctorLeftNav from '../../../components/nav/DoctorLeftNav';
import SwitchToggle from '../../../components/SwitchToggle/SwitchToggle';

import AuthContext from '../../../context/AuthContext';
import { getClinic, updateClinic } from '../../../api/doctor';

const ClinicSchema = Yup.object().shape({
  clinic_name: Yup.string().required('Field is Required'),
  email: Yup.string().required('Field is Required').email(),
  phone: Yup.string()
    .required('Field is Required')
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Please enter valid phone number',
    ),
  address: Yup.string().required('Field is Required'),
  city: Yup.string().required('Field is Required'),
  state: Yup.string().required('Field is Required'),
  zip: Yup.string().required('Field is Required'),
});

const ClinicPage = () => {
  const { isLoading, authUser } = useContext(AuthContext);

  const [dataLoading, setLoading] = useState(false);
  const isDataLoading = dataLoading || isLoading;
  const [clinicData, setClinicData] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [clinicId, setClinicId] = useState();

  const fetchClinic = async (id) => {
    try {
      setLoading(true);
      const clinicData = await getClinic(id);
      setClinicId(id);
      setClinicData(clinicData.clinic);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (authUser && authUser.clinics && authUser.clinics.id) {
      fetchClinic(authUser.clinics.id);
    }
  }, [authUser]);
  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <DoctorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div className={styles.header}>
              <h1>My Clinic Profile</h1>
            </div>
            <div className={styles.clinicData}>
              <div className={styles.detailsDiv}>
                <h6 className={styles.fieldTitle}>Clinic name</h6>
                <p className={styles.fieldValue}>
                  {clinicData && clinicData.clinic_name}
                </p>
              </div>
              <div className={styles.detailsDiv}>
                <h6 className={styles.fieldTitle}>Email</h6>
                <p className={styles.fieldValue}>
                  {clinicData && clinicData.email}
                </p>
              </div>
              <div className={styles.detailsDiv}>
                <h6 className={styles.fieldTitle}>Contact number</h6>
                <p className={styles.fieldValue}>
                  {clinicData && clinicData.phone}
                </p>
              </div>
              <div className={styles.detailsDiv}>
                <h6 className={styles.fieldTitle}>Address</h6>
                <p className={styles.fieldValue}>
                  {clinicData && clinicData.address}
                </p>
              </div>
              <div className={styles.detailsDiv}>
                <h6 className={styles.fieldTitle}>City</h6>
                <p className={styles.fieldValue}>
                  {clinicData && clinicData.city}
                </p>
              </div>
              <div className={styles.detailsDiv}>
                <h6 className={styles.fieldTitle}>State</h6>
                <p className={styles.fieldValue}>
                  {clinicData && clinicData.state}
                </p>
              </div>
              <div className={styles.detailsDiv}>
                <h6 className={styles.fieldTitle}>Zip</h6>
                <p className={styles.fieldValue}>
                  {clinicData && clinicData.zip}
                </p>
              </div>
              <div className={styles.lastDetailsDiv}>
                <h6 className={styles.fieldTitle}>Description</h6>
                <p className={styles.fieldValue}>
                  {clinicData && clinicData.description}
                </p>
              </div>
              <div className={styles.lastDetailsDiv}>
                <h6 className={styles.fieldTitle}>External matching</h6>
                <p className={styles.fieldValue}>
                  {clinicData && clinicData.external_matching ? 'On' : 'Off'}
                </p>
              </div>
            </div>
            <PrimaryButton
              label="Edit Profile"
              type="button"
              className={styles.editButton}
              onClick={() => {
                setShowEditModal(true);
              }}
            />
          </div>
        </Fragment>
      )}

      {showEditModal && clinicData && (
        <Modal className={styles.EditClinicmodal}>
          <div className={styles.content}>
            <div
              className={styles.closeButton}
              onClick={() => {
                setShowEditModal(false);
              }}
            >
              <img src={require(`../../../img/close.svg`)} alt={`Close`} />
            </div>
            <h1>
              <b>Edit Profile</b>
            </h1>
            <Formik
              initialValues={{
                clinic_name: clinicData.clinic_name,
                address: clinicData.address,
                city: clinicData.city,
                state: clinicData.state,
                zip: clinicData.zip,
                phone: clinicData.phone,
                email: clinicData.email,
                description: clinicData.description,
                external_matching: clinicData.external_matching,
                doctor_id: authUser.id,
              }}
              validationSchema={ClinicSchema}
              onSubmit={async (values) => {
                setLoading(true);
                await updateClinic(clinicId, values);
                fetchClinic(clinicId);
                setLoading(false);
                setShowEditModal(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form>
                    <Grid>
                      <Cell small={12}>
                        <FieldInput
                          autoComplete="clinic_name"
                          autoFocus={true}
                          label="Clinic Name"
                          errorText={touched.clinic_name && errors.clinic_name}
                          name="clinic_name"
                          value={values.clinic_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          data-testid="clinic_name"
                        />
                      </Cell>
                      <Cell small={12}>
                        <FieldInput
                          autoComplete="email"
                          label="Email"
                          errorText={touched.email && errors.email}
                          name="email"
                          onChange={handleChange}
                          type="email"
                          onBlur={handleBlur}
                          value={values.email}
                          data-testid="email"
                        />
                      </Cell>
                      <Cell small={12}>
                        <FieldInput
                          autoComplete="phone"
                          label="Contact Number"
                          errorText={touched.phone && errors.phone}
                          name="phone"
                          onChange={handleChange}
                          value={values.phone}
                          onBlur={handleBlur}
                          data-testid="phone"
                        />
                      </Cell>
                      <Cell small={12}>
                        <FieldInput
                          autoComplete="address"
                          label="Address"
                          errorText={touched.address && errors.address}
                          name="address"
                          onChange={handleChange}
                          value={values.address}
                          onBlur={handleBlur}
                          data-testid="address"
                        />
                      </Cell>
                      <Cell small={12}>
                        <FieldInput
                          autoComplete="city"
                          label="City"
                          errorText={touched.city && errors.city}
                          name="city"
                          onChange={handleChange}
                          value={values.city}
                          onBlur={handleBlur}
                          data-testid="city"
                        />
                      </Cell>
                      <Cell small={12}>
                        <FieldInput
                          label="State"
                          errorText={touched.state && errors.state}
                          name="state"
                          onChange={handleChange}
                          value={values.state}
                          onBlur={handleBlur}
                          data-testid="state"
                        />
                      </Cell>
                      <Cell small={12}>
                        <FieldInput
                          autoComplete="zip"
                          label="Zip"
                          errorText={touched.zip && errors.zip}
                          name="zip"
                          onChange={handleChange}
                          value={values.zip}
                          onBlur={handleBlur}
                          data-testid="zip"
                        />
                      </Cell>
                      <Cell small={12}>
                        <FieldInput
                          autoComplete="description"
                          label="Description"
                          errorText={errors.description}
                          name="description"
                          onChange={handleChange}
                          value={values.description}
                          onBlur={handleBlur}
                          data-testid="description"
                        />
                      </Cell>
                      <Cell>
                        <SwitchToggle
                          dataTestId="ClinicPage.Doctor.InternalMatching"
                          label="EXTERNAL MATCHING"
                          checked={values.external_matching}
                          onChange={handleChange}
                          name="external_matching"
                          value={values.external_matching}
                        />
                      </Cell>

                      <Cell small={12}>
                        <PrimaryButton
                          label="Save"
                          disabled={Object.entries(errors).length > 0}
                        />
                      </Cell>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ClinicPage;
