import React, {
  useState,
  Fragment,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { Formik, Form } from 'formik';

import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import CalendlyEvent from '../../../components/CalendlyEvent';
import MessageModal from '../../../components/MessageModal';
import UserConfirmation from '../../../components/UserConfirmation';
import Loading from '../../../components/common/Loading';
import DoctorLeftNav from '../../../components/nav/DoctorLeftNav';
import BillingPlanFeature from '../../../components/BillingPlanFeature';
import NylasAvailability from '../../../components/NylasAvailability';

import AuthContext from '../../../context/AuthContext';

import { requiredValidator } from '../../../utils/validators';
import useIntl from '../../../hooks/useIntl';
import messages from './messages';

import styles from './AvailabilityPage.module.scss';

import { getBookingTypes, getDoctor } from '../../../api/doctor';
import {
  getClinicianNylasDetail,
  disconnectNylasAccount,
  saveNylasBookingEvents,
  getDoctorNylasEvents,
} from '../../../api/nylas';

import { CALENDAR_TYPES } from '../../../constants';
const { REACT_APP_NYLAS_FEATURE_FLAG } = process.env;

const fieldValidators = ({ apiKey }) => ({
  ...requiredValidator(apiKey, 'apiKey'),
});

const AvailabilityPage = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { isLoading, authUser } = useContext(AuthContext);

  const [dataLoading, setLoading] = useState(false);
  const [isDoctorDataLoading, setIsDoctorDataLoading] = useState(true);
  const [isBookingTypesLoading, setIsBookingTypesLoading] = useState(true);

  const [bookingTypes, setBookingTypes] = useState([]);
  const [doctor, setDoctor] = useState({});

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [displayMessage, setDisplayMessage] = useState({
    title: '',
    message: '',
  });
  const [nylasAccountDetail, setNylasAccountDetail] = useState({});
  const [
    showDisconnectCalendarConfirmation,
    setShowDisconnectCalendarConfirmation,
  ] = useState(false);
  const [showSchedularPage, setShowSchedularPage] = useState(false);
  const [
    showNylasDisconnectRequirementModal,
    setShowNylasDisconnectRequirementModal,
  ] = useState(false);

  const isDataLoading =
    dataLoading || isLoading || isDoctorDataLoading || isBookingTypesLoading;

  const fetchBookingTypes = useCallback(
    async (isFirtsFetch = false) => {
      if (authUser?.id) {
        try {
          setIsBookingTypesLoading(true);

          const bookingType =
            REACT_APP_NYLAS_FEATURE_FLAG === 'true'
              ? CALENDAR_TYPES.NYLAS
              : CALENDAR_TYPES.CALENDLY;

          const { bookingTypes: savedBookingTypes } = await getBookingTypes(
            authUser.id,
            bookingType,
          );

          /**
           *
           * update current booking_types flow - start
           *
           */
          let events = null;
          try {
            events = await getDoctorNylasEvents(authUser.nylas_token);
          } catch (err) {
            /**
             * in this case we have to inform user that he should disconnect from Nylas and connect again
             */
            if (
              err?.config?.url === 'https://api.schedule.nylas.com/manage/pages'
            ) {
              setShowNylasDisconnectRequirementModal(true);
            }

            setIsBookingTypesLoading(false);
            return;
          }

          /**
           * if we can get event then we can get Nylas account details
           */
          const accountDetail = await getClinicianNylasDetail(authUser.id);
          setNylasAccountDetail(accountDetail);

          /**
           * remove from our DB all booking types that have been deleted from Nylas
           */
          const newBookingTypes = [];

          (events || []).forEach((ev) => {
            /**
             * if Nylas event already exists in our system
             */
            const bt = (savedBookingTypes || []).find(
              (bt) => bt.id.toString() === ev.id.toString(),
            );

            if (bt) {
              newBookingTypes.push({
                ...bt,
                calendly_name: ev.name,
                name: ev.config.event.title,
                url: `https://schedule.nylas.com/${ev.slug}`,
                duration: ev.config.event.duration,
              });
            } else {
              newBookingTypes.push({
                id: ev.id.toString(),
                calendly_name: ev.name,
                name: ev.config.event.title,
                url: `https://schedule.nylas.com/${ev.slug}`,
                duration: ev.config.event.duration,
                amount: ev.amount || 0,
                available_for_doctor: false,
                available_for_patient: false,
                calendar_type: CALENDAR_TYPES.NYLAS,
              });
            }
          });

          /**
           * update booking types in our system
           */
          await saveNylasBookingEvents(authUser.id, {
            booking_types: newBookingTypes.map((bt) => ({
              id: bt.id,
              calendar_type: bt.calendar_type,
              calendly_name: bt.calendly_name,
              name: bt.name,
              url: bt.url,
              duration: bt.duration,
              amount: bt.amount,
              available_for_doctor: bt.available_for_doctor,
              available_for_patient: bt.available_for_patient,
            })),
          });
          /**
           * update current booking_types flow - end
           */

          if (newBookingTypes.length) {
            setBookingTypes(
              newBookingTypes.map((bt) => ({
                ...bt,
                visibleToDoctor: bt.available_for_doctor,
                visibleToPatient: bt.available_for_patient,
              })),
            );
          } else {
            /**
             * if there is no booking types open Nylas popup to create new one
             * only after first fetch
             */
            if (isFirtsFetch) {
              setShowSchedularPage(true);
            }
          }

          setIsBookingTypesLoading(false);
        } catch (error) {
          setIsBookingTypesLoading(false);
        }
      }
    },
    [authUser],
  );

  const fetchDoctorData = async () => {
    if (authUser.id) {
      try {
        setIsDoctorDataLoading(true);

        const resp = await getDoctor(authUser.id);
        setDoctor(resp.doctor);

        setIsDoctorDataLoading(false);
      } catch (error) {
        setIsDoctorDataLoading(false);
      }
    }
  };

  useEffect(() => {
    if (authUser?.id) {
      fetchBookingTypes(true);
      fetchDoctorData();
    }
  }, [authUser /*, fetchBookingTypes, fetchDoctorData*/]);

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const obj = {
        booking_types: bookingTypes.map((bt) => ({
          id: bt.id,
          calendly_name: bt.calendly_name,
          name: bt.name,
          url: bt.url,
          duration: bt.duration,
          amount: bt.amount,
          available_for_doctor: bt.visibleToDoctor,
          available_for_patient: bt.visibleToPatient,
          calendar_type: CALENDAR_TYPES.NYLAS,
        })),
      };
      await saveNylasBookingEvents(authUser.id, obj);

      setLoading(false);
      setDisplayMessage({
        type: 'success',
        title: 'Events saved successfully',
        text: '',
      });
      setShowMessageModal(true);
    } catch (e) {
      let errorMsg = 'Please try again later';
      if (e.response && e.response.data && e.response.data.message) {
        errorMsg = e.response.data.message;
      }
      setLoading(false);
      setDisplayMessage({
        type: 'error',
        title: 'Error',
        text: errorMsg,
      });
      setShowMessageModal(true);
    }
  };

  const handleOnChangeEvent = (e, index, type) => {
    if (Math.sign(e.target.value) < 0) {
      return;
    }

    const tempBookingTypes = [...bookingTypes];

    if (type === 'amount') {
      tempBookingTypes[index].amount = e.target.value;
    } else if (type === 'visibleToDoctor') {
      tempBookingTypes[index].visibleToDoctor =
        !tempBookingTypes[index].visibleToDoctor;
    } else if (type === 'visibleToPatient') {
      tempBookingTypes[index].visibleToPatient =
        !tempBookingTypes[index].visibleToPatient;
    }

    setBookingTypes([...tempBookingTypes]);
  };

  const confirmDisconnectCalendar = async () => {
    setShowDisconnectCalendarConfirmation(false);
    setLoading(true);
    try {
      await disconnectNylasAccount();
      setLoading(false);
      setDisplayMessage({
        type: 'success',
        title: '',
        text: 'Nylas account disconnect successfully',
        buttonLabel: 'OK',
        redirectUrl: '/my-account',
      });
      setShowMessageModal(true);
    } catch (e) {
      setLoading(false);
      let errorMsg = 'Something go wrong';
      if (e.response && e.response.data && e.response.data.message) {
        errorMsg = e.response.data.message;
      }
      setLoading(false);
      setDisplayMessage({
        type: 'error',
        title: 'Error',
        text: errorMsg,
      });
      setShowMessageModal(true);
    }
  };

  const handleDisconnectCalendar = () => {
    setShowDisconnectCalendarConfirmation(true);
  };

  const handleCloseNylasAvailabilityEditor = () => {
    setShowSchedularPage(false);
    fetchBookingTypes();
  };

  const handleReconnectToNylas = async () => {
    try {
      setLoading(true);

      await disconnectNylasAccount();

      setShowNylasDisconnectRequirementModal(false);
      setLoading(false);

      history.push('/doctor/my-account');
    } catch (err) {
      setShowNylasDisconnectRequirementModal(false);
      setLoading(false);

      /**
       * if error status code == 404 it means that nylas has noe been connected yet
       */
      if (err?.response?.status === 404) {
        history.push('/doctor/my-account');
      }
    }
  };

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftNav, 'desktop-only')}>
        <DoctorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            ></div>
            <h1 data-testid={`calendly title`}>
              {formatMessage(messages.title)}
            </h1>
            <Fragment>
              <Formik
                initialValues={{
                  apiKey: '',
                }}
                validate={fieldValidators}
                enableReinitialize
                render={({ values, handleChange, errors, setFieldValue }) => {
                  return (
                    <Form noValidate>
                      <>
                        <Fragment>
                          <div className={styles.eventContainer}>
                            <BillingPlanFeature payments>
                              <div className={styles.stepDesc}>
                                {formatMessage(messages.chooseEventType)}
                              </div>
                            </BillingPlanFeature>

                            <div className={styles.stepDesc}>
                              Click on the Save button when you are finished.
                              Click on Dashboard to exit this page.
                            </div>

                            <div className={styles.events}>
                              {bookingTypes.map((bt, index) => (
                                <CalendlyEvent
                                  key={index}
                                  name={bt.name}
                                  duration={bt.duration}
                                  // onClick={(e) => handleCardClick(e, event)}
                                  // isActive={checkIsEventSelected(event.id)}
                                  amount={bt.amount}
                                  visibleToDoctor={bt.visibleToDoctor}
                                  visibleToPatient={bt.visibleToPatient}
                                  onAmountChange={(e) =>
                                    handleOnChangeEvent(e, index, 'amount')
                                  }
                                  onVisibleToDoctorChange={(e) =>
                                    handleOnChangeEvent(
                                      e,
                                      index,
                                      'visibleToDoctor',
                                    )
                                  }
                                  onVisibleToPatientChange={(e) =>
                                    handleOnChangeEvent(
                                      e,
                                      index,
                                      'visibleToPatient',
                                    )
                                  }
                                  index={index}
                                  data-testid={`calendly event input ${bt.name}`}
                                />
                              ))}
                            </div>
                          </div>

                          <div className={styles['actions-container']}>
                            <PrimaryButton
                              disabled={!bookingTypes.length}
                              label={formatMessage(messages.saveBtnLabel)}
                              type="submit"
                              className="font-18"
                              onClick={() => handleSubmit(values)}
                              data-testid={`save button`}
                            />

                            <PrimaryButton
                              label={formatMessage(
                                messages.editAppointmentTypesBtnLabel,
                              )}
                              type="submit"
                              className="font-18"
                              onClick={() => setShowSchedularPage(true)}
                              data-testid={`editAppointmentTypes`}
                            />
                          </div>

                          {nylasAccountDetail.email_address && (
                            <div className={styles.stepDesc}>
                              You are connected to{' '}
                              <b>{nylasAccountDetail.email_address}</b>. Click{' '}
                              <span
                                className={styles.link}
                                onClick={() => handleDisconnectCalendar()}
                              >
                                here
                              </span>{' '}
                              to disconnect.
                            </div>
                          )}
                        </Fragment>
                      </>
                    </Form>
                  );
                }}
              />
            </Fragment>
          </div>
        </Fragment>
      )}

      {showMessageModal && (
        <MessageModal
          message={displayMessage}
          onCloseModal={() => setShowMessageModal(false)}
        />
      )}

      {showDisconnectCalendarConfirmation && (
        <UserConfirmation
          message={`If you disconnect the email account ${nylasAccountDetail.email_address}, you will not be able to reschedule or cancel existing appointments. You will also lose any appointment type settings which you have created.`}
          onCancel={() => {
            setShowDisconnectCalendarConfirmation(false);
          }}
          onConfirm={() => confirmDisconnectCalendar()}
          buttonLabel="Continue"
        />
      )}

      {showSchedularPage && (
        <NylasAvailability
          token={doctor.nylas_token}
          handleCloseEditor={handleCloseNylasAvailabilityEditor}
        />
      )}

      {showNylasDisconnectRequirementModal && (
        <UserConfirmation
          message={'We need to reconnect your calendar account to Nylas'}
          buttonLabel={'Ok'}
          onCancel={() => {
            setShowNylasDisconnectRequirementModal(false);
            history.push('/doctor/my-account');
          }}
          onConfirm={handleReconnectToNylas}
        />
      )}
    </div>
  );
};

export default AvailabilityPage;
