import { doGet, doPost, doPut } from '../fetch';

const createNote = async (body) => {
  const note = await doPost('/b2c/notes', body);
  return note;
};

const getNotes = async (patientId) => {
  const notes = await doGet(`/b2c/notes?patient_id=${patientId}`);
  return notes;
};

const updateNote = async (noteId, body) => {
  const note = await doPut(`/b2c/notes/${noteId}`, body);
  return note;
};

export { createNote, getNotes, updateNote };
