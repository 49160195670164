import axios from 'axios';
import { doPost, doDelete } from '../fetch';

export const getDoctorEvents = async (params) => {
  const { data } = await axios.get(
    'https://calendly.com/api/v1/users/me/event_types',
    params,
  );
  return data;
};

export const connectCalendly = async (id, params) => {
  const data = await doPost(`/b2c/calendly/${id}`, params);
  return data;
};

export const disconnectCalendly = async (id) => {
  const data = await doDelete(`/b2c/calendly/${id}`);
  return data;
};
