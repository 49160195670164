import React from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

// TODO: temporarily not localized as it will move to Contentful
const defaultMarks = [
  {
    value: 1,
    label: 'Poor',
  },
  {
    value: 2,
    label: 'Fair',
  },
  {
    value: 3,
    label: 'Good',
  },
  {
    value: 4,
    label: 'Very Good',
  },
  {
    value: 5,
    label: 'Excellent',
  },
];

const severityMarks = [
  {
    value: 1,
    label: 'Very Severe',
  },
  {
    value: 2,
    label: 'Severe',
  },
  {
    value: 3,
    label: 'Moderate',
  },
  {
    value: 4,
    label: 'Mild',
  },
  {
    value: 5,
    label: 'None',
  },
];

const valueText = (marks, value) => {
  const mark = marks.filter((m) => m.value === value);
  return mark[0].label;
};

const styles = (theme) => ({
  root: {
    color: 'black',
  },
  rail: {
    height: '3px',
    opacity: 1,
  },
  thumb: {
    width: '24px',
    height: '24px',
    marginTop: '-10px',
    marginLeft: '-12px',
  },
  mark: {
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
  markLabel: {
    top: '30px',
    'font-size': '1rem',
    [theme.breakpoints.down('md')]: {
      top: '40px',
      width: '5rem',
      overflow: 'hidden',
      'white-space': 'initial',
      'text-overflow': 'ellipsis',
      'font-size': '0.8rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '4rem',
      'white-space': 'normal',
    },
  },
});

const StyledSlider = withStyles(styles)(Slider);

const CustomSlider = ({ onChange, defaultValue, value, type }) => {
  const marks = type === 'severity' ? severityMarks : defaultMarks;
  return (
    <StyledSlider
      aria-labelledby="discrete-slider"
      defaultValue={defaultValue || 3}
      getAriaValueText={(value) => valueText(marks, value)}
      min={1}
      max={5}
      marks={marks}
      step={null}
      track={false}
      onChange={onChange}
      value={value}
    ></StyledSlider>
  );
};

CustomSlider.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(['default', 'severity']),
};

CustomSlider.defaultProps = {
  type: 'default',
};

export default CustomSlider;
