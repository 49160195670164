import React, { useContext } from 'react';
import { Route, useParams } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import Logo from '../Logo';
import DynamicClinicText from '../DynamicClinicText';

import styles from './Approval.module.scss';
import ApprovalImg from '../../img/approval.svg';
import Back from '../../img/icons/back.svg';

import { logout } from '../../api/auth';

import AuthContext from '../../context/AuthContext';
import WhitelabelContext from '../../context/WhitelabelContext';

const Approval = () => {
  const param = useParams();
  const { authState, oktaAuth } = useOktaAuth();

  const { dispatch } = useContext(AuthContext);
  const { whitelabel } = useContext(WhitelabelContext);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.backDiv}>
          <img src={Back} alt="Back" />
          <Route
            render={({ history }) => (
              <span
                onClick={() => {
                  if (authState.isAuthenticated) {
                    oktaAuth.signOut({
                      postLogoutRedirectUri: window.location.origin,
                    });
                  }
                  logout(dispatch);
                  history.push('/');
                }}
              >
                Back
              </span>
            )}
          />
        </div>
        <Logo />
        <div className={styles.warningImg}>
          <img src={ApprovalImg} alt="Success" />
        </div>
        <h4 className={styles.confirmationTitle}>Waiting for approval</h4>
        <br />
        <p className={styles.text}>
          {param.clinic} still needs to approve your account.
        </p>
        <br />
        <p className={styles.text}>
          Please contact{' '}
          {whitelabel && whitelabel.subdomain ? (
            <b>hello@{whitelabel.subdomain}</b>
          ) : (
            <b>hello@evehealthsystems.com</b>
          )}{' '}
          if you are experiencing difficulties using an invitation, or would
          like to learn more about <DynamicClinicText />
        </p>
      </div>
    </div>
  );
};

export default Approval;
