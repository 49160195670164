import React, { useState, Fragment } from 'react';
import { Form, Formik } from 'formik';
import { Cell } from 'react-foundation';
import * as Yup from 'yup';
import classnames from 'classnames';
import styles from './InviteResearcherPage.module.scss';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import { inviteResearcher } from '../../../api/researcher';
import AdminLeftNav from '../../../components/nav/AdminLeftNav';
import Loading from '../../../components/common/Loading';
import FieldInput from '../../../components/common/fields/FieldInput/FieldInput';
import MessageModal from '../../../components/MessageModal';

const ClinicSchema = Yup.object().shape({
  firstName: Yup.string().required('Field Required'),
  email: Yup.string()
    .nullable()
    .email('Invalid email address')
    .required('Field is Required'),
});
const InviteResearcherPage = () => {
  const [error, setError] = useState('');
  const [dataLoading, setLoading] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [displayMessage, setDisplayMessage] = useState({
    title: '',
    message: '',
  });

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const { email, firstName } = values;
      await inviteResearcher({
        email,
        firstName,
        invitee_id: 0,
        invited_status: 'pending',
        lab_id: 0,
        isOwner: false,
      });
      setLoading(false);
      setDisplayMessage({
        type: 'success',
        title: 'Invitation sent successfully',
        text: '',
      });
      setShowMessageModal(true);
    } catch (error) {
      let errorMsg = '';
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMsg = error.response.data.message;
      }
      setError(errorMsg);
      setLoading(false);
      setDisplayMessage({
        type: 'error',
        title: '',
        text: errorMsg,
      });
      setShowMessageModal(true);
      throw error;
    }
  };

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftNav, 'desktop-only')}>
        <AdminLeftNav />
      </div>
      {dataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.lineArt}>
            <img src={require(`../../../img/LineArt.svg`)} alt="line-art" />
          </div>
          <div className={styles.contentDiv}>
            <div
              medium={3}
              className={classnames(styles.topNav, 'mobile-only')}
            ></div>

            <Formik
              initialValues={{ email: '', firstName: '' }}
              validationSchema={ClinicSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({
                errors,
                handleChange,
                values,
                touched,
                handleBlur,
                setFieldValue,
                isValid,
              }) => {
                return (
                  <Form className={styles['content']}>
                    <div className={classnames(styles[`form-div`])}>
                      <h2 data-testid="admin invite_researcher header">
                        Invite Researcher
                      </h2>
                      <div
                        className={styles['sub-text']}
                        data-testid="admin invite_researcher text"
                      >
                        Invite a Researcher To use Eve Health Systems Platform
                      </div>
                      <div className={styles.formInput}>
                        <Cell small={12} medium={12}>
                          <FieldInput
                            label="First Name"
                            className={styles.input}
                            name="firstName"
                            errorText={touched.firstName && errors.firstName}
                            onChange={(e) => {
                              setFieldValue('firstName', e.target.value);
                            }}
                            onBlur={handleBlur}
                            value={values.firstName}
                            placeholder="First Name"
                            data-testid="invite_researcher first_name"
                            label-testid="invite_researcher first_name label"
                            error-testid="invite_researcher first_name error"
                          />
                        </Cell>
                        <Cell small={12} medium={12}>
                          <FieldInput
                            label="email"
                            className={styles.input}
                            name="email"
                            errorText={touched.email && errors.email}
                            onChange={(e) => {
                              setFieldValue('email', e.target.value);
                            }}
                            onBlur={handleBlur}
                            value={values.email}
                            placeholder="researcher's Email Address"
                            data-testid="invite_researcher email"
                            label-testid="invite_researcher email label"
                            error-testid="invite_researcher email error"
                          />
                        </Cell>
                      </div>
                      {error && (
                        <div
                          className={styles.error}
                          data-testid="admin invite_researcher error"
                        >
                          {error}
                        </div>
                      )}
                      <div className={classnames(styles[`button-row`])}>
                        <PrimaryButton
                          label="Invite"
                          className={classnames(styles[`button`])}
                          disabled={!isValid}
                          data-testid="admin invite_researcher invite button"
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Fragment>
      )}
      {showMessageModal && (
        <MessageModal
          message={displayMessage}
          onCloseModal={() => setShowMessageModal(false)}
        />
      )}
    </div>
  );
};

export default InviteResearcherPage;
