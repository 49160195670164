import { defineMessages } from 'react-intl';

export default defineMessages({
  continueBtn: {
    id: 'pages.discoverPage.continueBtn',
    description: 'Label for continue button',
    defaultMessage: 'Continue',
  },
  backLabel: {
    id: 'pages.discoverPage.backLabel',
    description: 'Label for back action',
    defaultMessage: 'Back',
  },
  needImprovementLabel: {
    id: 'pages.discoverPage.needImprovementLabel',
    description: 'Label for Need Improvement',
    defaultMessage: 'Needs Improvement',
  },
  okLabel: {
    id: 'pages.discoverPage.okLabel',
    description: 'Label for Ok',
    defaultMessage: 'OK',
  },
  noNeedImprovementLabel: {
    id: 'pages.discoverPage.noNeedImprovementLabel',
    description: 'Label for No Need Improvement',
    defaultMessage: 'No Needed Improvement',
  },
  welcomeBackText: {
    id: 'pages.discoverPage.welcomeBackText',
    description: 'Welcome back text section',
    defaultMessage:
      'Welcome back, {name}!\n\nLet’s reset your recommendation & wellness profile.',
  },
  metaDescription: {
    id: 'pages.discoverPage.metaDescription',
    description: 'Meta description for discover page',
    defaultMessage:
      'Let ORO curate premium CBD products targeted to your specific needs.',
  },
});
