import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Grid, Cell } from 'react-foundation';
import ExperienceOneImg from '../../../../img/icons/Experience/Experience-01.png';
import ExperienceTwoImg from '../../../../img/icons/Experience/Experience-02.png';
import ExperienceThreeImg from '../../../../img/icons/Experience/Experience-03.png';
import FamiliarOneImg from '../../../../img/icons/Familiar/Familiar-175x175-01.png';
import FamiliarTwoImg from '../../../../img/icons/Familiar/Familiar-175x175-02.png';
import FamiliarThreeImg from '../../../../img/icons/Familiar/Familiar-175x175-03.png';
import styles from './CircleButton.module.scss';

const selectIcon = (icon) => {
  switch (icon) {
    case 'no-experience':
      return ExperienceOneImg;
    case 'some-experience':
      return ExperienceTwoImg;
    case 'deep-experience':
      return ExperienceThreeImg;
    case 'pretty-informed':
      return FamiliarOneImg;
    case 'sort-of-curious':
      return FamiliarTwoImg;
    case 'somewhat-skeptical':
      return FamiliarThreeImg;
    default:
      throw new Error(`${icon} icon does not exist.`);
  }
};

const CircleButton = ({
  title,
  subtitle,
  onClick,
  isSelected,
  icon,
  'data-testid': datatestid,
}) => (
  <button
    type="submit"
    onClick={onClick}
    className={classnames(styles['button-container'], {
      [styles['is-selected']]: isSelected,
    })}
    data-testid={datatestid}
  >
    <Grid>
      <Cell small={3} medium={12}>
        <img src={selectIcon(icon)} alt={icon} />
      </Cell>
      <Cell small={9} medium={12} className={styles['text-container']}>
        <div className={styles['button-title']}>{title}</div>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </Cell>
    </Grid>
  </button>
);

CircleButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  isSelected: PropTypes.bool,
  icon: PropTypes.string.isRequired,
};

CircleButton.defaultProps = {
  isSelected: false,
};

export default CircleButton;
