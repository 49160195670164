import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'footer.title',
    description: 'The footer title',
    defaultMessage: 'Modern science meets ancient wellness.',
  },
  shop: {
    id: 'footer.links.shop',
    description: 'Shop Link',
    defaultMessage: 'Shop',
  },
  follow: {
    id: 'footer.links.follow',
    description: 'Follow title',
    defaultMessage: 'Follow Vail Valley Tech',
  },
  copyright: {
    id: 'footer.links.copyright',
    description: 'Copyright message',
    defaultMessage: '© {year} Evehealthsystems.com All Rights Reserved.',
  },
  terms: {
    id: 'footer.links.terms',
    description: 'Terms of use message',
    defaultMessage: 'Terms & Conditions',
  },
  privacy: {
    id: 'footer.links.privacy',
    description: 'Privacy Message',
    defaultMessage: 'Privacy Policy',
  },
  contact: {
    id: 'footer.links.contact',
    description: 'Contact Us link',
    defaultMessage: 'Contact Us',
  },
});
