import React, {
  useState,
  Fragment,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import AuthContext from '../../context/AuthContext';
import LeftNav from '../../components/nav/LeftNav';
import styles from './WellnessPage.module.scss';
import classnames from 'classnames';
import { Avatar } from '@material-ui/core';
import classNames from 'classnames';
import { Donut } from '../../components/Donut/Donut';
import moment from 'moment';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import SecondaryButton from '../../components/common/buttons/SecondaryButton';
import { useHistory } from 'react-router-dom';

import { getWellnessData } from '../../api/wellness';

const EmptyCard = ({ attributeIconUrl, displayName }) => (
  <div>
    <div>
      <Avatar
        aria-label={displayName}
        alt={displayName.slice(0, 1)}
        src={attributeIconUrl}
        className={styles.statsCardAttributeIcon}
      />
      <span>{displayName}</span>
    </div>
  </div>
);

const StatCard = ({
  followupAssessment,
  attributeIconUrl,
  displayName,
  alertIconURL,
  alertMessage,
  headline,
  paragraph,
  ratio,
  percentage,
  colors,
  qualitatively,
  gotoAssessment,
  alertMessageColor,
}) => {
  return (
    <div>
      <div className={styles.statsCardHeader}>
        <div>
          <Avatar
            aria-label={displayName}
            alt={displayName.slice(0, 1)}
            src={attributeIconUrl}
            className={styles.statsCardAttributeIcon}
          />
          <div className={styles.statsCardHeaderDisplay}>{displayName}</div>
        </div>
        {ratio && (
          <div>
            <Avatar
              aria-label={alertMessage}
              alt={alertMessage?.slice(0, 1)}
              src={alertIconURL}
              className={styles.statsCardAttributeIcon}
            />
            <div
              className={classNames(styles.statsCardHeaderAlertMessage)}
              style={{ color: alertMessageColor }}
            >
              {alertMessage}
            </div>
          </div>
        )}
        {/* TODO add this once view details card functionality is here */}
        {/*<div className={styles.statsCardHeaderViewDetails}>
          {`View Details `}
          <img
            src={rightArrow}
            alt="view"
            className={styles.statsCardHeaderViewDetailsArrow}
          />
        </div>*/}
      </div>
      <div className={styles.statsCardContent}>
        <div className={styles.statsCardContentChart}>
          <Donut filledPercentage={percentage || 0} colors={colors} />
        </div>
        <div className={styles.statsCardContentTexts}>
          <div className={styles.statsCardContentHeadline}>{headline}</div>
          <div className={styles.statsCardContentParagraph}>{paragraph}</div>
        </div>
      </div>
      {((percentage <= 25 && qualitatively === 'GOOD') ||
        (percentage >= 75 && qualitatively === 'BAD')) && (
        <div className={styles.takeAssessmentBtn}>
          <PrimaryButton
            label={`Take Assessment`}
            className={styles.button}
            onClick={() => gotoAssessment(followupAssessment)}
          />
        </div>
      )}
    </div>
  );
};

const WellnessPage = () => {
  const { authUser } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [emptyData, setEmptyData] = useState([]);
  const [statDate, setStatDate] = useState('');

  const history = useHistory();

  // Updated the code to remove the warning of ES-LINT: hooks/exhaustive-deps
  const fetchWellnessData = useCallback(async () => {
    const order = ['life', 'happiness', 'pain', 'sleep'];

    if (authUser.id) {
      const { data: wellnessData, empty } = await getWellnessData();
      if (!!empty) {
        setEmptyData(
          order
            .map((i) => wellnessData.find((j) => j.key === i))
            .filter((i) => i),
        );
        setStatDate('No data available');
      } else {
        setData(
          order
            .map((i) => wellnessData.find((j) => j.key === i))
            .filter((i) => i),
        );
        wellnessData &&
          wellnessData.length > 0 &&
          setStatDate(
            `As of ${moment(wellnessData[0].updatedAt).format('MMM DD, YYYY')}`,
          );
      }
    }
  }, [authUser]);

  useEffect(() => {
    fetchWellnessData();
  }, [fetchWellnessData]);

  const gotoAssessment = (followupAssessment) => {
    history.push({
      pathname: `/my-account/assesment/${
        followupAssessment ? followupAssessment : 'eve_weekly_update'
      }`,
      state: { from: 'wellness' },
    });
  };
  const onBack = () => history.push('/my-account');

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftNav, 'desktop-only')}>
        <LeftNav />
      </div>
      <Fragment>
        <div className={styles.content}>
          <div className={styles.title} data-testid="wellness dashboard title">
            Your Wellness Dashboard
          </div>
          <div
            className={styles.subTitle}
            data-testid="wellness dashboard subtitle"
          >
            <div>
              {statDate && (
                <>
                  <span className={styles.subTitleLabel}>Eve Stats</span>
                  <span
                    className={styles.subTitleValue}
                    data-testid="wellness dashboard subtitle value"
                  >
                    {statDate}
                  </span>
                </>
              )}
            </div>
            {(!emptyData || emptyData.length > 0) && (
              <PrimaryButton
                label={`Complete Assessment Now`}
                onClick={() => gotoAssessment()}
              />
            )}
          </div>
          <div className={styles.statsCardContainer}>
            {data &&
              data.length > 0 &&
              data.map((card, i) => (
                <div className={styles.statsCard} key={i}>
                  <StatCard
                    followupAssessment={card.followup_asseement}
                    attributeIconUrl={card.attributeIconUrl}
                    displayName={card.displayName}
                    alertIconURL={card.alertIconURL}
                    alertMessage={card.alertMessage}
                    headline={card.headline}
                    paragraph={card.paragraph}
                    ratio={card.wow_ratio}
                    percentage={card.percentage}
                    colors={card.chartColors}
                    qualitatively={card.qualitatively}
                    gotoAssessment={gotoAssessment}
                    alertMessageColor={card.alertMessageColor}
                  />
                </div>
              ))}
            {(!emptyData || emptyData.length > 0) && (
              <>
                {emptyData.map((card, i) => (
                  <div className={styles.statsCardEmpty} key={i}>
                    <EmptyCard
                      key={i}
                      attributeIconUrl={card.attributeIconUrl}
                      displayName={card.displayName}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
          {(!emptyData || emptyData.length > 0) && (
            <div className={styles.backBtn}>
              <SecondaryButton label={`Back`} onClick={onBack} />
            </div>
          )}
        </div>
      </Fragment>
    </div>
  );
};

export default WellnessPage;
