import React, { useState } from 'react';
import { Cell, Grid } from 'react-foundation';
import moment from 'moment';
import classnames from 'classnames';
import useIntl from '../../hooks/useIntl';
import styles from './FormsList.module.scss';
import messages from './messages';
import Modal from '../common/Modal';
import PrimaryButton from '../common/buttons/PrimaryButton';
import SecondaryButton from '../common/buttons/SecondaryButton';
import Tick from '../../img/icons/appointment-booked.svg';
import Iframe from 'react-iframe';
import Signature from '../Hellosign';
import { useHistory } from 'react-router-dom';
import Loading from '../common/Loading';

import {
  getIntakeConsentForm,
  updateIntakeForm2,
  getForms,
} from '../../api/intakeForm';

const FormsList = ({
  title,
  form,
  onEdit,
  onFillInForm,
  isView,
  onResetForm,
  patientForm,
  isAssessment,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const [showProfileModal, setShowProfileModal] = useState(false);
  const [formDetail, setFormDetail] = useState(false);
  const [showResetFormConfirmation, setShowResetFormConfirmation] =
    useState(false);
  const [showAssessmentModal, setShowAssessmentModal] = useState(false);
  const [showConsentModal, setshowConsentModal] = useState(false);
  const [showSignature] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [getConsentFormURl, setGetConsentFormURl] = useState('');

  const handleView = (form, isAssessment) => {
    if (isAssessment) {
      setShowAssessmentModal(true);
    } else {
      setShowProfileModal(true);
    }

    setFormDetail(form);
  };
  const handleViewConsent = async (page) => {
    if (form.link_to_signed_pdf === null) {
      if (form.request_id) {
        setIsLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 9000));
        const consentFormUrl = await getIntakeConsentForm({
          requestId: form.request_id,
        });
        await updateIntakeForm2(form.id, {
          patient_id: form.patient_id,
          doctor_id: form.doctor_id,
          link_to_signed_pdf: consentFormUrl.documentUrl,
        });
        setIsLoading(false);
      }
      if (page === 'assessment') {
        patientForm();
        const result = await getForms(form.patient_id);
        setGetConsentFormURl(result.forms.intakeForm2.link_to_signed_pdf);
        if (result) setshowConsentModal(true);
      }
    }
    if (title && page === 'IntakeFormPage') {
      history.push(`/my-account/form/consentform/${form.id}`);
    }
  };

  const onClickReset = (title) => {
    onResetForm(title, form);
    setShowResetFormConfirmation(false);
  };
  return (
    <Grid className={styles.container}>
      {isLoading && <Loading />}
      <Cell small={12}>
        <div className={styles.content}>
          <div className={styles.bgImg}>
            <img
              alt=""
              src={require(`../../img/icons/document-icon.svg`)}
              data-testid="document icon"
            />
          </div>
          <div className={styles.title} data-testid="from matching assessment">
            {title}
          </div>
          <div className={styles.rightContent}>
            {form.id && !isView && (
              <>
                <img
                  className={styles.tick}
                  src={Tick}
                  alt="true"
                  data-testid="tick icon"
                />{' '}
                <span data-testid="from matching assessment status">
                  completed
                </span>
                <span data-testid="from matching assessment date">
                  on {moment(form.updatedAt).format('LLL')}
                </span>
              </>
            )}
            <div className={styles.buttons}>
              {!form.id && !isView && (
                <>
                  <PrimaryButton
                    label="Fill Out"
                    type="button"
                    className={styles.btn}
                    onClick={() => onFillInForm(title, form)}
                    data-testid="matching assement fill out button"
                  />
                </>
              )}
              {form.id && !isView && (
                <>
                  <SecondaryButton
                    label="Reset"
                    type="button"
                    className={styles.btn}
                    onClick={() => onClickReset(title)}
                    data-testid="matching assement reset button"
                  />
                  {form && form.request_id ? (
                    <PrimaryButton
                      label="View"
                      type="button"
                      className={styles.btn}
                      onClick={() => handleViewConsent('IntakeFormPage')}
                      data-testid="matching assement view button"
                    />
                  ) : (
                    <PrimaryButton
                      label="View"
                      type="button"
                      className={styles.btn}
                      onClick={() => {
                        if (title === 'Intake Form' || isAssessment) {
                          handleView(form, isAssessment);
                        }
                      }}
                      data-testid="matching assement view button"
                    />
                  )}
                </>
              )}
              {isView && (
                <>
                  <PrimaryButton
                    label="View It"
                    type="button"
                    className={styles.btn}
                    data-testid="matching assement viewit button"
                    onClick={() => {
                      if (title === 'Intake Form' || isAssessment) {
                        handleView(form, isAssessment);
                      }
                      if (title === 'Informed Consent Form') {
                        if (form && form.link_to_signed_pdf === null) {
                          handleViewConsent('assessment');
                        } else {
                          setGetConsentFormURl(form.link_to_signed_pdf);
                          setshowConsentModal(true);
                        }
                      }
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {showResetFormConfirmation && (
          <Modal className={styles.resetmodal}>
            <div className={styles.content}>
              <h1>
                <b>{formatMessage(messages.resetModalTitle)}</b>
              </h1>
              <p>{formatMessage(messages.resetModalText)}</p>
              <PrimaryButton
                label={formatMessage(messages.resetModalButtonPrimary)}
                className={styles.primaryButton}
                onClick={() => onClickReset(title)}
              />
              <SecondaryButton
                label={formatMessage(messages.resetModalButtonSecondary)}
                className={styles.secondaryButton}
                onClick={async () => setShowResetFormConfirmation(false)}
              />
            </div>
          </Modal>
        )}
        {showProfileModal && (
          <Modal className={styles.modal}>
            <div className={styles.content}>
              <div
                className={styles.closeButton}
                onClick={() => setShowProfileModal(false)}
              >
                <img src={require(`../../img/close.svg`)} alt={`Close`} />
              </div>
              <h1>
                <b>{title}</b>
              </h1>
              <div className={styles.box}>
                <div className={styles['content-detail']}>
                  {form.id ? (
                    <>
                      <h2>Personal Details</h2>
                      <div className={styles.text}>
                        <span>Name:</span>
                        <p>
                          {formDetail.personalDetails &&
                            formDetail.personalDetails.name}
                        </p>
                      </div>
                      <div className={styles.text}>
                        <span>Address:</span>
                        <p>
                          {formDetail.personalDetails &&
                            formDetail.personalDetails.address}
                        </p>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>City:</span>
                          <p>
                            {formDetail.personalDetails &&
                              formDetail.personalDetails.city}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>State:</span>
                          <p>
                            {formDetail.personalDetails &&
                              formDetail.personalDetails.state}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Zip/Postal Code:</span>
                          <p>
                            {formDetail.personalDetails &&
                              formDetail.personalDetails.postalCode}
                          </p>
                        </div>
                      </div>
                      <h2>Contact Details</h2>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>Home Phone:</span>
                          <p>
                            {formDetail.contactDetails &&
                              formDetail.contactDetails.homePhone}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Cell Phone:</span>
                          <p>
                            {formDetail.contactDetails &&
                              formDetail.contactDetails.cellPhone}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Work Phone:</span>
                          <p>
                            {formDetail.contactDetails &&
                              formDetail.contactDetails.workPhone}
                          </p>
                        </div>
                      </div>
                      <div className={styles.text}>
                        <span>Email:</span>
                        <p>
                          {formDetail.contactDetails &&
                            formDetail.contactDetails.email}
                        </p>
                      </div>
                      <h2>Other Details</h2>
                      <div className={styles.text}>
                        <span>SSH#</span>
                        <p>
                          {formDetail.otherDetails &&
                            formDetail.otherDetails.ssid}
                        </p>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>Age</span>
                          <p>
                            {formDetail.otherDetails &&
                              formDetail.otherDetails.age}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>BirthDate</span>
                          <p>
                            {formDetail.otherDetails &&
                              formDetail.otherDetails.birthDate}
                          </p>
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>Your Gender</span>
                          <p>
                            {formDetail.otherDetails &&
                              formDetail.otherDetails.gender}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Do you identify as transgender?</span>
                          <p>
                            {formDetail.otherDetails &&
                            !!formDetail.otherDetails.isTransgender
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>Education</span>
                          <p>
                            {formDetail.otherDetails &&
                              formDetail.otherDetails.education}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Current Occupation</span>
                          <p>
                            {formDetail.otherDetails &&
                              formDetail.otherDetails.currentOccupation}
                          </p>
                        </div>
                      </div>
                      <h2>Medical Emergency Contact Details</h2>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>Name</span>
                          <p>
                            {formDetail.medicalEmergency &&
                              formDetail.medicalEmergency.name}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Address</span>
                          <p>
                            {formDetail.medicalEmergency &&
                              formDetail.medicalEmergency.address}
                          </p>
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>Relation</span>
                          <p>
                            {formDetail.medicalEmergency &&
                              formDetail.medicalEmergency.relation}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Phone</span>
                          <p>
                            {formDetail.medicalEmergency &&
                              formDetail.medicalEmergency.phone}
                          </p>
                        </div>
                      </div>
                      <h2>Family Doctor Details</h2>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>Name</span>
                          <p>
                            {formDetail.familyDoctor &&
                              formDetail.familyDoctor.name}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Phone:</span>
                          <p>
                            {formDetail.familyDoctor &&
                              formDetail.familyDoctor.phone}
                          </p>
                        </div>
                      </div>
                      <h2>Family Details</h2>
                      <div className={styles.text}>
                        <span>RelationShip Status</span>
                        <p>
                          {formDetail.contactDetails &&
                            formDetail.contactDetails.relationshipStatus}
                        </p>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>Spouse Name</span>
                          <p>
                            {formDetail.familyDetails &&
                              formDetail.familyDetails.spouseName}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Spouse Phone:</span>
                          <p>
                            {formDetail.familyDetails &&
                              formDetail.familyDetails.spousePhone}
                          </p>
                        </div>
                      </div>
                      <h2>Primary Insurance Details</h2>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>Company Name</span>
                          <p>
                            {formDetail.insuranceDetails &&
                              formDetail.insuranceDetails.companyName}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Ins ID#</span>
                          <p>
                            {formDetail.insuranceDetails &&
                              formDetail.insuranceDetails.insuranceId}
                          </p>
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>Group Name</span>
                          <p>
                            {formDetail.insuranceDetails &&
                              formDetail.insuranceDetails.groupName}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Group ID#</span>
                          <p>
                            {formDetail.insuranceDetails &&
                              formDetail.insuranceDetails.groupId}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Employer</span>
                          <p>
                            {formDetail.insuranceDetails &&
                              formDetail.insuranceDetails.employer}
                          </p>
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>Subscriber's Name</span>
                          <p>
                            {formDetail.insuranceDetails &&
                              formDetail.insuranceDetails.subscriberName}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Relation</span>
                          <p>
                            {formDetail.insuranceDetails &&
                              formDetail.insuranceDetails.subscriberRelation}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Subscriber's DOB</span>
                          <p>
                            {formDetail.insuranceDetails &&
                              formDetail.insuranceDetails.subscriberDOB}
                          </p>
                        </div>
                      </div>
                      <h2>Medical History</h2>
                      <div className={styles.text}>
                        <span>Allergies</span>
                        <p>
                          {formDetail.allergies &&
                            formDetail.allergies.toString()}
                        </p>
                      </div>
                      {formDetail.currentMedications &&
                        formDetail.currentMedications.length > 0 && (
                          <>
                            <h2>Current Medications</h2>
                            <div className={styles.detail}>
                              <div className={classnames(styles[`table`])}>
                                <div className={classnames(styles[`table-tr`])}>
                                  <div className={classnames('text')}>Dose</div>
                                  <div className={classnames('text')}>
                                    frequency
                                  </div>
                                  <div className={classnames('text')}>
                                    DateUsed
                                  </div>
                                </div>
                                {formDetail.currentMedications.map(
                                  (item, index) => (
                                    <div
                                      key={index}
                                      className={classnames(styles[`table-tr`])}
                                    >
                                      <p>{item.dose}</p>
                                      <p>{item.frequency}</p>
                                      <p>{item.dateUsed}</p>
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          </>
                        )}

                      {formDetail.recreationalDrugs &&
                        formDetail.recreationalDrugs.length > 0 && (
                          <>
                            <h2>Recreational Drugs</h2>
                            <div className={styles.detail}>
                              <div className={classnames(styles[`table`])}>
                                <div className={classnames(styles[`table-tr`])}>
                                  <div className={classnames('text')}>type</div>
                                  <div className={classnames('text')}>dose</div>
                                  <div className={classnames('text')}>
                                    frequency
                                  </div>
                                  <div className={classnames('text')}>
                                    DateUsed
                                  </div>
                                </div>
                                {formDetail.recreationalDrugs.map(
                                  (item, index) => (
                                    <div
                                      key={index}
                                      className={classnames(styles[`table-tr`])}
                                    >
                                      <p>{item.method}</p>
                                      <p>{item.frequency}</p>
                                      <p>{item.experience}</p>
                                      <p>{item.dateUsed}</p>
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      {formDetail.medicalIllness &&
                        formDetail.medicalIllness.length > 0 && (
                          <>
                            <h2>Medical Illness</h2>
                            <div className={styles.detail}>
                              <div className={classnames(styles[`table`])}>
                                <div className={classnames(styles[`table-tr`])}>
                                  <div
                                    className={classnames(
                                      'text',
                                      styles.column3,
                                    )}
                                  >
                                    illness
                                  </div>
                                  <div
                                    className={classnames(
                                      'text',
                                      styles.column3,
                                    )}
                                  >
                                    status
                                  </div>
                                </div>
                                {formDetail.medicalIllness.map(
                                  (item, index) => (
                                    <div
                                      key={index}
                                      className={classnames(styles[`table-tr`])}
                                    >
                                      <p>{item.illness}</p>
                                      <p>{item.status}</p>
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      <div className={styles.text}>
                        <span>
                          Previously psychological care or counseling?
                        </span>
                        <p>
                          {formDetail.hasPreviousPsychologicalCare === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                      {formDetail.psychologicalCare &&
                        formDetail.psychologicalCare.length > 0 && (
                          <>
                            <h2>Psychological Care</h2>
                            <div className={styles.detail}>
                              <div className={classnames(styles[`table`])}>
                                <div className={classnames(styles[`table-tr`])}>
                                  <div className={classnames('text')}>
                                    clinicians
                                  </div>
                                  <div className={classnames('text')}>
                                    difficulty
                                  </div>
                                  <div className={classnames('text')}>date</div>
                                </div>
                                {formDetail.psychologicalCare.map(
                                  (item, index) => (
                                    <div
                                      key={index}
                                      className={classnames(styles[`table-tr`])}
                                    >
                                      <p>{item.clinicians}</p>
                                      <p>{item.difficulty}</p>
                                      <p>{item.date}</p>
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      <div className={styles.text}>
                        <span>
                          Have you been prescribed psychiatric medications?
                        </span>
                        <p>
                          {formDetail.hasPsychiatricMedications === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                      {formDetail.psychiatricMedications &&
                        formDetail.psychiatricMedications.length > 0 && (
                          <>
                            <h2>Psychiatric Medications</h2>
                            <div className={styles.detail}>
                              <div className={classnames(styles[`table`])}>
                                <div className={classnames(styles[`table-tr`])}>
                                  <div className={classnames('text')}>
                                    medicine
                                  </div>
                                  <div className={classnames('text')}>dose</div>
                                  <div className={classnames('text')}>
                                    effects
                                  </div>
                                </div>
                                {formDetail.psychiatricMedications.map(
                                  (item, index) => (
                                    <div
                                      key={index}
                                      className={classnames(styles[`table-tr`])}
                                    >
                                      <p>{item.medicine}</p>
                                      <p>{item.dose}</p>
                                      <p>{item.effects}</p>
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      <div className={styles.text}>
                        <span>
                          Have you ever been hospitalized for a psychological
                          difficulty?
                        </span>
                        <p>
                          {formDetail.hasHospitalized === true ? 'Yes' : 'No'}
                        </p>
                      </div>
                      <div className={styles.text}>
                        <span>
                          Have you ever had feelings or thoughts that you didn’t
                          want to live?
                        </span>
                        <p>
                          {formDetail.hasSuicidalThoughts === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                      <div className={styles.text}>
                        <span>sleeping habits</span>
                        <p>{formDetail.sleepingHabits}</p>
                      </div>
                      <div className={styles.text}>
                        <span>Do you have difficulty falling asleep?</span>
                        <p>
                          {formDetail.difficultyFallingAsleep === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                      <div className={styles.text}>
                        <span>Do you have awakenings during the night?</span>
                        <p>
                          {formDetail.awakeningDuringNight === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                      <div className={styles.text}>
                        <span>Unrefreshing Sleep</span>
                        <p>{formDetail.poorSleep === true ? 'Yes' : 'No'}</p>
                      </div>
                      <div className={styles.text}>
                        <span>
                          how long have you been experiencing Poor Sleep
                          Problems?
                        </span>
                        <p>{formDetail.sleepingProblemSince}</p>
                      </div>
                      <div className={styles.text}>
                        <span>Weight</span>
                        <p>{formDetail.weight}</p>
                      </div>
                      <div className={styles.text}>
                        <span>Height</span>
                        <p>{formDetail.height}</p>
                      </div>
                      <div className={styles.text}>
                        <span>Happy with your Weight</span>
                        <p>
                          {formDetail.isHappyWithWeight === true ? 'Yes' : 'No'}
                        </p>
                      </div>
                      <div className={styles.text}>
                        <span>recently lost or gained weight?</span>
                        <p>
                          {formDetail.hasLostGainedWeight === true
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                      <div className={styles.text}>
                        <span>Difficulty With Appetite</span>
                        <p>{formDetail.difficultyWithAppetite}</p>
                      </div>
                      <div className={styles.text}>
                        <span>Depression Level</span>
                        <p>{formDetail.depressionLevel}</p>
                      </div>
                      <div className={styles.text}>
                        <span>Depression Since</span>
                        <p>{formDetail.depressionSince}</p>
                      </div>
                      <div className={styles.text}>
                        <span>experiencing anxiety</span>
                        <p>{formDetail.hasAnxiety === true ? 'Yes' : 'No'}</p>
                      </div>
                      <div className={styles.text}>
                        <span>anxiety Since</span>
                        <p>{formDetail.anxietySince}</p>
                      </div>
                      <div className={styles.text}>
                        <span>alcohol Per Week</span>
                        <p>{formDetail.alchoholPerWeek}</p>
                      </div>
                      <div className={styles.text}>
                        <span>alcohol Per Night</span>
                        <p>{formDetail.alchoholPerNight}</p>
                      </div>
                      <div className={styles.text}>
                        <span>alcohol Per Hour</span>
                        <p>{formDetail.alchoholPerHour}</p>
                      </div>
                      <div className={styles.text}>
                        <span>How often do you use recreational drugs</span>
                        <p>{formDetail.recreationalDrugsFrequency}</p>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            dependency on alcohol or recreational drugs?
                          </span>
                          <p>
                            {formDetail.hadAlchoholDrugDependency === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Nature of dependency or abuse</span>
                          <p>{formDetail.natureOfAlchoholDepenency}</p>
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            have a history of prescription drug dependency or
                            abuse?
                          </span>
                          <p>
                            {formDetail.hadPrescreptionDrugDependency === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Nature of dependency or abuse</span>
                          <p>{formDetail.natureOfPrescreptionDependency}</p>
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>Is Pregnant</span>
                          <p>{formDetail.isPregnant === true ? 'Yes' : 'No'}</p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Form of BC do you use</span>
                          <p>{formDetail.formOfBC}</p>
                        </div>
                      </div>
                      <h2>
                        Has anyone in your family been diagnosed with or treated
                        for
                      </h2>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>Bipolar disorder</span>
                          <p>
                            {formDetail.familyMemberBipolarDisorder === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Anxiety</span>
                          <p>
                            {formDetail.familyMemberAnxiety === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>PTSD</span>
                          <p>
                            {formDetail.familyMemberPTSD === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Anger</span>
                          <p>
                            {formDetail.familyMemberAnger === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>Other substance abuse</span>
                          <p>
                            {formDetail.familyMemberOtherSubstance === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Depression</span>
                          <p>
                            {formDetail.familyMemberDepression === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>Violence</span>
                          <p>
                            {formDetail.familyMemberViolence === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Alcohol abuse</span>
                          <p>
                            {formDetail.familyMemberAlcoholAbuse === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>Schizophrenia</span>
                          <p>
                            {formDetail.familyMemberSchizophrenia === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>Suicide</span>
                          <p>
                            {formDetail.familyMemberSuicide === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                      </div>
                      <div className={styles.text}>
                        <span>What Problems</span>
                        <p>{formDetail.familyMemberTreatment}</p>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            Has any family member been treated with a
                            psychiatric medication?
                          </span>
                          <p>
                            {formDetail.hasFamilyMemberPsychatric === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>
                            If yes, who was treated, what medications, and how
                            effective was the treatment?
                          </span>
                          <p>{formDetail.familyMemberPsychatricTreatment}</p>
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={classnames('text', styles.column3)}>
                          <span>Natue OF Therapy</span>
                          <p>{formDetail.natureOfTherapy}</p>
                        </div>
                        <div className={classnames('text', styles.column3)}>
                          <span>significantTrauma</span>
                          <p>{formDetail.significantTrauma}</p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>No Data Available</div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Modal>
        )}
        {showAssessmentModal && (
          <Modal className={styles.modal}>
            <div className={styles.content}>
              <div
                className={styles.closeButton}
                onClick={() => setShowAssessmentModal(false)}
              >
                <img src={require(`../../img/close.svg`)} alt={`Close`} />
              </div>
              <h1>
                <b>{title}</b>
              </h1>
              <div className={styles.box}>
                <div className={styles['content-detail']}>
                  {form.assessments && form.assessments.length ? (
                    form.assessments.map((assessment, index) => (
                      <div className={styles.text} key={index}>
                        <span>{assessment.key}</span>
                        <p>{assessment.value}</p>
                      </div>
                    ))
                  ) : (
                    <>
                      <div>No Data Available</div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Modal>
        )}
        {showConsentModal && (
          <Modal className={styles.modal}>
            <div className={styles.content}>
              <div
                className={styles.closeButton}
                onClick={() => setshowConsentModal(false)}
              >
                <img src={require(`../../img/close.svg`)} alt={`Close`} />
              </div>
              <h1>
                <b>{title}</b>
              </h1>
              <div className={styles.box}>
                {getConsentFormURl ? (
                  <Iframe
                    url={getConsentFormURl}
                    max-width="600px"
                    width="100%s"
                    height="500px"
                    id="consetntForm"
                    display="initial"
                    position="relative"
                  />
                ) : (
                  <>
                    <div>No Data Available</div>
                  </>
                )}
              </div>
            </div>
          </Modal>
        )}
        {showSignature && (
          <Signature
            doctorId={form.doctor_id}
            patientId={form.patient_id}
            isType={true}
            role="Doctor"
          />
        )}
      </Cell>
    </Grid>
  );
};

export default FormsList;
