import React from 'react';
import { Cell } from 'react-foundation';
import useIntl from '../../../../../hooks/useIntl';
import FieldDropdown from '../../../../common/fields/FieldDropdown';
import FieldInput from '../../../../common/fields/FieldInput';
import RadioGroupWithLabel from '../../../../common/inputs/RadioGroupWithLabel';
import TextInput from '../../../../common/inputs/TextInput';
import Loading from '../../../../common/Loading';
import styles from '../../PersonalInfoForm/PersonalInfoForm.module.scss';
import { customDropdownStyles } from '.././../PersonalInfoForm/PersonalInfoForm';
import {
  anxietySymptoms,
  depressionSymptoms,
  frequencyOptions,
  stressSymptoms,
} from '../data';
import messages from '../messages';
import SymptomsForm from '../SymptomsForm/SymptomsForm';

const BPSFormStepTwo = ({
  values,
  handleChange,
  setFieldValue,
  handleBlur,
  isLoading,
  handleCheckboxChange,
}) => {
  const { formatMessage } = useIntl();

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Cell small={12}>
        <h4 className={styles.section}>
          {formatMessage(messages.currentWellbeing)}
        </h4>
      </Cell>
      <hr className={styles.form.hr} />
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.currentWellbeing.visitReason)}
          placeholder="Enter here"
          name="visit_reason"
          value={values.visit_reason}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepTwo.CurrentWellbeing.VisitReason.Input"
          autoFocus={true}
        />
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          type="checkbox"
          label={formatMessage(messages.currentWellbeing.depressionSymptoms)}
          componentName="depression_symptoms"
          options={depressionSymptoms}
          dataTestId="BPSFormStepTwo.CurrentWellbeing.DepressionSymptoms.RadioGroup"
          labelTestId="BPSFormStepTwo.CurrentWellbeing.DepressionSymptoms.Label"
          value={values.depression_symptoms}
          onChange={(value) => {
            const arrOfItems = handleCheckboxChange(
              values.depression_symptoms,
              value,
            );
            setFieldValue('depression_symptoms', arrOfItems);
          }}
        />
      </Cell>
      <SymptomsForm
        dropdownName="depression_symptoms_period"
        inputName="depression_question_response"
        radioGroupName="depression_symptoms_cause"
        dropdownValue={values.depression_symptoms_period}
        inputValue={values.depression_question_response}
        radioGroupValue={values.depression_symptoms_cause}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        dropdownDataTestId="BPSFormStepTwo.CurrentWellbeing.DepressionSymptomsPeriod.Dropdown"
        inputDataTestId="BPSFormStepTwo.CurrentWellbeing.DepressionQuestionResponse.Input"
        radioGroupDataTestId="BPSFormStepTwo.CurrentWellbeing.DepressionSymptomsCause.RadioGroup"
        radioGroupLabelTestId="BPSFormStepTwo.CurrentWellbeing.DepressionSymptomsCause.Label"
      />
      <Cell small={12}>
        <RadioGroupWithLabel
          type="checkbox"
          label={formatMessage(messages.currentWellbeing.anxietySymptoms)}
          componentName="anxiety_symptoms"
          options={anxietySymptoms}
          dataTestId="BPSFormStepTwo.CurrentWellbeing.AnxietySymptoms.RadioGroup"
          labelTestId="BPSFormStepTwo.CurrentWellbeing.AnxietySymptoms.Label"
          value={values.anxiety_symptoms}
          onChange={(value) => {
            const arrOfItems = handleCheckboxChange(
              values.anxiety_symptoms,
              value,
            );
            setFieldValue('anxiety_symptoms', arrOfItems);
          }}
        />
      </Cell>
      <Cell small={12}>
        <FieldDropdown
          label={formatMessage(messages.currentWellbeing.panicAttacks)}
          name="panic_attacks"
          placeholder="Select here"
          value={values.panic_attacks}
          onChange={(e) => {
            setFieldValue('panic_attacks', e.target.value);
          }}
          options={frequencyOptions}
          onBlur={handleBlur}
          dataTestId="PersonalInfoForm.InsuranceInformation.PanicAttacks.Dropdown"
          customStyles={customDropdownStyles}
        />
      </Cell>
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.currentWellbeing.otherSymptom)}
          placeholder="Enter here"
          name="other_anxiety_symptom"
          value={values.other_anxiety_symptom}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepTwo.CurrentWellbeing.OtherAnxietySymptom.Input"
        />
      </Cell>
      <SymptomsForm
        dropdownName="anxiety_symptoms_period"
        inputName="anxiety_question_response"
        radioGroupName="anxiety_symptoms_cause"
        dropdownValue={values.anxiety_symptoms_period}
        inputValue={values.anxiety_question_response}
        radioGroupValue={values.anxiety_symptoms_cause}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        dropdownDataTestId="BPSFormStepTwo.CurrentWellbeing.AnxietySymptomsPeriod.Dropdown"
        inputDataTestId="BPSFormStepTwo.CurrentWellbeing.AnxietyQuestionResponse.Input"
        radioGroupDataTestId="BPSFormStepTwo.CurrentWellbeing.AnxietySymptomsCause.RadioGroup"
        radioGroupLabelTestId="BPSFormStepTwo.CurrentWellbeing.AnxietySymptomsCause.Label"
      />
      <Cell small={12}>
        <RadioGroupWithLabel
          type="checkbox"
          label={formatMessage(messages.currentWellbeing.stressSymptoms)}
          componentName="stress_symptoms"
          options={stressSymptoms}
          dataTestId="BPSFormStepTwo.CurrentWellbeing.StressSymptoms.RadioGroup"
          labelTestId="BPSFormStepTwo.CurrentWellbeing.StressSymptoms.Label"
          value={values.stress_symptoms}
          onChange={(value) => {
            const arrOfItems = handleCheckboxChange(
              values.stress_symptoms,
              value,
            );
            setFieldValue('stress_symptoms', arrOfItems);
          }}
        />
        {values.stress_symptoms.includes('other') && (
          <TextInput
            placeholder="Enter here"
            name="stress_symptoms_input"
            value={values.stress_symptoms_input}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepTwo.CurrentWellbeing.StressSymptoms.Input"
          />
        )}
      </Cell>
      <SymptomsForm
        dropdownName="traumatic_symptoms_period"
        inputName="traumatic_question_response"
        radioGroupName="traumatic_symptoms_cause"
        dropdownValue={values.traumatic_symptoms_period}
        inputValue={values.traumatic_question_response}
        radioGroupValue={values.traumatic_symptoms_cause}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        dropdownDataTestId="BPSFormStepTwo.CurrentWellbeing.TraumaSymptomsPeriod.Dropdown"
        inputDataTestId="BPSFormStepTwo.CurrentWellbeing.TraumaticQuestionResponse.Input"
        radioGroupDataTestId="BPSFormStepTwo.CurrentWellbeing.TraumaSymptomsCause.RadioGroup"
        radioGroupLabelTestId="BPSFormStepTwo.CurrentWellbeing.TraumaSymptomsCause.Label"
        radioGroupLabel={formatMessage(
          messages.currentWellbeing.traumaticEvents,
        )}
        inputLabel={formatMessage(
          messages.currentWellbeing.affirmativeTraumaticEvent,
        )}
      />
      <SymptomsForm
        isDropdownDisplayed={false}
        inputName="stress_question_response"
        radioGroupName="stress_symptoms_cause"
        inputValue={values.stress_question_response}
        radioGroupValue={values.stress_symptoms_cause}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        inputDataTestId="BPSFormStepTwo.CurrentWellbeing.AffirmativeStressCause.Input"
        radioGroupDataTestId="BPSFormStepTwo.CurrentWellbeing.StressSymptomsCause.RadioGroup"
        radioGroupLabelTestId="BPSFormStepTwo.CurrentWellbeing.StressSymptomsCause.Label"
      />
    </>
  );
};

BPSFormStepTwo.propTypes = {};

BPSFormStepTwo.defaultProps = {};

export default BPSFormStepTwo;
