import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';

import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import SecondaryButton from '../../components/common/buttons/SecondaryButton';
import FieldInput from '../../components/common/fields/FieldInput/FieldInput';
import Modal from '../../components/common/Modal';
import Loading from '../../components/common/Loading';
import UserNotRecognized from '../../components/UserNotRecognized';
import Logo from '../../components/Logo';

import { addToDataLayer, checkWeeklyAssesment } from '../../utils/common';
import { emailValidator } from '../../utils/validators';

import config from '../../config';
import AuthContext from '../../context/AuthContext';
import { checkUser, getLoggedInUser } from '../../api/auth';
import { updateDoctorProfile } from '../../api/doctor';
import { FORM, USER_TYPES } from '../../constants';
import styles from './LoginPage.module.scss';

const LoginPage = ({ history }) => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showEmailNotFoundModal, setShowEmailNotFoundModal] = useState(false);

  const param = useParams();

  const auth = new OktaAuth({
    ...config.oidc,
  });

  const { dispatch } = useContext(AuthContext);
  const { authState } = useOktaAuth();

  const fetchUser = async () => {
    let loginData;
    try {
      setIsLoading(true);

      loginData = await getLoggedInUser(dispatch);

      if (loginData) {
        if (loginData.profile_type === USER_TYPES.DOCTOR) {
          /**
           * update doctor timezone on every login
           */
          const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          if (timezone) {
            await updateDoctorProfile({ timezone });
          }

          localStorage.setItem('doctorRedirectionUrl', '/doctor/availability');
          history.push('/doctor/my-account');
        } else if (loginData.profile_type === USER_TYPES.RESEARCHER) {
          history.push('/researcher/dashboard');
        } else if (loginData.profile_type === USER_TYPES.ADMIN) {
          history.push('/admin');
        } else {
          let redirectUrl = '';
          if (checkWeeklyAssesment(loginData)) {
            redirectUrl = '/my-account/assesment/eve_weekly_update';
            history.push(redirectUrl);
          } else if (
            loginData.assignAssessment &&
            loginData.assignAssessment.length > 0
          ) {
            let assessments = [];
            const informedConsentForm = loginData.assignAssessment.find(
              (form) => form.form_type === FORM.TYPE.CONSENT,
            );

            if (informedConsentForm) {
              history.push(`/my-account/form/consentform/`, {
                patient_id: informedConsentForm.patient_id,
                fact_form_id: informedConsentForm.id,
                template_name: informedConsentForm.dim_form.template_name,
                doctor_id: informedConsentForm.doctor_id,
              });
            } else {
              const allAssessments = loginData.assignAssessment.filter(
                (form) => form.form_type === FORM.TYPE.ASSESSMENT,
              );

              for (let i = 0; i < allAssessments.length; i++) {
                redirectUrl = `/my-account/assessment-form/${allAssessments[i].dim_form.assessment_key}`;

                if (i === allAssessments.length - 1) {
                  history.push(redirectUrl, {
                    fact_form_id: allAssessments[i].id,
                  });
                } else {
                  assessments.push({
                    url: redirectUrl,
                    id: allAssessments[i].id,
                  });
                }
              }
              history.assessments = assessments;
            }
          } else {
            redirectUrl = '/my-account';
            history.push(redirectUrl);
          }
        }
        const eventData = {
          event: 'ga4_event',
          properties: {
            event_name: 'login',
            method: 'Onsite Form or Google or Apple',
            user_id: loginData?.id || '',
            user_type: loginData?.profile_type || '',
          },
        };
        addToDataLayer(eventData);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const eventData = {
        event: 'ga4_event',
        properties: {
          event_name: 'error',
          user_id: loginData?.id || '',
          click_text: 'Error while user login',
          details: (err.response && err.response.data.message) || '',
          user_type: loginData?.profile_type || '',
          site_section: 'Login',
        },
      };
      addToDataLayer(eventData);
    }
  };

  useEffect(() => {
    // When Okta user is authenticated
    if (authState && authState.isAuthenticated === true) {
      fetchUser();
    }
  }, [authState]);

  const signIn = async (values) => {
    setIsLoading(true);
    try {
      const eventData = {
        event: 'ga4_event',
        properties: {
          event_name: 'login',
          method: 'Onsite Form',
        },
      };
      addToDataLayer(eventData);

      const transaction = await auth.signInWithCredentials({
        username: values.email,
        password: values.password,
      });
      if (transaction.status === 'SUCCESS') {
        await auth.token.getWithRedirect({
          sessionToken: transaction.data.sessionToken,
          responseType: ['token', 'id_token'],
        });
      }
    } catch (error) {
      const eventData = {
        event: 'ga4_event',
        properties: {
          event_name: 'error',
          user_id: '',
          click_text: 'Error while user login',
          details: (error && error.errorSummary) || '',
          user_type: '',
          site_section: 'Login',
        },
      };
      addToDataLayer(eventData);
      setError('Please Enter Correct Password');
      setIsLoading(false);
    }
  };

  const checkUserEmail = async (values, setSubmitting) => {
    const { email } = values;
    try {
      await checkUser(email);
      setChecked(true);
      await signIn(values);
    } catch (err) {
      const eventData = {
        event: 'ga4_event',
        properties: {
          event_name: 'error',
          user_id: '',
          click_text: 'Error while user login',
          details: 'user Not found' || '',
          user_type: '',
          site_section: 'Login',
        },
      };

      addToDataLayer(eventData);
      setChecked(true);
      setShowEmailNotFoundModal(true);
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      {checked && showEmailNotFoundModal && (
        <Modal className={styles.emailNotFoundModal}>
          <UserNotRecognized onClose={() => setShowEmailNotFoundModal(false)} />
        </Modal>
      )}
      <div className={styles.root}>
        {isLoading ? (
          <Loading />
        ) : (
          <div className={styles.container}>
            <div>
              <Logo />
            </div>
            <div className={styles.content}>
              <Formik
                initialValues={{
                  email: param.email,
                  userType: '',
                  password: '',
                  sessionToken: null,
                  terms: false,
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = 'Field Required';
                  } else if (!!emailValidator(values.email.trim()).email) {
                    errors.email = 'Invalid email address';
                  }
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  checkUserEmail(values, setSubmitting);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <h4 data-testid="login header">Please login to continue</h4>
                    <>
                      <FieldInput
                        className={styles.userInput}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        label="Email address"
                        errorText={touched.email && errors.email}
                        name="email"
                        data-testid="email"
                        label-testid="email label"
                        error-testid="email error"
                      />

                      <FieldInput
                        label="Password"
                        errorText={
                          (errors.password &&
                            touched.password &&
                            errors.password) ||
                          error
                        }
                        name="password"
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        data-testid="password"
                        label-testid="password label"
                        error-testid="password error"
                      />

                      <div className={styles.bottom}>
                        <div className={styles.nextBtn}>
                          <PrimaryButton
                            disabled={isSubmitting}
                            className={styles.primaryBtn}
                            label="Login"
                            data-testid="login button"
                          />
                        </div>
                        <div className={styles.bottomLinks}>
                          <Link
                            to={`/forgot-password`}
                            data-testid="forgot password"
                          >
                            Forgot Password?
                          </Link>
                          <Link to={`/pre-initial-signup`}>
                            Create New Account
                          </Link>
                        </div>
                      </div>
                    </>
                  </Form>
                )}
              </Formik>
              <div className={styles.verticalLine}></div>
              <div className={styles.socialLogin}>
                <div>
                  <SecondaryButton
                    label="Continue with Google"
                    className={styles.withGoogle}
                    data-testid="continue with google"
                    onClick={() => {
                      const eventData = {
                        event: 'ga4_event',
                        properties: {
                          event_name: 'login',
                          method: 'Google',
                        },
                      };
                      addToDataLayer(eventData);
                      auth.signInWithRedirect({
                        idp: process.env.REACT_APP_GOOGLE_IDP_ID,
                      });
                    }}
                  ></SecondaryButton>
                </div>
                <div>
                  <SecondaryButton
                    label="Continue with Apple"
                    className={styles.withApple}
                    data-testid="continue with apple"
                    onClick={() => {
                      const eventData = {
                        event: 'ga4_event',
                        properties: {
                          event_name: 'login',
                          method: 'Apple',
                        },
                      };
                      addToDataLayer(eventData);
                      auth.signInWithRedirect({
                        idp: process.env.REACT_APP_APPLE_IDP_ID,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withOktaAuth(LoginPage);
