import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Cell, Grid } from 'react-foundation';
import FieldInput from '../../common/fields/FieldInput/FieldInput';
import FieldDropdown from '../../common/fields/FieldDropdown';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import Datepicker from '../../common/Datepicker';
import { incompleteFields } from '../SuperBillForm';
import { procedureRequiredFields } from '../SuperBillForm';

const ProcedureForm = ({
  procedureValue,
  setProcedureValue,
  closeModal,
  styles,
  push,
  replace,
  editItemProcedure,
  setEditItemProcedure,
  placesOptions,
  onBlur,
  dropdownStyles,
  formDefaults,
  instance,
  setInstance,
  handleCustomChange,
}) => {
  const [tempValues, setTempValues] = useState({});

  const currentValues = editItemProcedure ? tempValues : procedureValue;

  const serviceDate = currentValues?.date_of_service;
  const appointmentDate = formDefaults?.appointment_date;

  const initialDateOfService = useMemo(() => {
    return serviceDate ? new Date(serviceDate) : new Date(appointmentDate);
  }, [serviceDate, appointmentDate]);

  useEffect(() => {
    if (!procedureValue) {
      setProcedureValue({
        ...procedureValue,
        date_of_service: initialDateOfService,
      });
    }
  }, [procedureValue, setProcedureValue, initialDateOfService]);

  useEffect(() => {
    if (editItemProcedure) {
      setTempValues(editItemProcedure.item);
    }
  }, [instance, editItemProcedure]);

  const handleChangeFormValue = (value, field) => {
    if (editItemProcedure) {
      setTempValues({
        ...currentValues,
        [field]: value,
      });
    } else {
      setProcedureValue({
        ...currentValues,
        [field]: value,
      });
    }
  };

  const addProcedure = () => {
    push(procedureValue);
    setProcedureValue();
    setInstance(instance + 1);
  };

  const editProcedure = () => {
    replace(editItemProcedure?.index, tempValues);
    setTempValues({});
    handleCustomChange();
    setInstance(instance + 1);
  };

  if (editItemProcedure && !currentValues) {
    return null;
  }

  return (
    <fieldset key={instance}>
      <legend> Procedures </legend>
      <Grid>
        <Cell large={12}>
          <FieldDropdown
            key={JSON.stringify(currentValues?.place_of_service) || ''}
            className={`${styles.selectDropdown} ${
              currentValues?.place_of_service ? styles.touched : ''
            }`}
            customStyles={dropdownStyles}
            label="Place of Service"
            name={'place_of_service'}
            value={currentValues?.place_of_service || ''}
            onChange={(e) =>
              handleChangeFormValue(e.target.value, 'place_of_service')
            }
            onBlur={onBlur}
            options={placesOptions}
            placeholder="Enter here."
            dataTestId="Superbill.ProcedureForm.PlaceOfService"
          />
        </Cell>
        <Cell large={12}>
          <Datepicker
            key={instance}
            label="Date of Service"
            id="dateofService"
            selected={initialDateOfService}
            value={initialDateOfService}
            autoComplete="off"
            peekNextMonth
            maxDate={new Date()}
            dropdownMode="select"
            data-testid="edit_procedure_dateOfService"
            placeholderText={'MM/dd/yyyy'}
            dateFormat="MM/dd/yyyy"
            onChange={(date) => handleChangeFormValue(date, 'date_of_service')}
          />
        </Cell>
        <Cell large={6} medium={6} small={12}>
          <FieldInput
            key={instance}
            label="CPT Code"
            placeholder="Enter here."
            value={currentValues?.cpt_code || ''}
            onChange={(e) => handleChangeFormValue(e.target.value, 'cpt_code')}
          />
        </Cell>
        <Cell large={6} medium={6} small={12}>
          <FieldInput
            label="Modifier"
            placeholder="Enter here."
            value={currentValues?.modifier || ''}
            onChange={(e) => handleChangeFormValue(e.target.value, 'modifier')}
          />
        </Cell>
        <Cell large={12}>
          <FieldInput
            label="Description"
            placeholder="Enter here."
            value={currentValues?.description || ''}
            onChange={(e) =>
              handleChangeFormValue(e.target.value, 'description')
            }
          />
        </Cell>
        <Cell large={6} medium={6} small={12}>
          <FieldInput
            label="Units"
            placeholder="Enter here."
            value={
              currentValues?.units ? currentValues.units.toString() : undefined
            }
            onChange={(e) => handleChangeFormValue(e.target.value, 'units')}
            type="number"
          />
        </Cell>

        <Cell large={6} medium={6} small={12}>
          <FieldInput
            label="Fee/Unit"
            placeholder="Enter here."
            value={
              currentValues?.fee ? currentValues.fee.toString() : undefined
            }
            onChange={(e) => handleChangeFormValue(e.target.value, 'fee')}
            type="number"
          />
        </Cell>
        {push && (
          <Cell>
            <button
              disabled={
                !currentValues ||
                incompleteFields(procedureRequiredFields, currentValues)
              }
              type="button"
              className={styles.buttonAddItem}
              onClick={() => {
                addProcedure();
                setProcedureValue();
              }}
              data-testid="add_item_procedure"
            >
              + Add Item
            </button>
          </Cell>
        )}
        {replace && editItemProcedure && (
          <PrimaryButton
            disabled={
              !currentValues ||
              incompleteFields(procedureRequiredFields, currentValues)
            }
            type="button"
            className={styles.editButton}
            label="Save"
            onClick={() => {
              editProcedure();
              setEditItemProcedure(null);
              closeModal();
            }}
          />
        )}
      </Grid>
    </fieldset>
  );
};

ProcedureForm.propTypes = {
  push: PropTypes.func,
  replace: PropTypes.func,
  closeModal: PropTypes.func,
  procedureValue: PropTypes.object,
  setProcedureValue: PropTypes.func,
  setEditItemProcedure: PropTypes.func,
  editItemProcedure: PropTypes.object,
  styles: PropTypes.object,
  placesOptions: PropTypes.array,
  onBlur: PropTypes.func,
  dropdownStyles: PropTypes.object,
  instance: PropTypes.number,
  setInstance: PropTypes.func,
};

export default ProcedureForm;
