import React from 'react';
import { Cell, Grid, Column } from 'react-foundation';
import { Field } from 'formik';
import classnames from 'classnames';

import { emailValidator, ageValidator } from '../../utils/validators';

import Places from '../../components/Places';
import BenefitIcon from '../../components/BenefitIcon';
import ProductTypeIcon from '../../components/ProductTypeIcon';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import CheckBoxSmall from '../../components/common/inputs/CheckBoxSmall';
import SwipeCardCustom from '../../components/SwipeCardCustom';
import SliderTypeIcon from '../../components/SliderTypeIcon';
import Modal from '../../components/common/Modal';
import CircleButtonBox from '../../components/common/buttons/CircleButtonBox';
import CircleButtonSmall from '../../components/common/buttons/CircleButtonSmall';
import RoundCheckBoxSmall from '../../components/common/inputs/RoundCheckBoxSmall';
import SwipeCardInfo from '../../components/common/SwipeCardInfo';
import AutoComplete from '../../components/common/inputs/AutoComplete';
import CircleButtonRadio from '../common/buttons/CircleButtonRadio';
import TextInput from '../../components/common/inputs/TextInput';
import RoundCheckBox from '../../components/common/inputs/RoundCheckBox';

import messages from './messages';

import styles from './AssessmentInputs.module.scss';

const AssessmentInputs = ({
  currentPage,
  answers,
  setAnswers,
  page,
  setPage,
  initialValues,
  formatMessage,
  setIsLastStep,
}) => {
  const {
    type,
    key,
    validation,
    marks,
    persist,
    subtype,
    leftLabel,
    rightLabel,
  } = currentPage;
  const formatTitle = ({ title, param }, answers) =>
    !param ? title : title.replace('*', answers[param].value);
  const Icon = ({ type, ...rest }) =>
    type === 'benefits' ? (
      <BenefitIcon {...rest} />
    ) : (
      <ProductTypeIcon {...rest} />
    );
  const validate = (value, key, validation, field) => {
    if (!value.trim()) {
      return 'A value is required';
    }
    switch (validation) {
      case 'email':
        const { email } = emailValidator(value);
        return email;
      case 'age':
        const { age } = ageValidator(value);
        return age;
      default:
        return null;
    }
  };
  switch (type) {
    case 'text':
      return (
        <div>
          <Field
            name={key}
            component={TextInput}
            submitButton={false}
            placeholder={`Enter ${
              currentPage.placeholder ? currentPage.placeholder : key
            }`}
            onChange={(e) =>
              setAnswers({
                ...answers,
                [key]: { value: e.currentTarget.value },
              })
            }
            value={answers[key].value}
            autoFocus={true}
            className={styles.input}
            validate={() => validate(answers[key].value, key, validation)}
            data-testid={`input-test-${key}`}
          />
        </div>
      );
    case 'places':
      return (
        <Field
          name={key}
          component={Places}
          submitButton={false}
          placeholder={`Enter ${key}`}
          onPlaceLoaded={(e) =>
            setAnswers({ ...answers, [key]: { value: e.formatted_address } })
          }
          value={answers[key].value}
          autoFocus={true}
          className={styles.input}
          validate={() => validate(answers[key].value, key, validation)}
          data-testid={`input-test-${key}`}
        />
      );
    case 'multiple-choice':
      return (
        <Grid className={styles['grid-center']}>
          {currentPage.choices.map((choice) => (
            <Cell small={4} medium={2} key={choice.key}>
              <Field
                label={choice.title}
                type={key}
                id={choice.key}
                isActive={
                  answers[key].options &&
                  !!answers[key].options.find(
                    (option) => option.key === choice.key,
                  )
                }
                component={RoundCheckBox}
                theme="gray"
                icon={<Icon size="100px" icon={choice.key} type={key} />}
                onClick={() => {
                  const optionExists =
                    answers[key].options &&
                    answers[key].options.find(
                      (option) => option.key === choice.key,
                    );
                  return optionExists
                    ? setAnswers({
                        ...answers,
                        [key]: {
                          options: answers[key].options.filter(
                            (option) => option.key !== choice.key,
                          ),
                        },
                      })
                    : setAnswers({
                        ...answers,
                        [key]: {
                          options: [...(answers[key].options || []), choice],
                        },
                      });
                }}
                data-testid={`multiple-choice-${choice.key}`}
              />
            </Cell>
          ))}
          <Cell small={12}>
            <PrimaryButton
              disabled={
                !answers[key].options || answers[key].options.length === 0
              }
              label={formatMessage(messages.continueBtn)}
              data-testid="multiple-choice button"
            />
          </Cell>
        </Grid>
      );
    case 'multiple-choice-checkbox':
      return (
        <Grid className={styles['grid-center']}>
          {currentPage.choices.map((choice) => (
            <Column
              small={12}
              medium={7}
              key={choice.key}
              className={styles['grid-cell']}
            >
              <Field
                label={choice.title}
                type={key}
                id={choice.key}
                isActive={
                  answers[key].options &&
                  !!answers[key].options.find(
                    (option) => option.key === choice.key,
                  )
                }
                component={CheckBoxSmall}
                theme="gray"
                onClick={() => {
                  const optionExists =
                    answers[key].options &&
                    answers[key].options.find(
                      (option) => option.key === choice.key,
                    );
                  return optionExists
                    ? setAnswers({
                        ...answers,
                        [key]: {
                          options: answers[key].options.filter(
                            (option) => option.key !== choice.key,
                          ),
                        },
                      })
                    : setAnswers({
                        ...answers,
                        [key]: {
                          options: [...(answers[key].options || []), choice],
                        },
                      });
                }}
                data-testid={`multiple-choice-checkbox-${choice.key}`}
              />
            </Column>
          ))}
        </Grid>
      );
    case 'swipe-card':
      const swipeCardChoice = ({ onClick }) => (
        <SwipeCardCustom
          title={currentPage.title}
          subtitle={currentPage.subtitle}
          description={currentPage.description}
          imageURL={currentPage.imageURL}
          onSwipe={onClick}
        />
      );
      return (
        <Field
          key={currentPage.key}
          component={swipeCardChoice}
          name={key}
          data-testid={`single-choice-${currentPage.key}`}
          onClick={(isRight) => {
            setAnswers({
              ...answers,
              [key]: {
                value: isRight ? 'yes' : 'no',
                key: currentPage.key,
                to: page + 1,
              },
            });
            setPage(answers[currentPage.key].to || page + 1);
          }}
        />
      );
    case 'single-choice':
      return (
        <Grid className={styles['expirience-boxes']}>
          {currentPage.choices.map((choice) => (
            <Field
              key={choice.key}
              component={CircleButtonBox}
              name={key}
              title={choice.title}
              subtitle={choice.subtitle}
              isSelected={answers[key].key === choice.key}
              icon={choice.key}
              onClick={() =>
                setAnswers({
                  ...answers,
                  [key]: {
                    value: choice.title,
                    key: choice.key,
                    to: choice['jump-to-position'],
                  },
                })
              }
              data-testid={`single-choice-${choice.key}`}
            />
          ))}
        </Grid>
      );
    case 'single-choice-small':
      return (
        <Grid style={{ justifyContent: 'center' }}>
          {currentPage.choices.map((choice) => (
            <Cell
              medium={4}
              small={currentPage.cols ? 12 / currentPage.cols : 12}
              key={choice.key}
              className={styles['actual-padding']}
            >
              <Field
                key={choice.key}
                component={CircleButtonSmall}
                name={key}
                title={choice.title}
                cols={currentPage.cols}
                isSelected={answers[key].key === choice.key}
                onClick={() => {
                  setAnswers({
                    ...answers,
                    [key]: {
                      value: choice.title,
                      key: choice.key,
                      to: choice['jump-to-position'],
                    },
                  });
                }}
                className={styles['actual-height']}
                data-testid={`single-choice-small-${choice.key}`}
              />
            </Cell>
          ))}
        </Grid>
      );
    case 'single-choice-radio':
      return (
        <Grid className={styles['grid-center']}>
          {currentPage.choices.map((choice) => (
            <Column
              small={12}
              medium={7}
              key={choice.key}
              className={styles['grid-cell']}
            >
              <Field
                key={choice.key}
                component={CircleButtonRadio}
                name={key}
                title={choice.title}
                subtitle={choice.subtitle}
                isSelected={answers[key].key === choice.key}
                icon={choice.key}
                onClick={() =>
                  setAnswers({
                    ...answers,
                    [key]: {
                      value: choice.title,
                      key: choice.key,
                      to: choice['jump-to-position'],
                    },
                  })
                }
                data-testid={`single-choice-${choice.key}`}
              />
            </Column>
          ))}
        </Grid>
      );
    case 'multi-choice-small':
      return (
        <Grid className={styles['grid-center']}>
          {currentPage.choices.map((choice) => (
            <Cell
              small={6}
              medium={4}
              key={choice.key}
              className={styles['multi-choice-small']}
            >
              <Field
                label={choice.title}
                type={key}
                id={choice.key}
                isActive={
                  answers[key].options &&
                  !!answers[key].options.find(
                    (option) => option.key === choice.key,
                  )
                }
                component={RoundCheckBoxSmall}
                theme="gray"
                icon={<Icon size="100px" icon={choice.key} type={key} />}
                onClick={() => {
                  const optionExists =
                    answers[key].options &&
                    answers[key].options.find(
                      (option) => option.key === choice.key,
                    );
                  return optionExists
                    ? setAnswers({
                        ...answers,
                        [key]: {
                          options: answers[key].options.filter(
                            (option) => option.key !== choice.key,
                          ),
                          to: answers[key].options.map((option) => {
                            if (option['jump-to-position'])
                              return option['jump-to-position'];
                            return undefined;
                          })[0],
                        },
                      })
                    : setAnswers({
                        ...answers,
                        [key]: {
                          options: [...(answers[key].options || []), choice],
                          to: choice['jump-to-position']
                            ? choice['jump-to-position']
                            : page + 1,
                        },
                      });
                }}
                data-testid={`multi-choice-small-${choice.key}`}
              />
            </Cell>
          ))}
          <Cell small={12}>
            <PrimaryButton
              disabled={
                !answers[key].options || answers[key].options.length === 0
              }
              label={formatMessage(messages.continueBtn)}
              data-testid="assessment continue button"
            />
          </Cell>
        </Grid>
      );
    case 'slider':
      let min = subtype === 'number' ? marks[0].value : 1;
      let max = marks[0].value === 0 ? marks.length - 1 : marks.length;
      return (
        <Grid flexContainer className={styles['flex-container']}>
          <Cell
            small={11}
            medium={10}
            alignX="center"
            className={styles['slider-container']}
          >
            <Field
              component={SliderTypeIcon}
              name={key}
              value={answers[key].value}
              onChange={(event, value) =>
                setAnswers({
                  ...answers,
                  [key]: { value: value, min, max, persist },
                })
              }
              isModified={answers[key] !== initialValues[key]}
              marks={marks}
              subtype={subtype}
              leftLabel={leftLabel}
              rightLabel={rightLabel}
              max={max}
              min={min}
            />
          </Cell>
        </Grid>
      );
    case 'modal':
      return (
        <div className={styles.modaldiv}>
          <Modal className={styles.modal}>
            <div className={styles['content-area']}>
              {currentPage.image && (
                <div
                  className={classnames(
                    styles.icon,
                    styles[`${currentPage.image}`],
                  )}
                >
                  <img
                    className={classnames(styles[`img-bg`])}
                    src={require(`../../img/icons//${currentPage.image}.svg`)}
                    alt={currentPage.title}
                    data-testid="assessment modal icon header"
                  />
                </div>
              )}
              {currentPage.swipCardInfo && (
                <div className={styles.swipeCardInfo}>
                  <SwipeCardInfo position={`top`} />
                </div>
              )}
              <h2 data-testid="assessment modal title header">
                {formatTitle(currentPage, answers)}
              </h2>
              <p data-testid="assessment modal text">{currentPage.subtitle}</p>
              <div className={styles.buttonRow}>
                <span className={classnames(styles[`button`])}>
                  <PrimaryButton
                    label={currentPage.cta}
                    className={classnames(styles[`button`])}
                    data-testid="assessment modal primary button"
                  />
                </span>
              </div>
            </div>
          </Modal>
        </div>
      );
    case 'transition-modal':
      setTimeout(async () => {
        if (currentPage['is-last-step']) {
          setIsLastStep(1);
        } else setPage(page + 1);
      }, 2500);
      return (
        <div className={styles.modaldiv}>
          <Modal className={styles.modal}>
            <div className={styles['content-area']}>
              <div className={classnames(styles[`icon`])}>
                <div className={classnames(styles[`spinner`])}>
                  <div className={classnames(styles[`rect1`])}></div>
                  <div className={classnames(styles[`rect2`])}></div>
                  <div className={classnames(styles[`rect3`])}></div>
                  <div className={classnames(styles[`rect4`])}></div>
                  <div className={classnames(styles[`rect5`])}></div>
                </div>
              </div>
              <h2 data-testid="transition modal title">
                {formatTitle(currentPage, answers)}
              </h2>
              <p data-testid="transition modal subtitle">
                {currentPage.subtitle}
              </p>
            </div>
          </Modal>
        </div>
      );
    case 'autocomplete':
      return (
        <Field
          name={key}
          component={AutoComplete}
          items={currentPage.items}
          placeholder={key}
          onChange={(value) =>
            setAnswers({
              ...answers,
              [key]: { value: value },
            })
          }
          value={answers[key].value}
          data-testid={`autocomplete-test-${key}`}
        />
      );
    default:
      return null;
  }
};

export default AssessmentInputs;
