import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import _ from 'lodash';
import { addToDataLayer } from '../../utils/common/';

const WithGASetup = (WrappedComponent, AuthContext, ...rest) => {
  const WithGASetup = (props) => {
    const authContext = useContext(
      AuthContext !== undefined ? AuthContext : '',
    );
    const { authUser } = authContext ? authContext : { authUser: undefined };

    const location = useLocation();

    const [, setEventData] = useState({
      event: 'ga4_event',
      properties: {
        event_name: 'pageView',
        env: process.env.REACT_APP_ENV,
        path: 'location',
        user_id: authUser?.id || '',
        clinic_id: authUser?.clinic_id || '',
        user_type: authUser?.profile_type || '',
      },
    });

    useEffect(() => {
      if (!_.isEmpty(authUser) || !AuthContext) {
        const eventObject = {
          event: 'ga4_event',
          properties: {
            event_name: 'pageView',
            env: process.env.REACT_APP_ENV,
            path: location.pathname,
            user_id: authUser?.id || '',
            clinic_id: authUser?.clinic_id || '',
            user_type: authUser?.profile_type || '',
          },
        };
        setEventData(eventObject);
        addToDataLayer(eventObject);
      }
    }, [authUser, location.pathname]);

    return (
      <>
        <WrappedComponent {...props} />
      </>
    );
  };
  return WithGASetup;
};

export default WithGASetup;
