import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '../common/Modal';
import styles from './MessageModal.module.scss';
import Back from '../../img/icons/back.svg';
import ApprovalImg from '../../img/approval.svg';
import SuccessImg from '../../img/success-new.svg';
import Component from '../../img/Component.svg';
import Logo from '../Logo';
import PrimaryButton from '../common/buttons/PrimaryButton';
import { addToDataLayer } from '../../utils/common';
import AuthContext from '../../context/AuthContext';
import { useHistory } from 'react-router-dom';

const MessageModal = ({ message, onCloseModal, displayLogo = true }) => {
  const { authUser } = useContext(AuthContext);
  useEffect(() => {
    if (message.text && message.type !== 'success') {
      const eventData = {
        event: 'ga4_event',
        properties: {
          event_name: 'error',
          user_id: (authUser && authUser.id) || '',
          user_type: authUser.profile_type,
          details: message,
        },
      };
      addToDataLayer(eventData);
    }
  }, [message, authUser]);

  const history = useHistory();
  const chooseLogo = (type) => {
    switch (type) {
      case 'error':
        return (
          <img
            src={ApprovalImg}
            alt="Warning"
            data-testid="modal error image"
          />
        );
      case 'success':
        return (
          <img src={SuccessImg} alt="Sucess" data-testid="modal error image" />
        );
      case 'alert':
        return (
          <img src={Component} alt="Alert" data-testid="modal error image" />
        );
      default:
        return (
          <img src={SuccessImg} alt="Sucess" data-testid="modal error image" />
        );
    }
  };

  return (
    <Modal className={styles.mainContainer}>
      <div className={styles.content}>
        <div className={styles.container}>
          <div className={styles.backDiv}>
            <img src={Back} alt="Back" data-testid="modal error back image" />
            <span onClick={onCloseModal} data-testid="modal error back label">
              Back
            </span>
          </div>
          {displayLogo && <Logo />}
          <div className={styles.warningImg}>{chooseLogo(message.type)}</div>
          <h4
            className={styles.confirmationTitle}
            data-testid="modal error title"
          >
            {message.title}
          </h4>
          <p className={styles.text} data-testid="modal error message">
            {message.text}
          </p>
          {message.buttonLabel && (message.redirectUrl || message.close) && (
            <PrimaryButton
              label={message.buttonLabel}
              data-testid="modal error button"
              onClick={() => {
                message.close
                  ? onCloseModal()
                  : history.push(message.redirectUrl);
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

MessageModal.propTypes = {
  message: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    buttonLabel: PropTypes.string,
    redirectUrl: PropTypes.string,
    close: PropTypes.bool,
  }).isRequired,
  onCloseModal: PropTypes.func.isRequired,
  displayLogo: PropTypes.bool,
};

export default MessageModal;
