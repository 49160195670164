import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Cell, Grid } from 'react-foundation';
import FieldInput from '../../common/fields/FieldInput/FieldInput';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import styles from '../SuperBillForm.module.scss';
import TextAreaInputWithLabel from '../../common/inputs/TextAreaInputWithLabel';

const DiagnosisForm = ({
  push,
  replace,
  editItemDiagnosis,
  closeModal,
  diagnosisValue,
  setDiagnosisValue,
  setEditItemDiagnosis,
}) => {
  useEffect(() => {
    if (editItemDiagnosis) {
      setDiagnosisValue(editItemDiagnosis.item);
    } else {
      setDiagnosisValue();
    }
  }, [editItemDiagnosis, setDiagnosisValue]);

  const handleChangeFormValue = (value, field) => {
    setDiagnosisValue({
      ...diagnosisValue,
      [field]: value,
    });
  };

  const addDiagnosis = () => {
    push(diagnosisValue);
    setDiagnosisValue();
  };

  const replaceItem = () => {
    replace(editItemDiagnosis.index, diagnosisValue);
  };

  const renderDescription = () => (
    <>
      {editItemDiagnosis && (
        <TextAreaInputWithLabel
          label={'Description'}
          placeholder={'Enter here.'}
          value={diagnosisValue?.description || ''}
          onChange={(e) => handleChangeFormValue(e.target.value, 'description')}
        />
      )}
      {!editItemDiagnosis && (
        <FieldInput
          label={'Description'}
          placeholder={'Enter here.'}
          value={diagnosisValue?.description || ''}
          onChange={(e) => handleChangeFormValue(e.target.value, 'description')}
        />
      )}
    </>
  );

  return (
    <fieldset>
      <legend>Diagnoses</legend>
      <Grid>
        <Cell large={12}>
          <FieldInput
            label={'Code'}
            placeholder={'Enter here.'}
            value={diagnosisValue?.code || ''}
            onChange={(e) => handleChangeFormValue(e.target.value, 'code')}
          />
        </Cell>
        <Cell large={12}>{renderDescription()}</Cell>
        {!editItemDiagnosis && (
          <Cell>
            <button
              disabled={
                !diagnosisValue ||
                Object.keys(diagnosisValue).length !== 2 ||
                Object.values(diagnosisValue).some((elem) => !elem)
              }
              type="button"
              className={styles.buttonAddItem}
              onClick={() => {
                addDiagnosis();
                setDiagnosisValue();
              }}
              data-testid="add_item_diagnosis"
            >{` + Add Item `}</button>
          </Cell>
        )}
        {editItemDiagnosis && (
          <PrimaryButton
            disabled={
              !diagnosisValue || !Object.values(diagnosisValue).every(Boolean)
            }
            type="button"
            className={styles.editButton}
            label={'Save'}
            onClick={() => {
              replaceItem();
              setDiagnosisValue();
              setEditItemDiagnosis(null);
              closeModal();
            }}
          />
        )}
      </Grid>
    </fieldset>
  );
};

DiagnosisForm.propTypes = {
  push: PropTypes.func,
  replace: PropTypes.func,
  editItemDiagnosis: PropTypes.object,
  closeModal: PropTypes.func,
  diagnosisValue: PropTypes.object,
  setDiagnosisValue: PropTypes.func,
  setEditItemDiagnosis: PropTypes.func,
};

export default DiagnosisForm;
