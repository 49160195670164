import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './SwipeCardInfo.module.scss';

const SwipeCardInfo = ({ position, onClickNo, onClickYes }) => (
  <>
    <div>
      <span
        className={
          onClickNo
            ? classNames(styles.link, styles.label)
            : classNames(styles.label)
        }
        onClick={onClickNo}
        data-testid="swipcard no"
      >
        No
      </span>
      <span
        className={
          onClickNo
            ? classNames(styles.link, styles.leftarrow)
            : classNames(styles.leftarrow)
        }
        onClick={onClickNo}
        data-testid="swipcard arrow no"
      >
        <img src={require(`../../../img/icons/back-arrow.svg`)} alt="left" />
      </span>
      <span
        className={
          position === 'bottom' ? styles['imgbgsmall'] : styles['imgbg']
        }
      >
        <img
          src={require(`../../../img/icons/hand-drag.svg`)}
          alt="hand-drag"
        />
      </span>
      <span
        className={
          onClickYes
            ? classNames(styles.link, styles.rightarrow)
            : classNames(styles.rightarrow)
        }
        onClick={onClickYes}
        data-testid="swipcard arrow yes"
      >
        <img src={require(`../../../img/icons/next-arrow.svg`)} alt="right" />
      </span>
      <span
        data-testid="swipcard yes"
        onClick={onClickYes}
        className={
          onClickYes
            ? classNames(styles.link, styles.label)
            : classNames(styles.label)
        }
      >
        Yes
      </span>
    </div>
  </>
);

SwipeCardInfo.propTypes = {
  position: PropTypes.string,
  onClickNo: PropTypes.func,
  onClickYes: PropTypes.func,
};

SwipeCardInfo.defaultProps = {
  position: 'bottom',
};
export default SwipeCardInfo;
