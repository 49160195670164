import React from 'react';
import { Cell } from 'react-foundation';
import useIntl from '../../../../../hooks/useIntl';
import FieldDropdown from '../../../../common/fields/FieldDropdown';
import FieldDropdownWithInput from '../../../../common/fields/FieldDropdownWithInput';
import FieldInput from '../../../../common/fields/FieldInput';
import RadioGroupWithLabel from '../../../../common/inputs/RadioGroupWithLabel';
import Loading from '../../../../common/Loading';
import { customDropdownStyles } from '../../PersonalInfoForm/PersonalInfoForm';
import styles from '../../PersonalInfoForm/PersonalInfoForm.module.scss';
import {
  binaryResponses,
  emotionalSupport,
  employmentStatuses,
  genderIdentities,
  incomeSources,
  relationshipStatuses,
  relationshipTypes,
  residentTypes,
  sexAtBirth,
  sexualOrientations,
} from '../data';
import messages from '../messages';

const BPSFormStepSix = ({
  values,
  handleChange,
  setFieldValue,
  handleBlur,
  isLoading,
}) => {
  const { formatMessage } = useIntl();

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Cell small={12}>
        <h4 className={styles.section}>
          {formatMessage(messages.familyAndSocialHistory)}
        </h4>
      </Cell>
      <hr className={styles.form.hr} />
      <Cell small={12}>
        <FieldDropdown
          autoFocus={true}
          label={formatMessage(messages.familyAndSocialHistory.sexAtBirth)}
          name="sex_at_birth"
          placeholder="Select here"
          value={values.sex_at_birth}
          onChange={(e) => {
            setFieldValue('sex_at_birth', e.target.value);
          }}
          options={sexAtBirth}
          onBlur={handleBlur}
          dataTestId="BPSFormStepSix.FamilyAndSocialHistory.SexAtBirth.Dropdown"
          customStyles={customDropdownStyles}
        />
      </Cell>
      <Cell small={12}>
        <FieldDropdownWithInput
          isClearable
          label={formatMessage(messages.familyAndSocialHistory.gender)}
          maxLength={25}
          onChange={(event) => {
            if (event === null) {
              setFieldValue('gender_identity', null);
              return;
            }
            setFieldValue('gender_identity', event.value);
          }}
          value={values?.gender_identity}
          options={genderIdentities}
          onBlur={handleBlur}
          placeholder="Select or type here"
          dataTestId="BPSFormStepSix.FamilyAndSocialHistory.GenderIdentity.Dropdown"
          customStyles={customDropdownStyles}
        />
      </Cell>
      {(values.gender_identity === 'other' ||
        values.gender_identity === 'non-conforming') && (
        <Cell small={12}>
          <FieldInput
            label={formatMessage(
              messages.familyAndSocialHistory.preferredGender,
            )}
            placeholder="Enter here"
            name="preferred_gender"
            value={values.preferred_gender}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepSix.FamilyAndSocialHistory.PreferredGender.Input"
          />
        </Cell>
      )}
      <Cell small={12}>
        <FieldDropdown
          label={formatMessage(
            messages.familyAndSocialHistory.sexualOrientation,
          )}
          name="sexual_orientation"
          placeholder="Select here"
          value={values.sexual_orientation}
          onChange={(e) => {
            setFieldValue('sexual_orientation', e.target.value);
          }}
          options={sexualOrientations}
          onBlur={handleBlur}
          dataTestId="BPSFormStepSix.FamilyAndSocialHistory.SexualOrientation.Dropdown"
          customStyles={customDropdownStyles}
        />
      </Cell>
      {values.sexual_orientation === 'other' && (
        <Cell small={12}>
          <FieldInput
            label={formatMessage(
              messages.familyAndSocialHistory.otherIdentifyLabel,
            )}
            placeholder="Enter here"
            name="other_sexual_orientation"
            value={values.other_sexual_orientation}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepSix.FamilyAndSocialHistory.OtherSexualOrientation.Input"
          />
        </Cell>
      )}
      <Cell small={12}>
        <FieldDropdown
          label={formatMessage(
            messages.familyAndSocialHistory.relationshipStatus,
          )}
          name="relationship_status"
          placeholder="Select here"
          value={values.relationship_status}
          onChange={(e) => {
            setFieldValue('relationship_status', e.target.value);
          }}
          options={relationshipStatuses}
          onBlur={handleBlur}
          dataTestId="BPSFormStepSix.FamilyAndSocialHistory.RelationshipStatus.Dropdown"
          customStyles={customDropdownStyles}
        />
      </Cell>
      {values.relationship_status === 'other' && (
        <Cell small={12}>
          <FieldInput
            label={formatMessage(
              messages.familyAndSocialHistory.otherIdentifyLabel,
            )}
            placeholder="Enter here"
            name="other_relationship_status"
            value={values.other_relationship_status}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepSix.FamilyAndSocialHistory.OtherRelationshipStatus.Input"
          />
        </Cell>
      )}
      <Cell small={12}>
        <FieldDropdown
          label={formatMessage(
            messages.familyAndSocialHistory.currentRelationship,
          )}
          name="relationship_type"
          placeholder="Select here"
          value={values.relationship_type}
          onChange={(e) => {
            setFieldValue('relationship_type', e.target.value);
          }}
          options={relationshipTypes}
          onBlur={handleBlur}
          dataTestId="BPSFormStepSix.FamilyAndSocialHistory.CurrentRelationship.Dropdown"
          customStyles={customDropdownStyles}
        />
      </Cell>
      {values.relationship_type === 'other' && (
        <Cell small={12}>
          <FieldInput
            label={formatMessage(
              messages.familyAndSocialHistory.otherIdentifyLabel,
            )}
            placeholder="Enter here"
            name="other_relationship_type"
            value={values.other_relationship_type}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepSix.FamilyAndSocialHistory.OtherRelationshipType.Input"
          />
        </Cell>
      )}
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.familyAndSocialHistory.nrOfChildren)}
          placeholder="Enter here"
          name="nr_of_children"
          value={values.nr_of_children}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepSix.FamilyAndSocialHistory.NrOfChildren.Input"
        />
      </Cell>
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.familyAndSocialHistory.nrOfPregnancies)}
          placeholder="Enter here"
          name="nr_of_pregnancies"
          value={values.nr_of_pregnancies}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepSix.FamilyAndSocialHistory.NrOfPregnancies.Input"
        />
      </Cell>
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.familyAndSocialHistory.nrOfAdoptions)}
          placeholder="Enter here"
          name="nr_of_adoptions"
          value={values.nr_of_adoptions}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepSix.FamilyAndSocialHistory.NrOfAdoptions.Input"
        />
      </Cell>
      <Cell small={12}>
        <FieldDropdown
          label={formatMessage(messages.familyAndSocialHistory.resideWith)}
          name="resident_type"
          placeholder="Select here"
          value={values.resident_type}
          onChange={(e) => {
            setFieldValue('resident_type', e.target.value);
          }}
          options={residentTypes}
          onBlur={handleBlur}
          dataTestId="BPSFormStepSix.FamilyAndSocialHistory.ResidentType.Dropdown"
          customStyles={customDropdownStyles}
        />
      </Cell>
      {values.resident_type === 'other' && (
        <Cell small={12}>
          <FieldInput
            label={formatMessage(
              messages.familyAndSocialHistory.otherWriteInLabel,
            )}
            placeholder="Enter here"
            name="other_resident_type"
            value={values.other_resident_type}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepSix.FamilyAndSocialHistory.OtherResidentType.Input"
          />
        </Cell>
      )}
      <Cell small={12}>
        <FieldDropdown
          label={formatMessage(
            messages.familyAndSocialHistory.employmentStatus,
          )}
          name="employment_status"
          placeholder="Select here"
          value={values.employment_status}
          onChange={(e) => {
            setFieldValue('employment_status', e.target.value);
          }}
          options={employmentStatuses}
          onBlur={handleBlur}
          dataTestId="BPSFormStepSix.FamilyAndSocialHistory.EmploymentStatus.Dropdown"
          customStyles={customDropdownStyles}
        />
      </Cell>
      <Cell small={12}>
        <FieldDropdown
          label={formatMessage(messages.familyAndSocialHistory.sourceOfIncome)}
          name="income_source"
          placeholder="Select here"
          value={values.income_source}
          onChange={(e) => {
            setFieldValue('income_source', e.target.value);
          }}
          options={incomeSources}
          onBlur={handleBlur}
          dataTestId="BPSFormStepSix.FamilyAndSocialHistory.IncomeSources.Dropdown"
          customStyles={customDropdownStyles}
        />
      </Cell>
      {values.income_source === 'other' && (
        <Cell small={12}>
          <FieldInput
            label={formatMessage(
              messages.familyAndSocialHistory.otherWriteInLabel,
            )}
            placeholder="Enter here"
            name="other_income_sources"
            value={values.other_income_sources}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepSix.FamilyAndSocialHistory.OtherIncomeSources.Input"
          />
        </Cell>
      )}
      <Cell small={12}>
        <FieldDropdown
          label={formatMessage(
            messages.familyAndSocialHistory.emotionalSupport,
          )}
          name="emotional_support"
          placeholder="Select here"
          value={values.emotional_support}
          onChange={(e) => {
            setFieldValue('emotional_support', e.target.value);
          }}
          options={emotionalSupport}
          onBlur={handleBlur}
          dataTestId="BPSFormStepSix.FamilyAndSocialHistory.EmotionalSupport.Dropdown"
          customStyles={customDropdownStyles}
        />
      </Cell>
      {values.emotional_support === 'other' && (
        <Cell small={12}>
          <FieldInput
            label={formatMessage(
              messages.familyAndSocialHistory.otherDescribeLabel,
            )}
            placeholder="Enter here"
            name="other_emotional_support"
            value={values.other_emotional_support}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepSix.FamilyAndSocialHistory.OtherEmotionalSupport.Input"
          />
        </Cell>
      )}
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(
            messages.familyAndSocialHistory.developmentalMilestones,
          )}
          componentName="developmental_milestones"
          options={binaryResponses}
          dataTestId="BPSFormStepSix.FamilyAndSocialHistory.DevelopmentalMilestones.RadioGroup"
          labelTestId="BPSFormStepSix.FamilyAndSocialHistory.DevelopmentalMilestones.Label"
          value={values.developmental_milestones}
          onChange={(value) => setFieldValue('developmental_milestones', value)}
        />
      </Cell>
      {values.developmental_milestones === 'no' && (
        <Cell small={12}>
          <FieldInput
            label={formatMessage(
              messages.familyAndSocialHistory.developmentalDelays,
            )}
            placeholder="Enter here"
            name="developmental_delays"
            value={values.developmental_delays}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="BPSFormStepSix.FamilyAndSocialHistory.DevelopmentalDelays.Input"
          />
        </Cell>
      )}
      <Cell small={12}>
        <FieldInput
          label={formatMessage(
            messages.familyAndSocialHistory.familyRelationship,
          )}
          placeholder="Enter here"
          name="family_relationship"
          value={values.family_relationship}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid="BPSFormStepSix.FamilyAndSocialHistory.FamilyRelationship.Input"
        />
      </Cell>
    </>
  );
};

BPSFormStepSix.propTypes = {};

BPSFormStepSix.defaultProps = {};

export default BPSFormStepSix;
