import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CloseImg from '../../../../img/close.svg';
import styles from '../../FormListView.module.scss';

const FormHeader = ({ formName, onClick }) => {
  return (
    <Fragment>
      <div className={styles.closeButton} onClick={onClick}>
        <img src={CloseImg} alt="Close" />
      </div>
      <h1>{formName}</h1>
    </Fragment>
  );
};

FormHeader.propTypes = {
  formName: PropTypes.string,
  onClick: PropTypes.func,
};

export default FormHeader;
