import React from 'react';

class NylasAvailability extends React.Component {
  nylasScriptSrc =
    'https://schedule.nylas.com/schedule-editor/v1.0/schedule-editor.js';
  buildCalendlyUrl = (url, queryString) =>
    `${url}?primary_color=000000&${queryString}`;
  loadCalender = (token) => {
    var schedulerPage = window.nylas.scheduler.show({
      auth: {
        // Note: To get quick access to a Nylas access_token for testing,
        // login to https://dashboard.nylas.com and then click "Accounts"
        // and then "Auth Account". After authenticating your access_token
        // will appear at the top of the dashboard
        accessToken: token, // Account access_token with active calendar scope
        // accessToken:'wjd7nqeg3J6vyE7r0cikPT7xS2BbvY'
      },
      locale: 'en',
      locale_for_guests: 'en',
      behavior: {
        displayOnly: ['event-info', 'calendars', 'opening-hours'],
        // disableSlugChanges: true,
      },
      // disableViewingPages: true,
      style: {
        // Style the schedule editor
        tintColor: '#32325d',
        backgroundColor: 'white',
        modalTitle: 'Appointment Types',
      },
      defaults: {
        appearance: {
          show_week_view: false,
          show_timezone_options: false,
          thank_you_redirect: `${window.location.origin}/thank-you.html`,
        },
        booking: {
          confirmation_emails_to_host: false,
          confirmation_emails_to_guests: false,
          calendar_invite_to_guests: true,
          additional_guests_hidden: false,
        },
        reminders: [
          {
            delivery_method: 'webhook',
            time_before_event: 20160,
            delivery_recipient: 'customer',
            webhook_url: `${process.env.REACT_APP_WEB_API_URL}/b2c/booking_webhook`,
          },
        ],
      },
    });

    schedulerPage.addEventListener('close', () => {
      this.props.handleCloseEditor();
    });
  };

  componentDidMount() {
    const { token } = this.props;
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', this.nylasScriptSrc);
    script.setAttribute('id', 'nylas-script');
    head.appendChild(script);
    script.onload = () => {
      if (this.loadCalender) this.loadCalender(token);
    };
  }

  componentWillUnmount() {
    const head = document.querySelector('head');
    const script = document.getElementById('nylas-script');
    script && head.removeChild(script);
  }
  render() {
    return <div id="schedule_form"></div>;
  }
}

export default NylasAvailability;
