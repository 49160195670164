import React, { useEffect, createContext, useState } from 'react';
import Loading from '../components/common/Loading';
import { getWhitelabelBySubdomain } from '../api/whitelabel';

const WhitelabelContext = createContext({});

export const WhitelabelProvider = ({ children }) => {
  const [whitelabel, setWhitelabel] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchWhitelabel = async () => {
      try {
        setLoading(true);
        const whitelabelData = await getWhitelabelBySubdomain();
        setWhitelabel(whitelabelData?.whitelabel || null);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchWhitelabel();
  }, []);
  return (
    <WhitelabelContext.Provider
      value={{
        whitelabel,
      }}
    >
      {loading && <Loading />}
      {children}
    </WhitelabelContext.Provider>
  );
};

export default WhitelabelContext;
