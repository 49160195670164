import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import List from './List';
import { AssessmentPDFStyles } from '../AssessmentPDFStyles';

const FullRowLabelledData = ({ label, value, isPrimary }) => (
  <View
    style={[
      AssessmentPDFStyles.content_detail,
      { margin: 5, border: 'none', padding: 0 },
      !isPrimary ? { marginLeft: 20 } : { marginTop: 20 },
    ]}
  >
    <Text
      style={[
        AssessmentPDFStyles.content_detail.span,
        { margin: 0 },
        !isPrimary ? { fontSize: 10 } : {},
      ]}
    >
      {label}
    </Text>
    {Array.isArray(value) &&
      value.map((v, i) => <List key={i} value={v} isPrimary={isPrimary} />)}
    {!Array.isArray(value) && (
      <Text
        style={[
          AssessmentPDFStyles.content_detail.p,
          { marginBottom: 0 },
          !isPrimary ? { fontSize: 10 } : {},
        ]}
      >
        {value}
      </Text>
    )}
  </View>
);

FullRowLabelledData.defaultProps = {
  isPrimary: true,
};

FullRowLabelledData.propTypes = {
  isPrimary: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default FullRowLabelledData;
