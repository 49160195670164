import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import Modal from '../../../components/common/Modal';
import styles from './PatientDetailsPage.module.scss';

const sections = [
  {
    title: 'Personal Details',
    cards: [
      {
        id: 'first_name',
        label: 'First Name',
      },
      {
        id: 'last_name',
        label: 'Last Name',
      },
      {
        id: 'allergies',
        label: 'Allergies',
      },
      {
        id: 'relationship_status',
        label: 'Relationship Status',
      },
      {
        id: 'gender',
        label: 'Gender',
      },
      {
        id: 'preferred_pronouns',
        label: 'Preferred pronouns',
      },
      {
        id: 'dob',
        label: 'Date of Birth',
      },
      {
        id: 'age',
        label: 'Age',
      },
      {
        id: 'status',
        label: 'Patient status',
      },
    ],
  },
  {
    title: 'Contact Details',
    cards: [
      {
        id: 'street_address',
        label: 'Street Address',
        fullWidth: true,
      },
      {
        id: 'city',
        label: 'City',
      },
      {
        id: 'state',
        label: 'State',
      },
      {
        id: 'zip',
        label: 'Zip Code',
      },
      {
        id: 'home_phone',
        label: 'Home Phone',
      },
      {
        id: 'phone',
        label: 'Mobile Phone',
      },
      {
        id: 'work_phone',
        label: 'Work Phone',
      },
      {
        id: 'email',
        label: 'Email',
      },
      {
        id: 'contact_method',
        label: 'Preferred method of contact',
      },
    ],
  },
  {
    title: 'Emergency Contact Information',
    cards: [
      {
        id: 'emergency_contact_name',
        label: 'Name',
      },
      {
        id: 'emergency_contact_relationship',
        label: 'Relationship',
      },
      {
        id: 'emergency_contact_phone',
        label: 'Phone',
      },
      {
        id: 'emergency_contact_email',
        label: 'Email',
      },
    ],
  },
  {
    title: 'Medical Records',
    cards: [
      {
        id: 'ssn',
        label: 'SSN',
      },
      {
        id: 'insurance_provider',
        label: 'Primary Insurance Provider',
      },
      {
        id: 'insurance_id',
        label: 'Insurance ID #',
      },
      {
        id: 'name_insured',
        label: 'Name of Insured',
      },
      {
        id: 'group_id',
        label: 'Group ID #',
      },
      {
        id: 'primary_care_doctor',
        label: 'Name of Primary Care Doctor',
      },
      {
        id: 'primary_care_doctor_phone',
        label: 'Doctor Phone',
      },
      {
        id: 'preferred_pharmacy_address',
        label: 'Preferred Pharmacy & Address',
        fullWidth: true,
      },
    ],
  },
  {
    title: 'Billing & Payment Information',
    cards: [
      {
        id: 'billing_street_address',
        label: 'Billing Address',
      },
      {
        id: 'billing_city',
        label: 'Billing City',
      },
      {
        id: 'billing_state',
        label: 'Billing State',
      },
      {
        id: 'billing_zip',
        label: 'Billing Zip',
      },
    ],
  },
  {
    title: 'Current Medications',
    infoTable: {
      id: 'current_medications',
      type: 'table',
      structure: [
        {
          label: 'Name',
          id: 'name',
        },
        {
          label: 'Dose',
          id: 'dose',
        },
        {
          label: 'Frequency',
          id: 'frequency',
        },
      ],
    },
  },
  {
    title: 'Major Illnesses and Injuries',
    infoTable: {
      id: 'illnesses_injuries',
      type: 'table',
      structure: [
        {
          label: 'Description',
          id: 'description',
        },
        {
          label: 'Date',
          id: 'dateOfOnset',
          beforeRender: (data) => moment(data).format('MM/DD/YYYY'),
        },
      ],
    },
  },
  {
    title: 'Alcohol and Drug Use',
    infoTable: {
      id: 'recreationalSubstancesUsage',
      type: 'table',
      structure: [
        {
          label: 'Type',
          id: 'type',
        },
        {
          label: 'Frequency',
          id: 'frequency',
        },
      ],
    },
  },
];

const InfoSection = ({
  dataTestId,
  sectionTitle,
  infoCards,
  infoTable,
  data,
}) => (
  <div className={styles.clientDetailsInfoSectionWrapper}>
    <SectionDivider title={sectionTitle} />
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {infoTable && <Table infoTable={infoTable} data={data} />}
      {infoCards?.map((card, i) => (
        <DataBox
          dataTestId={`${dataTestId}.DataBox`}
          key={i}
          label={card.label}
          value={
            Array.isArray(data[card.id])
              ? data[card.id].join(', ')
              : data[card.id]
          }
          fullWidth={card.fullWidth}
        />
      ))}
    </div>
  </div>
);

const SectionDivider = ({ title }) => (
  <div
    data-testid={`ClientDetailsModal.SectionProvider.SectionTitle[.${title.replace(
      /[\s&]/g,
      '',
    )}`}
    className={styles.clientDetailsSectionTitle}
  >
    {title}
  </div>
);

const DataBox = ({ dataTestId, label, value, fullWidth = false }) => (
  <div
    className={styles.dataBoxWrapper}
    style={{
      width: fullWidth ? '100%' : '50%',
    }}
  >
    <div
      style={{ fontWeight: 'bold' }}
      data-testid={`${dataTestId}.${label.replace(/[\/\s#&]/g, '')}.Label`}
    >
      {label}
    </div>
    <div data-testid={`${dataTestId}.${label.replace(/[\/\s#&]/g, '')}.Value`}>
      {value}
    </div>
  </div>
);

const Table = ({ infoTable, data }) => {
  const { id, structure } = infoTable;
  const tableData = data[id];
  return (
    <div className={styles.patientDetailsTableWrapper}>
      <table className={styles.patientDetailsTable}>
        <thead>
          <tr>
            {structure.map((th, i) => (
              <th
                key={i}
                data-testid={`ClientDetailsModal.TableHead.${th.label.replace(
                  /\s/g,
                  '',
                )}`}
              >
                {th.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((rowData, i) => {
            return (
              <tr key={i}>
                {structure.map((row, i) => {
                  const { id: rowId, beforeRender } = row;
                  const value = beforeRender
                    ? beforeRender(rowData[rowId])
                    : rowData[rowId];
                  return (
                    <td
                      key={i}
                      data-testid={`ClientDetailsModal.TableData.${rowId.replace(
                        /\s/g,
                        '',
                      )}`}
                    >
                      {value || null}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const ClientDetailsModal = ({ setShowClientDetailModal, patient }) => {
  const recreationalSubstancesUsageSection = [
    {
      id: 'tobacco',
      label: 'Tobacco',
    },
    {
      id: 'alcoholic_beverages',
      label: 'Alcohol',
    },
    {
      id: 'cannabis',
      label: 'Cannabis',
    },
    {
      id: 'cocaine',
      label: 'Cocaine',
    },
    {
      id: 'amphetamine_simulants',
      label: 'Amphetamine simulants',
    },
    {
      id: 'inhalants',
      label: 'Inhalants',
    },
    {
      id: 'sedatives_pills',
      label: 'Sedatives or Sleeping Pills',
    },
    {
      id: 'hallucinogens',
      label: 'Hallucinogens',
    },
    {
      id: 'opioids',
      label: 'Opioids',
    },
    {
      id: 'others',
      label: 'Others',
    },
  ];
  const data = {
    first_name: patient?.first_name || '',
    last_name: patient?.last_name || '',
    phone: patient?.phone || '',
    dob: patient?.dob ? moment(patient.dob).format('MM/DD/YYYY') : null,
    age: patient?.dob ? moment().diff(patient.dob, 'years', false) : null,
    status: patient?.status ? patient.status.toLowerCase() : '',
    email: patient?.email || '',
    allergies: patient?.details?.allergies || '',
    gender: patient?.details?.gender || '',
    preferred_pronouns: patient?.details?.preferred_pronouns || '',
    relationship_status: patient?.details?.relationship_status || '',
    street_address: patient?.details?.street_address || '',
    city: patient?.details?.city || '',
    state: patient?.details?.state || '',
    zip: patient?.details?.zip || '',
    home_phone: patient?.details?.home_phone || '',
    work_phone: patient?.details?.work_phone || '',
    contact_method: patient?.details?.contact_method || [],
    emergency_contact_name: patient?.details?.emergency_contact_name || '',
    emergency_contact_relationship:
      patient?.details?.emergency_contact_relationship || '',
    emergency_contact_phone: patient?.details?.emergency_contact_phone || '',
    emergency_contact_email: patient?.details?.emergency_contact_email || '',
    ssn: patient?.details?.ssn || '',
    insurance_provider: patient?.details?.insurance_provider || '',
    insurance_id: patient?.details?.insurance_id || '',
    name_insured: patient?.details?.name_insured || '',
    group_id: patient?.details?.group_id || '',
    primary_care_doctor: patient?.details?.primary_care_doctor || '',
    primary_care_doctor_phone:
      patient?.details?.primary_care_doctor_phone || '',
    preferred_pharmacy_address:
      patient?.details?.preferred_pharmacy_address || '',
    billing_street_address: patient?.details?.billing_street_address || '',
    billing_city: patient?.details?.billing_city || '',
    billing_state: patient?.details?.billing_state || '',
    billing_zip: patient?.details?.billing_zip || '',
    current_medications: patient?.details?.current_medications || [],
    illnesses_injuries: patient?.details?.illnesses_injuries || [],
    recreationalSubstancesUsage: [
      ...recreationalSubstancesUsageSection.reduce((acc, item) => {
        if (patient?.details && patient.details[item.id]) {
          acc.push({
            type: item.label,
            frequency: patient?.details[item.id] || '',
          });
        }
        return acc;
      }, []),
    ],
  };
  return (
    <Modal className={styles.clientDetailsModal}>
      <div className={styles.clientDetails}>
        <div className={styles.clientDetailsHeader}>
          <h1
            data-testid="ClientDetailsModal.ClientDetailsTitle"
            className={styles.clientDetailsTitle}
          >
            Client Details
          </h1>
          <div
            className={styles.closeButton}
            onClick={() => setShowClientDetailModal(false)}
          >
            <img
              data-testid="ClientDetailsModal.CloseIcon"
              src={require(`../../../img/close.svg`)}
              alt={`Close`}
            />
          </div>
        </div>
        <div className={styles.clientDetailsModalContent}>
          {sections.map((section, i) => (
            <InfoSection
              dataTestId={`ClientDetailsModal.InfoSection.${section[
                'title'
              ].replace(/\s/g, '')}`}
              key={i}
              data={data}
              sectionTitle={section.title}
              infoCards={section.cards}
              infoTable={section.infoTable}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

ClientDetailsModal.propTypes = {
  setShowClientDetailModal: PropTypes.func,
  patient: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone: PropTypes.string,
    dob: PropTypes.string,
    email: PropTypes.string,
    allergies: PropTypes.string,
    gender: PropTypes.string,
    preferred_pronouns: PropTypes.string,
    relationship_status: PropTypes.string,
    street_address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    home_phone: PropTypes.string,
    work_phone: PropTypes.string,
    contact_method: PropTypes.array,
    emergency_contact_name: PropTypes.string,
    emergency_contact_relationship: PropTypes.string,
    emergency_contact_phone: PropTypes.string,
    emergency_contact_email: PropTypes.string,
    ssn: PropTypes.string,
    insurance_provider: PropTypes.string,
    insurance_id: PropTypes.string,
    name_insured: PropTypes.string,
    group_id: PropTypes.string,
    primary_care_doctor: PropTypes.string,
    primary_care_doctor_phone: PropTypes.string,
    preferred_pharmacy_address: PropTypes.string,
    billing_street_address: PropTypes.string,
    billing_city: PropTypes.string,
    billing_state: PropTypes.string,
    billing_zip: PropTypes.string,
    current_medications: PropTypes.array,
    illnesses_injuries: PropTypes.array,
    tobacco: PropTypes.string,
    alcoholic_beverages: PropTypes.string,
    cannabis: PropTypes.string,
    cocaine: PropTypes.string,
    amphetamine_simulants: PropTypes.string,
    inhalants: PropTypes.string,
    sedatives_pills: PropTypes.string,
    hallucinogens: PropTypes.string,
    opioids: PropTypes.string,
    others: PropTypes.string,
  }),
};

export default ClientDetailsModal;
