/* eslint-disable no-unused-expressions */
import React, {
  useState,
  useContext,
  useEffect,
  Fragment,
  useRef,
} from 'react';
import styles from './ResearcherRegistration.module.scss';
import { Formik, Form, Field } from 'formik';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import { Cell, Grid, Column } from 'react-foundation';
import FieldInput from '../../../common/fields/FieldInput/FieldInput';
import messages from './messages';
import useIntl from '../../../../hooks/useIntl';
import Loading from '../../../common/Loading';
import { useHistory } from 'react-router-dom';
import Modal from '../../../common/Modal';
import 'react-image-crop/dist/ReactCrop.css';
import RadioButton from '../../../common/inputs/RadioButton';
import AuthContext from '../../../../context/AuthContext';

import {
  getResearcherInvitationData,
  addLab,
  getLabs,
  updateLab,
  saveResearcherRegistration,
} from '../../../../api/researcher';
import { getLoggedInUser } from '../../../../api/auth';
import LabeledCheckbox from '../../../common/inputs/LabeledCheckbox';
import * as Yup from 'yup';
import data from './data.json';

const { degreeList } = data;

const ACCOUNT_TYPE = {
  MEMBER: 'Member',
  SOLO: 'Solo',
  OWNER: 'Owner',
  DIRECTOR: 'DIRECTOR',
};

const LabSchema = Yup.object().shape({
  lab_name: Yup.string().required('Field Required'),
  email: Yup.string().required('Field Required').email(),
  phone: Yup.string()
    .required('Field Required')
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Please enter valid phone number',
    ),
  address: Yup.string().required('Field is Required'),
  city: Yup.string().required('Field is Required'),
  state: Yup.string().required('Field is Required'),
  zip: Yup.string().required('Field is Required'),
});

const ResearcherRegistration = () => {
  const { formatMessage } = useIntl();
  const [dataLoading, setLoading] = useState(false);
  const [showAddLab, setShowAddLab] = useState(false);
  const { authUser, dispatch } = useContext(AuthContext);
  const history = useHistory();
  const [, setShowGroupName] = useState(false);
  const [labOwner, setLabOwner] = useState([]);
  const [invitedResearcherWithLab, setInvitedResearcherWithLab] = useState('');

  const researcherSchema = Yup.object().shape({
    first_name: Yup.string().required('Field is Required'),
    last_name: Yup.string().required('Field is Required'),
    degrees: Yup.array()
      .min(1, 'Field is Required')
      .required('Field is Required'),
    contact_email: Yup.string()
      .nullable()
      .email('Invalid email address')
      .required('Field is Required'),
    professionalAffiliation: Yup.string().required('Field is Required'),
    group: Yup.string().required('Field is Required'),
    labName: Yup.string().required('Field is Required'),
  });

  const [initialRegistrationValues, setInitialRegistrationValues] = useState({
    first_name: '',
    last_name: '',
    contact_email: '',
    degrees: [],
    professionalAffiliation: '',
    group: '',
    labName: '',
  });
  const formikRef = useRef();

  const fetchLabs = async () => {
    if (authUser.id) {
      setLoading(true);
      const labsData = await getLabs();
      const owner = labsData.labs.filter(
        (element) => element.researcher_id === authUser.id,
      );
      if (owner) {
        setLabOwner(owner);
      }
      setLoading(false);
    }
  };

  const getLabInvitationData = async () => {
    try {
      setLoading(true);
      if (authUser.email) {
        const invitedResearcherWithLab = await getResearcherInvitationData(
          authUser.email,
        );
        setInvitedResearcherWithLab(invitedResearcherWithLab.lab);
        if (
          invitedResearcherWithLab.lab &&
          invitedResearcherWithLab.lab.user.isOwner
        ) {
          setInitialRegistrationValues((prev) => ({
            ...prev,
            group: ACCOUNT_TYPE.OWNER,
          }));
        } else if (
          invitedResearcherWithLab.lab &&
          !invitedResearcherWithLab.lab.user.isOwner
        ) {
          setInitialRegistrationValues((prev) => ({
            ...prev,
            group: ACCOUNT_TYPE.MEMBER,
          }));
        }
        setInitialRegistrationValues((prev) => ({
          ...prev,
          contact_email: authUser.email,
        }));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchLabs();
    getLabInvitationData();
  }, [authUser]);

  const submitHandler = async (values) => {
    values.researcher_id = authUser.id;
    if (values.group === ACCOUNT_TYPE.MEMBER && invitedResearcherWithLab) {
      values.selected_lab = invitedResearcherWithLab.lab_name;
      values.lab_id = invitedResearcherWithLab.id;
      values.lab_owner = null;
      values.work_type = 'Group(s)';
    }
    if (values.group === ACCOUNT_TYPE.DIRECTOR) {
      if (labOwner.length > 0) {
        values.selected_lab = labOwner[0].lab_name;
        values.lab_id = labOwner[0].id;
        values.lab_owner = labOwner[0].researcher_id;
        values.work_type = 'Group(s)';
      } else {
        setShowAddLab(true);
      }
    }
    if (values.group === ACCOUNT_TYPE.SOLO) {
      values.selected_lab = null;
      values.lab_id = 0;
      values.lab_owner = null;
      values.work_type = 'Solo';
    }
    if (values.group === ACCOUNT_TYPE.OWNER) {
      values.selected_lab = invitedResearcherWithLab.lab_name;
      values.lab_id = invitedResearcherWithLab.id;
      values.lab_owner = authUser.id;
      values.work_type = 'Group(s)';
    }
    try {
      if (values.lab_id !== null) {
        setLoading(true);
        if (values.group === ACCOUNT_TYPE.OWNER) {
          await updateLab(invitedResearcherWithLab.id, {
            researcher_id: authUser.id,
          });
        }
        const researcherRegistration = await saveResearcherRegistration(values);
        await getLoggedInUser(dispatch);
        if (researcherRegistration) {
          setLoading(false);
          history.push('/researcher/dashboard');
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className={styles.root}>
      {dataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.form}>
            <Grid>
              <Cell small={12} medium={12}>
                <h2
                  className={styles.formTitle}
                  data-testid="researcher registration title"
                >
                  Researcher Registration
                </h2>
              </Cell>
            </Grid>
            <Formik
              enableReinitialize
              validationSchema={researcherSchema}
              initialValues={initialRegistrationValues}
              onSubmit={(values) => submitHandler(values)}
              innerRef={formikRef}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => (
                <Form>
                  <Grid>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(
                          messages.contactDetails.firstNameLabel,
                        )}
                        errorText={touched.first_name && errors.first_name}
                        name="first_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name}
                        data-testid="researcher first name"
                        label-testid="researcher first name label"
                        error-testid="researcher first name error"
                      />
                    </Cell>
                    <Cell small={12} medium={12}>
                      <FieldInput
                        label={formatMessage(
                          messages.contactDetails.LastNameLabel,
                        )}
                        errorText={touched.last_name && errors.last_name}
                        name="last_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.last_name}
                        data-testid="researcher last name"
                        label-testid="researcher last name label"
                        error-testid="researcher last name error"
                      />
                    </Cell>

                    <Cell small={12} medium={12} className={styles['radioBtn']}>
                      <h3 data-testid="researcher degrees label">
                        Please indicate which of the following types of account
                        you want to create
                        <div
                          className={styles.typeError}
                          error-testid="researcher degrees error"
                        >
                          {touched.degrees && errors && errors.degrees}
                        </div>
                      </h3>
                      {degreeList.value.map((choice) => (
                        <Column
                          small={12}
                          medium={7}
                          key={choice.key}
                          className={styles['grid-cell']}
                        >
                          <LabeledCheckbox
                            checkboxKey={choice.key}
                            name="degrees"
                            labelName={choice.title}
                            value={choice.key}
                            checked={values.degrees.includes(choice.key)}
                            data-testid={`researcher degrees checkbox ${choice.title}`}
                            onChange={(e) => {
                              const { checked, value } = e.target;
                              if (checked) {
                                setFieldValue('degrees', [
                                  ...values.degrees,
                                  value,
                                ]);
                              } else {
                                setFieldValue(
                                  'degrees',
                                  values.degrees.filter((v) => v !== value),
                                );
                              }
                            }}
                          />
                        </Column>
                      ))}
                    </Cell>
                    <Cell small={12} medium={12} className={styles['radioBtn']}>
                      <FieldInput
                        name="professionalAffiliation"
                        label="University or Professional Affiliation"
                        value={values.professionalAffiliation}
                        errorText={
                          touched.professionalAffiliation &&
                          errors.professionalAffiliation
                        }
                        onChange={(e) => {
                          setFieldValue(
                            'professionalAffiliation',
                            e.target.value,
                          );
                        }}
                        onBlur={handleBlur}
                        data-testid={`researcher professionalAffiliation other`}
                        label-testid={`researcher professionalAffiliation label`}
                        error-testid={`researcher professionalAffiliation error`}
                      />
                    </Cell>
                    <Cell small={12} medium={12} className={styles['radioBtn']}>
                      <h3 data-testid={`researcher account type`}>
                        {formatMessage(messages.accountType)}
                        <div
                          className={styles.typeError}
                          error-testid={`researcher account type error`}
                        >
                          {touched.group && errors && errors.group}
                        </div>
                      </h3>
                      {invitedResearcherWithLab &&
                        !invitedResearcherWithLab.user.isOwner && (
                          <Field
                            component={(field) => (
                              <RadioButton {...field} name="account_type" />
                            )}
                            value={ACCOUNT_TYPE.MEMBER}
                            htmlFor={ACCOUNT_TYPE.MEMBER}
                            checked={ACCOUNT_TYPE.MEMBER === values.group}
                            name="account_type"
                            onChange={(value) => {
                              setFieldValue('group', value);
                              setShowGroupName(false);
                            }}
                            labelName={`Member of ${
                              invitedResearcherWithLab &&
                              invitedResearcherWithLab.lab_name
                            }`}
                            data-testid={`researcher account type member`}
                          />
                        )}
                      {(!invitedResearcherWithLab ||
                        (invitedResearcherWithLab &&
                          !invitedResearcherWithLab.user.isOwner)) && (
                        <>
                          <Field
                            component={(field) => (
                              <RadioButton {...field} name="account_type" />
                            )}
                            value={ACCOUNT_TYPE.SOLO}
                            htmlFor={ACCOUNT_TYPE.SOLO}
                            checked={ACCOUNT_TYPE.SOLO === values.group}
                            name="account_type"
                            onChange={(value) => {
                              setFieldValue('group', value);
                              setShowGroupName(false);
                            }}
                            labelName={`Solo Practice`}
                            data-testid={`researcher account type solo practice`}
                          />
                          {/* TODO:  re-enable this when functional  */}
                          {/* <Field
                            component={(field) => (
                              <RadioButton {...field} name="account_type" />
                            )}
                            value={ACCOUNT_TYPE.DIRECTOR}
                            htmlFor={ACCOUNT_TYPE.DIRECTOR}
                            checked={ACCOUNT_TYPE.DIRECTOR === values.group}
                            name="account_type"
                            onChange={(value) => {
                              setFieldValue('group', value);
                              setShowAddLab(true);
                              setShowGroupName(true);
                            }}
                            labelName={`New Lab or Director`}
                            data-testid={`researcher account type new lab or director`}
                          /> */}
                        </>
                      )}
                      {invitedResearcherWithLab &&
                        invitedResearcherWithLab.user.isOwner && (
                          <Field
                            component={(field) => (
                              <RadioButton {...field} name="account_type" />
                            )}
                            value={ACCOUNT_TYPE.OWNER}
                            htmlFor={ACCOUNT_TYPE.OWNER}
                            checked={ACCOUNT_TYPE.OWNER === values.group}
                            name="account_type"
                            onChange={(value) => {
                              setFieldValue('group', value);
                              setShowGroupName(false);
                            }}
                            disabled
                            labelName={`Owner of ${
                              invitedResearcherWithLab &&
                              invitedResearcherWithLab.lab_name
                            }`}
                            data-testid={`researcher account type owner of ${
                              invitedResearcherWithLab &&
                              invitedResearcherWithLab.lab_name
                            }`}
                          />
                        )}
                    </Cell>
                    <Cell
                      small={12}
                      medium={12}
                      className={styles['grid-cell']}
                    >
                      <FieldInput
                        name="labName"
                        label="What is the name of you lab?"
                        value={values.labName}
                        errorText={touched.labName && errors.labName}
                        onChange={(e) => {
                          setFieldValue('labName', e.target.value);
                        }}
                        onBlur={handleBlur}
                        data-testid={`Researcher labName other`}
                        label-testid={`Researcher labName label`}
                        error-testid={`Researcher labName error`}
                      />
                    </Cell>
                    <Cell
                      small={12}
                      medium={12}
                      className={styles['grid-cell']}
                    >
                      <FieldInput
                        autoComplete="contact_email"
                        label="What is the best email for research participants to use to contact you or your lab?"
                        errorText={
                          touched.contact_email && errors.contact_email
                        }
                        name="contact_email"
                        onChange={handleChange}
                        type="email"
                        onBlur={handleBlur}
                        value={values.contact_email}
                        data-testid="researcher email"
                        label-testid="researcher email label"
                        error-testid="researcher email error"
                      />
                    </Cell>

                    <PrimaryButton
                      disabled={Object.entries(errors).length > 0}
                      label="Submit"
                      type="submit"
                      className="font-18"
                      data-testid={`Researcher submit button`}
                    />
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </Fragment>
      )}
      {showAddLab && (
        <Modal className={styles.addClinicmodal}>
          <div className={styles.content}>
            <div
              className={styles.closeButton}
              onClick={() => {
                setShowAddLab(false);
              }}
            >
              <img src={require(`../../../../img/close.svg`)} alt={`Close`} />
            </div>
            <h1>
              <b data-testid={`researcher create new research lab`}>
                Create New Research Lab
              </b>
            </h1>
            <Formik
              initialValues={{
                lab_name: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                phone: '',
                email: '',
                description: '',
                researcher_id: authUser.id,
              }}
              validationSchema={LabSchema}
              onSubmit={async (values) => {
                await addLab(values);
                fetchLabs();
                setShowAddLab(false);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => {
                return (
                  <Form>
                    <Grid>
                      <Cell small={12}>
                        <FieldInput
                          autoComplete="lab_name"
                          autoFocus={true}
                          label="Lab Name"
                          errorText={touched.lab_name && errors.lab_name}
                          name="lab_name"
                          value={values.lab_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          data-testid="researcher add new lab name"
                          label-testid="researcher add new lab name label"
                          error-testid="researcher add new lab name error"
                        />
                      </Cell>
                      <Cell small={12}>
                        <FieldInput
                          autoComplete="email"
                          label="Email"
                          errorText={touched.email && errors.email}
                          name="email"
                          onChange={handleChange}
                          type="email"
                          onBlur={handleBlur}
                          value={values.email}
                          data-testid="researcher add new email"
                          label-testid="researcher add new email label"
                          error-testid="researcher add new email error"
                        />
                      </Cell>
                      <Cell small={12}>
                        <FieldInput
                          autoComplete="phone"
                          label="phone"
                          errorText={touched.phone && errors.phone}
                          name="phone"
                          onChange={handleChange}
                          value={values.phone}
                          onBlur={handleBlur}
                          data-testid="researcher add new phone"
                          label-testid="researcher add new phone label"
                          error-testid="researcher add new phone error"
                        />
                      </Cell>
                      <Cell small={12}>
                        <FieldInput
                          autoComplete="address"
                          label="Address"
                          errorText={touched.address && errors.address}
                          name="address"
                          onChange={handleChange}
                          value={values.address}
                          onBlur={handleBlur}
                          data-testid="researcher add new address"
                          label-testid="researcher add new address label"
                          error-testid="researcher add new address error"
                        />
                      </Cell>
                      <Cell small={12}>
                        <FieldInput
                          autoComplete="city"
                          label="City"
                          errorText={touched.city && errors.city}
                          name="city"
                          onChange={handleChange}
                          value={values.city}
                          onBlur={handleBlur}
                          data-testid="researcher add new city"
                          label-testid="researcher add new city label"
                          error-testid="researcher add new city error"
                        />
                      </Cell>
                      <Cell small={12}>
                        <FieldInput
                          label="State"
                          errorText={touched.state && errors.state}
                          name="state"
                          onChange={handleChange}
                          value={values.state}
                          onBlur={handleBlur}
                          data-testid="researcher add new state"
                          label-testid="researcher add new state label"
                          error-testid="researcher add new state error"
                        />
                      </Cell>
                      <Cell small={12}>
                        <FieldInput
                          autoComplete="zip"
                          label="Zip"
                          errorText={touched.zip && errors.zip}
                          name="zip"
                          onChange={handleChange}
                          value={values.zip}
                          onBlur={handleBlur}
                          data-testid="researcher add new zip"
                          label-testid="researcher add new zip label"
                          error-testid="researcher add new zip error"
                        />
                      </Cell>
                      <Cell small={12}>
                        <FieldInput
                          autoComplete="description"
                          label="Description"
                          errorText={errors.description}
                          name="description"
                          onChange={handleChange}
                          value={values.description}
                          onBlur={handleBlur}
                          data-testid="researcher add new description"
                          label-testid="researcher add new description label"
                          error-testid="researcher add new description error"
                        />
                      </Cell>

                      <Cell small={12}>
                        <PrimaryButton
                          label="Save"
                          data-testid="researcher add new save button"
                        />
                      </Cell>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ResearcherRegistration;
