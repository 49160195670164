import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import styles from './RoundCheckBox.module.scss';

const isAvailableType = (type) => ['benefits', 'categories'].includes(type);

const RoundCheckBox = ({ label, onClick, icon, isActive, theme, type }) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div
        className={classnames(styles.box, {
          [styles['box-active']]: isActive,
          [styles[theme]]: !isAvailableType(type),
        })}
        data-testid="box-div"
      >
        {isAvailableType(type) && icon}
      </div>
      <div className={styles['box-name']}>{label}</div>
    </div>
  );
};

RoundCheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  theme: PropTypes.oneOf(['gray', 'white']),
};

RoundCheckBox.defaultProps = {
  isActive: false,
  theme: 'white',
};

export default RoundCheckBox;
