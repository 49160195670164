import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const BoxContext = createContext(false);

export const BoxProvider = ({ children }) => {
  const [isBoxOpen, setIsBoxOpen] = useState(false);
  useEffect(() => {
    return isBoxOpen
      ? document.body.classList.add('no-scroll')
      : document.body.classList.remove('no-scroll');
  }, [isBoxOpen]);
  return (
    <BoxContext.Provider
      value={{
        isBoxOpen,
        setIsBoxOpen,
      }}
    >
      {children}
    </BoxContext.Provider>
  );
};

BoxContext.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default BoxContext;
