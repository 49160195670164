import React, { Fragment, useState, useContext, useEffect } from 'react';
import LeftNav from '../nav/LeftNav';
import styles from './ViewProfile.module.scss';
import classnames from 'classnames';
import AuthContext from '../../context/AuthContext';
import Loading from '../../components/common/Loading';
import { getClinicianProfile } from '../../api/doctor';
import { useParams } from 'react-router-dom';
import location from '../../img/location.svg';
import inbox from '../../img/inbox.svg';
import DoctorLeftNav from '../nav/DoctorLeftNav';
import { Link } from 'react-router-dom';
import Back from '../../img/icons/back.svg';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import { useHistory } from 'react-router-dom';
import { USER_TYPES } from '../../constants';

const ViewProfile = () => {
  const { isLoading, authUser } = useContext(AuthContext);
  const [dataLoading, setLoading] = useState(false);
  const isDataLoading = dataLoading || isLoading;
  const [clinician, setClinician] = useState({});
  const param = useParams();
  const history = useHistory();
  const [certification, setCertification] = useState();

  const fetchClinicianProfile = async (id) => {
    try {
      const { clinicianProfile } = await getClinicianProfile(id);
      setClinician(clinicianProfile);

      const certification =
        clinicianProfile.clinician_registration &&
        clinicianProfile.clinician_registration.certification.split(',');
      let index = certification.findIndex((x) => x === 'Other');
      if (index >= 0) {
        certification.splice(
          index,
          1,
          clinicianProfile &&
            clinicianProfile.clinician_registration &&
            clinicianProfile.clinician_registration.other_certification,
        );
      }
      setCertification(certification.toString());
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (param.id) {
      setLoading(true);
      fetchClinicianProfile(param.id);
    }
  }, [param]);

  const getSpecialization = (treatment, therapy) => {
    let specialization = [];
    if (treatment) {
      specialization = treatment.split(',');
    }
    if (therapy) {
      specialization = [...specialization, ...therapy.split(',')];
    }
    return specialization.map((treatment) => (
      <p key={treatment} className={styles.textBackground}>
        {treatment}
      </p>
    ));
  };

  const getDescription = (description) => {
    const desc = description.replace(/\r?\n/g, '<br />');
    return desc;
  };
  return (
    <div className={styles.container}>
      {authUser.profile_type === USER_TYPES.DOCTOR ? (
        <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
          <DoctorLeftNav />
        </div>
      ) : (
        <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
          <LeftNav />
        </div>
      )}

      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.root}>
            <div className={styles.header}>
              <div className={styles.backDiv}>
                <Link
                  to={
                    authUser.profile_type === USER_TYPES.DOCTOR
                      ? '/doctor/my-account'
                      : '/my-account'
                  }
                  data-testid="view_profile back"
                >
                  <img src={Back} alt="Back" />
                </Link>
                <h1 data-testid="view_profile">Profile</h1>
              </div>
              {clinician &&
                clinician.id &&
                authUser.profile_type === USER_TYPES.PATIENT && (
                  <PrimaryButton
                    label={`Book Appointment`}
                    className={styles.button}
                    onClick={() =>
                      history.push(`/my-account/appointment/${clinician.id}`)
                    }
                    data-testid="view_profile bookappointment"
                  />
                )}
            </div>
            <div className={styles.profileDiv}>
              <div className={styles.detailsDiv}>
                <div className={styles.clinicianHead}>
                  <div className={styles.profilePic}>
                    {clinician && (
                      <img
                        src={
                          clinician.profile_pic
                            ? clinician.profile_pic
                            : require('../../img/doctor.jpg')
                        }
                        alt="clinician_profile"
                        data-testid="view_profile profiepic"
                      />
                    )}
                  </div>
                  <div className={styles.clinicianTitle}>
                    <h6
                      className={styles.fieldTitle}
                      data-testid="view_profile name"
                    >
                      {clinician.first_name} {clinician.last_name}
                    </h6>
                    <div className={styles.certification}>
                      <p data-testid="view_profile certification">
                        {certification}
                      </p>
                    </div>
                    {clinician.clinician_registration &&
                      clinician.clinician_registration.city && (
                        <div className={styles.locationDiv}>
                          <img src={location} alt="location" />
                          <p data-testid="view_profile location">
                            {clinician.clinician_registration &&
                              clinician.clinician_registration.city}
                          </p>
                        </div>
                      )}
                    {clinician.clinician_registration &&
                      clinician.clinician_registration.contact_email && (
                        <div className={styles.locationDiv}>
                          <img src={inbox} alt="inbox" />
                          <p data-testid="view_profile contact_email">
                            <a
                              href={`mailto: ${clinician.clinician_registration.contact_email}`}
                            >
                              {clinician.clinician_registration &&
                                clinician.clinician_registration.contact_email}
                            </a>
                          </p>
                        </div>
                      )}
                  </div>
                </div>
                {clinician &&
                clinician.clinician_registration &&
                clinician.clinician_registration.clinic_licence_states &&
                clinician.clinician_registration.clinic_licence_states.length >
                  0 ? (
                  <div className={styles.licenseDiv}>
                    <div className={styles.stateDiv}>
                      <h6 data-testid="view_profile state label">State</h6>
                      {clinician &&
                        clinician.clinician_registration &&
                        clinician.clinician_registration
                          .clinic_licence_states &&
                        clinician.clinician_registration.clinic_licence_states.map(
                          (license) => (
                            <p
                              key={license.state}
                              data-testid="view_profile state value"
                            >
                              {license.state}
                            </p>
                          ),
                        )}
                    </div>
                    <div className={styles.licenseNumberDiv}>
                      <h6 data-testid="view_profile license_number label">
                        License number
                      </h6>
                      {clinician &&
                        clinician.clinician_registration &&
                        clinician.clinician_registration
                          .clinic_licence_states &&
                        clinician.clinician_registration.clinic_licence_states.map(
                          (license) => (
                            <p
                              key={license.license_number}
                              data-testid="view_profile license_number value"
                            >
                              {license.license_number}
                            </p>
                          ),
                        )}
                    </div>
                  </div>
                ) : (
                  <div className={styles.unLicenseDiv}>
                    <p data-testid="view_profile license_number value">
                      Unlicensed
                    </p>
                  </div>
                )}
              </div>
              <div className={styles.infoDiv}>
                <div className={styles.fieldHead}>
                  <h6
                    className={styles.fieldTitle}
                    data-testid="view_profile About label"
                  >
                    About
                  </h6>
                </div>
                <div className={styles.innerDiv}>
                  {clinician && clinician.description && (
                    <p
                      data-testid="view_profile About value"
                      className={styles.fieldValue}
                      dangerouslySetInnerHTML={{
                        __html: getDescription(clinician.description),
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={styles.infoDiv}>
                <div className={styles.fieldHead}>
                  <h6
                    className={styles.fieldTitle}
                    data-testid="view_profile Specialization label"
                  >
                    Specialization
                  </h6>
                </div>
                <div
                  className={styles.innerDiv}
                  data-testid="view_profile Specialization value"
                >
                  {clinician &&
                    clinician.clinician_registration &&
                    (clinician.clinician_registration.treatment ||
                      clinician.clinician_registration.therapy) &&
                    getSpecialization(
                      clinician.clinician_registration.treatment,
                      clinician.clinician_registration.therapy,
                    )}
                </div>
              </div>
              <div className={styles.infoDiv}>
                <div className={styles.fieldHead}>
                  <h6
                    className={styles.fieldTitle}
                    data-testid="view_profile clinic label"
                  >
                    Clinic
                  </h6>
                </div>
                <div className={styles.innerDiv}>
                  {clinician && clinician.clinician_registration && (
                    <p
                      className={styles.textBackground}
                      data-testid="view_profile clinic value"
                    >
                      {clinician.clinician_registration.clinic_name}
                    </p>
                  )}
                </div>
              </div>
              <div className={styles.infoDiv}>
                <div className={styles.fieldHead}>
                  <h6
                    className={styles.fieldTitle}
                    data-testid="view_profile Insurance label"
                  >
                    Insurance
                  </h6>
                </div>
                <div className={styles.stateAndProvider}>
                  <div className={styles.stateAndProviderHeaders}>
                    <div>
                      <h6
                        className={styles.fieldTitle}
                        data-testid="view_profile Insurance state label"
                      >
                        State
                      </h6>
                    </div>
                    <div>
                      <h6
                        className={styles.fieldTitle}
                        data-testid="view_profile Insurance Provider label"
                      >
                        Provider
                      </h6>
                    </div>
                  </div>

                  {clinician &&
                    clinician.clinician_registration &&
                    clinician.clinician_registration.clinic_licence_states &&
                    clinician.clinician_registration.clinic_licence_states.map(
                      (license) => (
                        <div className={styles.stateAndProvider}>
                          <div className={styles.stateAndProviderRow}>
                            <div>
                              <div
                                className={styles.fieldValue}
                                data-testid="view_profile Insurance state value"
                              >
                                {license.state}
                              </div>
                            </div>
                            <div>
                              {license.insurance_network &&
                                Array.isArray(license.insurance_network) &&
                                license.insurance_network.map((network) => (
                                  <>
                                    {network === 'None' ? (
                                      <p
                                        className={styles.fieldValue}
                                        data-testid="view_profile Insurance Provider value"
                                      >
                                        Direct Pay Only
                                      </p>
                                    ) : (
                                      <p
                                        className={styles.fieldValue}
                                        data-testid="view_profile Insurance Provider value"
                                      >
                                        {network}
                                      </p>
                                    )}
                                  </>
                                ))}
                            </div>
                          </div>
                        </div>
                      ),
                    )}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default ViewProfile;
