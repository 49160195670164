import React, {
  useState,
  Fragment,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { useHistory } from 'react-router';
import { Cell, Grid } from 'react-foundation';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';

import Loading from '../../../components/common/Loading';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import FieldInput from '../../../components/common/fields/FieldInput/FieldInput';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import MultiSelectDropdown from '../../../components/common/inputs/MultiSelectDropdown/MultiSelectDropdown';
import MessageModal from '../../../components/MessageModal';
import DoctorLeftNav from '../../../components/nav/DoctorLeftNav';

import { getClinicAssociatedFormsData } from '../../../api/intakeForm';
import { createProtocolTemplate } from '../../../api/doctor';

import styles from './CreateProtocolTemplate.module.scss';
import AuthContext from '../../../context/AuthContext';

import { FORM } from '../../../constants';

const createTemplateSchema = Yup.object().shape({
  template_name: Yup.string().required('Field Required'),
  forms: Yup.array().min(1).required('Forms Field Must Have At Least 1 Item '),
});

const CreateProtocolTemplate = () => {
  const history = useHistory();
  const { isLoading, authUser } = useContext(AuthContext);

  const [dataLoading, setLoading] = useState(false);
  const [formattedFormLists, setFormattedFormLists] = useState([]);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [displayMessage, setDisplayMessage] = useState({
    title: '',
    message: '',
  });
  const isDataLoading = dataLoading || isLoading;

  const fetchFormLists = useCallback(() => {
    const me = async () => {
      try {
        setLoading(true);
        const params = {
          clinic_id: authUser.clinic_id || 0,
        };
        const lists = await getClinicAssociatedFormsData(params);

        const formattedLists = lists
          .filter((form) => {
            if (
              form.dim_form?.form_type === FORM.TYPE.INTAKE &&
              !authUser?.billing_plan?.intake_forms
            ) {
              return false;
            }

            if (
              form.dim_form?.form_type === FORM.TYPE.ASSESSMENT &&
              !authUser?.billing_plan?.assessments
            ) {
              return false;
            }

            return true;
          })
          .map((form) => {
            return {
              label: form.dim_form.form_name,
              value: form.dim_form.id,
            };
          })
          .sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });

        setFormattedFormLists(formattedLists);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    me();
  }, [authUser.clinic_id, authUser.id]);

  const onCreateProtocolTemplate = async (values) => {
    try {
      setLoading(true);
      const forms = values.forms.map((form) => form.value);
      const body = {
        name: values.template_name,
        created_by: authUser.id,
        forms,
      };
      await createProtocolTemplate(body);
      setLoading(false);
      setDisplayMessage({
        type: 'success',
        title: 'Done',
        text: 'Protocol template successfully saved',
      });
      setShowMessageModal(true);
      history.goBack();
    } catch (error) {
      setLoading(false);
      setDisplayMessage({
        type: 'error',
        title: 'whoops!',
        text: 'Failed to create template',
      });
      setShowMessageModal(true);
    }
  };

  useEffect(() => {
    if (authUser.id) {
      fetchFormLists();
    }
  }, [authUser, fetchFormLists]);

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <DoctorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div className={styles.header}>
              <h1 data-testid="create_template header">
                Create Protocol Template
              </h1>
            </div>
            <Formik
              initialValues={{
                template_name: '',
                forms: [],
              }}
              validationSchema={createTemplateSchema}
              onSubmit={onCreateProtocolTemplate}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form className={styles.form}>
                    <div>
                      <Grid>
                        <Cell small={12} large={3}>
                          <FieldInput
                            autoComplete="template_name"
                            autoFocus={true}
                            label="Name"
                            errorText={
                              touched.template_name && errors.template_name
                            }
                            name="template_name"
                            value={values.template_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            data-testid="template_name"
                            label-testid="template_name label"
                            error-testid="template_name error"
                          />
                        </Cell>
                      </Grid>
                      <Grid>
                        <Cell small={12} large={4}>
                          <div className={styles.card}>
                            <div className={styles.cardTitle}>
                              <p data-testid="template_form label">
                                Forms and Protocols
                              </p>
                            </div>
                            <Grid>
                              <Cell
                                small={12}
                                medium={12}
                                className={
                                  (styles['label-bold'], styles.inputCell)
                                }
                              >
                                <div>
                                  <MultiSelectDropdown
                                    isMulti
                                    label="Name"
                                    errorText={touched.forms && errors.forms}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    isSearchable
                                    value={values.forms}
                                    options={formattedFormLists}
                                    name="forms"
                                    onChange={(selectedValues) => {
                                      handleChange(selectedValues);
                                      setFieldValue('forms', selectedValues);
                                    }}
                                    onBlur={handleBlur}
                                    classNamePrefix="select"
                                    placeholder="Select..."
                                    data-testid="template_forms"
                                  />
                                </div>
                              </Cell>
                            </Grid>
                          </div>
                        </Cell>
                      </Grid>
                    </div>
                    <div>
                      <Grid>
                        <Cell
                          small={12}
                          medium={12}
                          large={12}
                          flexContainer
                          alignY="middle"
                          className={styles['actions-container']}
                        >
                          <SecondaryButton
                            label="Back"
                            type="button"
                            className={styles.editButton}
                            onClick={() => history.goBack()}
                            data-testid="template_back button"
                          />
                          <PrimaryButton
                            label="Save as template"
                            type="button"
                            className={styles.editButton}
                            onClick={handleSubmit}
                            data-testid="Save template button"
                          />
                        </Cell>
                      </Grid>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Fragment>
      )}
      {showMessageModal && (
        <MessageModal
          onCloseModal={() => setShowMessageModal(false)}
          message={displayMessage}
          displayLogo={false}
        />
      )}
    </div>
  );
};

export default CreateProtocolTemplate;
