import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './CircleButtonRadio.module.scss';

const CircleButtonRadio = ({
  title,
  onClick,
  isSelected,
  'data-testid': datatestid,
}) => (
  <div
    onClick={onClick}
    className={classnames(styles['box-container'], {
      [styles['is-selected']]: isSelected,
    })}
    data-testid={datatestid}
  >
    <div className={classnames(styles['circle'])}>
      <div
        className={classnames(styles['circle-small'], {
          [styles['is-selected']]: isSelected,
        })}
      ></div>
    </div>
    <div className={styles['text-container']}>
      <div className={styles['button-title']}>{title}</div>
    </div>
  </div>
);

CircleButtonRadio.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

CircleButtonRadio.defaultProps = {
  isSelected: false,
};

export default CircleButtonRadio;
