import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';

import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import RadioButton from '../../components/common/inputs/RadioButton';
import Logo from '../../components/Logo';

import styles from './PreInitialSignupPage.module.scss';

const NEW_ACCOUNT_TYPE = {
  DOCTOR: 'DOCTOR',
  PATIENT: 'PATIENT',
};

const PreInitialSignupPage = ({ history }) => {
  const [accountType, setAccountType] = useState(null);

  const handleForm = async (values) => {
    if (accountType === NEW_ACCOUNT_TYPE.DOCTOR) {
      history.push('/doctor-initial-signup');
    } else if (accountType === NEW_ACCOUNT_TYPE.PATIENT) {
      history.push('/discoverv3');
    }
  };

  return (
    <React.Fragment>
      <div className={styles.root}>
        <div className={styles.container}>
          <div>
            <Logo />
          </div>
          <div className={styles.content}>
            <Formik
              initialValues={{
                accountType: null,
              }}
              onSubmit={handleForm}
            >
              {({
                values,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <h4 data-testid="login header">Please confirm your status</h4>
                  <>
                    <Field
                      component={(field) => (
                        <RadioButton {...field} name="accountType" />
                      )}
                      value={NEW_ACCOUNT_TYPE.DOCTOR}
                      htmlFor={NEW_ACCOUNT_TYPE.DOCTOR}
                      checked={NEW_ACCOUNT_TYPE.DOCTOR === values.accountType}
                      name="group"
                      onChange={(value) => {
                        setFieldValue('accountType', value);
                        setAccountType(value);
                      }}
                      // onBlur={handleBlur}
                      style={{ width: 'fit-content' }}
                      labelName={'I am a clinician or service provider'}
                    />
                    <Field
                      component={(field) => (
                        <RadioButton {...field} name="accountType" />
                      )}
                      value={NEW_ACCOUNT_TYPE.PATIENT}
                      htmlFor={NEW_ACCOUNT_TYPE.PATIENT}
                      checked={NEW_ACCOUNT_TYPE.PATIENT === values.accountType}
                      name="group"
                      onChange={(value) => {
                        setFieldValue('accountType', value);
                        setAccountType(value);
                      }}
                      // onBlur={handleBlur}
                      labelName={`I am looking for a service provider`}
                    />

                    <div className={styles.bottom}>
                      <div className={styles.nextBtn}>
                        <PrimaryButton
                          disabled={!accountType}
                          className={styles.primaryBtn}
                          label="Create New Account"
                          data-testid="Signup button"
                        />
                      </div>
                      <div className={styles.bottomLinks}>
                        <Link to={`/`}>Login to existing account</Link>
                      </div>
                    </div>
                  </>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PreInitialSignupPage;
