import React from 'react';
import { Redirect } from 'react-router-dom';
import useContentful from '../../../hooks/useContentful';
import Loading from '../../../components/common/Loading';
import styles from './ContactUsPage.module.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { WHITE_LABEL_DATA } from '../../../utils/common';

const ContactUsPage = () => {
  const hostname = window && window.location && window.location.hostname;
  const whilteLableData = WHITE_LABEL_DATA.find((e) => {
    return hostname.toLocaleLowerCase().includes(e.clinicName);
  });
  const { staticContent, isLoading } = useContentful({
    key: whilteLableData ? whilteLableData['contact-us'] : 'contact-us',
    contentType: 'staticPage',
  });
  return isLoading ? (
    <Loading />
  ) : !staticContent ? (
    <Redirect to="/" />
  ) : (
    <div className={styles.container}>
      <div className={styles.content}>
        {documentToReactComponents(staticContent.fields.content)}
      </div>
    </div>
  );
};

export default ContactUsPage;
