import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import styles from './UpdateCliniciainProfile.module.scss';
import Loading from '../../../components/common/Loading';
import {
  getClinicianProfile,
  updateClinicianRegistration,
  getInsuranceList,
} from '../../../api/doctor';
import AuthContext from '../../../context/AuthContext';
import { Cell, Grid, Column } from 'react-foundation';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import data from './data.json';
import FieldInput from '../../../components/common/fields/FieldInput/FieldInput';
import messages from './messages';
import useIntl from '../../../hooks/useIntl';
import TextAreaInputWithLabel from '../../../components/common/inputs/TextAreaInputWithLabel';
import useScreen from '../../../hooks/useScreen';
import Label from '../../../components/common/Label';
import Dropdown from '../../../components/common/inputs/Dropdown';
import TextInput from '../../../components/common/inputs/TextInput';
import Datepicker from '../../../components/common/Datepicker';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import DoctorLeftNav from '../../../components/nav/DoctorLeftNav';
import Back from '../../../img/icons/back.svg';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import RadioButton from '../../../components/common/inputs/RadioButton';
import LabeledCheckbox from '../../../components/common/inputs/LabeledCheckbox';
import { getLoggedInUser } from '../../../api/auth';

const UpdateClinicianProfile = () => {
  const { formatMessage } = useIntl();
  const [dataLoading, setLoading] = useState(false);
  const { authUser, dispatch } = useContext(AuthContext);
  const { isDesktop } = useScreen();
  const [showOther, setShowOther] = useState(true);
  const [insuranceList, setInsuranceList] = useState([]);
  const history = useHistory();

  const { services, expertice, therapyList, certificationList } = data;
  const [initialRegistrationValues, setInitialRegistrationValues] = useState({
    first_name: '',
    doctor_id: '',
    last_name: '',
    phone: '',
    contact_email: '',
    certification: [],
    states: [],
    network: '',
    selected_clinic: '',
    parts_of_therapeutic: [],
    practiciane_email: '',
    city: '',
    state: '',
    treatment: [],
    therapy: [],
    description: '',
    clinic_id: null,
    license_status: '',
    currently_licence_states: [],
    clinic_licence_states: [
      {
        label: '',
        value: '',
        license_number: '',
        expire_date: '',
        insurance_network: '',
      },
    ],
    bio: '',
  });
  const formikRef = useRef();
  const options = services.choices.sort((a, b) => (a.value > b.value ? 1 : -1));

  const clinicianSchema = Yup.object().shape({
    first_name: Yup.string().required('Field is Required'),
    last_name: Yup.string().required('Field is Required'),
    gender: Yup.string().required('Field is Required'),
    city: Yup.string().required('Field is Required'),
    state: Yup.string().required('Field is Required'),
    license_status: Yup.string().required('Field is Required'),
    bio: Yup.string().required('Field is Required'),
    currently_licence_states: Yup.array().when('license_status', {
      is: (val) => {
        if (val === 'Yes') {
          return true;
        }
      },
      then: Yup.array()
        .min(1, 'Field Must Have At Least 1 Item')
        .required('Field Must Have At Least 1 Item')
        .nullable(),
    }),
    clinic_licence_states: Yup.array().when('license_status', {
      is: (val) => val === 'Yes',
      then: Yup.array().of(
        Yup.object().shape({
          license_number: Yup.string().required('Field is Require'),
          expire_date: Yup.date().required('Field is Required'),
          insurance_network: Yup.array()
            .required('Field is Required')
            .nullable(),
          label: Yup.string(),
          value: Yup.string(),
        }),
      ),
    }),
    certification: Yup.array()
      .min(1, 'Field is Required')
      .required('Field is Required'),
    other_certification: Yup.string()
      .nullable()
      .when('certification', {
        is: (val) => val.includes('Other'),
        then: Yup.string()
          .nullable()
          .max(100, 'Certification Has a maximum limit of 100 characters.')
          .required('Field is required'),
      }),
    phone: Yup.string()
      .nullable()
      .required('Field is Required')
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Please enter valid phone number',
      ),
    contact_email: Yup.string()
      .nullable()
      .email('Invalid email address')
      .required('Field is Required'),
  });

  const fetchClinician = async (id) => {
    try {
      setLoading(true);
      const clinicianData = await getClinicianProfile(id);
      const { clinicianProfile } = clinicianData;
      console.log(
        'clinicianProfile.clinician_registration: ',
        clinicianProfile.clinician_registration,
      );
      if (clinicianProfile.clinician_registration) {
        let states = clinicianProfile.clinician_registration
          .clinic_licence_states
          ? clinicianProfile.clinician_registration.clinic_licence_states.map(
              (element) => ({
                label: element.state,
                value: element.state,
                license_number: element.license_number,
                expire_date: element.expire_date,
                insurance_network: element.insurance_network.map(
                  (insurance) => {
                    return {
                      label: insurance,
                      value: insurance,
                    };
                  },
                ),
              }),
            )
          : [];
        if (clinicianProfile.clinician_registration.other_certification) {
          setShowOther(false);
        }
        setInitialRegistrationValues({
          first_name: clinicianProfile.first_name,
          last_name: clinicianProfile.last_name,
          gender: clinicianProfile.clinician_registration.gender,
          doctor_id: '',
          phone: clinicianProfile.phone,
          contact_email: clinicianProfile.clinician_registration.contact_email,
          certification:
            clinicianProfile.clinician_registration.certification.split(','),
          states: states ? states : [],
          network: clinicianProfile?.clinician_registration?.network,
          selected_clinic: '',
          parts_of_therapeutic:
            clinicianProfile.clinician_registration.parts_of_therapeutic.split(
              ',',
            ),
          practiciane_email:
            clinicianProfile.clinician_registration.practiciane_email,
          city: clinicianProfile.clinician_registration.city,
          state: clinicianProfile.clinician_registration.state,
          treatment:
            clinicianProfile.clinician_registration.treatment.split(','),
          therapy: clinicianProfile.clinician_registration.therapy.split(','),
          description: clinicianProfile.clinician_registration.description,
          clinic_id: null,
          license_status:
            clinicianProfile.clinician_registration.states === 'not licensed'
              ? 'No'
              : 'Yes',
          clinic_licence_states: states,
          currently_licence_states: states,
          bio: clinicianProfile.description,
          other_certification:
            clinicianProfile.clinician_registration.other_certification,
        });
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const submitHandler = async (values) => {
    try {
      setLoading(true);

      if (values.license_status === 'No') {
        values.clinic_licence_states = null;
        values.states = ['not licensed'];
      } else {
        values.clinic_licence_states = values.clinic_licence_states.map(
          (element) => {
            return {
              state: element.value,
              license_number: element.license_number,
              expire_date: element.expire_date,
              insurance_network: element.insurance_network.map(
                (company) => company.value,
              ),
            };
          },
        );
        values.states = values.currently_licence_states.map(
          (state) => state.value,
        );
      }
      values.doctor_id = authUser.id;

      await updateClinicianRegistration(authUser.id, values);
      await getLoggedInUser(dispatch);

      setLoading(false);

      history.push('/doctor/profile');
      toast('Updated successfully', {
        autoClose: 5000,
        className: styles.toastMsg,
        bodyClassName: styles.toastDiv,
        hideProgressBar: true,
      });
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchInsuranceList = async () => {
    if (authUser.id) {
      const insurances = await getInsuranceList();
      const formattedInsurances = insurances.map((insurance) => {
        return {
          label: insurance.company_name,
          value: insurance.company_name,
        };
      });
      formattedInsurances.unshift({
        label: 'None',
        value: 'None',
      });
      setInsuranceList(formattedInsurances);
    }
  };
  useEffect(() => {
    if (authUser.id) {
      fetchClinician(authUser.id);
      fetchInsuranceList();
    }
  }, [authUser]);

  return (
    <div className={styles.root}>
      <div medium={3} className={classnames(styles.leftNav, 'desktop-only')}>
        <DoctorLeftNav />
      </div>
      {dataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.container}>
            <div className={styles.backDiv}>
              <Link to="/doctor/profile">
                <img src={Back} alt="Back" />
                <span data-testid="back">Back</span>
              </Link>
            </div>

            <div className={styles.form}>
              <Grid>
                <Cell small={12} medium={12}>
                  <h2
                    className={styles.formTitle}
                    data-testid="edit myaccount profile header"
                  >
                    Edit My Account Profile
                  </h2>
                </Cell>
              </Grid>
              <Formik
                enableReinitialize
                validationSchema={clinicianSchema}
                initialValues={initialRegistrationValues}
                onSubmit={(values) => submitHandler(values)}
                innerRef={formikRef}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form>
                    <Grid>
                      <Cell small={12} medium={12}>
                        <FieldInput
                          label={formatMessage(
                            messages.contactDetails.firstNameLabel,
                          )}
                          errorText={touched.first_name && errors.first_name}
                          name="first_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.first_name}
                          data-testid="first_name"
                          label-testid="first_name_label"
                          error-testid="first_name_error"
                        />
                      </Cell>
                      <Cell small={12} medium={12}>
                        <FieldInput
                          label={formatMessage(
                            messages.contactDetails.LastNameLabel,
                          )}
                          errorText={touched.last_name && errors.last_name}
                          name="last_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.last_name}
                          data-testid="last_name"
                          label-testid="last_name_label"
                          error-testid="last_name_error"
                        />
                      </Cell>
                      <Cell small={12} medium={12}>
                        <FieldInput
                          label={formatMessage(
                            messages.contactDetails.phoneLabel,
                          )}
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          errorText={touched.phone && errors.phone}
                          onBlur={handleBlur}
                          data-testid="phone"
                          label-testid="phone_label"
                          error-testid="phone_error"
                        />
                      </Cell>
                      <Cell small={12} medium={12}>
                        <FieldInput
                          label={formatMessage(
                            messages.contactDetails.contactEmailLabel,
                          )}
                          name="contact_email"
                          value={values.contact_email || ''}
                          onChange={handleChange}
                          errorText={
                            touched.contact_email && errors.contact_email
                          }
                          onBlur={handleBlur}
                          data-testid="contact_email"
                          label-testid="contact_email_label"
                          error-testid="contact_email_error"
                        />
                      </Cell>
                      <Cell small={12} medium={12}>
                        <Label
                          errorText={errors.gender}
                          dataTestId="UpdateClinicianProfile.Gender"
                        >
                          Gender
                        </Label>

                        <Field
                          component={(field) => (
                            <RadioButton {...field} name="gender" />
                          )}
                          value="Male"
                          htmlFor="Male"
                          checked={'Male' === values.gender}
                          name="gender"
                          onChange={(value) => {
                            setFieldValue('gender', value);
                          }}
                          labelName="Male"
                          data-testid="male"
                          label-testid="male label"
                        />
                        <Field
                          component={(field) => (
                            <RadioButton {...field} name="gender" />
                          )}
                          value="Female"
                          htmlFor="Female"
                          checked={'Female' === values.gender}
                          name="gender"
                          onChange={(value) => {
                            setFieldValue('gender', value);
                          }}
                          labelName="Female"
                          data-testid="female"
                          label-testid="female label"
                        />
                        <Field
                          component={(field) => (
                            <RadioButton {...field} name="gender" />
                          )}
                          value="Non-Binary"
                          htmlFor="Non-Binary"
                          checked={'Non-Binary' === values.gender}
                          name="gender"
                          onChange={(value) => {
                            setFieldValue('gender', value);
                          }}
                          labelName="Non-Binary"
                          data-testid="Non-Binary"
                          label-testid="Non-Binary label"
                        />
                      </Cell>

                      <Cell
                        small={12}
                        medium={12}
                        className={styles['radioBtn']}
                      >
                        <h3 data-testid="certification label">
                          {formatMessage(messages.certification)}
                          <div
                            className={styles.typeError}
                            error-testid="certification error"
                          >
                            {errors && errors.certification}
                          </div>
                        </h3>
                        {certificationList.value.map((choice) => (
                          <Column
                            small={12}
                            medium={7}
                            key={choice.key}
                            className={styles['grid-cell']}
                          >
                            <LabeledCheckbox
                              checkboxKey={choice.key}
                              name="certification"
                              labelName={choice.title}
                              value={choice.key}
                              checked={values.certification.includes(
                                choice.key,
                              )}
                              data-testid={`${choice.title}`}
                              onChange={(e) => {
                                const { checked, value } = e.target;
                                if (checked) {
                                  setFieldValue('certification', [
                                    ...values.certification,
                                    value,
                                  ]);
                                  if (value === 'Other') {
                                    setShowOther(false);
                                  }
                                } else {
                                  if (value === 'Other') {
                                    setShowOther(true);
                                    setFieldValue('other_certification', '');
                                  }
                                  setFieldValue(
                                    'certification',
                                    values.certification.filter(
                                      (v) => v !== value,
                                    ),
                                  );
                                }
                              }}
                            />
                          </Column>
                        ))}

                        <FieldInput
                          label=""
                          disabled={showOther}
                          value={values.other_certification}
                          errorText={errors.other_certification}
                          onChange={(e) => {
                            setFieldValue(
                              'other_certification',
                              e.target.value,
                            );
                          }}
                          onBlur={handleBlur}
                          data-testid="other_certification"
                          label-testid="other_certification_label"
                          error-testid="other_certification_error"
                        />
                      </Cell>
                      <Cell
                        small={12}
                        medium={12}
                        className={styles['checkboxBtn']}
                      >
                        <h3 data-testid="license_status_label">
                          {formatMessage(messages.license_status)}
                          <div
                            className={styles.licenseStatusError}
                            data-testid="license_status_error"
                          >
                            {errors.license_status}
                          </div>
                        </h3>

                        <div className={styles.radioCustomDiv}>
                          <input
                            id="radio-1"
                            className={styles.radioCustom}
                            name="radio-group"
                            type="radio"
                            checked={values.license_status === 'Yes'}
                            value={values.license_status}
                            onChange={() => {
                              setFieldValue('license_status', 'Yes');
                            }}
                            data-testid="radio-1"
                          />
                          <label
                            htmlFor="radio-1"
                            data-testid="radio-1-label"
                            className={styles.radioCustomLabel}
                          >
                            Yes
                          </label>
                        </div>
                        <div className={styles.radioCustomDiv}>
                          <input
                            id="radio-2"
                            className={styles.radioCustom}
                            name="radio-group"
                            type="radio"
                            checked={values.license_status === 'No'}
                            value={values.license_status}
                            onChange={() => {
                              setFieldValue('license_status', 'No');
                            }}
                          />
                          <label
                            htmlFor="radio-2"
                            data-testid="radio-2-label"
                            className={styles.radioCustomLabel}
                          >
                            No
                          </label>
                        </div>
                        {values.license_status === 'Yes' && (
                          <>
                            <h3 data-testid="indicate-state-copy">
                              {formatMessage(messages.states)}
                            </h3>
                            <FieldArray
                              name="clinic_licence_states"
                              id="clinic_licence_states"
                              render={(arrayHelper) => (
                                <div>
                                  <div className={styles.typeError}>
                                    {errors?.currently_licence_states}
                                  </div>
                                  <Dropdown
                                    options={options}
                                    isMulti
                                    data-testid="licence_states"
                                    value={values.currently_licence_states}
                                    name="currently_licence_states"
                                    onChange={(selectedValues, event) => {
                                      setFieldValue(
                                        'currently_licence_states',
                                        selectedValues === null
                                          ? []
                                          : selectedValues,
                                      );

                                      if (event.action === 'select-option') {
                                        arrayHelper.push(event.option);
                                      } else if (
                                        event.action === 'remove-value'
                                      ) {
                                        setFieldValue(
                                          'clinic_licence_states',
                                          selectedValues === null
                                            ? []
                                            : selectedValues,
                                        );
                                      } else if (event.action === 'clear') {
                                        setFieldValue(
                                          'clinic_licence_states',
                                          [],
                                        );
                                      }
                                    }}
                                    classNamePrefix="select"
                                    placeholder="Select..."
                                  />
                                  {values?.clinic_licence_states?.map(
                                    (licence_states, index) => (
                                      <>
                                        <div key={index}>
                                          <div
                                            className={styles.selectedStates}
                                          >
                                            {
                                              values.clinic_licence_states[
                                                index
                                              ].value
                                            }
                                          </div>

                                          <Grid
                                            className={styles.statesInputDiv}
                                          >
                                            <Cell small={12} medium={8}>
                                              <div
                                                className={styles.typeError}
                                                error-testid={`clinic_licence_states[${index}].license_number_error`}
                                              >
                                                {touched?.clinic_licence_states &&
                                                  touched
                                                    ?.clinic_licence_states[
                                                    index
                                                  ]?.license_number &&
                                                  errors?.clinic_licence_states &&
                                                  errors?.clinic_licence_states[
                                                    index
                                                  ]?.license_number}
                                              </div>
                                              <TextInput
                                                type="text"
                                                name={`clinic_licence_states[${index}].license_number`}
                                                value={
                                                  values?.clinic_licence_states[
                                                    index
                                                  ]?.license_number || ''
                                                }
                                                placeholder="License Number"
                                                className={styles.licenseNumber}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errorText={
                                                  errors?.clinic_licence_states &&
                                                  errors?.clinic_licence_states[
                                                    index
                                                  ]?.license_number
                                                }
                                                data-testid={`clinic_licence_states[${index}].license_number`}
                                                label-testid={`clinic_licence_states[${index}].license_number_label`}
                                              />
                                            </Cell>

                                            <Cell small={12} medium={4}>
                                              <Datepicker
                                                selected={
                                                  values.clinic_licence_states[
                                                    index
                                                  ].expire_date
                                                    ? new Date(
                                                        values.clinic_licence_states[
                                                          index
                                                        ].expire_date,
                                                      )
                                                    : null
                                                }
                                                className={styles.expireDate}
                                                name={`clinic_licence_states[${index}].expire_date`}
                                                data-testid={`clinic_licence_states[${index}].expire_date`}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                value={
                                                  values?.clinic_licence_states[
                                                    index
                                                  ]?.expire_date
                                                }
                                                dropdownMode="select"
                                                minDate={new Date()}
                                                placeholderText="Expiration Date"
                                                dateFormat="MM/dd/yyyy"
                                                onBlur={handleBlur}
                                                onChange={(date) => {
                                                  setFieldValue(
                                                    `clinic_licence_states[${index}].expire_date`,
                                                    date,
                                                  );
                                                }}
                                                required={true}
                                              />
                                            </Cell>
                                            <Cell
                                              small={12}
                                              medium={12}
                                              className={styles['label-bold']}
                                            >
                                              <div
                                                className={
                                                  styles.insuranceLable
                                                }
                                                error-testid={`clinic_licence_states[${index}].insurance_network_label`}
                                              >
                                                {formatMessage(
                                                  messages.networkLabel,
                                                )}
                                              </div>
                                              <div
                                                className={
                                                  styles.insuranceDropDown
                                                }
                                              >
                                                <div
                                                  className={styles.typeError}
                                                  error-testid={`clinic_licence_states[${index}].insurance_network_error`}
                                                >
                                                  {errors?.clinic_licence_states &&
                                                    errors
                                                      ?.clinic_licence_states[
                                                      index
                                                    ]?.insurance_network}
                                                </div>
                                              </div>
                                              <div>
                                                <Dropdown
                                                  id={`clinic_licence_states[${index}].insurance_network`}
                                                  options={insuranceList}
                                                  isMulti
                                                  value={
                                                    values
                                                      ?.clinic_licence_states[
                                                      index
                                                    ]?.insurance_network
                                                  }
                                                  name={`clinic_licence_states[${index}].insurance_network`}
                                                  onChange={(
                                                    selectedValues,
                                                  ) => {
                                                    setFieldValue(
                                                      `clinic_licence_states[${index}].insurance_network`,
                                                      selectedValues === null
                                                        ? []
                                                        : selectedValues,
                                                    );
                                                  }}
                                                  classNamePrefix="select"
                                                  placeholder="Select..."
                                                  data-testid={`clinician insurance network autocomplete`}
                                                />
                                              </div>
                                            </Cell>
                                          </Grid>
                                        </div>
                                      </>
                                    ),
                                  )}
                                </div>
                              )}
                            />
                          </>
                        )}
                      </Cell>
                      <Cell
                        small={12}
                        medium={12}
                        className={styles['label-bold']}
                      >
                        <FieldInput
                          label={formatMessage(messages.cityLabel)}
                          placeholder="Enter Here"
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          errorText={touched.city && errors.city}
                          onBlur={handleBlur}
                          data-testid="city"
                          label-testid="city label"
                          error-testid="city error"
                        />
                      </Cell>
                      <Cell
                        small={12}
                        medium={12}
                        className={styles['label-bold']}
                      >
                        <FieldInput
                          label={formatMessage(messages.stateLabel)}
                          placeholder="Enter Here"
                          name="state"
                          value={values.state}
                          onChange={handleChange}
                          errorText={touched.state && errors.state}
                          onBlur={handleBlur}
                          data-testid="state"
                          label-testid="state label"
                          error-testid="state error"
                        />
                      </Cell>
                      <Cell
                        small={12}
                        medium={12}
                        className={styles['checkboxBtn']}
                      >
                        <h3>{formatMessage(messages.expertice)}</h3>
                        {expertice.choices
                          .sort((a, b) => (a.title > b.title ? 1 : -1))
                          .map((choice) => (
                            <Column
                              small={12}
                              medium={7}
                              key={choice.key}
                              className={styles['grid-cell']}
                            >
                              <LabeledCheckbox
                                checkboxKey={choice.key}
                                name="treatment"
                                labelName={choice.title}
                                value={choice.key}
                                checked={values.treatment.includes(choice.key)}
                                data-testid="treatment_checkbox"
                                onChange={(e) => {
                                  const { checked, value } = e.target;
                                  if (checked) {
                                    setFieldValue('treatment', [
                                      ...values.treatment,
                                      value,
                                    ]);
                                  } else {
                                    setFieldValue(
                                      'treatment',
                                      values.treatment.filter(
                                        (v) => v !== value,
                                      ),
                                    );
                                  }
                                }}
                              />
                            </Column>
                          ))}
                      </Cell>
                      <Cell
                        small={12}
                        medium={12}
                        className={styles['checkboxBtn']}
                      >
                        <h3>{formatMessage(messages.therapyTitle)}</h3>
                        {therapyList.choices
                          .sort((a, b) => (a.title > b.title ? 1 : -1))
                          .map((choice) => (
                            <Column
                              small={12}
                              medium={7}
                              key={choice.key}
                              className={styles['grid-cell']}
                            >
                              <LabeledCheckbox
                                checkboxKey={choice.key}
                                name="therapy"
                                labelName={choice.title}
                                value={choice.key}
                                checked={values.therapy.includes(choice.key)}
                                data-testid="therapy_checkbox"
                                onChange={(e) => {
                                  const { checked, value } = e.target;
                                  if (checked) {
                                    setFieldValue('therapy', [
                                      ...values.therapy,
                                      value,
                                    ]);
                                  } else {
                                    setFieldValue(
                                      'therapy',
                                      values.therapy.filter((v) => v !== value),
                                    );
                                  }
                                }}
                              />
                            </Column>
                          ))}
                      </Cell>
                      <Cell
                        small={12}
                        medium={12}
                        className={styles['label-bold']}
                      >
                        <FieldInput
                          label={formatMessage(messages.descriptionLabel)}
                          placeholder="Enter Here"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          data-testid="description"
                          label-testid="description label"
                          error-testid="description error"
                        />
                      </Cell>
                      <Cell small={12} className={styles['label-bold']}>
                        <TextAreaInputWithLabel
                          label={formatMessage(
                            messages.contactDetails.bioPlaceholder,
                          )}
                          placeholder={formatMessage(
                            messages.contactDetails.bioPlaceholder,
                          )}
                          rows={isDesktop ? 4 : 6}
                          value={values.bio}
                          name="bio"
                          errorText={touched.bio && errors.bio}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          data-testid="bio"
                        ></TextAreaInputWithLabel>
                      </Cell>
                      <Cell
                        small={12}
                        medium={12}
                        className={styles['label-bold']}
                      ></Cell>
                      <PrimaryButton
                        disabled={Object.entries(errors).length > 0}
                        label="Submit"
                        type="submit"
                        className="font-18"
                        data-testid="submit button"
                      />
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default UpdateClinicianProfile;
