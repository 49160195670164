import React from 'react';
import classnames from 'classnames';

import style from './CalendlyEvent.module.scss';

import TextInput from '../../components/common/inputs/TextInput';
import { Switch } from '../../components/common/Switch';
import BillingPlanFeature from '../../components/BillingPlanFeature';

const CalendlyEvent = (props) => {
  const {
    name,
    duration,
    amount = 0,
    visibleToDoctor = false,
    visibleToPatient = false,
    onAmountChange,
    onVisibleToDoctorChange,
    onVisibleToPatientChange,
    index,
    'data-testid': datatestid,
  } = props;

  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className={style.mainInfo}>
          <div className={style.title} data-testid={`${datatestid} title`}>
            {name}
          </div>
          <div className={style.eventDetails}>
            <span
              className={style.eventDuration}
              data-testid={`${datatestid} duration`}
            >
              {duration} mins
            </span>

            <BillingPlanFeature payments>
              <TextInput
                value={amount.toString()}
                onChange={onAmountChange}
                type="number"
                className={style.amount}
                name={`amount-${index}`}
                placeholder="Amount"
                min={0}
                data-testid={datatestid}
              />
              <span className={style.amountFee}>fee</span>
            </BillingPlanFeature>
          </div>
        </div>
        <div className={style.settings}>
          <div className={style.settingsItem}>
            <div className={style.title}>Visible to Clinicians</div>
            <div className={style.settingsCheckbox}>
              <Switch
                checked={visibleToDoctor}
                onChange={onVisibleToDoctorChange}
                name="visibleToDoctors"
                disabled={visibleToPatient === true}
              />
            </div>
          </div>
          <div className={style.settingsItem}>
            <div className={style.title}>Visible to Clients</div>
            <div className={style.settingsCheckbox}>
              <Switch
                checked={visibleToPatient}
                onChange={onVisibleToPatientChange}
                name="visibleToPatients"
                disabled={visibleToDoctor === false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendlyEvent;
