import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Cell, Grid } from 'react-foundation';
import Dropzone from 'react-dropzone';
import moment from 'moment';

import PrimaryButton from '../common/buttons/PrimaryButton';
import SecondaryButton from '../common/buttons/SecondaryButton';
import Modal from '../common/Modal';
import Loading from '../common/Loading';
import UserConfirmation from '../UserConfirmation';

import {
  uploadPatientFile,
  getPatientFiles,
  getPatientFileSignedUrl,
  deletePatientFile,
} from '../../api/patientFile';
import styles from './PatientFiles.module.scss';

import { triggerDownloadLink } from '../../utils/common';

const PatientFiles = ({ patientId }) => {
  const { id } = useParams();

  const [dataLoading, setDataLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newPatientFile, setNewPatientFile] = useState(null);
  const [newPatientFileError, setNewPatientFileError] = useState(null);
  const [patientFiles, setPatientFiles] = useState([]);
  const [patientFileIdToDelete, setPatientFileIdToDelete] = useState(false);

  const onDrop = async (files, fileRejections) => {
    if (files.length) {
      setNewPatientFile(files[0]);

      if (newPatientFileError) {
        setNewPatientFileError(null);
      }
    } else {
      fileRejections.forEach((f) => {
        f.errors.forEach((err) => {
          if (err?.code === 'file-too-large') {
            setNewPatientFileError('File is larger than 100 MB.');
          } else {
            setNewPatientFileError(err.message);
          }
        });
      });

      if (newPatientFile) {
        setNewPatientFile(null);
      }
    }
  };

  const onCloseModal = () => {
    if (fileLoading) {
      console.log('fileLoading', fileLoading);
      return;
    }
    setDataLoading(false);
    setShowEditModal(false);
    setNewPatientFile(null);
    setNewPatientFileError(null);
  };

  const fetchPatientFiles = async () => {
    try {
      setDataLoading(true);

      const data = await getPatientFiles(id);
      setPatientFiles(data);

      setDataLoading(false);
    } catch (err) {
      console.error('err', err);
      setDataLoading(false);
    }
  };

  const onSaveNewPatientFile = async () => {
    try {
      setFileLoading(true);

      const data = new FormData();
      data.append('file', newPatientFile);
      data.append('patient_id', patientId);
      await uploadPatientFile(data);

      setFileLoading(false);
      onCloseModal();

      await fetchPatientFiles();
    } catch (err) {
      console.error('err', err);
      setFileLoading(false);
      setNewPatientFileError(err?.message || 'Something went wrong');
    }
  };

  const handleDownloadFile = async (id) => {
    try {
      const { url } = await getPatientFileSignedUrl(id);
      if (url) {
        triggerDownloadLink(url, true);
      }
    } catch (err) {
      console.error('err', err);
    }
  };

  const handleDeletePatientFile = async (id) => {
    try {
      setDataLoading(true);

      await deletePatientFile(id);

      setDataLoading(false);

      await fetchPatientFiles();
    } catch (err) {
      console.error('err', err);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    fetchPatientFiles();
  }, []);

  return (
    <Grid className={styles.container}>
      {dataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <Cell small={12}>
            <div className={styles.addNewBtn}>
              <PrimaryButton
                label="Add new"
                type="button"
                className={styles.btn}
                onClick={() => {
                  setShowEditModal(true);
                }}
              />
            </div>

            {patientFiles.map((item) => (
              <div key={item.id} className={styles.content}>
                <div className={styles.bgImg}>
                  <img alt="" src={require(`../../img/icons/note.svg`)} />
                </div>
                <div className={styles.title}>{item.filename}</div>
                <div className={styles.rightContent}>
                  <div className={styles.desc}>
                    <span>
                      Date Created: {moment(item.createdAt).format('LLL')}
                    </span>
                  </div>
                  <div className={styles.buttons}>
                    <SecondaryButton
                      label="View"
                      type="button"
                      className={styles.btn}
                      onClick={() => {
                        handleDownloadFile(item.id);
                      }}
                    />
                    <PrimaryButton
                      label="Delete"
                      type="button"
                      className={styles.btn}
                      onClick={() => {
                        setPatientFileIdToDelete(item.id);
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Cell>

          {showEditModal && (
            <Modal className={styles.modal}>
              <div className={styles.modalcontent}>
                <div className={styles.closeButton} onClick={onCloseModal}>
                  <img src={require(`../../img/close.svg`)} alt={`Close`} />
                </div>
                <h1>
                  <b>Upload file</b>
                </h1>
                <div>
                  <div className={styles.line}></div>

                  <div className={styles.dropzone}>
                    <Dropzone
                      onDrop={onDrop}
                      // accept="image/*"
                      maxSize={100000000}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <PrimaryButton
                            label="Select file"
                            className="font-18"
                            type="button"
                          />
                        </div>
                      )}
                    </Dropzone>
                    <p className={styles.dropzoneDesc}>100 MB max</p>
                  </div>

                  {!!newPatientFile?.name && (
                    <div className={styles.fileName}>{newPatientFile.name}</div>
                  )}
                  {!!newPatientFileError && (
                    <div className={styles.fileError}>
                      {newPatientFileError}
                    </div>
                  )}
                  {fileLoading && <Loading />}

                  <div className={styles.line}></div>
                  <div className={styles.buttons}>
                    <SecondaryButton
                      label="Close"
                      type="button"
                      className={styles.btn}
                      onClick={onCloseModal}
                    />
                    <PrimaryButton
                      label="save"
                      type="button"
                      className={styles.btn}
                      onClick={() => {
                        onSaveNewPatientFile();
                      }}
                      disabled={!newPatientFile || fileLoading}
                    />
                  </div>
                </div>
              </div>
            </Modal>
          )}

          {!!patientFileIdToDelete && (
            <UserConfirmation
              message={`Are you sure you want to delete?`}
              buttonLabel={'Delete'}
              onCancel={() => {
                setPatientFileIdToDelete(null);
              }}
              onConfirm={() => {
                handleDeletePatientFile(patientFileIdToDelete);
                setPatientFileIdToDelete(null);
              }}
            />
          )}
        </Fragment>
      )}
    </Grid>
  );
};

PatientFiles.propTypes = {
  patientId: PropTypes.string.isRequired,
};

export default PatientFiles;
