import React, { useState } from 'react';
import styles from './ForgotPassword.module.scss';
import { Link, useParams } from 'react-router-dom';
import Back from '../../img/icons/back.svg';
import { Formik, Form } from 'formik';
import FieldInput from '../../components/common/fields/FieldInput/FieldInput';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import { forgotPassword } from '../../api/auth';
import Loading from '../../components/common/Loading';
import { useHistory } from 'react-router-dom';
import Logo from '../../components/Logo';

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { email } = useParams();
  const history = useHistory();

  const RecoverPassword = async (values) => {
    setIsLoading(true);
    try {
      await forgotPassword({ username: values.email });
      setIsLoading(false);
      history.push('/reset/email');
    } catch (error) {
      if (error.response && error.response.data)
        setError(error.response.data.message);
      setIsLoading(false);
    }
  };
  return (
    <div className={styles.root}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.container}>
          <div className={styles.backDiv}>
            <Link to="/">
              <img src={Back} alt="Back" />
              <span>Back</span>
            </Link>
          </div>
          <Logo />
          <div className={styles.content}>
            <h4>Did you forgot your password?</h4>

            <Formik
              initialValues={{
                email: email,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Field Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                await RecoverPassword(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <>
                    <FieldInput
                      className={styles.userInput}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      autoComplete="email"
                      label="Enter registered email address"
                      errorText={(touched.email && errors.email) || error}
                      name="email"
                      data-testid="email"
                    />

                    <div className={styles.nextBtn}>
                      <PrimaryButton
                        className={styles.primaryBtn}
                        label="Reset Password"
                      />
                    </div>
                  </>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
