import React from 'react';
import { Cell } from 'react-foundation';
import useIntl from '../../../../../hooks/useIntl';
import RadioGroupWithLabel from '../../../../common/inputs/RadioGroupWithLabel';
import TextAreaInputWithLabel from '../../../../common/inputs/TextAreaInputWithLabel';
import FieldInput from '../../../../common/fields/FieldInput';
import Loading from '../../../../common/Loading';
import styles from '../../PersonalInfoForm/PersonalInfoForm.module.scss';
import messages from '../messages';
import { stepTwoFormStructure } from '../data';

const SolsticeFormStepTwo = ({
  isLoading,
  values,
  handleChange,
  setFieldValue,
  handleBlur,
  handleCheckboxChange,
}) => {
  const { formatMessage } = useIntl();

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Cell small={12}>
        <p className={styles.formSubtitle}>
          {formatMessage(messages.formInfoStepTwo)}
        </p>
      </Cell>
      {stepTwoFormStructure.map((item, i) => {
        return (
          <Cell small={12} key={i}>
            {item.type === 'textarea' && (
              <TextAreaInputWithLabel
                label={item.label ? formatMessage(messages[item.label]) : ''}
                name={item.id}
                rows={2}
                value={values[item.id]}
                onChange={handleChange}
                placeholder="Enter here"
                onBlur={handleBlur}
                autoFocus={true}
                data-testid={`SolsticeFormStepTwo.${item.id}.Textarea`}
              />
            )}
            {item.type === 'radio' && (
              <>
                <RadioGroupWithLabel
                  type="checkbox"
                  label={item.label ? formatMessage(messages[item.label]) : ''}
                  componentName={item.id}
                  name={item.id}
                  options={item.options || []}
                  data-testid={`SolsticeFormStepTwo.${item.id}.Radio`}
                  labelTestId={`SolsticeFormStepTwo.${item.id}.RadioLabel`}
                  value={values[item.id]}
                  onChange={(value) => {
                    const arrOfItems = handleCheckboxChange(
                      values[item.id],
                      value,
                    );
                    setFieldValue(item.id, arrOfItems);
                  }}
                />
                {item.freeText &&
                  !!values[item.id]?.some((v) => v === 'Other') && (
                    <FieldInput
                      placeholder="Enter here"
                      name={item.freeText.id}
                      value={values[item.freeText.id]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      data-testid={`SolsticeFormStepTwo.${item.freeText.id}.Input`}
                    />
                  )}
              </>
            )}
          </Cell>
        );
      })}
    </>
  );
};

SolsticeFormStepTwo.propTypes = {};

SolsticeFormStepTwo.defaultProps = {};

export default SolsticeFormStepTwo;
