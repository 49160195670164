import { defineMessages } from 'react-intl';

export default defineMessages({
  modalTitle: {
    id: 'pages.inviteResearcher.modalTitle',
    description: 'Invite Researcher Info Modal Title',
    defaultMessage: 'User invitation',
  },
  modalSuccessText: {
    id: 'pages.inviteResearcher.modalSuccessText',
    description: 'Invite Researcher Info Modal Success Text',
    defaultMessage: 'Researcher successfully invited.',
  },
  modalFailureText: {
    id: 'pages.inviteResearcher.modalFailureText',
    description: 'Invite Researcher Info Modal Failure Text',
    defaultMessage: 'Researcher invitation failed.',
  },
});
