import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Cell } from 'react-foundation';
import styles from './AddedItem.module.scss';

const AddedItem = ({ items, displayFields, setEditItem, displayEditItem }) => {
  if (!displayFields && items.length) {
    displayFields = Object.keys(items[0]);
  }
  const renderFields = (item, keysArray) => {
    const displayItems = displayFields?.length ? displayFields : keysArray;
    const dateFields = ['dateOfOnset', 'expire_date'];
    return displayItems.map((field, indexField) => {
      let displayValue = dateFields.includes(field)
        ? moment(item[field]).format('MMMM DD, YYYY')
        : item[field];

      if (Array.isArray(displayValue)) {
        displayValue = displayValue.join(', ');
      }

      return <span key={indexField}>{displayValue}</span>;
    });
  };

  return (
    <>
      <Cell medium={12} large={12} className={styles.fieldsContainer}>
        {items.length > 0 &&
          items.map((item, index) => (
            <div key={index} className={styles.fieldArray}>
              <div className={styles.fieldsWrapper}>
                {Object.keys(item).length > 0 &&
                  renderFields(item, displayFields)}
              </div>
              <div className={styles.itemButtonsWrapper}>
                <button
                  style={{ display: displayEditItem }}
                  type="button"
                  className={styles.edit}
                  onClick={() => setEditItem({ index: index, item: item })}
                >
                  Edit
                </button>
                <button
                  type="button"
                  className={styles.remove}
                  onClick={() =>
                    setEditItem({ index: index, item: item, delete: true })
                  }
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
      </Cell>
    </>
  );
};

AddedItem.propTypes = {
  items: PropTypes.array,
  remove: PropTypes.func,
  maxLength: PropTypes.number,
  displayFields: PropTypes.array,
  displayEditItem: PropTypes.string,
};

export default AddedItem;
