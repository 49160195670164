import React, { createContext, useReducer } from 'react';
import Loading from '../components/common/Loading';

const SquareupContext = createContext({});

const initialState = {
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FORM_LOADING':
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
export const SquareupProvider = ({ children }) => {
  const [{ loading }, dispatch] = useReducer(reducer, initialState);
  return (
    <SquareupContext.Provider
      value={{
        loading,
        dispatch,
      }}
    >
      {loading && <Loading />}
      {children}
    </SquareupContext.Provider>
  );
};

export default SquareupContext;
