import React from 'react';
import RadioGroupWithLabel from '../../../../common/inputs/RadioGroupWithLabel';

const Checkbox = ({
  item,
  handleCheckboxChange,
  dataTestId,
  labelTestId,
  value,
  setFieldValue,
  label,
}) => {
  return (
    <RadioGroupWithLabel
      type="checkbox"
      label={label}
      componentName={item.id}
      name={item.id}
      options={item.options || []}
      data-testid={dataTestId}
      labelTestId={labelTestId}
      value={value}
      onChange={(v) => {
        const arrOfItems = handleCheckboxChange(value, v);
        setFieldValue(item.id, arrOfItems);
      }}
    />
  );
};

export default Checkbox;
