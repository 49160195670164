import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.myAccount.payment.title',
    description: 'Title for the payment screen',
    defaultMessage: 'Payment',
  },
  addNewCardBtn: {
    id: 'pages.myAccount.payment.addNewCardBtn',
    description: 'Title for the add new card button',
    defaultMessage: 'Add New Card',
  },
  saveCardBtn: {
    id: 'pages.myAccount.payment.saveCardBtn',
    description: 'Title for the save card button',
    defaultMessage: 'Save Card',
  },
  cancelBtnLable: {
    id: 'pages.myAccount.payment.cancelBtnLable',
    description: 'Title for the cancel button',
    defaultMessage: 'Cancel',
  },
  cardNumber: {
    id: 'pages.myAccount.payment.cardNumber',
    description: 'Label for card number',
    defaultMessage: 'Card Number',
  },
  expirationDate: {
    id: 'pages.myAccount.payment.expirationDate',
    description: 'Label for expiration date',
    defaultMessage: 'Expiration date',
  },
  cvvCode: {
    id: 'pages.myAccount.payment.cvvCode',
    description: 'Label for cvv code',
    defaultMessage: 'Security code',
  },
  nameOnCard: {
    id: 'pages.myAccount.payment.nameOnCard',
    description: 'Label for name on card',
    defaultMessage: 'Name on card',
  },
  postalCode: {
    id: 'pages.myAccount.payment.postalCode',
    description: 'Label for postal code',
    defaultMessage: 'Billing zip code',
  },
  invalidCardDetails: {
    id: 'pages.myAccount.payment.invalidCardDetails',
    description: 'Label for Invalid card details error',
    defaultMessage: 'Invalid card details',
  },
});
