import { doGet, doPost, doPut, doDelete } from '../fetch';

const uploadPatientFile = async (body) => {
  const patientFile = await doPost('/b2c/patient-files', body);
  return patientFile;
};

const getPatientFiles = async (patientId) => {
  const patientFiles = await doGet(
    `/b2c/patient-files?patient_id=${patientId}`,
  );
  return patientFiles;
};

const deletePatientFile = async (id) => {
  const result = await doDelete(`/b2c/patient-files/${id}`);
  return result;
};

const getPatientFileSignedUrl = async (id) => {
  const signedUrl = await doGet(`/b2c/patient-files/signed-url/${id}`);
  return signedUrl;
};

export {
  uploadPatientFile,
  getPatientFiles,
  deletePatientFile,
  getPatientFileSignedUrl,
};
