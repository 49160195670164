import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Cell, Grid } from 'react-foundation';
import { useHistory } from 'react-router-dom';
import { saveIntakeForm21 } from '../../../../api/intakeForm';
import useIntl from '../../../../hooks/useIntl';
import colors from '../../../../styles/_colors.scss';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import SecondaryButton from '../../../common/buttons/SecondaryButton';
import Loading from '../../../common/Loading';
import styles from '../PersonalInfoForm/PersonalInfoForm.module.scss';
import {
  BPSFormStepFive,
  BPSFormStepFour,
  BPSFormStepOne,
  BPSFormStepSeven,
  BPSFormStepSix,
  BPSFormStepThree,
  BPSFormStepTwo,
} from './BPSFormSteps';
import messages from './messages';
import { validate } from './validationSchema';
import { onKeyPress } from '../../../../utils/common';

export const bpsCustomStyles = {
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  pageNumber: {
    fontSize: 15,
    fontFamily: ['Montserrat-Regular', 'sans-serif'],
    color: colors.grayDark,
  },
  leftButtonStyles: { marginRight: 30 },
};

const BPSForm = ({ fact_form_id, patientId, doctorId, id }) => {
  const { formatMessage } = useIntl();

  const [providerValue, setProviderValue] = useState();
  const [editItemProvider, setEditItemProvider] = useState();

  const [facilityValue, setFacilityValue] = useState();
  const [editItemFacility, setEditItemFacility] = useState();
  const [formStep, setFormStep] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const initialValues = {
    reference: '',
    physician_name: '',
    physician_phone: '',
    health_care_providers: '',
    health_care_providers_list: [],
    providers_contact: '',
    medically_hospitalized: '',
    head_injury: '',
    chest_pain: '',
    physical_exam_date: null,
    personal_history: [],
    family_diagnoses: [],
    visit_reason: '',
    depression_symptoms: [],
    personal_disease_input: '',
    family_diagnoses_input: '',
    depression_symptoms_period: '',
    depression_question_response: '',
    depression_symptoms_cause: '',
    anxiety_symptoms: [],
    panic_attacks: '',
    other_anxiety_symptom: '',
    anxiety_symptoms_period: '',
    anxiety_question_response: '',
    anxiety_symptoms_cause: '',
    stress_symptoms: [],
    stress_symptoms_input: '',
    traumatic_symptoms_period: '',
    traumatic_question_response: '',
    traumatic_symptoms_cause: '',
    stress_question_response: '',
    stress_symptoms_cause: '',
    mania_symptoms: [],
    mania_symptoms_input: '',
    mania_symptoms_period: '',
    mania_question_response: '',
    mania_symptoms_cause: '',
    ocd_symptoms: [],
    ocd_symptoms_input: '',
    ocd_symptoms_period: '',
    ocd_question_response: '',
    ocd_symptoms_cause: '',
    behavior_concerns_symptoms: [],
    behavior_concerns_symptoms_input: '',
    behavior_concerns_symptoms_period: '',
    behavior_concerns_question_response: '',
    behavior_concerns_symptoms_cause: '',
    attentional_issues_symptoms: [],
    attentional_issues_symptoms_input: '',
    attentional_issues_symptoms_period: '',
    attentional_issues_question_response: '',
    attentional_issues_symptoms_cause: '',
    eating_problems_symptoms: [],
    eating_problems_symptoms_input: '',
    eating_problems_symptoms_period: '',
    eating_problems_question_response: '',
    eating_problems_symptoms_cause: '',
    psychosis_symptoms: [],
    psychosis_symptoms_input: '',
    psychosis_symptoms_period: '',
    psychosis_question_response: '',
    psychosis_symptoms_cause: '',
    substance_history: '',
    drinking_or_drug_use: '',
    use_criticizing: '',
    felt_bad: '',
    hangover: '',
    psychiatric_treatment: [],
    past_treatment_facilities: [],
    previous_mental_conditions: [],
    previous_mental_conditions_input: '',
    previous_medications: '',
    family_mental_conditions: [],
    family_mental_conditions_input: '',
    mental_conditions_question_response: '',
    mental_conditions_symptoms_cause: '',
    sex_at_birth: '',
    gender_identity: '',
    preferred_gender: '',
    sexual_orientation: '',
    other_sexual_orientation: '',
    relationship_status: '',
    other_relationship_status: '',
    relationship_type: '',
    other_relationship_type: '',
    nr_of_children: '',
    nr_of_pregnancies: '',
    nr_of_adoptions: '',
    resident_type: '',
    other_resident_type: '',
    employment_status: '',
    income_source: '',
    other_income_sources: '',
    emotional_support: '',
    other_emotional_support: '',
    developmental_milestones: '',
    developmental_delays: '',
    family_relationship: '',
    legal_involvement: '',
    other_legal_involvement: '',
    legal_status: '',
    mental_health_treatment: '',
    religion_role: '',
    religion_practices: '',
    racial_identity: '',
    specific_culture: '',
    hobbies: '',
    mental_health_result: '',
    therapy_goals: '',
    possible_barriers: '',
    therapy_needs: '',
    current_strengths: '',
    therapist_advice: '',
    family_suicide: '',
    your_family_relationship: '',
  };

  const nextStep = () => {
    setFormStep(formStep + 1);
  };
  const prevStep = () => {
    setFormStep(formStep - 1);
  };

  const handleSubmit = async (values) => {
    formatValues(values);
    setIsLoading(true);
    if (id) {
      try {
        await saveIntakeForm21(id, { ...values });
        history.push(`/my-account/intake-forms`);
      } catch (error) {}
    } else {
      setIsLoading(true);
      try {
        await saveIntakeForm21({
          patient_id: patientId,
          doctor_id: doctorId,
          fact_form_id,
          intake_form_values: values,
        });
        history.push(`/my-account/intake-forms`);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const formatValues = (values) => {
    if (providerValue && Object.values(providerValue).every(Boolean)) {
      if (!values.current_medications) {
        values.currentMedications = [];
      }
      values.current_medications.push({
        name: providerValue.name,
        specialty: providerValue.specialty,
        phone: providerValue.phone,
      });
    }

    if (facilityValue) {
      if (!values.past_treatment_facilities) {
        values.past_treatment_facilities = [];
      }
      values.past_treatment_facilities.push({
        facility_name: facilityValue.facilityName,
        treatment_date: facilityValue.startingDate,
        treatmentLength: facilityValue.treatmentLength,
        issuesAddressed: facilityValue.issuesAddressed,
      });
    }
    setProviderValue();
    setFacilityValue();
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.form}>
      <Formik
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        enableReinitialize
        validationsSchema={validate}
        initialValues={initialValues}
        render={({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          dirty,
        }) => {
          const handleCheckboxChange = (arrOfItems, value) => {
            const itemIndex = arrOfItems.indexOf(value);
            itemIndex === -1
              ? arrOfItems.push(value)
              : arrOfItems.splice(itemIndex, 1);
            return arrOfItems;
          };

          const bpsFormSteps = [
            BPSFormStepOne,
            BPSFormStepTwo,
            BPSFormStepThree,
            BPSFormStepFour,
            BPSFormStepFive,
            BPSFormStepSix,
            BPSFormStepSeven,
          ].map((FormStep, i) => (
            <FormStep
              key={i}
              isLoading={isLoading}
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              editItemProvider={editItemProvider}
              providerValue={providerValue}
              setProviderValue={setProviderValue}
              setEditItemProvider={setEditItemProvider}
              setFacilityValue={setFacilityValue}
              editItemFacility={editItemFacility}
              facilityValue={facilityValue}
              setEditItemFacility={setEditItemFacility}
              handleCheckboxChange={handleCheckboxChange}
            />
          ));

          return (
            <Form onKeyPress={onKeyPress} data-testid="Personal-info-form">
              <Grid>
                <Cell small={12}>
                  <div style={bpsCustomStyles.paginationContainer}>
                    <h2 className={styles.formTitle}>
                      {formatMessage(messages.formTitle)}
                    </h2>
                    <p style={bpsCustomStyles.pageNumber}>{`Page ${
                      formStep + 1
                    } of ${bpsFormSteps.length}`}</p>
                  </div>
                </Cell>
                <Cell small={12}>
                  <p className={styles.formSubtitle}>
                    {formatMessage(messages.formSubtitle)}
                  </p>
                </Cell>
                {bpsFormSteps[formStep]}
                <Cell medium={12} flexContainer>
                  {formStep > 0 && (
                    <div style={bpsCustomStyles.leftButtonStyles}>
                      <SecondaryButton
                        type="button"
                        label={'Back'}
                        onClick={prevStep}
                        className="font-18"
                      />
                    </div>
                  )}
                  {formStep < bpsFormSteps.length - 1 && (
                    <PrimaryButton
                      type="button"
                      label={'Next'}
                      onClick={nextStep}
                      className="font-18"
                    />
                  )}
                  {formStep === bpsFormSteps.length - 1 && (
                    <PrimaryButton
                      disabled={!dirty}
                      label={'Submit'}
                      type="submit"
                      className="font-18"
                    />
                  )}
                </Cell>
              </Grid>
            </Form>
          );
        }}
      />
    </div>
  );
};

BPSForm.defaultProps = {};

export default BPSForm;
