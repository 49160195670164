import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.doctorPaymentPage.title',
    description: 'Doctor Payment Page title',
    defaultMessage: 'Payment',
  },
  balanceTitle: {
    id: 'pages.doctorPaymentPage.balanceTitle',
    description: 'Balance title',
    defaultMessage: 'USD Balance',
  },
  payoutButtonLabel: {
    id: 'pages.doctorPaymentPage.payoutButtonLabel',
    defaultMessage: 'Pay out to bank',
    description: 'payout button label',
  },
  topUpButtonLabel: {
    id: 'pages.doctorPaymentPage.topUpButtonLabel',
    description: 'Top up balance button label',
    defaultMessage: 'Top up balance',
  },
  availablePayoutLabel: {
    id: 'pages.doctorPaymentPage.availablePayoutLabel',
    description: 'Available to pay out label',
    defaultMessage: 'Available to pay out',
  },
  onTheWayToYourBankLabel: {
    id: 'pages.doctorPaymentPage.onTheWayToYourBankLabel',
    description: 'On the way to your bank label',
    defaultMessage: 'On the way to your bank',
  },
  willBeAvailableSoonLabel: {
    id: 'pages.doctorPaymentPage.willBeAvailableSoonLabel',
    description: 'Will be available soon label',
    defaultMessage: 'Will be available soon',
  },
  totalLabel: {
    id: 'pages.doctorPaymentPage.totalLabel',
    description: 'Total label',
    defaultMessage: 'Total',
  },
  fundWillBeAvailableSoonLabel: {
    id: 'pages.doctorPaymentPage.fundWillBeAvailableSoonLabel',
    description: 'These funds should arrive in your bank account soon label',
    defaultMessage: 'These funds should arrive in your bank account soon.',
  },
});
