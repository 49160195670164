const emailValidator = (email) => {
  let errors = {};
  if (!email) {
    errors.email = 'Email is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

const ageValidator = (age) => {
  let errors = {};
  if (!/^(1[89]|[2-9]\d)$/i.test(age)) {
    errors.age = 'Provide a valid age between 18 and 99';
  }
  return errors;
};

const requiredValidator = (value, key = 'field') => {
  const errors = {};
  if (!value) {
    errors[key] = 'Field is required';
  }
  return errors;
};
const radioValidator = (value, key = 'field') => {
  const errors = {};
  if (value === undefined) {
    errors[key] = 'Field is required';
  }
  return errors;
};

const phoneValidator = (phone, key = 'field') => {
  let errors = {};
  if (!phone) {
    errors[key] = 'Field is required';
  } else if (phone.length < 6 || !/^[0-9\b]+$/.test(phone)) {
    errors[key] = 'Please enter valid phone number';
  }
  return errors;
};

const passwordValidator = (value, key = 'password') => {
  const errors = {};
  if (!value) {
    errors[key] = 'Password is required';
  } else if (
    value.length < 8 ||
    !/\d/.test(value) ||
    !/[a-z]/.test(value) ||
    !/[A-Z]/.test(value)
  ) {
    errors[
      key
    ] = `Password length of at least 8 characters, uppercase and number`;
  }

  return errors;
};

export {
  ageValidator,
  emailValidator,
  passwordValidator,
  requiredValidator,
  phoneValidator,
  radioValidator,
};
