import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import colors from '../../../../styles/_colors.scss';

const styles = {
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      borderRadius: '100px',
    };
  },
  menu: (provided, state) => ({
    ...provided,
    zIndex: 10,
  }),
  control: (base) => ({
    ...base,
    borderRadius: '100px',
    width: '100%',
    boxShadow: 0,
    minHeight: '3.25rem',
    border: `1px solid #d8d8d8`,
    '&:hover': {
      border: `1px solid ${colors.grayBorder}`,
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: colors.black,
    paddingRight: '0.5rem',
    '&:hover': {
      color: colors.black,
    },
  }),
};

const Dropdown = ({
  options,
  onChange,
  value,
  isSearchable,
  placeholder,
  isMulti,
  'data-testid': datatestid,
}) => (
  <Select
    id={`select_${datatestid}`}
    options={options}
    onChange={(value, event) => {
      onChange(value, event);
    }}
    value={isMulti ? value : options.find((option) => option.value === value)}
    isSearchable={isSearchable}
    styles={styles}
    isMulti={isMulti}
    theme={(theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary25: colors.grayLight2,
        primary: colors.black,
        primary50: colors.grayLight2,
      },
    })}
    components={{
      IndicatorSeparator: () => null,
    }}
    placeholder={placeholder}
  />
);

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]).isRequired,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
};

Dropdown.defaultProps = {
  isSearchable: false,
  isMulti: false,
  placeholder: '',
};

export default Dropdown;
