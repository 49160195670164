import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'react-foundation';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import OverlayContext from '../../../context/OverlayContext';
import BoxContext from '../../../context/BoxContext';
import UserPopup from '../UserPopup';
import styles from './DesktopNav.module.scss';
import Logo from '../../Logo';

const DesktopNav = ({
  isHomePage,
  isSticky,
  history,
  isDoctor,
  isResearcher,
}) => {
  const { showLearnMenuOverlay, setShowLearnMenuOverlay } =
    useContext(OverlayContext);
  const { isBoxOpen } = useContext(BoxContext);
  const [isShopMenuOpen, setIsShopMenuOpen] = useState(false);
  const [isLearnMenuOpen, setIsLearnMenuOpen] = useState(false);
  const [isExploreMenuOpen, setIsExploreMenuOpen] = useState(false);

  const isTransparentBanner =
    isHomePage &&
    !isShopMenuOpen &&
    !isLearnMenuOpen &&
    !isExploreMenuOpen &&
    !isBoxOpen &&
    !isSticky;

  // disable scroll when showing the cart
  useEffect(() => {
    if (showLearnMenuOverlay) {
      setIsLearnMenuOpen(true);
    }
    return isLearnMenuOpen || isShopMenuOpen || isExploreMenuOpen
      ? document.body.classList.add('no-scroll')
      : document.body.classList.remove('no-scroll');
  }, [
    isShopMenuOpen,
    isLearnMenuOpen,
    isExploreMenuOpen,
    setIsLearnMenuOpen,
    showLearnMenuOverlay,
  ]);

  return (
    <div
      className={classnames('desktop-only', styles.container, {
        [styles.home]: isTransparentBanner,
        [styles['overlay-open']]:
          isShopMenuOpen || isLearnMenuOpen || isExploreMenuOpen || isBoxOpen,
        'fixed-nav': isSticky,
      })}
    >
      <div className={styles['menu-left']}>
        <Menu>
          {/* {!isDoctor && (
            <MenuItem>
              <Link
                to="/my-account/discoverv2"
                onClick={() => {
                  document.body.classList.remove("no-scroll");
                  setIsShopMenuOpen(false);
                  setIsLearnMenuOpen(false);
                  setIsExploreMenuOpen(false);
                  setShowLearnMenuOverlay(false);
                }}
              >
                {formatMessage(messages.discoverLink)}
              </Link>
            </MenuItem>
          )} */}
          {/* {!isDoctor && <MenuItem>
            <ShopDropDown history={history} />
          </MenuItem>}
          {!isDoctor && <MenuItem>
            <LearnDropDown history={history} />
          </MenuItem>}
          <MenuItem>
            <span
              onClick={() => {
                setIsShopMenuOpen(false);
                setIsLearnMenuOpen(false);
                setIsExploreMenuOpen(!isExploreMenuOpen);
                setShowLearnMenuOverlay(false);
              }}
            >
              {formatMessage(messages.exploreLink)}
            </span>
          </MenuItem> */}
        </Menu>
      </div>
      <div className={styles.logo}>
        <Link
          to={
            isDoctor
              ? '/doctor/my-account'
              : isResearcher
              ? '/researcher/dashboard'
              : '/my-account'
          }
          onClick={() => {
            setIsShopMenuOpen(false);
            setIsLearnMenuOpen(false);
            setIsExploreMenuOpen(false);
            setShowLearnMenuOverlay(false);
          }}
        >
          <Logo />
        </Link>
      </div>
      <div className={styles['menu-right']}>
        {/* <SearchMenuItem theme={"default"} /> */}
        <UserPopup theme={'default'} />
        {/* {!isDoctor && <Minicart
          theme={"default"}
          isSticky={isSticky}
        />} */}
      </div>
      {/* {isShopMenuOpen && (
        <ShopMenuOverlay
          onClick={() => setIsShopMenuOpen(false)}
          isSticky={isSticky}
        />
      )}
      {(isLearnMenuOpen) && (
        <LearnMenuOverlay
          onClick={() => { setIsLearnMenuOpen(false); setShowLearnMenuOverlay(false); }}
          isSticky={isSticky}
        />
      )}
      {isExploreMenuOpen && (
        <ExploreMenuOverlay
          onClick={() => setIsExploreMenuOpen(false)}
          isSticky={isSticky}
        />
      )} */}
    </div>
  );
};

DesktopNav.propTypes = {
  isHomePage: PropTypes.bool,
  isSticky: PropTypes.bool,
  isDoctor: PropTypes.bool,
};

DesktopNav.defaultProps = {
  isHomePage: false,
  isSticky: false,
  isDoctor: false,
};

export default DesktopNav;
