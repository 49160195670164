import React, { useState, Fragment, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import _ from 'lodash';
import classnames from 'classnames';
import styles from './EditActiveProtocolPage.module.scss';
import DoctorLeftNav from '../../../components/nav/DoctorLeftNav';
import Loading from '../../../components/common/Loading';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import AuthContext from '../../../context/AuthContext';
import { Formik, Form, Field } from 'formik';
import { Cell, Grid } from 'react-foundation';
import FieldInput from '../../../components/common/fields/FieldInput/FieldInput';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import { getActiveProtocol, getAllMyClients } from '../../../api/doctor';
import MultiSelectDropdown from '../../../components/common/inputs/MultiSelectDropdown/MultiSelectDropdown';
import Modal from '../../../components/common/Modal';
import Datepicker from '../../../components/common/Datepicker';
import moment from 'moment';
import FieldDropdown from '../../../components/common/fields/FieldDropdown';
import upArrow from '../../../img/icons/up-arrow.svg';
import downArrow from '../../../img/icons/down-arrow.svg';
import RadioButton from '../../../components/common/inputs/RadioButton/RadioButton';
import CheckBox from '../../../components/common/inputs/CheckBox';
import { compareFun } from '../../../utils/common';
import { getPatientList, updateSchedule } from '../../../api/doctor';
import MessageModal from '../../../components/MessageModal';
import { SCHEDULE_OPTIONS, PATIENT_DETAILS_STATUS } from '../../../constants';

const repetitionEndDateValues = {
  never: 'Never',
  on: 'On',
  after: 'After',
};

const patientSelectionType = {
  allClinicClients: 'ALL_CLINIC_CLIENTS',
  allMyClients: 'ALL_MY_CLIENTS',
};

const repeatOptionsValues = {
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year',
};

const momentKeys = {
  day: 'days',
  week: 'weeks',
  month: 'months',
  year: 'years',
};

const repeatOptionsConst = [
  {
    value: repeatOptionsValues.day,
    label: 'Day',
  },
  {
    value: repeatOptionsValues.week,
    label: 'Week',
  },
  {
    value: repeatOptionsValues.month,
    label: 'Month',
  },
  {
    value: repeatOptionsValues.year,
    label: 'Year',
  },
];

const initialScheduleOptions = [
  {
    value: '',
    label: 'Select...',
  },
  {
    value: SCHEDULE_OPTIONS.now.value,
    label: SCHEDULE_OPTIONS.now.label,
  },
  {
    value: SCHEDULE_OPTIONS.scheduleDate.value,
    label: SCHEDULE_OPTIONS.scheduleDate.label,
  },
  {
    value: SCHEDULE_OPTIONS.onRegistration.value,
    label: SCHEDULE_OPTIONS.onRegistration.label,
  },
];

const EditActiveProtocolPage = () => {
  const history = useHistory();
  const { id: activeProtocolId } = useParams();
  const [initialFormValues, setInitialFormValues] = useState({
    id: '',
    template_name: '',
    assignee: [],
    schedule: '',
    forms: [],
  });
  const { isLoading, authUser } = useContext(AuthContext);
  const [dataLoading, setLoading] = useState(false);
  const [showScheduleSelectorModel, setShowScheduleSelectorModel] =
    useState(false);
  const [formattedFormLists] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [allMyPatients, setAllMyPatients] = useState([]);
  const [formattedScheduleObject, setFormattedScheduleObject] = useState({
    checkRepetitionCompletionDate: true,
    endDate: '',
    occurrenceCount: 0,
    repeat: 'day',
    repeatCount: 0,
    repetitionEndDate: repetitionEndDateValues.never,
    startDate: new Date(),
  });
  const [templateId, setTemplateId] = useState(null);
  const [isScheduleFormFilled, setIsScheduleFormFilled] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [displayMessage, setDisplayMessage] = useState({
    title: '',
    message: '',
  });
  const [isScheduleDisable, setIsScheduleDisable] = useState(true);
  const [scheduleOptions, setScheduleOptions] = useState(
    initialScheduleOptions,
  );
  const [endDateLimit, setEndDateLimit] = useState({ count: 0, unit: 'days' });

  const isDataLoading = dataLoading > 0 || isLoading;

  const scheduleSchema = Yup.object().shape({
    startDate: Yup.string().required('Field is Required'),
    repeat: Yup.string().required('Field is Required'),
    repeatCount: Yup.number()
      .min(0, 'Number out of range')
      .required('Field is Required'),
    endDate: Yup.string().when('repetitionEndDate', {
      is: (val) => val === repetitionEndDateValues.on,
      then: Yup.string().required('Field is Required'),
    }),
    occurrenceCount: Yup.number().when('repetitionEndDate', {
      is: (val) => val === repetitionEndDateValues.after,
      then: Yup.number()
        .min(0, 'Number out of range')
        .required('Field is Required'),
    }),
  });

  const createTemplateSchema = Yup.object().shape({
    template_name: Yup.string().required('Field Required'),
    assignee: Yup.array().min(1).required('Field Must Have At Least 1 Item'),
    schedule: Yup.string()
      .test('test-scheduleSet', 'Please choose the schedule dates.', (value) =>
        value === SCHEDULE_OPTIONS.scheduleDate.value
          ? isScheduleFormFilled
          : true,
      )
      .required('Field Required'),
  });

  const addOptionIntakeForNewClients = () => {
    if (
      !scheduleOptions.find(
        (option) => option.value === SCHEDULE_OPTIONS.intakeForNewClients.value,
      )
    ) {
      const options = scheduleOptions.concat({
        value: SCHEDULE_OPTIONS.intakeForNewClients.value,
        label: SCHEDULE_OPTIONS.intakeForNewClients.label,
      });
      setScheduleOptions(options);
    }
  };
  const removeOptionIntakeForNewClients = () => {
    setScheduleOptions(
      scheduleOptions.filter(
        (option) => option.value !== SCHEDULE_OPTIONS.intakeForNewClients.value,
      ),
    );
  };

  const incrementRepeatCounter = async (
    currentValue,
    maxValue,
    setFieldValue,
    fieldName,
  ) => {
    if (currentValue < maxValue) {
      currentValue = currentValue + 1;
      setFieldValue(fieldName, currentValue);
      if (fieldName === 'repeatCount') {
        setEndDateLimit({ ...endDateLimit, count: currentValue });
      }
    }
  };
  const decrementRepeatCounter = async (
    currentValue,
    minValue,
    setFieldValue,
    fieldName,
  ) => {
    if (currentValue > minValue) {
      currentValue = currentValue - 1;
      setFieldValue(fieldName, currentValue);
      if (fieldName === 'repeatCount') {
        setEndDateLimit({ ...endDateLimit, count: currentValue });
      }
    }
  };

  const fetchActiveProtocol = async () => {
    setLoading((prev) => prev + 1);
    try {
      if (authUser.id) {
        const protocol = await getActiveProtocol(activeProtocolId);
        const {
          id,
          ScheduleProtocols,
          assign_to,
          used_for_new_patient,
          repeat_every,
          occurrences,
          end_date,
          start_date,
          repetition_on_completion_date,
          used_for_new_registration,
        } = protocol && protocol.activeProtocol;
        const { id: templateId, name: template_name } =
          ScheduleProtocols[0]?.ProtocolTemplate;
        setTemplateId(templateId);

        // getting form list
        const formattedFormLists =
          ScheduleProtocols[0]?.ProtocolTemplate?.ProtocolTemplateForms?.map(
            (list) => {
              return {
                label: list.dim_fo,
                value: list.form_i,
                isFixed: true,
              };
            },
          );

        // getting assignees and populating it in react-select component's options
        let formattedPatientList = [];
        const patients = ScheduleProtocols.map((schedule) => {
          const { Patient } = schedule;
          return {
            value: `${Patient.id}`,
            label: `${Patient.first_name} ${
              Patient.last_name ? Patient.last_name : ''
            }`,
          };
        });
        if (patients.length > 0) {
          setIsScheduleDisable(false);
        }
        if (assign_to === patientSelectionType.allMyClients) {
          formattedPatientList = formattedPatientList.concat([
            {
              value: patientSelectionType.allMyClients,
              label: `- All My Clients -`,
            },
          ]);
          addOptionIntakeForNewClients();
        }
        if (assign_to === patientSelectionType.allClinicClients) {
          formattedPatientList = formattedPatientList.concat([
            {
              value: patientSelectionType.allClinicClients,
              label: `- All Clinic Clients -`,
            },
          ]);
          addOptionIntakeForNewClients();
        }
        formattedPatientList = formattedPatientList.concat(patients);

        let repetitionOnCompletionStatus = repetition_on_completion_date;

        // getting schedule type
        let schedule = used_for_new_patient
          ? SCHEDULE_OPTIONS.intakeForNewClients.value
          : SCHEDULE_OPTIONS.scheduleDate.value;
        if (used_for_new_patient) {
          schedule = SCHEDULE_OPTIONS.intakeForNewClients.value;
          repetitionOnCompletionStatus = true;
        } else if (used_for_new_registration) {
          schedule = SCHEDULE_OPTIONS.onRegistration.value;
          repetitionOnCompletionStatus = true;
        } else {
          schedule = SCHEDULE_OPTIONS.scheduleDate.value;
        }

        // getting populating schedule model-form initial values
        const repeatEvery = repeat_every?.split('/');
        onRepeatChange(
          repeatEvery ? repeatEvery[1] : 'day',
          repeatEvery ? Number(repeatEvery[0]) : 0,
        );
        const repetitionEndDate = end_date
          ? occurrences > 0
            ? repetitionEndDateValues.after
            : repetitionEndDateValues.on
          : repetitionEndDateValues.never;

        setFormattedScheduleObject({
          checkRepetitionCompletionDate: repetitionOnCompletionStatus,
          endDate: end_date ? end_date : '',
          occurrenceCount: occurrences,
          repeat: repeatEvery ? repeatEvery[1] : 'day',
          repeatCount: repeatEvery ? Number(repeatEvery[0]) : 0,
          repetitionEndDate,
          startDate: start_date,
        });
        setIsScheduleFormFilled(true);

        setInitialFormValues({
          ...initialFormValues,
          id,
          schedule,
          forms: formattedFormLists,
          template_name,
          assignee: formattedPatientList,
        });
      }
      setLoading((prev) => prev - 1);
    } catch (error) {
      console.log(error);
      setLoading((prev) => prev - 1);
    }
  };

  const fetchPatientList = async () => {
    setLoading((prev) => prev + 1);
    try {
      if (authUser.clinic_id) {
        const params = {
          doctor_id: authUser.id,
        };
        let { patientList } = await getPatientList(params);

        let patients = patientList
          .map((patients) => patients.patient)
          .filter((x) => x.status === PATIENT_DETAILS_STATUS.ACTIVE);

        patients = patients.sort((a, b) => {
          return compareFun(
            a.first_name.toLowerCase(),
            b.first_name.toLowerCase(),
            'Asc',
          );
        });
        let formattedPatientList = [
          {
            value: patientSelectionType.allMyClients,
            label: `- All My Clients -`,
          },
        ];

        if (authUser && authUser.clinics && authUser.clinics.id) {
          formattedPatientList = formattedPatientList.concat([
            {
              value: patientSelectionType.allClinicClients,
              label: `- All Clinic Clients -`,
            },
          ]);
        }

        const list = patients.map((patient) => {
          return {
            value: `${patient.id}`,
            label: `${patient.first_name} ${
              patient.last_name ? patient.last_name : ''
            }`,
          };
        });
        formattedPatientList = formattedPatientList.concat(list);
        setPatientList(formattedPatientList);
      }
      setLoading((prev) => prev - 1);
    } catch (error) {
      console.log(error);
      setLoading((prev) => prev - 1);
    }
  };

  const fetchAllMyClients = async () => {
    setLoading((prev) => prev + 1);
    try {
      if (authUser.clinic_id) {
        const doctorId = authUser.id;
        let { Patients } = await getAllMyClients(doctorId);

        Patients = Patients.sort((a, b) => {
          return compareFun(
            a.first_name.toLowerCase(),
            b.first_name.toLowerCase(),
            'Asc',
          );
        });
        let formattedPatientList = [
          {
            value: patientSelectionType.allMyClients,
            label: `- All My Clients -`,
          },
        ];

        if (authUser && authUser.clinics && authUser.clinics.id) {
          formattedPatientList = formattedPatientList.concat([
            {
              value: patientSelectionType.allClinicClients,
              label: `- All Clinic Clients -`,
            },
          ]);
        }

        const list = Patients.map((patient) => {
          return {
            value: `${patient.id}`,
            label: `${patient.first_name} ${
              patient.last_name ? patient.last_name : ''
            }`,
          };
        });
        formattedPatientList = formattedPatientList.concat(list);

        setAllMyPatients(formattedPatientList);
      }
      setLoading((prev) => prev - 1);
    } catch (error) {
      console.log(error);
      setLoading((prev) => prev - 1);
    }
  };

  const onUpdateSchedule = async (values) => {
    try {
      setLoading((prev) => prev + 1);
      const assignee = values.assignee.map((assignee) => assignee.value);

      let body = {
        id: values.id,
        clinic_id: authUser.clinic_id,
        created_by: authUser.id,
        templateId: Number(templateId),
        assignee,
        schedule: values.schedule,
        used_for_new_patient:
          values.schedule === SCHEDULE_OPTIONS.intakeForNewClients.value,
        used_for_new_registration:
          values.schedule === SCHEDULE_OPTIONS.onRegistration.value,
      };
      if (!_.isEmpty(formattedScheduleObject)) {
        body = {
          ...body,
          start_date: formattedScheduleObject.startDate,
          end_date: formattedScheduleObject.endDate || null,
          repeat_every: `${formattedScheduleObject.repeatCount}/${formattedScheduleObject.repeat}`,
          occurrences: formattedScheduleObject.occurrenceCount,
          next_scheduled_time: moment(formattedScheduleObject.startDate).add(
            formattedScheduleObject.repeatCount,
            momentKeys[formattedScheduleObject.repeat],
          ),
          repetition_on_completion_date:
            formattedScheduleObject.repeatCount > 0
              ? formattedScheduleObject.checkRepetitionCompletionDate
              : false,
        };
      }
      if (values.schedule === SCHEDULE_OPTIONS.now.value) {
        body = {
          ...body,
          current_status: 'COMPLETE',
          repeat_every: null,
          end_date: null,
          repetition_on_completion_date: false,
          occurrences: 0,
        };
      }
      if (
        values.schedule === SCHEDULE_OPTIONS.intakeForNewClients.value ||
        values.schedule === SCHEDULE_OPTIONS.onRegistration.value
      ) {
        body = {
          ...body,
          start_date: new Date(),
          repeat_every: null,
          end_date: null,
          repetition_on_completion_date: false,
          occurrences: 0,
        };
      }
      if (
        values.assignee.find(
          (assignee) =>
            assignee.value === patientSelectionType.allClinicClients,
        )
      ) {
        const assigneeFormatted = body.assignee.filter(
          (assignee) => assignee !== patientSelectionType.allClinicClients,
        );
        body = {
          ...body,
          assignee: assigneeFormatted,
          assign_to: 'ALL_CLINIC_CLIENTS',
        };
      } else if (
        values.assignee.find(
          (assignee) => assignee.value === patientSelectionType.allMyClients,
        )
      ) {
        const assigneeFormatted = body.assignee.filter(
          (assignee) => assignee !== patientSelectionType.allMyClients,
        );
        body = {
          ...body,
          assignee: assigneeFormatted,
          assign_to: 'ALL_MY_CLIENTS',
        };
      } else {
        body = {
          ...body,
          assign_to: 'SELECTIVELY',
        };
      }
      await updateSchedule(body);
      setLoading((prev) => prev - 1);
      setDisplayMessage({
        type: 'success',
        title: 'Done',
        text: 'Protocol successfully updated',
      });
      setShowMessageModal(true);
      setFormattedScheduleObject({});
      setIsScheduleFormFilled(false);
    } catch (error) {
      console.log('error: ', error);
      setLoading((prev) => prev - 1);
      setDisplayMessage({
        type: 'error',
        title: 'whoops!',
        text: 'Failed to update template',
      });
      setShowMessageModal(true);
    }
  };

  const handleScheduleModelSubmit = (values) => {
    let scheduledValues = values;
    if (values.repetitionEndDate === repetitionEndDateValues.after) {
      scheduledValues = {
        ...scheduledValues,
        endDate: moment(values.startDate)
          .add(
            Number(values.occurrenceCount) * Number(values.repeatCount),
            momentKeys[values.repeat],
          )
          .startOf('day')
          .toDate(),
      };
    }
    if (values.repetitionEndDate === repetitionEndDateValues.never) {
      delete scheduledValues.endDate;
      scheduledValues.occurrenceCount = 0;
    }
    if (values.repetitionEndDate === repetitionEndDateValues.on) {
      scheduledValues.occurrenceCount = 0;
    }
    setFormattedScheduleObject(scheduledValues);
    setIsScheduleFormFilled(true);
    setShowScheduleSelectorModel(false);
  };

  const onRepeatChange = (repeat, repeatCount) => {
    if (repeat === repeatOptionsValues.day) {
      setEndDateLimit({ count: repeatCount, unit: 'days' });
    } else if (repeat === repeatOptionsValues.week) {
      setEndDateLimit({ count: repeatCount, unit: 'weeks' });
    } else if (repeat === repeatOptionsValues.month) {
      setEndDateLimit({ count: repeatCount, unit: 'months' });
    } else if (repeat === repeatOptionsValues.year) {
      setEndDateLimit({ count: repeatCount, unit: 'years' });
    }
  };

  const onAssigneeSelect = (selectedValues, event, setFieldValue) => {
    const isAllOptionPresent = selectedValues?.find(
      (patient) =>
        patient.value === patientSelectionType.allClinicClients ||
        patient.value === patientSelectionType.allMyClients,
    );
    if (isAllOptionPresent) {
      addOptionIntakeForNewClients();
    }
    if (selectedValues?.length <= 0 || !selectedValues) {
      setIsScheduleDisable(true);
      removeOptionIntakeForNewClients();
      setFieldValue('schedule', '');
    } else {
      setIsScheduleDisable(false);
    }
    if (
      event.action === 'select-option' &&
      event.option.value === patientSelectionType.allClinicClients
    ) {
      setFieldValue(
        'assignee',
        patientList.filter(
          (patient) => patient.value !== patientSelectionType.allMyClients,
        ),
      );
    } else if (
      event.action === 'select-option' &&
      event.option.value === patientSelectionType.allMyClients
    ) {
      setFieldValue(
        'assignee',
        allMyPatients.filter(
          (patient) => patient.value !== patientSelectionType.allClinicClients,
        ),
      );
    } else if (
      event.action === 'deselect-option' &&
      (event.option.value === patientSelectionType.allClinicClients ||
        event.option.value === patientSelectionType.allMyClients)
    ) {
      setIsScheduleDisable(true);
      removeOptionIntakeForNewClients();
      setFieldValue('schedule', '');
      setFieldValue('assignee', []);
    } else if (
      event.action === 'remove-value' &&
      (event.removedValue.value === patientSelectionType.allClinicClients ||
        event.removedValue.value === patientSelectionType.allMyClients)
    ) {
      setIsScheduleDisable(true);
      removeOptionIntakeForNewClients();
      setFieldValue('schedule', '');
      setFieldValue('assignee', []);
    } else if (
      event.action === 'deselect-option' &&
      (event.option.value !== patientSelectionType.allClinicClients ||
        event.option.value !== patientSelectionType.allMyClients)
    ) {
      setFieldValue(
        'assignee',
        selectedValues?.filter(
          (patient) =>
            patient.value !== patientSelectionType.allClinicClients &&
            patient.value !== patientSelectionType.allMyClients,
        ),
      );
    } else if (
      event.action === 'remove-value' &&
      (event.removedValue.value !== patientSelectionType.allClinicClients ||
        event.removedValue.value !== patientSelectionType.allMyClients)
    ) {
      setFieldValue(
        'assignee',
        selectedValues?.filter(
          (patient) =>
            patient.value !== patientSelectionType.allClinicClients &&
            patient.value !== patientSelectionType.allMyClients,
        ),
      );
      removeOptionIntakeForNewClients();
      setFieldValue('schedule', '');
    } else if (selectedValues?.length === patientList.length - 1) {
      setFieldValue('assignee', []);
    } else {
      setFieldValue('assignee', selectedValues);
    }
  };

  useEffect(() => {
    fetchActiveProtocol();
    fetchPatientList();
    fetchAllMyClients();
  }, [authUser]);

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <DoctorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div className={styles.header}>
              <h1 data-testid="edit_protocol header">Edit Protocol</h1>
            </div>

            <Formik
              initialValues={initialFormValues}
              validationSchema={createTemplateSchema}
              onSubmit={async (values) => {
                onUpdateSchedule(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form className={styles.form}>
                    <div>
                      <Grid>
                        <Cell small={12} large={3}>
                          <FieldInput
                            disabled
                            autoComplete="template_name"
                            autoFocus={true}
                            label="Name"
                            errorText={
                              touched.template_name && errors.template_name
                            }
                            name="template_name"
                            value={values.template_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            data-testid="template_name"
                          />
                        </Cell>
                      </Grid>
                      <Grid>
                        <Cell small={12} large={4}>
                          <div className={styles.card}>
                            <div className={styles.cardTitle}>
                              <p>Forms and Protocols</p>
                            </div>
                            <Grid>
                              <Cell
                                small={12}
                                medium={12}
                                className={
                                  (styles['label-bold'], styles.inputCell)
                                }
                              >
                                <div>
                                  <MultiSelectDropdown
                                    isMulti
                                    isDisabled
                                    errorText={touched.forms && errors.forms}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    isSearchable={false}
                                    value={values.forms}
                                    options={formattedFormLists}
                                    name="forms"
                                    onChange={(selectedValues) => {
                                      handleChange(selectedValues);
                                      setFieldValue('forms', selectedValues);
                                    }}
                                    onBlur={handleBlur}
                                    classNamePrefix="select"
                                    placeholder="Select..."
                                    data-testid="edit_template_forms"
                                  />
                                </div>
                              </Cell>
                            </Grid>
                          </div>
                        </Cell>
                        <Cell small={12} large={4}>
                          <div className={styles.card}>
                            <div className={styles.cardTitle}>
                              <p data-testid="edit_template_assignedto label">
                                Assign to
                              </p>
                            </div>
                            <Grid>
                              <Cell
                                small={12}
                                medium={12}
                                className={
                                  (styles['label-bold'], styles.inputCell)
                                }
                              >
                                <div>
                                  <MultiSelectDropdown
                                    isMulti
                                    errorText={
                                      touched.assignee && errors.assignee
                                    }
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    isSearchable={false}
                                    value={values.assignee}
                                    options={patientList}
                                    name="assignee"
                                    onChange={(selectedValues, event) => {
                                      onAssigneeSelect(
                                        selectedValues,
                                        event,
                                        setFieldValue,
                                      );
                                    }}
                                    onBlur={handleBlur}
                                    classNamePrefix="select"
                                    placeholder="Select..."
                                    data-testid="edit_template_assignedto"
                                  />
                                </div>
                              </Cell>
                            </Grid>
                          </div>
                        </Cell>
                        <Cell small={12} large={4}>
                          <div className={styles.card}>
                            <div className={styles.cardTitle}>
                              <p data-testid="edit_template_scheduling label">
                                Scheduling
                              </p>
                            </div>
                            <Grid>
                              <Cell
                                small={12}
                                medium={12}
                                className={
                                  (styles['label-bold'], styles.inputCell)
                                }
                              >
                                <div>
                                  <MultiSelectDropdown
                                    isDisabled={isScheduleDisable}
                                    errorText={
                                      touched.schedule && errors.schedule
                                    }
                                    hideSelectedOptions={false}
                                    isSearchable={false}
                                    value={values.schedule}
                                    options={scheduleOptions}
                                    name="schedule"
                                    onChange={(selectedValues) => {
                                      if (
                                        selectedValues.value ===
                                        SCHEDULE_OPTIONS.scheduleDate.value
                                      )
                                        setShowScheduleSelectorModel(true);
                                      handleChange(selectedValues);
                                      setFieldValue(
                                        'schedule',
                                        selectedValues.value,
                                      );
                                    }}
                                    onBlur={handleBlur}
                                    classNamePrefix="select"
                                    placeholder="Select..."
                                    data-testid="edit_template_schedule"
                                  />
                                </div>
                              </Cell>
                              {isScheduleFormFilled && (
                                <Cell
                                  small={12}
                                  medium={12}
                                  className={styles.scheduleSummarySection}
                                >
                                  <div className={styles.scheduleSummaryColumn}>
                                    <p
                                      className={styles.scheduleSummaryHeader}
                                      data-testid="edit_template_schedule_start_date label"
                                    >
                                      Start Date
                                    </p>
                                    <p
                                      className={styles.scheduleSummaryBody}
                                      data-testid="edit_template_schedule_start_date value"
                                    >
                                      {moment(
                                        formattedScheduleObject?.startDate,
                                      ).format('DD/MM/YY')}
                                    </p>
                                  </div>
                                  <div className={styles.scheduleSummaryColumn}>
                                    <p
                                      className={styles.scheduleSummaryHeader}
                                      data-testid="edit_template_schedule_end_date label"
                                    >
                                      End Date
                                    </p>
                                    <p
                                      className={styles.scheduleSummaryBody}
                                      data-testid="edit_template_schedule_end_date value"
                                    >
                                      {formattedScheduleObject?.endDate
                                        ? moment(
                                            formattedScheduleObject?.endDate,
                                          ).format('DD/MM/YY')
                                        : 'Never'}
                                    </p>
                                  </div>
                                  <div className={styles.scheduleSummaryColumn}>
                                    <p
                                      className={styles.scheduleSummaryHeader}
                                      data-testid="edit_template_schedule_repeat label"
                                    >
                                      Repeat every
                                    </p>
                                    <p
                                      className={styles.scheduleSummaryBody}
                                      data-testid="edit_template_schedule_repeat value"
                                    >
                                      {formattedScheduleObject?.repeatCount &&
                                      formattedScheduleObject?.repeat
                                        ? `${formattedScheduleObject?.repeatCount} ${formattedScheduleObject?.repeat}`
                                        : 0}
                                    </p>
                                  </div>
                                </Cell>
                              )}
                            </Grid>
                          </div>
                        </Cell>
                      </Grid>
                    </div>
                    <div>
                      <Grid>
                        <Cell
                          small={12}
                          medium={12}
                          large={12}
                          flexContainer
                          alignY="middle"
                          className={styles['actions-container']}
                        >
                          <SecondaryButton
                            label="Back"
                            type="button"
                            className={styles.editButton}
                            onClick={() => history.goBack()}
                            data-testid="edit_protocol back button"
                          />
                          <PrimaryButton
                            label="Save"
                            type="button"
                            className={styles.editButton}
                            data-testid="edit_protocol save button"
                          />
                        </Cell>
                      </Grid>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          {showScheduleSelectorModel && (
            <Modal className={styles.scheduleModal}>
              <Formik
                initialValues={formattedScheduleObject}
                validationSchema={scheduleSchema}
                onSubmit={handleScheduleModelSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                }) => {
                  return (
                    <Form className={styles.scheduleForm}>
                      <div className={styles.content}>
                        <p data-testid="edit Choose start date">
                          Choose start date:
                        </p>
                        <div>
                          <Datepicker
                            label=""
                            id="startDate"
                            selected={
                              values.startDate
                                ? new Date(values.startDate)
                                : null
                            }
                            value={values.startDate}
                            autoComplete="off"
                            name="startDate"
                            peekNextMonth
                            minDate={new Date()}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText={moment(new Date()).format(
                              'dddd, MMM DD',
                            )}
                            dateFormat="EEEE, MMM dd"
                            onChange={(date) => {
                              setFieldValue('startDate', date);
                            }}
                            required={true}
                            data-testid="edit_protocol_schedule_startDate"
                          />
                        </div>
                        <div className={styles.repeatSection}>
                          <div
                            className={styles.repeatLabel}
                            data-testid="edit_template_schedule_repeat label"
                          >
                            Repeat every
                          </div>
                          <div className={styles.repeatCount}>
                            <FieldInput
                              errorText={
                                touched.repeatCount && errors.repeatCount
                              }
                              name="repeatCount"
                              value={values.repeatCount}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              data-testid="repeatCount"
                              label-testid="repeatCount label"
                              error-testid="repeatCount error"
                            />
                            <div className={styles.counterArrows}>
                              <img
                                src={upArrow}
                                alt="Expand"
                                height="5px"
                                width="15px"
                                onClick={() => {
                                  setFieldValue('endDate', '');
                                  incrementRepeatCounter(
                                    values.repeatCount,
                                    10,
                                    setFieldValue,
                                    'repeatCount',
                                  );
                                }}
                                data-testid="edit repeatCount up"
                              />
                              <img
                                src={downArrow}
                                alt="Expand"
                                height="5px"
                                width="15px"
                                onClick={() => {
                                  setFieldValue('endDate', '');
                                  decrementRepeatCounter(
                                    values.repeatCount,
                                    0,
                                    setFieldValue,
                                    'repeatCount',
                                  );
                                }}
                                data-testid="edit repeatCount down"
                              />
                            </div>
                          </div>
                          <div className={styles.repeatSelect}>
                            <FieldDropdown
                              name="repeat"
                              errorText={touched.repeat && errors.repeat}
                              value={values.repeat}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue('endDate', '');
                                onRepeatChange(
                                  e.target.value,
                                  values.repeatCount,
                                );
                              }}
                              onBlur={handleBlur}
                              options={repeatOptionsConst}
                              dataTestId="EditActiveProtocol.Repeat.Dropdown"
                            />
                          </div>
                        </div>
                        <p data-testid="edit repetition end date">
                          Repetition end date:
                        </p>
                        <div className={styles.repetitionEndDateSection}>
                          <Grid>
                            <Cell className={styles.cell} large={4}>
                              <Field
                                component={(field) => (
                                  <RadioButton
                                    {...field}
                                    name="repetitionEndDate"
                                  />
                                )}
                                value={repetitionEndDateValues.never}
                                htmlFor={repetitionEndDateValues.never}
                                checked={
                                  repetitionEndDateValues.never ===
                                  values.repetitionEndDate
                                }
                                name="repetitionEndDate"
                                onChange={(value) => {
                                  handleChange({
                                    target: {
                                      value,
                                      name: 'repetitionEndDate',
                                    },
                                  });
                                }}
                                labelName="Never"
                                data-testid="edit Never"
                                label-testid="edit Never label"
                              />
                            </Cell>
                            <Cell className={styles.cell} large={8}></Cell>
                          </Grid>
                          <Grid>
                            <Cell className={styles.cell} large={4}>
                              <Field
                                component={(field) => (
                                  <RadioButton
                                    {...field}
                                    name="repetitionEndDate"
                                  />
                                )}
                                value={repetitionEndDateValues.on}
                                htmlFor={repetitionEndDateValues.on}
                                name="repetitionEndDate"
                                checked={
                                  repetitionEndDateValues.on ===
                                  values.repetitionEndDate
                                }
                                onChange={(value) => {
                                  handleChange({
                                    target: {
                                      value,
                                      name: 'repetitionEndDate',
                                    },
                                  });
                                }}
                                labelName="On"
                                data-testid="edit On"
                                label-testid="edit On label"
                              />
                            </Cell>
                            <Cell className={styles.cell} large={8}>
                              <div className={styles.datePicker}>
                                <Datepicker
                                  label=""
                                  id="endDate"
                                  name="endDate"
                                  selected={
                                    values.endDate
                                      ? new Date(values.endDate)
                                      : null
                                  }
                                  autoComplete="off"
                                  disabled={
                                    repetitionEndDateValues.on !==
                                    values.repetitionEndDate
                                  }
                                  value={values.startDate}
                                  peekNextMonth
                                  minDate={moment()
                                    .add(endDateLimit.count, endDateLimit.unit)
                                    .toDate()}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  placeholderText={moment(new Date())
                                    .add(endDateLimit.count, endDateLimit.unit)
                                    .format('dddd, MMM DD')}
                                  dateFormat="EEEE, MMM dd"
                                  onChange={(date) => {
                                    setFieldValue('endDate', date);
                                  }}
                                  required={
                                    repetitionEndDateValues.on ===
                                    values.repetitionEndDate
                                  }
                                  data-testid="edit_protocol_schedule_endDate"
                                />
                              </div>
                            </Cell>
                          </Grid>
                          <Grid>
                            <Cell className={styles.cell} large={4}>
                              <Field
                                component={(field) => (
                                  <RadioButton
                                    {...field}
                                    name="repetitionEndDate"
                                  />
                                )}
                                value={repetitionEndDateValues.after}
                                htmlFor={repetitionEndDateValues.after}
                                name="repetitionEndDate"
                                checked={
                                  repetitionEndDateValues.after ===
                                  values.repetitionEndDate
                                }
                                onChange={(value) =>
                                  handleChange({
                                    target: {
                                      value,
                                      name: 'repetitionEndDate',
                                    },
                                  })
                                }
                                labelName="After"
                                data-testid="edit After"
                                label-testid="edit After label"
                              />
                            </Cell>
                            <Cell className={styles.cell} large={8}>
                              <div className={styles.occurrenceCount}>
                                <FieldInput
                                  label=""
                                  disabled={
                                    repetitionEndDateValues.after !==
                                    values.repetitionEndDate
                                  }
                                  errorText={
                                    touched.occurrenceCount &&
                                    errors.occurrenceCount
                                  }
                                  name="occurrenceCount"
                                  value={values.occurrenceCount}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  data-testid="occurrenceCount"
                                  label-testid="occurrenceCount label"
                                  error-testid="occurrenceCount error"
                                />
                                <div className={styles.counterArrows}>
                                  <img
                                    src={upArrow}
                                    alt="Expand"
                                    width="10px"
                                    onClick={() => {
                                      repetitionEndDateValues.after ===
                                        values.repetitionEndDate &&
                                        incrementRepeatCounter(
                                          values.occurrenceCount,
                                          10,
                                          setFieldValue,
                                          'occurrenceCount',
                                        );
                                    }}
                                    data-testid="edit occurrenceCount up"
                                  />
                                  <img
                                    src={downArrow}
                                    alt="Expand"
                                    width="10px"
                                    onClick={() => {
                                      decrementRepeatCounter(
                                        values.occurrenceCount,
                                        0,
                                        setFieldValue,
                                        'occurrenceCount',
                                      );
                                    }}
                                    data-testid="edit occurrenceCount down"
                                  />
                                </div>
                                <div data-testid="occurrences label">
                                  occurrences
                                </div>
                              </div>
                            </Cell>
                          </Grid>
                        </div>
                        <div className={styles.checkRepetitionCompletionDate}>
                          <CheckBox
                            name="checkRepetitionCompletionDate"
                            value={
                              values.repeatCount > 0 &&
                              values.checkRepetitionCompletionDate
                            }
                            isActive={
                              values.repeatCount > 0 &&
                              values.checkRepetitionCompletionDate
                            }
                            onClick={() => {
                              if (values.repeatCount > 0) {
                                setFieldValue(
                                  'checkRepetitionCompletionDate',
                                  values.checkRepetitionCompletionDate
                                    ? false
                                    : true,
                                );
                              }
                            }}
                          />
                          <div>
                            <p data-testid="edit repetition from completion date label">
                              Repetition from completion date
                            </p>
                            <span data-testid="edit repetition from completion date text">
                              Unchecking - repetition will occur based on first
                              assignment date for all participants
                            </span>
                          </div>
                        </div>
                        <div className={styles.actionButtons}>
                          <SecondaryButton
                            label="Cancel"
                            onClick={() => {
                              setShowScheduleSelectorModel(false);
                            }}
                            data-testid="edit schedule cancel button"
                          />
                          <PrimaryButton
                            label="Save"
                            data-testid="edit schedule save button"
                          />
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Modal>
          )}
        </Fragment>
      )}
      {showMessageModal && (
        <MessageModal
          onCloseModal={() => history.goBack()}
          message={displayMessage}
          displayLogo={false}
        />
      )}
    </div>
  );
};

export default EditActiveProtocolPage;
