import React, { useEffect, useState } from 'react';
import styles from './ConfirmationPage.module.scss';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import FieldInput from '../../components/common/fields/FieldInput/FieldInput';
import { Link, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { withOktaAuth } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import Loading from '../../components/common/Loading';
import { emailActivation } from '../../api/auth';
import { toast } from 'react-toastify';
import config from '../../config';
import SecondaryButton from '../../components/common/buttons/SecondaryButton';
import Logo from '../../components/Logo';
import { OKTA_REDIRECT_URL } from '../../utils/envVariables';

const ConfirmationPage = ({ showLogin }) => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const param = useParams();
  const auth = new OktaAuth({
    ...config.oidc,
  });
  const AuthorizeUrl = process.env.REACT_APP_OKTA_ISSUER.split('/oauth2')[0];

  const activateUser = async (token) => {
    try {
      setIsLoading(true);
      await emailActivation(token);
      toast('Your email is confirmed successfully', {
        autoClose: 5000,
        className: styles.toastMsg,
        bodyClassName: styles.toastDiv,
        hideProgressBar: true,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (param.activationId) {
      activateUser(param.activationId);
    }
  }, [param.activationId]);

  const signIn = async (values) => {
    setIsLoading(true);
    try {
      const transaction = await auth.signInWithCredentials({
        username: values.email,
        password: values.password,
      });

      if (transaction.status === 'SUCCESS') {
        await auth.token.getWithRedirect({
          sessionToken: transaction.data.sessionToken,
          responseType: ['token', 'id_token'],
        });
      }
    } catch (error) {
      setIsLoading(false);
      setError('Please Enter Correct Password');
    }
  };

  return (
    <div className={styles.root}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.container}>
          <>
            <div>
              <Logo />
            </div>
            <div className={styles.content}>
              <Formik
                initialValues={{
                  email: param.email,
                  password: '',
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = 'Field Required';
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email,
                    )
                  ) {
                    errors.email = 'Invalid email address';
                  }
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  await signIn(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <h4>Please login to continue</h4>
                    <>
                      <FieldInput
                        className={styles.userInput}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        autoComplete="email"
                        label="Email address"
                        errorText={touched.email && errors.email}
                        name="email"
                        data-testid="email"
                      />

                      <FieldInput
                        autoComplete="current-password"
                        label="Password"
                        errorText={
                          (errors.password &&
                            touched.password &&
                            errors.password) ||
                          error
                        }
                        name="password"
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        data-testid="password"
                      />
                      <div className={styles.forgotPassword}>
                        <Link to={`/forgot-password/${values.email}`}>
                          Forgot Password?
                        </Link>
                      </div>
                      <div className={styles.nextBtn}>
                        <PrimaryButton
                          disabled={isSubmitting}
                          className={styles.primaryBtn}
                          label="Login"
                        />
                      </div>
                    </>
                  </Form>
                )}
              </Formik>
              <div className={styles.verticalLine}></div>
              <div className={styles.socialLogin}>
                <div>
                  <a
                    href={`${AuthorizeUrl}/oauth2/v1/authorize?idp=${process.env.REACT_APP_GOOGLE_IDP_ID}&client_id=${process.env.REACT_APP_OKTA_CLIENT_ID}&response_type=id_token%20token&response_mode=fragment&scope=openid%20email%20profile&redirect_uri=${OKTA_REDIRECT_URL}&state=WM6D&nonce=YsG76jo&storage=localStorage`}
                  >
                    <SecondaryButton
                      label="Continue with Google"
                      className={styles.withGoogle}
                    ></SecondaryButton>
                  </a>
                </div>
                <div>
                  <a
                    href={`${AuthorizeUrl}/oauth2/v1/authorize?idp=${process.env.REACT_APP_APPLE_IDP_ID}&client_id=${process.env.REACT_APP_OKTA_CLIENT_ID}&response_type=id_token%20token&response_mode=fragment&scope=openid%20email%20profile&redirect_uri=${OKTA_REDIRECT_URL}&state=WM6D&nonce=YsG76jo&storage=localStorage`}
                  >
                    <SecondaryButton
                      label="Continue with Apple"
                      className={styles.withApple}
                    />
                  </a>
                </div>
              </div>
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default withOktaAuth(ConfirmationPage);
