import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@react-pdf/renderer';
import { AssessmentPDFStyles } from '../AssessmentPDFStyles';

const SubtitleSection = ({ title }) => (
  <Text
    style={[
      AssessmentPDFStyles.content_detail.h3,
      { margin: 0, padding: 0, marginTop: 10 },
    ]}
  >
    {title}
  </Text>
);

SubtitleSection.propTypes = {
  title: PropTypes.string,
};

export default SubtitleSection;
