import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { Cell, Grid } from 'react-foundation';
import styles from '../SuperBillForm.module.scss';
import moment from 'moment';
import FieldInput from '../../common/fields/FieldInput/FieldInput';
import FieldDropdown from '../../common/fields/FieldDropdown';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import SecondaryButton from '../../common/buttons/SecondaryButton';
import { useHistory } from 'react-router-dom';
import Logo from '../../../img/logo.svg';
import { incompleteFields } from '../SuperBillForm';
import { dropdownStyles } from '../SuperBillForm';
import Modal from '../../common/Modal';
import Datepicker from '../../common/Datepicker';
import Loading from '../../common/Loading';
import states from '../../../utils/data/states.json';
import { sendSuperbill, downloadSuperbill } from '../../../api/superbill';
import useSuperbill from '../../../hooks/useSuperbill';
import CloseSVG from '../../../img/close-navy.svg';
import SuperBillMessageModal from '../SuperBillMessageModal/SuperBillMessageModal';

export const clientRequiredFields = [
  'client_first_name',
  'client_last_name',
  'client_address',
  'client_city',
  'client_dob',
  'client_home_phone',
  'client_state',
  'client_zip_code',
  'client_email',
];
const PatientSuperBillForm = ({
  formDefaults,
  superbillInitialValues,
  superbillId,
}) => {
  const [isLoading, setLoading] = useState();
  const [downloadedSuperBill, setDownloadedSuperBill] = useState(null);

  const {
    formDefaults: defaults,
    isLoading: superbillLoading,
    refetch,
    superbillInitialValues: superbillData,
  } = useSuperbill(superbillId, true);

  const clientFields = (values, patientFields) => {
    const clientValues = {};
    patientFields.forEach((field) => {
      clientValues[field] = values[field];
    });
    return clientValues;
  };

  formDefaults = defaults || formDefaults;
  superbillInitialValues = superbillData || superbillInitialValues;

  const [clientDetails, setClientDetails] = useState(
    incompleteFields(
      clientRequiredFields,
      clientFields(superbillInitialValues, clientRequiredFields),
    ) || !superbillInitialValues.patient_viewed_at,
  );

  const history = useHistory();

  const Diagnoses = () => {
    if (superbillInitialValues.diagnoses) {
      const diagnoses = superbillInitialValues.diagnoses;
      return (
        <>
          <Cell>
            <h3>Diagnoses</h3>
            <table className={styles.superbillTable}>
              <caption className="hidden">Diagnoses list</caption>
              <thead>
                <tr>
                  <th style={{ width: '25%' }}>Code</th>
                  <th style={{ width: '75%' }}>Description</th>
                </tr>
              </thead>
              <tbody>
                {diagnoses.length &&
                  diagnoses.map((diagnosis, index) => {
                    return (
                      <tr key={index}>
                        <td>{diagnosis.code}</td>
                        <td>{diagnosis.description}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Cell>
        </>
      );
    }
    return null;
  };

  const Procedures = () => {
    if (superbillInitialValues.procedures) {
      const procedures = superbillInitialValues.procedures;
      return (
        <>
          <Cell>
            <h3>Procedures</h3>
            <table className={styles.superbillTable}>
              <caption className="hidden">Procedures list</caption>
              <thead>
                <tr>
                  <th style={{ width: '20%' }}>Code</th>
                  <th style={{ width: '60%' }}>Description</th>
                  <th style={{ width: '20%' }}>Fee</th>
                </tr>
              </thead>
              <tbody>
                {procedures.length &&
                  procedures.map((procedure, index) => {
                    return (
                      <tr key={index}>
                        <td>{procedure.cpt_code}</td>
                        <td>{procedure.description}</td>
                        <td>{procedure.fee}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Cell>
        </>
      );
    }
    return null;
  };

  const handleContinue = async (values) => {
    setLoading(true);
    values.patient_viewed_at = new Date();

    try {
      await sendSuperbill(superbillId, values);
      setClientDetails(null);
      setLoading(false);
      refetch();
    } catch {
      setClientDetails(null);
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    try {
      setLoading(true);
      const response = await downloadSuperbill(superbillId);
      setDownloadedSuperBill(response);
      setLoading(false);
    } catch {
      setDownloadedSuperBill({ error: "Couldn't download the PDF." });
    }
  };

  return (
    <div className={styles.formWrapper}>
      {superbillLoading || (isLoading && <Loading />)}
      <div className={styles.superBillForm}>
        <img
          className={styles.logo}
          src={Logo}
          alt="logo-eve"
          data-testid="logo image"
        />
        <h1>Superbill</h1>
        <Grid>
          <Cell>
            <p className={styles.mb10}>{formDefaults.superbill_date}</p>
            <p>{formDefaults.doctor_full_name}</p>
            {formDefaults.doctor_clinic?.clinic_name && (
              <p>{formDefaults.doctor_clinic?.clinic_name}</p>
            )}
            {formDefaults.doctor_clinic?.address && (
              <p>{formDefaults.doctor_clinic?.address}</p>
            )}
            {(formDefaults.doctor_clinic?.city ||
              formDefaults.doctor_clinic?.state ||
              formDefaults.doctor_clinic?.zip) && (
              <p>
                {formDefaults.doctor_clinic?.city && (
                  <span>{`${formDefaults.doctor_clinic?.city}${
                    formDefaults.doctor_clinic?.state ? ', ' : ''
                  }`}</span>
                )}
                {formDefaults.doctor_clinic?.state && (
                  <span>{`${formDefaults.doctor_clinic.state}${
                    formDefaults.doctor_clinic?.zip ? ' ' : ''
                  }`}</span>
                )}
                {formDefaults.doctor_clinic?.zip && (
                  <span>{formDefaults.doctor_clinic?.zip}</span>
                )}
              </p>
            )}
            {formDefaults.doctor_clinic?.phone && (
              <p>
                <span>Phone: </span>
                {formDefaults.doctor_clinic?.phone}
              </p>
            )}
            {formDefaults?.doctor_email && <p>{formDefaults?.doctor_email}</p>}
          </Cell>
        </Grid>
        <Grid className={styles.clientInfo}>
          <Cell large={12}>
            <h2 className={styles.formTitle}>Fill in your details</h2>
          </Cell>
          <Cell>
            <h3>Provider Details</h3>
          </Cell>
          {superbillInitialValues?.dr_license_nr && (
            <Cell large={12} medium={12}>
              <p className={styles.mb10}>License # - State</p>
              <strong>{superbillInitialValues?.dr_license_nr}</strong>
            </Cell>
          )}
          {superbillInitialValues?.dr_ein_nr && (
            <Cell large={12} medium={12}>
              <p className={styles.mb10}>EIN/TIN #</p>
              <strong>{superbillInitialValues?.dr_ein_nr}</strong>
            </Cell>
          )}
          {superbillInitialValues?.dr_npi_nr && (
            <Cell large={12} medium={12}>
              <p className={styles.mb10}>NPI #</p>
              <strong>{superbillInitialValues?.dr_npi_nr}</strong>
            </Cell>
          )}
          {superbillInitialValues?.dr_notes && (
            <Cell large={12} medium={12}>
              <p className={styles.mb10}>Additional Notes</p>
              <strong>{superbillInitialValues?.dr_notes}</strong>
            </Cell>
          )}
          <Cell large={12} className={styles.editClient}>
            <h3>Client information</h3>
            <button
              type="button"
              className={styles.edit}
              onClick={() => setClientDetails(true)}
            >
              Edit
            </button>
          </Cell>
          {(superbillInitialValues?.client_first_name ||
            formDefaults?.patient_first_name) && (
            <Cell large={6} medium={6}>
              <p className={styles.mb10}>First Name</p>
              <strong>
                {superbillInitialValues.client_first_name ||
                  formDefaults?.patient_first_name}
              </strong>
            </Cell>
          )}
          {(superbillInitialValues?.client_last_name ||
            formDefaults?.patient_last_name) && (
            <Cell large={6} medium={6}>
              <p className={styles.mb10}>Last name</p>
              <strong>
                {superbillInitialValues?.client_last_name ||
                  formDefaults?.patient_last_name}
              </strong>
            </Cell>
          )}
          {superbillInitialValues?.client_address && (
            <Cell large={5} medium={5}>
              <p className={styles.mb10}>Address</p>
              <strong>{superbillInitialValues?.client_address}</strong>
            </Cell>
          )}
          {superbillInitialValues?.client_city && (
            <Cell large={3} medium={3}>
              <p className={styles.mb10}>City</p>
              <strong>{superbillInitialValues?.client_city}</strong>
            </Cell>
          )}
          {superbillInitialValues?.client_state && (
            <Cell large={2} medium={2}>
              <p className={styles.mb10}>State</p>
              <strong>{superbillInitialValues?.client_state}</strong>
            </Cell>
          )}
          {superbillInitialValues?.client_zip_code && (
            <Cell large={2} medium={2}>
              <p className={styles.mb10}>Zip Code</p>
              <strong>{superbillInitialValues?.client_zip_code}</strong>
            </Cell>
          )}
          {superbillInitialValues?.client_home_phone && (
            <Cell large={12} medium={12}>
              <p className={styles.mb10}>Phone #</p>
              <strong>{superbillInitialValues?.client_home_phone}</strong>
            </Cell>
          )}
          {(superbillInitialValues?.client_email ||
            formDefaults?.patient_email) && (
            <Cell large={12} medium={12}>
              <p className={styles.mb10}>Email</p>
              <strong>
                {superbillInitialValues?.client_email ||
                  formDefaults?.patient_email}
              </strong>
            </Cell>
          )}
          {superbillInitialValues?.client_dob && (
            <Cell large={12} medium={12}>
              <p className={styles.mb10}>Date of Birth</p>
              <strong>
                {moment(new Date(superbillInitialValues.client_dob)).format(
                  'MM/DD/YYYY',
                )}
              </strong>
            </Cell>
          )}
          <Diagnoses />
          <Procedures />
          <div className={styles.paymentFields}>
            {superbillInitialValues?.total_charge && (
              <Cell large={12} medium={12}>
                <div>
                  <strong>Total Charge</strong>
                </div>
                <strong>${superbillInitialValues?.total_charge}</strong>
              </Cell>
            )}
            {superbillInitialValues?.payment && (
              <Cell large={12} medium={12}>
                <div>
                  <strong>Payments</strong>
                </div>
                <strong>${superbillInitialValues?.payment}</strong>
              </Cell>
            )}
            {superbillInitialValues?.balance_due && (
              <Cell large={12} medium={12}>
                <div>
                  <strong>Balance Due</strong>
                </div>
                <strong>${superbillInitialValues?.balance_due}</strong>
              </Cell>
            )}
          </div>
          <Cell>
            <div className={styles.buttonsWrapper}>
              <PrimaryButton
                type="button"
                label={'Edit'}
                onClick={() => {
                  setClientDetails(true);
                }}
              />
              <SecondaryButton
                type="button"
                onClick={() => history.push('/my-account/appointments')}
                label={'Exit'}
              />
            </div>
          </Cell>
          <Cell small={12} flexContainer alignY="middle" flexDirCol="all">
            <strong>
              <a className={styles.viewPdf} onClick={() => handleDownload()}>
                Download PDF
              </a>
            </strong>
          </Cell>
          {downloadedSuperBill && (
            <SuperBillMessageModal
              type={!downloadedSuperBill?.error ? 'success' : 'error'}
            >
              <h1>
                {downloadedSuperBill?.error
                  ? downloadedSuperBill?.error
                  : 'Download completed successfully!'}
              </h1>
              <div className={styles.buttonsWrapperDelete}>
                <PrimaryButton
                  label="Ok"
                  onClick={() => {
                    history.push(
                      `/my-account/superbill/patient/${superbillId}`,
                    );
                  }}
                />
              </div>
            </SuperBillMessageModal>
          )}
        </Grid>
        {clientDetails && (
          <Modal
            className={`${styles.mainContainer} ${styles.clientDetailsModal}`}
          >
            <Formik
              enableReinitialize={true}
              onSubmit={async (values) => {
                handleContinue(values);
              }}
              initialValues={clientFields(
                superbillInitialValues,
                clientRequiredFields,
              )}
            >
              {({
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                isValid,
                setFieldValue,
              }) => {
                const handleCustomChange = (e) => {
                  handleChange(e);
                };

                return (
                  <Form data-testid="patient-form">
                    <Grid>
                      <Cell small={12}>
                        <div className={styles.modalClient}>
                          <h1>Complete/Confirm your information: </h1>
                          <button
                            type="button"
                            className={styles.close}
                            onClick={() => setClientDetails(false)}
                          >
                            <img
                              className={styles.closeImg}
                              src={CloseSVG}
                              alt="close-modal"
                              data-testid="close modal"
                            />
                          </button>
                        </div>
                      </Cell>
                      <Cell large={6} medium={6}>
                        <FieldInput
                          placeholder={'Enter here.'}
                          label={'First Name'}
                          name="client_first_name"
                          value={values.client_first_name}
                          onChange={handleCustomChange}
                          onBlur={handleBlur}
                        />
                      </Cell>
                      <Cell large={6} medium={6}>
                        <FieldInput
                          placeholder={'Enter here.'}
                          label={'Last Name'}
                          name="client_last_name"
                          value={values.client_last_name}
                          onChange={handleCustomChange}
                          onBlur={handleBlur}
                        />
                      </Cell>
                      <Cell large={12}>
                        <FieldInput
                          placeholder={'Enter here.'}
                          label={'Address'}
                          name="client_address"
                          value={values.client_address}
                          onChange={handleCustomChange}
                          onBlur={handleBlur}
                        />
                      </Cell>
                      <Cell large={4} medium={4}>
                        <FieldDropdown
                          className={`${styles.selectDropdown} ${
                            values.client_state ? styles.touched : ''
                          }`}
                          customStyles={dropdownStyles}
                          placeholder={'Select'}
                          label={'State'}
                          autoFocus
                          name="client_state"
                          value={values.client_state}
                          options={states}
                          onChange={handleCustomChange}
                          onBlur={handleBlur}
                          dataTestId="Superbill.Client.State"
                        />
                      </Cell>
                      <Cell large={4} medium={4}>
                        <FieldInput
                          placeholder={'Enter here.'}
                          label={'City'}
                          name="client_city"
                          value={values.client_city}
                          onChange={handleCustomChange}
                          onBlur={handleBlur}
                        />
                      </Cell>
                      <Cell large={4} medium={4}>
                        <FieldInput
                          placeholder={'Enter here.'}
                          label={'Zip Code'}
                          name="client_zip_code"
                          value={values.client_zip_code}
                          onChange={handleCustomChange}
                          onBlur={handleBlur}
                        />
                      </Cell>
                      <Cell large={12}>
                        <FieldInput
                          placeholder={'Enter here.'}
                          label={'Phone #'}
                          name="client_home_phone"
                          value={values.client_home_phone}
                          onChange={handleCustomChange}
                          onBlur={handleBlur}
                        />
                      </Cell>
                      <Cell large={12}>
                        <FieldInput
                          placeholder={'Enter here.'}
                          label={'Email'}
                          name="client_email"
                          value={values.client_email}
                          onChange={handleCustomChange}
                          onBlur={handleBlur}
                        />
                      </Cell>
                      <Cell large={12}>
                        <Datepicker
                          label="Date of birth"
                          id="dob"
                          selected={
                            values?.client_dob
                              ? new Date(values.client_dob)
                              : new Date()
                          }
                          value={values.client_dob}
                          autoComplete="off"
                          name="dob"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText={'MM/dd/yyyy'}
                          dateFormat="MM/dd/yyyy"
                          onChange={(date) => {
                            setFieldValue('client_dob', date);
                          }}
                          data-testid="client_dob"
                        />
                      </Cell>
                      <div
                        className={`${styles.buttonsWrapper} ${styles.continueButton}`}
                      >
                        <PrimaryButton
                          disabled={incompleteFields(
                            clientRequiredFields,
                            values,
                          )}
                          label={'Continue'}
                        />
                      </div>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Modal>
        )}
      </div>
    </div>
  );
};

PatientSuperBillForm.propTypes = {
  formDefaults: PropTypes.object,
  superbillInitialValues: PropTypes.object,
};

export default PatientSuperBillForm;
