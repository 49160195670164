import { doGet, doPut, doPost, doDelete } from '../fetch';

const getPatientCards = async (id) => {
  const doctor = await doGet(`/b2c/patient/${id}/card`);
  return doctor;
};

const savePatientCard = async (id, nonceToken) => {
  const card = await doPost(`/b2c/patient/${id}/card`, {
    nonce: nonceToken,
  });
  return card;
};

const deletePatientCard = async (id, cardId, doctorId) => {
  const card = await doDelete(`/b2c/patient/${id}/${doctorId}/card/${cardId}`);
  return card;
};

const uploadPatientProfilePic = async ({ id, params }, dispatch) => {
  const profile = await doPut(`/b2c/patient/${id}/upload-pic`, params);
  dispatch({ type: 'UPDATE_AVATAR', payload: profile.patient.profile_pic });
  return profile;
};

const removePatientProfilePic = async ({ id }, dispatch) => {
  const profile = await doDelete(`/b2c/patient/${id}/upload-pic`);
  dispatch({
    type: 'UPDATE_AVATAR',
    payload: require('../../img/patient.jpg'),
  });
  return profile;
};

const updatePatientProfile = async ({ id, params }, dispatch) => {
  const profile = await doPut(`/b2c/patient/${id}`, params);
  const { patient } = profile;
  dispatch({ type: 'UPDATE_USER', payload: patient });
  return profile;
};

const invitePatient = async (body) => {
  const patient = await doPost('/b2c/okta/invite-patient', body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return patient;
};

const getPatientAssessment = async (id) => {
  const res = doGet(`/b2c/patient/${id}/assessment`);
  return res;
};
const resendInvitation = async (id, body) => {
  const res = doPost(`/b2c/patient/send-invitation/${id}`, body);
  return res;
};

const closePatientAccount = async (id) => {
  const res = doDelete(`/b2c/patient/close-patient-account/${id}`);
  return res;
};

const updatePatientDetails = async (fact_form_id, body) => {
  const res = await doPut(`/b2c/patient/manage/profile-details`, {
    ...body,
    fact_form_id,
  });
  return res;
};

export {
  getPatientCards,
  savePatientCard,
  deletePatientCard,
  uploadPatientProfilePic,
  removePatientProfilePic,
  updatePatientProfile,
  invitePatient,
  getPatientAssessment,
  resendInvitation,
  closePatientAccount,
  updatePatientDetails,
};
