import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@react-pdf/renderer';
import { AssessmentPDFStyles } from '../AssessmentPDFStyles';

const TitleSection = ({ title }) => (
  <Text style={[AssessmentPDFStyles.content_detail.h2, { margin: '10 0' }]}>
    {title}
  </Text>
);

TitleSection.propTypes = {
  title: PropTypes.string,
};

export default TitleSection;
