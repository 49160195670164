import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Confirmation.module.scss';
import Success from '../../img/success-new.svg';
import Back from '../../img/icons/back.svg';

import Logo from '../Logo';

const Confirmation = () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.backDiv}>
          <Link to="/">
            <img src={Back} alt="Back" data-testid="Confirmation back image" />
            <span data-testid="Confirmation back label">Back</span>
          </Link>
        </div>
        <Logo />
        <div className={styles.successImg}>
          <img
            src={Success}
            alt="Success"
            data-testid="Confirmation success image"
          />
        </div>
        <h4
          className={styles.confirmationTitle}
          data-testid="Confirmation title"
        >
          Confirmation Email sent
        </h4>
        <p className={styles.confirmText} data-testid="Confirmation text">
          A confirmation message has been sent to your email. Please click on
          the verification link in that message to complete your registration.
        </p>
      </div>
    </div>
  );
};

export default Confirmation;
