import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import styles from './SuperBillModal.module.scss';
import ApprovalImg from '../../../img/approval.svg';
import SuccessImg from '../../../img/success-new.svg';
import Component from '../../../img/Component.svg';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';

const SuperBillModal = ({ message, onCloseModal, onRequest }) => {
  const chooseLogo = (type) => {
    switch (type) {
      case 'error':
        return (
          <img
            src={ApprovalImg}
            alt="Warning"
            data-testid="modal warning image"
          />
        );
      case 'success':
        return (
          <img
            src={SuccessImg}
            alt="Sucess"
            data-testid="modal success image"
          />
        );
      case 'alert':
        return (
          <img src={Component} alt="Alert" data-testid="modal info image" />
        );
      default:
        return (
          <img
            src={SuccessImg}
            alt="Sucess"
            data-testid="modal success image"
          />
        );
    }
  };

  const handleSubmitRequest = async () => {
    if (message && message.closeLabel) {
      await onRequest(true);
    } else {
      onCloseModal();
    }
  };

  return (
    <Modal
      className={`${styles.mainContainer} ${
        message.type === 'error' ? styles.errorModal : ''
      }`}
    >
      <div className={styles.content}>
        <div className={styles.container}>
          <div className={styles.warningImg}>{chooseLogo(message.type)}</div>
          <h1 className={styles.modalTitle} data-testid="modal error title">
            {message.title}
          </h1>
          <p className={styles.text} data-testid="modal superbill message">
            {message.text}
          </p>
          <div
            className={`${styles.buttonsWrapper} ${
              message.closeLabel ? styles.requestAgainButtonsWrapper : ''
            }`}
          >
            {message.closeLabel && (
              <SecondaryButton
                label={message.closeLabel}
                data-testid="modal error button"
                onClick={onCloseModal}
              />
            )}
            {message.requestLabel && (
              <PrimaryButton
                className={styles.modalPrimaryButton}
                label={message.requestLabel}
                data-testid="modal error button"
                onClick={handleSubmitRequest}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

SuperBillModal.propTypes = {
  message: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    closeLabel: PropTypes.string,
    requestLabel: PropTypes.string,
  }).isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onRequest: PropTypes.func,
};

export default SuperBillModal;
