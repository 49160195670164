import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Cell, Grid } from 'react-foundation';
import useIntl from '../../../../../hooks/useIntl';
import Datepicker from '../../../../common/Datepicker';
import FieldInput from '../../../../common/fields/FieldInput/FieldInput';
import messages from '../messages';
import customStyles from '../PersonalInfoForm.module.scss';

const IllnessesForm = ({ push, illnessValue, setIllnessValue, styles }) => {
  const { formatMessage } = useIntl();
  useEffect(() => {
    setIllnessValue();
  }, [setIllnessValue]);

  const handleChangeFormValue = (value, field) => {
    setIllnessValue({
      ...illnessValue,
      [field]: value,
    });
  };

  const addIllness = () => {
    push(illnessValue);
    setIllnessValue();
  };

  return (
    <fieldset className={customStyles['fieldset']}>
      <Grid medium={12}>
        <Cell small={12} medium={12}>
          <FieldInput
            label={formatMessage(
              messages.illnessesAndInjuries.descriptionLabel,
            )}
            placeholder={'Any current or past major illnesses & injuries'}
            value={illnessValue?.description}
            onChange={(e) =>
              handleChangeFormValue(e.target.value, 'description')
            }
          />
        </Cell>
        <Cell small={12} medium={12} className={styles['datepicker']}>
          <Datepicker
            label={formatMessage(
              messages.illnessesAndInjuries.dateOfOnsetLabel,
            )}
            selected={
              illnessValue?.dateOfOnset ? illnessValue.dateOfOnset : null
            }
            value={illnessValue?.dateOfOnset}
            className="form-control"
            name="illnessesAndInjuries.dateOfOnset"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={new Date()}
            placeholderText="MM/DD/YYYY"
            onChange={(dateOfOnset) =>
              handleChangeFormValue(dateOfOnset, 'dateOfOnset')
            }
          />
        </Cell>
        <Cell className={customStyles['centeredButton']}>
          <button
            disabled={
              !illnessValue ||
              Object.keys(illnessValue).length !== 2 ||
              Object.values(illnessValue).some((elem) => !elem)
            }
            type="button"
            className={customStyles['buttonAddItem']}
            onClick={() => {
              addIllness();
            }}
            data-testid="add_item_illness"
          >{` + Add Item `}</button>
        </Cell>
      </Grid>
    </fieldset>
  );
};

IllnessesForm.propTypes = {
  push: PropTypes.func,
  illnessValue: PropTypes.object,
  setIllnessValue: PropTypes.func,
};

export default IllnessesForm;
