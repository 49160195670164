import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import MultiSelectDropdownStyles from './MultiSelectDropdown.module.scss';

import colors from '../../../../styles/_colors.scss';
import CheckBox from '../CheckBox';
import Label from '../../Label';

const styles = {
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      borderRadius: '100px',
    };
  },
  menu: (provided, state) => ({
    ...provided,
    zIndex: 10,
  }),
  control: (base) => ({
    ...base,
    borderRadius: '25px',
    padding: '0px',
    width: '100%',
    boxShadow: 0,
    minHeight: '3.25rem',
    border: `1px solid #d8d8d8`,
    '&:hover': {
      border: `1px solid ${colors.grayBorder}`,
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: colors.black,
    paddingRight: '0.5rem',
    '&:hover': {
      color: colors.black,
    },
  }),
  option: (styles, { isClearable }) => {
    return {
      padding: '5px 10px',
      cursor: 'pointer',
      backgroundColor: isClearable && null,
      color: colors.black,
    };
  },
  placeholder: (base) => ({
    ...base,
    paddingLeft: '1rem',
    color: colors.grayPlaceholder,
    fontSize: 13,
  }),
};
const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className={MultiSelectDropdownStyles.customOption}>
        <CheckBox
          checked
          className={MultiSelectDropdownStyles}
          isActive={props.isSelected}
        />
        <div>{props.data?.label}</div>
      </div>
    </components.Option>
  );
};

const MultiSelectDropdown = ({
  errorText,
  options,
  onChange,
  value,
  isSearchable,
  isDisabled,
  placeholder,
  isMulti,
  closeMenuOnSelect,
  hideSelectedOptions,
  'data-testid': datatestid,
}) => (
  <div className={MultiSelectDropdownStyles.container}>
    <Label errorText={errorText} dataTestId={datatestid}></Label>
    <Select
      closeMenuOnSelect={closeMenuOnSelect}
      hideSelectedOptions={hideSelectedOptions}
      id={`select_${datatestid}`}
      options={options}
      onChange={(value, event) => {
        onChange(value, event);
      }}
      value={isMulti ? value : options.find((option) => option.value === value)}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      styles={styles}
      isMulti={isMulti}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: colors.grayLight2,
          primary: colors.black,
          primary50: colors.grayLight2,
        },
      })}
      components={{
        IndicatorSeparator: () => null,
        Option,
      }}
      placeholder={placeholder}
    />
  </div>
);

MultiSelectDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]),
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  errorText: PropTypes.string,
};

MultiSelectDropdown.defaultProps = {
  value: [],
  isSearchable: false,
  isDisabled: false,
  isMulti: false,
  placeholder: '',
  closeMenuOnSelect: true,
  hideSelectedOptions: true,
  errorText: '',
};

export default MultiSelectDropdown;
