import React from 'react';
import classNames from 'classnames';
import styles from './Label.module.scss';

//TODO Refactor all components that uses Label to remove errorTestId and labelTestID
const Label = ({ children, errorText, htmlFor, dataTestId }) => (
  <div className={styles.container}>
    <label
      className={classNames(styles.label, {
        [styles['is-error--strong']]: Boolean(errorText),
      })}
      htmlFor={htmlFor}
      data-testid={`${dataTestId}.Label`}
    >
      {children}
      {errorText && '*'}
    </label>
  </div>
);

export default Label;
