import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.DoctorNylasCallbackPage.title',
    description: 'Doctor Square Connect Page title',
    defaultMessage: 'Calendar Connect',
  },
  squareaccountexists: {
    id: 'pages.DoctorNylasCallbackPage.squareaccountexists',
    description: 'Already have a nylas Account',
    defaultMessage: 'If you already have a nylas account',
  },
  squareaccountnotexists: {
    id: 'pages.DoctorNylasCallbackPage.squareaccountnotexists',
    defaultMessage: "Don't have a nylas Account",
    description: "If you don't have a nylas account",
  },
});
