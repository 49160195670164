import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Cell, Grid } from 'react-foundation';
import useIntl from '../../../../../hooks/useIntl';
import FieldInput from '../../../../common/fields/FieldInput/FieldInput';
import customStyles from '../../PersonalInfoForm/PersonalInfoForm.module.scss';
import messages from '../messages';

const ProvidersForm = ({ push, providerValue, setProviderValue }) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    setProviderValue();
  }, [setProviderValue]);

  const handleChangeFormValue = (value, field) => {
    setProviderValue({
      ...providerValue,
      [field]: value,
    });
  };

  const addProvider = () => {
    push(providerValue);
    setProviderValue();
  };

  return (
    <fieldset className={customStyles['fieldset']}>
      <Grid medium={12}>
        <Cell small={12}>
          <FieldInput
            label={formatMessage(
              messages.medicalHistory.primaryCarePhysician.providersName,
            )}
            placeholder={'Enter here'}
            value={providerValue?.name}
            onChange={(e) => handleChangeFormValue(e.target.value, 'name')}
          />
        </Cell>
        <Cell small={12}>
          <FieldInput
            label={formatMessage(
              messages.medicalHistory.primaryCarePhysician.providersSpecialty,
            )}
            placeholder={'Enter here'}
            value={providerValue?.specialty}
            onChange={(e) => handleChangeFormValue(e.target.value, 'specialty')}
          />
        </Cell>
        <Cell small={12}>
          <FieldInput
            label={formatMessage(
              messages.medicalHistory.primaryCarePhysician.providersPhoneNumber,
            )}
            placeholder={'Enter here'}
            value={providerValue?.phone}
            onChange={(e) => handleChangeFormValue(e.target.value, 'phone')}
          />
        </Cell>
        <Cell className={customStyles['centeredButton']}>
          <button
            disabled={
              !providerValue ||
              Object.keys(providerValue).length !== 3 ||
              Object.values(providerValue).some((elem) => !elem)
            }
            type="button"
            className={customStyles['buttonAddItem']}
            onClick={addProvider}
            data-testid="add_item_provider"
          >{` + Add Item `}</button>
        </Cell>
      </Grid>
    </fieldset>
  );
};

ProvidersForm.propTypes = {
  push: PropTypes.func,
  ProviderValue: PropTypes.object,
  setProviderValue: PropTypes.func,
  customDropdownStyles: PropTypes.object,
};

export default ProvidersForm;
