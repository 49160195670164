import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';

import colors from '../../../../styles/_colors.scss';

const styles = {
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  control: (base) => ({
    ...base,
    width: '100%',
    height: '3.25rem',
    maxHeight: '40px',
    boxShadow: 0,
    border: `1px solid #b4b4c6`,
    '&:hover': {
      border: `1px solid #b4b4c6`,
    },
    '&:focus': {
      outlineWidth: 0,
      boxShadow: 'none',
      border: 'none',
    },
    background: 'white',
    img: {
      height: '30px',
      width: '30px',
      paddingLeft: '10px',
    },
  }),
  valueContainer: (base) => ({
    ...base,
    height: '100%',
    input: { boxShadow: 'none !important' },
  }),
};

const SelectContainer = (props) => {
  return (
    <components.SelectContainer
      {...props}
      innerProps={Object.assign({}, props.innerProps, {
        'data-testid': props.selectProps.dataTestId,
      })}
    />
  );
};

const Option = (props) => (
  <components.Option
    {...props}
    innerProps={Object.assign({}, props.innerProps, {
      'data-testid': props.data.label,
    })}
  />
);

const Control = ({ children, ...props }) => {
  const { emoji, onEmojiClick } = props.selectProps;
  const style = { cursor: 'pointer' };

  return (
    <components.Control {...props}>
      <span onMouseDown={onEmojiClick} style={style}>
        {emoji}
      </span>
      {children}
    </components.Control>
  );
};

const SquareDropdown = ({
  disabled,
  options,
  onChange,
  value,
  name,
  isSearchable,
  placeholder,
  emoji,
  noOptionsMessage,
  dropDownIndicator,
  dataTestId,
}) => (
  <Select
    isClearable
    id={`select_${dataTestId}`}
    emoji={emoji}
    dataTestId={dataTestId}
    aria-label={dataTestId}
    options={options}
    name={name}
    onChange={(value, event) => {
      onChange(value, event);
    }}
    value={options.find((option) => option.value === value)}
    isSearchable={isSearchable}
    styles={styles}
    isDisabled={disabled}
    theme={(theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary25: colors.grayLight2,
        primary: colors.black,
        primary50: colors.grayLight2,
      },
    })}
    components={{
      SelectContainer,
      Option,
      IndicatorSeparator: () => null,
      DropdownIndicator: (props) =>
        dropDownIndicator ? <components.DropdownIndicator {...props} /> : null,
      Control,
      NoOptionsMessage: (props) => {
        return (
          <components.NoOptionsMessage {...props}>
            <span className="custom-css-class">{noOptionsMessage}</span>
          </components.NoOptionsMessage>
        );
      },
    }}
    placeholder={placeholder}
  />
);

SquareDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  emoji: PropTypes.object,
  noOptionsMessage: PropTypes.string,
  dropDownIndicator: PropTypes.bool,
};

SquareDropdown.defaultProps = {
  isSearchable: false,
  placeholder: '',
  noOptionsMessage: 'No Options',
  dropDownIndicator: true,
};

export default SquareDropdown;
