import { doGet, doPut, getPdf } from '../fetch';

const fetchSuperbill = async (id, includeDefaults = false) => {
  const queryString = includeDefaults ? '?includeDefaults=true' : '';
  let response;
  try {
    response = await doGet(`/b2c/superbill/${id}${queryString}`);
  } catch {
    return { error: 'error' };
  }
  return response;
};

const updateSuperbill = async (id, formValues) => {
  const response = await doPut(`/b2c/superbill/${id}`, formValues, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return response;
};

const sendSuperbill = async (id, formValues) => {
  const response = await doPut(`/b2c/superbill/${id}?send=true`, formValues, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return response;
};

const downloadSuperbill = async (id) => {
  return await getPdf(`/superbill/${id}`, { includeDefaults: true });
};

export { fetchSuperbill, updateSuperbill, sendSuperbill, downloadSuperbill };
