import { defineMessages } from 'react-intl';

export default defineMessages({
  cookiesStatementPart1: {
    id: 'component.CookiesPanel.cookiesStatementPart1',
    description:
      'First part of the text explaining cookies policy for the site',
    defaultMessage:
      'When it comes to your privacy we want you to be informed. EVE uses cookies to optimize your experience and enhance the services provided to you by EVE and our partners. You can learn how your information is used by visiting the cookies section of our ',
  },
  cookiesStatementPart2: {
    id: 'component.CookiesPanel.cookiesStatementPart2',
    description:
      'Second part of the text explaining cookies policy for the site',
    defaultMessage: 'privacy policy.',
  },
  buttonLabel: {
    id: 'component.CookiesPanel.buttonLabel',
    description: 'The text contained in the OK button',
    defaultMessage: 'OK',
  },
});
