import { FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import { Cell, Column } from 'react-foundation';
import 'react-image-crop/dist/ReactCrop.css';
import useIntl from '../../../../../hooks/useIntl';
import {
  emailValidator,
  phoneValidator,
  requiredValidator,
} from '../../../../../utils/validators';
import AddedItem from '../../../../common/AddedItem/AddedItem';
import PrimaryButton from '../../../../common/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/buttons/SecondaryButton';
import FieldDropdownWithInput from '../../../../common/fields/FieldDropdownWithInput';
import FieldInput from '../../../../common/fields/FieldInput/FieldInput';
import LabeledCheckbox from '../../../../common/inputs/LabeledCheckbox';
import RadioGroupWithLabel from '../../../../common/inputs/RadioGroupWithLabel';
import Label from '../../../../common/Label';
import Loading from '../../../../common/Loading';
import SuperBillMessageModal from '../../../../SuperBillForm/SuperBillMessageModal/SuperBillMessageModal';
import { binaryResponses } from '../../BPSForm/data';
import { genderOptions } from '../../PersonalInfoForm/data';
import { customDropdownStyles } from '../../PersonalInfoForm/PersonalInfoForm';
import styles from '../../PersonalInfoForm/PersonalInfoForm.module.scss';
import data from '../data.json';
import LicenseForm from '../LicenseForm/LicenseForm';
import LocationForm from '../LocationForm/LocationForm';
import messages from '../messages';

const ClinicianRegistrationStepTwo = ({
  isLoading,
  values,
  handleChange,
  // handleBlur,
  setFieldValue,
  insuranceList,
  options,
  handleMultipleChoices,
  setLicenseValue,
  setLocationValue,
  locationValue,
  licenseValue,
  secondStepValidation,
  setSecondStepValidation,
}) => {
  const { formatMessage } = useIntl();

  const [editItemLicense, setEditItemLicense] = useState();
  const [editItemLocation, setEditItemLocation] = useState();

  const fieldValidators = (value, fieldName) => {
    const validationResult = requiredValidator(value, fieldName);
    if (fieldName === 'phone') {
      const phoneValidationResult = phoneValidator(value, fieldName);
      secondStepValidation[fieldName] = phoneValidationResult[fieldName];
    } else if (fieldName === 'contact_email') {
      const emailValidationResult = emailValidator(value, fieldName);
      secondStepValidation[fieldName] = emailValidationResult.email;
    } else if (Object.keys(validationResult).length) {
      secondStepValidation[fieldName] = validationResult[fieldName];
    } else {
      secondStepValidation[fieldName] = '';
    }
    setSecondStepValidation(secondStepValidation);
  };

  useEffect(() => {
    [
      'phone',
      'contact_email',
      'gender',
      'preferred_pronouns',
      'license_status',
      'telehealth',
    ].forEach((fieldName) => {
      fieldValidators(values[fieldName], fieldName);
    });
  }, [values]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.contactDetails.phoneLabel)}
          placeholder="Enter here"
          name="phone"
          value={values?.phone || ''}
          onChange={handleChange}
          onBlur={(e) => {
            setFieldValue('phone', e.target.value);
          }}
          data-testid="clinician phone"
          label-testid="clinician phone label"
          error-testid="clinician phone error"
          errorText={
            secondStepValidation.phone ? secondStepValidation?.phone : ''
          }
        />
      </Cell>
      <Cell small={12}>
        <FieldInput
          placeholder={'Enter here'}
          label={formatMessage(messages.contactDetails.contactEmailLabel)}
          name="contact_email"
          value={values.contact_email}
          onChange={handleChange}
          onBlur={(e) => {
            setFieldValue('contact_email', e.target.value);
          }}
          data-testid="clinician contact_email"
          label-testid="clinician contact_email label"
          error-testid="clinician contact_email error"
          errorText={
            secondStepValidation.contact_email
              ? secondStepValidation?.contact_email
              : ''
          }
        />
      </Cell>
      <Cell small={12}>
        <FieldDropdownWithInput
          isSearchable
          isClearable
          label={formatMessage(messages.gender)}
          onChange={(event) => {
            if (event === null) {
              setFieldValue('gender', null);
              return;
            }
            setFieldValue('gender', event.value);
          }}
          value={values.gender}
          options={genderOptions}
          placeholder="Select or type here"
          dataTestId="ClinicianRegistration.Gender.Dropdown"
          customStyles={customDropdownStyles}
          errorText={!values.gender ? secondStepValidation?.gender : ''}
        />
      </Cell>
      <Cell small={12}>
        <FieldInput
          label={formatMessage(messages.preferred_pronouns)}
          placeholder="Enter here"
          name="preferred_pronouns"
          value={values.preferred_pronouns}
          onChange={handleChange}
          onBlur={(e) => {
            setFieldValue('preferred_pronouns', e.target.value);
          }}
          dataTestId="ClinicianRegistration.PreferredPronouns.Dropdown"
          errorText={
            !values.preferred_pronouns
              ? secondStepValidation?.preferred_pronouns
              : ''
          }
        />
      </Cell>
      <Cell small={12}>
        <FieldDropdownWithInput
          isMulti
          isSearchable
          isClearable
          label={formatMessage(messages.certification)}
          onChange={(event) => {
            if (event === null) {
              setFieldValue('certification', []);
              return;
            }
            const arrOfItems = handleMultipleChoices(
              values.certification,
              event,
            );
            setFieldValue('certification', arrOfItems);
          }}
          value={values?.certification}
          options={data.certificationList}
          placeholder="Select or type here"
          dataTestId="ClinicianRegistration.CertificationList.Dropdown"
          customStyles={customDropdownStyles}
        />
      </Cell>
      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(messages.licenseStatus)}
          componentName="license_status"
          options={binaryResponses}
          dataTestId="ClinicianRegistration.LicenseStatus.RadioGroup"
          labelTestId="ClinicianRegistration.LicenseStatus.Label"
          value={values?.license_status}
          onChange={(value) => {
            if (value === 'yes') {
              values.clinic_locations = [];
            }
            if (value === 'no') {
              values.clinic_licence_states = [];
            }

            setFieldValue('license_status', value);
          }}
          errorText={
            !values.license_status ? secondStepValidation?.license_status : ''
          }
        />
      </Cell>
      {values.license_status === 'yes' && (
        <FieldArray
          name="clinic_licence_states"
          render={({ remove, push }) => (
            <>
              <LicenseForm
                handleMultipleChoices={handleMultipleChoices}
                push={push}
                values={values?.clinic_licence_states}
                licenseValue={!editItemLicense ? licenseValue : null}
                setLicenseValue={setLicenseValue}
                customDropdownStyles={customDropdownStyles}
                options={options}
                insuranceList={insuranceList}
              />
              {values?.clinic_licence_states?.length > 0 && (
                <Cell>
                  <AddedItem
                    displayFields={[
                      'state',
                      'license_number',
                      'expire_date',
                      'insurance_network',
                    ]}
                    displayEditItem="none"
                    remove={remove}
                    items={values.clinic_licence_states}
                    maxLength={10}
                    setEditItem={setEditItemLicense}
                  />
                </Cell>
              )}
              {!!editItemLicense && editItemLicense.delete && (
                <SuperBillMessageModal type="error">
                  <h1>Deleting Licenses</h1>
                  <p>Are you sure do you want to proceed?</p>
                  <div className={styles.buttonsWrapperDelete}>
                    <SecondaryButton
                      data-testid="ClinicianRegistration.CurrentLicense.Modal.CancelButton"
                      className={styles.cancel}
                      onClick={() => {
                        setEditItemLicense();
                      }}
                      label={'Cancel'}
                    />
                    <PrimaryButton
                      data-testid="ClinicianRegistration.CurrentLicense.Modal.YesButton"
                      label="Yes"
                      onClick={() => {
                        remove(editItemLicense.index);
                        setEditItemLicense();
                      }}
                    />
                  </div>
                </SuperBillMessageModal>
              )}
            </>
          )}
        />
      )}
      {values?.license_status === 'no' && (
        <FieldArray
          name="clinic_locations"
          render={({ remove, push }) => (
            <>
              <LocationForm
                push={push}
                values={values?.clinic_locations}
                locationValue={!editItemLocation ? locationValue : null}
                setLocationValue={setLocationValue}
                customDropdownStyles={customDropdownStyles}
              />
              {values.clinic_locations?.length > 0 && (
                <Cell>
                  <AddedItem
                    displayFields={['city', 'state', 'country']}
                    displayEditItem="none"
                    remove={remove}
                    items={values?.clinic_locations}
                    maxLength={10}
                    setEditItem={setEditItemLocation}
                  />
                </Cell>
              )}
              {!!editItemLocation && editItemLocation.delete && (
                <SuperBillMessageModal type="error">
                  <h1>Deleting Locations</h1>
                  <p>Are you sure do you want to proceed?</p>
                  <div className={styles.buttonsWrapperDelete}>
                    <SecondaryButton
                      data-testid="ClinicianRegistration.CurrentLocation.Modal.CancelButton"
                      className={styles.cancel}
                      onClick={() => {
                        setEditItemLocation();
                      }}
                      label={'Cancel'}
                    />
                    <PrimaryButton
                      data-testid="ClinicianRegistration.CurrentLocation.Modal.YesButton"
                      label="Yes"
                      onClick={() => {
                        remove(editItemLocation.index);
                        setEditItemLocation();
                      }}
                    />
                  </div>
                </SuperBillMessageModal>
              )}
            </>
          )}
        />
      )}

      <Cell small={12}>
        <RadioGroupWithLabel
          label={formatMessage(messages.telehealthLabel)}
          componentName="telehealth"
          options={binaryResponses}
          dataTestId="ClinicianRegistration.Telehealth.RadioGroup"
          labelTestId="ClinicianRegistration.Telehealth.Label"
          value={values.telehealth}
          onChange={(value) => setFieldValue('telehealth', value)}
          errorText={!values.telehealth ? secondStepValidation?.telehealth : ''}
        />
      </Cell>
      <Cell small={12} medium={12} className={styles['checkboxBtn']}>
        <Label>{formatMessage(messages.expertice)}</Label>
        {data.expertice.choices
          .sort((a, b) => (a.title > b.title ? 1 : -1))
          .map((choice) => (
            <Column
              small={12}
              medium={7}
              key={choice.key}
              className={styles['grid-cell']}
            >
              <LabeledCheckbox
                checkboxKey={choice.key}
                name="treatment"
                labelName={choice.title}
                value={choice.key}
                checked={values.treatment.includes(choice.key)}
                onChange={(e) => {
                  const { checked, value } = e.target;
                  if (checked) {
                    setFieldValue('treatment', [...values.treatment, value]);
                  } else {
                    setFieldValue(
                      'treatment',
                      values.treatment.filter((v) => v !== value),
                    );
                  }
                }}
                data-testid={`clinician treatment ${choice.title}`}
                label-testid={`clinician treatment label ${choice.title}`}
                error-testid={`clinician treatment error ${choice.title}`}
              />
            </Column>
          ))}
      </Cell>
      <Cell small={12} medium={12} className={styles['checkboxBtn']}>
        <Label>{formatMessage(messages.therapyTitle)}</Label>
        {data.therapyList.choices
          .sort((a, b) => (a.title > b.title ? 1 : -1))
          .map((choice) => (
            <Column
              small={12}
              medium={7}
              key={choice.key}
              className={styles['grid-cell']}
            >
              <LabeledCheckbox
                checkboxKey={choice.key}
                name="therapy"
                labelName={choice.title}
                value={choice.key}
                checked={values.therapy.includes(choice.key)}
                onChange={(e) => {
                  const { checked, value } = e.target;
                  if (checked) {
                    setFieldValue('therapy', [...values.therapy, value]);
                  } else {
                    setFieldValue(
                      'therapy',
                      values.therapy.filter((v) => v !== value),
                    );
                  }
                }}
                data-testid={`clinician treatment ${choice.title}`}
                label-testid={`clinician treatment label ${choice.title}`}
                error-testid={`clinician treatment error ${choice.title}`}
              />
            </Column>
          ))}
      </Cell>
    </>
  );
};

ClinicianRegistrationStepTwo.propTypes = {};

ClinicianRegistrationStepTwo.defaultProps = {};

export default ClinicianRegistrationStepTwo;
