import React from 'react';
import { Cell } from 'react-foundation';
import useIntl from '../../../../../hooks/useIntl';
import RadioGroupWithLabel from '../../../../common/inputs/RadioGroupWithLabel';
import TextAreaInputWithLabel from '../../../../common/inputs/TextAreaInputWithLabel';
import Loading from '../../../../common/Loading';
import styles from '../../PersonalInfoForm/PersonalInfoForm.module.scss';
import messages from '../messages';
import { stepOneFormStructure, binaryResponses } from '../data';

const SolsticeFormStepOne = ({
  isLoading,
  values,
  handleChange,
  setFieldValue,
  handleBlur,
}) => {
  const { formatMessage } = useIntl();

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Cell small={12}>
        <p className={styles.formSubtitle}>
          {formatMessage(messages.formInfoStepOne)}
        </p>
      </Cell>
      {stepOneFormStructure.map((item, i) => (
        <Cell small={12} key={i}>
          {item.type === 'textarea' && (
            <TextAreaInputWithLabel
              label={formatMessage(messages[item.label])}
              name={item.id}
              value={values[item.id]}
              onChange={handleChange}
              placeholder="Enter here"
              onBlur={handleBlur}
              autoFocus={true}
              data-testid={`SolsticeFormStepOne.${item.id}.Textarea`}
              rows={2}
            />
          )}
          {item.type === 'radio' && (
            <RadioGroupWithLabel
              label={formatMessage(messages[item.label])}
              componentName={item.id}
              name={item.id}
              options={binaryResponses}
              dataTestId={`SolsticeFormStepOne.${item.id}.RadioGroup`}
              labelTestId={`SolsticeFormStepOne.${item.id}.Label`}
              value={values[item.id]}
              onChange={(value) => setFieldValue(item.id, value)}
            />
          )}
        </Cell>
      ))}
    </>
  );
};

SolsticeFormStepOne.propTypes = {};

SolsticeFormStepOne.defaultProps = {};

export default SolsticeFormStepOne;
