import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Cell, Grid } from 'react-foundation';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { uploadDoctorProfilePic } from '../../../../../api/doctor';
import TextAreaInputWithLabel from '../../../../../components/common/inputs/TextAreaInputWithLabel';
import useIntl from '../../../../../hooks/useIntl';
import useScreen from '../../../../../hooks/useScreen';
import UploadCloudIcon from '../../../../../img/icons/common/outline-cloud.svg';
import TooltipIcon from '../../../../../img/icons/common/tooltip.svg';
import colors from '../../../../../styles/_colors.scss';
import { getCroppedImg } from '../../../../../utils/common';
import PrimaryButton from '../../../../common/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/buttons/SecondaryButton';
import FieldInput from '../../../../common/fields/FieldInput/FieldInput';
import Label from '../../../../common/Label';
import Loading from '../../../../common/Loading';
import Modal from '../../../../common/Modal';
import styles from '../ClinicianRegistration.module.scss';
import messages from '../messages';

const cropConfig = {
  x: 180,
  y: 180,
  unit: 'px',
  width: 160,
  height: 160,
  aspect: 1 / 1,
};

const ClinicianRegistrationStepThree = ({
  isLoading,
  values,
  handleChange,
  // handleBlur,
  authUser,
  dispatch,
  setProfile,
  setLoading,
}) => {
  const { formatMessage } = useIntl();
  const { isDesktop } = useScreen();

  const [imageBlob, setImageBlob] = useState('');
  const [showProfilePicDialog, setShowProfilePicDialog] = useState(false);
  const [cropObj, setCropObj] = useState(cropConfig);
  const [imageRef, setImageRef] = useState('');
  const [image, setImage] = useState(require(`../../../../../img/doctor.jpg`));
  const [error, setError] = useState('');
  const [uploadImage, setUploadImage] = useState(UploadCloudIcon);
  const [displayedText, setDisplayedText] = useState();

  const onClickUploadProfilePic = async () => {
    const formData = new FormData();
    formData.append('file', imageBlob, `${Date().now}.jpeg`);
    try {
      const { doctor } = await uploadDoctorProfilePic(
        { id: authUser.id, params: formData },
        dispatch,
      );
      setShowProfilePicDialog(false);
      setProfile(doctor.profile_pic);
      setUploadImage(doctor.profile_pic);
      setCropObj(cropConfig);
      setDisplayedText('Replace photo');
      values.profile_pic = doctor.profile_pic;
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const onDrop = (files) => {
    if (files.length === 0) {
      setError('File size must be less than 2 MB');
    }
    setShowProfilePicDialog(true);
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    if (files.length) {
      reader.readAsDataURL(files[0]);
    }
  };

  const onImageLoaded = (img) => {
    setImageRef(img);
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop /*percentCrop*/) => {
    setCropObj(crop); // we could also use percentCrop:
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageBlob = await getCroppedImg(
        imageRef,
        crop,
        `${Date.now()}.jpeg`,
      );
      setImageBlob(croppedImageBlob);
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Cell small={12} medium={12} className={styles['label-bold']}>
        <FieldInput
          label={formatMessage(messages.descriptionLabel)}
          placeholder="Enter Here"
          name="description"
          value={values.description}
          onChange={handleChange}
          // onBlur={handleBlur}
          data-testid={`clinician description`}
          label-testid="clinician description label"
          error-testid="clinician description error"
        />
      </Cell>
      <Cell small={12} className={styles['label-bold']}>
        <TextAreaInputWithLabel
          label={formatMessage(messages.contactDetails.bioPlaceholder)}
          placeholder="Enter here"
          rows={isDesktop ? 4 : 6}
          value={values.bio}
          name="bio"
          onChange={handleChange}
          // onBlur={handleBlur}
          style={{ borderRadius: 10 }}
          data-testid="clinician bio"
          label-testid="clinician bio label"
          error-testid="clinician bio error"
        ></TextAreaInputWithLabel>
      </Cell>
      <Cell small={12}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Label data-testid={`clinician profile pic`}>
            {formatMessage(messages.profilePic)}
          </Label>
          <Tooltip
            title={
              <React.Fragment>
                <Typography
                  style={{
                    color: 'white',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  {formatMessage(messages.uploadPhotoTooltip)}
                </Typography>
              </React.Fragment>
            }
            arrow
            placement="right"
          >
            <img
              src={TooltipIcon}
              style={{ verticalAlign: 'middle', marginLeft: '10px' }}
            />
          </Tooltip>
        </div>
        <Dropzone
          onDrop={onDrop}
          accept="image/jpeg, image/png"
          maxSize={2000000}
        >
          {({ getRootProps, getInputProps }) => {
            return (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className={styles.photoContainer}>
                  <img width="70px" height="70px" src={uploadImage} />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {(displayedText && (
                      <div style={{ marginTop: '25px' }}>
                        <Label>{displayedText}</Label>
                      </div>
                    )) || (
                      <>
                        <span
                          style={{
                            color: colors.grayDisabled,
                            textAlign: 'center',
                            fontFamily: 'Montserrat',
                          }}
                        >
                          Drag files here <br />
                          or
                          <br />
                        </span>
                        <b>Choose File</b>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          }}
        </Dropzone>
      </Cell>

      <div
        className={showProfilePicDialog ? styles.showDialog : styles.hideDialog}
      >
        {showProfilePicDialog && (
          <Modal
            className={[
              styles.profileModal,
              showProfilePicDialog ? 'showDialog' : '',
            ].join(' ')}
          >
            <div className={styles.content}>
              <h1>
                <b>Upload your profile photo</b>
              </h1>
              <p>Max file size: 2 MB</p>
              {error && <p className={styles.error}>{error}</p>}
              <div className={styles.cropper}>
                <ReactCrop
                  src={image}
                  crop={cropObj}
                  ruleOfThirds
                  onImageLoaded={onImageLoaded}
                  onComplete={onCropComplete}
                  onChange={onCropChange}
                  minHeight={160}
                  minWidth={160}
                  circularCrop={true}
                />
              </div>
            </div>
            <Grid className={styles['crop-action-grid']}>
              <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Cell
                      medium={5}
                      small={6}
                      className={styles['actions-container-left']}
                    >
                      <SecondaryButton
                        label="Choose another photo"
                        className="font-16"
                        type="button"
                        onClick={() => setCropObj(cropConfig)}
                        data-testid="clinician choose another photo button"
                      />
                    </Cell>
                  </div>
                )}
              </Dropzone>
              <Cell
                medium={7}
                small={6}
                className={styles['actions-container-right']}
              >
                <SecondaryButton
                  label="Cancel"
                  className="font-16"
                  type="button"
                  onClick={() => {
                    setShowProfilePicDialog(false);
                    setCropObj(cropConfig);
                  }}
                  data-testid={`clinician cancel button`}
                />
                <PrimaryButton
                  label="Save"
                  disabled={isLoading}
                  className="font-18"
                  type="button"
                  onClick={() => onClickUploadProfilePic()}
                  data-testid={`clinician save button`}
                />
              </Cell>
            </Grid>
          </Modal>
        )}
      </div>
    </>
  );
};

ClinicianRegistrationStepThree.propTypes = {};

ClinicianRegistrationStepThree.defaultProps = {};

export default ClinicianRegistrationStepThree;
