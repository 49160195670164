import React, { Fragment, useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Formik, Form } from 'formik';
import classnames from 'classnames';

import AssessmentInputs from '../../components/AssessmentInputs';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import Section from '../../components/common/Section';
import Loader from '../../components/common/Loading';
import SwipeCardInfo from '../../components/common/SwipeCardInfo';
import Progress from '../../components/Progress';

import { getEntries } from '../../api/contentful';
import { saveAssessments } from '../../api/user';
import { getLastAppointment } from '../../api/appointment';

import useIntl from '../../hooks/useIntl';
import messages from './messages';
import { addToDataLayer } from '../../utils/common';

import { DiscoverContext } from '../../context/DiscoverContext';
import AuthContext from '../../context/AuthContext';

import styles from './DiscoverPageV2.module.scss';

const formatTitle = ({ title, param }, answers) =>
  !param ? title : title.replace('*', answers[param].value);

const handleBackPage = (page, results, setPage, setDirection) => {
  let previousPage = page - 1;
  let evaluatedPage = results[previousPage];
  while (previousPage > 0 && evaluatedPage.type === 'modal') {
    previousPage--;
    evaluatedPage = results[previousPage];
  }
  setDirection(-1);
  return setPage(previousPage);
};

const mapResults = (items, state, formatMessage) => {
  if (state && state.firstName) {
    return items.reduce((acc, item) => {
      if (item.position === 0) {
        return [
          ...acc,
          {
            position: 0,
            type: 'transition',
            title: (
              <FormattedMessage
                {...messages.welcomeBackText}
                values={{ name: state.firstName }}
              />
            ),
            'jump-to-position': 4,
          },
        ];
      }
      return [...acc, item];
    }, []);
  }
  return items;
};

let currentPage;

const DiscoverPage = ({ match, location }) => {
  const questionnaireSlug = match?.params?.questionnaireSlug || 'eve-discover';

  const { formatMessage } = useIntl();
  const history = useHistory();

  const { authUser } = useContext(AuthContext);
  const discover = useContext(DiscoverContext);

  const [answers, setAnswers] = useState({});
  const [page, setPage] = useState(0);
  const [direction, setDirection] = useState(1);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLastStep, setIsLastStep] = useState(0);
  const [assessment, setAssessment] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    const fetchQuesionnaire = async () => {
      try {
        let questionnaire = await getEntries({
          'fields.key': questionnaireSlug,
          content_type: 'recommendationEngineQuestionnaire',
        });

        if (
          questionnaire.items.length > 0 &&
          questionnaire.items[0].fields['questions']
        ) {
          /**
           * we dont need to ask email again
           */
          setQuestionnaire(
            questionnaire.items[0].fields['questions'].filter(
              (item) => item.key !== 'email',
            ),
          );
          setAssessment(questionnaire.items[0]);
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
      }
    };
    fetchQuesionnaire();
  }, [questionnaireSlug]);

  useEffect(() => {
    if (Object.entries(answers).length > 0) {
      const progress = (page / questionnaire.length) * 100;
      discover.setProgress(progress);
    }
    if (questionnaire.length && Object.entries(answers).length === 0) {
      const initialAnswers = questionnaire.reduce(
        (acc, { key, parent, type, marks, persist }) =>
          key
            ? {
                ...acc,
                [key]:
                  type === 'slider'
                    ? { value: 3, min: 1, max: marks.length, persist: persist }
                    : {},
              }
            : acc,
        {},
      );
      setAnswers({
        ...initialAnswers,
        first_name:
          authUser.first_name || (location.state && location.state.firstName)
            ? { value: authUser.first_name || location.state.firstName }
            : {},
      });
    }
    discover.setStep(page);
  }, [
    answers,
    authUser.first_name,
    location.state,
    questionnaire,
    discover,
    page,
  ]);

  useEffect(() => {
    const saveAnswer = async () => {
      try {
        setIsLoading(true);
        let type;

        if (
          assessment.fields['key'] === 'eve-discover' ||
          authUser?.whitelabelDetails?.assessment_key
        ) {
          type = 'matching';
        } else {
          type = 'follow-up';
        }

        if (isLastStep === 1) {
          const appointment = await getLastAppointment(authUser.id);
          let doctorId = null;
          if (appointment && appointment.doctor_id) {
            doctorId = appointment.doctor_id;
          }

          const data = await saveAssessments({
            assessment_name: assessment.fields['title'],
            external_id: assessment.sys['id'],
            answers,
            patient_id: authUser.id,
            assessment_type: type,
            doctor_id: doctorId,
            clinic_id: (authUser.invites && authUser.invites.clinic_id) || 0,
            isInvited:
              authUser.invites && authUser.invites.invited_url ? true : false,
            url: window.location.hostname,
            white_labelled_clinic: authUser.whitelabel_id || false,
          });
          if (assessment.fields['key'] === 'eve-discover') {
            localStorage.setItem('assessment-id', data.assessment.id);
          }
        }
        if (assessment.fields['key'] === 'eve-discover') {
          localStorage.setItem(
            'recommendations-answers-id',
            'c6719a18-9ace-41b9-8e17-26c6e686a082',
          );
          history.push('/my-account', {
            name: answers.first_name.value,
          });
        } else {
          history.push('/my-account');
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
    if (isLastStep) {
      saveAnswer();
    }
  }, [
    answers,
    assessment.fields,
    assessment.sys,
    authUser.whitelabel_id,
    authUser.whitelabelDetails,
    authUser.id,
    authUser.invites,
    history,
    isLastStep,
  ]);

  currentPage = mapResults(questionnaire, location.state, formatMessage)[page];
  if (currentPage && currentPage.parent) {
    const parentOptions = answers[currentPage.parent].options;
    if (!parentOptions.find((option) => option.key === currentPage.key)) {
      setAnswers({ ...answers, [currentPage.key]: undefined });
      setPage(page + direction);
    }
  }
  const onCardInforButtonClick = (item, isRight) => {
    setAnswers({
      ...answers,
      [item.key]: {
        value: isRight ? 'yes' : 'no',
        key: item.key,
        to: page + 1,
      },
    });
    const eventData = {
      event: 'ga4_event',
      properties: {
        event_name: 'assessment',
        clinician_id: 'NA',
        clinician_name: 'NA',
        patient_id: authUser ? authUser.id : '',
        step_name: (currentPage && currentPage.title) || '',
        step_number: page,
        details: {
          value: isRight ? 'yes' : 'no',
        },
        assessment_name: assessment.fields['title'],
        [currentPage.key]: {
          value: isRight ? 'yes' : 'no',
        },
      },
    };
    addToDataLayer(eventData);
    setPage(answers[item.key].to || page + 1);
  };

  document.onkeydown = (e) => {
    e = e || window.event;
    if (e.keyCode && (e.keyCode === 37 || e.keyCode === 39)) {
      if (currentPage.type === 'swipe-card') {
        onCardInforButtonClick(currentPage, e.keyCode === 39);
      }
    }
  };

  return (
    <Section>
      {isLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <Helmet>
            <meta
              name="description"
              content={formatMessage(messages.metaDescription)}
            />
          </Helmet>
          <div
            className={classnames(
              styles[`quiz-container-bg`],
              styles[`container-background`],
            )}
          >
            {page !== 0 && (
              <div className={styles.header}>
                <div className={styles['header-left']}>
                  <span data-testid="assessment welcome text">
                    {answers &&
                      answers.first_name &&
                      answers.first_name.value &&
                      `Welcome ${answers.first_name.value}`}
                  </span>
                </div>
                <div
                  className={styles['header-right']}
                  style={{ maxHeight: '5px' }}
                >
                  <div>
                    <Progress
                      width={103}
                      height={5}
                      percent={discover.progress}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className={styles.lineArt}>
              <img src={require(`../../img/LineArt.svg`)} alt="line-art" />
            </div>
            {!isLoading && (
              <Formik
                onSubmit={() => {
                  setDirection(1);
                  const eventData = {
                    event: 'ga4_event',
                    properties: {
                      event_name: 'assessment',
                      clinician_id: 'NA',
                      clinician_name: 'NA',
                      patient_id: authUser ? authUser.id : '',
                      step_name: (currentPage && currentPage.title) || '',
                      step_number: page,
                      details: answers[currentPage.key],
                      assessment_name: assessment.fields['title'],
                      [currentPage.key]: answers[currentPage.key],
                    },
                  };
                  addToDataLayer(eventData);
                  setPage(
                    (page !== 0 &&
                      currentPage.type !== 'modal' &&
                      answers[currentPage.key].to) ||
                      page + 1,
                  );
                }}
              >
                {({ errors, initialValues }) => (
                  <Fragment>
                    <Form
                      className={
                        currentPage.type === 'modal' ||
                        currentPage.type === 'swipe-card'
                          ? styles['modal-content']
                          : styles['content']
                      }
                    >
                      <div className={classnames(styles[`form-div`])}>
                        {currentPage.image && (
                          <div
                            className={classnames(
                              styles[`icon`],
                              styles[`${currentPage.image}`],
                            )}
                          >
                            <img
                              className={classnames(styles[`img-bg`])}
                              src={require(`../../img/icons/${currentPage.image}.svg`)}
                              alt={currentPage.title}
                            />
                          </div>
                        )}
                        {page === 0 ? (
                          <h1 data-testid="assessment header">
                            {currentPage.title}
                          </h1>
                        ) : (
                          <h2 data-testid="assessment header">
                            {formatTitle(currentPage, answers)}
                          </h2>
                        )}
                        {!currentPage.swipCardInfo && currentPage.subtitle && (
                          <div
                            className={styles['sub-text']}
                            data-testid="assessment text"
                          >
                            {currentPage.subtitle}
                          </div>
                        )}
                        {page === 0 && currentPage.cta && (
                          <div className={classnames(styles[`button-row`])}>
                            <PrimaryButton
                              label={currentPage.cta}
                              className={classnames(styles[`button`])}
                              data-testid="assessment button"
                            />
                          </div>
                        )}
                        <AssessmentInputs
                          currentPage={currentPage}
                          answers={answers}
                          setAnswers={setAnswers}
                          page={page}
                          setPage={setPage}
                          initialValues={initialValues}
                          formatMessage={formatMessage}
                          history={history}
                          isLastStep={isLastStep}
                          setIsLastStep={setIsLastStep}
                          assessment={assessment}
                          authUser={authUser}
                        />
                        {errors[currentPage.key] && (
                          <div className={styles.error}>
                            {errors[currentPage.key]}
                          </div>
                        )}
                      </div>
                      {page !== 0 && currentPage.type !== 'transition' && (
                        <div
                          className={
                            currentPage.type === 'swipe-card'
                              ? styles['single-button-row']
                              : styles['buttons-row']
                          }
                        >
                          {currentPage.key !== 'name' && (
                            <div
                              className={classnames(styles[`buttons-row-left`])}
                              onClick={() =>
                                handleBackPage(
                                  page,
                                  questionnaire,
                                  setPage,
                                  setDirection,
                                )
                              }
                            >
                              <span
                                className={classnames(styles[`back-button`])}
                                data-testid="assessment back button"
                              >
                                {formatMessage(messages.backLabel)}
                              </span>
                            </div>
                          )}
                          {currentPage.cta && (
                            <div
                              className={
                                currentPage.key !== 'name'
                                  ? styles['buttons-row-right']
                                  : ''
                              }
                            >
                              <PrimaryButton
                                data-testid="assessment next button"
                                disabled={
                                  currentPage.type !== 'slider' &&
                                  currentPage.key &&
                                  (!answers[currentPage.key].value ||
                                    answers[currentPage.key].value.length ===
                                      0) &&
                                  (!answers[currentPage.key].options ||
                                    answers[currentPage.key].options.length ===
                                      0) &&
                                  currentPage.noResponse !== 'true'
                                }
                                onClick={() => {
                                  currentPage.type === 'slider' &&
                                    (!isNaN(
                                      Number(answers[currentPage.key].value),
                                    ) ||
                                      setAnswers({
                                        ...answers,
                                        [currentPage.key]: {
                                          value: 3,
                                          min: 1,
                                          max: currentPage.marks.length,
                                          persist: currentPage.persist,
                                        },
                                      }));
                                  currentPage.noResponse === 'true' &&
                                    setAnswers({
                                      ...answers,
                                      [currentPage.key]: {
                                        value:
                                          answers[currentPage.key].value ||
                                          'no-response',
                                      },
                                    });
                                }}
                                label={
                                  currentPage.type !== 'modal'
                                    ? currentPage.cta
                                    : 'Next'
                                }
                                className={classnames(styles[`button`])}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </Form>
                    <Fragment>
                      {currentPage.swipCardInfo &&
                        currentPage.type !== 'modal' && (
                          <div className={styles.swipeCardContent}>
                            <div className={styles.swipeCardInfo}>
                              <SwipeCardInfo
                                onClickYes={() =>
                                  onCardInforButtonClick(currentPage, true)
                                }
                                onClickNo={() =>
                                  onCardInforButtonClick(currentPage, false)
                                }
                              />
                            </div>
                          </div>
                        )}
                    </Fragment>
                  </Fragment>
                )}
              </Formik>
            )}
          </div>
        </Fragment>
      )}
    </Section>
  );
};

export default DiscoverPage;
