import React, { useState, Fragment, useContext, useEffect } from 'react';
import classnames from 'classnames';
import Loading from '../../../components/common/Loading';
import DoctorLeftNav from '../../../components/nav/DoctorLeftNav';
import styles from './DoctorPaymentPage.module.scss';
import AuthContext from '../../../context/AuthContext';
import { getDoctorPayment } from '../../../api/doctor';
import NumberFormat from 'react-number-format';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import useIntl from '../../../hooks/useIntl';
import messages from './messages';

const DoctorPaymentPage = () => {
  const { formatMessage } = useIntl();
  const { isLoading, authUser } = useContext(AuthContext);
  const [dataLoading, setLoading] = useState(false);
  const [availableAmount, setAvailableAmount] = useState(0);
  const isDataLoading = dataLoading || isLoading;

  const fetchDoctorPayment = async () => {
    if (authUser && authUser.id) {
      try {
        const payment = await getDoctorPayment(authUser.id);
        setAvailableAmount(payment.total_amount);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchDoctorPayment();
  }, [authUser]);

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftNav, 'desktop-only')}>
        <DoctorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            />
            <h1>{formatMessage(messages.title)}</h1>
            <div className={styles.mainBox}>
              <div className={styles.card}>
                <div className={styles.cardHeader}>
                  <span> {formatMessage(messages.balanceTitle)} </span>
                  <div className={styles.buttons}>
                    <SecondaryButton
                      label={formatMessage(messages.payoutButtonLabel)}
                      type="button"
                      className={styles.btn}
                    />
                    <SecondaryButton
                      label={formatMessage(messages.topUpButtonLabel)}
                      type="button"
                      className={styles.btn}
                    />
                  </div>
                </div>
                <span className={styles.hr}></span>
                <div className={styles.cardRow}>
                  <span>{formatMessage(messages.availablePayoutLabel)}</span>
                  <span>
                    <NumberFormat
                      value={availableAmount}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </span>
                </div>
                <div className={styles.cardRow}>
                  <span>{formatMessage(messages.onTheWayToYourBankLabel)}</span>
                  <span>
                    <NumberFormat
                      value={0}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </span>
                </div>
                <div className={styles.cardRow}>
                  <span>
                    {formatMessage(messages.willBeAvailableSoonLabel)}
                  </span>
                  <span>
                    <NumberFormat
                      value={0}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </span>
                </div>
                <span className={styles.hr}></span>
                <div className={styles.cardFooter}>
                  <span> {formatMessage(messages.totalLabel)} </span>
                  <span>
                    <NumberFormat
                      value={availableAmount}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </span>
                </div>
              </div>
              <div className={[styles.card, styles.cardMargin].join(' ')}>
                <div className={styles.cardRow2}>
                  <span>
                    <div>{formatMessage(messages.availablePayoutLabel)}</div>
                    <div>
                      Automatic payouts are <a>disabled.</a>
                    </div>
                  </span>
                </div>
                <span className={styles.hr}></span>
                <div className={styles.cardFooter}>
                  <span> {formatMessage(messages.totalLabel)} </span>
                  <span>
                    <NumberFormat
                      value={0}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </span>
                </div>
              </div>
              <div className={[styles.card, styles.cardMargin].join(' ')}>
                <div className={styles.cardRow2}>
                  <span>
                    <div>{formatMessage(messages.onTheWayToYourBankLabel)}</div>
                    <div>
                      {formatMessage(messages.fundWillBeAvailableSoonLabel)}
                    </div>
                  </span>
                </div>
                <span className={styles.hr}></span>
                <div className={styles.cardFooter}>
                  <span> {formatMessage(messages.totalLabel)} </span>
                  <span>
                    <NumberFormat
                      value={0}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default DoctorPaymentPage;
