import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './BenefitIcon.module.scss';
import Aroused50pxImg from '../../img/icons/Benefit/50px/Aroused.png';
import Aroused100pxImg from '../../img/icons/Benefit/100px/Aroused.png';
import Beautiful50pxImg from '../../img/icons/Benefit/50px/Beautiful.png';
import Beautiful100pxImg from '../../img/icons/Benefit/100px/Beautiful.png';
import Calm50pxImg from '../../img/icons/Benefit/50px/Calm.png';
import Calm100pxImg from '../../img/icons/Benefit/100px/Calm.png';
import Energized50pxImg from '../../img/icons/Benefit/50px/Energized.png';
import Energized100pxImg from '../../img/icons/Benefit/100px/Energized.png';
import Focused50pxImg from '../../img/icons/Benefit/50px/Focused.png';
import Focused100pxImg from '../../img/icons/Benefit/100px/Focused.png';
import GeneralWell50pxImg from '../../img/icons/Benefit/50px/GeneralWell.png';
import GeneralWell100pxImg from '../../img/icons/Benefit/100px/GeneralWell.png';
import Happy50pxImg from '../../img/icons/Benefit/50px/Happy.png';
import Happy100pxImg from '../../img/icons/Benefit/100px/Happy.png';
import PainRelief50pxImg from '../../img/icons/Benefit/50px/PainRelief.png';
import PainRelief100pxImg from '../../img/icons/Benefit/100px/PainRelief.png';
import Rested50pxImg from '../../img/icons/Benefit/50px/Rested.png';
import Rested100pxImg from '../../img/icons/Benefit/100px/Rested.png';
import StarBenefitIcon from '../../img/icons/benefit-star.svg';

const chooseIcon = (icon, size) => {
  switch (icon) {
    case 'aroused':
      return size === '50px' ? Aroused50pxImg : Aroused100pxImg;
    case 'beautiful':
    case 'beauty':
      return size === '50px' ? Beautiful50pxImg : Beautiful100pxImg;
    case 'calm':
      return size === '50px' ? Calm50pxImg : Calm100pxImg;
    case 'energized':
      return size === '50px' ? Energized50pxImg : Energized100pxImg;
    case 'clarity':
    case 'focused':
      return size === '50px' ? Focused50pxImg : Focused100pxImg;
    case 'general-wellness':
      return size === '50px' ? GeneralWell50pxImg : GeneralWell100pxImg;
    case 'happy':
      return size === '50px' ? Happy50pxImg : Happy100pxImg;
    case 'pain-relief':
    case 'pain':
      return size === '50px' ? PainRelief50pxImg : PainRelief100pxImg;
    case 'rested':
      return size === '50px' ? Rested50pxImg : Rested100pxImg;
    default:
      return StarBenefitIcon;
  }
};

const BenefitIcon = ({ icon, size, className }) => (
  <img
    src={chooseIcon(icon, size)}
    alt={icon}
    className={classNames(styles.img, {
      [className]: className,
    })}
  />
);

BenefitIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['50px', '100px']),
};

BenefitIcon.defaultProps = {
  size: '50px',
};

export default BenefitIcon;
