import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import OverlayContext from '../../../context/OverlayContext';
import BoxContext from '../../../context/BoxContext';
import styles from './DiscoverNav.module.scss';
import { DiscoverContext } from '../../../context/DiscoverContext';
import UserPopup from '../UserPopup';
import Logo from '../../Logo';

const DiscoverNav = ({ isPatient, isSticky, progressPercent }) => {
  const { showLearnMenuOverlay, setShowLearnMenuOverlay } =
    useContext(OverlayContext);
  const { isBoxOpen } = useContext(BoxContext);
  const [isShopMenuOpen, setIsShopMenuOpen] = useState(false);
  const [isLearnMenuOpen, setIsLearnMenuOpen] = useState(false);
  const [isExploreMenuOpen, setIsExploreMenuOpen] = useState(false);
  const [Name, setName] = useState('');
  const discover = useContext(DiscoverContext);
  const { answers } = discover;

  const isTransparentBanner =
    isPatient &&
    !isShopMenuOpen &&
    !isLearnMenuOpen &&
    !isExploreMenuOpen &&
    !isBoxOpen &&
    !isSticky;
  useEffect(() => {
    if (answers) {
      if (answers.first_name) {
        setName(answers.first_name);
      }
    }
  }, [answers]);

  useEffect(() => {
    if (showLearnMenuOverlay) {
      setIsLearnMenuOpen(true);
    }
    return isLearnMenuOpen || isShopMenuOpen || isExploreMenuOpen
      ? document.body.classList.add('no-scroll')
      : document.body.classList.remove('no-scroll');
  }, [
    isShopMenuOpen,
    isLearnMenuOpen,
    isExploreMenuOpen,
    setIsLearnMenuOpen,
    showLearnMenuOverlay,
  ]);

  return (
    <div
      className={classnames('desktop-only', styles.container, {
        [styles.home]: isTransparentBanner,
        [styles['overlay-open']]:
          isShopMenuOpen || isLearnMenuOpen || isExploreMenuOpen || isBoxOpen,
        'fixed-nav': isSticky,
      })}
    >
      <div className={styles['menu-left']}>
        {Name.value && `Hello ${Name.value}!`}
      </div>
      <div className={styles.logo}>
        <Link
          to={isPatient ? '/my-account' : '/doctor/my-account'}
          onClick={() => {
            setIsShopMenuOpen(false);
            setIsLearnMenuOpen(false);
            setIsExploreMenuOpen(false);
            setShowLearnMenuOverlay(false);
          }}
        >
          <Logo />
        </Link>
      </div>
      <div className={styles['menu-right']} style={{ maxHeight: '5px' }}>
        <UserPopup theme={'default'} />
      </div>
    </div>
  );
};

DiscoverNav.propTypes = {
  isPatient: PropTypes.bool,
  isSticky: PropTypes.bool,
  progressPercent: PropTypes.number,
};

DiscoverNav.defaultProps = {
  isPatient: false,
  isSticky: false,
  progressPercent: 0,
};

export default DiscoverNav;
