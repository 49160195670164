import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/common/Modal';
import styles from './PreNylasIntegrationPopup.module.scss';

import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import FieldInput from '../../../components/common/fields/FieldInput/FieldInput';

import { emailValidator } from '../../../utils/validators';

const { REACT_APP_NYLAS_BASE_PATH, REACT_APP_NYLAS_APPLICATION_ID } =
  process.env;
const redirect_uri = window.location.origin;
// const redirect_uri = process.env.REACT_APP_NYLAS_REDIRECT_URI; // for local testing

const PreNylasIntegrationPopup = ({ onCloseModal }) => {
  const [googleEmail, setGoogleEmail] = useState('');
  const [otherEmail, setOtherEmail] = useState('');

  const [googleEmailError, setGoogleEmailError] = useState(null);
  const [otherEmailError, setOtherEmailError] = useState(null);

  const handleGoogleBtn = () => {
    if (!!emailValidator(googleEmail.trim()).email) {
      setGoogleEmailError('Email is not valid');
      return;
    }
    window.location.href = `${REACT_APP_NYLAS_BASE_PATH}/oauth/authorize?client_id=${REACT_APP_NYLAS_APPLICATION_ID}&response_type=code&login_hint=${googleEmail}&redirect_uri=${redirect_uri}/api/b2c/nylas_callback&scopes=calendar`;
  };

  const handleOtherBtn = () => {
    if (!!emailValidator(otherEmail.trim()).email) {
      setOtherEmailError('Email is not valid');
      return;
    }
    window.location.href = `${REACT_APP_NYLAS_BASE_PATH}/oauth/authorize?client_id=${REACT_APP_NYLAS_APPLICATION_ID}&response_type=code&login_hint=${otherEmail}&redirect_uri=${redirect_uri}/api/b2c/nylas_callback&scopes=calendar`;
  };

  const handleGoogleEmail = (e) => {
    setGoogleEmailError(null);
    setGoogleEmail(e.target.value);
  };

  const handleOtherEmail = (e) => {
    setOtherEmailError(null);
    setOtherEmail(e.target.value);
  };

  return (
    <Modal className={styles.mainContainer}>
      <div className={styles.content}>
        <h1 className={styles.modalTitle}>Calendar Sign-up</h1>
        <div className={styles.modalSubTitle}>
          Please provide the email address associated with your calendar. We use
          Nylas to power our scheduling system.
        </div>
        <div className={styles.container}>
          <div className={styles.leftside}>
            <FieldInput
              placeholder={'Gmail Address'}
              label={'Gmail Address'}
              errorText={googleEmailError}
              name="google-provider-email"
              value={googleEmail}
              onChange={handleGoogleEmail}
            />
            <div className={styles.sidebtn}>
              <button
                className={[
                  styles.googlebtn,
                  googleEmail ? '' : styles.googlebtndisabled,
                ].join(' ')}
                onClick={handleGoogleBtn}
              />
            </div>
          </div>

          <div className={styles.rightside}>
            <FieldInput
              placeholder={'Email'}
              label={'Other Email Address'}
              errorText={otherEmailError}
              name="other-provider-email"
              value={otherEmail}
              onChange={handleOtherEmail}
            />
            <div className={styles.sidebtn}>
              <PrimaryButton
                label="Submit"
                onClick={handleOtherBtn}
                disabled={!otherEmail}
              />
            </div>
          </div>
        </div>

        <div className={styles.buttonsWrapper}>
          <SecondaryButton label="Close" onClick={onCloseModal} />
        </div>
      </div>
    </Modal>
  );
};

PreNylasIntegrationPopup.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
};

export default PreNylasIntegrationPopup;
