import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useIntl from '../../hooks/useIntl';
import messages from './messages';
import styles from './CookiesPanel.module.scss';
import PrimaryButton from '../common/buttons/PrimaryButton';
import DynamicClinicText from '../DynamicClinicText';

const CookiesPanel = () => {
  const { formatMessage } = useIntl();
  const [cookiePolicy, setCookiePolicy] = useState(
    localStorage.getItem('cookiePolicy') === 'true',
  );

  useEffect(() => {
    if (cookiePolicy) localStorage.setItem('cookiePolicy', cookiePolicy);
  }, [cookiePolicy]);

  return (
    !cookiePolicy && (
      <div>
        <div className={styles['container']} data-testid="cookie-policy">
          <p>
            At <DynamicClinicText />, we adhere to the highest standards for
            protecting your personal data, and will never share your data
            without your explicit consent. <DynamicClinicText /> does use
            cookies to facilitate and optimize your experience within our
            website. For more information, view our policies &nbsp;
            <span className={styles['link']}>
              <Link
                onClick={() => setCookiePolicy(true)}
                to="/agreements/privacy-policy"
              >
                {formatMessage(messages.cookiesStatementPart2)}
              </Link>
            </span>
          </p>
          <PrimaryButton
            label={formatMessage(messages.buttonLabel)}
            onClick={() => setCookiePolicy(true)}
            data-testid="cookie-policy button"
          />
        </div>
      </div>
    )
  );
};

export default CookiesPanel;
