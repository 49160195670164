import React from 'react';
import LayoutPDF from './components/LayoutPDF';
import FullRowLabelledData from './components/FullRowLabelledData';
import TitleSection from './components/TitleSection';
import SubtitleSection from './components/SubtitleSection';
import { messages } from '../forms/user/WildHeartForm/messages';

const WildHeartIntakePDF = ({ item, formDetail, data }) => {
  return (
    <LayoutPDF item={item} formDetail={formDetail}>
      <>
        <FullRowLabelledData
          label={messages.name.defaultMessage}
          value={data.name}
        />
        <FullRowLabelledData
          label={messages.dob.defaultMessage}
          value={data.dob}
        />
        <FullRowLabelledData
          label={messages.regularUpdatesPermission.defaultMessage}
          value={data.regular_updates_permission}
        />
        <FullRowLabelledData
          label={messages.currentTherapist.defaultMessage}
          value={data.current_therapist}
        />
        <FullRowLabelledData
          label={messages.therapistPhone.defaultMessage}
          value={data.therapist_phone}
        />
        <FullRowLabelledData
          label={messages.problemsDescription.defaultMessage}
          value={data.problems_description}
        />
        <FullRowLabelledData
          label={messages.treatmentGoals.defaultMessage}
          value={data.treatment_goals}
        />
        <FullRowLabelledData
          label={messages.treatmentChecklist.defaultMessage}
          value={data.treatment_checklist}
        />
        {!!data?.treatment_checklist_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.otherWriteIn.defaultMessage}
            value={data.treatment_checklist_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.majorSymptoms.defaultMessage}
          value={data.major_symptoms}
        />
        {/* Page 2 */}
        <TitleSection
          title={messages.suicideRiskAssessmentSectionTitle.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.hadLifeNegativeThoughts.defaultMessage}
          value={data.life_negative_thoughts}
        />
        <SubtitleSection
          title={messages.suicideThoughtsTextSpacer.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.hasLifeNegativeThoughts.defaultMessage}
          value={data.suicidal_thoughts}
        />
        <FullRowLabelledData
          label={messages.lifeNegativeThoughtsFrequency.defaultMessage}
          value={data.life_negative_thoughts_frequency}
        />
        <FullRowLabelledData
          label={messages.lastTimeLifeNegativeThoughts.defaultMessage}
          value={data.last_time_life_negative_thoughts}
        />
        <FullRowLabelledData
          label={messages.lifeNegativeThoughtsTriggerEvent.defaultMessage}
          value={data.life_negative_thoughts_trigger_event}
        />
        <FullRowLabelledData
          label={messages.lifeNegativeThoughtsIntensityScale.defaultMessage}
          value={data.life_negative_thoughts_intensity_scale}
        />
        <FullRowLabelledData
          label={messages.lifeNegativeThoughtsImprovementFactors.defaultMessage}
          value={data.life_negative_thoughts_improvement_factors}
        />
        <FullRowLabelledData
          label={messages.envisagedSuicideMethod.defaultMessage}
          value={data.envisaged_suicide_method}
        />
        <FullRowLabelledData
          label={messages.suicideMethodAvailability.defaultMessage}
          value={data.suicide_method_availability}
        />
        <FullRowLabelledData
          label={messages.suicidePlannedTime.defaultMessage}
          value={data.suicide_planned_time}
        />
        <FullRowLabelledData
          label={messages.suicideAbandonPossibility.defaultMessage}
          value={data.suicide_abandon_possibility}
        />
        <FullRowLabelledData
          label={messages.feelingHopeless.defaultMessage}
          value={data.feeling_hopeless}
        />
        <FullRowLabelledData
          label={messages.previousSelfHarmingAttempts.defaultMessage}
          value={data.previous_self_harming_attempts}
        />
        <FullRowLabelledData
          label={messages.gunsAccess.defaultMessage}
          value={data.guns_access}
        />
        {!!data?.guns_access_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.gunsAccessDependentFields.defaultMessage}
            value={data.treatment_goals_free_text}
          />
        )}
        {/* Page 3 */}
        <TitleSection
          title={messages.pastMedicalHistorySectionTitle.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.allergies.defaultMessage}
          value={data.allergies}
        />
        <FullRowLabelledData
          label={messages.currentWeight.defaultMessage}
          value={data.current_weight}
        />
        <FullRowLabelledData
          label={messages.height.defaultMessage}
          value={data.height}
        />
        <FullRowLabelledData
          label={messages.prescribedMedications.defaultMessage}
          value={data.prescribed_medications}
        />
        <FullRowLabelledData
          label={messages.takenMedicationsSupplements.defaultMessage}
          value={data.taken_medications_supplements}
        />
        <FullRowLabelledData
          label={messages.currentMedicalProblems.defaultMessage}
          value={data.current_medical_problems}
        />
        <FullRowLabelledData
          label={messages.pastMedicalProblems.defaultMessage}
          value={data.past_medical_problems}
        />
        <FullRowLabelledData
          label={messages.pastEKG.defaultMessage}
          value={data.past_ekg}
        />
        {!!data?.past_ekg_time && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.pastEKGTime.defaultMessage}
            value={data.past_ekg_time}
          />
        )}
        {!!data?.past_ekg_result && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.pastEKGResult.defaultMessage}
            value={data.past_ekg_result}
          />
        )}
        <SubtitleSection
          title={messages.womenOnlySectionTitle.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.lastMenstrualPeriod.defaultMessage}
          value={data.last_menstrual_period}
        />
        <FullRowLabelledData
          label={messages.pregnancyStatus.defaultMessage}
          value={data.pregnancy_status}
        />
        <FullRowLabelledData
          label={messages.pregnancyPlans.defaultMessage}
          value={data.pregnancy_plans}
        />
        <FullRowLabelledData
          label={messages.birthControlMethod.defaultMessage}
          value={data.birth_control_method}
        />
        <FullRowLabelledData
          label={messages.numberOfPregnancies.defaultMessage}
          value={data.number_of_pregnancies}
        />
        <FullRowLabelledData
          label={messages.liveBirths.defaultMessage}
          value={data.live_births}
        />
        {/* Page 4 */}
        <TitleSection title={messages.familySectionTitle.defaultMessage} />
        <FullRowLabelledData
          label={messages.physicalHealthConcerns.defaultMessage}
          value={data.physical_health_concerns}
        />
        <FullRowLabelledData
          label={messages.physicalExamDateAndPlace.defaultMessage}
          value={data.physical_exam_date_and_place}
        />
        <SubtitleSection
          title={messages.familyAilmentsTextDelimiter.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.thyroidDisease.defaultMessage}
          value={data.thyroid_disease}
        />
        {!!data?.family_member_with_thyroid_disease && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_with_thyroid_disease}
          />
        )}
        <FullRowLabelledData
          label={messages.anemia.defaultMessage}
          value={data.anemia}
        />
        {!!data?.family_member_with_anemia && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_with_anemia}
          />
        )}
        <FullRowLabelledData
          label={messages.liverDisease.defaultMessage}
          value={data.liver_disease}
        />
        {!!data?.family_member_with_liver_disease && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_with_liver_disease}
          />
        )}
        <FullRowLabelledData
          label={messages.chronicFatigue.defaultMessage}
          value={data.chronic_fatigue}
        />
        {!!data?.family_member_with_chronic_fatigue && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_with_chronic_fatigue}
          />
        )}
        <FullRowLabelledData
          label={messages.chronicFatigue.defaultMessage}
          value={data.chronic_fatigue}
        />
        {!!data?.family_member_with_chronic_fatigue && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_with_chronic_fatigue}
          />
        )}
        <FullRowLabelledData
          label={messages.kidneyDisease.defaultMessage}
          value={data.kidney_disease}
        />
        {!!data?.family_member_with_kidney_disease && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_with_kidney_disease}
          />
        )}
        <FullRowLabelledData
          label={messages.diabetes.defaultMessage}
          value={data.diabetes}
        />
        {!!data?.family_member_with_diabetes && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_with_diabetes}
          />
        )}
        <FullRowLabelledData
          label={messages.asthmaRespiratoryProblems.defaultMessage}
          value={data.asthma_respiratory_problems}
        />
        {!!data?.family_member_asthma_respiratory_problems && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_asthma_respiratory_problems}
          />
        )}
        <FullRowLabelledData
          label={messages.stomachOrIntestinalProblems.defaultMessage}
          value={data.stomach_or_intestinal_problems}
        />
        {!!data?.family_member_stomach_or_intestinal_problems && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_stomach_or_intestinal_problems}
          />
        )}
        <FullRowLabelledData
          label={messages.cancer.defaultMessage}
          value={data.cancer}
        />
        {!!data?.family_member_cancer && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_cancer}
          />
        )}
        <FullRowLabelledData
          label={messages.fibromyalgia.defaultMessage}
          value={data.fibromyalgia}
        />
        {!!data?.family_member_fibromyalgia && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_fibromyalgia}
          />
        )}
        <FullRowLabelledData
          label={messages.heartDisease.defaultMessage}
          value={data.heart_disease}
        />
        {!!data?.family_member_heart_disease && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_heart_disease}
          />
        )}
        <FullRowLabelledData
          label={messages.epilepsyOrSeizures.defaultMessage}
          value={data.epilepsy_or_seizures}
        />
        {!!data?.family_member_epilepsy_or_seizures && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_epilepsy_or_seizures}
          />
        )}
        <FullRowLabelledData
          label={messages.epilepsyOrSeizures.defaultMessage}
          value={data.epilepsy_or_seizures}
        />
        {!!data?.family_member_epilepsy_or_seizures && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_epilepsy_or_seizures}
          />
        )}
        <FullRowLabelledData
          label={messages.chronicPain.defaultMessage}
          value={data.chronic_pain}
        />
        {!!data?.family_member_chronic_pain && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_chronic_pain}
          />
        )}
        <FullRowLabelledData
          label={messages.highCholesterol.defaultMessage}
          value={data.high_cholesterol}
        />
        {!!data?.family_member_high_cholesterol && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_high_cholesterol}
          />
        )}
        <FullRowLabelledData
          label={messages.highBloodPressure.defaultMessage}
          value={data.high_blood_pressure}
        />
        {!!data?.family_member_high_blood_pressure && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_high_blood_pressure}
          />
        )}
        <FullRowLabelledData
          label={messages.headTrauma.defaultMessage}
          value={data.head_trauma}
        />
        {!!data?.family_member_head_trauma && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_head_trauma}
          />
        )}
        <FullRowLabelledData
          label={messages.liverProblems.defaultMessage}
          value={data.liver_problems}
        />
        {!!data?.family_member_liver_problems && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberWithAffectionsDependentFields.defaultMessage
            }
            value={data.family_member_liver_problems}
          />
        )}
        <FullRowLabelledData
          label={messages.additionalMedicalHistory.defaultMessage}
          value={data.additional_medical_history}
        />
        {!!data?.additional_medical_history_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.additionalMedicalHistoryDependentFields.defaultMessage
            }
            value={data.additional_medical_history_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.motherPregnancyComplications.defaultMessage}
          value={data.mother_pregnancy_complications}
        />
        {/* Page 5 */}
        <TitleSection
          title={messages.pastPsychiatricHistoryTitle.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.outpatientTreatment.defaultMessage}
          value={data.outpatient_treatment}
        />
        {!!data?.outpatient_treatment_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.outpatientTreatmentDependentFields.defaultMessage}
            value={data.outpatient_treatment_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.psychiatricHospitalization.defaultMessage}
          value={data.psychiatric_hospitalization}
        />
        {!!data?.psychiatric_hospitalization_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.psychiatricHospitalizationDependentFields.defaultMessage
            }
            value={data.psychiatric_hospitalization_free_text}
          />
        )}
        <TitleSection
          title={messages.pastPsychiatricHistorySectionTitle.defaultMessage}
        />
        <SubtitleSection
          title={messages.pastPsychiatricHistorySectionSubTitle.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.antiDepressantsTextDelimiter.defaultMessage}
        />
        <FullRowLabelledData value={data.anafranil} />
        {!!data?.anafranildependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.anafranildependentFields}
          />
        )}
        <FullRowLabelledData value={data.celexa} />
        {!!data?.celexadependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.celexadependentFields}
          />
        )}
        <FullRowLabelledData value={data.cymbalta} />
        {!!data?.psychiatric_hospitalization_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.cymbaltadependentFields}
          />
        )}
        <FullRowLabelledData value={data.effexor} />
        {!!data?.effexordependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.effexordependentFields}
          />
        )}
        <FullRowLabelledData value={data.elavil} />
        {!!data?.elavildependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.elavildependentFields}
          />
        )}
        <FullRowLabelledData value={data.lexapro} />
        {!!data?.lexaprodependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.lexaprodependentFields}
          />
        )}
        <FullRowLabelledData value={data.luvox_paxil} />
        {!!data?.luvox_paxildependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.luvox_paxildependentFields}
          />
        )}
        <FullRowLabelledData value={data.pamelor} />
        {!!data?.pamelordependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.pamelordependentFields}
          />
        )}
        <FullRowLabelledData value={data.prozac} />
        {!!data?.prozacdependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.prozacdependentFields}
          />
        )}
        <FullRowLabelledData value={data.remeron} />
        {!!data?.remerondependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.remerondependentFields}
          />
        )}
        <FullRowLabelledData value={data.serzone} />
        {!!data?.serzonedependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.serzonedependentFields}
          />
        )}
        <FullRowLabelledData value={data.tofranil} />
        {!!data?.tofranildependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.tofranildependentFields}
          />
        )}
        <FullRowLabelledData value={data.wellbutrin} />
        {!!data?.wellbutrindependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.wellbutrindependentFields}
          />
        )}
        <FullRowLabelledData
          label={messages.antipsychoticsTextDelimiter.defaultMessage}
        />
        <FullRowLabelledData value={data.abilify} />
        {!!data?.abilifydependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.abilifydependentFields}
          />
        )}
        <FullRowLabelledData value={data.clozaril} />
        {!!data?.clozarildependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.clozarildependentFields}
          />
        )}
        <FullRowLabelledData value={data.depakote} />
        {!!data?.depakotedependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.depakotedependentFields}
          />
        )}
        <FullRowLabelledData value={data.geodon} />
        {!!data?.geodondependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.geodondependentFields}
          />
        )}
        <FullRowLabelledData value={data.Haldol} />
        {!!data?.HaldoldependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.HaldoldependentFields}
          />
        )}
        <FullRowLabelledData value={data.lamictal} />
        {!!data?.lamictaldependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.lamictaldependentFields}
          />
        )}
        <FullRowLabelledData value={data.lithium} />
        {!!data?.lithiumdependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.lithiumdependentFields}
          />
        )}
        <FullRowLabelledData value={data.prolixin} />
        {!!data?.prolixindependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.prolixindependentFields}
          />
        )}
        <FullRowLabelledData value={data.risperdal} />
        {!!data?.risperdaldependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.risperdaldependentFields}
          />
        )}
        <FullRowLabelledData value={data.tegretol} />
        {!!data?.tegretoldependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.tegretoldependentFields}
          />
        )}
        <FullRowLabelledData value={data.topamax} />
        {!!data?.topamaxdependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.topamaxdependentFields}
          />
        )}
        <FullRowLabelledData value={data.zyprexa} />
        {!!data?.zyprexadependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.zyprexadependentFields}
          />
        )}
        <FullRowLabelledData
          label={messages.sedativesHyptnoticsTextDelimiter.defaultMessage}
        />
        <FullRowLabelledData value={data.ambien} />
        {!!data?.ambiendependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.ambiendependentFields}
          />
        )}
        <FullRowLabelledData value={data.desyrel} />
        {!!data?.desyreldependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.desyreldependentFields}
          />
        )}
        <FullRowLabelledData value={data.sonata} />
        {!!data?.sonatadependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.sonatadependentFields}
          />
        )}
        <FullRowLabelledData value={data.restoril} />
        {!!data?.restorildependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.restorildependentFields}
          />
        )}
        <FullRowLabelledData value={data.rozerem} />
        {!!data?.rozeremdependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.rozeremdependentFields}
          />
        )}
        <FullRowLabelledData
          label={messages.ADHDMedicationsTextDelimiter.defaultMessage}
        />
        <FullRowLabelledData value={data.adderall} />
        {!!data?.adderalldependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.adderalldependentFields}
          />
        )}
        <FullRowLabelledData value={data.concerta} />
        {!!data?.concertadependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.concertadependentFields}
          />
        )}
        <FullRowLabelledData value={data.ritalin} />
        {!!data?.ritalindependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.ritalindependentFields}
          />
        )}
        <FullRowLabelledData value={data.strattera} />
        {!!data?.stratteradependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.stratteradependentFields}
          />
        )}
        <FullRowLabelledData
          label={messages.antianxietyMedicationsTextDelimiter.defaultMessage}
        />
        <FullRowLabelledData value={data.ativan} />
        {!!data?.ativandependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.ativandependentFields}
          />
        )}
        <FullRowLabelledData value={data.buspar} />
        {!!data?.buspardependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.buspardependentFields}
          />
        )}
        <FullRowLabelledData value={data.klonopin} />
        {!!data?.klonopindependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.klonopindependentFields}
          />
        )}
        <FullRowLabelledData value={data.tranxene} />
        {!!data?.tranxenedependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.tranxenedependentFields}
          />
        )}
        <FullRowLabelledData value={data.valium} />
        {!!data?.valiumdependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.valiumdependentFields}
          />
        )}
        <FullRowLabelledData value={data.xanax} />
        {!!data?.xanaxdependentFields && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.antiDepressantsdependentFields.defaultMessage}
            value={data.xanaxdependentFields}
          />
        )}
        <FullRowLabelledData
          label={messages.otherPsychiatricMedications.defaultMessage}
          value={data.other_psychiatric_medications}
        />
        {/* Page 6 */}
        <TitleSection
          title={messages.exerciseLevelSectionTitle.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.regularlyExercise.defaultMessage}
          value={data.regularly_exercise}
        />
        <FullRowLabelledData
          label={messages.exerciseDaysPerWeek.defaultMessage}
          value={data.exercise_days_per_week}
        />
        <FullRowLabelledData
          label={messages.exerciseTimePerDay.defaultMessage}
          value={data.exercise_time_per_day}
        />
        <FullRowLabelledData
          label={messages.exerciseType.defaultMessage}
          value={data.exercise_type}
        />
        <TitleSection
          title={messages.familyPsychiatricHistorySectionTitle.defaultMessage}
        />
        <SubtitleSection
          title={
            messages.familyPsychiatricHistorySubSectionTitle.defaultMessage
          }
        />
        <FullRowLabelledData
          label={messages.alcoholAbuse.defaultMessage}
          value={data.alcohol_abuse}
        />
        {!!data?.alcohol_abuse_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberSubstanceAbusedependentFields.defaultMessage
            }
            value={data.alcohol_abuse_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.anger.defaultMessage}
          value={data.anger}
        />
        {!!data?.anger_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberSubstanceAbusedependentFields.defaultMessage
            }
            value={data.anger_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.anxiety.defaultMessage}
          value={data.anxiety}
        />
        {!!data?.anxiety_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberSubstanceAbusedependentFields.defaultMessage
            }
            value={data.anxiety_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.bipolarDisorder.defaultMessage}
          value={data.bipolar_disorder}
        />
        {!!data?.bipolar_disorder_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberSubstanceAbusedependentFields.defaultMessage
            }
            value={data.bipolar_disorder_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.depression.defaultMessage}
          value={data.depression}
        />
        {!!data?.depression_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberSubstanceAbusedependentFields.defaultMessage
            }
            value={data.depression_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.otherSubstanceAbuse.defaultMessage}
          value={data.other_substance_abuse}
        />
        {!!data?.other_substance_abuse_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberSubstanceAbusedependentFields.defaultMessage
            }
            value={data.other_substance_abuse_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.postTraumaticStress.defaultMessage}
          value={data.post_traumatic_stress}
        />
        {!!data?.post_traumatic_stress_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberSubstanceAbusedependentFields.defaultMessage
            }
            value={data.post_traumatic_stress_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.schizophrenia.defaultMessage}
          value={data.schizophrenia}
        />
        {!!data?.schizophrenia_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberSubstanceAbusedependentFields.defaultMessage
            }
            value={data.schizophrenia_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.suicide.defaultMessage}
          value={data.suicide}
        />
        {!!data?.suicide_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberSubstanceAbusedependentFields.defaultMessage
            }
            value={data.suicide_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.violence.defaultMessage}
          value={data.violence}
        />
        {!!data?.violence_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberSubstanceAbusedependentFields.defaultMessage
            }
            value={data.violence_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.familyMemberPsychiatricMedication.defaultMessage}
          value={data.family_member_psychiatric_medication}
        />
        {!!data?.family_member_psychiatric_medication_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.familyMemberSubstanceAbusedependentFields.defaultMessage
            }
            value={data.family_member_psychiatric_medication_free_text}
          />
        )}
        <TitleSection
          title={messages.substanceUseSectionTitle.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.alcoholDrugAbuseTreated.defaultMessage}
          value={data.alcohol_drug_abuse_treated}
        />
        {!!data?.alcohol_drug_abuse_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.alcoholDrugAbusedependentFields.defaultMessage}
            value={data.alcohol_drug_abuse_free_text}
          />
        )}
        {!!data?.alcohol_drug_abuse_treated_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.alcoholDrugAbuseTreateddependentFields.defaultMessage
            }
            value={data.alcohol_drug_abuse_treated_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.alcoholDaysPerWeek.defaultMessage}
          value={data.alcohol_days_per_week}
        />
        <FullRowLabelledData
          label={messages.minDrinksInADay.defaultMessage}
          value={data.min_drinks_in_a_day}
        />
        <FullRowLabelledData
          label={messages.maxDrinksInADay.defaultMessage}
          value={data.max_drinks_in_a_day}
        />
        <FullRowLabelledData
          label={messages.maxDrinksInThreeMonths.defaultMessage}
          value={data.max_drinks_in_three_months}
        />
        <FullRowLabelledData
          label={messages.substanceCutDownPlan.defaultMessage}
          value={data.substance_cut_down_plan}
        />
        <FullRowLabelledData
          label={messages.annoyedByPeopleCriticism.defaultMessage}
          value={data.annoyed_by_people_criticism}
        />
        <FullRowLabelledData
          label={messages.drinkingRemorse.defaultMessage}
          value={data.drinking_remorse}
        />
        <FullRowLabelledData
          label={messages.drinkingAsHangoverCure.defaultMessage}
          value={data.drinking_as_hangover_cure}
        />
        <FullRowLabelledData
          label={messages.acknowledgeDrinkingProblem.defaultMessage}
          value={data.acknowledge_drinking_problem}
        />
        <FullRowLabelledData
          label={messages.streetDrugsPastThreeMonths.defaultMessage}
          value={data.street_drugs_past_three_months}
        />
        {!!data?.street_drugs_past_three_months_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.streetDrugsPastThreeMonthsdependentFields.defaultMessage
            }
            value={data.street_drugs_past_three_months_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.prescribedMedicationAbuse.defaultMessage}
          value={data.prescribed_medication_abuse}
        />
        {!!data?.prescribed_medication_abuse_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.prescribedMedicationAbusedependentFields.defaultMessage
            }
            value={data.prescribed_medication_abuse_free_text}
          />
        )}
        <TitleSection
          title={messages.substancesTriedSectionTitle.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.triedAlcohol.defaultMessage}
          value={data.tried_alcohol}
        />
        {!!data?.tried_alcohol_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.substancesTrieddependentFields.defaultMessage}
            value={data.tried_alcohol_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.triedCocaine.defaultMessage}
          value={data.tried_cocaine}
        />
        {!!data?.tried_cocaine_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.substancesTrieddependentFields.defaultMessage}
            value={data.tried_cocaine_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.triedEcstasy.defaultMessage}
          value={data.tried_ecstasy}
        />
        {!!data?.tried_ecstasy_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.substancesTrieddependentFields.defaultMessage}
            value={data.tried_ecstasy_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.triedHeroin.defaultMessage}
          value={data.tried_heroin}
        />
        {!!data?.tried_heroin_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.substancesTrieddependentFields.defaultMessage}
            value={data.tried_heroin_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.triedLSDHallucinogens.defaultMessage}
          value={data.tried_lsd_hallucinogens}
        />
        {!!data?.tried_lsd_hallucinogens_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.substancesTrieddependentFields.defaultMessage}
            value={data.tried_lsd_hallucinogens_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.triedMarijuana.defaultMessage}
          value={data.tried_marijuana}
        />
        {!!data?.tried_marijuana_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.substancesTrieddependentFields.defaultMessage}
            value={data.tried_marijuana_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.triedMethadone.defaultMessage}
          value={data.tried_methadone}
        />
        {!!data?.tried_methadone_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.substancesTrieddependentFields.defaultMessage}
            value={data.tried_methadone_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.triedMethamphetamine.defaultMessage}
          value={data.tried_methamphetamine}
        />
        {!!data?.tried_methamphetamine_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.substancesTrieddependentFields.defaultMessage}
            value={data.tried_methamphetamine_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.triedPainKillers.defaultMessage}
          value={data.tried_painKillers}
        />
        {!!data?.tried_pain_killers_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.substancesTrieddependentFields.defaultMessage}
            value={data.tried_pain_killers_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.triedStimulants.defaultMessage}
          value={data.tried_stimulants}
        />
        {!!data?.tried_stimulants_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.substancesTrieddependentFields.defaultMessage}
            value={data.tried_stimulants_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.triedTranquilizerSleepingPills.defaultMessage}
          value={data.tried_tranquilizer_sleeping_pills}
        />
        {!!data?.tried_stimulants_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.substancesTrieddependentFields.defaultMessage}
            value={data.tried_tranquilizer_sleeping_pills_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.otherSubstancesTried.defaultMessage}
          value={data.other_substances_tried}
        />
        <SubtitleSection
          title={messages.caffeinatedBeveragesPerDayTitle.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.coffeePerDay.defaultMessage}
          value={data.coffee_per_day}
        />
        <FullRowLabelledData
          label={messages.sodasPerDay.defaultMessage}
          value={data.sodas_per_day}
        />
        <FullRowLabelledData
          label={messages.teaPerDay.defaultMessage}
          value={data.tea_per_day}
        />
        <SubtitleSection
          title={messages.tobaccoHistorySectionTitle.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.smokedCigarettes.defaultMessage}
          value={data.smoked_cigarettes}
        />
        {!!data?.currently_smoking && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.currentlySmoking.defaultMessage}
            value={data.currently_smoking}
          />
        )}
        {!!data?.years_of_smoking_and_daily_packs && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.yearsOfSmokingAndDailyPacks.defaultMessage}
            value={data.years_of_smoking_and_daily_packs}
          />
        )}
        <FullRowLabelledData
          label={messages.smokedBefore.defaultMessage}
          value={data.smoked_before}
        />
        {!!data?.smoked_before_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.yearsOfSmokingAndQuittingReason.defaultMessage}
            value={data.smoked_before_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.pipeUse.defaultMessage}
          value={data.pipe_use}
        />
        {!!data?.pipe_use_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.pipeUsedependentFields.defaultMessage}
            value={data.pipe_use_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.everUsedPipe.defaultMessage}
          value={data.ever_used_pipe}
        />
        {!!data?.ever_used_pipe_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.everUsedPipedependentFields.defaultMessage}
            value={data.ever_used_pipe_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.yearsOfSmoking.defaultMessage}
          value={data.years_of_smoking}
        />
        {/* Page 7 */}
        <TitleSection
          title={messages.familyBackgroundSectionTitle.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.adopted.defaultMessage}
          value={data.adopted}
        />
        <FullRowLabelledData
          label={messages.growUpPlace.defaultMessage}
          value={data.grow_up_place}
        />
        <FullRowLabelledData
          label={messages.siblingsInfo.defaultMessage}
          value={data.siblings_info}
        />
        <FullRowLabelledData
          label={messages.fatherOccupation.defaultMessage}
          value={data.father_occupation}
        />
        <FullRowLabelledData
          label={messages.motherOccupation.defaultMessage}
          value={data.mother_occupation}
        />
        <FullRowLabelledData
          label={messages.divorcedParents.defaultMessage}
          value={data.divorced_parents}
        />
        {!!data?.divorced_parents_at_age && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.divorcedParentsAtAge.defaultMessage}
            value={data.divorced_parents_at_age}
          />
        )}
        {!!data?.person_living_with_on_parents_divorce && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.personLivingWithOnParentsDivorce.defaultMessage}
            value={data.person_living_with_on_parents_divorce}
          />
        )}
        <FullRowLabelledData
          label={messages.fatherRelationship.defaultMessage}
          value={data.father_relationship}
        />
        <FullRowLabelledData
          label={messages.motherRelationship.defaultMessage}
          value={data.mother_relationship}
        />
        <FullRowLabelledData
          label={messages.leftHomeAtAge.defaultMessage}
          value={data.left_home_at_age}
        />
        <FullRowLabelledData
          label={messages.immediateFamilyDeaths.defaultMessage}
          value={data.immediate_family_deaths}
        />
        {!!data?.divorced_parents_at_age && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.immediateFamilyDeathsInfo.defaultMessage}
            value={data.divorced_parents_at_age}
          />
        )}
        <TitleSection
          title={messages.traumaHistorySectionTitle.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.immediateFamilyDeaths.defaultMessage}
          value={data.abuse_history}
        />
        {!!data?.abuse_history_info && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.abuseHistoryInfo.defaultMessage}
            value={data.abuse_history_info}
          />
        )}
        {/* Page 8 */}
        <TitleSection
          title={messages.educationalHistorySectionTitle.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.highestGradeCompleted.defaultMessage}
          value={data.highest_grade_completed}
        />
        <FullRowLabelledData
          label={messages.highestGradeCompletedLocation.defaultMessage}
          value={data.highest_grade_completed_location}
        />
        <FullRowLabelledData
          label={messages.hasAttendedCollege.defaultMessage}
          value={data.has_attended_college}
        />
        {!!data?.attended_college_info && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.attendedCollegeInfo.defaultMessage}
            value={data.attended_college_info}
          />
        )}
        <FullRowLabelledData
          label={messages.highestEducationAttained.defaultMessage}
          value={data.highest_education_attained}
        />
        <TitleSection
          title={messages.occupationalHistoryTitle.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.currentOccupation.defaultMessage}
          value={data.current_occupation}
        />
        <FullRowLabelledData
          label={messages.presentPositionLength.defaultMessage}
          value={data.present_position_length}
        />
        <FullRowLabelledData
          label={messages.occupation.defaultMessage}
          value={data.occupation}
        />
        <FullRowLabelledData
          label={messages.workingLocation.defaultMessage}
          value={data.working_location}
        />
        <FullRowLabelledData
          label={messages.militaryService.defaultMessage}
          value={data.military_service}
        />
        {!!data?.military_service_info && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.militaryServiceInfo.defaultMessage}
            value={data.military_service_info}
          />
        )}
        <FullRowLabelledData
          label={messages.honorableDischarge.defaultMessage}
          value={data.honorable_discharge}
        />
        {!!data?.honorable_discharge_info && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.honorableDischargeInfo.defaultMessage}
            value={data.honorable_discharge_info}
          />
        )}
        <TitleSection
          title={messages.relationshipHistorySectionTitle.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.relationshipStatus.defaultMessage}
          value={data.relationship_status}
        />
        <FullRowLabelledData
          label={messages.relationshipStatusDuration.defaultMessage}
          value={data.relationship_status_duration}
        />
        <FullRowLabelledData
          label={messages.currentlyInRelationship.defaultMessage}
          value={data.currently_in_relationship}
        />
        {!!data?.current_relationship_duration && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.currentRelationshipDuration.defaultMessage}
            value={data.current_relationship_duration}
          />
        )}
        <FullRowLabelledData
          label={messages.sexuallyActive.defaultMessage}
          value={data.sexually_active}
        />
        <FullRowLabelledData
          label={messages.sexualOrientation.defaultMessage}
          value={data.sexual_orientation}
        />
        <FullRowLabelledData
          label={messages.spouseOccupation.defaultMessage}
          value={data.spouse_occupation}
        />
        <FullRowLabelledData
          label={messages.spouseRelationShipDescription.defaultMessage}
          value={data.spouse_relationship_description}
        />
        <FullRowLabelledData
          label={messages.previousMarriages.defaultMessage}
          value={data.previous_marriages}
        />
        {!!data?.previous_marriages_number && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.previousMarriagesNumber.defaultMessage}
            value={data.previous_marriages_number}
          />
        )}
        {!!data?.previous_marriages_duration && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.previousMarriagesDuration.defaultMessage}
            value={data.previous_marriages_duration}
          />
        )}
        <FullRowLabelledData
          label={messages.hasChildren.defaultMessage}
          value={data.has_children}
        />
        {!!data?.children_info && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.childrenInfo.defaultMessage}
            value={data.children_info}
          />
        )}
        {!!data?.children_relationship_description && (
          <FullRowLabelledData
            isPrimary={false}
            label={messages.childrenRelationshipDescription.defaultMessage}
            value={data.children_relationship_description}
          />
        )}
        <FullRowLabelledData
          label={messages.othersCoHabitants.defaultMessage}
          value={data.others_cohabitants}
        />
        {/* Page 9 */}
        <TitleSection
          title={messages.legalHistorySectionTitle.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.beenArrested.defaultMessage}
          value={data.been_arrested}
        />
        <FullRowLabelledData
          label={messages.pendingLegalProblems.defaultMessage}
          value={data.pending_legal_problems}
        />
        <TitleSection
          title={messages.spiritualLifeSectionTitle.defaultMessage}
        />
        <FullRowLabelledData
          label={messages.belongsToReligionGroup.defaultMessage}
          value={data.belongs_to_religion_group}
        />
        {!!data?.belongs_to_religion_group_free_text && (
          <FullRowLabelledData
            isPrimary={false}
            label={
              messages.belongsToReligionGroupdependentFields.defaultMessage
            }
            value={data.belongs_to_religion_group_free_text}
          />
        )}
        <FullRowLabelledData
          label={messages.religionGroupInvolvementResult.defaultMessage}
          value={data.religion_group_involvement_result}
        />
        <FullRowLabelledData
          label={messages.otherMentions.defaultMessage}
          value={data.other_mentions}
        />
        <FullRowLabelledData
          label={messages.emergencyContact.defaultMessage}
          value={data.emergency_contact}
        />
        <FullRowLabelledData
          label={messages.telephoneNumber.defaultMessage}
          value={data.telephone_number}
        />
      </>
    </LayoutPDF>
  );
};

export default WildHeartIntakePDF;
