import PropTypes from 'prop-types';
import React from 'react';
import Label from '../../Label';
import styles from './TextAreaInputWithLabel.module.scss';

const TextAreaInputWithLabel = ({
  rows,
  label,
  value,
  onChange,
  errorText,
  name,
  placeholder,
  disabled,
  onBlur,
  maxLength = 10000,
  'data-testid': datatestid,
  style,
}) => (
  <div>
    <Label errorText={errorText} dataTestId={datatestid} htmlFor={name}>
      {label}
    </Label>
    <textarea
      maxLength={maxLength}
      style={style}
      rows={rows}
      value={value}
      name={name}
      onChange={onChange}
      className={styles.textarea}
      placeholder={placeholder}
      disabled={disabled}
      onBlur={onBlur}
      data-testid={datatestid}
    />
  </div>
);

TextAreaInputWithLabel.propTypes = {
  rows: PropTypes.number,
  label: PropTypes.string,
  errorText: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

TextAreaInputWithLabel.defaultProps = {
  rows: 4,
  disabled: false,
};

export default TextAreaInputWithLabel;
