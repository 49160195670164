import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../Label';
import Autocomplete from 'react-autocomplete';
import styles from './AutoComplete.module.scss';
import colors from '../../../../styles/_colors.scss';

const AutoComplete = ({
  label,
  items,
  name,
  onChange,
  value,
  placeholder,
  'data-testid': datatestid,
}) => {
  const handleOnChange = (e) => {
    onChange(e.target.value);
  };
  const handleOnSelect = (v) => {
    onChange(v);
  };

  const itemStyles = (highlighted) => {
    return {
      backgroundColor: highlighted ? colors.black : colors.white,
      color: highlighted ? colors.white : colors.black,
      paddingLeft: '0.5rem',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      lineHieght: '1.5rem',
      '&:hover': {
        color: colors.black,
      },
      fontFamily: 'Barlow, sans-serif',
      fontSize: '13px',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '402px',
      width: '100%',
    };
  };

  return (
    <div>
      {label && (
        <Label htmlFor={name} dataTestId={datatestid}>
          {label}
        </Label>
      )}
      <div className={styles.container} data-testid={datatestid}>
        <Autocomplete
          items={items}
          name={name}
          shouldItemRender={(item, v) =>
            item.label.toLowerCase().indexOf(v.toLowerCase()) > -1
          }
          getItemValue={(item) => item.label}
          renderItem={(item, highlighted) => (
            <div
              key={item.label}
              style={itemStyles(highlighted)}
              className={styles.item}
            >
              {item.label}
            </div>
          )}
          value={value}
          onChange={handleOnChange}
          onSelect={handleOnSelect}
          wrapperStyle={{ position: 'relative' }}
          menuStyle={{
            position: 'absolute',
            top: '60px',
            left: 0,
            zIndex: '999999',
          }}
          inputProps={{
            placeholder,
          }}
        />
      </div>
    </div>
  );
};

AutoComplete.propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

export default AutoComplete;
