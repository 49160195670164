import React, { useContext } from 'react';

import styles from './Logo.module.scss';
import EveLogo from '../../img/logo.svg';

import WhitelabelContext from '../../context/WhitelabelContext';

const Logo = () => {
  const { whitelabel } = useContext(WhitelabelContext);

  return (
    <div className={styles.logo}>
      {whitelabel && whitelabel.logo_url ? (
        <img
          src={whitelabel.logo_url}
          alt="logo"
          data-testid="white-labelled-logo"
        />
      ) : (
        <img
          className={styles.evelogo}
          src={EveLogo}
          alt="Eve"
          data-testid="eve-logo"
        />
      )}
    </div>
  );
};

export default Logo;
