import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { IntlProvider } from 'react-intl';
import 'foundation-sites/dist/css/foundation.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import translations from './i18n/locales';

const {
  REACT_APP_BUGSNAG_API_KEY,
  REACT_APP_ENV,
  REACT_APP_BUGSNAG_RELEASE_STAGE,
} = process.env;

const language = 'en';
const messages = translations[language];

if (REACT_APP_BUGSNAG_API_KEY && REACT_APP_ENV !== 'local') {
  var package_json = require('../package.json');
  Bugsnag.start({
    apiKey: REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    appVersion: package_json.version,
    releaseStage: REACT_APP_BUGSNAG_RELEASE_STAGE,
  });

  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
  ReactDOM.render(
    <IntlProvider locale={language} key={language} messages={messages}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </IntlProvider>,
    document.getElementById('root'),
  );
} else {
  ReactDOM.render(
    <IntlProvider locale={language} key={language} messages={messages}>
      <App />
    </IntlProvider>,
    document.getElementById('root'),
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
