import React, { useState, useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Cell } from 'react-foundation';

import styles from './DoctorLeftNav.module.scss';

import Modal from '../../common/Modal';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import SecondaryButton from '../../common/buttons/SecondaryButton';
import FieldDropdown from '../../common/fields/FieldDropdown';
import Loading from '../../../components/common/Loading';
import BillingPlanFeature from '../../../components/BillingPlanFeature';

import { getPatientList, getFactMatchedDoctors } from '../../../api/doctor';

import { compareFun, addToDataLayer } from '../../../utils/common';
import { PATIENT_DETAILS_STATUS } from '../../../constants';
import useIntl from '../../../hooks/useIntl';
import AuthContext from '../../../context/AuthContext';

import messages from './messages';

const DoctorLeftNav = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const { authUser } = useContext(AuthContext);

  const [showBookAppointment, setShowBookAppointment] = useState(false);
  const [error, setError] = useState('');
  const [patientId, setPatientId] = useState('');
  const [clinicianId, setClinicianId] = useState('');
  const [patientList, setPatientList] = useState([]);
  const [clinicianList, setClinicianList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  if (authUser.id && window.UserLeap) {
    window.UserLeap('setUserId', authUser.id);
  }

  const fetchPatients = async () => {
    setIsLoading(true);
    const params = {
      doctor_id: authUser.id,
      clinic_id: authUser.clinic_id,
    };
    let { patientList } = await getPatientList(params);
    let patients = patientList
      .map((patients) => patients.patient)
      .filter((x) => x.status === PATIENT_DETAILS_STATUS.ACTIVE);

    if (patients.length > 0) {
      // sort patients by alphbetical order
      patients = patients.sort((a, b) => {
        return compareFun(
          a.first_name && a.first_name.toLowerCase(),
          b.first_name && b.first_name.toLowerCase(),
          'Asc',
        );
      });
    }

    if (authUser.clinic_id !== 0) {
      const doctorParams = {
        clinic_id: authUser.clinic_id || 0,
        filter: 'clinic',
      };
      let { doctors } = await getFactMatchedDoctors(doctorParams);

      if (doctors.length > 0) {
        // sort doctors by alphbetical order
        doctors = doctors.sort((a, b) => {
          return compareFun(
            a.first_name.toLowerCase(),
            b.first_name.toLowerCase(),
            'Asc',
          );
        });
      }
      const formattedClinicianList = doctors.map((doctor) => {
        return {
          label: `${doctor.first_name} ${
            doctor.last_name ? doctor.last_name : ''
          }`,
          value: `${doctor.id}`,
        };
      });
      setClinicianList(formattedClinicianList);
    }

    const formattedPatientList = patients.map((patient) => {
      return {
        label: `${patient.first_name} ${
          patient.last_name ? patient.last_name : ''
        }`,
        value: `${patient.id}`,
      };
    });
    setIsLoading(false);
    setPatientList(formattedPatientList);
  };

  const openAppointment = async () => {
    await fetchPatients();
    const eventData = {
      event: 'ga4_event',
      properties: {
        event_name: 'book_appointment',
        step_name: 'book_appointment_popup',
        step_number: 1,
        clinician_id: authUser.id || '',
      },
    };
    addToDataLayer(eventData);

    /**
     * set current doctor by default
     */
    setClinicianId(authUser?.id?.toString());

    setShowBookAppointment(true);
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div className={styles.title} data-testid="doctor username label">
            Hi {authUser.first_name}
          </div>
          <ul className={styles.menu}>
            <BillingPlanFeature>
              <li>
                <NavLink
                  exact
                  to="/doctor/my-account"
                  activeClassName={styles.active}
                >
                  <span data-testid="Dashboard tab">Dashboard</span>
                </NavLink>
              </li>
              <BillingPlanFeature appointments>
                <li>
                  <NavLink
                    to="/doctor/appointments"
                    activeClassName={styles.active}
                  >
                    <span data-testid="appointments tab">Appointments</span>
                  </NavLink>
                </li>
                <li>
                  <div className={styles.bookBtn}>
                    <button onClick={openAppointment}>
                      <span data-testid="book new appointment">
                        Book New Appointment
                      </span>
                    </button>
                  </div>
                </li>
              </BillingPlanFeature>
              <li>
                <NavLink to="/doctor/patients" activeClassName={styles.active}>
                  <span data-testid="Patients tab">Patients</span>
                </NavLink>
              </li>
              {process.env.REACT_APP_MANAGE_FORM_FUNCTIONALITY === 'true' && (
                <li>
                  <NavLink
                    to="/doctor/manage-forms"
                    activeClassName={styles.active}
                  >
                    <span data-testid="Manage Forms tab">Manage Forms</span>
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  to="/doctor/invite-patient"
                  activeClassName={styles.active}
                >
                  <span data-testid="Invite Patient tab">Invite Patient</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/doctor/invite-clinician"
                  activeClassName={styles.active}
                >
                  <span data-testid="Invite Clinician tab">
                    Invite Clinician
                  </span>
                </NavLink>
              </li>
              {authUser && authUser.clinics && authUser.clinics.id && (
                <li>
                  <NavLink to="/doctor/team" activeClassName={styles.active}>
                    <span data-testid="Team tab">Team</span>
                  </NavLink>
                </li>
              )}
              {authUser && authUser.clinics && authUser.clinics.id && (
                <li>
                  <NavLink to="/doctor/clinic" activeClassName={styles.active}>
                    <span data-testid="My Clinic tab">My Clinic</span>
                  </NavLink>
                </li>
              )}
            </BillingPlanFeature>
            <li>
              <NavLink to="/doctor/billing" activeClassName={styles.active}>
                <span data-testid="Billing tab">Billing</span>
              </NavLink>
            </li>
            <li>
              <a>
                <span>Give Feedback</span>
              </a>
            </li>
          </ul>

          <BillingPlanFeature appointments>
            {showBookAppointment && (
              <Modal className={styles.bookAppointmentModal}>
                <div className={styles.content}>
                  <div
                    className={styles.closeButton}
                    onClick={() => {
                      setShowBookAppointment(false);
                      setError('');
                      setPatientId('');
                      setClinicianId('');
                    }}
                    data-testid="close button"
                  >
                    <img
                      src={require(`../../../img/close.svg`)}
                      alt={`Close`}
                      data-testid="close button"
                    />
                  </div>
                  <h1>
                    <b data-testid="modal header">Appointment With?</b>
                  </h1>

                  <div className={styles.box}>
                    <p data-testid="sub header">
                      {' '}
                      Please select patient to book appointment with
                    </p>
                  </div>

                  {patientList.length > 0 && (
                    <Cell
                      small={12}
                      medium={12}
                      className={styles['label-bold']}
                    >
                      <FieldDropdown
                        label=""
                        name="patient"
                        errorText={error}
                        value={patientId}
                        onChange={(e) => {
                          setError('');
                          setPatientId(e.target.value);
                          const eventData = {
                            event: 'ga4_event',
                            properties: {
                              event_name: 'book_appointment',
                              step_name: 'select_patient',
                              step_number: 2,
                              clinician_id: authUser.id || '',
                              patient_id: e.target.value || '',
                            },
                          };
                          addToDataLayer(eventData);
                        }}
                        options={patientList}
                        placeholder="Select Patient"
                        dataTestId="BookAppointment.Doctor.SelectPatient"
                        isSearchable={true}
                      />
                    </Cell>
                  )}

                  {clinicianList.length > 0 && (
                    <>
                      <div className={styles.box}>
                        <p data-testid="add a clinician">Add a clinician </p>
                      </div>

                      <Cell
                        small={12}
                        medium={12}
                        className={styles['label-bold']}
                      >
                        <FieldDropdown
                          label=""
                          name="doctor"
                          value={clinicianId}
                          onChange={(e) => {
                            setError('');
                            setClinicianId(e.target.value);
                          }}
                          options={clinicianList}
                          placeholder="Select Clinician"
                          dataTestId="BookAppointment.Doctor.SelectClinician"
                          isSearchable={true}
                        />
                      </Cell>
                    </>
                  )}

                  {patientList.length > 0 ? (
                    <>
                      <PrimaryButton
                        label="Continue Appointment"
                        className={styles.primaryButton}
                        onClick={() => {
                          if (patientId) {
                            const eventData = {
                              event: 'ga4_event',
                              properties: {
                                event_name: 'book_appointment',
                                step_name: 'continue_appointment',
                                step_number: 3,
                                patient_id: patientId || '',
                                clinician_id: clinicianId,
                              },
                            };
                            addToDataLayer(eventData);

                            history.push(
                              `/doctor/appointment/${patientId}/clinician/${clinicianId}`,
                            );
                          } else {
                            setError('Please Select Patient');
                          }
                        }}
                        data-testid="continue appointment"
                      />
                      <SecondaryButton
                        label={formatMessage(
                          messages.resetModalButtonSecondary,
                        )}
                        className={styles.secondaryButton}
                        onClick={() => {
                          setShowBookAppointment(false);
                          setError('');
                          setPatientId('');
                          setClinicianId('');
                        }}
                        data-testid="cancel button"
                      />
                    </>
                  ) : (
                    <div className={styles.notInvited}>
                      <p data-testid="no patient found">No Patients Found!!</p>
                    </div>
                  )}
                </div>
              </Modal>
            )}
          </BillingPlanFeature>
        </div>
      )}
    </div>
  );
};

export default DoctorLeftNav;
