import React, { useState, Fragment, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment';
import Loading from '../../components/common/Loading';
import LeftNav from '../../components/nav/LeftNav';
import styles from './DashboardPage.module.scss';
import StepCard from '../../components/StepCard';
import RecommendPsychotherapists from '../../components/RecommendPsychotherapists';
import AuthContext from '../../context/AuthContext';
import useIntl from '../../hooks/useIntl';
import messages from './messages';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import SecondaryButton from '../../components/common/buttons/SecondaryButton';
import Modal from '../../components/common/Modal';
import MarketingCopy from '../../components/MarketingCopy';
import { getUserProfile } from '../../api/user';
import { addToDataLayer } from '../../utils/common';
import { getSuperBillRequests, getFactMatchedDoctors } from '../../api/doctor';
import { parseSuperBillRequests } from '../../utils/common';
import SuperBillCard from '../doctor/SuperBillCard';

const DashboardPage = () => {
  const history = useHistory();
  const { isLoading, authUser } = useContext(AuthContext);
  const { formatMessage } = useIntl();
  const [dataLoading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const isDataLoading = dataLoading || isLoading;
  const [psychotherapists, setPsychotherapists] = useState([]);
  const [showResetRecommendationModal, setShowResetRecommendationModal] =
    useState(false);
  const [showIntakeFormConfirmation, setShowIntakeFormConfirmation] =
    useState(false);
  const [selectedDoc, setSelectedDoc] = useState({});
  const [limit] = useState(5);
  const [skip, setSkip] = useState(0);
  const [totalClinicians, setTotalClinicians] = useState([]);

  const fetchProfile = async () => {
    if (authUser.id) {
      try {
        const { patient } = await getUserProfile();
        setProfile(patient);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const [showPsychotherapists, setShowPsychotherapists] = useState(true);
  const [requestedSuperBills, setRequestedSuperBills] = useState([]);

  const fetchPsychotherapists = async () => {
    try {
      if (authUser.id) {
        const params = {
          patient_id: authUser.id,
          clinic_id: (authUser.invites && authUser.invites.clinic_id) || 0,
          limit,
          skip: skip ? skip : 0,
        };
        const { doctors, total } = await getFactMatchedDoctors(params);
        setPsychotherapists(doctors);
        setTotalClinicians(total);
        setShowPsychotherapists(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const loadMore = async () => {
    const nextSkip = skip + limit;
    setSkip(nextSkip);
    try {
      const params = {
        patient_id: authUser.id,
        clinic_id: (authUser.invites && authUser.invites.clinic_id) || 0,
        limit,
        skip: nextSkip,
      };
      const { doctors: clinicians, total } = await getFactMatchedDoctors(
        params,
      );
      setPsychotherapists((doctors) => [...doctors, ...clinicians]);
      setShowPsychotherapists(true);
      setTotalClinicians(total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchSuperBill = async () => {
    setLoading(true);
    let data;
    try {
      data = await getSuperBillRequests();
    } catch (error) {
      if (error) {
        return;
      }
    }
    const requestsParsingFilters = [
      {
        key: 'sent_at',
        value: true,
      },
      {
        key: 'patient_viewed_at',
        value: false,
      },
    ];
    const response = parseSuperBillRequests(
      data,
      'appointment.doctor.id',
      'sent_at',
      requestsParsingFilters,
    );
    setRequestedSuperBills(response);
    setLoading(false);
  };

  useEffect(() => {
    if (authUser.id) {
      fetchProfile();
      fetchPsychotherapists();
      fetchSuperBill();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const handleDiscover = () => {
    if (authUser?.whitelabelDetails?.assessment_key) {
      history.push(
        `/my-account/discoverv2/${authUser.whitelabelDetails.assessment_key}`,
        {
          patientId: profile.id,
        },
      );
    } else {
      history.push('/my-account/discoverv2', { patientId: profile.id });
    }
  };

  const handleBookAppointment = (item) => {
    setSelectedDoc(item);
    history.push(`/my-account/appointment/${item.id}`);
  };

  const handleFillInForm = () => {
    setShowIntakeFormConfirmation(false);
    history.push('/my-account/intake-form', {
      patient_id: profile.id,
      doctor_id: selectedDoc.id,
    });
  };
  const handleSkip = () => {
    setShowIntakeFormConfirmation(false);
    history.push(`/my-account/appointment/${selectedDoc.id}`);
  };

  return (
    <div
      className={`${styles.container} ${
        process.env.REACT_APP_SUPERBILL_FLAG === 'true' ? styles.superBill : ''
      }`}
    >
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <LeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content} data-testid="dashboard page">
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            >
              <div className={styles.header} data-testid="dashboard_firstname">
                Hi {authUser.first_name}
              </div>
            </div>
            <div>
              <MarketingCopy></MarketingCopy>
              {!!process.env.REACT_APP_SUPERBILL_FLAG &&
                requestedSuperBills.length > 0 && (
                  <ul className={styles.superBillWrapper}>
                    {requestedSuperBills.map((item, index) => (
                      <li key={index}>
                        <SuperBillCard
                          title={'Superbill Received'}
                          message={`You have a new Superbill available for your appointment on ${moment(
                            item.appointment.start_time,
                          ).format('dddd, MMMM DD, YYYY')}`}
                          buttonClass={'viewSuperBillButton'}
                          buttonLabel={'View'}
                          onClick={() =>
                            history.push(
                              `/my-account/superbill/patient/${item.id}`,
                            )
                          }
                        ></SuperBillCard>
                      </li>
                    ))}
                  </ul>
                )}
            </div>
            <div className={styles.stepsCard}>
              {profile && profile.forms && profile.forms.length > 0 && (
                <StepCard
                  step={`Step 1`}
                  head={
                    profile && profile.forms.length > 1
                      ? `Please complete these forms or assessments`
                      : `Please complete your ${profile.forms[0].form_name}`
                  }
                  oneLiner={true}
                  btnText={`OK`}
                  onClick={() => {
                    const eventData = {
                      event: 'ga4_event',
                      properties: {
                        event_name: 'select_promotion',
                        card_name: 'forms',
                        patient_id: authUser ? authUser.id : '',
                      },
                    };
                    addToDataLayer(eventData);
                    history.push('/my-account/intake-forms');
                  }}
                  data-testid="dashboard stepcard step 1"
                />
              )}
              {profile && profile.has_unpaid_appointment && (
                <StepCard
                  step={`Step 3`}
                  head={`Your appointment requires payment`}
                  oneLiner={true}
                  btnText={`OK`}
                  onClick={() => {
                    const eventData = {
                      event: 'ga4_event',
                      properties: {
                        event_name: 'select_promotion',
                        card_name: 'payment',
                        patient_id: authUser ? authUser.id : '',
                      },
                    };
                    addToDataLayer(eventData);
                    history.push('/my-account/appointments');
                  }}
                  data-testid="dashboard stepcard step 3"
                />
              )}
            </div>

            {isDataLoading ? (
              <Loading />
            ) : (
              <div>
                {showPsychotherapists && (
                  <RecommendPsychotherapists
                    psychotherapists={psychotherapists}
                    onBookAppointment={handleBookAppointment}
                    history={history}
                    onFillAssessment={handleDiscover}
                  />
                )}
                {totalClinicians > psychotherapists.length && (
                  <div className={styles.morebutton}>
                    <SecondaryButton
                      label={`Load More`}
                      className={styles.btn}
                      type="button"
                      onClick={loadMore}
                      data-testid="dashboard_loadmore_button"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {showResetRecommendationModal && (
            <Modal
              className={styles.modal}
              data-testid="dashboard reset recommendation modal"
            >
              <div className={styles.content}>
                <h1 data-testid="dashboard reset recommendation modal title">
                  <b>{formatMessage(messages.confirmResetTitle)}</b>
                </h1>
                <p data-testid="dashboard reset recommendation modal text">
                  {formatMessage(messages.confirmResetText)}
                </p>
                <PrimaryButton
                  label={formatMessage(messages.reset)}
                  className={styles.primaryButton}
                  onClick={() => {}}
                  data-testid="dashboard reset recommendation modal confirm button"
                />
                <SecondaryButton
                  label={formatMessage(messages.confirmResetCancel)}
                  className={styles.secondaryButton}
                  onClick={() => setShowResetRecommendationModal(false)}
                  data-testid="dashboard reset recommendation modal cancel button"
                />
              </div>
            </Modal>
          )}
          {showIntakeFormConfirmation && (
            <Modal
              className={styles.modal}
              data-testid="dashboard intake form confirmation modal"
            >
              <div className={styles.content}>
                <h1 data-testid="dashboard intake form confirmation modal title">
                  <b>{formatMessage(messages.modalTitle)}</b>
                </h1>
                <p data-testid="dashboard intake form confirmation text">
                  {formatMessage(messages.modalText)}
                </p>
                <PrimaryButton
                  label={formatMessage(messages.modalButtonPrimary)}
                  className={styles.btn}
                  onClick={handleFillInForm}
                  data-testid="dashboard intake form confirmation modal confirm button"
                />
                <SecondaryButton
                  label={formatMessage(messages.modalButtonSecondary)}
                  className={styles.btn}
                  onClick={handleSkip}
                  data-testid="dashboard intake form confirmation modal skip button"
                />
              </div>
            </Modal>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default DashboardPage;
