import React, { useState, Fragment, useContext, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

import Loading from '../../../components/common/Loading';
import SecondaryButton from '../../../components/common/buttons/SecondaryButton';
import Dropdown from '../../../components/common/inputs/Dropdown';
import DcotorLeftNav from '../../../components/nav/DoctorLeftNav';
import DoctorAppointment from '../../../components/DoctorAppointment';

import { getDoctorAppointments } from '../../../api/user';
import { getPatientList } from '../../../api/doctor';

import AuthContext from '../../../context/AuthContext';

import styles from './DoctorAppointmentsPage.module.scss';

const TAB = {
  UPCOMING: 'upcoming',
  ALL: 'all',
};
const tabs = [TAB.UPCOMING, TAB.ALL];

const DoctorAppointmentsPage = () => {
  const { isLoading, authUser } = useContext(AuthContext);

  const [dataLoading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [totalAppointments, setTotalAppointments] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeTab, setActiveTab] = useState(TAB.UPCOMING);
  const [search, setSearch] = useState([]);
  const [patient, setPatient] = useState();
  const [fetchingAppointments, setFetchingAppointments] = useState(false);

  const isDataLoading = dataLoading || isLoading;

  const fetchAppointments = async (
    filterParams = null,
    startDate = null,
    endDate = null,
    skip = 0,
    searchValue = [],
  ) => {
    if (!!fetchingAppointments) return;

    if (authUser.id) {
      try {
        setFetchingAppointments(true);
        setLoading(true);
        const ids = searchValue.map((value) => value.id);
        const params = _.pickBy(
          {
            doctor_id: authUser.id,
            filter: filterParams,
            startDate: startDate,
            endDate: endDate,
            limit,
            skip: skip ? skip : 0,
            patientIds: ids,
            isViewByDoctor: true,
          },
          (v) => v !== undefined && v !== null,
        );

        const { appointments: doctorAppointments, total } =
          await getDoctorAppointments(params, { filter: filterParams });

        const allAppointments =
          skip === 0
            ? [...doctorAppointments]
            : [...appointments, ...doctorAppointments];

        setAppointments(allAppointments);
        setTotalAppointments(total);
        setLoading(false);
        setFetchingAppointments(false);
      } catch (error) {
        setLoading(false);
        setFetchingAppointments(false);
      }
    }
  };

  const onclickTab = (tab) => {
    setActiveTab(tab);
    setStartDate(null);
    setEndDate(null);
    setSearch([]);
    listenFilterEvents(tab, null, null, 0, []);
  };

  const fetchPatients = async () => {
    if (authUser.id && authUser.clinic_id) {
      try {
        const params = _.pickBy(
          {
            doctor_id: authUser.id,
            clinic_id: authUser.clinic_id,
            endDate: null,
            fitler: null,
            limit: null,
            skip: 0,
            sortBy: null,
            startDate: null,
          },
          (v) => v !== undefined && v !== null,
        );
        const { patientList } = await getPatientList(params);
        const searchOption = patientList
          .filter((record) => record.patient)
          .map((record) => {
            const patients = record.patient;
            let fullName = patients.first_name;
            if (patients.last_name) {
              fullName = fullName + ' ' + patients.last_name;
            }
            return {
              id: patients.id,
              label: fullName,
              value: fullName,
            };
          });
        setPatient(searchOption);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchPatients();
    fetchAppointments(activeTab, startDate, endDate, skip, search);
  }, [authUser]);

  // listing other filter events
  const listenFilterEvents = (
    activeTabVal = activeTab,
    startDateVal = startDate,
    endDateVal = endDate,
    skipVal = 0,
    searchVal = search,
  ) => {
    const utcStartDate = startDateVal
      ? moment(startDateVal).startOf('day').utc().format()
      : null;
    const utcEndDate = endDateVal
      ? moment(endDateVal).endOf('day').utc().format()
      : null;

    fetchAppointments(
      activeTabVal,
      utcStartDate,
      utcEndDate,
      skipVal,
      searchVal,
    );
  };

  const onClear = (isClearStartDate = false) => {
    if (isClearStartDate) {
      setStartDate(null);
    } else {
      setEndDate(null);
    }
    setSkip(0);
    listenFilterEvents(
      activeTab,
      isClearStartDate ? null : startDate,
      !isClearStartDate ? null : endDate,
      0,
    );
  };

  const loadMore = async () => {
    const nextSkip = skip + limit;
    setSkip(nextSkip);
    listenFilterEvents(activeTab, startDate, endDate, nextSkip);
  };

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <DcotorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            />
            <div className={styles.header}>
              <h1 data-testid="appointment header">Appointments</h1>
              <div className={styles.subheader}>
                <nav className={styles.tabs}>
                  {tabs.map((tab) => (
                    <button
                      key={tab}
                      className={[
                        styles.tab,
                        activeTab === tab ? styles.activeTab : '',
                      ].join(' ')}
                      type="button"
                      onClick={() => onclickTab(tab)}
                    >
                      <span data-testid={tab}>{tab}</span>
                      <div></div>
                    </button>
                  ))}
                </nav>

                <div className={styles.filters}>
                  <div className={styles.datePicker}>
                    <label data-testid="calender label">Calendar</label>
                    <DatePicker
                      onChange={(date) => {
                        setStartDate(date);
                        listenFilterEvents(activeTab, date, endDate, 0);
                      }}
                      selectsStart
                      selected={startDate}
                      startDate={startDate}
                      endDate={endDate}
                      customInput={
                        <div
                          data-testid="start date range"
                          className={styles.daterange}
                        >
                          <p data-testid="start date">
                            {startDate
                              ? moment(startDate).format('MM/DD/YYYY')
                              : 'Start Date'}
                          </p>
                          {!startDate && (
                            <img
                              src={require(`../../../img/icons/calendar-black.svg`)}
                              alt={'calendar'}
                            />
                          )}
                          {startDate && (
                            <img
                              className={styles.closeIcon}
                              onClick={() => onClear(true)}
                              src={require(`../../../img/icons/close-thin.svg`)}
                              alt={'calendar'}
                            />
                          )}
                        </div>
                      }
                      dateFormat="MM/dd/yyyy"
                    />
                    <label
                      data-testid="to label"
                      className={styles['to-label']}
                    >
                      to
                    </label>
                    <DatePicker
                      onChange={(date) => {
                        setEndDate(date);
                        listenFilterEvents(activeTab, startDate, date, 0);
                      }}
                      selectsEnd
                      selected={endDate}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      customInput={
                        <div
                          data-testid="end date range"
                          className={styles.daterange}
                        >
                          <p data-testid="end date">
                            {endDate
                              ? moment(endDate).format('MM/DD/YYYY')
                              : 'End Date'}
                          </p>
                          {!endDate && (
                            <img
                              src={require(`../../../img/icons/calendar-black.svg`)}
                              alt={'calendar'}
                            />
                          )}
                          {endDate && (
                            <img
                              className={styles.closeIcon}
                              onClick={() => onClear(false)}
                              src={require(`../../../img/icons/close-thin.svg`)}
                              alt={'calendar'}
                            />
                          )}
                        </div>
                      }
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                  <div className={styles.searchFilter}>
                    <label
                      data-testid="filter label"
                      className={styles['to-label']}
                    >
                      Filter
                    </label>
                    {patient && (
                      <Dropdown
                        options={patient}
                        isMulti
                        name="search"
                        value={search}
                        onChange={(selectedValues) => {
                          let searchValue = [];
                          if (selectedValues && selectedValues?.length) {
                            searchValue = selectedValues;
                          }
                          setSearch(searchValue);
                          listenFilterEvents(
                            activeTab,
                            startDate,
                            endDate,
                            0,
                            searchValue,
                          );
                        }}
                        classNamePrefix="select"
                        data-testid="filter dropdown"
                        placeholder="Select..."
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {appointments.length ? (
              <Fragment>
                {appointments.map((appointment, index) => (
                  <DoctorAppointment item={appointment} key={index} />
                ))}
                {totalAppointments > appointments.length && (
                  <div className={styles.buttons}>
                    <SecondaryButton
                      label={`Load More`}
                      className={styles.btn}
                      type="button"
                      onClick={loadMore}
                      data-testid="load more button"
                    />
                  </div>
                )}
              </Fragment>
            ) : (
              <div
                data-testid="no appointment available text"
                className={styles.nodata}
              >
                No appointments available
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default DoctorAppointmentsPage;
