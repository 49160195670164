import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';

import colors from '../../../../styles/_colors.scss';

const styles = {
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  control: (base) => ({
    ...base,
    borderRadius: '1.625rem',
    width: '100%',
    height: '3.25rem',
    maxHeight: '40px',
    boxShadow: 0,
    border: `1px solid ${colors.grayLight2}`,
    '&:hover': {
      border: `1px solid ${colors.grayLight2}`,
    },
    '&:focus': {
      outlineWidth: 0,
      boxShadow: 'none',
      border: 'none',
    },
    background: 'white',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: colors.grayLight2,
    paddingRight: '0.5rem',
    transition: 'all .2s ease',
    transform: state.isFocused ? 'rotate(180deg)' : null,
    '&:hover': {
      color: colors.grayLight2,
    },
  }),
  singleValue: (base) => ({ ...base, marginLeft: '1rem' }),
  valueContainer: (base) => ({
    ...base,
    height: '100%',
    input: { boxShadow: 'none !important' },
  }),
};

const SelectContainer = (props) => {
  return (
    <components.SelectContainer
      {...props}
      innerProps={Object.assign({}, props.innerProps, {
        'data-testid': props.selectProps.dataTestId,
      })}
    />
  );
};

const Option = (props) => (
  <components.Option
    {...props}
    innerProps={Object.assign({}, props.innerProps, {
      'data-testid': props.data.label,
    })}
  />
);

const RoundDropdown = ({
  disabled,
  options,
  onChange,
  onBlur,
  value,
  name,
  isSearchable,
  placeholder,
  className,
  customStyles,
  dataTestId,
  autoFocus,
}) => {
  return (
    <Select
      autoFocus={autoFocus}
      className={className || ''}
      id={`select_${dataTestId}`}
      aria-label={dataTestId}
      aria-labelledby={dataTestId}
      options={options}
      name={name}
      dataTestId={dataTestId}
      onChange={({ value }, { name }) => {
        onChange({ target: { value, name } });
      }}
      onBlur={onBlur}
      value={options.find((option) => option.value === value)}
      isSearchable={isSearchable}
      styles={customStyles || styles}
      isDisabled={disabled}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: colors.grayLight2,
          primary: colors.black,
          primary50: colors.grayLight2,
        },
      })}
      components={{
        SelectContainer,
        Option,
        IndicatorSeparator: () => null,
      }}
      placeholder={placeholder}
    />
  );
};

RoundDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
};

RoundDropdown.defaultProps = {
  isSearchable: false,
  placeholder: '',
};

export default RoundDropdown;
