import React from 'react';
import classnames from 'classnames';
import styles from './RoundCheckBoxSmall.module.scss';

const RoundCheckBoxSmall = ({ label, isActive, onClick }) => (
  <div className={styles.container} onClick={onClick}>
    <div
      className={classnames(styles.box, {
        [styles['box-active']]: isActive,
      })}
      data-testid="box-div"
    ></div>
    <div className={styles['box-name']}>{label}</div>
  </div>
);

export default RoundCheckBoxSmall;
