import React from 'react';
import LayoutPDF from './components/LayoutPDF';
import { Text, View } from '@react-pdf/renderer';
import { alcoholAndDrugsOptions } from '../../components/forms/user/PersonalInfoForm/data';
import { displayDateFormat } from '../../utils/common';
import { AssessmentPDFStyles } from './AssessmentPDFStyles';

const PersonalInfoPDF = ({ item, formDetail }) => {
  return (
    <LayoutPDF item={item} formDetail={formDetail} title="Assessment">
      <>
        <Text style={AssessmentPDFStyles.content_detail.h2}>
          Personal Details
        </Text>
        <View style={AssessmentPDFStyles.content_detail.rowContainer}>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>
              First Name:
            </Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail && formDetail.first_name}
            </Text>
          </View>
          <View>
            <Text style={AssessmentPDFStyles.content_detail.span}>
              Last Name:
            </Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail && formDetail.last_name}
            </Text>
          </View>
        </View>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Allergies:
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.details && formDetail.details.allergies}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.rowContainer}>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>Gender:</Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.gender}
            </Text>
          </View>
          <View View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>
              Preferred Pronouns:
            </Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.preferred_pronouns}
            </Text>
          </View>
          <View>
            <Text style={AssessmentPDFStyles.content_detail.span}>
              Relationship Status:
            </Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.relationship_status}
            </Text>
          </View>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Date of Birth:
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail && displayDateFormat(formDetail.dob)}
          </Text>
        </View>
        <Text style={AssessmentPDFStyles.content_detail.h2}>
          Contact Information
        </Text>
        <View style={AssessmentPDFStyles.content_detail.rowContainer}>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>
              Street Address:
            </Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.street_address}
            </Text>
          </View>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>City:</Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.city}
            </Text>
          </View>
        </View>
        <View style={AssessmentPDFStyles.content_detail.rowContainer}>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>State:</Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.state}
            </Text>
          </View>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>ZIP:</Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.zip}
            </Text>
          </View>
        </View>

        <View style={AssessmentPDFStyles.content_detail.rowContainer}>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>
              Home Phone:
            </Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.home_phone}
            </Text>
          </View>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>
              Mobile Phone:
            </Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail && formDetail.phone}
            </Text>
          </View>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>
              Work Phone:
            </Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.work_phone}
            </Text>
          </View>
        </View>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>Email:</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail && formDetail.email}
          </Text>
        </View>
        <View>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Preferred Methods of Contact:
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.details &&
              formDetail?.details?.contact_method?.join(', ')}
          </Text>
        </View>
        <Text style={AssessmentPDFStyles.content_detail.h2}>
          Emergency Contact Information
        </Text>
        <View style={AssessmentPDFStyles.content_detail.rowContainer}>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>Name:</Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.emergency_contact_name}
            </Text>
          </View>
          <View>
            <Text style={AssessmentPDFStyles.content_detail.span}>
              Relationship:
            </Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details &&
                formDetail.details.emergency_contact_relationship}
            </Text>
          </View>
        </View>
        <View style={AssessmentPDFStyles.content_detail.rowContainer}>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>Phone:</Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.emergency_contact_phone}
            </Text>
          </View>
          <View>
            <Text style={AssessmentPDFStyles.content_detail.span}>Email:</Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.emergency_contact_email}
            </Text>
          </View>
        </View>
        <Text style={AssessmentPDFStyles.content_detail.h2}>
          Insurance Information
        </Text>
        <View style={AssessmentPDFStyles.content_detail.rowContainer}>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>SSN:</Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.ssn}
            </Text>
          </View>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>
              Primary Insurance Provider:
            </Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.insurance_provider}
            </Text>
          </View>
        </View>
        <View style={AssessmentPDFStyles.content_detail.rowContainer}>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>
              Insurance ID #:
            </Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.insurance_id}
            </Text>
          </View>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>
              Group ID #:
            </Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.group_id}
            </Text>
          </View>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            Name of Insured:
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.details && formDetail.details.name_insured}
          </Text>
        </View>
        <View style={AssessmentPDFStyles.content_detail.rowContainer}>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>
              Name of Primary Care Doctor:
            </Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.primary_care_doctor}
            </Text>
          </View>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>
              Doctor's Contact #:
            </Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details &&
                formDetail.details.primary_care_doctor_phone}
            </Text>
          </View>
        </View>
        <View style={AssessmentPDFStyles.content_detail.column3}>
          <Text style={AssessmentPDFStyles.content_detail.span}>
            {`Preferred Pharmacy & Address`}:
          </Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.details &&
              formDetail.details.preferred_pharmacy_address}
          </Text>
        </View>
        <Text style={AssessmentPDFStyles.content_detail.h2}>
          {`Billing & Payment Information`}
        </Text>
        <View style={AssessmentPDFStyles.content_detail.rowContainer}>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>
              Street Address:
            </Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.billing_street_address}
            </Text>
          </View>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>City:</Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.billing_city}
            </Text>
          </View>
        </View>
        <View style={AssessmentPDFStyles.content_detail.rowContainer}>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>State:</Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.billing_state}
            </Text>
          </View>
          <View style={AssessmentPDFStyles.content_detail.column3}>
            <Text style={AssessmentPDFStyles.content_detail.span}>ZIP:</Text>
            <Text style={AssessmentPDFStyles.content_detail.p}>
              {formDetail.details && formDetail.details.billing_zip}
            </Text>
          </View>
        </View>
        {formDetail.details &&
          formDetail.details.current_medications.length > 0 && (
            <>
              <Text style={AssessmentPDFStyles.content_detail.h2}>
                Current Medications:
              </Text>
              <View style={AssessmentPDFStyles.content_detail.detail}>
                <View style={AssessmentPDFStyles.table}>
                  <View style={AssessmentPDFStyles.table.tableTr}>
                    <View
                      style={[
                        AssessmentPDFStyles.table.tableTr.tableTrDiv,
                        AssessmentPDFStyles.content_detail.column3,
                      ]}
                    >
                      <Text style={AssessmentPDFStyles.table.tableTr.span}>
                        Name
                      </Text>
                    </View>
                    <View
                      style={[
                        AssessmentPDFStyles.table.tableTr.tableTrDiv,
                        AssessmentPDFStyles.content_detail.column3,
                      ]}
                    >
                      <Text style={AssessmentPDFStyles.table.tableTr.span}>
                        Dose
                      </Text>
                    </View>
                    <View
                      style={[
                        AssessmentPDFStyles.table.tableTr.tableTrDiv,
                        AssessmentPDFStyles.content_detail.column3,
                      ]}
                    >
                      <Text style={AssessmentPDFStyles.table.tableTr.span}>
                        Frequency
                      </Text>
                    </View>
                  </View>
                  {formDetail.details.current_medications.map((item, index) => (
                    <View key={index} style={AssessmentPDFStyles.table.tableTr}>
                      <Text
                        style={[
                          AssessmentPDFStyles.table.tableTr.p,
                          AssessmentPDFStyles.content_detail.column3,
                        ]}
                      >
                        {item.name}
                      </Text>
                      <Text
                        style={[
                          AssessmentPDFStyles.table.tableTr.p,
                          AssessmentPDFStyles.content_detail.column3,
                        ]}
                      >
                        {item.dose}
                      </Text>
                      <Text
                        style={[
                          AssessmentPDFStyles.table.tableTr.p,
                          AssessmentPDFStyles.content_detail.column3,
                        ]}
                      >
                        {item.frequency}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            </>
          )}
        {formDetail.details &&
          formDetail.details.illnesses_injuries.length > 0 && (
            <>
              <Text style={AssessmentPDFStyles.content_detail.h2}>
                Illnesses and Injuries:
              </Text>
              <View style={AssessmentPDFStyles.content_detail.detail}>
                <View style={AssessmentPDFStyles.table}>
                  <View style={AssessmentPDFStyles.table.tableTr}>
                    <View
                      style={[
                        AssessmentPDFStyles.table.tableTr.tableTrDiv,
                        AssessmentPDFStyles.content_detail.column3,
                      ]}
                    >
                      <Text style={AssessmentPDFStyles.table.tableTr.span}>
                        Description
                      </Text>
                    </View>
                    <View
                      style={[
                        AssessmentPDFStyles.table.tableTr.tableTrDiv,
                        AssessmentPDFStyles.content_detail.column3,
                      ]}
                    >
                      <Text style={AssessmentPDFStyles.table.tableTr.span}>
                        Estimated Date of Onset
                      </Text>
                    </View>
                  </View>
                  {formDetail.details.illnesses_injuries.map((item, index) => (
                    <View key={index} style={AssessmentPDFStyles.table.tableTr}>
                      <Text
                        style={[
                          AssessmentPDFStyles.table.tableTr.p,
                          AssessmentPDFStyles.content_detail.column3,
                        ]}
                      >
                        {item.description}
                      </Text>
                      <Text
                        style={[
                          AssessmentPDFStyles.table.tableTr.p,
                          AssessmentPDFStyles.content_detail.column3,
                        ]}
                      >
                        {displayDateFormat(item.dateOfOnset)}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            </>
          )}

        <Text style={AssessmentPDFStyles.content_detail.h2}>
          Alcohol and Drug Use:
        </Text>
        <View style={AssessmentPDFStyles.content_detail.detail}>
          <View style={AssessmentPDFStyles.table}>
            {alcoholAndDrugsOptions.map((item, index) => (
              <View key={index} style={AssessmentPDFStyles.content_detail}>
                <Text style={AssessmentPDFStyles.content_detail.span}>
                  {item.label}:
                </Text>
                <Text style={AssessmentPDFStyles.content_detail.p}>
                  {formDetail.details && formDetail.details[item.id]}
                </Text>
              </View>
            ))}
          </View>
        </View>
        <View style={AssessmentPDFStyles.content_detail}>
          <Text style={AssessmentPDFStyles.content_detail.span}>Others:</Text>
          <Text style={AssessmentPDFStyles.content_detail.p}>
            {formDetail.details && formDetail.details.others}
          </Text>
        </View>
      </>
    </LayoutPDF>
  );
};

export default PersonalInfoPDF;
