import React, { useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Cell, Grid } from 'react-foundation';
import Iframe from 'react-iframe';
import { PDFDownloadLink } from '@react-pdf/renderer';
import moment from 'moment';
import classnames from 'classnames';

import Loading from '../common/Loading';
import Modal from '../common/Modal';
import PrimaryButton from '../common/buttons/PrimaryButton';
import SecondaryButton from '../common/buttons/SecondaryButton';
import Signature from '../Hellosign';
import UserConfirmation from '../UserConfirmation';

import { displayDateFormat, prepareAssessmentValues } from '../../utils/common';
import intakeFormDisplayMessages from '../forms/user/KAPIntakeForm/messages';

import styles from './FormListView.module.scss';
import { moosIntake as moosIntakeData } from './IntakeQuestions.json';
import Tick from '../../img/icons/appointment-booked.svg';

import useIntl from '../../hooks/useIntl';
import messages from './messages';

import {
  getIntakeConsentForm,
  updateIntakeForm2,
  getIntakeFormByFactFormId,
  getConsentFormByFactFormId,
  getAssessmentByFactFormId,
  getMoosIntakeFormByFactFormId,
  fetchIntakeFormByFactFormId,
} from '../../api/intakeForm';
import { getEntries } from '../../api/contentful';

import { FORM, USER_TYPES } from '../../constants';

import IntakeFormPDF from '../AssessmentPDF/IntakeFormPDF';
import AssessmentPDF from '../AssessmentPDF/AssessmentPDF';
import AssessmentPDF2 from '../AssessmentPDF/AssessmentPDF2';
import MessageModal from '../MessageModal';
import PatientInformationResponse from '../../components/forms/user/PersonalInfoForm/PatientInformationResponse';
import { getUserProfile } from '../../api/user';
import BPSFormResponse from '../../components/forms/user/BPSForm/BPSFormResponse';
import SolsticeFormResponse from '../../components/forms/user/SolsticeForm/SolsticeFormResponse';
import AnahitaFormResponse from '../../components/forms/user/AnahitaForm/AnahitaFormResponse';
import WildHeartFormResponse from '../../components/forms/user/WildHeartForm/WildHeartFormResponse';

const prepareIntakeFormDisplayValues = (fields, assessmentValues) => {
  const assessments = [];
  let assessmentValue;

  fields.forEach((field) => {
    if (field.key && assessmentValues[field.key]) {
      if (
        assessmentValues[field.key].value ||
        assessmentValues[field.key].options ||
        field.type === 'slider'
      ) {
        if (assessmentValues[field.key].options) {
          assessments.push({
            key: [field.title],
            value: assessmentValues[field.key].options
              .map((option) => option.title)
              .toString(),
          });
        } else if (field.type === 'slider') {
          assessmentValue = field.marks.find(
            (mark) =>
              mark.value === assessmentValues[field.key].value ||
              mark.value === assessmentValues[field.key],
          ).label;
          assessments.push({ key: [field.title], value: assessmentValue });
        } else if (field.type === 'swipe-card') {
          assessments.push({
            key: [`${field.title.replace(/\.+$/, '')} ${field.subtitle}`],
            value: assessmentValues[field.key].value,
          });
        } else if (
          Array.isArray(assessmentValues[field.key]) &&
          field.type === 'arrayOfString'
        ) {
          assessments.push({
            key: [field.title],
            value: assessmentValues[field.key].join(),
          });
        } else if (
          Array.isArray(assessmentValues[field.key]) &&
          field.type === 'fieldArray'
        ) {
          assessments.push({
            isFieldArray: true,
            key: [field.title],
            tableHeads: field.subFields.map((subField) => ({
              title: subField.title,
              key: subField.key,
            })),
            rows: assessmentValues[field.key],
          });
        } else if (
          !Array.isArray(assessmentValues[field.key]) &&
          field.type === 'object'
        ) {
          field.subFields.forEach((subField) => {
            assessments.push({
              key: [subField.title],
              value: assessmentValues[field.key][subField.key],
            });
          });
        } else {
          assessments.push({
            key: [field.title],
            value: assessmentValues[field.key].value,
          });
        }
      } else if (field.key && assessmentValues[field.key]) {
        if (
          Array.isArray(assessmentValues[field.key]) &&
          field.type === 'arrayOfString'
        ) {
          assessments.push({
            key: [field.title],
            value: assessmentValues[field.key].join(),
          });
        } else if (
          Array.isArray(assessmentValues[field.key]) &&
          field.type === 'fieldArray'
        ) {
          assessments.push({
            isFieldArray: true,
            key: [field.title],
            tableHeads: field.subFields.map((subField) => ({
              title: subField.title,
              key: subField.key,
            })),
            rows: assessmentValues[field.key],
          });
        } else if (
          !Array.isArray(assessmentValues[field.key]) &&
          field.type === 'object'
        ) {
          field.subFields.forEach((subField) => {
            if (subField.type === 'date') {
              assessments.push({
                key: [subField.title],
                value: moment(assessmentValues[field.key][subField.key]).format(
                  'DD/MM/YYYY',
                ),
              });
            } else {
              assessments.push({
                key: [subField.title],
                value: assessmentValues[field.key][subField.key],
              });
            }
          });
        } else if (field.type === 'date') {
          assessments.push({
            key: [field.title],
            value: moment(assessmentValues[field.key]).format('DD/MM/YYYY'),
          });
        } else {
          assessments.push({
            key: [field.title],
            value: assessmentValues[field.key],
          });
        }
      }
    }
  });
  return assessments;
};

const FormListView = ({
  onFillInForm,
  isView,
  onResetForm,
  item,
  userType,
  onConfirmRemove,
  hasAssignedConsent,
  viewSign,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showIntakeFormModal, setShowIntakeFormModal] = useState(false);
  const [showPatientDetailsModal, setShowPatientDetailsModal] = useState(false);
  const [openModalForm, setOpenModalForm] = useState();
  const [formDetail, setFormDetail] = useState(false);
  const [showResetFormConfirmation, setShowResetFormConfirmation] =
    useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [displayMessage, setDisplayMessage] = useState({
    title: '',
    message: '',
  });
  const [showAssessmentModal, setShowAssessmentModal] = useState(false);
  const [showConsentModal, setshowConsentModal] = useState(false);
  const [showSignature] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [getConsentFormURl, setGetConsentFormURl] = useState('');
  const [formassessments, setFormassessments] = useState({});
  const [showCofirmation, setShowCofirmation] = useState(false);
  const [removeForm, setRemoveForm] = useState({});
  const [patient, setPatient] = useState();

  const initialValues = {
    first_name: patient?.first_name || '',
    last_name: patient?.last_name || '',
    phone: patient?.phone || '',
    dob: patient?.dob ? moment(patient?.dob).format('MM/DD/YYYY') : '',
    email: patient?.email || '',
    allergies: patient?.details?.allergies || '',
    gender: patient?.details?.gender || '',
    preferred_pronouns: patient?.details?.preferred_pronouns || '',
    relationship_status: patient?.details?.relationship_status || '',
    street_address: patient?.details?.street_address || '',
    city: patient?.details?.city || '',
    state: patient?.details?.state || '',
    zip: patient?.details?.zip || '',
    home_phone: patient?.details?.home_phone || '',
    work_phone: patient?.details?.work_phone || '',
    contact_method: patient?.details?.contact_method || '',
    ssn: patient?.details?.ssn || '',
    insurance_provider: patient?.details?.insurance_provider || '',
    insurance_id: patient?.details?.insurance_id || '',
    name_insured: patient?.details?.name_insured || '',
    group_id: patient?.details?.group_id || '',
    primary_care_doctor: patient?.details?.primary_care_doctor || '',
    primary_care_doctor_phone:
      patient?.details?.primary_care_doctor_phone || '',
    preferred_pharmacy_address:
      patient?.details?.preferred_pharmacy_address || '',
    billing_street_address: patient?.details?.billing_street_address || '',
    billing_city: patient?.details?.billing_city || '',
    billing_state: patient?.details?.billing_state || '',
    billing_zip: patient?.details?.billing_zip || '',
    current_medications: patient?.details?.current_medications || [],
    illnesses_injuries: patient?.details?.illnesses_injuries || [],
    tobacco: patient?.details?.tobacco || '',
    alcoholic_beverages: patient?.details?.alcoholic_beverages || '',
    cannabis: patient?.details?.cannabis || '',
    cocaine: patient?.details?.cocaine || '',
    amphetamine_simulants: patient?.details?.amphetamine_simulants || '',
    inhalants: patient?.details?.inhalants || '',
    sedatives_pills: patient?.details?.sedatives_pills || '',
    hallucinogens: patient?.details?.hallucinogens || '',
    opioids: patient?.details?.opioids || '',
    others: patient?.details?.others || '',
  };

  const handleView = async (form) => {
    setPatient(initialValues);

    if (
      form.form_type === FORM.TYPE.INTAKE &&
      form.dim_form.intake_type === null
    ) {
      setIsLoading(true);

      const { intakeForm1 } = await getIntakeFormByFactFormId(form.id);

      setFormDetail(intakeForm1);

      setIsLoading(false);
      setShowProfileModal(true);
    } else if (
      form.form_type === FORM.TYPE.INTAKE &&
      form.dim_form.intake_type === FORM.INTAKE_TYPE.PATIENT_INFO
    ) {
      setIsLoading(true);

      const patient =
        userType !== USER_TYPES.PATIENT
          ? await getUserProfile(form.patient_id)
          : await getUserProfile();
      setPatient(patient);

      setIsLoading(false);
      setShowPatientDetailsModal(true);
    } else if (
      form.form_type === FORM.TYPE.INTAKE &&
      form.dim_form.intake_type === FORM.INTAKE_TYPE.MOOSE
    ) {
      setIsLoading(true);

      const {
        moosIntakeForm: { intake_form_values },
      } = await getMoosIntakeFormByFactFormId(form.id);

      const moosIntakeFormattedData = prepareIntakeFormDisplayValues(
        moosIntakeData.questions,
        intake_form_values,
      );

      setFormDetail(moosIntakeFormattedData);

      setIsLoading(false);
      setShowIntakeFormModal(true);
    } else if (
      form.form_type === FORM.TYPE.INTAKE &&
      [
        FORM.INTAKE_TYPE.ANAHITA,
        FORM.INTAKE_TYPE.BPS,
        FORM.INTAKE_TYPE.SOLSTICE,
        FORM.INTAKE_TYPE.WILD_HEART,
      ].includes(form.dim_form.intake_type)
    ) {
      setIsLoading(true);

      const data = await fetchIntakeFormByFactFormId(form.id);
      setFormDetail(data?.intakeForm?.intake_form_values);

      setIsLoading(false);
      setOpenModalForm(form.dim_form.intake_type);
    } else if (form.form_type === FORM.TYPE.CONSENT) {
      setIsLoading(true);

      const { intakeForm2 } = await getConsentFormByFactFormId(form.id);

      if (intakeForm2.link_to_signed_pdf) {
        setGetConsentFormURl(intakeForm2.link_to_signed_pdf);
        setshowConsentModal(true);
      } else {
        await new Promise((resolve) => setTimeout(resolve, 9000));

        const consentFormUrl = await getIntakeConsentForm({
          requestId: intakeForm2.request_id,
        });

        await updateIntakeForm2(intakeForm2.id, {
          link_to_signed_pdf: consentFormUrl.documentUrl,
        });

        setIsLoading(false);

        if (userType !== USER_TYPES.PATIENT) {
          setGetConsentFormURl(consentFormUrl.documentUrl);
          setshowConsentModal(true);
        }
      }
      setIsLoading(false);

      if (userType === USER_TYPES.PATIENT) {
        history.push(`/my-account/form/consentform/${intakeForm2.id}`);
      }
    } else if (form.form_type === FORM.TYPE.ASSESSMENT) {
      setIsLoading(true);

      const { assessment } = await getAssessmentByFactFormId(form.id);

      const questionnaire = await getEntries({
        'fields.title': assessment.assessment_name,
        content_type: 'recommendationEngineQuestionnaire',
      });

      if (
        assessment.assessment_values &&
        questionnaire.items.length > 0 &&
        questionnaire.items[0].fields['questions']
      ) {
        const data = Object.assign({
          id: assessment.id,
          updatedAt: assessment.updatedAt,
          assessments: prepareAssessmentValues(
            questionnaire.items[0].fields['questions'],
            assessment.assessment_values,
          ),
        });

        setFormassessments(data.assessments);

        setShowAssessmentModal(true);
        setIsLoading(false);
      }
    }
  };

  const onClickReset = (title) => {
    onResetForm(title, item);
    setShowResetFormConfirmation(false);
  };

  const onConfirmClick = () => {
    setShowCofirmation(false);
    onConfirmRemove(removeForm);
  };

  const INTAKE_FORMS_RESPONSE_MAPPING = {
    [FORM.INTAKE_TYPE.BPS]: BPSFormResponse,
    [FORM.INTAKE_TYPE.ANAHITA]: AnahitaFormResponse,
    [FORM.INTAKE_TYPE.SOLSTICE]: SolsticeFormResponse,
    [FORM.INTAKE_TYPE.WILD_HEART]: WildHeartFormResponse,
  };

  return (
    <Grid className={styles.container}>
      {isLoading && <Loading />}

      <Cell small={12}>
        <div className={styles.content}>
          <div className={styles.bgImg}>
            <img alt="" src={require(`../../img/icons/document-icon.svg`)} />
          </div>
          <div className={styles.title} data-testid={`form_name_${item.id}`}>
            {item.form_name}
          </div>
          <div className={styles.rightContent}>
            {item.status === FORM.STATUS.COMPLETED && (
              <img className={styles.tick} src={Tick} alt="true" />
            )}
            <span
              className={styles.status}
              data-testid={`form status ${item.id}`}
            >
              {item.status}
            </span>
            <span data-testid={`form date ${item.id}`}>
              {' on '}
              {moment(item.updatedAt).format('LLL')}
            </span>
            <div className={styles.buttons}>
              {viewSign &&
                userType === USER_TYPES.DOCTOR &&
                item.status === FORM.STATUS.COMPLETED &&
                item.consentform &&
                item.consentform.length > 0 &&
                item.consentform[0].multi_signer && (
                  <PrimaryButton
                    label="Sign"
                    type="button"
                    className={styles.btn}
                    onClick={() => {
                      history.push(`/doctor/form/consentform/`, {
                        patient_id: item.patient_id,
                        fact_form_id: item.id,
                        template_name: item.dim_form.template_name,
                        doctor_id: item.doctor_id,
                        formData: item,
                      });
                    }}
                    data-testid={`form sign ${item.id}`}
                  />
                )}
              {item.status === FORM.STATUS.ASSIGNED && !isView && (
                <PrimaryButton
                  label="Fill Out"
                  type="button"
                  className={styles.btn}
                  onClick={() => {
                    if (
                      hasAssignedConsent &&
                      item.form_type !== FORM.TYPE.CONSENT
                    ) {
                      setShowMessage(true);
                      setDisplayMessage({
                        type: 'error',
                        title: '',
                        text: formatMessage(messages.messageModalText),
                        buttonLabel: 'OK',
                        close: true,
                      });
                      return;
                    }
                    onFillInForm(item.form_type, item);
                  }}
                  data-testid={`form Fill Out ${item.id}`}
                />
              )}
              {item.status === FORM.STATUS.COMPLETED && (
                <PrimaryButton
                  label={userType === USER_TYPES.PATIENT ? `View` : `View It`}
                  type="button"
                  className={styles.btn}
                  onClick={() => {
                    handleView(item);
                  }}
                  data-testid={`form view ${item.id}`}
                />
              )}
              {userType === USER_TYPES.DOCTOR &&
                item.status === FORM.STATUS.ASSIGNED && (
                  <PrimaryButton
                    label="Remove"
                    type="button"
                    className={styles.btn}
                    onClick={() => {
                      setShowCofirmation(true);
                      setRemoveForm(item);
                    }}
                    data-testid={`form Remove ${item.id}`}
                  />
                )}
            </div>
          </div>
        </div>

        {showResetFormConfirmation && (
          <Modal className={styles.resetmodal}>
            <div className={styles.content}>
              <h1>
                <b>{formatMessage(messages.resetModalTitle)}</b>
              </h1>
              <p>{formatMessage(messages.resetModalText)}</p>
              <PrimaryButton
                label={formatMessage(messages.resetModalButtonPrimary)}
                className={styles.primaryButton}
                onClick={() => onClickReset(item.form_name)}
              />
              <SecondaryButton
                label={formatMessage(messages.resetModalButtonSecondary)}
                className={styles.secondaryButton}
                onClick={async () => setShowResetFormConfirmation(false)}
              />
            </div>
          </Modal>
        )}

        {showMessage && (
          <MessageModal
            message={displayMessage}
            onCloseModal={() => setShowMessage(false)}
          />
        )}

        {showPatientDetailsModal && (
          <PatientInformationResponse
            personalInfo={patient}
            item={item}
            showPatientDetailsModal={showPatientDetailsModal}
            onClick={() => setShowPatientDetailsModal(!showPatientDetailsModal)}
          />
        )}

        {Object.keys(INTAKE_FORMS_RESPONSE_MAPPING).map((intakeType, i) => {
          const Form = INTAKE_FORMS_RESPONSE_MAPPING[intakeType];
          return (
            <Form
              key={i}
              data={formDetail}
              item={item}
              opened={openModalForm === intakeType}
              onClick={() => setOpenModalForm(!(openModalForm === intakeType))}
            />
          );
        })}

        {showProfileModal && (
          <Modal className={styles.modal}>
            <div className={styles.content}>
              <div
                className={styles.closeButton}
                onClick={() => setShowProfileModal(false)}
              >
                <img src={require(`../../img/close.svg`)} alt={`Close`} />
              </div>
              <h1>
                <b>{item.form_name}</b>
              </h1>
              <div className={styles.box}>
                <div className={styles['content-detail']}>
                  <div className={classnames([styles.headerSection])}>
                    <div>
                      <p className={styles.label}>Client Name:</p>
                      <p>
                        {item.patient &&
                          `${item.patient.first_name || ''} ${
                            item.patient.last_name || ''
                          }`}
                      </p>
                    </div>
                    <div>
                      <p className={styles.label}>Assessment:</p>
                      <p>{item.form_name || ''}</p>
                    </div>
                    <div>
                      <p className={styles.label}>Date Completed:</p>
                      <p>{moment(item.updatedAt).format('MMMM DD, YYYY')}</p>
                    </div>
                    <PDFDownloadLink
                      document={
                        <AssessmentPDF item={item} formDetail={formDetail} />
                      }
                      fileName={`${item.form_name || 'assessment'}.pdf`}
                    >
                      <PrimaryButton
                        label={`Download PDF`}
                        type="button"
                        className={styles.btn}
                      />
                    </PDFDownloadLink>
                  </div>
                </div>
              </div>
              <div className={styles.box}>
                <div className={styles['content-detail']}>
                  {formDetail && formDetail.id ? (
                    <Fragment>
                      <section className={styles['content-detail-section']}>
                        <h2>Personal Details</h2>
                        <div className={styles.text}>
                          <span>Name:</span>
                          <p>
                            {formDetail.personalDetails &&
                              formDetail.personalDetails.name}
                          </p>
                        </div>
                        <div className={styles.text}>
                          <span>Address:</span>
                          <p>
                            {formDetail.personalDetails &&
                              formDetail.personalDetails.address}
                          </p>
                        </div>

                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>City:</span>
                            <p>
                              {formDetail.personalDetails &&
                                formDetail.personalDetails.city}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>State:</span>
                            <p>
                              {formDetail.personalDetails &&
                                formDetail.personalDetails.state}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Zip/Postal Code:</span>
                            <p>
                              {formDetail.personalDetails &&
                                formDetail.personalDetails.postalCode}
                            </p>
                          </div>
                        </div>
                      </section>

                      <section className={styles['content-detail-section']}>
                        <h2>Contact Details</h2>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>Home Phone:</span>
                            <p>
                              {formDetail.contactDetails &&
                                formDetail.contactDetails.homePhone}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Cell Phone:</span>
                            <p>
                              {formDetail.contactDetails &&
                                formDetail.contactDetails.cellPhone}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Work Phone:</span>
                            <p>
                              {formDetail.contactDetails &&
                                formDetail.contactDetails.workPhone}
                            </p>
                          </div>
                        </div>
                        <div className={styles.text}>
                          <span>Email:</span>
                          <p>
                            {formDetail.contactDetails &&
                              formDetail.contactDetails.email}
                          </p>
                        </div>
                      </section>

                      <section className={styles['content-detail-section']}>
                        <h2>Other Details</h2>
                        <div className={styles.text}>
                          <span>SSH#</span>
                          <p>{formDetail && formDetail.otherDetails.ssId}</p>
                        </div>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>Age</span>
                            <p>
                              {formDetail.otherDetails &&
                                formDetail.otherDetails.age}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Birth Date</span>
                            <p>
                              {formDetail.otherDetails &&
                                displayDateFormat(
                                  formDetail.otherDetails.birthDate,
                                )}
                            </p>
                          </div>
                        </div>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>Your Gender</span>
                            <p>
                              {formDetail.otherDetails &&
                                formDetail.otherDetails.gender}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Do you identify as transgender?</span>
                            <p>
                              {formDetail.otherDetails &&
                              !!formDetail.otherDetails.isTransgender
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                        </div>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>Education</span>
                            <p>
                              {formDetail.otherDetails &&
                                formDetail.otherDetails.education}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Current Occupation</span>
                            <p>
                              {formDetail.otherDetails &&
                                formDetail.otherDetails.currentOccupation}
                            </p>
                          </div>
                        </div>
                      </section>

                      <section className={styles['content-detail-section']}>
                        <h2>Medical Emergency Contact Details</h2>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>Name</span>
                            <p>
                              {formDetail.medicalEmergency &&
                                formDetail.medicalEmergency.name}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Address</span>
                            <p>
                              {formDetail.medicalEmergency &&
                                formDetail.medicalEmergency.address}
                            </p>
                          </div>
                        </div>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>Relation</span>
                            <p>
                              {formDetail.medicalEmergency &&
                                formDetail.medicalEmergency.relation}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Phone</span>
                            <p>
                              {formDetail.medicalEmergency &&
                                formDetail.medicalEmergency.phone}
                            </p>
                          </div>
                        </div>
                      </section>

                      <section className={styles['content-detail-section']}>
                        <h2>Family Doctor Details</h2>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>Name</span>
                            <p>
                              {formDetail.familyDoctor &&
                                formDetail.familyDoctor.name}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Phone:</span>
                            <p>
                              {formDetail.familyDoctor &&
                                formDetail.familyDoctor.phone}
                            </p>
                          </div>
                        </div>
                      </section>

                      <section className={styles['content-detail-section']}>
                        <h2>Family Details</h2>
                        <div className={styles.text}>
                          <span>RelationShip Status</span>
                          <p>
                            {formDetail.familyDetails &&
                              formDetail.familyDetails.relationshipStatus}
                          </p>
                        </div>
                      </section>

                      <section className={styles['content-detail-section']}>
                        {formDetail.familyDetails &&
                          formDetail.familyDetails.children.length > 0 && (
                            <Fragment>
                              <h2>Children's</h2>
                              <div className={styles.detail}>
                                <div className={classnames(styles[`table`])}>
                                  <div
                                    className={classnames(styles[`table-tr`])}
                                  >
                                    <div className={classnames('text')}>
                                      Name
                                    </div>
                                    <div className={classnames('text')}>
                                      Gender
                                    </div>
                                    <div className={classnames('text')}>
                                      Age
                                    </div>
                                  </div>
                                  {formDetail.familyDetails.children.map(
                                    (item, index) => (
                                      <div
                                        key={index}
                                        className={classnames(
                                          styles[`table-tr`],
                                        )}
                                      >
                                        <p>{item.name}</p>
                                        <p>{item.gender}</p>
                                        <p>{item.age}</p>
                                      </div>
                                    ),
                                  )}
                                </div>
                              </div>
                            </Fragment>
                          )}

                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>Spouse Name</span>
                            <p>
                              {formDetail.familyDetails &&
                                formDetail.familyDetails.spouseName}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Spouse Phone:</span>
                            <p>
                              {formDetail.familyDetails &&
                                formDetail.familyDetails.spousePhone}
                            </p>
                          </div>
                        </div>
                      </section>

                      <section className={styles['content-detail-section']}>
                        <h2>Primary Insurance Details</h2>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>Company Name</span>
                            <p>
                              {formDetail.insuranceDetails &&
                                formDetail.insuranceDetails.companyName}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Ins ID#</span>
                            <p>
                              {formDetail.insuranceDetails &&
                                formDetail.insuranceDetails.insuranceId}
                            </p>
                          </div>
                        </div>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>Group Name</span>
                            <p>
                              {formDetail.insuranceDetails &&
                                formDetail.insuranceDetails.groupName}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Group ID#</span>
                            <p>
                              {formDetail.insuranceDetails &&
                                formDetail.insuranceDetails.groupId}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Employer</span>
                            <p>
                              {formDetail.insuranceDetails &&
                                formDetail.insuranceDetails.employer}
                            </p>
                          </div>
                        </div>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>Subscriber's Name</span>
                            <p>
                              {formDetail.insuranceDetails &&
                                formDetail.insuranceDetails.subscriberName}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Relation</span>
                            <p>
                              {formDetail.insuranceDetails &&
                                formDetail.insuranceDetails.subscriberRelation}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Subscriber's DOB</span>
                            <p>
                              {formDetail.insuranceDetails &&
                                displayDateFormat(
                                  formDetail.insuranceDetails.subscriberDOB,
                                )}
                            </p>
                          </div>
                        </div>
                      </section>

                      <section className={styles['content-detail-section']}>
                        <h2>Medical History</h2>
                        <div className={styles.text}>
                          <span>Allergies</span>
                          <p>
                            {formDetail.allergies &&
                              formDetail.allergies.toString()}
                          </p>
                        </div>
                      </section>

                      {formDetail.currentMedications &&
                        formDetail.currentMedications.length > 0 && (
                          <section className={styles['content-detail-section']}>
                            <h2>Current Medications</h2>
                            <div className={styles.detail}>
                              <div className={classnames(styles[`table`])}>
                                <div className={classnames(styles[`table-tr`])}>
                                  <div className={classnames('text')}>Dose</div>
                                  <div className={classnames('text')}>
                                    Frequency
                                  </div>
                                  <div className={classnames('text')}>
                                    Date Used
                                  </div>
                                </div>
                                {formDetail.currentMedications.map(
                                  (item, index) => (
                                    <div
                                      key={index}
                                      className={classnames(styles[`table-tr`])}
                                    >
                                      <p>{item.dose}</p>
                                      <p>{item.frequency}</p>
                                      <p>{item.dateUsed}</p>
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          </section>
                        )}

                      {formDetail.recreationalDrugs &&
                        formDetail.recreationalDrugs.length > 0 && (
                          <section className={styles['content-detail-section']}>
                            <h2>Recreational Durgs</h2>
                            <div className={styles.detail}>
                              <div className={classnames(styles[`table`])}>
                                <div className={classnames(styles[`table-tr`])}>
                                  <div className={classnames('text')}>Type</div>
                                  <div className={classnames('text')}>Dose</div>
                                  <div className={classnames('text')}>
                                    Frequency
                                  </div>
                                  <div className={classnames('text')}>
                                    Date Used
                                  </div>
                                </div>
                                {formDetail.recreationalDrugs.map(
                                  (item, index) => (
                                    <div
                                      key={index}
                                      className={classnames(styles[`table-tr`])}
                                    >
                                      <p>{item.type}</p>
                                      <p>{item.dose}</p>
                                      <p>{item.frequency}</p>
                                      <p>{item.dateUsed}</p>
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          </section>
                        )}

                      <section className={styles['content-detail-section']}>
                        {formDetail.medicalIllness &&
                          formDetail.medicalIllness.length > 0 && (
                            <Fragment>
                              <h2>Medical Illness</h2>
                              <div className={styles.detail}>
                                <div className={classnames(styles[`table`])}>
                                  <div
                                    className={classnames(styles[`table-tr`])}
                                  >
                                    <div
                                      className={classnames(
                                        'text',
                                        styles.column3,
                                      )}
                                    >
                                      Illness
                                    </div>
                                    <div
                                      className={classnames(
                                        'text',
                                        styles.column3,
                                      )}
                                    >
                                      Status
                                    </div>
                                  </div>
                                  {formDetail.medicalIllness.map(
                                    (item, index) => (
                                      <div
                                        key={index}
                                        className={classnames(
                                          styles[`table-tr`],
                                        )}
                                      >
                                        <p>{item.illness}</p>
                                        <p>{item.status}</p>
                                      </div>
                                    ),
                                  )}
                                </div>
                              </div>
                            </Fragment>
                          )}

                        <div className={styles.text}>
                          <span>
                            {formatMessage(
                              intakeFormDisplayMessages.psychologicalCareRadioLabel,
                            )}
                          </span>
                          <p>
                            {formDetail.hasPreviousPsychologicalCare === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                      </section>

                      <section className={styles['content-detail-section']}>
                        {formDetail.psychologicalCare &&
                          formDetail.psychologicalCare.length > 0 && (
                            <Fragment>
                              <h2>Psychological Care</h2>
                              <div className={styles.detail}>
                                <div className={classnames(styles[`table`])}>
                                  <div
                                    className={classnames(styles[`table-tr`])}
                                  >
                                    <div className={classnames('text')}>
                                      Clinicians
                                    </div>
                                    <div className={classnames('text')}>
                                      Difficulty
                                    </div>
                                    <div className={classnames('text')}>
                                      Date
                                    </div>
                                  </div>
                                  {formDetail.psychologicalCare.map(
                                    (item, index) => (
                                      <div
                                        key={index}
                                        className={classnames(
                                          styles[`table-tr`],
                                        )}
                                      >
                                        <p>{item.clinicians}</p>
                                        <p>{item.difficulty}</p>
                                        <p>{item.date}</p>
                                      </div>
                                    ),
                                  )}
                                </div>
                              </div>
                            </Fragment>
                          )}

                        <div className={styles.text}>
                          <span>
                            {formatMessage(
                              intakeFormDisplayMessages.psychiatricMedicationRadioLabel,
                            )}
                          </span>
                          <p>
                            {formDetail.hasPsychiatricMedications === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                      </section>

                      <section className={styles['content-detail-section']}>
                        {formDetail.psychiatricMedications &&
                          formDetail.psychiatricMedications.length > 0 && (
                            <Fragment>
                              <h2>Psychiatric Medications</h2>
                              <div className={styles.detail}>
                                <div className={classnames(styles[`table`])}>
                                  <div
                                    className={classnames(styles[`table-tr`])}
                                  >
                                    <div className={classnames('text')}>
                                      Medicine
                                    </div>
                                    <div className={classnames('text')}>
                                      Dose
                                    </div>
                                    <div className={classnames('text')}>
                                      Effects
                                    </div>
                                  </div>
                                  {formDetail.psychiatricMedications.map(
                                    (item, index) => (
                                      <div
                                        key={index}
                                        className={classnames(
                                          styles[`table-tr`],
                                        )}
                                      >
                                        <p>{item.medicine}</p>
                                        <p>{item.dose}</p>
                                        <p>{item.effects}</p>
                                      </div>
                                    ),
                                  )}
                                </div>
                              </div>
                            </Fragment>
                          )}

                        <div className={styles.text}>
                          <span>
                            {formatMessage(
                              intakeFormDisplayMessages.psychologicalDifficultyRadioLabel,
                            )}
                          </span>
                          <p>
                            {formDetail.hasHospitalized === true ? 'Yes' : 'No'}
                          </p>
                        </div>
                        <div className={styles.text}>
                          <span>
                            {formatMessage(
                              intakeFormDisplayMessages.suicidalThoughtsRadioLabel,
                            )}
                          </span>
                          <p>
                            {formDetail.hasSuicidalThoughts === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                        <div className={styles.text}>
                          <span>
                            {formatMessage(
                              intakeFormDisplayMessages.sleepingHabitsRadioLabel,
                            )}
                          </span>
                          <p>{formDetail.sleepingHabits}</p>
                        </div>
                        <div className={styles.text}>
                          <span>
                            {formatMessage(
                              intakeFormDisplayMessages.difficultyFallingAsleepRadioLabel,
                            )}
                          </span>
                          <p>
                            {formDetail.difficultyFallingAsleep === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                        <div className={styles.text}>
                          <span>
                            {formatMessage(
                              intakeFormDisplayMessages.awakeningDuringNightRadioLabel,
                            )}
                          </span>
                          <p>
                            {formDetail.awakeningDuringNight === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                        <div className={styles.text}>
                          <span>
                            {formatMessage(
                              intakeFormDisplayMessages.poorSleepRadioLabel,
                            )}
                          </span>
                          <p>{formDetail.poorSleep === true ? 'Yes' : 'No'}</p>
                        </div>
                        <div className={styles.text}>
                          <span>
                            {formatMessage(
                              intakeFormDisplayMessages.sleepingProblemLabel,
                            )}
                          </span>
                          <p>{formDetail.sleepingProblemSince}</p>
                        </div>
                        <div className={styles.text}>
                          <span>
                            {formatMessage(
                              intakeFormDisplayMessages.currentWeightLabel,
                            )}
                          </span>
                          <p>{formDetail.weight}</p>
                        </div>
                        <div className={styles.text}>
                          <span>
                            {formatMessage(
                              intakeFormDisplayMessages.currentHeightLabel,
                            )}
                          </span>
                          <p>{formDetail.height}</p>
                        </div>
                        <div className={styles.text}>
                          <span>Are you happy with your weight?</span>
                          <p>
                            {formDetail.isHappyWithWeight === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                        <div className={styles.text}>
                          <span>Have you recently lost or gained weight?</span>
                          <p>
                            {formDetail.hasLostGainedWeight === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </div>
                        <div className={styles.text}>
                          <span>Difficulty with appetite</span>
                          <p>{formDetail.difficultyWithAppetite}</p>
                        </div>
                        <div className={styles.text}>
                          <span>Depression Level</span>
                          <p>{formDetail.depressionLevel}</p>
                        </div>
                        <div className={styles.text}>
                          <span>Depression Since</span>
                          <p>{formDetail.depressionSince}</p>
                        </div>
                        <div className={styles.text}>
                          <span>
                            Are you currently experiencing anxiety, or panic
                            attacks?
                          </span>
                          <p>{formDetail.hasAnxiety === true ? 'Yes' : 'No'}</p>
                        </div>
                        <div className={styles.text}>
                          <span>Anxiety Since</span>
                          <p>{formDetail.anxietySince}</p>
                        </div>
                        <div className={styles.text}>
                          <span>Alcohol Per Week</span>
                          <p>{formDetail.alchoholPerWeek}</p>
                        </div>
                        <div className={styles.text}>
                          <span>Alcohol Per Night</span>
                          <p>{formDetail.alchoholPerNight}</p>
                        </div>
                        <div className={styles.text}>
                          <span>Alcohol Per Hour</span>
                          <p>{formDetail.alchoholPerHour}</p>
                        </div>
                        <div className={styles.text}>
                          <span>How often do you use recreational drugs</span>
                          <p>{formDetail.recreationalDrugsFrequency}</p>
                        </div>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>
                              Have you ever had a dependency on alcohol or
                              recreational drugs?
                            </span>
                            <p>
                              {formDetail.hadAlchoholDrugDependency === true
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>
                              If yes, please explain the nature of dependency or
                              abuse.
                            </span>
                            <p>{formDetail.natureOfAlchoholDepenency}</p>
                          </div>
                        </div>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>
                              Have a history of prescription drug dependency or
                              abuse?
                            </span>
                            <p>
                              {formDetail.hadPrescreptionDrugDependency === true
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>
                              If yes, please explain the nature of dependency or
                              abuse.
                            </span>
                            <p>{formDetail.natureOfPrescreptionDependency}</p>
                          </div>
                        </div>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>
                              {formatMessage(
                                intakeFormDisplayMessages.isPregnantLabel,
                              )}
                            </span>
                            <p>
                              {formDetail.isPregnant === true ? 'Yes' : 'No'}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>
                              If applicable, what form of BC do you use?
                            </span>
                            <p>{formDetail.formOfBC}</p>
                          </div>
                        </div>
                      </section>

                      <section className={styles['content-detail-section']}>
                        <h2>
                          Has anyone in your family been diagnosed with or
                          treated for
                        </h2>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>Bipolar disorder</span>
                            <p>
                              {formDetail.familyMemberBipolarDisorder === true
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Anxiety</span>
                            <p>
                              {formDetail.familyMemberAnxiety === true
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                        </div>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>PTSD</span>
                            <p>
                              {formDetail.familyMemberPTSD === true
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Anger</span>
                            <p>
                              {formDetail.familyMemberAnger === true
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                        </div>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>Other substance abuse</span>
                            <p>
                              {formDetail.familyMemberOtherSubstance === true
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Depression</span>
                            <p>
                              {formDetail.familyMemberDepression === true
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                        </div>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>Violence</span>
                            <p>
                              {formDetail.familyMemberViolence === true
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Alcohol abuse</span>
                            <p>
                              {formDetail.familyMemberAlcoholAbuse === true
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                        </div>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>Schizophrenia</span>
                            <p>
                              {formDetail.familyMemberSchizophrenia === true
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>Suicide</span>
                            <p>
                              {formDetail.familyMemberSuicide === true
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                        </div>
                        <div className={styles.text}>
                          <span>If yes, who had what problems?</span>
                          <p>{formDetail.familyMemberTreatment}</p>
                        </div>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>
                              Has any family member been treated with a
                              psychiatric medication?
                            </span>
                            <p>
                              {formDetail.hasFamilyMemberPsychatric === true
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>
                              If yes, who was treated, what medications, and how
                              effective was the treatment?
                            </span>
                            <p>{formDetail.familyMemberPsychatricTreatment}</p>
                          </div>
                        </div>
                        <div className={styles.detail}>
                          <div className={classnames('text', styles.column3)}>
                            <span>
                              {formatMessage(
                                intakeFormDisplayMessages.natureOfTherapyLabel,
                              )}
                            </span>
                            <p>{formDetail.natureOfTherapy}</p>
                          </div>
                          <div className={classnames('text', styles.column3)}>
                            <span>
                              {formatMessage(
                                intakeFormDisplayMessages.significantTraumaLabel,
                              )}
                            </span>
                            <p>{formDetail.significantTrauma}</p>
                          </div>
                        </div>
                      </section>
                    </Fragment>
                  ) : (
                    <div>No Data Available</div>
                  )}
                </div>
              </div>
            </div>
          </Modal>
        )}

        {showIntakeFormModal && (
          <Modal className={styles.modal}>
            <div className={styles.content}>
              <div
                className={styles.closeButton}
                onClick={() => setShowIntakeFormModal(false)}
              >
                <img src={require(`../../img/close.svg`)} alt={`Close`} />
              </div>
              <h1>
                <b>{item.form_name}</b>
              </h1>
              <div className={styles.box}>
                <div className={styles['content-detail']}>
                  <div className={classnames([styles.headerSection])}>
                    <div>
                      <p className={styles.label}>Client Name:</p>
                      <p>
                        {item.patient &&
                          `${item.patient.first_name || ''} ${
                            item.patient.last_name || ''
                          }`}
                      </p>
                    </div>
                    <div>
                      <p className={styles.label}>Assessment:</p>
                      <p>{item.form_name || ''}</p>
                    </div>
                    <div>
                      <p className={styles.label}>Date Completed:</p>
                      <p>{moment(item.updatedAt).format('MMMM DD, YYYY')}</p>
                    </div>
                    <PDFDownloadLink
                      document={
                        <IntakeFormPDF item={item} formDetail={formDetail} />
                      }
                      fileName={`${item.form_name || 'assessment'}.pdf`}
                    >
                      <PrimaryButton
                        label={`Download PDF`}
                        type="button"
                        className={styles.btn}
                      />
                    </PDFDownloadLink>
                  </div>
                </div>
              </div>
              <div className={styles.box}>
                <div className={styles['content-detail']}>
                  {formDetail && formDetail.length ? (
                    formDetail.map((intakeValue, index) => {
                      if (intakeValue.isFieldArray) {
                        return (
                          <div className={classnames([styles.questionSection])}>
                            <span>{intakeValue.key}</span>
                            <div className={classnames([styles.detail])}>
                              <div className={classnames(styles[`table`])}>
                                <div className={classnames(styles[`table-tr`])}>
                                  {intakeValue.tableHeads.map((element) => {
                                    return (
                                      <div className={classnames('text')}>
                                        {element.title}
                                      </div>
                                    );
                                  })}
                                </div>
                                {intakeValue.rows.map((item, index) => (
                                  <div
                                    key={index}
                                    className={classnames(styles[`table-tr`])}
                                  >
                                    {intakeValue.tableHeads.map((element) => {
                                      return <p>{item[element.key]}</p>;
                                    })}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className={styles.questionSection} key={index}>
                            <span>{intakeValue.key}</span>
                            <p>{intakeValue.value}</p>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div>No Data Available</div>
                  )}
                </div>
              </div>
            </div>
          </Modal>
        )}

        {showConsentModal && (
          <Modal className={styles.modal}>
            <div className={styles.content}>
              <div
                className={styles.closeButton}
                onClick={() => setshowConsentModal(false)}
              >
                <img src={require(`../../img/close.svg`)} alt={`Close`} />
              </div>
              <h1>
                <b>{item.form_name}</b>
              </h1>
              <div className={styles.box}>
                {getConsentFormURl ? (
                  <Iframe
                    url={getConsentFormURl}
                    max-width="600px"
                    width="100%s"
                    height="500px"
                    id="consetntForm"
                    display="initial"
                    position="relative"
                  />
                ) : (
                  <>
                    <div>No Data Available</div>
                  </>
                )}
              </div>
            </div>
          </Modal>
        )}

        {showSignature && (
          <Signature
            doctorId={formDetail.doctor_id}
            patientId={formDetail.patient_id}
            isType={true}
            role="Doctor"
          />
        )}

        {showAssessmentModal && (
          <Modal className={styles.modal}>
            <div className={styles.content}>
              <div
                className={styles.closeButton}
                onClick={() => setShowAssessmentModal(false)}
              >
                <img src={require(`../../img/close.svg`)} alt={`Close`} />
              </div>
              <h1>
                <b>Assessment</b>
              </h1>
              <div className={styles.box}>
                <div className={styles['content-detail']}>
                  <div className={classnames([styles.headerSection])}>
                    <div>
                      <p className={styles.label}>Client Name:</p>
                      <p>
                        {item.patient &&
                          `${item.patient.first_name || ''} ${
                            item.patient.last_name || ''
                          }`}
                      </p>
                    </div>
                    <div>
                      <p className={styles.label}>Assessment:</p>
                      <p>{item.form_name || ''}</p>
                    </div>
                    <div>
                      <p className={styles.label}>Date Completed:</p>
                      <p>{moment(item.updatedAt).format('MMMM DD, YYYY')}</p>
                    </div>
                    <PDFDownloadLink
                      document={
                        <AssessmentPDF2
                          item={item}
                          formassessments={formassessments}
                        />
                      }
                      fileName={`${item.form_name || 'assessment'}.pdf`}
                    >
                      <PrimaryButton
                        label={`Download PDF`}
                        type="button"
                        className={styles.btn}
                      />
                    </PDFDownloadLink>
                  </div>
                </div>
              </div>
              <div className={styles.box}>
                <div className={styles['content-detail']}>
                  {formassessments && formassessments.length ? (
                    formassessments.map((assessment, index) => (
                      <div className={styles['content-detail-row']} key={index}>
                        <span>{assessment.key}</span>
                        <p>{assessment.value}</p>
                      </div>
                    ))
                  ) : (
                    <div>No Data Available</div>
                  )}
                </div>
              </div>
            </div>
          </Modal>
        )}

        {showCofirmation && (
          <UserConfirmation
            message={`Are you sure you want to remove?`}
            onCancel={() => {
              setShowCofirmation(false);
              setRemoveForm(null);
            }}
            onConfirm={() => onConfirmClick()}
          />
        )}
      </Cell>
    </Grid>
  );
};

export default FormListView;
