import React from 'react';

class Calendly extends React.Component {
  calendlyScriptSrc = 'https://assets.calendly.com/assets/external/widget.js';
  buildCalendlyUrl = (url, queryString) =>
    `${url}?primary_color=000000&${queryString}`;

  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', this.calendlyScriptSrc);
    script.setAttribute('id', 'calendly-script');
    head.appendChild(script);
  }

  componentWillUnmount() {
    const head = document.querySelector('head');
    const script = document.getElementById('calendly-script');
    script && head.removeChild(script);
  }
  render() {
    const { url, queryString = '' } = this.props;
    return (
      <div id="schedule_form">
        <div
          className="calendly-inline-widget"
          data-url={this.buildCalendlyUrl(url, queryString)}
          style={{ height: '640px' }}
        />
      </div>
    );
  }
}

export default Calendly;
