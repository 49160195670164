import React, { useState, Fragment } from 'react';
import { Cell, Grid } from 'react-foundation';
import moment from 'moment';

import { saveAs } from 'file-saver';
import { pdfExporter } from 'quill-to-pdf';

import PrimaryButton from '../common/buttons/PrimaryButton';
import SecondaryButton from '../common/buttons/SecondaryButton';
import Modal from '../common/Modal';
import Loading from '../common/Loading';

import Editor from '../Editor/Editor';

import { createNote, updateNote } from '../../api/note';
import styles from './NotesList.module.scss';

const NotesList = ({
  doctor,
  canEdit,
  canAdd,
  updatedAt,
  readOnly,
  notes,
  appointment,
  onNoteSave,
  showTitle,
  loggedInId,
}) => {
  const editorFormats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];
  const [showEditModal, setShowEditModal] = useState(false);
  const [dataLoading, setLoading] = useState(false);
  const [, setShowNoteModal] = useState(false);
  const [noteDetails, setNoteDetails] = useState({});

  const handleNoteChange = (html) => {
    return setNoteDetails(() => {
      return { ...noteDetails, note: html };
    });
  };

  const onClickSaveNote = async () => {
    setLoading(true);
    try {
      if (noteDetails.isNewNote) {
        await createNote({
          note: noteDetails.note,
          doctor_id: noteDetails.doctor_id,
          patient_id: noteDetails.patient_id,
        });
        onNoteSave();
      } else {
        await updateNote(noteDetails.id, {
          note: noteDetails.note,
        });
        onNoteSave();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSavePdf = async (noteId) => {
    const editor = window.activeQuillEditors[noteId];
    const delta = editor.editor.editor.delta;

    const newDelta = {
      ops: (delta.ops || [])
        .filter((item) => {
          let result = true;

          if (
            item.insert &&
            item.insert.image &&
            /^http.*/.test(item.insert.image)
          ) {
            result = false;
          }

          return result;
        })
        .map((item) => {
          if (item.attributes && item.attributes.indent) {
            delete item.attributes.indent;

            if (Object.keys(item.attributes).length === 0) {
              delete item.attributes;
            }
          }
          return item;
        }),
    };

    newDelta.ops.unshift({
      insert:
        '--------------------------------------------------------------------------------------------------------------------- \n',
    });

    newDelta.ops.unshift({
      insert: `${moment(noteDetails.updatedAt).format('LLL')} \n`,
    });
    newDelta.ops.unshift({
      insert: `Last saved: `,
      attributes: { bold: true },
    });

    newDelta.ops.unshift({ insert: `${doctor} \n` });
    newDelta.ops.unshift({ insert: `Physician: `, attributes: { bold: true } });

    newDelta.ops.unshift({
      insert: `${appointment.patient.first_name} ${
        appointment.patient.last_name || ''
      } \n`,
    });
    newDelta.ops.unshift({ insert: `Patient: `, attributes: { bold: true } });

    const pdfAsBlob = await pdfExporter.generatePdf(newDelta); // converts to PDF
    saveAs(pdfAsBlob, 'pdf-export.pdf'); // downloads from the browser
  };

  const getDetailContent = (title) => {
    switch (title) {
      case 'Save Note':
        return (
          <div>
            <div className={styles.modalheader}>
              <div data-testid="note patient_name">
                Patient: {appointment.patient.first_name}
                {appointment.patient.last_name
                  ? appointment.patient.last_name
                  : ''}{' '}
              </div>
              <div data-testid="note Physician">Physician: {doctor}</div>
              {!noteDetails.isNewNote && (
                <div data-testid="note save last saved">
                  Last saved: {moment(noteDetails.updatedAt).format('LLL')}
                </div>
              )}
            </div>
            <div className={styles.line}></div>
            <Editor
              value={noteDetails.note}
              onChange={handleNoteChange}
              formats={editorFormats}
              toolbar={false}
              placeholder={'Type your note here...'}
              readOnly={false}
            />
            <div className={styles.line}></div>
            <div className={styles.buttons}>
              <SecondaryButton
                label="Close"
                type="button"
                className={styles.btn}
                onClick={() => {
                  setShowEditModal(false);
                }}
                data-testid="note close button"
              />
              <PrimaryButton
                label="save"
                type="button"
                className={styles.btn}
                onClick={() => {
                  onClickSaveNote();
                  setShowEditModal(false);
                  setShowNoteModal(false);
                  setNoteDetails({});
                }}
                data-testid="note save button"
              />
            </div>
          </div>
        );
      default:
        return;
    }
  };
  return (
    <Grid className={styles.container}>
      {dataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <Cell small={12}>
            <div className={styles.content}>
              <div className={styles.bgImg}>
                <img
                  alt=""
                  src={require(`../../img/icons/note.svg`)}
                  data-testid="notes image"
                />
              </div>
              <div>
                <div className={styles.title} data-testid="doctor_name label">
                  {doctor}
                  {showTitle && canEdit && (
                    <p data-testid="add new note label">Add a new note</p>
                  )}
                </div>
              </div>
              <div className={styles.rightContent}>
                <div className={styles.buttons}>
                  {canAdd && (
                    <PrimaryButton
                      label="Add"
                      type="button"
                      className={styles.btn}
                      onClick={() => {
                        setShowEditModal(true);
                        setNoteDetails({
                          patient_id: appointment.patient_id,
                          doctor_id: loggedInId,
                          isNewNote: true,
                        });
                      }}
                      data-testid="add note button"
                    />
                  )}
                </div>
              </div>
            </div>
          </Cell>
          {notes &&
            notes.map((note, index) => {
              return (
                <Cell small={12} key={note.id}>
                  <div className={styles.content}>
                    <div className={styles.addedNote}>
                      <div className={styles.leftContent}>
                        <Editor
                          value={note.note}
                          modules={{ toolbar: false }}
                          readOnly={true}
                          editorId={note.id}
                        />
                        <div className={styles.lastEdited}>
                          <div
                            className={styles.lastSaved}
                            data-testid={`notes lastsaved ${note.id}`}
                          >
                            {note.updatedAt ? `Last Saved : ` : 'Add note'}
                            {note.updatedAt &&
                              moment(note.updatedAt).format('LLL')}
                          </div>
                          <div
                            className={styles.lastSaved}
                            data-testid={`notes lastsaved ${note.id}`}
                          >
                            {!canEdit && (
                              <div className={styles.lastSaved}>
                                {!readOnly ? '' : 'Read only'}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={styles.rightContent}>
                        <div className={styles.buttons}>
                          <SecondaryButton
                            id={note.id}
                            label="Save PDF"
                            type="button"
                            className={styles.btn}
                            onClick={() => handleSavePdf(note.id)}
                            data-testid="Save PDF button"
                          />
                          {canEdit && (
                            <PrimaryButton
                              label="Edit"
                              type="button"
                              className={styles.btn}
                              onClick={() => {
                                setShowEditModal(true);
                                setNoteDetails({
                                  id: note.id,
                                  patient_id: note.patient_id,
                                  doctor_id: note.doctor_id,
                                  note: note.note || '',
                                  isNewNote: false,
                                  canEdit: note.doctor_id === loggedInId,
                                  doctorName:
                                    note.first_name +
                                    (note.last_name
                                      ? ' ' + note.last_name
                                      : ''),
                                  updatedAt: note.updatedAt,
                                });
                              }}
                              data-testid="note edit button"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Cell>
              );
            })}
          {showEditModal && (
            <Modal className={styles.modal}>
              <div className={styles.content}>
                <div
                  className={styles.closeButton}
                  onClick={() => {
                    setShowEditModal(false);
                  }}
                  data-testid="add notes close"
                >
                  <img src={require(`../../img/close.svg`)} alt={`Close`} />
                </div>
                <h1 data-testid="add notes label">
                  <b>
                    {noteDetails.isNewNote ? 'Adding Notes' : 'Editing Notes'}
                  </b>
                </h1>
                {getDetailContent('Save Note')}
              </div>
            </Modal>
          )}
        </Fragment>
      )}
    </Grid>
  );
};

export default NotesList;
