import React, { useContext } from 'react';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';

import ScrollToTop from './ScrollToTop';
import Routes from './components/Routes';
import CookiesPanel from './components/CookiesPanel';
import Header from '../src/components/nav/Header';
import Footer from '../src/components/Footer';

import AuthContext, { AuthProvider } from './context/AuthContext';
import { BoxProvider } from './context/BoxContext';
import { InjectIntlContext } from './context/IntlContext';
import { OverlayProvider } from './context/OverlayContext';
import { DiscoverProvider } from './context/DiscoverContext';
import { SquareupProvider } from './context/SquareupContext';
import { WhitelabelProvider } from './context/WhitelabelContext';

import config from './config';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

const oktaAuth = new OktaAuth(config.oidc);

function App() {
  const { authUser } = useContext(AuthContext);
  const history = useHistory();
  const customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    history.push('/');
  };
  const restoreOriginalUri = async (oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  return (
    <InjectIntlContext>
      <AuthProvider>
        <BoxProvider>
          <OverlayProvider>
            <DiscoverProvider>
              <SquareupProvider>
                <WhitelabelProvider>
                  <Router>
                    <ScrollToTop>
                      <Security
                        oktaAuth={oktaAuth}
                        onAuthRequired={customAuthHandler}
                        restoreOriginalUri={restoreOriginalUri}
                      >
                        <Header />
                        <ToastContainer />
                        <Routes authUser={authUser} />
                        <Footer />
                      </Security>
                      <CookiesPanel />
                    </ScrollToTop>
                  </Router>
                </WhitelabelProvider>
              </SquareupProvider>
            </DiscoverProvider>
          </OverlayProvider>
        </BoxProvider>
      </AuthProvider>
    </InjectIntlContext>
  );
}

export default App;
