import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import ReactSwing from 'react-swing';
import styles from './SwipeCard.module.scss';

const useStyles = makeStyles({
  card: {
    height: '30rem',
    width: '24rem',
    maxWidth: '24rem',
  },
  media: {
    height: 'inherit',
  },
});

const App = ({ title, subtitle, description, imageURL, onSwipe }) => {
  const stackEl = React.createRef();
  const classes = useStyles();
  const [stack, setStack] = useState([]);
  const [cardThrown, setCardThrown] = useState(false);

  // Event Handlers
  const throwOutCard = (e) => {
    console.log('throwout', e);
    console.log('stack', stack);
    setCardThrown(true);
  };
  const thrownInCard = (e) => {
    console.log('thrown in', e);
    console.log('stack', stack);
    setCardThrown(false);
  };

  const swingConfig = {
    allowedDirections: [ReactSwing.DIRECTION.LEFT, ReactSwing.DIRECTION.RIGHT],
    throwOutConfidence: (xOffset, yOffset, element) => {
      console.log('throwOutConfidence', Math.abs(xOffset), Math.abs(yOffset));
      if (Math.abs(xOffset) >= 190) {
        return 1;
      }
      return 0;
    },
  };
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <ReactSwing
          className="stack"
          tagName="div"
          setStack={(stack) => setStack(stack)}
          ref={stackEl}
          config={swingConfig}
          throwout={throwOutCard}
          throwoutleft={() => onSwipe(false)}
          throwoutright={() => onSwipe(true)}
          throwin={thrownInCard}
        >
          <Card className={classes.card} ref="card1">
            <CardHeader title={title} />
            {subtitle && (
              <CardContent>
                <div>{subtitle}</div>
              </CardContent>
            )}
            {imageURL && (
              <CardMedia className={classes.media} image={imageURL} />
            )}
            {description && (
              <CardContent>
                <div>{description}</div>
              </CardContent>
            )}
          </Card>
        </ReactSwing>
        {cardThrown && <div>Card thrown!</div>}
        <div className="controls"></div>
      </div>
    </div>
  );
};

export default App;
