import React, { useState, Fragment, useContext, useEffect } from 'react';
import classnames from 'classnames';

import styles from './TeamPage.module.scss';

import DoctorLeftNav from '../../../components/nav/DoctorLeftNav';
import Loading from '../../../components/common/Loading';
import CliniciansList from '../../../components/CliniciansList';
import DynamicClinicText from '../../../components/DynamicClinicText';

import { getTeamMembers } from '../../../api/doctor';

import AuthContext from '../../../context/AuthContext';

const TeamPage = () => {
  const { isLoading, authUser } = useContext(AuthContext);

  const [dataLoading, setLoading] = useState(false);
  const [clinicians, setClinicians] = useState();
  const [incomeleteClinician, setIncompleteClinician] = useState();

  const isDataLoading = dataLoading || isLoading;

  const fetchTeamMembers = async (id) => {
    try {
      const data = await getTeamMembers(id);

      const clinicianRegistrationsId = data.clinician_registrations?.map(
        (d) => d.doctor_id,
      );

      const incompletedInvitedDoctors = data.invited_clinicians?.filter(
        (invitedClinician) => {
          /**
           * if there is no invitedClinician.doctor?.clinic_id
           *    that user has not passed the registration form yet and has not assigned to any clinic yet
           *    and show this doctor in the list
           * if there is invitedClinician.doctor?.clinic_id
           *    and invitedClinician.doctor?.clinic_id === authUser.clinic_id
           *      the the invited doctor has passed the registration form
           *      that the invited doctor is in the user clinic
           *      otherwise the invited doctor has chosen another clinic and not the p
           */
          const cond1 = invitedClinician.doctor?.clinic_id
            ? invitedClinician.doctor.clinic_id === authUser.clinic_id
            : true;

          /**
           * if we can find the related invited doctor`s registartion form`s id
           *   that invited doctor successfully passed full registartion
           */
          const cond2 = !clinicianRegistrationsId.includes(
            invitedClinician.doctor?.id,
          );

          if (cond1 && cond2) {
            return true;
          }
          return false;
        },
      );

      setClinicians(data.clinician_registrations || []);
      setIncompleteClinician(incompletedInvitedDoctors || []);
    } catch (error) {
      console.error('error', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authUser.id) {
      fetchTeamMembers(authUser.id);
    }
  }, [authUser]);

  return (
    <div className={styles.container}>
      <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
        <DoctorLeftNav />
      </div>
      {isDataLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.content}>
            <div
              medium={3}
              className={classnames(styles.toptnav, 'mobile-only')}
            ></div>
            <div className={styles.header}>
              <h1>Team</h1>
            </div>
            {(clinicians && clinicians.length) ||
            (incomeleteClinician && incomeleteClinician.length) ? (
              <div className={styles.clinicianList}>
                {clinicians &&
                  authUser.id &&
                  clinicians.map((clinician) => (
                    <CliniciansList
                      item={clinician}
                      key={clinician.id}
                      fetchTeam={() => fetchTeamMembers(authUser.id)}
                    />
                  ))}
                {incomeleteClinician &&
                  incomeleteClinician.map((clinician) => (
                    <CliniciansList
                      key={clinician.id}
                      item={clinician}
                      incomplete
                    />
                  ))}
              </div>
            ) : (
              <div className={styles.nodata}>
                Use the "Invite Clinician" in the navigation tab to invite Team
                Members to register on <DynamicClinicText />. You can also use
                this to invite colleagues who are not part of your Clinic.
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default TeamPage;
