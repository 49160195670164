import classnames from 'classnames';
import React from 'react';
import useIntl from '../../../../hooks/useIntl';
import WildHeartIntakePDF from '../../../AssessmentPDF/WildHeartIntakePDF';
import Modal from '../../../common/Modal';
import FormDownloadLink from '../../../FormListView/components/FormDownloadLink/FormDownloadLink';
import FormHeader from '../../../FormListView/components/FormHeader/FormHeader';
import styles from '../../../FormListView/FormListView.module.scss';
// import styles from './WildHeartForm.module.scss';
import messages from './messages';

const WildHeartFormResponse = ({ onClick, item, data, opened }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {opened && (
        <Modal className={styles.modal}>
          <div className={styles.content}>
            <FormHeader onClick={onClick} formName={item.form_name} />
            <FormDownloadLink
              item={item}
              pdfDownload={
                <WildHeartIntakePDF
                  item={item}
                  formDetail={item.patient}
                  data={data}
                />
              }
            />
            <div className={styles.box}>
              <div className={styles['content-detail']}>
                {item.patient && item.patient.id ? (
                  <section className={styles['content-detail-section']}>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.name)}</span>
                      <p>{data.name}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.dob)}</span>
                      <p>{data.dob}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.regularUpdatesPermission)}
                      </span>
                      <p>{data.regular_updates_permission}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.currentTherapist)}</span>
                      <p>{data.current_therapist}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.therapistPhone)}</span>
                      <p>{data.therapist_phone}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.problemsDescription)}</span>
                      <p>{data.problems_description}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.treatmentGoals)}</span>
                      <p>{data.treatment_goals}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.treatmentChecklist)}</span>
                      <p>{data?.treatment_checklist?.join(', ')}</p>
                      {data.treatment_checklist.some(
                        (v) => v === 'Other (Please write in)',
                      ) && (
                        <>
                          <span>Other input:</span>
                          <p>${data.treatment_checklist_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.majorSymptoms)}</span>
                      <p>{data.major_symptoms}</p>
                    </div>
                    <h2>
                      {formatMessage(
                        messages.suicideRiskAssessmentSectionTitle,
                      )}
                    </h2>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.hadLifeNegativeThoughts)}
                      </span>
                      <p>{data.life_negative_thoughts}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.suicideThoughtsTextSpacer)}
                      </span>
                      <p>{data.suicidal_thoughts}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.hasLifeNegativeThoughts)}
                      </span>
                      <p>{data.life_negative_thoughts_frequency}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.lastTimeLifeNegativeThoughts)}
                      </span>
                      <p>{data.last_time_life_negative_thoughts}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(
                          messages.lifeNegativeThoughtsTriggerEvent,
                        )}
                      </span>
                      <p>{data.life_negative_thoughts_trigger_event}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(
                          messages.lifeNegativeThoughtsIntensityScale,
                        )}
                      </span>
                      <p>{data.life_negative_thoughts_intensity_scale}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(
                          messages.lifeNegativeThoughtsImprovementFactors,
                        )}
                      </span>
                      <p>{data.life_negative_thoughts_improvement_factors}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.envisagedSuicideMethod)}
                      </span>
                      <p>{data.envisaged_suicide_method}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.suicideMethodAvailability)}
                      </span>
                      <p>{data.suicide_method_availability}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.suicidePlannedTime)}</span>
                      <p>{data.suicide_planned_time}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.suicideAbandonPossibility)}
                      </span>
                      <p>{data.suicide_abandon_possibility}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.feelingHopeless)}</span>
                      <p>{data.feeling_hopeless}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.previousSelfHarmingAttempts)}
                      </span>
                      <p>{data.previous_self_harming_attempts}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.gunsAccess)}</span>
                      <p>{data.guns_access}</p>
                      {data.guns_access === 'yes' && (
                        <>
                          <span>
                            {formatMessage(messages.gunsAccessDependentFields)}:{' '}
                          </span>
                          <p>{data.guns_access_free_text}</p>
                        </>
                      )}
                    </div>
                    <h2>
                      {formatMessage(messages.pastMedicalHistorySectionTitle)}
                    </h2>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.allergies)}</span>
                      <p>{data.allergies}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.currentWeight)}</span>
                      <p>{data.current_weight}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.height)}</span>
                      <p>{data.height}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.prescribedMedications)}
                      </span>
                      <p>{data.prescribed_medications}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.takenMedicationsSupplements)}
                      </span>
                      <p>{data.taken_medications_supplements}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.currentMedicalProblems)}
                      </span>
                      <p>{data.current_medical_problems}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.pastMedicalProblems)}</span>
                      <p>{data.past_medical_problems}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.pastEKG)}</span>
                      <p>{data.past_ekg}</p>
                      {data.past_ekg === 'yes' && (
                        <>
                          <span>{formatMessage(messages.pastEKGTime)}: </span>
                          <p>{data.past_ekg_time}</p>
                          <span>{formatMessage(messages.pastEKGResult)}: </span>
                          <p>{data.past_ekg_result}</p>
                        </>
                      )}
                    </div>
                    <h2>{formatMessage(messages.womenOnlySectionTitle)}</h2>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.lastMenstrualPeriod)}</span>
                      <p>{data.last_menstrual_period}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.pregnancyStatus)}</span>
                      <p>{data.pregnancy_status}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.pregnancyPlans)}</span>
                      <p>{data.pregnancy_plans}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.birthControlMethod)}</span>
                      <p>{data.birth_control_method}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.numberOfPregnancies)}</span>
                      <p>{data.number_of_pregnancies}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.liveBirths)}</span>
                      <p>{data.live_births}</p>
                    </div>
                    <h2>{formatMessage(messages.familySectionTitle)}</h2>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.physicalHealthConcerns)}
                      </span>
                      <p>{data.physical_health_concerns}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.physicalExamDateAndPlace)}
                      </span>
                      <p>{data.physical_exam_date_and_place}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.familyAilmentsTextDelimiter)}
                      </span>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.thyroidDisease)}</span>
                      <p>{data.thyroid_disease}</p>
                      {data.thyroid_disease === 'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberWithAffectionsDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>{data.family_member_with_thyroid_disease}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.anemia)}</span>
                      <p>{data.anemia}</p>
                      {data.anemia === 'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberWithAffectionsDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>{data.family_member_with_anemia}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.liverDisease)}</span>
                      <p>{data.liver_disease}</p>
                      {data.liver_disease === 'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberWithAffectionsDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>{data.family_member_with_liver_disease}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.chronicFatigue)}</span>
                      <p>{data.chronic_fatigue}</p>
                      {data.chronic_fatigue === 'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberWithAffectionsDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>{data.family_member_with_chronic_fatigue}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.kidneyDisease)}</span>
                      <p>{data.kidney_disease}</p>
                      {data.kidney_disease === 'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberWithAffectionsDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>{data.family_member_with_kidney_disease}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.diabetes)}</span>
                      <p>{data.diabetes}</p>
                      {data.diabetes === 'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberWithAffectionsDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>{data.family_member_with_diabetes}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.asthmaRespiratoryProblems)}
                      </span>
                      <p>{data.asthma_respiratory_problems}</p>
                      {data.asthma_respiratory_problems === 'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberWithAffectionsDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>
                            {data.family_member_asthma_respiratory_problems}
                          </p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.stomachOrIntestinalProblems)}
                      </span>
                      <p>{data.stomach_or_intestinal_problems}</p>
                      {data.stomach_or_intestinal_problems ===
                        'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberWithAffectionsDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>
                            {data.family_member_stomach_or_intestinal_problems}
                          </p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.cancer)}</span>
                      <p>{data.cancer}</p>
                      {data.cancer === 'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberWithAffectionsDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>{data.family_member_cancer}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.fibromyalgia)}</span>
                      <p>{data.fibromyalgia}</p>
                      {data.fibromyalgia === 'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberWithAffectionsDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>{data.family_member_fibromyalgia}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.heartDisease)}</span>
                      <p>{data.heart_disease}</p>
                      {data.heart_disease === 'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberWithAffectionsDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>{data.family_member_heart_disease}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.epilepsyOrSeizures)}</span>
                      <p>{data.epilepsy_or_seizures}</p>
                      {data.epilepsy_or_seizures === 'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberWithAffectionsDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>{data.family_member_epilepsy_or_seizures}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.chronicPain)}</span>
                      <p>{data.chronic_pain}</p>
                      {data.chronic_pain === 'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberWithAffectionsDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>{data.family_member_chronic_pain}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.highCholesterol)}</span>
                      <p>{data.high_cholesterol}</p>
                      {data.high_cholesterol === 'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberWithAffectionsDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>{data.family_member_high_cholesterol}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.highBloodPressure)}</span>
                      <p>{data.high_blood_pressure}</p>
                      {data.high_blood_pressure === 'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberWithAffectionsDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>{data.family_member_high_blood_pressure}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.headTrauma)}</span>
                      <p>{data.head_trauma}</p>
                      {data.head_trauma === 'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberWithAffectionsDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>{data.family_member_head_trauma}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.liverProblems)}</span>
                      <p>{data.liver_problems}</p>
                      {data.liver_problems === 'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberWithAffectionsDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>{data.family_member_liver_problems}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.additionalMedicalHistory)}
                      </span>
                      <p>{data.additional_medical_history}</p>
                      {data.additional_medical_history === 'Your Family' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.motherPregnancyComplications,
                            )}
                            :{' '}
                          </span>
                          <p>{data.additional_medical_history_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.motherPregnancyComplications)}
                      </span>
                      <p>{data.mother_pregnancy_complications}</p>
                    </div>
                    <h2>
                      {formatMessage(messages.pastPsychiatricHistoryTitle)}
                    </h2>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.outpatientTreatment)}</span>
                      <p>{data.outpatient_treatment}</p>
                      {data.outpatient_treatment === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.outpatientTreatmentDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>{data.outpatient_treatment_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.psychiatricHospitalization)}
                      </span>
                      <p>{data.psychiatric_hospitalization}</p>
                      {data.psychiatric_hospitalization === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.psychiatricHospitalizationDependentFields,
                            )}
                            :{' '}
                          </span>
                          <p>{data.psychiatric_hospitalization_free_text}</p>
                        </>
                      )}
                    </div>
                    <h2>
                      {formatMessage(
                        messages.pastPsychiatricHistorySectionTitle,
                      )}
                    </h2>
                    <p>
                      <strong>
                        {formatMessage(
                          messages.pastPsychiatricHistorySectionSubTitle,
                        )}
                      </strong>
                    </p>
                    <div>
                      <span>
                        {formatMessage(messages.antiDepressantsTextDelimiter)}
                      </span>
                      <div>
                        <p>{data.anafranil}</p>
                        {data.anafranil.includes('Anafril') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.anafranildependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.celexa}</p>
                        {data.celexa.includes('Celexa (citalopram)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.celexadependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.cymbalta}</p>
                        {data.cymbalta.includes('Cymbalta (duloxetine)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.cymbaltadependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.effexor}</p>
                        {data.effexor.includes('Effexor (venlafaxine)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.effexordependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.elavil}</p>
                        {data.elavil.includes('Elavil (amitriptyline)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.elavildependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.lexapro}</p>
                        {data.lexapro.includes('Lexapro (escitalopram)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.lexaprodependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.luvox_paxil}</p>
                        {data.luvox_paxil.includes(
                          'Luvox (fluvoxamine) Paxil (paroxetine)',
                        ) && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.luvox_paxildependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.pamelor}</p>
                        {data.pamelor.includes('Pamelor (nortriptyline)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.pamelordependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.prozac}</p>
                        {data.prozac.includes('Prozac (fluoxetine)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.prozacdependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.remeron}</p>
                        {data.remeron.includes('Remeron (mirtazapine)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.remerondependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.serzone}</p>
                        {data.serzone.includes('Serzone (nefazodone)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.serzonedependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.tofranil}</p>
                        {data.tofranil.includes('Tofranil (imipramine)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.tofranildependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.wellbutrin}</p>
                        {data.wellbutrin.includes('Wellbutrin (bupropion)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.wellbutrindependentFields}</p>
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      <span>
                        {formatMessage(messages.antipsychoticsTextDelimiter)}
                      </span>
                      <div>
                        <p>{data.abilify}</p>
                        {data.abilify.includes('Abilify (aripiprazole)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.abilifydependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.clozaril}</p>
                        {data.clozaril.includes('Clozaril (clozapine)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.clozarildependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.depakote}</p>
                        {data.depakote.includes('Depakote (valproate)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.depakotedependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.geodon}</p>
                        {data.geodon.includes('Geodon (ziprasidone)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.geodondependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.Haldol}</p>
                        {data.Haldol.includes('Haldol (haloperidol)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.HaldoldependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.lamictal}</p>
                        {data.lamictal.includes('Lamictal (lamotrigine)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.lamictaldependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.lithium}</p>
                        {data.lithium.includes('Lithium') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.lithiumdependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.prolixin}</p>
                        {data.prolixin.includes('Prolixin (fluphenazine)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.prolixindependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.risperdal}</p>
                        {data.risperdal.includes('Risperdal (risperidone)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.risperdaldependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.tegretol}</p>
                        {data.tegretol.includes('Tegretol (carbamazepine)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.remerondependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.topamax}</p>
                        {data.topamax.includes('Topamax (topiramate)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.topamaxdependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.zyprexa}</p>
                        {data.zyprexa.includes('Zyprexa (olanzepine)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.zyprexadependentFields}</p>
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      <span>
                        {formatMessage(
                          messages.sedativesHyptnoticsTextDelimiter,
                        )}
                      </span>
                      <div>
                        <p>{data.ambien}</p>
                        {data.ambien.includes('Ambien (zolpidem)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.ambiendependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.desyrel}</p>
                        {data.desyrel.includes('Desyrel (trazodone)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.desyreldependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.sonata}</p>
                        {data.sonata.includes('Sonata (zaleplon)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.sonatadependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.restoril}</p>
                        {data.restoril.includes('Restoril (temazepam)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.restorildependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.rozerem}</p>
                        {data.rozerem.includes('Rozerem (ramelteon)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.rozeremdependentFields}</p>
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      <span>
                        {formatMessage(messages.ADHDMedicationsTextDelimiter)}
                      </span>
                      <div>
                        <p>{data.adderall}</p>
                        {data.adderall.includes('Adderall (amphetamine)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.adderalldependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.concerta}</p>
                        {data.concerta.includes(
                          'Concerta (methylphenidate)',
                        ) && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.concertadependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.ritalin}</p>
                        {data.ritalin.includes('Ritalin (methylphenidate)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.ritalindependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.strattera}</p>
                        {data.strattera.includes('Strattera (atomoxetine)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.stratteradependentFields}</p>
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      <span>
                        {formatMessage(
                          messages.antianxietyMedicationsTextDelimiter,
                        )}
                      </span>
                      <div>
                        <p>{data.ativan}</p>
                        {data.ativan.includes('Ativan (lorazepam)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.ativandependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.buspar}</p>
                        {data.buspar.includes('Buspar (buspirone)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.buspardependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.klonopin}</p>
                        {data.klonopin.includes('Klonopin (clonazepam)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.klonopindependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.tranxene}</p>
                        {data.tranxene.includes('Tranxene (clorazepate)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.tranxenedependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.valium}</p>
                        {data.valium.includes('Valium (diazepam)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.valiumdependentFields}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <p>{data.xanax}</p>
                        {data.xanax.includes('Xanax (alprazolam)') && (
                          <>
                            <span>
                              {formatMessage(
                                messages.antiDepressantsdependentFields,
                              )}
                              :{' '}
                            </span>
                            <p>{data.xanaxdependentFields}</p>
                          </>
                        )}
                      </div>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.otherPsychiatricMedications)}
                      </span>
                      <p>{data.other_psychiatric_medications}</p>
                    </div>
                    <h2>{formatMessage(messages.exerciseLevelSectionTitle)}</h2>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.regularlyExercise)}</span>
                      <p>{data.regularly_exercise}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.exerciseDaysPerWeek)}</span>
                      <p>{data.exercise_days_per_week}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.exerciseTimePerDay)}</span>
                      <p>{data.exercise_time_per_day}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.exerciseType)}</span>
                      <p>{data.exercise_type}</p>
                    </div>
                    <h2>
                      {formatMessage(
                        messages.familyPsychiatricHistorySectionTitle,
                      )}
                    </h2>
                    <p>
                      <strong>
                        {formatMessage(
                          messages.familyPsychiatricHistorySubSectionTitle,
                        )}
                      </strong>
                    </p>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.alcoholAbuse)}</span>
                      <p>{data.alcohol_abuse}</p>
                      {data.alcohol_abuse === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberSubstanceAbusedependentFields,
                            )}
                          </span>
                          <p>{data.alcohol_abuse_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.anger)}</span>
                      <p>{data.anger}</p>
                      {data.anger === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberSubstanceAbusedependentFields,
                            )}
                          </span>
                          <p>{data.anger_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.anxiety)}</span>
                      <p>{data.anxiety}</p>
                      {data.anxiety === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberSubstanceAbusedependentFields,
                            )}
                          </span>
                          <p>{data.anxiety_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.bipolarDisorder)}</span>
                      <p>{data.bipolar_disorder}</p>
                      {data.bipolar_disorder === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberSubstanceAbusedependentFields,
                            )}
                          </span>
                          <p>{data.bipolar_disorder_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.depression)}</span>
                      <p>{data.depression}</p>
                      {data.depression === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberSubstanceAbusedependentFields,
                            )}
                          </span>
                          <p>{data.depression_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.otherSubstanceAbuse)}</span>
                      <p>{data.other_substance_abuse}</p>
                      {data.other_substance_abuse === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberSubstanceAbusedependentFields,
                            )}
                          </span>
                          <p>{data.other_substance_abuse_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.postTraumaticStress)}</span>
                      <p>{data.post_traumatic_stress}</p>
                      {data.post_traumatic_stress === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberSubstanceAbusedependentFields,
                            )}
                          </span>
                          <p>{data.post_traumatic_stress_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.schizophrenia)}</span>
                      <p>{data.schizophrenia}</p>
                      {data.schizophrenia === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberSubstanceAbusedependentFields,
                            )}
                          </span>
                          <p>{data.schizophrenia_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.suicide)}</span>
                      <p>{data.suicide}</p>
                      {data.suicide === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberSubstanceAbusedependentFields,
                            )}
                          </span>
                          <p>{data.suicide_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.violence)}</span>
                      <p>{data.violence}</p>
                      {data.violence === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberSubstanceAbusedependentFields,
                            )}
                          </span>
                          <p>{data.violence_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(
                          messages.familyMemberPsychiatricMedication,
                        )}
                      </span>
                      <p>{data.family_member_psychiatric_medication}</p>
                      {data.family_member_psychiatric_medication === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.familyMemberSubstanceAbusedependentFields,
                            )}
                          </span>
                          <p>
                            {
                              data.family_member_psychiatric_medication_free_text
                            }
                          </p>
                        </>
                      )}
                    </div>
                    <h2>{formatMessage(messages.substanceUseSectionTitle)}</h2>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.alcoholDrugAbuseTreated)}
                      </span>
                      <p>{data.alcohol_drug_abuse_treated}</p>
                      {data.alcohol_drug_abuse_treated === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.alcoholDrugAbusedependentFields,
                            )}
                          </span>
                          <p>{data.alcohol_drug_abuse_free_text}</p>
                          <span>
                            {formatMessage(
                              messages.alcoholDrugAbuseTreateddependentFields,
                            )}
                          </span>
                          <p>{data.alcohol_drug_abuse_treated_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.alcoholDaysPerWeek)}</span>
                      <p>{data.alcohol_days_per_week}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.minDrinksInADay)}</span>
                      <p>{data.min_drinks_in_a_day}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.maxDrinksInADay)}</span>
                      <p>{data.max_drinks_in_a_day}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.maxDrinksInThreeMonths)}
                      </span>
                      <p>{data.max_drinks_in_three_months}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.substanceCutDownPlan)}
                      </span>
                      <p>{data.substance_cut_down_plan}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.annoyedByPeopleCriticism)}
                      </span>
                      <p>{data.annoyed_by_people_criticism}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.drinkingRemorse)}</span>
                      <p>{data.drinking_remorse}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.drinkingAsHangoverCure)}
                      </span>
                      <p>{data.drinking_as_hangover_cure}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.acknowledgeDrinkingProblem)}
                      </span>
                      <p>{data.acknowledge_drinking_problem}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.acknowledgeDrinkingProblem)}
                      </span>
                      <p>{data.acknowledge_drinking_problem}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.streetDrugsPastThreeMonths)}
                      </span>
                      <p>{data.street_drugs_past_three_months}</p>
                      {data.street_drugs_past_three_months === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.streetDrugsPastThreeMonthsdependentFields,
                            )}
                          </span>
                          <p>{data.street_drugs_past_three_months_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.prescribedMedicationAbuse)}
                      </span>
                      <p>{data.prescribed_medication_abuse}</p>
                      {data.prescribed_medication_abuse === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.prescribedMedicationAbusedependentFields,
                            )}
                          </span>
                          <p>{data.prescribed_medication_abuse_free_text}</p>
                        </>
                      )}
                    </div>
                    <h2>
                      {formatMessage(messages.substancesTriedSectionTitle)}
                    </h2>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.triedAlcohol)}</span>
                      <p>{data.tried_alcohol}</p>
                      {data.tried_alcohol === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.substancesTrieddependentFields,
                            )}
                          </span>
                          <p>{data.tried_alcohol_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.triedCocaine)}</span>
                      <p>{data.tried_cocaine}</p>
                      {data.tried_cocaine === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.substancesTrieddependentFields,
                            )}
                          </span>
                          <p>{data.tried_cocaine_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.triedEcstasy)}</span>
                      <p>{data.tried_ecstasy}</p>
                      {data.tried_ecstasy === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.substancesTrieddependentFields,
                            )}
                          </span>
                          <p>{data.tried_ecstasy_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.triedHeroin)}</span>
                      <p>{data.tried_heroin}</p>
                      {data.tried_heroin === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.substancesTrieddependentFields,
                            )}
                          </span>
                          <p>{data.tried_heroin_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.triedLSDHallucinogens)}
                      </span>
                      <p>{data.tried_lsd_hallucinogens}</p>
                      {data.tried_lsd_hallucinogens === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.substancesTrieddependentFields,
                            )}
                          </span>
                          <p>{data.tried_lsd_hallucinogens_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.triedMarijuana)}</span>
                      <p>{data.tried_marijuana}</p>
                      {data.tried_marijuana === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.substancesTrieddependentFields,
                            )}
                          </span>
                          <p>{data.tried_marijuana_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.triedMethadone)}</span>
                      <p>{data.tried_methadone}</p>
                      {data.tried_methadone === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.substancesTrieddependentFields,
                            )}
                          </span>
                          <p>{data.tried_methadone_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.triedMethamphetamine)}
                      </span>
                      <p>{data.tried_methamphetamine}</p>
                      {data.tried_methamphetamine === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.substancesTrieddependentFields,
                            )}
                          </span>
                          <p>{data.tried_methamphetamine_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.triedPainKillers)}</span>
                      <p>{data.tried_painKillers}</p>
                      {data.tried_painKillers === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.substancesTrieddependentFields,
                            )}
                          </span>
                          <p>{data.tried_painKillers_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.triedStimulants)}</span>
                      <p>{data.tried_stimulants}</p>
                      {data.tried_stimulants === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.substancesTrieddependentFields,
                            )}
                          </span>
                          <p>{data.tried_stimulants_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.triedTranquilizerSleepingPills)}
                      </span>
                      <p>{data.tried_tranquilizer_sleeping_pills}</p>
                      {data.tried_tranquilizer_sleeping_pills === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.substancesTrieddependentFields,
                            )}
                          </span>
                          <p>
                            {data.tried_tranquilizer_sleeping_pills_free_text}
                          </p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.otherSubstancesTried)}
                      </span>
                      <p>{data.other_substances_tried}</p>
                    </div>
                    <h2>
                      {formatMessage(messages.caffeinatedBeveragesPerDayTitle)}
                    </h2>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.coffeePerDay)}</span>
                      <p>{data.coffee_per_day}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.sodasPerDay)}</span>
                      <p>{data.sodas_per_day}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.teaPerDay)}</span>
                      <p>{data.tea_per_day}</p>
                    </div>
                    <h2>
                      {formatMessage(messages.tobaccoHistorySectionTitle)}
                    </h2>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.smokedCigarettes)}</span>
                      <p>{data.smoked_cigarettes}</p>
                      {data.smoked_cigarettes === 'yes' && (
                        <>
                          <span>
                            {formatMessage(messages.currentlySmoking)}
                          </span>
                          <p>{data.currently_smoking}</p>
                          <span>
                            {formatMessage(
                              messages.yearsOfSmokingAndDailyPacks,
                            )}
                          </span>
                          <p>{data.years_of_smoking_and_daily_packs}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.smokedBefore)}</span>
                      <p>{data.smoked_before}</p>
                      {data.smoked_before === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.yearsOfSmokingAndQuittingReason,
                            )}
                          </span>
                          <p>{data.smoked_before_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.pipeUse)}</span>
                      <p>{data.pipe_use}</p>
                      {data.pipe_use === 'yes' && (
                        <>
                          <span>
                            {formatMessage(messages.pipeUsedependentFields)}
                          </span>
                          <p>{data.pipe_use_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.everUsedPipe)}</span>
                      <p>{data.ever_used_pipe}</p>
                      {data.ever_used_pipe === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.everUsedPipedependentFields,
                            )}
                          </span>
                          <p>{data.ever_used_pipe_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.yearsOfSmoking)}</span>
                      <p>{data.years_of_smoking}</p>
                    </div>
                    <h2>
                      {formatMessage(messages.familyBackgroundSectionTitle)}
                    </h2>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.adopted)}</span>
                      <p>{data.adopted}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.growUpPlace)}</span>
                      <p>{data.grow_up_place}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.siblingsInfo)}</span>
                      <p>{data.siblings_info}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.fatherOccupation)}</span>
                      <p>{data.father_occupation}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.motherOccupation)}</span>
                      <p>{data.mother_occupation}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.motherOccupation)}</span>
                      <p>{data.mother_occupation}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.divorcedParents)}</span>
                      <p>{data.divorced_parents}</p>
                      {data.divorced_parents === 'yes' && (
                        <>
                          <span>
                            {formatMessage(messages.divorcedParentsAtAge)}
                          </span>
                          <p>{data.divorced_parents_at_age}</p>
                          <span>
                            {formatMessage(
                              messages.personLivingWithOnParentsDivorce,
                            )}
                          </span>
                          <p>{data.person_living_with_on_parents_divorce}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.fatherRelationship)}</span>
                      <p>{data.father_relationship}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.motherRelationship)}</span>
                      <p>{data.mother_relationship}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.leftHomeAtAge)}</span>
                      <p>{data.left_home_at_age}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.immediateFamilyDeaths)}
                      </span>
                      <p>{data.immediate_family_deaths}</p>
                      {data.immediate_family_deaths === 'yes' && (
                        <>
                          <span>
                            {formatMessage(messages.immediateFamilyDeathsInfo)}
                          </span>
                          <p>{data.immediate_family_deaths_info}</p>
                        </>
                      )}
                    </div>
                    <h2>{formatMessage(messages.traumaHistorySectionTitle)}</h2>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.abuseHistory)}</span>
                      <p>{data.abuse_history}</p>
                      {data.abuse_history === 'yes' && (
                        <>
                          <span>
                            {formatMessage(messages.abuseHistoryInfo)}
                          </span>
                          <p>{data.abuse_history_info}</p>
                        </>
                      )}
                    </div>
                    <h2>
                      {formatMessage(messages.educationalHistorySectionTitle)}
                    </h2>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.highestGradeCompleted)}
                      </span>
                      <p>{data.highest_grade_completed}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.highestGradeCompletedLocation)}
                      </span>
                      <p>{data.highest_grade_completed_location}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.hasAttendedCollege)}</span>
                      <p>{data.has_attended_college}</p>
                      {data.has_attended_college === 'yes' && (
                        <>
                          <span>
                            {formatMessage(messages.attendedCollegeInfo)}
                          </span>
                          <p>{data.attended_college_info}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.highestEducationAttained)}
                      </span>
                      <p>{data.highest_education_attained}</p>
                    </div>
                    <h2>{formatMessage(messages.occupationalHistoryTitle)}</h2>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.currentOccupation)}</span>
                      <p>{data?.current_occupation?.join(', ')}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.presentPositionLength)}
                      </span>
                      <p>{data.present_position_length}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.occupation)}</span>
                      <p>{data.occupation}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.workingLocation)}</span>
                      <p>{data.working_location}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.militaryService)}</span>
                      <p>{data.military_service}</p>
                      {data.military_service === 'yes' && (
                        <>
                          <span>
                            {formatMessage(messages.militaryServiceInfo)}
                          </span>
                          <p>{data.military_service_info}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.honorableDischarge)}</span>
                      <p>{data.honorable_discharge}</p>
                      {data.honorable_discharge === 'Other type discharge' && (
                        <>
                          <span>
                            {formatMessage(messages.honorableDischargeInfo)}
                          </span>
                          <p>{data.honorable_discharge_info}</p>
                        </>
                      )}
                    </div>
                    <h2>
                      {formatMessage(messages.relationshipHistorySectionTitle)}
                    </h2>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.relationshipStatus)}</span>
                      <p>{data.relationship_status}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.relationshipStatusDuration)}
                      </span>
                      <p>{data.relationship_status_duration}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.currentlyInRelationship)}
                      </span>
                      <p>{data.currently_in_relationship}</p>
                      {data.currently_in_relationship === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.currentRelationshipDuration,
                            )}
                          </span>
                          <p>{data.current_relationship_duration}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.sexuallyActive)}</span>
                      <p>{data.sexually_active}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.sexualOrientation)}</span>
                      <p>{data.sexual_orientation}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.sexuallyActive)}</span>
                      <p>{data.sexually_active}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.spouseOccupation)}</span>
                      <p>{data.spouse_occupation}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.spouseRelationShipDescription)}
                      </span>
                      <p>{data.spouse_relationship_description}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.previousMarriages)}</span>
                      <p>{data.previous_marriages}</p>
                      {data.previous_marriages === 'yes' && (
                        <>
                          <span>
                            {formatMessage(messages.previousMarriagesNumber)}
                          </span>
                          <p>{data.previous_marriages_number}</p>
                          <span>
                            {formatMessage(messages.previousMarriagesDuration)}
                          </span>
                          <p>{data.previous_marriages_duration}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.hasChildren)}</span>
                      <p>{data.has_children}</p>
                      {data.has_children === 'yes' && (
                        <>
                          <span>{formatMessage(messages.childrenInfo)}</span>
                          <p>{data.children_info}</p>
                          <span>
                            {formatMessage(
                              messages.childrenRelationshipDescription,
                            )}
                          </span>
                          <p>{data.children_relationship_description}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.othersCoHabitants)}</span>
                      <p>{data.others_cohabitants}</p>
                    </div>
                    <h2>{formatMessage(messages.legalHistorySectionTitle)}</h2>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.beenArrested)}</span>
                      <p>{data.been_arrested}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.pendingLegalProblems)}
                      </span>
                      <p>{data.pending_legal_problems}</p>
                    </div>
                    <h2>{formatMessage(messages.spiritualLifeSectionTitle)}</h2>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.belongsToReligionGroup)}
                      </span>
                      <p>{data.belongs_to_religion_group}</p>
                      {data.belongs_to_religion_group === 'yes' && (
                        <>
                          <span>
                            {formatMessage(
                              messages.belongsToReligionGroupdependentFields,
                            )}
                          </span>
                          <p>{data.belongs_to_religion_group_free_text}</p>
                        </>
                      )}
                    </div>
                    <div className={styles['text']}>
                      <span>
                        {formatMessage(messages.religionGroupInvolvementResult)}
                      </span>
                      <p>{data.religion_group_involvement_result}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.otherMentions)}</span>
                      <p>{data.other_mentions}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.emergencyContact)}</span>
                      <p>{data.emergency_contact}</p>
                    </div>
                    <div className={styles['text']}>
                      <span>{formatMessage(messages.telephoneNumber)}</span>
                      <p>{data.telephone_number}</p>
                    </div>
                  </section>
                ) : (
                  <div>No Data Available</div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default WildHeartFormResponse;
