import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Label from '../../Label';
import { Cell } from 'react-foundation';
import styles from './FieldArrayItem.module.scss';
import { Field } from 'formik';

const FieldArrayItem = ({
  label,
  items,
  push,
  remove,
  arrayName,
  fields,
  required,
  maxLength,
}) => {
  const newItem = fields.reduce((acc, cur) => ({ ...acc, [cur.name]: '' }), {});
  const [errorText, setErrorText] = useState('');

  // Adding blank item
  useEffect(() => {
    if (items.length === 0) {
      if (required) {
        setErrorText('Field is required');
      } else {
        setErrorText('');
      }
    }
  }, [items, required]);
  return (
    <React.Fragment>
      <Cell small={12} medium={12} className={styles['array-field-container']}>
        <Label errorText={errorText}> {label} </Label>
        {items.length < maxLength && (
          <div className={styles.addNewLink} onClick={() => push(newItem)}>
            + Add New
          </div>
        )}
      </Cell>
      <Cell small={12} medium={12}>
        {items.length > 0 &&
          items.map((item, index) => (
            <div key={index} className={styles.fieldArray}>
              {fields.length > 0 &&
                fields.map((field, fieldIndex) => (
                  <Field
                    placeholder={field.placeholder}
                    name={`${arrayName}.${index}.${field.name}`}
                    key={fieldIndex}
                  />
                ))}
              <button
                type="button"
                className="secondary"
                onClick={() => remove(index)}
              >
                X
              </button>
            </div>
          ))}
      </Cell>
    </React.Fragment>
  );
};

FieldArrayItem.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.array,
  push: PropTypes.func,
  remove: PropTypes.func,
  arrayName: PropTypes.string,
  fields: PropTypes.array,
  maxLength: PropTypes.number,
};

FieldArrayItem.defaultProps = {
  maxLength: 20,
};

export default FieldArrayItem;
