import React from 'react';
import { Cell } from 'react-foundation';
import useIntl from '../../../../../hooks/useIntl';
import messages from '../messages';
import {
  Checkbox,
  TextInput,
  Radio,
  Textarea,
  SectionTitle,
  SectionSubTitle,
  SectionTextDelimiter,
} from '.';

const FormElement = ({
  item,
  stepName,
  values,
  handleChange,
  setFieldValue,
  handleCheckboxChange,
  handleBlur,
}) => {
  const { formatMessage } = useIntl();
  const label = messages[item.label] ? formatMessage(messages[item.label]) : '';

  switch (item.type) {
    case 'sectionTitle':
      return (
        <Cell small={12} style={item.inlineStyle || {}}>
          <SectionTitle label={label} />
        </Cell>
      );
    case 'sectionSubTitle':
      return (
        <Cell small={12} style={item.inlineStyle || {}}>
          <SectionSubTitle label={label} />
        </Cell>
      );
    case 'sectionTextDelimiter':
      return (
        <Cell small={12} style={item.inlineStyle || {}}>
          <SectionTextDelimiter label={label} />
        </Cell>
      );
    case 'delimiter':
      return <hr />;
    case 'textarea':
      return (
        <Cell small={12} style={item.inlineStyle || {}}>
          <Textarea
            item={item}
            label={label}
            name={item.id}
            value={values[item.id]}
            onChange={handleChange}
            placeholder="Enter here"
            onBlur={handleBlur}
            autoFocus={true}
            data-testid={`${stepName}.${item.id}.Textarea`}
            rows={2}
          />
        </Cell>
      );
    case 'radio':
      return (
        <Cell small={12} style={item.inlineStyle || {}}>
          <Radio
            item={item}
            label={label}
            componentName={item.id}
            name={item.id}
            options={item.options}
            dataTestId={`${stepName}.${item.id}.RadioGroup`}
            labelTestId={`${stepName}.${item.id}.Label`}
            value={values[item.id]}
            onChange={(value) => setFieldValue(item.id, value)}
          />
        </Cell>
      );
    case 'textInput':
      return (
        <Cell small={12} style={item.inlineStyle || {}}>
          <TextInput
            item={item}
            label={label}
            placeholder="Enter here"
            name={item.id}
            value={values[item.id]}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid={`${stepName}.${item.id}.Input`}
          />
        </Cell>
      );
    case 'checkbox':
      return (
        <Cell small={12} style={item.inlineStyle || {}}>
          <Checkbox
            item={item}
            messages={messages}
            handleCheckboxChange={handleCheckboxChange}
            dataTestId={`${stepName}.${item.id}.Radio`}
            labelTestId={`${stepName}.${item.id}.RadioLabel`}
            value={values[item.id]}
            setFieldValue={setFieldValue}
            label={label}
          />
        </Cell>
      );

    default:
      return null;
  }
};

FormElement.propTypes = {};

FormElement.defaultProps = {};

export default FormElement;
