import { defineMessages } from 'react-intl';

export const messages = {
  formTitle: {
    id: 'components.WildHeart.formTitle',
    defaultMessage: 'Integrated Health Intake Form',
  },
  formSubtitle: {
    id: 'components.WildHeart.formSubtitle',
    defaultMessage:
      'Please complete all information on this form before your first visit. It may seem long, but most of the questions require only a check, so it will go quickly. You may need to ask family members about the family history. Thank you! ',
  },
  name: {
    id: 'components.WildHeart.name',
    defaultMessage: 'Name',
  },
  dob: {
    id: 'components.WildHeart.dob',
    defaultMessage: 'Date of Birth',
  },
  regularUpdatesPermission: {
    id: 'components.WildHeart.regularUpdatesPermission',
    defaultMessage:
      'Do you give permission for ongoing regular updates to be provided to your primary care physician?',
  },
  currentTherapist: {
    id: 'components.WildHeart.currentTherapist',
    defaultMessage: 'Current Therapist/Counselor',
  },
  therapistPhone: {
    id: 'components.WildHeart.therapistPhone',
    defaultMessage: `Therapist's Phone`,
  },
  problemsDescription: {
    id: 'components.WildHeart.problemsDescription',
    defaultMessage: 'What are the problem(s) for which you are seeking help?',
  },
  treatmentGoals: {
    id: 'components.WildHeart.treatmentGoals',
    defaultMessage: 'What are your treatment goals?',
  },
  treatmentChecklist: {
    id: 'components.WildHeart.treatmentChecklist',
    defaultMessage: 'Current Symptoms Checklist:',
  },
  majorSymptoms: {
    id: 'components.WildHeart.majorSymptoms',
    defaultMessage:
      'If you have any of the above current symptoms, for which, if any, do you have what you consider to be “major symptoms?”',
  },

  suicideRiskAssessmentSectionTitle: {
    id: 'components.WildHeart.suicideRiskAssessmentSectionTitle',
    defaultMessage: 'Suicide Risk Assessment',
  },
  suicideThoughtsTextSpacer: {
    id: 'components.WildHeart.suicideThoughtsTextSpacer',
    defaultMessage:
      'If YES, please answer the following. If NO, please skip to the next section. ',
  },
  hadLifeNegativeThoughts: {
    id: 'components.WildHeart.hadLifeNegativeThoughts',
    defaultMessage: `Have you ever had feelings or thoughts that you didn't want to live?`,
  },
  hasLifeNegativeThoughts: {
    id: 'components.WildHeart.hasLifeNegativeThoughts',
    defaultMessage: `Do you currently feel that you don't want to live?`,
  },
  lifeNegativeThoughtsFrequency: {
    id: 'components.WildHeart.lifeNegativeThoughtsFrequency',
    defaultMessage: 'How often do you have these thoughts?',
  },
  lastTimeLifeNegativeThoughts: {
    id: 'components.WildHeart.lastTimeLifeNegativeThoughts',
    defaultMessage: 'When was the last time you had thoughts of dying?',
  },
  lifeNegativeThoughtsTriggerEvent: {
    id: 'components.WildHeart.lifeNegativeThoughtsTriggerEvent',
    defaultMessage: 'Has anything happened recently to make you feel this way?',
  },
  lifeNegativeThoughtsIntensityScale: {
    id: 'components.WildHeart.lifeNegativeThoughtsIntensityScale',
    defaultMessage:
      'On a scale of 1 to 10, (ten being strongest) how strong is your desire to kill yourself currently?',
  },
  lifeNegativeThoughtsImprovementFactors: {
    id: 'components.WildHeart.lifeNegativeThoughtsImprovementFactors',
    defaultMessage: 'What, if anything, could make it better?',
  },
  envisagedSuicideMethod: {
    id: 'components.WildHeart.envisagedSuicideMethod',
    defaultMessage: 'Have you ever thought about how you would kill yourself?',
  },
  suicideMethodAvailability: {
    id: 'components.WildHeart.suicideMethodAvailability',
    defaultMessage: 'Is the method you would use readily available?',
  },
  suicidePlannedTime: {
    id: 'components.WildHeart.suicidePlannedTime',
    defaultMessage: 'Have you planned a time for this?',
  },
  suicideAbandonPossibility: {
    id: 'components.WildHeart.suicideAbandonPossibility',
    defaultMessage:
      'Is there anything that would stop you from killing yourself?',
  },
  feelingHopeless: {
    id: 'components.WildHeart.feelingHopeless',
    defaultMessage: 'Do you feel hopeless and/or worthless?',
  },
  previousSelfHarmingAttempts: {
    id: 'components.WildHeart.previousSelfHarmingAttempts',
    defaultMessage: 'Have you ever tried to kill or harm yourself before?',
  },
  gunsAccess: {
    id: 'components.WildHeart.gunsAccess',
    defaultMessage: 'Do you have access to guns?',
  },
  gunsAccessDependentFields: {
    id: 'components.WildHeart.gunsAccessDependentFields',
    defaultMessage: 'If YES to access to guns, please explain.',
  },

  pastMedicalHistorySectionTitle: {
    id: 'components.WildHeart.pastMedicalHistorySectionTitle',
    defaultMessage: 'Past Medical History',
  },
  allergies: {
    id: 'components.WildHeart.allergies',
    defaultMessage: 'Allergies',
  },
  currentWeight: {
    id: 'components.WildHeart.currentWeight',
    defaultMessage: 'Current Weight',
  },
  height: {
    id: 'components.WildHeart.height',
    defaultMessage: 'Height',
  },
  prescribedMedications: {
    id: 'components.WildHeart.prescribedMedications',
    defaultMessage:
      'Please list ALL you current prescription medications: Medication name, dosage, how often you take, when you started.',
  },
  takenMedicationsSupplements: {
    id: 'components.WildHeart.takenMedicationsSupplements',
    defaultMessage: 'Current over-the-counter medications or supplements taken',
  },
  currentMedicalProblems: {
    id: 'components.WildHeart.currentMedicalProblems',
    defaultMessage: 'Current medical problems',
  },
  pastMedicalProblems: {
    id: 'components.WildHeart.pastMedicalProblems',
    defaultMessage:
      'Past medical problems, nonpsychiatric hospitalization, or surgeries',
  },
  pastEKG: {
    id: 'components.WildHeart.pastEKG',
    defaultMessage: 'Have you ever had an EKG?',
  },
  pastEKGTime: {
    id: 'components.WildHeart.pastEKGTime',
    defaultMessage: 'If YES, when?',
  },
  pastEKGResult: {
    id: 'components.WildHeart.pastEKGResult',
    defaultMessage: 'Was the EKG normal, abnormal or unknown?',
  },
  womenOnlySectionTitle: {
    id: 'components.WildHeart.womenOnlySectionTitle',
    defaultMessage: 'For women only:',
  },
  lastMenstrualPeriod: {
    id: 'components.WildHeart.pregnancyStatus',
    defaultMessage: 'Date of last menstrual period',
  },
  pregnancyStatus: {
    id: 'components.WildHeart.pregnancyStatus',
    defaultMessage:
      'Are you currently pregnant or do you think you might be pregnant?',
  },
  pregnancyPlans: {
    id: 'components.WildHeart.pregnancyPlans',
    defaultMessage: 'Are you planning to get pregnant in the near future?',
  },
  birthControlMethod: {
    id: 'components.WildHeart.birthControlMethod',
    defaultMessage: 'Birth control method',
  },
  numberOfPregnancies: {
    id: 'components.WildHeart.numberOfPregnancies',
    defaultMessage: 'How many times have you been pregnant?',
  },
  liveBirths: {
    id: 'components.WildHeart.liveBirths',
    defaultMessage: 'How many live births?',
  },

  familySectionTitle: {
    id: 'components.WildHeart.familySectionTitle',
    defaultMessage: 'Personal and Family Medical History',
  },
  physicalHealthConcerns: {
    id: 'components.WildHeart.physicalHealthConcerns',
    defaultMessage:
      'Do you have any concerns about your physical health that you would like to discuss with us?',
  },
  physicalExamDateAndPlace: {
    id: 'components.WildHeart.physicalExamDateAndPlace',
    defaultMessage: 'Date and place of last physical exam',
  },
  familyAilmentsTextDelimiter: {
    id: 'components.WildHeart.familyAilmentsTextDelimiter',
    defaultMessage:
      'Please indicate if  you or anyone in your family suffered from any of the following ailments:',
  },
  familyMemberWithAffectionsDependentFields: {
    id: 'components.WildHeart.familyMemberWithAffectionsDependentFields',
    defaultMessage: 'What family member?',
  },
  thyroidDisease: {
    id: 'components.WildHeart.thyroidDisease',
    defaultMessage: 'Thyroid Disease',
  },
  anemia: {
    id: 'components.WildHeart.anemia',
    defaultMessage: 'Anemia',
  },
  liverDisease: {
    id: 'components.WildHeart.liverDisease',
    defaultMessage: 'Liver Disease',
  },
  chronicFatigue: {
    id: 'components.WildHeart.chronicFatigue',
    defaultMessage: 'Chronic Fatigue',
  },
  kidneyDisease: {
    id: 'components.WildHeart.kidneyDisease',
    defaultMessage: 'Kidney Disease',
  },
  diabetes: {
    id: 'components.WildHeart.diabetes',
    defaultMessage: 'Diabetes',
  },
  asthmaRespiratoryProblems: {
    id: 'components.WildHeart.asthmaRespiratoryProblems',
    defaultMessage: 'Asthma/Respiratory Problems',
  },
  stomachOrIntestinalProblems: {
    id: 'components.WildHeart.stomachOrIntestinalProblems',
    defaultMessage: 'Stomach Or Intestinal Problems',
  },
  cancer: {
    id: 'components.WildHeart.cancer',
    defaultMessage: 'Cancer (type)',
  },
  fibromyalgia: {
    id: 'components.WildHeart.fibromyalgia',
    defaultMessage: 'Fibromyalgia',
  },
  heartDisease: {
    id: 'components.WildHeart.heartDisease',
    defaultMessage: 'Heart Disease',
  },
  epilepsyOrSeizures: {
    id: 'components.WildHeart.epilepsyOrSeizures',
    defaultMessage: 'Epilepsy Or Seizures',
  },
  chronicPain: {
    id: 'components.WildHeart.chronicPain',
    defaultMessage: 'Chronic Pain',
  },
  highCholesterol: {
    id: 'components.WildHeart.highCholesterol',
    defaultMessage: 'High Cholesterol',
  },
  highBloodPressure: {
    id: 'components.WildHeart.highBloodPressure',
    defaultMessage: 'High Blood Pressure',
  },
  headTrauma: {
    id: 'components.WildHeart.headTrauma',
    defaultMessage: 'Head Trauma',
  },
  liverProblems: {
    id: 'components.WildHeart.liverProblems',
    defaultMessage: 'Liver Problems',
  },
  additionalMedicalHistory: {
    id: 'components.WildHeart.additionalMedicalHistory',
    defaultMessage:
      'Is there any additional personal or family medical history?',
  },
  additionalMedicalHistoryDependentFields: {
    id: 'components.WildHeart.additionalMedicalHistory',
    defaultMessage: 'If YES, please explain.',
  },
  motherPregnancyComplications: {
    id: 'components.WildHeart.motherPregnancyComplications',
    defaultMessage:
      'When your mother was pregnant with you, were there any complications during the pregnancy or birth?',
  },
  // Step 5
  pastPsychiatricHistoryTitle: {
    id: 'components.WildHeart.pastPsychiatricHistoryTitle',
    defaultMessage: 'Past Psychiatric History',
  },
  outpatientTreatment: {
    id: 'components.WildHeart.outpatientTreatment',
    defaultMessage: 'Outpatient treatment?',
  },
  outpatientTreatmentDependentFields: {
    id: 'components.WildHeart.outpatientTreatmentDependentFields',
    defaultMessage:
      'Please describe the reason for treatment, the kinds of treatment received, the approximate dates, and the name of the doctor or facility where you received treatment.',
  },
  psychiatricHospitalization: {
    id: 'components.WildHeart.psychiatricHospitalization',
    defaultMessage: 'Psychiatric Hospitalization',
  },
  psychiatricHospitalizationDependentFields: {
    id: 'components.WildHeart.psychiatricHospitalizationDependentFields',
    defaultMessage: 'If YES, describe for what reason, when and where.',
  },
  pastPsychiatricHistorySectionTitle: {
    id: 'components.WildHeart.pastPsychiatricHistorySectionTitle',
    defaultMessage: 'Past Psychiatric History',
  },
  pastPsychiatricHistorySectionSubTitle: {
    id: 'components.WildHeart.pastPsychiatricHistorySectionSubTitle',
    defaultMessage:
      "If you have ever taken any of the following medications, please select and indicate the dates, dosage, and how helpful they were (if you can't remember all the details, just write in what you do remember).",
  },
  antiDepressantsTextDelimiter: {
    id: 'components.WildHeart.antiDepressantsTextDelimiter',
    defaultMessage: 'Antidepressants',
  },
  antipsychoticsTextDelimiter: {
    id: 'components.WildHeart.antipsychoticsTextDelimiter',
    defaultMessage: 'Antipsychotics and Mood Stabilizers',
  },
  sedativesHyptnoticsTextDelimiter: {
    id: 'components.WildHeart.sedativesHyptnoticsTextDelimiter',
    defaultMessage: 'Sedatives/Hyptnotics',
  },
  ADHDMedicationsTextDelimiter: {
    id: 'components.WildHeart.ADHDMedicationsTextDelimiter',
    defaultMessage: 'ADHD Medications',
  },
  antianxietyMedicationsTextDelimiter: {
    id: 'components.WildHeart.antianxietyMedicationsTextDelimiter',
    defaultMessage: 'Antianxiety Medications',
  },
  antiDepressantsdependentFields: {
    id: 'components.WildHeart.antiDepressantsdependentFields',
    defaultMessage:
      'Please describe dates, dosage, and the effects and side-effects you experienced.',
  },
  otherPsychiatricMedications: {
    id: 'components.WildHeart.otherPsychiatricMedications',
    defaultMessage:
      'Please describe any other psychiatric medications you have taken:  Name of medication, dates you took it, daily dosage, and the effects and side-effects you experienced.',
  },
  exerciseLevelSectionTitle: {
    id: 'components.WildHeart.exerciseLevelSectionTitle',
    defaultMessage: 'Your Exercise Level',
  },
  regularlyExercise: {
    id: 'components.WildHeart.regularlyExercise',
    defaultMessage: 'Do you exercise regularly?',
  },
  exerciseDaysPerWeek: {
    id: 'components.WildHeart.exerciseDays_PerWeek',
    defaultMessage: 'How many days a week do you get exercise?',
  },
  exerciseTimePerDay: {
    id: 'components.WildHeart.exerciseTimePerDay',
    defaultMessage: 'How much time each day do you exercise?',
  },
  exerciseType: {
    id: 'components.WildHeart.exerciseType',
    defaultMessage: 'What kind of exercise do you do?',
  },
  familyPsychiatricHistorySectionTitle: {
    id: 'components.WildHeart.familyPsychiatricHistorySectionTitle',
    defaultMessage: 'Family Psychiatric History',
  },
  familyPsychiatricHistorySubSectionTitle: {
    id: 'components.WildHeart.familyPsychiatricHistorySubSectionTitle',
    defaultMessage:
      'Has anyone in your family been diagnosed with or treated for any of the following:',
  },
  alcoholAbuse: {
    id: 'components.WildHeart.alcoholAbuse',
    defaultMessage: 'Alcohol abuse',
  },
  anger: {
    id: 'components.WildHeart.anger',
    defaultMessage: 'Anger',
  },
  anxiety: {
    id: 'components.WildHeart.anxiety',
    defaultMessage: 'Anxiety',
  },
  bipolarDisorder: {
    id: 'components.WildHeart.bipolarDisorder',
    defaultMessage: 'Bipolar disorder',
  },
  depression: {
    id: 'components.WildHeart.depression',
    defaultMessage: 'Depression',
  },
  otherSubstanceAbuse: {
    id: 'components.WildHeart.otherSubstanceAbuse',
    defaultMessage: 'Other substance abuse',
  },
  postTraumaticStress: {
    id: 'components.WildHeart.postTraumaticStress',
    defaultMessage: 'Post-traumatic stress',
  },
  schizophrenia: {
    id: 'components.WildHeart.schizophrenia',
    defaultMessage: 'Schizophrenia',
  },
  suicide: {
    id: 'components.WildHeart.suicide',
    defaultMessage: 'Suicide',
  },
  violence: {
    id: 'components.WildHeart.violence',
    defaultMessage: 'Violence',
  },
  familyMemberSubstanceAbusedependentFields: {
    id: 'components.WildHeart.familyMemberSubstanceAbusedependentFields',
    defaultMessage: 'Which family member(s)?',
  },
  familyMemberPsychiatricMedication: {
    id: 'components.WildHeart.familyMemberPsychiatricMedication',
    defaultMessage:
      'Has any family member been treated with psychiatric medication?',
  },
  familyMemberPsychiatricMedicationdependentFields: {
    id: 'components.WildHeart.familyMemberPsychiatricMedicationdependentFields',
    defaultMessage:
      'If YES, who was treated, what medications did they take, and how effective was the treatment?',
  },
  substanceUseSectionTitle: {
    id: 'components.WildHeart.substanceUseSectionTitle',
    defaultMessage: 'Substance Use',
  },
  alcoholDrugAbuseTreated: {
    id: 'components.WildHeart.alcoholDrugAbuseTreated',
    defaultMessage:
      'Have you ever been treated for alcohol or drug use or abuse?',
  },
  alcoholDrugAbuseTreateddependentFields: {
    id: 'components.WildHeart.alcoholDrugAbuseTreateddependentFields',
    defaultMessage: 'If YES, where were you treated and when?',
  },
  alcoholDrugAbusedependentFields: {
    id: 'components.WildHeart.alcoholDrugAbusedependentFields',
    defaultMessage: 'If YES, for which substances?',
  },
  alcoholDaysPerWeek: {
    id: 'components.WildHeart.alcoholDaysPerWeek',
    defaultMessage: 'How many days per week do you drink any alcohol?',
  },
  minDrinksInADay: {
    id: 'components.WildHeart.minDrinksInADay',
    defaultMessage:
      'What is the least number of drinks you will drink in a day?',
  },
  maxDrinksInADay: {
    id: 'components.WildHeart.maxDrinksInADay',
    defaultMessage:
      'What is the most number of drinks you will drink in a day?',
  },
  maxDrinksInThreeMonths: {
    id: 'components.WildHeart.maxDrinksInThreeMonths',
    defaultMessage:
      'In the past three months, what is the largest amount of alcoholic drinks you have consumed in one day?',
  },
  substanceCutDownPlan: {
    id: 'components.WildHeart.substanceCutDownPlan',
    defaultMessage:
      'Have you ever felt you ought to cut down on your drinking or drug use?',
  },
  annoyedByPeopleCriticism: {
    id: 'components.WildHeart.annoyedByPeopleCriticism',
    defaultMessage:
      'Have people annoyed you by criticizing your drinking or drug use?',
  },
  drinkingRemorse: {
    id: 'components.WildHeart.drinkingRemorse',
    defaultMessage:
      'Have you ever felt bad or guilty about your drinking or drug use?',
  },
  drinkingAsHangoverCure: {
    id: 'components.WildHeart.drinkingAsHangoverCure',
    defaultMessage:
      'Have you ever had a drink or used drugs first thing in the morning to steady your nerves or to get rid of a hangover?',
  },
  acknowledgeDrinkingProblem: {
    id: 'components.WildHeart.acknowledgeDrinkingProblem',
    defaultMessage:
      'Do you think you may have a problem with alcohol or drug use?',
  },
  streetDrugsPastThreeMonths: {
    id: 'components.WildHeart.streetDrugsPastThreeMonths',
    defaultMessage: 'Have you used any street drugs in the past 3 months?',
  },
  streetDrugsPastThreeMonthsdependentFields: {
    id: 'components.WildHeart.streetDrugsPastThreeMonthsdependentFields',
    defaultMessage: 'If YES, which ones?',
  },
  prescribedMedicationAbuse: {
    id: 'components.WildHeart.prescribedMedicationAbuse',
    defaultMessage: 'Have you ever abused prescription medication? ',
  },
  prescribedMedicationAbusedependentFields: {
    id: 'components.WildHeart.prescribedMedicationAbusedependentFields',
    defaultMessage: 'If YES, which ones and for how long?',
  },
  substancesTriedSectionTitle: {
    id: 'components.WildHeart.substancesTriedSectionTitle',
    defaultMessage: 'Have you ever tried the following:',
  },
  triedAlcohol: {
    id: 'components.WildHeart.triedAlcohol',
    defaultMessage: 'Alcohol',
  },
  triedCocaine: {
    id: 'components.WildHeart.triedCocaine',
    defaultMessage: 'Cocaine',
  },
  triedEcstasy: {
    id: 'components.WildHeart.triedEcstasy',
    defaultMessage: 'Ecstasy',
  },
  triedHeroin: {
    id: 'components.WildHeart.triedHeroin',
    defaultMessage: 'Heroin',
  },
  triedLSDHallucinogens: {
    id: 'components.WildHeart.triedLSDHallucinogens',
    defaultMessage: 'LSD or Hallucinogens',
  },
  triedMarijuana: {
    id: 'components.WildHeart.triedMarijuana',
    defaultMessage: 'Marijuana',
  },
  triedMethadone: {
    id: 'components.WildHeart.triedMethadone',
    defaultMessage: 'Methadone',
  },
  triedMethamphetamine: {
    id: 'components.WildHeart.triedMethamphetamine',
    defaultMessage: 'Methamphetamine',
  },
  triedPainKillers: {
    id: 'components.WildHeart.triedPainKillers',
    defaultMessage: 'Pain killers (not as prescribed)',
  },
  triedStimulants: {
    id: 'components.WildHeart.triesStimulants',
    defaultMessage: 'Stimulants (pills)',
  },
  triedTranquilizerSleepingPills: {
    id: 'components.WildHeart.triedTranquilizerSleepingPills',
    defaultMessage: 'Tranquilizer/Sleeping pills',
  },
  substancesTrieddependentFields: {
    id: 'components.WildHeart.substancesTrieddependentFields',
    defaultMessage:
      'Please describe approximate length of use and when you last used.',
  },
  otherSubstancesTried: {
    id: 'components.WildHeart.otherSubstancesTried',
    defaultMessage:
      'Please describe any other recreational drugs that you have taken, for how long, and when last used.',
  },
  caffeinatedBeveragesPerDayTitle: {
    id: 'components.WildHeart.caffeinatedBeveragesPerDayTitle',
    defaultMessage: 'How many caffeinated beverages do you drink a day?',
  },
  coffeePerDay: {
    id: 'components.WildHeart.coffeePerDay',
    defaultMessage: 'Coffee',
  },
  sodasPerDay: {
    id: 'components.WildHeart.sodasPerDay',
    defaultMessage: 'Sodas',
  },
  teaPerDay: {
    id: 'components.WildHeart.teaPerDay',
    defaultMessage: 'Tea',
  },
  tobaccoHistorySectionTitle: {
    id: 'components.WildHeart.tobaccoHistorySectionTitle',
    defaultMessage: 'Tobacco History',
  },
  smokedCigarettes: {
    id: 'components.WildHeart.smokedCigarettes',
    defaultMessage: 'Have you ever smoked cigarettes?',
  },
  currentlySmoking: {
    id: 'components.WildHeart.currentlySmoking',
    defaultMessage: 'Do you smoke currently?',
  },
  yearsOfSmokingAndDailyPacks: {
    id: 'components.WildHeart.yearsOfSmokingAndDailyPacks',
    defaultMessage:
      'How many packs a day do you smoke, and how many years have you smoked?',
  },
  smokedBefore: {
    id: 'components.WildHeart.smokedBefore',
    defaultMessage: 'Did you smoke in the past?',
  },
  yearsOfSmokingAndQuittingReason: {
    id: 'components.WildHeart.yearsOfSmokingAndQuittingReason',
    defaultMessage: 'How many years did you smoke, and when did you quit?',
  },
  pipeUse: {
    id: 'components.WildHeart.pipeUse',
    defaultMessage: 'Do you currently use a Pipe, cigars, or chewing tobacco',
  },
  pipeUsedependentFields: {
    id: 'components.WildHeart.pipeUsedependentFields',
    defaultMessage: 'If YES, what kind?',
  },
  everUsedPipe: {
    id: 'components.WildHeart.everUsedPipe',
    defaultMessage:
      'Have you used a Pipe, cigars, or chewing tobacco in the past?',
  },
  everUsedPipedependentFields: {
    id: 'components.WildHeart.everUsedPipedependentFields',
    defaultMessage:
      'What kind, how many years, and how often a day on average do you use?',
  },
  yearsOfSmoking: {
    id: 'components.WildHeart.yearsOfSmoking',
    defaultMessage: 'For how many years?',
  },
  // step 7
  familyBackgroundSectionTitle: {
    id: 'components.WildHeart.familyBackgroundSectionTitle',
    defaultMessage: 'Family Background and Childhood History',
  },
  adopted: {
    id: 'components.WildHeart.adopted',
    defaultMessage: 'Were you adopted?',
  },
  growUpPlace: {
    id: 'components.WildHeart.growUpPlace',
    defaultMessage: 'Where did you grow up?',
  },
  siblingsInfo: {
    id: 'components.WildHeart.siblingsInfo',
    defaultMessage: `If applicable, please list your sibling's name/s and their age/s.`,
  },
  fatherOccupation: {
    id: 'components.WildHeart.fatherOccupation',
    defaultMessage: `What was your father's occupation?`,
  },
  motherOccupation: {
    id: 'components.WildHeart.motherOccupation',
    defaultMessage: `What was your mother's occupation?`,
  },
  divorcedParents: {
    id: 'components.WildHeart.divorcedParents',
    defaultMessage: `Did your parents divorce?`,
  },
  divorcedParentsAtAge: {
    id: 'components.WildHeart.divorcedParentsAtAge',
    defaultMessage: `Did your parents divorce?`,
  },
  divorcedParentsAtAge: {
    id: 'components.WildHeart.divorcedParentsAtAge',
    defaultMessage: `If so, how old were you when they divorced?`,
  },
  personLivingWithOnParentsDivorce: {
    id: 'components.WildHeart.personLivingWithOnParentsDivorce',
    defaultMessage: `If your parents divorced, who did you live with?`,
  },
  fatherRelationship: {
    id: 'components.WildHeart.fatherRelationship',
    defaultMessage: `Describe your father and your relationship with him`,
  },
  motherRelationship: {
    id: 'components.WildHeart.motherRelationship',
    defaultMessage: `Describe your mother and your relationship with her`,
  },
  leftHomeAtAge: {
    id: 'components.WildHeart.leftHomeAtAge',
    defaultMessage: `How old were you when you left home?`,
  },
  immediateFamilyDeaths: {
    id: 'components.WildHeart.immediateFamilyDeaths',
    defaultMessage: `Has anyone in your immediate family died?`,
  },
  immediateFamilyDeathsInfo: {
    id: 'components.WildHeart.immediateFamilyDeathsInfo',
    defaultMessage: `Who and when?`,
  },
  traumaHistorySectionTitle: {
    id: 'components.WildHeart.traumaHistorySectionTitle',
    defaultMessage: `Trauma History`,
  },
  abuseHistory: {
    id: 'components.WildHeart.abuseHistory',
    defaultMessage: `Do you have a history of being abused emotionally, sexually, physically or by neglect?`,
  },
  abuseHistoryInfo: {
    id: 'components.WildHeart.abuseHistoryInfo',
    defaultMessage: `If YES, please describe when, where and by whom`,
  },
  // step 8
  educationalHistorySectionTitle: {
    id: 'components.WildHeart.educationalHistorySectionTitle',
    defaultMessage: 'Educational History',
  },
  highestGradeCompleted: {
    id: 'components.WildHeart.highestGradeCompleted',
    defaultMessage: 'Highest Grade Completed',
  },
  highestGradeCompletedLocation: {
    id: 'components.WildHeart.highestGradeCompletedLocation',
    defaultMessage: 'Where?',
  },
  hasAttendedCollege: {
    id: 'components.WildHeart.hasAttendedCollege',
    defaultMessage: 'Did you attend college?',
  },
  attendedCollegeInfo: {
    id: 'components.WildHeart.attendedCollegeInfo',
    defaultMessage:
      'For each college attended, please list the name, major or area of study.',
  },
  highestEducationAttained: {
    id: 'components.WildHeart.highestEducationAttained',
    defaultMessage:
      'What is your highest educational level or degree attained?',
  },
  occupationalHistoryTitle: {
    id: 'components.WildHeart.occupationalHistoryTitle',
    defaultMessage: 'Occupational History',
  },
  currentOccupation: {
    id: 'components.WildHeart.currentOccupation',
    defaultMessage: 'Are you currently:',
  },
  presentPositionLength: {
    id: 'components.WildHeart.presentPositionLength',
    defaultMessage: 'How long in present position?',
  },
  occupation: {
    id: 'components.WildHeart.occupation',
    defaultMessage: 'What is/was your occupation?',
  },
  workingLocation: {
    id: 'components.WildHeart.workingLocation',
    defaultMessage: 'Where do you work?',
  },
  militaryService: {
    id: 'components.WildHeart.militaryService',
    defaultMessage: 'Have you ever served in the military?',
  },
  militaryServiceInfo: {
    id: 'components.WildHeart.militaryServiceInfo',
    defaultMessage: 'If so, what branch and when?',
  },
  honorableDischarge: {
    id: 'components.WildHeart.honorableDischarge',
    defaultMessage: 'Honorable discharge',
  },
  honorableDischargeInfo: {
    id: 'components.WildHeart.honorableDischargeInfo',
    defaultMessage: 'If Other, please describe',
  },
  relationshipHistorySectionTitle: {
    id: 'components.WildHeart.relationshipHistorySectionTitle',
    defaultMessage: 'Relationship History and Current Family',
  },
  relationshipStatus: {
    id: 'components.WildHeart.relationshipStatus',
    defaultMessage: 'Are you currently:',
  },
  relationshipStatusDuration: {
    id: 'components.WildHeart.relationshipStatusDuration',
    defaultMessage: 'How long have you been in current status?',
  },
  currentlyInRelationship: {
    id: 'components.WildHeart.currentlyInRelationship',
    defaultMessage: 'If not married, are you currently in a relationship?',
  },
  currentRelationshipDuration: {
    id: 'components.WildHeart.currentRelationshipDuration',
    defaultMessage: 'If YES, how long?',
  },
  sexuallyActive: {
    id: 'components.WildHeart.sexuallyActive',
    defaultMessage: 'Are you sexually active?',
  },
  sexualOrientation: {
    id: 'components.WildHeart.sexualOrientation',
    defaultMessage: 'How would you identify your sexual orientation?',
  },
  spouseOccupation: {
    id: 'components.WildHeart.spouseOccupation',
    defaultMessage: `If applicable, what is your spouse or significant other's occupation?`,
  },
  spouseRelationShipDescription: {
    id: 'components.WildHeart.spouseRelationShipDescription',
    defaultMessage: `If applicable, describe your relationship with your spouse or significant other`,
  },
  previousMarriages: {
    id: 'components.WildHeart.previousMarriages',
    defaultMessage: `Have you had any prior marriages?`,
  },
  previousMarriagesNumber: {
    id: 'components.WildHeart.previousMarriagesNumber',
    defaultMessage: `If so, how many?`,
  },
  previousMarriagesDuration: {
    id: 'components.WildHeart.previousMarriagesDuration',
    defaultMessage: `How long did each previous marriage last?`,
  },
  hasChildren: {
    id: 'components.WildHeart.hasChildren',
    defaultMessage: `Do you have children?`,
  },
  childrenInfo: {
    id: 'components.WildHeart.childrenInfo',
    defaultMessage: `If YES, list ages and genders`,
  },
  childrenRelationshipDescription: {
    id: 'components.WildHeart.childrenRelationshipDescription',
    defaultMessage: `Describe your relationship with your children`,
  },
  othersCoHabitants: {
    id: 'components.WildHeart.othersCoHabitants',
    defaultMessage: `List everyone who currently lives with you`,
  },
  // Step 9
  legalHistorySectionTitle: {
    id: 'components.WildHeart.legalHistorySectionTitle',
    defaultMessage: `Legal History`,
  },
  beenArrested: {
    id: 'components.WildHeart.beenArrested',
    defaultMessage: `Have you ever been arrested?`,
  },
  pendingLegalProblems: {
    id: 'components.WildHeart.pendingLegalProblems',
    defaultMessage: `Do you have any pending legal problems?`,
  },
  spiritualLifeSectionTitle: {
    id: 'components.WildHeart.spiritualLifeSectionTitle',
    defaultMessage: `Spiritual Life`,
  },
  belongsToReligionGroup: {
    id: 'components.WildHeart.belongsToReligionGroup',
    defaultMessage: `Do you belong to a particular religion or spiritual group?`,
  },
  belongsToReligionGroupdependentFields: {
    id: 'components.WildHeart.belongsToReligionGroupdependentFields',
    defaultMessage: `If YES, what is the level of your involvement?`,
  },
  religionGroupInvolvementResult: {
    id: 'components.WildHeart.religionGroupInvolvementResult',
    defaultMessage: `Do you find your involvement helpful during this illness, or does the involvement make things more difficult or stressful for you?`,
  },
  otherMentions: {
    id: 'components.WildHeart.otherMentions',
    defaultMessage: `Is there anything else that you would like us to know?`,
  },
  emergencyContact: {
    id: 'components.WildHeart.emergencyContact',
    defaultMessage: `Emergency Contact`,
  },
  telephoneNumber: {
    id: 'components.WildHeart.telephoneNumber',
    defaultMessage: `Telephone Number`,
  },
  otherWriteIn: {
    id: 'components.WildHeart.otherWriteIn',
    defaultMessage: 'Other (Please write in)',
  },
};

export default defineMessages(messages);
