import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.CalendlyAuthCallbackPage.title',
    description: 'Doctor Calendly Connect Page title',
    defaultMessage: 'Calendly Connect',
  },
  squareaccountexists: {
    id: 'pages.CalendlyAuthCallbackPage.squareaccountexists',
    description: 'Already have a Calendly Account',
    defaultMessage: 'If you already have a square account',
  },
  squareaccountnotexists: {
    id: 'pages.CalendlyAuthCallbackPage.squareaccountnotexists',
    defaultMessage: "Don't have a Calendly Account",
    description: "If you don't have a Calendly account",
  },
});
