import { defineMessages } from 'react-intl';

export default defineMessages({
  modalTitle: {
    id: 'pages.inviteClinicial.modalTitle',
    description: 'Invite Clinician Info Modal Title',
    defaultMessage: 'User invitation',
  },
  modalSuccessText: {
    id: 'pages.inviteClinicial.modalSuccessText',
    description: 'Invite Clinician Info Modal Success Text',
    defaultMessage: 'Clinician successfully invited.',
  },
  modalFailureText: {
    id: 'pages.inviteClinicial.modalFailureText',
    description: 'Invite Clinician Info Modal Failure Text',
    defaultMessage: 'Clinician invitation failed.',
  },
});
