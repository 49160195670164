import React, { useState, Fragment, useContext } from 'react';
import { Form, Formik } from 'formik';
import { Cell } from 'react-foundation';
import classnames from 'classnames';
import styles from './InviteResearcherPage.module.scss';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import Loading from '../../../components/common/Loading';
import FieldInput from '../../../components/common/fields/FieldInput/FieldInput';
import AuthContext from '../../../context/AuthContext';
import * as Yup from 'yup';
import messages from './messages';
import useIntl from '../../../hooks/useIntl';
import MessageModal from '../../../components/MessageModal';
import ResearcherLeftNav from '../../../components/nav/ResearcherLeftNav';
import { inviteResearcher } from '../../../api/researcher';

const tabs = ['EMAIL', 'SMS'];
const InviteResearcherPage = () => {
  const { formatMessage } = useIntl();
  const [error, setError] = useState('');
  const [dataLoading, setLoading] = useState(false);
  const { authUser } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [displayMessage, setDisplayMessage] = useState({
    title: '',
    message: '',
  });

  const EmailSchema = Yup.object().shape({
    firstName: Yup.string().required('Field Required'),
    email: Yup.string().email().required('Field Required'),
  });
  const SmsSchema = Yup.object().shape({
    firstName: Yup.string().required('Field Required'),
    phone: Yup.string()
      .min(10, 'Please enter valid phone number')
      .max(10, 'Please enter valid phone number')
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Please enter valid phone number',
      )
      .required('Phone number is required.'),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const { email, firstName, phone } = values;
      const { id, lab_id } = authUser;

      await inviteResearcher({
        email: email === '' ? null : email,
        phone: phone === '' ? null : phone,
        firstName,
        invitee_id: id,
        invited_status: 'pending',
        lab_id: lab_id ? lab_id : null,
        isOwner: true,
      });
      setLoading(false);
      setDisplayMessage({
        type: 'success',
        title: formatMessage(messages.modalSuccessText),
        text: '',
      });
      setShowMessageModal(true);
    } catch (error) {
      let errorMsg = '';
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMsg = error.response.data.message;
      }
      setError(errorMsg);
      setLoading(false);
      setDisplayMessage({
        type: 'error',
        title: '',
        text: formatMessage(messages.modalFailureText),
      });
      setShowMessageModal(true);
      throw error;
    }
  };

  const onclickTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className={styles.container}>
        <div medium={3} className={classnames(styles.leftnav, 'desktop-only')}>
          <ResearcherLeftNav />
        </div>
        {dataLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <div className={styles.lineArt}>
              <img src={require(`../../../img/LineArt.svg`)} alt="line-art" />
            </div>
            <div className={styles.contentDiv}>
              <div
                medium={3}
                className={classnames(styles.toptnav, 'mobile-only')}
              />

              <Formik
                initialValues={{ email: '', firstName: '', phone: '' }}
                validationSchema={
                  activeTab === 'EMAIL' ? EmailSchema : SmsSchema
                }
                onSubmit={async (values) => {
                  handleSubmit(values);
                }}
              >
                {({
                  errors,
                  handleChange,
                  values,
                  touched,
                  handleBlur,
                  setFieldValue,
                  isValid,
                }) => {
                  return (
                    <Form className={styles['content']}>
                      <div className={classnames(styles[`form-div`])}>
                        <h2 data-testid="invite researcher header">
                          Invite Researcher
                        </h2>
                        <div
                          className={styles['sub-text']}
                          data-testid="invite-patient subtext"
                        >
                          Invite Researcher To Join Eve Health Systems Platform
                        </div>
                        <nav className={styles.tabs}>
                          <div>
                            <p data-testid="researcher send_invitation">
                              How do you want to send your invitation?
                            </p>
                          </div>
                          <div>
                            {tabs.map((tab) => (
                              <button
                                key={tab}
                                className={[
                                  styles.tab,
                                  activeTab === tab ? styles.activeTab : '',
                                ].join(' ')}
                                type="button"
                                onClick={() => {
                                  setFieldValue('email', '');
                                  setFieldValue('phone', '');
                                  onclickTab(tab);
                                }}
                              >
                                <span data-testid={tab}>{tab}</span>
                              </button>
                            ))}
                          </div>
                        </nav>
                        <div className={styles.formInput}>
                          <Cell small={12} medium={12}>
                            <FieldInput
                              label="First Name"
                              className={styles.input}
                              name="firstName"
                              errorText={errors.firstName}
                              onChange={(e) => {
                                setFieldValue('firstName', e.target.value);
                              }}
                              onBlur={handleBlur}
                              value={values.firstName}
                              placeholder="First Name"
                              data-testid="invite_researcher first_name"
                              label-testid="invite_researcher first_name label"
                              error-testid="invite_researcher first_name error"
                            />
                          </Cell>
                          <Cell small={12} medium={12}>
                            {activeTab === 'EMAIL' ? (
                              <FieldInput
                                label="email"
                                className={styles.input}
                                name="email"
                                errorText={errors.email}
                                onChange={(e) => {
                                  setError('');
                                  setFieldValue('email', e.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values.email}
                                placeholder="Researcher's Email Address"
                                data-testid="invite_researcher email"
                                label-testid="invite_researcher email label"
                                error-testid="invite_researcher email error"
                              />
                            ) : (
                              <FieldInput
                                label="Mobile number"
                                className={styles.input}
                                name="phone"
                                errorText={errors.phone}
                                onChange={(e) => {
                                  setError('');
                                  setFieldValue('phone', e.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values.phone}
                                placeholder="Mobile number"
                                data-testid="researcher phone"
                                label-testid="researcher phone label"
                                error-testid="researcher phone error"
                              />
                            )}
                          </Cell>
                        </div>
                        {error && (
                          <div
                            className={styles.error}
                            data-testid="invite_researcher error"
                          >
                            {error}
                          </div>
                        )}
                        <div className={classnames(styles[`button-row`])}>
                          <PrimaryButton
                            label="Invite"
                            className={classnames(styles[`button`])}
                            disabled={!isValid}
                            data-testid="researcher invite_researcher invite button"
                          />
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Fragment>
        )}
      </div>

      {showMessageModal && (
        <MessageModal
          message={displayMessage}
          onCloseModal={() => setShowMessageModal(false)}
          redirectUrl={'/doctor/my-account'}
        />
      )}
    </>
  );
};

export default InviteResearcherPage;
