import { defineMessages } from 'react-intl';

export default defineMessages({
  modalTitle: {
    id: 'pages.InviteParticipantsPage.modalTitle',
    description: 'Invite Patient Info Modal Title',
    defaultMessage: 'User invitation',
  },
  modalSuccessText: {
    id: 'pages.InviteParticipantsPage.modalSuccessText',
    description: 'Invite Patient Info Modal Success Text',
    defaultMessage: 'Patient successfully invited.',
  },
  modalFailureText: {
    id: 'pages.InviteParticipantsPage.modalFailureText',
    description: 'Invite Patient Info Modal Failure Text',
    defaultMessage: 'Patient invitation failed.',
  },
});
